<template>
	<div class="row patient_chart">
		<Sidebar></Sidebar>
		<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 edit_record_page">
			<div class="row">
				<div class="col-12">
					<div class="text-end page_header_btns">
						<button class="comman_brdr_btn mx15" @click="cancel">
							Cancel
						</button>
						<button class="comman_btn mx15" @click="submit">
							{{btnname}}
							<img src="images/loader.gif" v-if="loader" />
						</button>
					</div>
				</div>
			</div>
			<div class="scrlbar">
				<div class="row ">
					<div class="col-6">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title mt-0">Demographic</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Last Name<small class="asterisksign">*</small></label> <span><input
											type="text" class="form-control"
											v-model="v$.form.last_name.$model"></span></p>
								<ValidationErrorMessageList :errors="v$.form.last_name.$errors" />

								<p><label>Middle Name</label> <span><input type="text" class="form-control"
											v-model="form.middle_name"></span></p>
								<p><label>First Name<small class="asterisksign">*</small></label> <span><input
											type="text" class="form-control"
											v-model="v$.form.first_name.$model"></span></p>
								<ValidationErrorMessageList :errors="v$.form.first_name.$errors" />

								<p><label>Gender<small class="asterisksign">*</small></label>
									<span>
										<CustomDropDown :options="gender_option" :initialValue="this.form.gender_text"
											v-model="form.gender" @item-selected="handleItemSelected"
											fieldName="gender"></CustomDropDown>
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.gender.$errors" />

								<p>
									<label>Date Of Birth <small class="asterisksign">*</small></label>
									<span class="two_inputs">
										<div class="calendar">
											<ejs-datepicker class="e-field form-control" v-model="form.dob"
												:maxDate="currentDate" @change="this.calculateAge"
												:showClearButton="false" format="yyyy-MM-dd"></ejs-datepicker>
										</div>
										<aside class="mute_text ms-3">(YYYY-MM-DD)</aside>
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.dob.$errors" />

								<p><label>Age</label> <span><input type="text" class="form-control"
											v-model="this.form.age"></span></p>
								<p><label>Title</label> <span>
										<CustomDropDown :options="title_option" :initialValue="form.title_text"
											v-model="form.title" @item-selected="handleItemSelected" fieldName="title">
										</CustomDropDown>
									</span></p>

							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">HEALTH INSURANCE</h4>
							</div>
							<div class="box-body containt_detail">
								<div class="two_inputs">
									<p><label>Health Card Number </label><span><input type="text"
												class="form-control " v-model="form.health_insurance_no"></span>
									</p>
									<p><label>Ver.</label><span><input type="text" class="form-control"
												v-model="form.health_card_ver"></span></p>
								</div>
								<p><label>Health Card Province </label><span>
										<CustomDropDown :options="insuranceProviderDataSource"
											:initialValue="form.heath_card_province_text"
											v-model="form.health_card_province_id" @item-selected="handleItemSelected"
											fieldName="health_card_province_id"></CustomDropDown>
									</span></p>
							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">Clinic Status</h4>
							</div>
							<div class="box-body containt_detail">
								<p>
									<label>Roster Status</label>
									<span>
										<CustomDropDown :options="roster_status_options"
											:initialValue="form.roster_status_text" v-model="form.roster_status"
											@item-selected="handleItemSelected" fieldName="roster_status">
										</CustomDropDown>
									</span>
								</p>
								<p><label>Family Physician Last Name</label> <span><input type="text"
											class="form-control" v-model="form.family_physician_last_name"></span>
								</p>
								<p><label>Family Physician First Name </label> <span><input type="text"
											class="form-control"
											v-model="form.family_physician_first_name"></span></p>
								<p><label>Patient Status</label>
									<span>
										<CustomDropDown :options="status_option"
											:initialValue="form.patient_status_text" v-model="form.status"
											@item-selected="handleItemSelected" fieldName="status"></CustomDropDown>
										<!-- <select class="form-control" v-model="form.status" :value="form.status">
										<option value="">Select Patient Status</option>
										<option :value="item.value" v-for="(item,index) in status_option" :key="index">{{ item.title }}</option>
									</select> -->
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.status.$errors" />

								<p><label>Patient Status Date</label>
									<span class="calendar">
										<div class="controls">
											<div class="syncfusion-component">
												<ejs-datepicker class="e-field form-control"
													v-model="form.patient_status_date" :maxDate="currentDate"
													:showClearButton="false" format="yyyy-MM-dd"
													@change="onFieldChange('patient_status_date')"></ejs-datepicker>
											</div>
										</div>
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.patient_status_date.$errors" />

								<p><label>Roster Termination Date</label>
									<span class="calendar">
										<div class="controls">
											<div class="syncfusion-component">
											</div>
											<ejs-datepicker class="e-field form-control" v-model="form.termination_date"
												:maxDate="currentDate" :showClearButton="false" format="yyyy-MM-dd"
												@change="onFieldChange('termination_date')"></ejs-datepicker>
										</div>
									</span>
								</p>
								<p><label>Roster Termination Reason</label>
									<span>
										<CustomDropDown :options="termination_reason_option"
											:initialValue="form.enrolment_status_text" v-model="form.enrolment_status"
											@item-selected="handleItemSelected" fieldName="enrolment_status">
										</CustomDropDown>
										<!-- <select class="form-control" v-model="form.enrolment_status">
											<option value="">Select Roster Status</option>
											<option :value="item.value" v-for="(item,index) in termination_reason_option" :key="index">{{ item.title }}</option>
										</select> -->
									</span>
								</p>
								<p><label>Primary Physician</label> <span>
										<CustomDropDown :options="mrpProviderDataSource"
											:initialValue="form.physician_text" v-model="form.provider_id"
											@item-selected="handleItemSelected" fieldName="provider_id">
										</CustomDropDown>
										<!-- <select class="form-control" v-model="form.provider_id">
										<option value="">Select Primary Physician</option>
											<option :value="item.value" v-for="(item,index) in mrpProviderDataSource" :key="index">{{ item.title }}</option>
									</select> -->
									</span></p>

							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title mt-0">Contact Information</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Cell Phone <small class="asterisksign">*</small></label> <span><input type="text" class="form-control "
											v-model="form.cell_phone"></span></p>
								<p><label>Email </label> <span><input type="text" class="form-control "
											v-model="form.email"></span></p>
								<ValidationErrorMessageList :errors="v$.form.email.$errors" />

								<p><label>Residential Address </label> <span><input type="text"
											class="form-control " v-model="form.residential_address"></span></p>
								<p><label>Residential City </label> <span><input type="text" class="form-control "
											v-model="form.residential_city"></span></p>
								<p><label>Residential Country </label> <span>
										<CustomDropDown :options="country_options"
											:initialValue="form.residential_country_text" 
											v-model="form.residential_country" @item-selected="handleItemSelected"
											fieldName="residential_country"></CustomDropDown>
										<!-- <select class="form-control" v-model="form.residential_country" :value="form.residential_country">
										<option value="">Select Residential Country</option>
										<option :value="item.value" v-for="(item,index) in country_options" :key="index" @change="getResidentStateData(form.residential_country)">{{ item.title }}</option>
									</select> -->
									</span></p>
								<p><label>Residential Province </label> <span>
										<CustomDropDown :options="this.residential_state_options" :initialValue="form.residential_province_name" v-model="form.residential_state" @item-selected="handleItemSelected" fieldName="residential_state" :key="stateKey"></CustomDropDown>

										<!-- <select class="form-control " v-model="form.residential_state"
											:value="form.residential_state">
											<option value="">Select Residential Province</option>{{
											residential_state_options }}
											<option :value="item.value"
												v-for="(item,index) in residential_state_options" :key="index">{{
												item.title }}</option>
										</select> -->
									</span></p>
								<p><label>Residential Postal</label> <span><input type="text" class="form-control "
											v-model="form.residential_zip"></span></p>
								<p><label>Home Phone</label> <span><input type="text" class="form-control "
											v-model="form.home_phone"></span></p>
								<div class="two_inputs">
									<p><label>Work Phone</label> <span><input type="text" class="form-control "
												v-model="form.work_phone"></span></p>
									<p><label>Ext</label><span><input type="text" class="form-control"
												v-model="form.work_phone_ext"></span></p>
								</div>
								<p class="address_line">
									<label class="border-0 p-0">Mailing Address Same as Residential</label>
									<span class="ms-3 p-0 lh-1 w-auto">
										<input class="form-check-input m-0 align-middle" type="checkbox" v-if="form.is_same_residential_mailing" checked v-model="form.is_same_residential_mailing">
										<input class="form-check-input m-0 align-middle" type="checkbox" v-else checked v-model="form.is_same_residential_mailing">
									</span>
								</p>

								<slot v-if="!form.is_same_residential_mailing">
									<p><label>Mailing Address </label> <span><input type="text" class="form-control"
												v-model="form.mailing_address"></span></p>
									<p><label>Mailing City </label> <span><input type="text" class="form-control"
												v-model="form.mailing_city"></span></p>
									<p><label>Mailing Country </label> <span>
											<CustomDropDown :options="country_options"
												:initialValue="form.mailing_country_text" v-model="form.mailing_country"
												@item-selected="handleItemSelected" fieldName="mailing_country">
											</CustomDropDown>
											<!-- <select class="form-control" v-model="form.mailing_country" :value="form.mailing_country">
											<option value="">Select Mailing Country</option>
											<option :value="item.value" v-for="(item,index) in country_options" :key="index" @change="getResidentStateData(form.mailing_country)">{{ item.title }}</option>
										</select> -->
										</span></p>
									<p><label>Mailing Province </label> <span>
											<CustomDropDown :options="this.mailing_state_options" :initialValue="form.mailing_province_name" v-model="form.mailing_state" @item-selected="handleItemSelected" fieldName="mailing_state" :key="stateMailingKey"></CustomDropDown>
											<!-- <select class="form-control" v-model="form.mailing_state"
												:value="form.mailing_state">
												<option value="">Select Mailing Province</option>
												<option :value="item.value"
													v-for="(item,index) in mailing_state_options" :key="index">{{
													item.title }}</option>
											</select> -->
										</span></p>
								</slot>

							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">PATIENT’S NOTE</h4>
							</div>
							<div class="box-body containt_detail">
								<p class="align-items-start mb-3"><label class="mw-auto">Notes</label> <span><textarea class="form-control" v-model="form.notes"
											rows="3"></textarea></span></p>
								<p class="align-items-start mb-3"><label class="mw-auto">Alerts</label> <span><textarea class="form-control" v-model="form.alert"
											rows="3"></textarea></span></p>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<slot v-for="(item,index) in form.alternative_contact_detail" :key="index">
						<div class="col-6">
							<div class="box box-bordered ">
								<div class="box-header with-border">
									<h4 class="box-title" :class="{ 'opacity-0': index > 0 }">ALTERNATIVE CONTACT</h4>
								</div>
								<div class="box-body containt_detail" :class="{ 'right_collumn': shouldHighlightRow(index) }">
									<p><label>Contact {{ index + 1 }} First Name</label> <span><input
												type="text" class="form-control w-300"
												v-model="item.first_name"></span></p>
									<p><label>Contact {{ index + 1 }} Last Name</label> <span><input type="text"
												class="form-control w-300" v-model="item.last_name"></span></p>
									<p><label>Contact {{ index + 1 }} Purpose</label> <span>
											<select class="form-control" v-model="item.contact_purpose"
												:value="item.contact_purpose">
												<option value="">Select Purpose</option>
												<option :value="item.value" v-for="(item,index) in purposeList"
													:key="index">{{ item.title }}</option>
											</select>
										</span></p>
									<p><label>Contact {{ index + 1 }} Relationship</label> <span>
											<select class="form-control" v-model="item.relationship"
												:value="item.relationship">
												<option value="">Select Relationship</option>
												<option :value="item.value"
													v-for="(item,index) in relationshipList" :key="index">{{
													item.title }}</option>
											</select>
										</span></p>
									<p><label>Contact {{ index + 1 }} Cell Phone</label> <span><input
												type="text" class="form-control "
												v-model="item.cell_phone"></span></p>
									<p><label>Contact {{ index + 1 }} Home Phone</label> <span><input
												type="text" class="form-control "
												v-model="item.residence_phone"></span></p>
									<div class="two_inputs">
										<p><label>Contact {{ index +1 }} Work Phone</label> <span><input
													type="text" class="form-control "
													v-model="item.work_phone"></span></p>
										<p><label>Ext</label><span><input type="text"
													class="form-control w-100p"
													v-model="item.work_phone_ext"></span></p>
									</div>
									<p><label>Contact {{ index + 1 }} Email</label> <span><input type="text"
												class="form-control " v-model="item.email"></span></p>
									<p><label>Contact {{ index + 1 }} Note</label> <span><textarea
												class="form-control " v-model="item.contact_note"
												rows="1"></textarea></span></p>
								</div>
							</div>
						</div>
					</slot>
				</div>
				<div class="row">
					<div class="col-6">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">ADDITIONAL DETAILS</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Name Suffix</label> <span>
										<select class="form-control" v-model="form.suffix">
											<option value="">Select Suffix</option>
											<option :value="item.value" v-for="(item,index) in suffix_option"
												:key="index">{{ item.title }}</option>
										</select>
									</span></p>
								<p><label>Chart ID<small class="asterisksign_blue m-0">*</small></label> <span><input type="text" class="form-control"
											v-model="form.chart_id"></span></p>
								<p><label>Spoken Language</label>
									<span>
										<CustomDropDown :options="spoken_lang_options"
											:initialValue="form.spoken_language_text" v-model="form.spoken_language"
											@item-selected="handleItemSelected" fieldName="spoken_language">
										</CustomDropDown>
										<!-- <select class="form-control" v-model="form.spoken_language" :value="form.spoken_language">
													<option value="">Select Primary Physician</option>
														<option :value="item.value" v-for="(item,index) in spoken_lang_options" :key="index">{{ item.title }}</option>
												</select> -->
									</span>
								</p>
							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="box box-bordered ">
							<div class="box-header with-border opacity-0">
								<h4 class="box-title">ADDITIONAL DETAILS</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Referred Physician Last Name </label> <span><input type="text"
											class="form-control " v-model="form.referred_physician_last_name"></span></p>
								<p><label>Referred Physician First Name </label> <span><input type="text"
											class="form-control " v-model="form.referred_physician_first_name"></span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import { mapState } from 'vuex';
	import Sidebar from './Sidebar.vue';
	import axios from "@/scripts/axios.js";
	import useVuelidate from "@vuelidate/core";
	import $ from "jquery";
	import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
	import CustomDropDown from '../base/formFields/CustomDropDown.vue';
	import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';

	export default {
		data() {
			return {
				inputWidth: 0,
				altContactInfo: [],
				gender_option: [
					{ value: "M", title: "Male" },
					{ value: "F", title: "Female" },
					{ value: "T", title: "Transgendered" },
					{ value: "O", title: "Other" },
					{ value: "U", title: "Undefined" }
				],
				spoken_lang_options: [
					{ value: "ENG", title: "English" },
					{ value: "FRE", title: "French" },
					{ value: "OTH", title: "Other" },
				],
				status_option: [
					{ value: "AC", title: "Active" },
					{ value: "IN", title: "Inactive" },
					{ value: "DE", title: "Deceased" },
				],
				mrpProviderDataSource: [],
				insuranceProviderDataSource: [],
				roster_status_options: [
					{ value: "RO", title: "RO-Rostered" },
					{ value: "NR", title: "NR-Not Rostered" }
				],
				termination_reason_option: [],
				country_options: [],
				purposeList: [{
					'value': 'SDM',
					'title': 'Substitute Decision Maker',
				},
				{
					'value': 'EC',
					'title': 'Emergency Contact',
				}
				],
				relationshipList: [
					{ 'value': 'Father', 'title': 'Father' },
					{ 'value': 'Mother', 'title': 'Mother' },
					{ 'value': 'Parent', 'title': 'Parent' },
					{ 'value': 'Husband', 'title': 'Husband' },
					{ 'value': 'Wife', 'title': 'Wife' },
					{ 'value': 'Partner', 'title': 'Partner' },
					{ 'value': 'Son', 'title': 'Son' },
					{ 'value': 'Daughter', 'title': 'Daughter' },
					{ 'value': 'Brother', 'title': 'Brother' },
					{ 'value': 'Sister', 'title': 'Sister' },
					{ 'value': 'Uncle', 'title': 'Uncle' },
					{ 'value': 'Aunt', 'title': 'Aunt' },
					{ 'value': 'GrandFather', 'title': 'GrandFather' },
					{ 'value': 'GrandMother', 'title': 'GrandMother' },
					{ 'value': 'Guardian', 'title': 'Guardian' },
					{ 'value': 'Foster Parent', 'title': 'Foster Parent' },
					{ 'value': 'Next of Kin', 'title': 'Next of Kin' },
					{ 'value': 'Administrative Staff', 'title': 'Administrative Staff' },
					{ 'value': 'Care Giver ', 'title': 'Care Giver' },
					{ 'value': 'Power Of Attorney ', 'title': 'Power Of Attorney' },
					{ 'value': 'Insurance', 'title': 'Insurance' },
					{ 'value': 'Guarantor', 'title': 'Guarantor' },
					{ 'value': 'Other', 'title': 'Other' },
				],
				title_option: [
					{ value: "Bro", title: "Bro" },
					{ value: "Capt", title: "Capt" },
					{ value: "Chief", title: "Chief" },
					{ value: "Cst", title: "Cst" },
					{ value: "Corp", title: "Corp" },
					{ value: "Dr", title: "Dr" },
					{ value: "Fr", title: "Fr" },
					{ value: "Hon", title: "Hon" },
					{ value: "Lt", title: "Lt" },
					{ value: "Madam", title: "Madam" },
					{ value: "Mme", title: "Mme" },
					{ value: "Mlle", title: "Mlle" },
					{ value: "Major", title: "Major" },
					{ value: "Mayor", title: "Mayor" },
					{ value: "Miss", title: "Miss" },
					{ value: "Mr", title: "Mr" },
					{ value: "Mssr", title: "Mssr" },
					{ value: "Mrs", title: "Mrs" },
					{ value: "Ms", title: "Ms" },
					{ value: "Prof", title: "Prof" },
					{ value: "Reeve", title: "Reeve" },
					{ value: "Rev", title: "Rev" },
					{ value: "Rthon", title: "Rthon" },
					{ value: "Sen", title: "Sen" },
					{ value: "Sgt", title: "Sgt" },
					{ value: "Sr", title: "Sr" },
				],
				selectedValue: null,
				currentDate: new Date(),
				showGenderList: false,
				suffix_option: [
					{ value: "Jr", title: "Jr" },
					{ value: "Sr", title: "Sr" },
					{ value: "II", title: "II" },
					{ value: "III", title: "III" },
					{ value: "IV", title: "IV" },
				],
				showTitleList: false,
				residential_state_options: [],
				mailing_state_options: [],
				stateKey:0,
				stateMailingKey:0,
				contact_purpose_text:"Select Purpose"
			}
		},
		setup: () => {
			return { v$: useVuelidate() };
		},
		components: {
			Sidebar,
			'ejs-datepicker': DatePickerComponent,
			CustomDropDown,
			ValidationErrorMessageList
		},
		async created() {
			this.$store.state.loader = true;
			this.fetchProviderData();
			this.fetchInsuranceType();
			this.fetchTerminationReasonData();
			this.fetchCountryData();
			if (this.$route.name === "PatientEdit") {
				this.getOtherContact()
				this.edit();
			} else {
				this.$store.state.loader = false;
			}
		},
		computed: {
			...mapState(['patientId']),
			form() {
				return this.$store.state.patient.form;
			},
			vuelidateExternalResults() {
				return this.$store.state.patient.vuelidateExternalResults;
			},
			btnname() {
				return this.$route.name === "PatientEdit" ? "Update" : "Create";
			},
			title() {
				return this.$route.name === "PatientEdit" ? "Edit" : "Create";
			},
			displaySidebar() {
				return this.$route.name === "PatientEdit" ? true : false;
			}
		},
		validations() {
			return this.$store.state.patient.validationRules;
		},
		mounted() {
			this.calculateAge();
			if (this.$route.name !== "PatientEdit") {
				this.resetforms();
			} else {
				this.updateSelectWidth();
			}
			document.addEventListener('click', this.handleClickOutside);
		},
		watch: {
			selectedValue() {
				// Watch for changes in the selected value and update the select width
				this.updateSelectWidth();
			},
			dob() {
				// Trigger the age calculation whenever the date of birth changes
				this.calculateAge();
			}
		},
		methods: {
			async edit() {
				if (this.$route.name === "PatientEdit") {
					axios.post("patient/retrieve", { 'id': this.$route.params.patient_id })
						.then((response) => {
							if (response.data.status === 200) {
								let detail = response.data.data;
								detail.gender_text = (this.gender_option.find(item => item.value === detail.gender) || {}).title || "Select Gender";
								detail.title_text = (this.title_option.find(item => item.value === detail.title) || {}).title || "Select Title";
								detail.heath_card_province_text = (this.insuranceProviderDataSource.find(item => item.value === detail.health_card_province_id) || {}).title || "Select Title";
								detail.roster_status_text = (this.roster_status_options.find(item => item.value === detail.roster_status) || {}).title || "Select Roster Status";

								detail.patient_status_text = (this.status_option.find(item => item.value === detail.status) || {}).title || "Select Patient Status Date";
								detail.physician_text = (this.mrpProviderDataSource.find(item => item.value === detail.provider_id) || {}).title || "Select Primary Physician";
								detail.spoken_language_text = (this.spoken_lang_options.find(item => item.value === detail.spoken_language) || {}).title || "Select Spoken Language";
								detail.enrolment_status_text = (this.termination_reason_option.find(item => item.value === detail.enrolment_status) || {}).title || "Select Roster Termination Reason";

								this.calculateAge();

								if (detail.residential_country) {
									detail.residential_country_text = (this.country_options.find(item => item.value == detail.residential_country) || {}).title || "Select Residential Country";
									this.getResidentStateData(detail.residential_country)
									// if ( detail.residential_state && this.getResidentStateData) {
									// 	detail.residential_state_text = (this.residential_state_options.find(item => item.value == detail.residential_state) || {}).title || "Select Residential Province";
									// }
								}
								if (detail.mailing_country) {
									detail.mailing_country_text = (this.country_options.find(item => item.value == detail.mailing_country) || {}).title || "Select Mailing Country";
									this.getMailingStateData(detail.mailing_country)

									// if ( detail.mailing_state) {
									// 	detail.mailing_state_text = (this.mailing_state_options.find(item => item.value == detail.mailing_state)).title || "Select Mailing Province";
									// }

								}

								if (detail.alternative_contact_detail.length == 0) {
									detail.alternative_contact_detail = this.$store.state.patient.defaultFormData.alternative_contact_detail
								} else if (detail.alternative_contact_detail.length == 1){
									detail.alternative_contact_detail[1] = { "first_name":""}
								}

								this.$store.state.patient.form = detail;

								this.$store.state.loader = false;

							} else {
								this.$filters.moshaToast(response.data.message, "error")
								this.$store.state.loader = false;
							}
						})
						.catch(error => {
							this.$filters.moshaToast(error.message, "error")
							this.$store.state.loader = false;
						});
				}
			},
			calculateAge() {
				let dob = this.form.dob
				if (dob) {
					const birthDate = new Date(dob);
					const currentDate = new Date();

					let years = currentDate.getFullYear() - birthDate.getFullYear();
					this.form.age = `${years}`;
					this.formatDate('dob');
					return true
					// let months = currentDate.getMonth() - birthDate.getMonth();
					// let days = currentDate.getDate() - birthDate.getDate();

					// // Adjust age if birthday hasn't occurred yet this month
					// if (currentDate.getDate() < birthDate.getDate()) {
					// 	months--;

					// 	// Adjust months to be positive
					// 	if (months < 0) {
					// 		years--;
					// 		months += 12;
					// 	}
					// }

					// let cal_years = years > 1 ? `${years} years` : years === 1 ? '1 year' : '';
					// let cal_months = months > 1 ? `${months} months` : months === 1 ? '1 month' : '';
					// let cal_days = days > 1 ? `${days} days` : days === 1 ? '1 day' : '';

					// // if (years > 0) {
					// // 	cal_months = '';
					// // 	cal_days = '';
					// // } else if (months > 0) {
					// // 	cal_days = '';
					// // }
					// if (cal_years == "" && cal_months == "" && cal_days == ""){
					// 	// console.log(new Date())
					// 	// console.log(birthDate)
					// 	let isToday =
					// 	birthDate.getDate() === currentDate.getDate() &&
					// 		birthDate.getMonth() === currentDate.getMonth() &&
					// 		birthDate.getFullYear() === currentDate.getFullYear();
					// 		if (isToday) {
					// 			this.form.age = "0 day";
					// 	}
					// }


					// this.form.age = `${cal_years} ${cal_months} ${cal_days}`;
					// return true
				}
				this.form.age = 0;
			},
			getOtherContact() {
				axios.post(`/patient/alternative-contact/list`, { 'patient_id': this.$route.params.patient_id })
					.then((response) => {
						if (response.data.status === 200) {
							this.altContactInfo = response.data.data
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						this.$filters.moshaToast(error.message, "error");
					});
			},
			createContact() {
				const url = this.$router.resolve({
					path: `/patient/alter-native-contact/${this.$route.params.patient_id}/create`,
					// query: { target: '_blank' }
				}).href;

				window.open(url, '_blank');
				// this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}/create`);   
			},
			editContact(id) {
				// this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}/retrieve/${id}`);   
				const url = this.$router.resolve({
					path: `/patient/alter-native-contact/${this.$route.params.patient_id}/retrieve/${id}`,
					// query: { target: '_blank' }
				}).href;

				window.open(url, '_blank');
			},
			contactList() {
				const url = this.$router.resolve({
					path: `/patient/alter-native-contact/${this.$route.params.patient_id}`,
					// query: { target: '_blank' }
				}).href;

				window.open(url, '_blank');
				// this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}`);   
			},
			enrollmentHistory() {
				const url = this.$router.resolve({
					path: `/patient/enrollment-history/${this.$route.params.patient_id}`,
					// query: { target: '_blank' }
				}).href;

				window.open(url, '_blank');
				// this.$router.push(`/patient/enrollment-history/${this.$route.params.patient_id}`);   
			},
			dateFormat(date) {
				if (date == '' || date == null) {
					return ''
				}
				const dateObject = new Date(date);
				const options = { year: 'numeric', month: 'long', day: 'numeric' };

				// Adjust the options based on your desired date format
				return dateObject.toLocaleDateString('en-US', options);
			},
			async getResidentStateData(countryId) {
				await this.getStateData(countryId)
			},
			async getMailingStateData(countryId) {
				await this.getStateData(countryId, 'mailing')
			},
			async getStateData(countryId, type = 'resident') {
				const countryTempData = []
				if (countryId == '') {
					if (type == 'mailing') {
						this.mailing_state_options = countryTempData
					} else {
						this.residential_state_options = countryTempData
					}
					return false
				}
				axios.post("json_autocomplete/" + countryId + "/state", { 'countryId': countryId })
					.then((response) => {
						const details = response.data.data;
						$.each(details, function (key, detail) {
							countryTempData.push({
								value: detail['id'],
								title: detail['name']
							})
						})
						if (type == 'mailing') {
							this.mailing_state_options = countryTempData
						} else {
							this.residential_state_options = countryTempData
						}
					})
			},
			fetchProviderData() {
				var $this = this
				axios.post("json_list/provider", { 'roleId': '' })
					.then((response) => {
						const providerTempData = [];
						const details = response.data.data;
						$.each(details, function (key, detail) {
							if (!($this.$route.name == "PatientCreate" && detail.deleted_datetime)) {
								providerTempData.push({
									value: detail.user_id,
									title: detail.full_name,
								})
							}
						})
						this.mrpProviderDataSource = providerTempData
					})
			},
			getAltContactClass(index) {
				return index % 2 === 0 ? 'me-1' : '';
			},
			fetchInsuranceType() {
				axios.get("json_autocomplete/get-health-insurance-type")
					.then((response) => {
						const insuranceProviderTempData = []
						const details = response.data.data;
						$.each(details, function (key, detail) {
							insuranceProviderTempData.push({
								value: detail['id'],
								title: detail['type']
							})
						})
						this.insuranceProviderDataSource = insuranceProviderTempData;
					})

			},
			fetchTerminationReasonData() {
				axios.get("json_autocomplete/get-termination-reason")
					.then((response) => {
						const reasonTempData = []
						const details = response.data.data;
						$.each(details, function (key, detail) {
							reasonTempData.push({
								value: detail['id'],
								title: detail['reason_name']
							})
						})
						this.termination_reason_option = reasonTempData;
					})
			},
			fetchCountryData() {
				const countryTempData = []
				axios.post("json_autocomplete/country")
					.then((response) => {
						const details = response.data.data;
						$.each(details, function (key, detail) {
							countryTempData.push({
								value: detail['id'],
								title: detail['name']
							})
						})
						this.country_options = countryTempData
					})
			},
			resetforms() {
				this.$store.state.patient.form = {}
				Object.assign(
					this.$store.state.patient.form,
					this.$store.state.patient.defaultFormData
				);
			},
			submit() {
				this.v$.$validate();
				const $this = this
				if (!this.v$.$error) {
					this.loader = true
					const form = this.form;
					let url
					if (this.$route.name === "PatientEdit") {
						url = "patient/update";
					} else {
						url = "patient/store";
					}

					axios.post(url, form)
						.then((response) => {
							if (response.status == 200) {
								this.loader = false
								setTimeout(function () {
									$this.$filters.moshaToast(response.data.message, "success")
									$this.$router.push(
										`/patient/chart/` + response.data.data.id
									);
								}, 400);
							} else {
								this.loader = false
								this.$filters.moshaToast(response.data.message, "error")
							}
						})
				} else {
					for (const [key] of Object.entries(this.v$.form)) {
						if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
			},
			cancel() {
				if (this.$route.name === "PatientEdit") {
					this.$router.push(
						`/patient/chart/${this.$route.params.patient_id}`
					);
				} else {
					this.$router.push(
						`/`
					);
				}
			},
			shouldHighlightRow(index) {
				// Return true for rows with indices 1, 3, 5, 7, ...
				return (index % 2 !== 0);
			},
			updateInputWidth(event) {
				this.inputWidth = event.target.value.length * 10; // Adjust multiplier as needed
			},
			updateSelectWidth() {
				const selectElement = this.$refs.customSelect;
				if (selectElement) {
					const selectedText = selectElement.selectedOptions[0] ? selectElement.selectedOptions[0].text : 'Select Title';
					console.log(selectedText)
					selectElement.style.width = `${this.getTextWidth(selectedText, selectElement) + 21}px`;
				}
			},

			getTextWidth(text, referenceElement) {
				// Helper function to calculate the width of the text using the reference element's font properties
				const tempElement = document.createElement('span');
				tempElement.style.font = window.getComputedStyle(referenceElement).font;
				tempElement.textContent = text;
				document.body.appendChild(tempElement);

				const width = tempElement.getBoundingClientRect().width;

				// Remove the temporary element from the DOM
				document.body.removeChild(tempElement);

				return width;
			},
			handleClickOutside(event) {
				// const dropdownElement = this.$el;
				// console.log(dropdownElement)
				// if (dropdownElement && !dropdownElement.contains(event.target)) {
				// 	alert("here")
				// 	this.showTitleList  = false;
				// 	this.showGenderList = false;
				// }

				for (let i = 0; i < this.form.length; i++) {
					const dropdownElement = this.$refs[`dropdown${i}`];
					if (dropdownElement && !dropdownElement.contains(event.target)) {
						this.$set(this.forms, i, {
							...this.forms[i],
							showTitleList: false,
						});
					}
				}

			},
			onFieldChange(fieldName) {
				this.formatDate(fieldName);
			},
			formatDate(fieldName) {
				const dateObject = new Date(this.form[fieldName]);
				const year = dateObject.getFullYear();
				const month = String(dateObject.getMonth() + 1).padStart(2, '0');
				const day = String(dateObject.getDate()).padStart(2, '0');
				this.form[fieldName] = `${year}-${month}-${day}`;
			},
			handleItemSelected({ fieldName, item }) {
				console.log(fieldName)
				// Update the form field with the selected item
				this.form[fieldName] = item.value;

				if (fieldName == 'residential_country') {
					this.form.residential_state_text = 'Select Residential Province'
					this.form.residential_state =''
					this.getResidentStateData(this.form.residential_country);
					this.stateKey++
				}
				if (fieldName == 'mailing_country') {
					this.form.mailing_state_text = 'Select Mailing Province'
					this.form.mailing_state =''
					this.getMailingStateData(this.form.mailing_country);
					this.stateMailingKey++
				}
				
			},

		},
		beforeCreate() {
		},
	}
</script>