<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 px-0">
			<div class="auditLogs-main-list">
				<div class="audit-head-box d-inline-block w-100 mb-15">
					<div class="range_calc blg-date-select d-inline-block float-start mr-15">
						<div class="form-group syncfusion-component">
							<ejs-daterangepicker id="daterangepicker" :placeholder="waterMarkText" :format="dateFormat" :openOnFocus="true" @select="changeDateRangePicker" @change="changeDatePicker" :htmlAttributes="htmlAttributes" :showCustomRangeLabel=false  >
								<e-presets>
									<e-preset label="Today" :start='today' :end='today'></e-preset>
									<e-preset label="Yesterday" :start='yesterday' :end='yesterday'></e-preset>
									<e-preset label="This Week" :start='last7days' :end='today'></e-preset>
									<e-preset label="Last 7 days" :start='last7days' :end='today'></e-preset>
									<e-preset label="Last 30 days" :start='last30days' :end='today'></e-preset>
									<e-preset label="Last 90 days" :start='last30days' :end='today'></e-preset>
									<e-preset label="Last 180 days" :start='last30days' :end='today'></e-preset>
									<e-preset label="This Month" :start='thisMonth' :end='today'></e-preset>
									<e-preset label="Last Month" :start='lastStart' :end='lastEnd'></e-preset>
									<e-preset label="All Time" :start='lastStart' :end='lastEnd'></e-preset>
								</e-presets>
							</ejs-daterangepicker>
						</div>
					</div>
					<div class="audit-prvd-select d-inline-block float-start mr-15">
						<CustomDropDown :options="insuranceProviderDataSource"
							:initialValue="this.heath_card_province_text"
							@item-selected="handleItemSelected"
							fieldName="health_card_province_id"></CustomDropDown>
					</div>
					<div class="audit-prvd-select d-inline-block float-start mr-15">
						<CustomDropDown :options="insuranceProviderDataSource"
							:initialValue="this.heath_card_province_text"
							@item-selected="handleItemSelected"
							fieldName="health_card_province_id"></CustomDropDown>
					</div>
					<div class="audit-prvd-select d-inline-block float-start">
						<CustomDropDown :options="insuranceProviderDataSource"
							:initialValue="this.heath_card_province_text"
							@item-selected="handleItemSelected"
							fieldName="health_card_province_id"></CustomDropDown>
					</div>
				</div>

				<div class="table-responsive auditLogs-table">
					<table class="table table-borderless cursor-alias mb-0">
						<thead>
							<tr>
								<th>DATETIME</th>
								<th>USER</th>
								<th>MODULE</th>
								<th>ACTION TYPE</th>
								<th>DEMOGRAPHIC</th>
								<th style="width: 520px;">ACTION DETAILS</th>
								<th style="width:45px;"></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="white-space-pre">2024-02-05 01:55:15</td>
								<td>joshua_xi</td>
								<td>Demographic</td>
								<td>CREATE</td>
								<td>Bob Vladmir (1999-01-01)</td>
								<td><span class="max-demographic-cont">New Demographic</span></td>
								<td><img src="/images/dots-icon.svg" class="dot_icon" @click="viewLog"></td>
							</tr>
							<tr>
								<td class="white-space-pre">2024-02-05 01:58:32</td>
								<td>joshua_xi</td>
								<td>Demographic</td>
								<td>CREATE</td>
								<td>Bob Vladmir (1999-01-01)</td>
								<td><span class="max-demographic-cont">Change first_name from “Judith” to “Bob”, gender from “M” to “F”, date_of_b...</span></td>
								<td><img src="/images/dots-icon.svg" class="dot_icon" @click="viewLog"></td>
							</tr>
							<tr>
								<td>2024-02-05 04:13:01</td>
								<td>jm_hong</td>
								<td>Encounter Note</td>
								<td>CREATE</td>
								<td>Bob Vladmir (1999-01-01)</td>
								<td><span class="max-demographic-cont">New Encounter Note</span></td>
								<td><img src="/images/dots-icon.svg" class="dot_icon" @click="viewLog"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade main_popup" id="view-auditLogs" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl view-auditLogs-model">
			<div class="modal-content manage_cmn_pup">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div>
							<ul class="audit-view-list mb-0 p-0">
								<li class="cursor-alias"><span>DATETIME</span> <p class="d-inline-block mb-0">2024-02-05 01:55:15</p></li>
								<li class="cursor-alias"><span>USER</span> <p class="d-inline-block mb-0">joshua_xi</p></li>
								<li class="cursor-alias"><span>MODULE</span> <p class="d-inline-block mb-0">Demographic</p></li>
								<li class="cursor-alias"><span>ACTION TYPE</span> <p class="d-inline-block mb-0">CREATE</p></li>
								<li class="cursor-alias"><span>DEMOGRAPHIC</span> <p class="d-inline-block mb-0">Bob Vladmir (1999-01-01)</p></li>
								<li class="cursor-alias"><span>ACTION DETAILS</span> <p class="d-inline-block mb-0">Change first_name from “Judith to “Bob”, gender from “M” to “F”, date_of_brith from “1999-08-22” to “1999-01-01”</p></li>
								<li class="cursor-alias"><span>BEFORE LOG</span> <p class="d-inline-block mb-0">{“first_name”: “Judith”, “gender”: “M”, “date_of_birth”: “1999-08-22”}</p></li>
								<li class="cursor-alias"><span>AFTER LOG</span> <p class="d-inline-block mb-0">{“first_name”: “Bob”, “gender”: “F”, “date_of_birth”: “1999-01-01”}</p></li>
								<li class="cursor-alias"><span>IP ADDRESS</span> <p class="d-inline-block mb-0">192.168.2.155</p></li>
							</ul>
						</div>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn" @click="cancelModel">Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import sidebar from './sidebar.vue';
	import { DateRangePickerComponent } from '@syncfusion/ej2-vue-calendars';
	import moment from "moment";
	import $ from "jquery";
	import axios from "@/scripts/axios.js";
	import CustomDropDown from '../base/formFields/CustomDropDown.vue';


	export default {
		data() {
			return {
				waterMarkText: "Select a Range",
				dateFormat: "yyyy/MM/dd",//MM-dd-yyyy
				htmlAttributes: { showCustomRangeLabel: true },
				today: new Date(new Date().toDateString()),
				yesterday: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()),
				last7days: new Date(new Date(new Date().setDate(new Date().getDate() - 7)).toDateString()),
				weekStart: new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString()),
				weekEnd: new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
					- (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()),
				last30days: new Date(new Date(new Date().setDate(new Date().getDate() - 30)).toDateString()),
				lastStart: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
				lastEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
				thisMonth: new Date(moment().subtract(1, 'month').startOf('month')),
				minDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
				staticstic_data:[],
				filter:{
					date_range:null
				},
				insuranceProviderDataSource:[
					{ value: "RO", title: "Doctor | ZAED ABURI MOHAMMED" },
					{ value: "NR", title: "Doctor | HONG JAMES" },
					{ value: "NR", title: "Staff | XI JOSHUA" }
				],
				heath_card_province_text:"Select Users"
			}
		},
		components: {
			sidebar,
			'ejs-daterangepicker': DateRangePickerComponent,
			CustomDropDown,
		},
		methods: {
			changeDatePicker() {
				this.getdate();
			},
			getdate() {
				this.filter.date_range = $("#daterangepicker").val();
				this.fetchStaticsData()
			},
			changeDateRangePicker() {
				this.getdate();
			},
			fetchStaticsData(){
				this.$store.state.loader = true;
				axios.post('invoice/fetch-dashboard-data', this.filter)
                .then((response) => {
					this.$store.state.loader = false;
                    if (response.status == 200) {
						this.staticstic_data = response.data.data
					} else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
					this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
			},
			formatCurrency(value) {
				return value.toFixed(2);
			},
			viewLog(){
				window.$("#view-auditLogs").modal("show")
			},
			cancelModel(){
				window.$("#view-auditLogs").modal("hide")
			}
		},
		mounted() {
			this.fetchStaticsData();

		},


	};
</script>
