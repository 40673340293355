import {
	helpers,
	required,
	// email,
} from "@vuelidate/validators";
const billing = {
    namespaced: true,

    state: {

		list: [],
		defaultFormData: {
			id: null,
			provider_id:null,
			patient_id:null,
			number_of_services:null,
			service_date: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
			status:null,
            type:'Ontario',
			province_code_text:"ON - Ontario",
			claim_type_text:"HCP - Health Claims Program",
			claim_payee_text:"P - Provider",
			residential_state:'ON',
			claim_type:'HCP',
			visit_location:'0000 – No Location',
			service_location_text:"Select Service location Indicators",
			health_insurance_no:null,
			health_card_ver:null,
			rmb_patient_last_name:null,
			rmb_patient_first_name:null,
			dob:null,
			rmb_patient_sex:null,
			referral_hcp_number:null,
			rmb_registration_number:null,
			inpatient_admission_date:null,
			referring_lab_license_number:null,
			group_number_override:null,
			specialty_override:null,
			master_number:null,
			manual_review:false,
			service_location:'0000 – No Location',
			claim_payee: 'P - Provider',
			grandTotal:null,
			province_code:'ON'
		},
		form: {
			provider_ids:[],
            id: null,
			provider_id:null,
			patient_id:null,
			number_of_services:null,
			service_date: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
			status:null,
            type:'Ontario',
			claim_type:'',
			claim_type_text:"HCP - Health Claims Program",
			claim_payee_text:"P - Provider",
			province_code_text:'ON - Ontario',
			visit_location:'0000 – No Location',
			service_location_text:"Select Service location Indicators",
			service_location:'0000 – No Location',
			claim_payee: 'P - Provider',
			grandTotal:null,
			province_code:'ON'
		},
		itemForm:{
			'service_code':null,
			'number_of_service':1,
			'discount':null,
			'fee_submitted':null,
			'dx_code':null,
			'service_date': new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
			fee_submitted_format:null,
			service_code_description:null
		},
		defaultItemForm:{
			'service_code':null,
			'number_of_service':1,
			'discount':null,
			'fee_submitted':null,
			'dx_code':null,
			'service_date': new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
			fee_submitted_format:null,
			service_code_description:null
		},
		validationRules: {
			form: {
				provider_id: {
					required: helpers.withMessage("Please select provider.", required),
				},
				patient_id:{
					required: helpers.withMessage("Please select patient.", required),
				},
				type:{
					required: helpers.withMessage("Please select invoice type.", required),
				},
				claim_type:{
					required: helpers.withMessage("Please select claim type.", required),
				},
				claim_payee:{
					required: helpers.withMessage("Please select claim payee.", required),
				},

			},
		},
		filter:{

		}
    },
    getters: {},
	mutations: {},
	actions: {},

};

export default billing;