<template>
   <div class="row">
        <div class="col-12">
            <div class="box">
                <div class="box-header">
                    <h4 class="box-title">Type options</h4>  
                </div>

                <div class="box-body">
                    <SelectBoxStatic  divClass="col-4" title="Contact Relationship" :option="relationshipList" v-model="form.relationship" :isRequired="true" :errors="v$.form.relationship.$errors"></SelectBoxStatic>
                    <InputField title="Contact  First Name" divClass="col-4" :isRequired="true" v-model="form.first_name" :errors="v$.form.first_name.$errors"> </InputField>
                    <InputField title="Contact Last Name" divClass="col-4" :isRequired="true" v-model="form.last_name" :errors="v$.form.last_name.$errors"> </InputField>
                    <SelectBoxStatic  divClass="col-4" title="Contact Purpose" v-model="form.contact_purpose" :option="purposeList" :isRequired="true" :errors="v$.form.contact_purpose.$errors"></SelectBoxStatic>
                    <InputField title="Contact Residence Phone" divClass="col-4" v-model="form.residence_phone"> </InputField>
                    <InputField title="Contact Cell Phone" divClass="col-4" v-model="form.cell_phone" :isRequired="true" :errors="v$.form.cell_phone.$errors"> </InputField>
                    <InputField title="Contact Work Phone" divClass="col-4" v-model="form.work_phone"> </InputField>
                    <InputField title="Contact Work Phone Extension" divClass="col-4" v-model="form.work_phone_ext"> </InputField>
                    <InputField title="Contact E-Mail Address" divClass="col-4" v-model="form.email" :errors="v$.form.email.$errors"> </InputField>
                    <InputField title="Contact Note" divClass="col-4" v-model="form.contact_note"> </InputField>


                    <div class="row">
                        <button class="btn btn-sm btn-primary text-white me-1 col-1"  @click="save">
                            {{this.saveBtnText}}
                            <img src="images/loader.gif" v-if="loader" />
                        </button>
                    </div>

                </div>
            </div>
        </div>
   </div>
</template>

<script>
import InputField from "../../base/formFields/InputField.vue"
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
	data() {
		return {
            saveBtnText:'Save',
            purposeList:[{
                'value':'SD',
                'title':'Substitute Decision Maker',
            },
            {
                'value':'EC',
                'title':'Emergency Contact',
            }
            ],
            relationshipList:[
                {'value':'Father','title':'Father'},
                {'value':'Mother','title':'Mother'},
                {'value':'Parent','title':'Parent'},
                {'value':'Husband','title':'Husband'},
                {'value':'Wife','title':'Wife'},
                {'value':'Partner','title':'Partner'},
                {'value':'Son','title':'Son'},
                {'value':'Daughter','title':'Daughter'},
                {'value':'Brother','title':'Brother'},
                {'value':'Sister','title':'Sister'},
                {'value':'Uncle','title':'Uncle'},
                {'value':'Aunt','title':'Aunt'},
                {'value':'GrandFather','title':'GrandFather'},
                {'value':'GrandMother','title':'GrandMother'},
                {'value':'Guardian','title':'Guardian'},
                {'value':'Foster Parent','title':'Foster Parent'},
                {'value':'Next of Kin','title':'Next of Kin'},
                {'value':'Administrative Staff','title':'Administrative Staff'},
                {'value':'Care Giver ','title':'Care Giver'},
                {'value':'Power Of Attorney ','title':'Power Of Attorney'},
                {'value':'Insurance','title':'Insurance'},
                {'value':'Guarantor','title':'Guarantor'},
                {'value':'Other','title':'Other'},
            ]
        }
    },
    components: {
        InputField,
        SelectBoxStatic
    },
    computed: {
		form() {
			return this.$store.state.alt_contact.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.alt_contact.vuelidateExternalResults;
		},
    },
    validations() {
		return this.$store.state.alt_contact.validationRules;
	},
    mounted(){
        if (this.$route.params.id) {
            this.saveBtnText='Update';
            this.edit();
        } else {
            this.resetforms()
        }
    },
    methods:{
        save() {
            const formName = 'form';
			this.v$.$validate();
			if (!this.v$.$error) {
                const $this = this
                this.loader = true;

                let url = "/patient/alternative-contact/store";
                if (this.$route.params.id) {
                    url = "/patient/alternative-contact/update";
                }

                this.form.patient_id = this.$route.params.patient_id;
                if (this.$route.params.id) {
                    this.form.id = this.$route.params.id;
                }
                axios.post(url, this.form)
				.then((response) => {
					if (response.status == 200) {
                        this.loader = false;
                        this.$filters.moshaToast(response.data.message, "success");
                        $this.$router.push(
                                `/patient/alter-native-contact/${this.$route.params.patient_id}`
                            );
                        window.opener.postMessage("Alt contact is updated", "*");
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }else {
                for (const [key] of Object.entries(this.v$[formName])) {
                    if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                        const myEl = document.getElementById(key);
                        if (myEl) {
                            this.$smoothScroll({
                                scrollTo: myEl,
                            });
                            break;
                        }
                    }
                }
            }
        },
        async edit() {
			axios.post(`/patient/alternative-contact/retrieve`, { 'id': this.$route.params.id , 'patient_id': this.$route.params.patient_id})
				.then((response) => {
					if (response.data.status === 200) {
						this.$store.state.alt_contact.form = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$filters.moshaToast(error.message, "error");
				});
		},
        resetforms() {
			Object.assign(
				this.$store.state.alt_contact.form,
				this.$store.state.alt_contact.defaultFormData
			);
		},
    }
};
</script>
