<template>
	<div class="content">

        <div class="row">
            <div class="box">
                <div class="box-body">
                    <div class="row">
                        <div class="col-10">
                            <h3 >User</h3> 
                        </div>

                        <div class="col-2" v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_acount_setting_crud_all])">
                             <router-link :to="{ name: 'CreateAdminUser'}" class="waves-effect waves-light btn btn-info d-block mb-10 btn-sm ">
                            <i class="fa fa-plus fs-14"></i> Create User
                            </router-link>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="box">
            <div class="box-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row justify-content-sm-left">

                            <InputField title="Provider Full Name" divClass="col-2" v-model="filter.provider_name"> </InputField>
                            <InputField title="Provider No." divClass="col-2" v-model="filter.provider_no"> </InputField>
                            <SelectBoxStatic divClass="col-2" title="Type"  :option="user_type_options" v-model="filter.roleId"></SelectBoxStatic>
                            <InputField title="Username" divClass="col-2" v-model="filter.username"> </InputField>

                            <div class="col-3">
                                <div class="form-group">
                                    <label class="form-label">Status</label>
                                    <div class="controls">
                                        <div class="syncfusion-component ">
                                        <ejs-multiselect id='multiselect' 
                                            v-model="filter.status"
                                            :dataSource="statusDataSource"
                                            :fields="fields"
                                            placeholder="Assign a user" ></ejs-multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                            <button
                                class="btn btn-sm btn-info col-md-1 me-1"
                                @click="applyFilter">
                                Search
                            </button>
                            <button
                                class="btn btn-sm btn-warning col-md-1"
                                @click="resetFilter">
                                Clear
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Datatable dataType="user" dataMethod="post" :key="key">
            <template v-slot="slotProps">
                <td>{{slotProps.item.provider_no}}</td>
                <td>{{slotProps.item.last_name}}</td>
                <td>{{slotProps.item.first_name}}</td>
                <td>{{slotProps.item.role}}</td>
                <td>{{slotProps.item.cpsid}}</td>
                <td>{{slotProps.item.billing_no}}</td>
                <td>{{slotProps.item.username}}</td>
                <td>{{slotProps.item.address}}</td>
                <td>
                    <button v-if="this.checkEditPermission(slotProps.item.user_id)" class="btn btn-primary btn-sm me-1" href="javascript::void(0)" @click="editUser(slotProps.item.user_id)" ><i class="fa fa-pencil"></i>&nbsp;Edit</button>
                    <button v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_acount_setting_crud_all])" class="btn btn-danger btn-sm me-1" href="javascript::void(0)" @click="deleteUser(slotProps.item.user_id)" ><i class="fa fa-trash"></i>&nbsp;Delete</button>
                    <button v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_acount_setting_crud_all])" class="btn btn-info btn-sm me-1" href="javascript::void(0)" @click="assinPermission(slotProps.item.user_id)" ><i class="fa fa-lock"></i>&nbsp;Assign Permission</button>

                </td>
            </template>
        </Datatable>
	</div>
</template>

<script>
import Datatable from '../../base/formFields/Datatable.vue';
import InputField from "../../base/formFields/InputField.vue"
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import axios from "@/scripts/axios.js";
import $ from "jquery";

export default {
    data() {
        return {
            key: 0,
            search_drop: true,
            user_type_options:[],
            statusDataSource: [{'text':"Active", 'value':'AC'},{'text':"InActive", 'value':'IN'}],
            fields:{ 'text': 'text', 'value': 'value' },
            userRole: localStorage.getItem('authUserRole'),
            userName: localStorage.getItem('userName'),
            authUserId: localStorage.getItem('userId'),
        }
    },
    components: {
        Datatable,
        InputField,
        SelectBoxStatic,
        'ejs-multiselect':MultiSelectComponent,

    },
    methods: {
        editUser(id){
            this.$router.push(`/administration/user/retrieve/${id}`);
        },
        applyFilter() {
            this.key++;
            this.search_drop = false;
        },
        resetFilter(){
            this.filter.provider_no = ''
            this.filter.role = ''
            this.filter.username=''
            this.filter.provider_name=''
            this.filter.status=''
            this.applyFilter()
        },
        deleteUser(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to revert this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "user/delete";
                    axios.post(url, {'id':id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.applyFilter()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                }
            })

        },
        assinPermission(id){
            this.$router.push(`/administration/user/permission/${id}`);
        },
        getRoleOption(){
            let params = {}
            let $this = this
            axios.post("admin/role/list", params)
				.then((response) => {
					if (response.data.status === 200) {
                         $.each(response.data.data, function(index, detail) {
                            $this.user_type_options.push({'value':detail.id, 'title':$this.formatRoleName(detail.name)})
                         })
					} else {
						// this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
        },
        formatRoleName(role){
            return role.charAt(0).toUpperCase() + role.slice(1)
        },
        checkEditPermission(userId){
            if (this.$filters.permissioncheck([this.$store.state.permission.admin_acount_setting_crud_all])) {
                return true
            } else if (userId == this.authUserId && this.$filters.permissioncheck([this.$store.state.permission.admin_acount_setting_crud])) {
                return true
            }

            return false
        }
    },
    computed: {
        filter(){
            return this.$store.state.user.filter;
        }
    },
    mounted() {
    },
    created(){
        this.getRoleOption()
    }

}
</script>