<template>
	<div class="PatientChart_header">
		<div class="row align-items-center">
			<div :class="headerClass">
				<span class="logo me-2">{{ headerTitle }}</span>
				<img src="/images/person-male-icon.svg" class="patient_icon ms-4" v-if="this.$route.name === 'PatientCreate'">
			</div>
			<div :class="headerSubClass">
				<div class="d-flex align-items-center " :class="justifyClass">
					<div class="detail_list"  v-if="this.isDemographicRoute()" @click="redirectMasterRecord"><img src="/images/person-acc-icon.svg" class="patient_icon"><label :class="{ 'text-decoration-underline': this.$route.name == 'PatientEChart'}">{{ $filters.strToUpperCase($store.state.patientHeaderData.last_name) }}, {{ $filters.strToUpperCase($store.state.patientHeaderData.first_name) }} </label><span class="ms-3"> {{ $store.state.patientHeaderData.dob }} ( {{ $filters.patientCalculatedAge($store.state.patientHeaderData.dob) }} )<label class="ms-3">{{ $store.state.patientHeaderData.gender }}</label></span></div>
					<div class="">
						<div class="search d-inline-block" v-if="this.$route.name == 'PatientEChart' || this.$route.name == 'PatientChart' || this.$route.name == 'PatientAppointmentHistory'">
							<img class="serch_icon" src="/images/search-icon.svg" alt="">
							<input type="text" @keyup="patientSearch" class="form-control" placeholder="Search Patient" v-model="patient_search" @blur="patientSearch" ref="globalSearch">
							<div class="search_drop_box" v-if="patientList.length > 0">
								<slot v-for="(item, index) in patientList" :key="index">
									<div class="con_drop_line" >
										<div class="row px-2 py-3 align-items-center">
											<div class="col-md-6">
												<div class="man_haeding">{{ item.last_name }}, {{ item.first_name }}
													<span>({{ item.gender }})</span>
												</div>
												<ul>
													<li>
														DOB: {{ item.dob }}
													</li>
													<li>
														HIN: {{ item.health_insurance_no }}
													</li>
													<li>
														Tel: {{ item.cell_phone }}
													</li>
												</ul>
											</div>
											<div class="col-md-6 text-end">
												<ol class="">
													<li class="prevent">
														<router-link :to="{ name: 'PatientChart', params: { patient_id: item.patient_id, } }" target="_blank">M</router-link>
													</li>
													<li class="prevent">
														<router-link :to="{ name: 'PatientEChart', params: { patient_id: item.patient_id, } }" target="_blank">E</router-link>
													</li>
													<li class="prevent">
														<router-link :to="{ name: 'PatientPrescriptions', params: { patient_id: item.patient_id, } }" target="_blank">Rx</router-link>
													</li>
													<li class="prevent">
														<router-link :to="{ name: 'PatientPrescriptions', params: { patient_id: item.patient_id, } }" target="_blank">Label</router-link>
													</li>
												</ol>
											</div>
										</div>
									</div>
								</slot>
								<div class="create_grpc border-top"><router-link target="_blank" to="/patient/create">CREATE DEMOGRAPHIC</router-link></div>
							</div>
						</div>
						<span @click="cancelBtn" class="close_sign">
							<img src="/images/close_sign.svg">
						</span>
						<!-- <i class="fa-solid fa-xmark" @click="cancelBtn"></i> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";


export default {
	components: {},
	data() {
		return {
			currentPatient:[],
			patientList:[],
			patient_search: [],
		};
	},
	props: {
		headerTitle: {
			type: String,
			required: true
		},
	},
	validations() { },
	created() { },
	methods: {
		redirectMasterRecord() {
			this.$router.push(
					'/patient/chart/' + this.$route.params.patient_id
				);
		},
		cancelBtn(){
			if (['PatientEChart', 'PatientCreate', 'PatientChart', 'PatientEdit', 'PatientAppointmentHistory', 'PatientEnrollmentHistory'].includes(this.$route.name)) {
				this.$router.push(
					'/'
				);
			}
			else if(this.$route.name == 'PatientEChartConsultAdd')
			{
				this.$router.push(
					'/patient/e-chart/' + this.$route.params.patient_id + '/consultation/consult-list'
				);
			}
			else
			{
				this.$router.push(
					'/patient/e-chart/' + this.$route.params.patient_id
				);
			}
		},
		patientSearch() {
			if (this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
					.then((response) => {
						this.patientList = response.data.data;
					})
			}
			if(this.patient_search.length == 0){
				this.patientList = [];
			}
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				this.patient_search = '';
				this.patientList = [];
			}
			
		},
		edit(){
			axios.post("patient/retrieve", { 'id': this.$route.params.patient_id })
				.then((response) => {
					if (response.data.status === 200) {
						let detail = response.data.data;

						this.currentPatient = detail;

					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		isDemographicRoute() {
			return !(
				this.$route.name === "PatientCreate" ||
				this.$route.name === "templateMasterList" 
			)
		},
	},
	mounted(){
		document.addEventListener('click', this.handleClickOutside);
		if (this.$route.name === "PatientEdit") {
			this.edit();
		}
	},
	computed: {
		justifyClass() {
			return {
				'justify-content-between': this.isDemographicRoute(),
				'justify-content-end': !this.isDemographicRoute()
			};
		},
		headerClass(){
			return {
				'col-xxl-2 col-md-3': this.isDemographicRoute(),
				'col-md-3': !this.isDemographicRoute()
			};

		},
		headerSubClass(){
			return {
				'col-xxl-10 col-md-9': this.isDemographicRoute(),
				'col-md-9': !this.isDemographicRoute()
			};

		}
	}
};
</script>
<style setup>
.detail_list {
	cursor: pointer;
}
</style>
