import { helpers, required } from "@vuelidate/validators";

const prescriptions = {
	namespaced: true,

	state: {
		listUrl: "",
		header: [],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		defaultFormData: {
			id: null,
			generic_id: null,
			med_id: null,
			generic_name: null,
			drug_name: null,
			manufacture: null,
			start_date: new Date().toISOString().slice(0, 10),
			qty: null,
			repeats: 0,
			eTreatment_type: null,
			status: null,
			notes: null,
			drug_listing_date: null,
			unit_price: null,
			covered_price: null,
			strength: null,
			dosage_form: null,
			coverage_status: null,
			annotation_note: null			
		},
		form: {
		},
		validationRules: {
			form: {
				generic_name: {
					required: helpers.withMessage("Please Enter Generic Name.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {
				
			}
		},
		list: [],
	},

	getters: {},
	mutations: {},
	actions: {},
};


export default prescriptions;
