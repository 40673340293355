<template>
	<div class="content">

        <div class="row">
            <div class="box">
                <div class="box-body">
                    <div class="row">
                        <div class="col-10">
                            <h3>Fax Account Settings</h3> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <SelectBoxStatic v-if="authUserRole == 'Admin'" divClass="col-6" title="Provider" v-model="form.user_id" :option="providerList" ></SelectBoxStatic>

            <InputField title="Account Number" v-model="form.account_number" divClass="col-6" :isRequired="true" :errors="v$.form.account_number.$errors"> </InputField>
            <InputField title="Password" type="password" v-model="form.password" divClass="col-6" :isRequired="true" :errors="v$.form.password.$errors"> </InputField>
            <InputField title="Internal Name" v-model="form.internal_name" divClass="col-6" > </InputField>

            <InputField title="Notification Email" v-model="form.notification_email" divClass="col-6" :isRequired="true" :errors="v$.form.notification_email.$errors"> </InputField>
            <InputField title="Response Fax Number" v-model="form.respomse_fax_number" divClass="col-6" :isRequired="true" :errors="v$.form.respomse_fax_number.$errors"> </InputField>


            <div class="row">
                <button class="btn btn-sm btn-primary text-white me-1 col-1"  v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_inbox_crud_all, this.$store.state.permission.admin_inbox_crud])" @click="save">
					Save
					<img src="images/loader.gif" v-if="loader" />
				</button>
            </div>

        </div>
    </div>
</template>

<script>
import InputField from "../../base/formFields/InputField.vue"
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import $ from "jquery";
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";

export default {
    data(){
        return{
            loader:false,
            authUserRole:localStorage.getItem("authUserRole"),
            providerList:[]
        }
    },
    components: {
        InputField,
        SelectBoxStatic
    }, 
    setup() {
        return { v$: useVuelidate() };
    },
    form(){
        return this.$store.state.fax.form;
    },
    computed: {
        form() {
			return this.$store.state.fax.form;
		},
    },
    validations() {
		return this.$store.state.fax.validationRules;
	},
    methods:{
        save() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loader = true

                let url = "/admin/fax-config/save";
                this.form.is_enable_integration = true
                this.form.is_enable_incoming_fax = true
                this.form.is_enable_outgoing_fax = true
                if (this.authUserRole != 'Admin'){
                    this.form.user_id = localStorage.getItem("userId")
                }

                axios.post(url, this.form)
				.then((response) => {
					if (response.status == 200) {
                        this.loader = false;
                        this.$filters.moshaToast(response.data.message, "success");
                        this.$router.push(`/administration/fax/config/list`);

                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }
        },
        fetchProviderData(){
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                'Content-Type': 'application/json', // Adjust this as needed for your API
            };

            axios.post("json_list/provider",{'roleId':''}, headers)
                .then((response) => {
                    const tempUserData = []
                    $.each(response.data.data, function(key, detail) {
                      if (localStorage.getItem("userId") != detail.user_id) {
                        let fullName = detail.full_name
                        if (detail.userName != null) {
                          fullName += ' ('+detail.userName+')'
                        }
                        tempUserData.push({
                          'value':detail.user_id,
                          'title':fullName,
                        })
                      }
                    });
                    this.providerList = tempUserData

            })

        },
    },
    async mounted(){
        if (this.authUserRole == 'Admin'){
            this.fetchProviderData();
        }
    }
}
</script>
