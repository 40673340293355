<template>
	<div class="content">
        <div class="box">
			<div class="box-body">
				<h3>Edit User</h3>
            </div>
        </div>

        
        <div class="box">
			<div class="box-body">
                <InputField title="Provider No." readonly="true" divClass="col-6" v-model="form.provider_no" :isRequired="true" :errors="v$.form.provider_no.$errors"> </InputField>

                <InputField title="Provider Last Name" divClass="col-6" v-model="form.last_name" :isRequired="true" :errors="v$.form.last_name.$errors"> </InputField>
                <InputField title="Provider First Name" divClass="col-6" v-model="form.first_name" :isRequired="true" :errors="v$.form.first_name.$errors"> </InputField>
                <SelectBoxStatic divClass="col-md-6" title="Type"  :option="user_type_options" v-model="form.roleId" :isRequired="true" :errors="v$.form.role.$errors"></SelectBoxStatic>
                <InputField title="Specialty" divClass="col-6" v-model="form.specialty" > </InputField>
                <!-- <InputField title="Team" divClass="col-6" v-model="form.team" > </InputField> -->
                <SelectBoxStatic title="Sex" :option="gender_option" v-model="form.gender" :isRequired="true" :errors="v$.form.gender.$errors"></SelectBoxStatic>
                <DatePicker title="DOB" :maxDate="currentDate" v-model="form.date_of_birth" :isRequired="true" :errors="v$.form.date_of_birth.$errors"></DatePicker>
                <TextArea title="Address" divClass="col-6" v-model="form.address" :rows="4"></TextArea>

                <InputField title="Phone (Home)" v-model="form.home_phone" mask="(000) 000-0000"></InputField>
                <InputField title="Phone (Work)" v-model="form.work_phone" mask="(000) 000-0000"></InputField>
                <InputField title="Email" v-model="form.email" :isRequired="true" :errors="v$.form.email.$errors"> </InputField>

                <!-- <InputField title="Pager" v-model="form.pager_phone" mask="(000) 000-0000"></InputField> -->
                <InputField title="Cell" v-model="form.cell_phone" mask="(000) 000-0000"></InputField>
                <!-- <InputField title="Other Phone" v-model="form.other_phone" mask="(000) 000-0000"></InputField> -->
                <InputField title="Fax" v-model="form.fax_phone" mask="(000) 000-0000"></InputField>

                <InputField title="OHIP Billing Number" divClass="col-6" v-model="form.provincial_billing_no" > </InputField>
                <!-- <InputField title="3rd Party Billing #" divClass="col-6" v-model="form.third_party_billing_no" > </InputField> -->
                <!-- <InputField title="Billing #" divClass="col-6" v-model="form.billing_no" > </InputField> -->
                <!-- <InputField title="Alternate Billing #" divClass="col-6" v-model="form.alternate_billing" > </InputField> -->

                <!-- <InputField title="LifeLabs Id" divClass="col-6" v-model="form.default_lab_id" > </InputField> -->
                <InputField title="Group Billing #" divClass="col-6" v-model="form.group_billing_no" > </InputField>
                <InputField title="Specialty Code #" divClass="col-6" v-model="form.specialty_code" > </InputField>
                <InputField title="CPSO Number" divClass="col-6" v-model="form.cpsid" > </InputField>
                <!-- <InputField title="Bill Center" divClass="col-6" v-model="form.bill_center" > </InputField> -->

                <InputField title="Username" divClass="col-6" v-model="form.username" :isRequired="true" :errors="v$.form.username.$errors"> </InputField>

                <SelectBoxStatic title="Status" :option="status_option" v-model="form.status" :isRequired="true" :errors="v$.form.status.$errors"></SelectBoxStatic>

                <div class="row">
                    <div class="col-md-2 pt-10">
                        <button class="btn btn-primary text-white" @click="submit" v-if="this.checkEditPermission(form.id)">
                            {{saveBtn}}
                            <img src="images/loader.gif" v-if="loader" />
                        </button>

                    </div>
                </div>
            </div>
        </div>

	</div>
</template>

<script>
import InputField from "../../base/formFields/InputField.vue"
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import DatePicker from "../../base/formFields/DatePicker.vue";
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import TextArea from "../../base/formFields/TextArea.vue";
import $ from "jquery";

export default {
    setup: () => {
		return { v$: useVuelidate() };
	},
    data(){
        return {
            user_type_options:[],
			gender_option: [
				{ value: "M", title: "Male" },
				{ value: "F", title: "Female" },
				{ value: "T", title: "Transgendered" },
				{ value: "O", title: "Other" },
				{ value: "U", title: "Undefined" }
			],
            currentDate: new Date(),
            saveBtn:'Update',
            status_option:[
                { value: "AC", title: "Active" },
				{ value: "IN", title: "Inactive" },
            ],
            loader:false,
            authUserId: localStorage.getItem('userId')
        }
    },
    components: {
        InputField,
        SelectBoxStatic,
        DatePicker,
        TextArea
    },
    mounted() {
        this.getRoleOption()
        if(this.$route.name=='EditAdminUser') {
            this.edit()
        }
    },
    methods:{
        generateProviderNo(){
            axios.get("user/generate-provider-no")
				.then((response) => {
					if (response.data.status === 200) {
						let detail = response.data.data
                        this.form.provider_no = detail.provider_no
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
        },
        edit(){
            let params = { 'id': this.$route.params.id }

			axios.post("user/retrieve", params)
				.then((response) => {
					if (response.data.status === 200) {
                        this.$store.state.user.form=response.data.data
                        this.$store.state.user.form.third_party_billing_no = response.data.data['3rd_party_billing_no']
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
        },
        submit(){
            this.v$.$validate();
            const formName = 'form';
			if (!this.v$.$error) {
                const $this = this
                this.loader = true;
                let url = "user/update";

                $this.form['3rd_party_billing_no'] = $this.form.third_party_billing_no
                $this.form.provider_id = $this.form.user_id
                axios.post(url, $this.form)
                .then((response) => {
                    if (response.status == 200) {
                        this.loader = false
                        setTimeout(function () {
                            
                            $this.$filters.moshaToast(response.data.message, "success")
                            $this.$router.push(
                                `/administration/user/list`
                            );
                        }, 400);
                    } else {
                        this.loader = false
                        this.$filters.moshaToast(response.data.message, "error")
                    }
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })

            }else {
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
        },
        getRoleOption(){
            let params = {}
            let $this = this
            axios.post("admin/role/list", params)
				.then((response) => {
					if (response.data.status === 200) {
                         $.each(response.data.data, function(index, detail) {
                            $this.user_type_options.push({'value':detail.id, 'title':$this.formatRoleName(detail.name)})
                         })
					} else {
						// this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
        },
        formatRoleName(role){
            return role.charAt(0).toUpperCase() + role.slice(1)
        },
        checkEditPermission(userId){
            if (this.$filters.permissioncheck([this.$store.state.permission.admin_acount_setting_crud_all])) {
                return true
            } else if (userId == this.authUserId && this.$filters.permissioncheck([this.$store.state.permission.admin_acount_setting_crud])) {
                return true
            }

            return false
        }
    },
    computed: {
        form() {
			return this.$store.state.user.form;
		},
        vuelidateExternalResults() {
			return this.$store.state.user.vuelidateExternalResults;
		},
    },
    validations() {
		return this.$store.state.user.validationRulesEdit;
	},
    beforeCreate() {
    }
}
</script>
