<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Consultation" v-model="form.keyword" @blur="this.getConsultationList()">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList"></CustomFilter>
                    </span>
                    <button class="comman_brdr_btn cmn_mute_clr" v-if="this.selectedItems.length > 0" @click="exportconsults()"> Export </button>
                </div>
                <div>
                    <button class="comman_btn" @click="selectTemplate"> Select Template </button>
                    <button class="comman_btn" @click="addConsultation"> Add Consultation </button>
                </div>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'referral_date' }" @click="changeSort('referral_date')">Referral Date <img src="/images/down-arrow-new.svg" class="img-fluid " :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'patient_name' }" @click="changeSort('patient_name')">Demographic <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'specialist_type' }" @click="changeSort('specialist_type')">Service <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'specialist_name' }" @click="changeSort('specialist_name')">Specialist <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'status' }" @click="changeSort('status')">Latest Status <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.consultationsList" :key="index" :class="{ 'active': (selectChk[item.id] || selectedItems.includes(item.id) ) }">
                            <td><input class="form-check-input" type="checkbox" :checked="isChecked(item.id)" v-model="selectChk[item.id]" @change="checkSelect(item.id)"></td>
                            <td>{{ item.referral_date }}</td>
                            <td>{{ item.patient_name }}</td>
                            <td>{{ item.specialist_type }}</td>
                            <td>{{ item.specialist_name }}</td>
                            <td>{{ item.status }}</td>
                            <td><span class="popup_link" @click="viewlogs(item.id)"> View Logs</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="add-viewlogs-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeViewLogs"></button>
                    <div class="box">
						<h2 class="popup_title">Tracing History</h2>
						<div class="table-responsive specialist-list">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Specialist</th>
                                        <th scope="col">Tracing Status Role</th>
                                        <th scope="col" class="hover_unset"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in this.logList" :key="index">
                                        <td>{{ $filters.inputDateTime(item.observation_datetime, 'YYYY-MM-DD') }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.specialist_name }}</td>
                                        <td>{{ item.tracing_role }}</td>
                                        <td><span class="popup_link" @click="viewLogDocument(item.id, item.tracing_role)"> {{ item.tracing_role === 'Requisition Created' ? 'View Request' : 'View File' }}</span></td>
                                    </tr>
                                    <tr v-if="this.logList && this.logList.length && this.logList[0].file_path">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><span class="popup_link" @click="viewPdf"> View PDF</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="modal fade" id="add-exportconsultation-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="box">
                        <ul class="export_tabs">
                            <li :class="{ active: isFaxFormVisible }" @click="changeExportOpt('fax')">Fax</li>
                            <li :class="{ active: isEmailFormVisible }" @click="changeExportOpt('email')">Email</li>
                            <li :class="{ active: isMailFormVisible }" @click="changeExportOpt('mail')">Mail</li>
                        </ul>
                        <section class="con_detl_sec">
                            <div class="fax_menu" v-if="isFaxFormVisible">
                                <div class="row align-items-center">
                                    <label for="text" class="col-sm-4 col-form-label right-bor py-0">Specialist</label>
                                    <div class="col-sm-8 position-relative">
                                        <CustomDropDown :options="specialist_option" :initialValue="specialist_text"
											@item-selected="handleItemSelected" v-model="faxForm.specialist_id"
											fieldName="specialist_id">
                                        </CustomDropDown>
                                        <!-- <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                                    </div>
                                 </div>   

                                <div class="or_line"><span>OR</span></div>

                                <!-- disable-fax -->
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="faxForm.fax_number" @change="changeReceiptFaxnumber">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Fax Number</label>
                                    </div>
                                </div>

                                
                                <div class="error-msg" v-if="this.faxValidationMsg">
                                    <ul class="mb-0 p-0 fs-14">
                                        <li class="d-block text-danger">
                                        {{ this.faxValidationMsg }}
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div class="email_menu" v-if="isEmailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_id">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Email</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_id.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_header">
                                        <label class="form-label" for="typeunique_idX-2">Email Header</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_header.$errors" />
                                    </div>
                                </div>

                            </div>
                            <div class="mail_menu" v-if="isMailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.name">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Name</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.name.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.address">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Address</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.address.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.city">
                                        <label class="form-label" for="typeunique_idX-2">Receipient City</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.city.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.state">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Province</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.state.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.zip">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Postal Code</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.zip.$errors" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeExportModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="exportSubmit">Confirm</button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import sidebar from './sidebar.vue';
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../base/CustomFilter.vue';
import $ from "jquery";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';

export default {
        setup: () => {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                specialist_text:"",
                isFaxFormVisible:true,
                isEmailFormVisible:false,
                isMailFormVisible:false,
                consultationsList:[],
                selectedItems:[],
                selectAllChk:false,
                allItems:[],
                sortBy:'',
                sortOrder:'',
                filterList:[],
                isShowCustomFilter:false,
                filterOptions:[
                    { value: "patient_name", title: "Patient Name" },
                    { value: "custom_specialists.name", title: "Specialist Name" },
                    { value: "consultations.status", title: "Latest Status" },
                ],
                selectChk:[],
                logList: [],
                viewConsultationId: '',
                faxValidationMsg:''
            }
        },
        components: {
            sidebar,
            CustomDropDown,
            CustomFilter,
            ValidationErrorMessageList,
        },
        mounted(){
            this.getConsultationList()
            this.getSpecialistList()
        },
        methods:{
            viewLogDocument(documentId, tracingRole){
                if (tracingRole === 'Requisition Created') {
                    // this.$router.push({ name: 'templateMasterEdit', params: { patient_id: this.$route.params.patient_id, template_id:documentId} });
                    const url = this.$router.resolve({
                        name: 'editModuleTemplate', params: { patient_id: this.$route.params.patient_id, template_id:documentId, module:'consultation'}
                    }).href;

                    window.open(url, '_blank');
                } else {
                    // this.$router.push({ name: 'PatientEChartFilesEdit', params: { id:documentId} });
                    const url = this.$router.resolve({
                        name: 'PatientEChartFilesEdit', params: { id:documentId}
                    }).href;

                    window.open(url, '_blank');
                }
            },
            viewPdf(){
                // this.$router.push({ name: 'PatientConsultationsEdit', params: { id: this.viewConsultationId} });
                window.open(this.documentUrl(this.logList[0].file_path), '_blank');
            },
            closeViewLogs(){
                this.logList = [];
                this.viewConsultationId = '';
            },
            viewlogs(consultationId){
                this.viewConsultationId = consultationId;
                window.$("#add-viewlogs-model").modal("show");
                this.$store.state.loader = true;
                axios.post("patient/consultations/view-logs", { patient_id: this.$route.params.patient_id, id: this.viewConsultationId })
                .then((response) => {
                    console.log(response);
                    this.logList = response.data.data;

                    this.$store.state.loader = false;
                });
            }, 
            exportconsults(){
                this.v$['faxForm'].$reset();
                this.v$['emailForm'].$reset();
                this.v$['mailForm'].$reset();
                Object.assign(
                    this.$store.state.consultations.faxForm,
                    this.$store.state.consultations.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.consultations.emailForm,
                    this.$store.state.consultations.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.consultations.mailForm,
                    this.$store.state.consultations.defaultexportfrm
                );
                window.$("#add-exportconsultation-model").modal("show");
            },
            addConsultation(){
                this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}/consultation/consult-add`);
            },
            changeExportOpt(type){
                this.isFaxFormVisible=false
                this.isEmailFormVisible=false
                this.isMailFormVisible=false

                if (type == 'fax') {
                    this.isFaxFormVisible=true
                } else if (type == 'email') {
                    this.isEmailFormVisible=true
                } else if (type == 'mail') {
                    this.isMailFormVisible=true
                }
            },
            isChecked(id){
                return this.selectedItems.includes(id);
            },
            getConsultationList(){
                this.$store.state.loader = true;
                axios.post("patient/consultations/list", 
                {
                     patient_id: this.$route.params.patient_id,
                     keyword: this.form.keyword,
                     advanceFilter:this.form.advanceFilter,
                     sort_by: this.sortBy,
                     sort_order:this.sortOrder
                })
                .then((response) => {
                    this.consultationsList = response.data.data;
                    this.consultationsList.forEach(item => {
                        this.allItems.push(item.id)
                    });

                    this.$store.state.loader = false;
                });
                console.log(this.allItems)
            },
            checkSelectAll(){
                if (this.selectAllChk) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            checkSelect(id){
                if (this.selectChk[id]) {
                    this.selectedItems.push(id)
                } else {
                    const index = this.selectedItems.indexOf(id);
                    if (index !== -1) {
                        this.selectedItems.splice(index, 1);
                    }
                }

                if (this.selectedItems.length == this.allItems.length) {
                    this.selectAllChk = true;
                } else if (this.selectedItems.length == 0) {
                    this.selectAllChk = false;
                } else {
                    this.selectAllChk = false;
                }
            },
            changeSort(fieldName){
                if (fieldName != this.sortBy) {
                    this.sortOrder = 'desc'
                } else if (this.sortOrder == 'desc') {
                    this.sortOrder = 'asc'
                } else{
                    this.sortOrder = 'desc'
                }

                this.sortBy = fieldName

                this.getConsultationList()
            },
            addFilter(){
                if (this.isShowCustomFilter) {
                    this.getConsultationList()
                }
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    advanceFilter[item.field] = item.value
                });
                this.form.advanceFilter = advanceFilter
            },
            getSpecialistList(){
                axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
                    .then((response) => {
                        if (response.status == 200) {
                            const details = response.data.data;
                            const specialistTempData = [];
                            $.each(details, function (key, detail) {
                                detail.value = detail.id
                                let name = detail.name;
                                if (detail.is_preferred_specialist) {
                                    name += "<p class='float-end'>(Preferred)</p>"
                                }
                                detail.title = name
                                specialistTempData.push(detail)
                            })
                            this.specialist_option = specialistTempData;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.loader = false;
                        console.log(error)
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            handleItemSelected(values){
                this.faxForm.specialist_id = values.item.id
                this.faxValidationMsg = ''
            },
            changeReceiptFaxnumber(){
                this.faxValidationMsg = ''
                if (!this.faxForm.fax_number) {
                    this.faxValidationMsg = "Please select specialist or enter fax number"
                }
            },
            closeExportModel(){
                window.$("#add-exportconsultation-model").modal("hide");
            },
            exportSubmit(){
                this.faxValidationMsg = ''
                let form = null
                let formName = 'faxForm'
                if (this.isFaxFormVisible) {
                    form = this.faxForm
                    form.type = "fax"
                    formName = 'faxForm';
                } else if(this.isEmailFormVisible){
                    form = this.emailForm
                    form.type = "email"
                    formName = 'emailForm';
                } else if (this.isMailFormVisible){
                    form = this.mailForm
                    form.type = "mail"
                    formName = 'mailForm';
                }
                form.ids = this.selectedItems
                form.patient_id = this.$route.params.patient_id
                this.v$[formName].$validate();

                if (formName == 'faxForm' || !this.v$[formName].$error) {
                    if (formName == 'faxForm') {
                        if (!this.faxForm.specialist_id && !this.faxForm.fax_number){
                            this.faxValidationMsg = "Please select specialist or enter fax number"
                            return false
                        }
                    }
                    this.loader = true

                    let url = "patient/consultations/export"
                        axios.post(url, form)
                            .then((response) => {
                                this.$filters.moshaToast(response.data.message, "success");
                                this.loader = false;
                                window.$("#add-exportconsultation-model").modal("hide");
                            });
                } else {
                    console.log(Object.entries(this.v$[formName]))
                    for (const [key] of Object.entries(this.v$[formName])) {
                        if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                            const myEl = document.getElementById(key);
                            if (myEl) {
                                this.$smoothScroll({
                                    scrollTo: myEl,
                                });
                                break;
                            }
                        }
                    }
                }

            },
            selectTemplate(){
                this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}/template/consultation/list`);
            },
            documentUrl(documentPath) {
                // Assuming your documents are stored in the 'public' directory
                return `${process.env.VUE_APP_STORAGE_URL}/document/view/${documentPath}`;
            },

        },
        computed: {
            form() {
                return this.$store.state.consultations.form;
            },
            faxForm(){
                return this.$store.state.consultations.faxForm;
            },
            emailForm(){
                return this.$store.state.consultations.emailForm;
            },
            mailForm(){
                return this.$store.state.consultations.mailForm;
            }
        },
        validations() {
            return {
                faxForm: this.$store.state.consultations.validationRules.faxForm,
                emailForm: this.$store.state.consultations.validationRules.emailForm,
                mailForm: this.$store.state.consultations.validationRules.mailForm,
            };

        },
}
</script>
