<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 dashboard dashboard-billing">
			<div class="header_boxes">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="d-flex align-items-center">
                            <h3 class="d-h3head">Total Billing</h3>
                            <div class="range_calc blg-date-select">
                                <div class="form-group syncfusion-component">
                                    <ejs-daterangepicker id="daterangepicker" :placeholder="waterMarkText" :format="dateFormat" :openOnFocus="true" @select="changeDateRangePicker" @change="changeDatePicker" :htmlAttributes="htmlAttributes" :showCustomRangeLabel=false  >
                                        <e-presets>
                                            <e-preset label="Today" :start='today' :end='today'></e-preset>
                                            <e-preset label="Yesterday" :start='yesterday' :end='yesterday'></e-preset>
                                            <e-preset label="This Week" :start='last7days' :end='today'></e-preset>
                                            <e-preset label="Last 7 days" :start='last7days' :end='today'></e-preset>
                                            <e-preset label="Last 30 days" :start='last30days' :end='today'></e-preset>
                                            <e-preset label="Last 90 days" :start='last30days' :end='today'></e-preset>
                                            <e-preset label="Last 180 days" :start='last30days' :end='today'></e-preset>
                                            <e-preset label="This Month" :start='thisMonth' :end='today'></e-preset>
                                            <e-preset label="Last Month" :start='lastStart' :end='lastEnd'></e-preset>
                                            <e-preset label="All Time" :start='lastStart' :end='lastEnd'></e-preset>
                                        </e-presets>
                                    </ejs-daterangepicker>
                                </div>
                            </div>
                        </div>
					</div>
					<div class="col-12 col-sm-12 col-md-6 col-lg-6">
						<p class="m-light-r mb-0 text-end">data refreshes at every 9:00 pm EST</p>
					</div>
				</div>
				<div class="t-box-b-brd d-xl-flex">
					<div class="w-pric-box d-inline-block">
						<div class="inv-price-bx">
							<div class="prc-t-invc">
								<p class="mb-0">Total # of Invoice</p><span>{{ this.staticstic_data['total_no_invoice'] }}</span>
							</div>
						</div>
					</div>
					<div class="w-pric-box d-inline-block">
						<div class="inv-price-bx">
							<div class="prc-t-invc">
								<p class="mb-0">Total Amount</p><span>{{ this.staticstic_data['total_amount'] }}</span>
							</div>
						</div>
					</div>
					<div class="w-pric-box d-inline-block">
						<div class="inv-price-bx">
							<div class="prc-t-invc">
								<p class="mb-0">Total Rejection</p><span>{{ this.staticstic_data['total_rejection_amount'] }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="stts-chart-bar">
				<div class="row">
					<div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 invoice">
						<div class="mb-4">
							<div id="chart">
								<ul id="numbers" v-if="this.staticstic_data.status_distr_total_invoice_config">
									<li v-for="(item, index) in this.staticstic_data.status_distr_total_invoice_config.ranges" :key="index">
										<span>{{ item }}</span>
									</li>
								</ul>
								<div>
									<div class="chart_title">Status Distribution of # of Invoice</div>
									<ul id="bars">
										<li v-for="(item, index) in this.staticstic_data['status_distr_total_invoice']" :key="index">
											<div class="bar" :style="{ height: `${item.percentage}%` }"><label class="progres_tooltip">{{item.label}}: {{item.value}}</label></div><span>{{ item.label }}</span>
										</li>
										<li class="crt_lines">
											<div></div>
											<div></div>
											<div></div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 amount">
						<div class="ttl-amunt-chart mb-4">
							<div id="chart">
								<ul id="numbers" v-if="this.staticstic_data.status_distr_total_amount_config">
									<li v-for="(item, index) in this.staticstic_data.status_distr_total_amount_config.ranges" :key="index">
										<span>${{ formatCurrency(item) }}</span>
									</li>
								</ul>
								<div>
									<div class="chart_title">Status Distribution of Total Amount</div>
									<ul id="bars">
										<li v-for="(item, index) in this.staticstic_data['status_distr_total_amount']" :key="index">
											<div class="bar" :style="{ height: `${item.percentage}%` }"><label class="progres_tooltip">{{item.label}}: ${{formatCurrency(item.value)}}</label></div><span>{{ item.label }}</span>
										</li>
										<li class="crt_lines">
											<div></div>
											<div></div>
											<div></div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 reason">
						<div class="top-rjct-chart mb-4">
							<div id="chart">
								<ul id="numbers" v-if="this.staticstic_data.top_rejection_reasons_config">
									<li v-for="(item, index) in this.staticstic_data.top_rejection_reasons_config.ranges" :key="index">
										<span>{{ item }}</span>
									</li>
									
								</ul>
								<div>
									<div class="chart_title">Top Rejection Reasons | <span>Past 180 Days</span></div>
									<ul id="bars">
										<li v-for="(item, index) in this.staticstic_data['top_rejection_reasons']" :key="index">
											<div class="bar" :style="{ height: `${item.percentage}%` }"></div><span>{{ item.label }}</span>
										</li>
										<li class="crt_lines">
											<div></div>
											<div></div>
											<div></div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import sidebar from './sidebar.vue'
// import useVuelidate from "@vuelidate/core";
// import 'syncfusion-ej2/vue/dist/ej2-vue.css';
	import { DateRangePickerComponent } from '@syncfusion/ej2-vue-calendars';
	import moment from "moment";
	import $ from "jquery";
	import axios from "@/scripts/axios.js";

	export default {
		data() {
			return {
				waterMarkText: "Select a Range",
				dateFormat: "yyyy/MM/dd",//MM-dd-yyyy
				htmlAttributes: { showCustomRangeLabel: true },
				today: new Date(new Date().toDateString()),
				yesterday: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()),
				last7days: new Date(new Date(new Date().setDate(new Date().getDate() - 7)).toDateString()),
				weekStart: new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString()),
				weekEnd: new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
					- (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()),
				last30days: new Date(new Date(new Date().setDate(new Date().getDate() - 30)).toDateString()),
				lastStart: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
				lastEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
				thisMonth: new Date(moment().subtract(1, 'month').startOf('month')),
				minDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
				staticstic_data:[],
				filter:{
					date_range:null
				}
			}
		},
		components: {
			sidebar,
			'ejs-daterangepicker': DateRangePickerComponent,
		},
		methods: {
			changeDatePicker() {
				this.getdate();
			},
			getdate() {
				this.filter.date_range = $("#daterangepicker").val();
				this.fetchStaticsData()
			},
			changeDateRangePicker() {
				this.getdate();
			},
			fetchStaticsData(){
				this.$store.state.loader = true;
				axios.post('invoice/fetch-dashboard-data', this.filter)
                .then((response) => {
					this.$store.state.loader = false;
                    if (response.status == 200) {
						this.staticstic_data = response.data.data
					} else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
					this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
			},
			formatCurrency(value) {
				return value.toFixed(2);
			}
		},
		mounted() {
			this.fetchStaticsData();

		},
		computed: {

		},
		provide: {
		},
		beforeCreate(){
		}
	}
</script>

<style>
/* @import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
 .wrapper {
	max-width: 250px;
	margin: 0 auto;
  } */
  #total_invoice_container {
	height: 120px;
  }

  #total_amt_container {
	height: 120px;
  }

  #top_rejection_container {
	height: 120px;
  }
  .e-daterangepicker.e-popup.e-preset-wrapper {
    top: 190px !important;
}
</style>