import {
	helpers,
	required,
	email,
} from "@vuelidate/validators";

const fax = {
    namespaced: true,

    state: {
		listUrl: "/admin/fax-config/list",
        header: [
            { "account_number": "Account Name" },
            { "account_number": "Fax Account" },
            { "is_enable_incoming_fax": "Incoming Faxing" },
            { "is_enable_outgoing_fax": "Outgoing Faxing" },
            { "action": "Action" },
        ],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
            'is_enable_integration':1,
            'account_number':null,
            'password':null,
            'internal_name':null,
            'is_enable_incoming_fax':1,
            'is_enable_outgoing_fax':1,
            'notification_email':null,
            'response_fax_number':null,
            'cover_letter':'Not Set',
        },
        form:{
            'is_enable_integration':1,
            'account_number':null,
            'password':null,
            'internal_name':null,
            'is_enable_incoming_fax':1,
            'is_enable_outgoing_fax':1,
            'notification_email':null,
            'response_fax_number':null,
            'cover_letter':'Not Set',
        },
        validationRules: {
			form: {
				account_number: {
					required: helpers.withMessage("Please enter account number.", required),
				},
                password: {
					required: helpers.withMessage("Please enter password.", required),
				},
                notification_email: {
					required: helpers.withMessage("Please enter notification email.", required),
                    email: helpers.withMessage("Please enter notification email.", email),
				},
                response_fax_number: {
					required: helpers.withMessage("Please enter response fax number.", required),
				},
            }
        },
        filter:{
            'user_id':localStorage.getItem("userId")
        }

    }
}

export default fax;
