<template>
 <div class="row prescription_html">

     <div class="col-12">
     <h2 class="popup_title">Prescription Rx</h2>
     <p>
          {{ this.provider_detail.full_name }} | CPS ID - {{ this.provider_detail.cpsid }}
     </p>
     <p v-html="this.provider_detail.address"></p>
     <p> {{ this.provider_detail.cell_phone }} FAX  {{ this.provider_detail.fax_phone }}</p>

     <h2 class="popup_title">Basic Info</h2>

     <p>
          <label>Patient</label> 
          <span>{{ this.patient_detail.full_name }} </span>
          <span>{{ this.patient_detail.address }}
               {{ this.patient_detail.city }}, {{ this.patient_detail.state }}, {{ this.patient_detail.country }}
               {{ this.patient_detail.zip }}
               Tel {{ this.patient_detail.cell_phone }}</span>
     </p>
     <p>
          <label>date of birth</label> 
          <span>{{this.patient_detail.dob}} </span>
     </p>
     <p>
          <label>hin</label> 
          <span>{{this.patient_detail.health_insurance_no}} BB (ON)</span>
     </p>
     <p>
          <label>Service Date</label> 
          <span>{{ this.current_date }} </span>
     </p>

     <h2 class="popup_title">Medication</h2>
     <p>
          <label>prescription 1 </label> 
          <span>{{ this.prescriptionDetail.drug_name }} </span>
     </p>

     <p>
          <label>instruction</label> 
          <span v-html="this.prescriptionDetail.notes"></span>
     </p>

     </div>

</div>

</template>

<script>
import axios from "@/scripts/axios.js";
// import $ from "jquery";

export default ({
    data(){
          return {
               prescriptionDetail:[],
               patient_detail:[],
               provider_detail:[]
          }
    },
    methods:{
        edit() {
            axios.post("patient/prescriptions/retrieve", {id:this.$route.params.id})
                    .then((response) => {
                         if (response.status == 200) {
                            this.prescriptionDetail = response.data.data;
                         } else {
                              this.$filters.moshaToast(response.data.message, "error");
                         }
                    }).catch(error => {
                         this.loader = false;
                         console.log(error)
                         if (error.response.status === 422) {
                              this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                         } else {
                              this.$filters.moshaToast(error.response.data.message, "error");
                         }
                    });
        },
        fetchPatientDetail() {
            let params = { 'id': this.$route.params.patient_id }
			axios.post("patient/detail", params)
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
        getProvider(){
            axios.post("json_list/provider", { type: 'Doctor', 'id':this.prescriptionDetail.provider_id })
                .then((response) => {
                this.provider_detail = response.data.data[0];
            })
        },
        printPreview() {
           window.print();
        },
    },
    mounted(){
        this.edit();
        this.fetchPatientDetail();
        this.getProvider();
     //    setTimeout(() => {
     //         this.printPreview();
     //    }, 200);
    }
})
</script>