<template>
	<div class="container-fluid">
		<Header></Header>
	</div>
	<div class="content">
		<div class="container-full">
			<section class="content">
				<component :is="$store.state.component">

				</component>
			</section>
		</div>
	</div>
</template>
<script>
import ProviderCreate from '../../components/provider/Form.vue';
import ProviderList from '../../components/provider/List.vue';
import Header from '../Header.vue';
export default {
	components: {
		Header,
		ProviderCreate,
		ProviderList
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
}
</script>