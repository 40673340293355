<template>
			<section >

        <div class="row">
          <Users :currentUserVal="currentUser" :currentUserIdVal="currentUserId" @updateUserValue="updateUserValue" @updateUserIdValue="updateUserIdValue" @fetchMessages="fetchMessages" :chatType="chatType" @updateChatType="updateChatType" @fetchGroupData="fetchGroupData" :groupsNames="groupsNames" @modalOpen="modalOpen" />

          <Chat :currentUserVal="currentUser" :currentUserIdVal="currentUserId" @updateUserValue="updateUserValue" @updateUserIdValue="updateUserIdValue"  @fetchMessages="fetchMessages" :messages="messages" @updateMessage="updateMessage" :chatType="chatType" @updateChatType="updateChatType" @sendMsgToSocket="sendMsgToSocket" />

        </div>

        <div class="modal center-modal fade" id="groupModal" tabindex="-1" style="display: none;" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Create Group</h5>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
              </div>

              <div class="modal-body">

                <InputField  v-model="form.group_name" title="Group Name" :isRequired="true" id="group_name" :errors="v$.form.group_name.$errors"> </InputField>

                <ejs-multiselect id='multiselect' 
                v-model="form.users"
                :dataSource="providerDataSource"
                :fields="fields"
                placeholder="Assign a user" ></ejs-multiselect>
                <p v-if="!isValid" style="color: red;">Please select at least one option.</p>


              </div>

              <div class="modal-footer">
                <button type="button" class="text-white btn btn-primary saveBtn" @click="storeForm()">Create</button>
                <button type="button" class="text-white btn btn-danger float-end close-btn" data-bs-dismiss="modal">Close</button>
              </div>

          </div>
        </div>

      </div>

  </section>
</template>
<script>
import Chat from './chat.vue';
import Users from './users.vue';
import axios from "@/scripts/axios.js";
import InputField from "../base/formFields/InputField.vue"
import $ from "jquery";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import useVuelidate from "@vuelidate/core";
import { decryptMessage } from '@/cryptoUtils';

export default {
  data() {
    return {
      currentUser:'',
      currentUserId:'',
      messages:[],
      channelUsers:null,
      providerDataSource: [],
      fields:{ text: 'text', value: 'id' },
      chatType:'',
      groupsNames:[],
      isValid: true,
      groupId:'',
      ws:null
    }
  },
  setup: () => {
		return { v$: useVuelidate() };
	},
  components: {
    Users,
    Chat,
    InputField,
    'ejs-multiselect':MultiSelectComponent,
	},
	created() {
    this.fetchProviderData()
	},
  mounted() {
  },
  computed:{
    form() {
			return this.$store.state.group.form;
		},
    vuelidateExternalResults() {
			return this.$store.state.group.vuelidateExternalResults;
		},
  },
  validations() {
		return this.$store.state.group.validationRules;
	},
  methods: {
    fetchProviderData() {
			axios.post("json_list/provider", { 'roleId': '' })
				.then((response) => {
					const providerTempData = []
					const details = response.data.data;
					$.each(details, function (key, detail) {
						providerTempData.push({
							id: detail.user_id,
							text: detail.full_name,
						})
					})
					this.providerDataSource = providerTempData
				})
		},
    updateMessage(newMsg) {
      this.messages.push(newMsg)
    },
    updateUserValue(newValue) {
      this.currentUser = newValue;
      // document.addEventListener("DOMContentLoaded", function () {
      //     document.getElementById("newMessage").focus();
      // });
    },
    updateUserIdValue(newValue) {
      this.currentUserId = newValue;
    },
    updateChatType(newValue) {
      this.chatType = newValue;
    },
    fetchMessages(receiverUserId) {
          if (receiverUserId == '') {
            return false
          }
          const headers = {
            'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
            'Content-Type': 'application/json', // Adjust this as needed for your API
          };
          axios.post("chat/get-message", {  receiver_user_id:receiverUserId,  chatType:this.chatType}, headers).then((response) => {
                if (response.data.status == 200) {
                  const tempMsg = []
                  $.each(response.data.data, function (i, detail) {
                    detail.message = decryptMessage(detail.message)
                    tempMsg.push(detail)
                  });
                  this.messages = tempMsg
                  this.scrollToBottom()
                }
              });
    },
    storeForm(){
      this.v$.$validate();
      this.isValid = (this.form.users && this.form.users.length > 0);
			const $this = this
      if (this.v$.$error == true && this.form.group_name.length > 0) {
        this.v$.$error = false
      }
			if (!this.v$.$error && this.isValid) {
        this.form.id = this.groupId
        axios.post('chat/group/save', this.form)
          .then((response) => {
            $this.form.group_name = ""
            $this.form.users=''
            if (response.status == 200) {
              $this.fetchGroupData();
              this.$filters.moshaToast(response.data.message, "success");
              $(".close-btn").trigger('click')
            } else {
              this.$filters.moshaToast(response.data.message, "error");
            }
          })
      }

    },
    fetchGroupData(){
      const headers = {
            'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
            'Content-Type': 'application/json', // Adjust this as needed for your API
        };

        axios.post("chat/group/list",{}, headers)
            .then((response) => {
                const tempUserData = []
                $.each(response.data.data, function(key, detail) {
                    tempUserData.push({
                      'id':detail.id,
                      'group_name':detail.group_name,
                    })
                });
                this.groupsNames = tempUserData
        })
    },
    // eslint-disable-next-line no-unused-vars
    modalOpen(moduleName, id='') {
      if (moduleName == 'EditGroup') {
        this.groupId = id
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
            'Content-Type': 'application/json', // Adjust this as needed for your API
        };

        axios.post("chat/group/retrieve",{'group_id':id}, headers)
            .then((response) => {
              if (response.data.status == 200) {
                const detail = response.data.data
                this.form.group_name=detail.group_name
                this.form.users = detail.member_ids
              } else {
                this.$filters.moshaToast(response.data.message, "error");
              }
        }).catch(error => {
          if (error.response.status === 422) {
            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
            console.log(Object.values(error.response.data.data).join(', '))
          } else {
            this.$filters.moshaToast(error.response.data.message, "error");
          }
        });
        $("#groupModal .modal-title").text('Edit Group')
        $("#groupModal .saveBtn").text('Update')
        
      } else {
        this.groupId = id
        this.form.group_name=null
        this.form.users = []
        $("#groupModal .modal-title").text('Create Group')
        $("#groupModal .saveBtn").text('Save')
      }
    },
    sendMsgToSocket(newMsg)  {
      try {
          newMsg.timeStamp = Date.now(),
          this.$socket.send(JSON.stringify(newMsg));
        
      }catch (error) {
        this.$filters.moshaToast('WebSocket send error:'+error, "error");
      }
    },
    scrollToBottom() {
      setTimeout(() => {
        $(".message-container")[0].scrollTop = $(".message-container")[0].scrollHeight;
      }, 500);
    }
  }
}
</script>

