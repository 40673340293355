<template>
    <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <h3 style="text-align: center;">Task</h3>

        <div class="box">		
            <div class="box-body p-0">
                <div class="media-list media-list-hover">
                    <div class="row">


                        <div class="col-md-12" v-if="this.$route.name=='TaskCreate'">
                            <div class="form-group" :class="{ error: v$.form.patient_id.$errors.length }">
                                <label class="form-label">Patient<small class="asterisksign">*</small></label>
                                
                                <div class="syncfusion-component">
                                    <ejs-dropdownlist id="patient_id" placeholder='Select a Patient' v-model="form.patient_id" :showClearButton="true" :allowFiltering='allowFiltering' :dataSource='dataSource' :fields='fields' @filtering="onFilter" :value="form.patient_id" @change="selectPatient"></ejs-dropdownlist>
                                    <div class="error-msg">
                                        <ul class="mb-0 p-0 fs-14">
                                            <li class="d-block" v-for="(error, index) of v$.form.patient_id.$errors" :key="index">
                                                {{ error.$message }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DatePicker divClass="col-md-12" title="Service Date" v-model="form.service_date" :isRequired="true" :errors="v$.form.service_date.$errors" ></DatePicker>


                        <SelectBoxStatic divClass="col-md-12" title="Priority" v-model="form.priority" :option="priority_options" :errors="v$.form.priority.$errors" :isRequired="true"></SelectBoxStatic>

                        <SelectBoxStatic divClass="col-md-12" title="Task Assigned to	" v-model="form.assigned_to" :option="providerDataSource" :errors="v$.form.assigned_to.$errors" :isRequired="true"></SelectBoxStatic>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label">Reminder Message<small class="asterisksign" style="">*</small></label>
                                <TextArea divClass="col-12" v-model="form.reminder_message"  :rows="6" placeholder="Enter Reminder Message" style="width: 100%;  resize: vertical" :errors="v$.form.reminder_message.$errors"></TextArea>
                            </div>
                        </div>
                        
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-center pt-10">
                        <button class="btn btn-danger btn-sm text-white col-md-3" @click="closeWindow"> Cancel &amp; EXIT </button>
                        <form-btn
                            :title="this.saveBtn"
                            btnCls="btn btn-primary btn-sm text-white col-md-3 ml-2"
                            style="margin-left:1vw"
                            @click="submit"
                            v-if="this.$filters.permissioncheck([this.$store.state.permission.task_task_crud_all, this.$store.state.permission.task_task_crud])"
                        ></form-btn>

                        <form-btn
                            title="Submit & Write to Encounter"
                            btnCls="btn btn-primary btn-sm text-white col-md-3 ml-2"
                            style="margin-left:1vw"
                            @click="submit('encounter')"
                        ></form-btn>
                        </div>


                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
import $ from "jquery";
import TextArea from '../../base/formFields/TextArea.vue';
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import FormBtn from "../../base/formFields/FormBtn.vue";
import DatePicker from '../../base/formFields/DatePicker.vue';
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";

// Use the beforeunload event to notify the parent window when it's being closed
window.addEventListener("beforeunload", () => {
    // Notify the parent window that the child window is being closed
    if (window.opener) {
        window.opener.postMessage("Task window is closing", "*");
    }
});

export default {
    props: {
        uniqueId: {
            type: String,
            required: true,
        },
    },
	data() {
		return {
            saveBtn: "",
            loader: false,
            providerDataSource:[],
			priority_options:[
                { value: "High", title: "High" },
                { value: "Normal", title: "Normal" },
                { value: "Low", title: "Low" },
            ],
            dateVal: new Date(new Date().setDate(14)),
            dataSource : [{ text: "Select A Patient", value: "" }],
            fields : { text: "title", value: "value" },
            minSearchLength: 0, // Minimum length for filtering
            allowFiltering:true
		};
	},
    setup: () => {
		return { v$: useVuelidate() };
	},
    components: {
        TextArea,
        SelectBoxStatic,
        FormBtn,
        DatePicker,
        'ejs-dropdownlist': DropDownListComponent,

    },
    computed: {
        form() {
			return this.$store.state.task.form;
		},
        vuelidateExternalResults() {
			return this.$store.state.task.vuelidateExternalResults;
		},
    },
	watch: {
		
	},
	mounted() {
        this.resetforms()
        this.edit()
        this.fetchProviderData()
        if(this.$route.name!='TaskCreate') {
            this.fetchPatientDetail()
        }
    },
	methods: {
        fetchProviderData() {
			axios.post("json_list/provider", { 'roleId': '' })
				.then((response) => {
					const providerTempData = []
					const details = response.data.data;
					$.each(details, function (key, detail) {
                        if (!detail.deleted_datetime){
                            providerTempData.push({
                                value: detail.user_id,
                                title: detail.full_name,
                            })
                        }
					})
					this.providerDataSource = providerTempData
				})
		},
        submit(action='save') {
            this.v$.$validate();
			if (!this.v$.$error) {
                this.loader = true;
                this.form.patient_id = this.$route.params.patient_id;

                let url
				if (this.$route.name === "PatientTaskEdit") {
                    this.form.id = this.$route.params.id;
					url = "patient/task/update";
				} else {
					url = "patient/task/store";
				}

                if (action == 'encounter') {
                    this.form.action = action
                }
                axios.post(url, this.form)
				.then((response) => {
                    this.loader = false;
					if (response.status == 200) {
                        this.$filters.moshaToast(response.data.message, "success");
                        this.closeWindow()
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }
        },
        closeWindow(){
            if(this.$route.name=='TaskCreate') {
                this.$router.push(`/task`);
            } else {
                this.$router.push(`/patient/task/${this.$route.params.patient_id}/list`);
            }
            // if (window.opener === null) {
            //     // console.log('This page was opened in a new window or tab.');
            //     this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}`);

            // } else {
            //     // console.log('This page was opened from another page.');
            //     window.close();
            // }
        },
        edit() {
            if (this.$route.name === "PatientTaskEdit") {
                this.saveBtn = "Update & EXIT";
                axios.post("patient/task/retrieve", { 'patient_id': this.$route.params.patient_id, 'id': this.$route.params.id})
                    .then((response) => {
                        if (response.data.status === 200) {
                            this.$store.state.task.form = response.data.data;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error")
                        }
                    }).catch(error => {
                        this.$filters.moshaToast(error.message, "error")
                    });
            } else {
                this.form.service_date = new Date().toISOString().substr(0, 10); // Format as YYYY-MM-DD
                this.saveBtn = "Submit & EXIT";
            }
        },
        resetforms() {
			Object.assign(
				this.$store.state.task.form,
				this.$store.state.task.defaultFormData
			);
			this.$store.state.task.form = {}
		},
        onFilter(args) {
            // Update the searchText based on user input during filtering
            this.searchText = args.text;
            if (this.searchText.length > this.minSearchLength) {
                this.getPatientList()
            } else {
                this.dataSource = [{ text: "Select A Patient", value: "" }]
            }
        },
        selectPatient(){
            let selectedUser = document.getElementById("patient_id").value;

            selectedUser = selectedUser.match(/\[(\d+)\]/);
            selectedUser =  selectedUser ? selectedUser[1] : "";
            this.filter.patient_id = selectedUser
        },
        getPatientList(){
            axios.post("patient/list", {'filter_patient':this.searchText, })
				.then((response) => {
					this.patientList = response.data.data.map((item) => {
						return { value: item.id, title: item.full_name+' ['+item.patient_id+']' };
					});
                    this.dataSource = this.patientList
				});
        },
        fetchPatientDetail() {
            let params = { 'id': this.uniqueId }
            if (!this.uniqueId) {
                params = { 'id': this.$route.params.patient_id }
            }
			axios.post("patient/detail", params)
				.then((response) => {
					if (response.data.status === 200) {
						let patient_detail = response.data.data
                        this.$store.state.task.form.patient_id=patient_detail.id
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
	},
    validations() {
		return this.$store.state.task.validationRules;
	},
    beforeCreate() {
    }
};
</script>