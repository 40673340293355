<template>
	<div :class="divClass">
		<div class="form-group" :class="{ error: errors.length }">
			<label :class="labelClass">{{ title }}
				<small v-show="isRequired" class="asterisksign">*</small></label>
			<div class="input-group controls">
				<input :type="type" name="text" :id="title" class="form-control" :value="modelValue"
					@input="$emit('update:modelValue', $event.target.value)" :placeholder="title"
					autocomplete="new-password" />
				<a href="javascript:void(0)" class="input-group-prepend input-group-addon pt-10" v-show="showPassword"
					@click="show(show_icon)" style="background: #f5f8fa;border-color: #d4dde6;">
					<i id="password_icon" class="fa fa-eye fs-14" aria-hidden="true"></i>
				</a>
				<a href="javascript:void(0)" class="input-group-addon" v-show="suggestion" @click="password_suggestion"
					style="background: #f5f8fa;border-color: #d4dde6;border-radius: 0 3px 3px 0;padding-top: 8px;"><i
						class="fa fa-magic fs-14" aria-hidden="true"></i></a>
				<span class="form-text text-muted" v-if="notes != ''">{{ notes }}</span>
			</div>
			<div class="error-msg">
				<ul class="mb-0 p-0 fs-14">
					<li class="d-block" v-for="(error, index) of errors" :key="index">
						{{ error.$message }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		type:{
			type:String,
			default:"password"
		},
		title: {
			type: String,
			default: ""
		},
		modelValue: {
			type: String,
			default: ""
		},
		errors: {
			type: Array,
			default: () => []
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		showPassword: {
			type: Boolean,
			default: false
		},
		suggestion: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ""
		},
		labelClass: {
			type: String,
			default: "form-label"
		},
		divClass: {
			type: String,
			default: "col-md-6"
		},
		notes: {
			type: String,
			default: ""
		},
	},
	methods: {
		password_suggestion() {
			console.log(this.title)
			var length = 8,
				charset = "12345677890!@£$%^&*()-=_+qwertyuiopasdfghjklzxcvbnmQWERTYUIOPSDFGHJKLZXCVBNM[]{};:\\|,<.>/?",
				retVal = "";
			if (this.title == 'PIN') {
				charset = "12345677890QWERTYUIOPSDFGHJKLZXCVBNM"
			}

			for (var i = 0, n = charset.length; i < length; ++i) {
				retVal += charset.charAt(Math.floor(Math.random() * n));
			}
			this.$emit("update:modelValue", retVal);
		},
		show(show_icon) {
			var passwordIcon = document.getElementById("password_icon");
			if (show_icon == 0) {
				document.getElementById('Password').setAttribute('type', 'text');
				passwordIcon.classList.add("fa-eye-slash");
				passwordIcon.classList.remove("fa-eye");
				this.show_icon = 1
			} else {
				document.getElementById('Password').setAttribute('type', 'password');
				passwordIcon.classList.add("fa-eye");
				passwordIcon.classList.remove("fa-eye-slash");
				this.show_icon = 0
			}
		}
	},
	data() {
		return {
			show_icon: 0,
		}
	}
};
</script>