import {
	helpers,
	required,
    email
} from "@vuelidate/validators";

const clinic = {
    namespaced: true,

    state: {
		defaultFormData: {
            'organization_name':null,
            'address':null,
            'city':null,
            'state':null,
            'zipcode':null,
            'phone_number':null,
            'fax':null,
            'email':null,
        },
        form:{
            'organization_name':null,
            'address':null,
            'city':null,
            'state':null,
            'zipcode':null,
            'phone_number':null,
            'fax':null,
            'email':null,
        },
        validationRules: {
			form: {
				organization_name: {
					required: helpers.withMessage("Please enter clinic name.", required),
				},
                city: {
					required: helpers.withMessage("Please enter clinic city.", required),
				},
                email: {
					required: helpers.withMessage("Please enter clinic email.", required),
                    email: helpers.withMessage("Please enter valid clinic email.", email),
				},
                state: {
					required: helpers.withMessage("Please enter clinic province.", required),
				},
                zipcode:{
                    required: helpers.withMessage("Please enter clinic postal.", required),
                }
            }
        }
    }
}

export default clinic;
