import {
	helpers,
	required,
	email,
	minLength,
	maxLength,
	sameAs
} from "@vuelidate/validators";

const provider = {
	namespaced: true,

	state: {
		listUrl: "/provider/list",
		form: {
			title: null,
			first_name: null,
			last_name: null,
			email: null,
			suffix: null,
			type: null,
			gender: null,
			specialty: null,
			home_phone: null,
			cell_phone: null,
			work_phone: null,
			fax: null,
			zip_code: null,
			country: null,
			state: null,
			city: null,
			address: null,
			username: null,
			password: null,
			password_confirmation: null,
		},
		validationRules: {
			form: {
				first_name: {
					required: helpers.withMessage("Please Enter Provider's First Name.", required),
				},
				last_name: {
					required: helpers.withMessage("Please Enter Provider's Last Name.", required),
				},
				email: {
					email: helpers.withMessage("Email address is not valid", email),
				},
				username: {
					required: helpers.withMessage("Username is required", required),
					minLength: helpers.withMessage(() => 'Username must be between 8 to 16 characters.', minLength(8)),
					maxLength: helpers.withMessage(() => 'Username must be between 8 to 16 characters.', maxLength(16)),
				},
				password: {
					required: helpers.withMessage("Password is required", required),
					minLength: helpers.withMessage(() => 'Password must be between 8 to 16 characters.', minLength(8)),
					maxLength: helpers.withMessage(() => 'Password must be between 8 to 16 characters.', maxLength(16)),
				},
				password_confirmation: {
					required: helpers.withMessage("Confirm Password is required", required),
					minLength: helpers.withMessage(() => 'Password confirmatioin must be between 8 to 16 characters.', minLength(8)),
					maxLength: helpers.withMessage(() => 'Password confirmatioin must be between 8 to 16 characters.', maxLength(16)),
					sameAsPassword: helpers.withMessage(() => 'Password Confirmation Does Not Match.', sameAs('password')),
				},
			},
		},
		vuelidateExternalResults: {
			form: {
				first_name: '',
				last_name: '',
			}
		},
		list: [],
	},

	getters: {},
	mutations: {},
	actions: {},
};

export default provider;