<template>
	<!-- Top section with settings and provider selection -->
	<div class="d-flex justify-content-between py-4 px-xxl-5 align-items-center calender_header">
		<div class="position-relative">
			<div class="search" v-if="currentView!=='Month'">
				<img class="serch_icon" src="/images/search-icon.svg" alt="">
				<input type="text" @keyup="patientSearch" class="form-control" placeholder="Search Patient" v-model="patient_search" @blur="patientSearch" ref="globalSearch" @focus="patientSearch">
			</div>
			<div class="search_drop_box" v-if="patientList.length > 0 || this.isShowCreateDemographics">
				<slot v-for="(item, index) in patientList" :key="index">
					<div class="con_drop_line" >
						<div class="row px-2 py-3 align-items-center">
							<div class="col-md-6">
								<div class="man_haeding">{{ item.last_name }}, {{ item.first_name }}
									<span>({{ item.gender }})</span>
								</div>
								<ul>
									<li>
										DOB: {{ item.dob }}
									</li>
									<li>
										HIN: {{ item.health_insurance_no }}
									</li>
									<li>
										Tel: {{ item.cell_phone }}
									</li>
								</ul>
							</div>
							<div class="col-md-6 text-end">
								<ol class="">
									<li class="prevent">
										<router-link :to="{ name: 'PatientChart', params: { patient_id: item.patient_id, } }" target="_blank">M</router-link>
									</li>
									<li class="prevent">
										<router-link :to="{ name: 'PatientEChart', params: { patient_id: item.patient_id, } }" target="_blank">E</router-link>
									</li>
									<li class="prevent">
										<router-link :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }" target="_blank">Rx</router-link>
									</li>
									<li class="prevent">
										<router-link :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }" target="_blank">Label</router-link>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</slot>
				<div class="create_grpc" :class="{ 'border-top': patientList.length > 0 }"><router-link target="_blank" to="/patient/create">CREATE DEMOGRAPHIC</router-link></div>
			</div>
			<!-- <router-link to="/patient/create" class="patient_icon">
				<img src="/images/person-male-icon.svg" >
			</router-link> -->
		</div>
		<div class="d-flex">
			<div class="menu_list">
				<ul class="p-0 m-0">
					<li class="p-0">
						<div class="calendar">
							<ejs-datepicker ref='mainDatePicker' :change="changeScheduleDate" class="text-center e-field form-control "
								:format="{ skeleton: 'full' }" :focus='onFocus' :showClearButton="false"
								:value="selectedDate" />
						</div>
					</li>
					<!-- <li @click="changeView('Today', $event)">Today</li> -->
					<li class="active" @click="changeView('Day', $event)">Day</li>
					<li @click="changeView('Week', $event)">Week</li>
					<li @click="changeView('Month', $event)" class="me-0">Month</li>
				</ul>
			</div>
			<img id="settingIcon" src="/images/dots-icon.svg" class="dot_icon" @click="openSetting">
			<div class="selact_dropdown">
				<selectCheckBox :dataSource='provider_list' labelName="Select Provider(s)" @clicked="selected_provider" @last-selected-item="handleLastSelectedItem"
					:displayDiv="displayDiv" :key="select_box_key" />
			</div>
		</div>
	</div>
	<!-- Main section with the schedule view -->
	<div :key="scheduleKey">
		<slot v-if="currentView!=='Month'">
			<div class="calender_page_btn">
				<img src="/images/calender-left-arrow.svg" @click="checkMultiPrev" v-if="this.currentPage!=1" class="img-fluid left_icon">
				<!-- <span>{{ this.currentPage }} / {{ this.totalPages }}</span> -->
				<img src="/images/calender-right-arrow.svg" @click="checkMultiNext" v-if="this.currentPage!=this.totalPages" class="img-fluid right_icon">
			</div>
		</slot>	

		<!-- Essential JS 2 Scheduler component configuration -->
		<ejs-schedule id="schedule" ref='scheduleObj' height='850px' width='100%'
			:editorTemplate="'schedulerEditorTemplate'" :eventSettings='eventSettings' :views="viewsDataSource"
			:popupOpen='onPopupOpen' :eventRendered="onEventRendered" :timeScale="timeScale" :popupClose="onPopupClose"
			:allowDragAndDrop="allowDragAndDrop" :group="groupResource" :showQuickInfo="false" :startHour="startHour" :endHour="endHour"
			cssClass='header-schedule' :showHeaderBar='false' :selectedDate="selectedDate" :cellClick="onCellClick"
			:eventClick="onEventClick" :eventDoubleClick="onEventDoubleClick" :renderCell='onRenderCell'
			:dragStop="onDragStop"
			:dragStart="onDragStart" :showTimeIndicator="false">
			<!-- Custom editor template for creating or editing events -->
			<template v-slot:schedulerEditorTemplate="{ }">
				<div class="p-15 appointment_popup">
					<!-- Input fields fappointment_popupor event details -->
					<div class="row justify-content-between">
						<!-- Demographic input -->
						<div class="col-md-6">
							<label class="form-label mb-3">Demographic </label>
							<!-- Input field for Demographic -->
							<div class="search">
								<img class="serch_icon" src="/images/search-icon.svg" alt="">
								<input type="text" id="patient_id" name="patient_id" class="e-field e-input form-control" placeholder="Search Patient" @keyup="schedulerPatientSearch" v-model="appointmentForm.patient_name" autocomplete="off" ref="globalModalSearch">
								<div class="invalid-feedback" style="display:block;position: absolute;" v-for="(error, index) of v$.appointmentForm.patient_id.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="search_drop_box global-search" v-if="schedulerPatientList.length > 0">
									<slot v-for="(item, index) in schedulerPatientList" :key="index">
										<div class="con_drop_line" @click="schedulerPatientSelection($event, item)">
											<div class="row px-2 py-3 align-items-center">
												<div class="col-md-6">
													<div class="man_haeding">{{ item.last_name }}, {{ item.first_name }}
														<span>({{ item.gender }})</span>
													</div>
													<ul>
														<li>
															DOB: {{ item.dob }}
														</li>
														<li>
															HIN: {{ item.health_insurance_no }}
														</li>
														<li>
															Tel: {{ item.cell_phone }}
														</li>
													</ul>
												</div>
												<div class="col-md-6 text-end">
													<ol class="">
														<li class="prevent"><router-link :to="{ name: 'PatientChart', params: { patient_id: item.patient_id, } }" target="_blank">M</router-link></li>
														
														<li class="prevent"><router-link :to="{ name: 'PatientEChart', params: { patient_id: item.patient_id, } }" target="_blank">E</router-link></li>
														<li class="prevent"><router-link :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }" target="_blank">Rx</router-link></li>
														<li class="prevent"><router-link :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }" target="_blank">Label</router-link></li>
													</ol>
												</div>
											</div>
										</div>
									</slot>
									<div class="create_grpc"><router-link target="_blank" to="/patient/create">CREATE DEMOGRAPHIC</router-link></div>
								</div>
							</div>
							<div class="pt-3 pb-4 mb-3 patient_card_detail">
								<div class="card_status" v-if="this.appointmentForm.is_hcv_verified"><div class="d-flex"><span class="icon">&#9679;</span> <span class="txt"> Patient Health Card Passed Validation</span></div></div>
								<div class="card_status" v-else><div class="d-flex"><span class="icon red">&#9679;</span> <span class="txt"> Patient Health Card Does Not Exist / Invalid</span></div></div>
								<!-- <div class="ps-3 card_status" v-else-if="!this.appointmentForm.health_insurance_no && this.appointmentForm.id"><div class="mb-0"><span class="icon red">&#x25CB;</span> <span class="txt"> Patient Health Card Does Not Exist / Invalid</span></div></div> -->
							</div>
						</div>
						<!-- Scheduling Provider input -->
						<div class="col-md-6 ps-lg-3">
							<div class="ps-lg-4">
								<label class="form-label mb-3 ms-1">Scheduling Provider </label>
								<!-- Input field for Scheduling Provider -->
								<div class="Selact_drodwn ms-auto" ref="dropdownSProviderRef">
									<div class="dropdown-div position-relative" @click="toggleProviderList">
										<div class="form-control caption"><img src="/images/doctor-man-icon.svg"
												class="ms-0 me-2" alt=""> {{ (appointmentForm.provider_name != null) ?
													appointmentForm.provider_name : 'Select Provider' }}</div>
										<div class="list" v-show="showProviderList">
											<div v-for="(item, index) in providerDataSource" :key="index" class="item"
												@click="selectProvider(item)">
												{{ item.name }}
											</div>
										</div>
									</div>
								</div>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.provider_id.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="pt-3 pb-4 mb-3 patient_card_detail ms-1">
									<div class="Patient_task_link" v-if="this.appointmentForm.is_task" @click="redirectActiveTask(this.appointmentForm.patient_id)"><label><img src="/images/long_arrow.svg" class="img-fluid"></label><span>Patient has Unsolved Urgent Task</span></div>
									<div class="Patient_task_link mt-3" v-if="!this.appointmentForm.is_hcv_verified && this.appointmentForm.id"><label><img src="/images/long_arrow.svg" class="img-fluid"></label><span>Proceed to Create Private Invoice</span></div>
								</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-center text-center mb-3">
						<div class="col-md-3 pb-3">
							<div class="form-group">
								<label class="form-label">Date </label>
								<div class="controls">
									<ejs-datepicker ref='appointment_date' class="e-field e-input " :format="dateFormat" :showClearButton="false" name="appointment_date" id="appointment_date" @change="schedulerDateChange"></ejs-datepicker>
								</div>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.appointment_date.$errors" :key="index">
									{{ error.$message }}
								</div>
							</div>
						</div>
						<div class="col-md-3 pb-3">
							<div class="form-group">
								<label class="form-label"> Start Time </label>
								<div class="controls">
									<ejs-timepicker ref='appointment_starttime' class="e-field e-input " :step="5" :showClearButton="false" name="appointment_starttime" id="appointment_starttime" @change="schedulerStartTimeChange" :strictMode='Strict'  ></ejs-timepicker>
								</div>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.appointment_starttime.$errors" :key="index">
									{{ error.$message }}
								</div>
							</div>
						</div>
						<div class="col-md-3 pb-3">
							<div class="form-group">
								<label class="form-label"> Duration (Mins) </label>
								<div class="controls w-50 m-auto">
									<input type="number" id="duration" name="duration" class="e-field e-input form-control text-center" v-model="appointmentForm.duration" @change="schedulerDurationChange" @input="validateDuration" step="5" min="5">
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="!isValidDuration">Please enter a valid duration (multiple of 5).</div>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.duration.$errors" :key="index">
									{{ error.$message }}
								</div>
							</div>
						</div>
						<div class="col-md-3 pb-3">
							<div class="form-group">
								<label class="form-label"> End Time </label>
								<div class="controls">
									<ejs-timepicker ref='appointment_endtime' class="e-field e-input" :step="5" :showClearButton="false" :strictMode='Strict' name="appointment_endtime" id="appointment_endtime" @change="schedulerEndTimeChange" @blur="schedulerEndTimeChange" ></ejs-timepicker>
								</div>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.appointment_endtime.$errors" :key="index">
									{{ error.$message }}
								</div>
							</div>
						</div>
						<div class="col-md-3 py-3 mt-3 ">
							<label class="form-label">Status </label>
							<div class="Selact_drodwn unline_remove" ref="dropdownStatusRef">
								<div class="dropdown-div" @click="toggleStatusList">
									<div class="form-control caption"> {{ (appointmentForm.appointment_status != null) ?
										appointmentForm.appointment_status : 'Status' }} <img src="/images/down-arrow-new.svg"
											class="img-fluid" :class="{ rotate180: this.showStatusList }"></div>
									<div class="list" v-show="showStatusList">
										<div v-for="(item, index) in StatusDataSource" :key="index" class="item"
											@click="selectStatus(item)">
											{{ item.status_label }}
										</div>
									</div>
								</div>
							</div>
							<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.status_id.$errors" :key="index">
								{{ error.$message }}
							</div>
						</div>
						<div class="col-md-3 py-3 mt-3">
							<label class="form-label">Mode </label>
							<div class="Selact_drodwn" ref="dropdownModeRef">
								<div class="dropdown-div" @click="toggleTypeList">
									<div class="form-control caption"> {{ (appointmentForm.appointment_type != null) ?
										appointmentForm.appointment_type : 'Mode' }} <img src="/images/down-arrow-new.svg"
											class="img-fluid" :class="{ rotate180: this.showTypeList }"></div>
									<div class="list" v-show="showTypeList">
										<div v-for="(item, index) in TypeDataSource" :key="index" class="item"
											@click="selectType(item)">
											{{ item.name }}
										</div>
									</div>
								</div>
							</div>
							<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.type_id.$errors" :key="index">
								{{ error.$message }}
							</div>
						</div>
					</div>
					<div class="row mb-3">	
						<div class="col-md-12 pb-3">
							<div class="text-left">
								<label class="form-label">Reason </label>
								<textarea id="reason_for_visit" ref='reason_for_visit' class="e-field form-control e-input" name="reason_for_visit" rows="3" cols="50"
									style="width: 100%; height: 100px !important; resize: vertical"
									v-model="appointmentForm.reason_for_visit"></textarea>
								
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.reason_for_visit.$errors" :key="index">
									{{ error.$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<!-- Custom template for rendering events in the schedule -->
			<template v-slot:schedulerTemplate="{ data }">
				<div class="subject dayView view-main" v-if="(currentView=='Day' || currentView=='Today') && (this.checkedProviderCount() < 4 && (this.checkedProviderCount() > 0 || this.providerDataSource.length < 4))">
					<!-- <span class="calender_plushsign" v-if="data.is_more_appointment">+1</span> -->
					<ul class="calendar_data_table">
						<li>
							<div class="d-flex justify-content-between align-items-center">
								<div class="target-click">
									<span v-if="data.is_hcv_verified">●</span> 
									<span v-else class="opacity-0">○</span>
									<slot v-if="data.is_task || !data.is_hcv_verified">
										<div class="icon_tooltip">
											<Popper class="theme" placement="bottom" :content="data.patient_name" hover arrow>
												<div class="target-click toltip_dtl_prdr">
													<slot v-if="data.patient_name.length > 20">
														{{ data.patient_name.substring(0, 20) }}...
													</slot>
													<slot v-else>
														{{ data.patient_name }}
													</slot>
													<sup class="text text-danger" v-if="data.is_task">
														!
													</sup>
												</div>
											</Popper>
										</div>
									</slot>
									<slot v-else>
										<slot v-if="data.patient_name.length > 20">
											{{ data.patient_name.substring(0, 20) }}...
										</slot>
										<slot v-else>
											{{ data.patient_name }}
										</slot>
									</slot>
								</div>
								<ol class="list_menu_txt">
									<li >
										<span class="circle_count" v-if="this.extractNumberFromString(data.appointment_status)">{{ this.extractNumberFromString(data.appointment_status) }}</span>
									</li>
									<li class="fasr_latter">
										<router-link :to="{ name: 'PatientChart', params: { patient_id: data.patient_id, } }" target="_blank">M</router-link>
									</li>
									<li class="fasr_latter">
										<router-link :to="{ name: 'PatientEChart', params: { patient_id: data.patient_id, } }" target="_blank">E</router-link>
									</li>
									<li class="fasr_latter">B</li>
									<li class="fasr_latter">
										<router-link :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: data.patient_id, } }" target="_blank">Rx</router-link>
									</li>
									<li class="drop-trigger"  @click="showEncounterDropdown($event)"> 
										<i class="fa-solid fa-chevron-down"></i>
										<div :key="encKey" class="drop-options encounterDropdown d-none">
											<div class="drop-option">
												<router-link :to="{ name: 'PatientChart', params: { patient_id: data.patient_id, } }" target="_blank">
													Master Record
												</router-link>
											</div>
											<div class="drop-option">
												<router-link :to="{ name: 'PatientEChart', params: { patient_id: data.patient_id, } }" target="_blank">
													E-Chart
												</router-link>
											</div>
											<div class="drop-option">Billing</div>
											<div class="drop-option">
												<router-link :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: data.patient_id, } }" target="_blank">Rx</router-link>
											</div>
											<div class="drop-option">
												<router-link :to="{ name: 'patientMeasurementList', params: { patient_id: data.patient_id, } }" target="_blank">Measurements</router-link>
											</div>
											<div class="drop-option">Print Label</div>
											<!-- <div class="drop-option">
												<router-link :to="{ name: 'PatientEChart', params: { patient_id: data.patient_id, } }" target="_blank">Encounter</router-link>
											</div> -->
										</div>
									</li>
								</ol>
							</div>
						</li>
						<li>
							<div class="fasr_latter target-click">{{ data.reason_for_visit }}</div>
						</li>
					</ul>
				</div>
				<div class="weekView view-main"  v-if="currentView=='Week' || ((currentView=='Day' || currentView=='Today') && (this.checkedProviderCount() >= 4 || (this.checkedProviderCount() == 0 && providerDataSource.length > 4) ))">
					<!-- <span class="calender_plushsign" v-if="data.is_more_appointment">+1</span> -->
					<ul class="calendar_data_table">
						<li>
							<div class="d-flex justify-content-between align-items-center">
								<div class="target-click">
									<span v-if="data.is_hcv_verified">●</span> 
									<span v-else class="opacity-0">○</span>
									<slot v-if="data.is_task">
										<div class="icon_tooltip">
											<Popper class="theme" placement="bottom" :content="data.patient_name" hover arrow>
												<div class="target-click toltip_dtl_prdr">
													<slot v-if="data.patient_name.length > 20">
														{{ data.patient_name.substring(0, 20) }}...
													</slot>
													<slot v-else>
														{{ data.patient_name }}
													</slot>
													<sup class="text text-danger" v-if="data.is_task">
														!
													</sup>
												</div>
											</Popper>
										</div>
									</slot>
									<slot v-else>
											<slot v-if="data.patient_name.length > 20">
												{{ data.patient_name.substring(0, 20) }}...
											</slot>
											<slot v-else>
												{{ data.patient_name }}
											</slot>
									</slot>
								</div>
								<ol class="list_menu_txt">
									<li class="drop-trigger"  @click="showEncounterDropdown"> 
										<img src="/images/down-arrow-new.svg" alt="" class="img-fluid">
										<div :key="encKey" class="drop-options encounterDropdown d-none">
											<div class="drop-option">
												<router-link :to="{ name: 'PatientChart', params: { patient_id: data.patient_id, } }" target="_blank">
													Master Record
												</router-link>
											</div>
											<div class="drop-option">
												<router-link :to="{ name: 'PatientEChart', params: { patient_id: data.patient_id, } }" target="_blank">
													E-Chart
												</router-link>
											</div>
											<div class="drop-option">Billing</div>
											<div class="drop-option">
												<router-link :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: data.patient_id, } }" target="_blank">Rx</router-link>
											</div>
											<div class="drop-option">
												<router-link :to="{ name: 'patientMeasurementList', params: { patient_id: data.patient_id, } }" target="_blank">Measurements</router-link>
											</div>
											<div class="drop-option">Print Label</div>
										</div>
									</li>
								</ol>
							</div>
						</li>
					</ul>
				</div>
				<div class="monthView view-main w-100"  v-if="currentView=='Month'">
					<slot v-for="(providerStatus, key) in groupedList" :key="key">
						<slot v-if="key.split('_')[1] == data.appointment_date">
							<div class="row calendar_data_table">
								<div class="col-md-6 pe-0">
									<div>{{ key.split('_')[0] }}</div>
								</div>
								<div class="col-md-6 ps-0">
									<div class="sub_heading">| On Schedule ({{providerStatus.length}})</div>
								</div>
							</div>
						</slot>
					</slot>

					<slot v-for="(item, key) in additionalData" :key="key">
						<slot v-if="item.Date == data.Date">
							<div class="row calendar_data_table">
								<div class="col-md-6 pe-0">
									<div>{{ item.Subject }}</div>
								</div>
								<div class="col-md-6 ps-0">
									<div class="sub_heading">| {{item.Description}}</div>
								</div>
							</div>
						</slot>
					</slot>
				</div>
			</template>
			
			<!-- Configure available views for the scheduler -->
			<e-views>
				<e-view option="Day" :isSelected="true" :allowVirtualScrolling="true"></e-view>
				<e-view option="Week" :allowVirtualScrolling="true"></e-view>
				<e-view option="Month" :allowVirtualScrolling="true"></e-view>
			</e-views>

			<!-- Configure resources for grouping events (e.g., by provider) -->
			<e-resources>
				<e-resource field="provider_id" title="Provider" name="Providers" :dataSource="providerDataSource"
					textField="name" idField="id" :allowVirtualScrolling="true">
				</e-resource>
			</e-resources>
		</ejs-schedule>
	</div>
	<!-- Settings modal for adjusting preferences -->
	<div class="modal fade" id="appointmentView-setting" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeAppPrefrences"></button>					
					<div class="box">
						<h2 class="popup_title">APPOINTMENT PREFERENCES</h2>
						<div class="row box-border justify-content-center p-10">
							<!-- Input fields for setting preferences -->
							<InputField type="time" v-model="preferenceForm.startHour" title="Start Time" id="startHour" divClass="col-md-4 time_det_box" @input="checkEndTime"> </InputField>
							<InputField type="time" v-model="preferenceForm.endHour" title="End Time" id="endHour" divClass="col-md-4 time_det_box" @input="checkEndTime"> </InputField>
							<InputField type="number" v-model="preferenceForm.defaultDuration" title="Schedule Interval (Mins)" id="defaultDuration" :min="0" divClass="col-md-4 time_det_box"> </InputField>
							<!-- Submit button to store preferences -->
							<div class="col-md-11 my-5 px-0">
								<div class="con_detl ps-5">
									<label>Auto-populate preferences to show all appointments</label>
									<input class="form-check-input me-4" :checked="isAppPrefChecked" v-model="isAppPrefChecked" type="checkbox" id="checkboxNoLabel" @click="getAppPrefTime()">
								</div>
								<span class="m_title">Appointment Day-Sheet</span>
								<div class="con_detl ps-5">
									<label>Sorted by Timeline</label>
									<a href="#" class="dwn_link" @click="downloadPDF('byTimeline')">Download</a>
								</div>
								<div class="con_detl ps-5 mb-0">
									<label>Sorted by Patient Name Alphabetically</label>
									<a href="#" class="dwn_link" @click="downloadPDF('byPatientName')">Download</a>
								</div>
							</div>
							<div class="text-center">
								<button type="button" class="comman_brdr_btn mx17" data-bs-dismiss="modal">Cancel
								</button>

								<button type="button" class="comman_btn mx17" data-bs-dismiss="modal"
									@click="storePreference">Update </button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="appointment-confirm-delete" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg">
			<div class="modal-content">
				<div class="modal-body">					
					<div class="box">
							<div class="text-center py-5">
								<span class="m_title">Are you sure to DELETE this appointment?</span>
							</div>
							<div class="text-center mt-4">
								<button type="button" class="comman_brdr_btn me-4 py-2 px-5" data-bs-dismiss="modal" @click="cancelDelete">Cancel
								</button>

								<button type="button" class="comman_btn ms-4 py-2 px-5" data-bs-dismiss="modal"
								@click="confirmDelete">Delete </button>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import { Day, Week, Month, ScheduleComponent, RecurrenceEditor, DragAndDrop, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective } from "@syncfusion/ej2-vue-schedule";
// import { DateTimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import moment from "moment";
import axios from "@/scripts/axios.js";
// import { AutoComplete, DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager, CustomDataAdaptor } from "@syncfusion/ej2-data";
import { extend } from "@syncfusion/ej2-base";
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import $ from "jquery";
import InputField from "../base/formFields/InputField.vue";
import { DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { Internationalization, L10n } from '@syncfusion/ej2-base';
import useVuelidate from "@vuelidate/core";
import { required,	helpers, maxLength } from "@vuelidate/validators";
import Popper from "vue3-popper";

L10n.load({
	'en-US': {
		'schedule': {
			'newEvent': 'APPOINTMENT DETAILS',
			'editEvent': 'APPOINTMENT DETAILS',
		},
	}
});

export default {
	setup: () => {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			appointmentForm: {
				patient_id: {
					required: helpers.withMessage("Please select Patient", required)
				},
				provider_id: {
					required: helpers.withMessage("Please select Provider.", required)
				},
				appointment_date: {
					required: helpers.withMessage("Please select Date.", required)
				},
				appointment_starttime: {
					required: helpers.withMessage("Please select Start Time.", required)
				},
				appointment_endtime: {
					required: helpers.withMessage("Please select End Time.", required)
				},
				duration: {
					required: helpers.withMessage("Please select Duration.", required)
				},
				reason_for_visit: {
					required: helpers.withMessage("Please enter reason.", required),
					maxLength:helpers.withMessage("Maximum 50 characters allowed.", maxLength(50)),
				},
				status_id: {
					required: helpers.withMessage("Please select Status.", required)
				},
				type_id: {
					required: helpers.withMessage("Please select Mode.", required)
				},
			},
		};
	},
	components: {
		'ejs-schedule': ScheduleComponent,
		"e-views": ViewsDirective,
		"e-view": ViewDirective,
		"e-resources": ResourcesDirective,
		"e-resource": ResourceDirective,
		'ejs-datepicker': DatePickerComponent,
		'ejs-timepicker': TimePickerComponent,
		selectCheckBox,
		InputField,
		Popper,
	},
	data() {
		return {
			appointmentList: [],
			groupedList: [],
			showProviderList: false,
			showStatusList: false,
			showTypeList: false,
			appointmentForm: {
				id: null,
				patient_id: null,
				patient_name: null,
				provider_id: null,
				provider_name: null,
				appointment_date: null,
				appointment_starttime: null,
				appointment_endtime: null,
				duration: null,
				reason_for_visit: null,
				status_id: null,
				appointment_status: null,
				type_id: null,
				appointment_type: null,
			},
			selectedDate: new Date(),
			// Key to track changes and force re-rendering of the schedule component
			scheduleKey: 0,
			// Form data for user preferences
			preferenceForm: {
				startHour: "06:00",
				endHour: "23:00",
				defaultDuration: 10,
			},
			// Default schedule start time
			startHour: "06:00",
			// Default schedule end time
			endHour: "23:00",
			// Default event duration
			defaultDuration: 10,
			// Time scale configuration for the schedule
			timeScale: {
				enable: true,
				interval: 10,
				slotCount: 1,
			},
			// Resource configuration for grouping by provider
			groupResource: { resources: ["Providers"] },
			// Available views for the schedule
			viewsDataSource: ["Day", "Week", "Month"],
			// Event settings for the schedule
			eventSettings: {
				template: "schedulerTemplate",
				dataSource: this.dataManager(),
			},
			// List of providers for filtering
			provider_list: [],
			// Filter configuration
			filter: {
				provider_list: ['All'],
			},
			// Display flag for provider selection dropdown
			displayDiv: false,
			// Key to force re-rendering of the selectCheckBox component
			select_box_key: 0,
			// Data source for the provider dropdown in the schedule
			providerDataSource: [],
			TypeDataSource: [],
			StatusDataSource: [],
			// Flag for user inactivity
			userInactive: false,
			// Flag to track changes in the calendar
			changeInCalendar: false,
			dateFormat: 'yyyy-MM-dd',
			schedulerPatientList: [],
			patientList: [],
			patient_search: null,
			currentView: "Day",
			encKey:0,
			allowDragAndDrop: true,
			scheduledAppointments:{},
			appointmentToDelete: null,
			currentPage:1,
			totalPages:1,
			isValidDuration: true,
			Strict: true,
			isAppPrefChecked: false,
			isMultiAppointment:[],
			defaultProviderChecked:7,
			isShowCreateDemographics:false,
			lastSelectedItem:null,
			additionalData: [],
		}
	},
	provide: {
		// Provide schedule views and drag-and-drop functionality
		schedule: [Day, Week, Month, DragAndDrop]
	},
	methods: {
		formattedDescription(description) {
            // Sanitize and return the description with HTML line breaks
            return description.replace(/\n/g, '<br>');
        },
		getAppPrefTime() {
			this.isAppPrefChecked = !this.isAppPrefChecked;
			if(this.isAppPrefChecked)
			{
				var onDayDate = '';
				onDayDate = $(".e-datepicker").val();
				axios.post("appointment/appointment-preferense-time", { providers: this.filter.provider_list, calendar_current_view: this.currentView, on_day_date: onDayDate})
					.then((response) => {
						if(response.status == 200)
						{
							if(response.data.data.min_start_time && response.data.data.max_end_time && response.data.data.min_duration) {
								this.preferenceForm.defaultDuration = response.data.data.min_duration
								this.preferenceForm.startHour = response.data.data.min_start_time
								this.preferenceForm.endHour = response.data.data.max_end_time
							}
						}
					})
					.catch((error) => {
						console.log('new-call-error', error);
					})
			} else {
				this.startHour = this.preferenceForm.startHour;
				this.endHour = this.preferenceForm.endHour;
				this.defaultDuration = this.preferenceForm.defaultDuration;
				this.timeScale.interval = this.defaultDuration;
				this.scheduleKey += 1;
			}
		},
		patientSearch() {
			if (this.patient_search && this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
					.then((response) => {
						this.patientList = response.data.data;
					})
			}

			if(!this.patient_search || this.patient_search.length == 0){
				this.patientList = [];
				this.isShowCreateDemographics = true;
			}
		},
		onDragStart(){
			// args.cancel = true;
		},
		onDragStop(args){
			Object.assign(
				this.appointmentForm,
				args.data
			);
			this.appointmentForm.appointment_date = moment(args.data.StartTime).format("YYYY-MM-DD");
			this.appointmentForm.appointment_starttime = moment(args.data.StartTime).format("HH:mm:ss");
			this.appointmentForm.appointment_endtime = moment(args.data.EndTime).format("HH:mm:ss");
			// let scheduleObj = this.$refs.scheduleObj.ej2Instances;
			// if(scheduleObj.isSlotAvailable(args.data.StartTime,args.data.EndTime)){
			// }else{
			// 	args.cancel = true;
			// 	this.$filters.moshaToast("Slot Already Booked !!", "danger")
			// }
		},
		isCurrentMonth(dateStr) {
			// console.log('dateStr:-', new Date(dateStr).toJSON().slice(0, 7));
			// console.log('without-dateStr:- ', new Date().toJSON().slice(0, 7));
			// return new Date(dateStr).toJSON().slice(0, 7) === new Date().toJSON().slice(0, 7);

			const currentDate = new Date();
			const currentMonth = currentDate.getMonth();
			const currentYear = currentDate.getFullYear();
			
			const dateToCheck = new Date(dateStr);
			const checkMonth = dateToCheck.getMonth();
			const checkYear = dateToCheck.getFullYear();
			
			return currentMonth === checkMonth && currentYear === checkYear;
		},
		getOrdinalSuffix(day) {
			if (day > 3 && day < 21) return 'th'; // 4-20 are "th"
			switch (day % 10) {
				case 1: return 'st';
				case 2: return 'nd';
				case 3: return 'rd';
				default: return 'th';
			}
		},
		formatCustomDate(date) {
			var instance = new Internationalization();
			var weekday = instance.formatDate(date, { format: 'EEEE' });
			var month = instance.formatDate(date, { format: 'MMMM' });
			var day = instance.formatDate(date, { format: 'd' });

			var dayWithSuffix = day + this.getOrdinalSuffix(parseInt(day));
			return `${weekday}, ${month} ${dayWithSuffix}`;
		},
		onRenderCell(args) {
			let scheduleObj = this.$refs.scheduleObj.ej2Instances;
			if (args.elementType === 'dateHeader' && scheduleObj.ej2Instances.currentView === 'Week') {
				// var instance = new Internationalization();
				// var argsDate = instance.formatDate(args.date, { skeleton: 'full' });
				var argsDate = this.formatCustomDate(args.date);

				let ele = document.createElement('div');
				ele.innerHTML = argsDate;
				$(args.element).html(ele.firstChild);
				$(args.element).css({
					"text-align": "center",
					"font-weight": "600",
					"font-size": "16px",
					"color": "#0E2641",
				});
			} else if (args.elementType == 'dateHeader') {
				$(args.element).css("display", "none");
			} else if (args.elementType == "monthDay") {
				let ele = document.createElement('div');
				ele.innerHTML = this.getWeekName(args.date);
				$(args.element).html(ele.firstChild);
				$(args.element).css({
					"text-align": "center",
					"font-weight": "600",
					"font-size": "16px",
					"color": "#0E2641",
				});
			} else if (args.elementType === "monthCells") {
				setTimeout(() => {
					$(args.element).find('.e-appointment-wrapper .e-appointment:not(:last)').remove();
				}, 2000);
				if (!this.isCurrentMonth(args.date)) {
					if ($(args.element).hasClass('e-other-month')) {
						$(args.element).html('');
					}
				}
			}
		},
		getWeekName(date) {
			return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
		},
		changeView(item, $event) {
			this.$store.state.loader = true;
			$(".menu_list li.active").removeClass("active");
			$($event.target).addClass("active");
			if (item === 'Month') {
				this.$refs.scheduleObj.ej2Instances.group.resources = [];
				this.$refs.scheduleObj.ej2Instances.dataBind();
				this.$refs.scheduleObj.ej2Instances.changeCurrentView(item);
				this.allowDragAndDrop = false;
			} else if (item === 'Week') {
				this.$refs.scheduleObj.ej2Instances.group.resources = ["Providers"];
				this.$refs.scheduleObj.ej2Instances.dataBind();
				this.$refs.scheduleObj.ej2Instances.changeCurrentView(item);
				this.allowDragAndDrop = true;
				this.provider_list.forEach(item => {
					item.checked = false;
				});
				this.provider_list[0].checked = true;
				this.selected_provider(this.provider_list,'off')
				this.select_box_key = this.select_box_key + 1;
				this.totalPages = this.provider_list.length
			} else if(item === 'Today'){
				this.selectedDate = new Date();
				this.scheduleKey += 1;
				this.allowDragAndDrop = true;
				const checkedItemNo = this.provider_list.filter(item => item.checked == true).length;
				this.totalPages = Math.ceil(this.provider_list.length/checkedItemNo)
			} else {
				this.scheduleKey += 1;
				this.allowDragAndDrop = true;
				// this.provider_list = this.provider_list.map((item, index) => {
				this.provider_list = this.provider_list.map((item) => {
					return {
						...item,
						// checked: index < 7 ? true : false
						checked: false
					};
				});
				const checkedItemNo = this.provider_list.filter(item => item.checked == true).length;
				this.totalPages = Math.ceil(this.provider_list.length/checkedItemNo);
			}
			this.currentView = item;
		},
		onEventDoubleClick(args) {
			args.cancel = true;
		},
		onEventClick(args) {
			if (!$(args.originalEvent.srcElement).hasClass('target-click')) {
				args.cancel = true;
			} else {
				let scheduleObj = this.$refs.scheduleObj.ej2Instances;
				scheduleObj.openEditor(args.event, 'EditOccurrence')
			}
		},
		onCellClick(args) {
			let scheduleObj = this.$refs.scheduleObj.ej2Instances;
			this.selectedDate = new Date(args.startTime);
			if (scheduleObj.ej2Instances.currentView === 'Month') {
				this.scheduleKey += 1;
				this.allowDragAndDrop = true;
				this.currentView = 'Day';
			} else {
				scheduleObj.openEditor(args, 'Add');
			}
		},
		toggleTypeList() {
			this.showTypeList = !this.showTypeList;
		},
		selectType(item) {
			this.appointmentForm.type_id = item.id;
			this.appointmentForm.appointment_type = item.name;
		},
		toggleStatusList() {
			this.showStatusList = !this.showStatusList;
		},
		selectStatus(item) {
			this.appointmentForm.status_id = item.id;
			this.appointmentForm.appointment_status = item.status_label;
		},
		toggleProviderList() {
			this.showProviderList = !this.showProviderList;
		},
		selectProvider(item) {
			this.appointmentForm.provider_id = item.id;
			this.appointmentForm.provider_name = item.name;
		},
		onFocus() {
			this.$refs.mainDatePicker.show();
		},
		changeScheduleDate() {
			this.$store.state.loader = true;
			let mainDatePicker = this.$refs.mainDatePicker.ej2Instances;
			this.selectedDate = new Date(mainDatePicker.changedArgs.value);
			this.$refs.mainDatePicker.focusOut();
		},
		// Method to store user preferences in the database
		storePreference() {
			const form = {
				id: localStorage.getItem('userId'),
				preference: this.preferenceForm,
			}
			axios.post("user/update-preference", form)
				.then((response) => {
					if (response.data.status == 200) {
						// Update local storage with new preferences
						localStorage.setItem("preference", JSON.stringify(this.preferenceForm));
						// Update schedule with new preferences
						this.startHour = this.preferenceForm.startHour;
						this.endHour = this.preferenceForm.endHour;
						this.defaultDuration = this.preferenceForm.defaultDuration;
						this.timeScale.interval = this.defaultDuration;
						// Increment the key to force re-rendering of the schedule
						this.scheduleKey += 1;
					}
				}).catch(error => {
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
		},
		// Method to open the user preference settings modal
		openSetting() {
			// $('#settingIcon').toggleClass('rotateIcon');
			window.$("#appointmentView-setting").modal("show");
		},
		// Method called when the popup closes
		async onPopupClose(args) {
			if (args.type === "Editor") {
				if ($(args.event.target).parent().hasClass('e-dlg-closeicon-btn') || $(args.event.target).hasClass('e-dlg-closeicon-btn')) {
					$(".e-event-cancel").trigger('click')
					return true
				}
				this.v$.$validate();
				if(!$(args.event.target).hasClass('e-event-cancel')){
					if (this.v$.$error) {
						// let scheduleObj = this.$refs.scheduleObj.ej2Instances;
						// let startDate = new Date(new Date(`${this.appointmentForm.appointment_date} ${this.appointmentForm.appointment_starttime}`).getTime());
						
						// let endDate =  new Date(new Date(`${this.appointmentForm.appointment_date} ${this.appointmentForm.appointment_endtime}`).getTime());
						// if(!scheduleObj.isSlotAvailable(startDate,endDate)){
						// 	this.$filters.moshaToast("Slot Already Booked !!", "danger")
						// 	args.cancel = true;
						// }
						args.cancel = true;
					}
				}
			}
		},
		schedulerDateChange(){
			if($('#appointment_date').val() == ''){
				this.appointmentForm.appointment_date = null;
			}else{
				this.appointmentForm.appointment_date =  $('#appointment_date').val();
			}
		},
		// Method called when the popup opens
		async onPopupOpen(args) {
			if (args.type === "Editor") {
				this.isValidDuration = true
				console.log(moment(args.data.StartTime).format("YYYYMMDDHHmmss"))
				this.schedulerPatientList = [];
				this.v$.$reset();
				this.appointmentForm = {
					id: null,
					patient_id: null,
					patient_name: null,
					provider_id: null,
					provider_name: null,
					appointment_date: null,
					appointment_starttime: null,
					appointment_endtime: null,
					duration: null,
					reason_for_visit: null,
					status_id: null,
					appointment_status: null,
					type_id: null,
					appointment_type: null,
				};
				Object.assign(
					this.appointmentForm,
					args.data
				);
				this.appointmentForm.appointment_date = moment(args.data.StartTime).format("YYYY-MM-DD");
				$("#appointment_date").val(moment(args.data.StartTime).format("YYYY-MM-DD"));
				this.appointmentForm.appointment_starttime = moment(args.data.StartTime).format("HH:mm:ss");
				$("#appointment_starttime").val(moment(args.data.StartTime).format("LT"));
				this.appointmentForm.appointment_endtime = moment(args.data.EndTime).format("HH:mm:ss");
				$("#appointment_endtime").val(moment(args.data.EndTime).format("LT"));

				this.appointmentForm.duration = this.getDiffrenceInMinutes(this.appointmentForm.appointment_starttime,this.appointmentForm.appointment_endtime);
				if (this.appointmentForm.duration == 0) {
					this.appointmentForm.duration = this.preferenceForm.defaultDuration
					this.appointmentForm.appointment_endtime = moment(args.data.EndTime).add(this.appointmentForm.duration, 'minutes').format("HH:mm:ss");
					$("#appointment_endtime").val(moment(args.data.EndTime).add(this.appointmentForm.duration, 'minutes').format("LT"));
				}

				if (this.appointmentForm.patient_name) {
					$("#patient_id").val(this.appointmentForm.patient_name);
				}

				if (this.appointmentForm.id) {
					$(".e-event-save").html('Update')
				} else {
					this.checkAvailbilitySlot();
					$(".e-event-save").html('Save')
					let providerData = this.getObjectById(this.providerDataSource,args.data.provider_id);
					this.appointmentForm.provider_id = providerData.id;
					this.appointmentForm.provider_name = providerData.name;

					let statusData = this.getObjectById(this.StatusDataSource,1);
					this.appointmentForm.status_id = statusData.id;
					this.appointmentForm.appointment_status = statusData.status_label;

					let typeData = this.getObjectById(this.TypeDataSource,1);
					this.appointmentForm.type_id = typeData.id;
					this.appointmentForm.appointment_type = typeData.name;
				}
			}
			if (args.type === "QuickInfo") {
				if ((!args.data.Id)) {
					args.cancel = true;
				}
			}
			if (args.type === 'DeleteAlert'){
				// Customize the delete confirmation modal here
				args.cancel = true; // Cancel the default modal
				this.appointmentToDelete = args.data;
				window.$("#appointment-confirm-delete").modal("show");
			}
		},
		// Method to configure data manager for schedule events
		dataManager() {
			this.$store.state.loader = true;
			const $this = this;
			return new DataManager({
				adaptor: new CustomDataAdaptor({
					getData: function (option) {
						let params = JSON.parse(option.data);
						let reqParams = {
							start_date: moment(new Date(params.StartDate)).format("YYYY-MM-DD"),
							end_date: moment(new Date(params.EndDate)).format("YYYY-MM-DD"),
							range_type:this.currentView
						};
						// Fetch events from the server
						let isMultiAppointment = []
						axios.post("appointment/list", reqParams)
						.then((response) => {
							const data = response.data.data.appointments.map((item) => {
								if (item.is_more_appointment){
									isMultiAppointment.push(item.provider_name);
								}
								let startTime = item.appointment_date + ' ' + item.appointment_starttime;
								
								return {
									...item,
									StartTime: moment(startTime, "YYYY-MM-DD HH:mm:ss").toDate(),
									EndTime: moment(item.appointment_date + ' ' + item.appointment_endtime, "YYYY-MM-DD HH:mm:ss").toDate(),
									Id: item.id,
									Subject: item.patient_name,
									Description: item.reason_for_visit,
									Duration: item.duration
								};
							});

							const scheduleEvents = response.data.data.schedule_events;
							let ejsEvents = [];
							scheduleEvents.forEach(event => {
									let [year, month, day] = event.date.split('-').map(Number);
									month -= 1;

									let [startHour, startMinute, startSecond] = event.start_time.split(':').map(Number);
									let [endHour, endMinute, endSecond] = event.end_time.split(':').map(Number);

									const formattedStartTime = moment(event.start_time, 'HH:mm:ss').format('hh:mm A');
									const formattedEndTime = moment(event.end_time, 'HH:mm:ss').format('hh:mm A');

									ejsEvents.push({
										Id: event.Id,
										Subject: `${event.user_name}`,
										Description: `${formattedStartTime.trim()} : ${formattedEndTime.trim()}`,
										StartTime: new Date(year, month, day, startHour, startMinute, startSecond),
										EndTime: new Date(year, month, day, endHour, endMinute, endSecond),
										Date: `${year}-${month + 1}-${day}`
									});
							});

							const combinedData = data.concat(ejsEvents);
							$this.appointmentList = data;
							$this.additionalData = ejsEvents;

							option.onSuccess(combinedData);
							$this.$store.state.loader = false;
							$this.isMultiAppointment = [...new Set(isMultiAppointment)];
						});
						
					},
					batchUpdate: function (option) {
						let data = JSON.parse(option.data);
						let scheduleObj = $this.$refs.scheduleObj.ej2Instances;
						if (data.changed.length > 0) {
							axios.post("appointment/update", $this.appointmentForm)
								.then(() => {
									$this.sendMsgToSocket();
									scheduleObj.refreshEvents();
								}).catch(error => {
									if (error.response.status === 422) {
										$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
										console.log(Object.values(error.response.data.data).join(', '))
									} else {
										$this.$filters.moshaToast(error.response.data.message, "error");
									}
								})
						} else if (data.added.length > 0) {
							axios.post("appointment/store", $this.appointmentForm)
								.then(() => {
									$this.sendMsgToSocket();
									scheduleObj.refreshEvents();
								}).catch(error => {
									if (error.response.status === 422) {
										$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
										console.log(Object.values(error.response.data.data).join(', '))
									} else {
										$this.$filters.moshaToast(error.response.data.message, "error");
									}
								})
						} else if (data.deleted.length > 0) {
							axios.post("appointment/delete", $this.appointmentForm)
								.then(() => {
									$this.sendMsgToSocket();
									scheduleObj.refreshEvents();
								}).catch(error => {
									if (error.response.status === 422) {
										$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
										console.log(Object.values(error.response.data.data).join(', '))
									} else {
										$this.$filters.moshaToast(error.response.data.message, "error");
									}
								})
						}
					},
				}),
			});
		},
		// Method to get the list of providers
		getProviderList() {
			axios.post("json_list/provider", { roleId: 2 })
			.then((response) => {
				console.log('provider-list', response.data.data);
				this.provider_list = response.data.data.map((item) => {
				// this.provider_list = response.data.data.map((item, index) => {
					// if(index == 0){
					// 	return { id: item.id, name: item.full_name, checked: true };
					// }else{
					// 	return { id: item.id, name: item.full_name, checked: false };
					// }
					return { id: item.id, name: item.full_name, checked: false };
				});
				let providerDataSource = response.data.data.map((item, index) => {
					if (index < 7) {
						return { id: item.id, name: item.full_name, checked: true };
					}
				}).filter(item => item !== "" && item !== null && item !== undefined);
				this.providerDataSource = providerDataSource;

				const checkedItemNo = this.provider_list.filter(item => item.checked == true).length;
				this.totalPages = Math.ceil(this.provider_list.length/checkedItemNo);
			});
		},
		getStatusList() {
			axios.post("appointment-status/list")
			.then((response) => {
				this.StatusDataSource = response.data.data;
			})
		},
		getTypeList() {
			axios.post("appointment-type/list")
			.then((response) => {
				this.TypeDataSource = response.data.data;
			})
		},
		// Method called when providers are selected in the filter
		selected_provider(result,pagecount="on") {
			this.provider_list = result;
			if ( this.currentView == 'Week' ) {
				this.filter.provider_list = this.provider_list.map((item) => {
					if (!this.lastSelectedItem) {
						this.filter.provider_list[0].checked = true
					} else {
						if (item.id == this.lastSelectedItem) {
							item.checked = true
						} else {
							item.checked = false
						}
					}
				})
				// this.lastSelectedItem
			}
			this.filter.provider_list = this.provider_list.map((item) => {
				return (item.checked == true) ? item.id : null;
			});
			this.filter.provider_list = this.filter.provider_list.filter(n => n)
			
			var selected_count = this.provider_list.map((item) => {
				return (item.checked == true) ? item.id : null;
			}).filter(n => n).length;
			var all_count = this.provider_list.length;
			if (selected_count == all_count) {
				this.filter.provider_list = ['All'];
			}
			let scheduleObj = this.$refs.scheduleObj;

			const checkedItems = this.provider_list.filter(item => item.checked == true);
			const uncheckedItems = this.provider_list.filter(item => item.checked != true);

			let displayedList = []
			scheduleObj.getResourceCollections().forEach((resource) => {
				if (resource.name == 'Providers') {
					resource.dataSource.forEach((data) => {
						displayedList.push(data)
					})
				}
			});
			displayedList = displayedList.filter(function (item, pos) {
				return displayedList.indexOf(item) == pos;
			});

			const uncheckedIds = uncheckedItems.map(item => item.id);

			// Filter unchecked items from checkedItems and displayedItems based on the ID
			const filteredChecked = checkedItems.filter(item => !uncheckedIds.includes(item.id));
			const filteredDisplayed = displayedList.filter(item => !uncheckedIds.includes(item.id));

			// Combine filtered checked and displayed arrays
			let combinedArray = Array.from(new Set([...filteredChecked, ...filteredDisplayed].map(item => item.id))).map(id => {
				const foundItem = filteredChecked.find(item => item.id === id) || filteredDisplayed.find(item => item.id === id);
				return foundItem;
			});
			this.provider_list.forEach((data) => {
				scheduleObj.removeResource(data.id, 'Providers');
			})
			if (combinedArray.length == 0) {
				this.provider_list.forEach((data) => {
					scheduleObj.addResource(data, 'Providers');
				})
			} else {
				combinedArray.forEach((data) => {
					scheduleObj.addResource(data, 'Providers');
				})
			}
			if(pagecount=='on'){
				const checkedItemNo = this.provider_list.filter(item => item.checked == true).length;
				this.totalPages = Math.ceil(this.provider_list.length/checkedItemNo)
			}
			this.initializeTableObserver();
		},
		getObjectById(arr, id) {
			return arr.find(obj => obj.id === id);
		},
		getDiffrenceInMinutes(startTime,endTime){
			var [hours1, minutes1] = startTime.split(':').map(Number);
			var [hours2, minutes2] = endTime.split(':').map(Number);

			var totalMinutes1 = hours1 * 60 + minutes1;
			var totalMinutes2 = hours2 * 60 + minutes2;

			return Math.abs(totalMinutes2 - totalMinutes1);
		},
		// Method to render events with custom background color
		onEventRendered(args) {
			if (this.currentView!='Month') {
				if($(args.element).find('.circle_count').length > 0){
					$(args.element).find('.circle_count').css('color', args.data.color_code);
				}
				if(args.data.same_slot_count == 2){
					$(args.element).addClass('pageTwoAppointment');
					args.element.style.display = 'none';
				}else{
					$(args.element).addClass('pageOneAppointment');
				}
				$(args.element).attr('data-name', args.data.provider_name);
				args.element.style.backgroundColor = args.data.color_code;
			} else {
				args.element.style.backgroundColor = '#FFF';
				args.element.style.top="30px";
			}
		},
		// Method to get the dropdown list for various configurations
		getDropdownList(url, method, params) {
			return new DataManager({
				adaptor: new CustomDataAdaptor({
					getData: function (option) {
						let xhttp = new XMLHttpRequest();
						xhttp.onreadystatechange = function () {
							if (this.readyState == 4) {
								let request = extend({}, option, {
									httpRequest: xhttp,
								});
								if ((xhttp.status >= 200 && xhttp.status <= 299) || xhttp.status === 304) {
									let data = JSON.parse(xhttp.responseText);
									option.onSuccess(data.data, request);
								} else {
									option.onFailure(request);
								}
							}
						};
						xhttp.open(method, `${process.env.VUE_APP_API_BASE_URL}/${url}`, true);
						xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
						xhttp.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("authToken")}`);
						if (params) {
							xhttp.send(JSON.stringify(params));
						} else {
							let customOption = JSON.parse(option.data);
							xhttp.send(JSON.stringify(customOption));
						}
					},
				}),
			});
		},
		schedulerStartTimeChange(args){
			var endDate = new Date(args.value);
			endDate.setTime(endDate.getTime() + this.appointmentForm.duration * 60000);

			this.appointmentForm.appointment_starttime = moment(new Date(args.value)).format("HH:mm:ss");
			$("#appointment_starttime").val(moment(new Date(args.value)).format("LT"));

			this.appointmentForm.appointment_endtime = moment(endDate).format("HH:mm:ss");
			$("#appointment_endtime").val(moment(endDate).format("LT"));
			this.checkAvailbilitySlot()

		},
		// Method called on input change for event duration
		schedulerDurationChange() {
			let durObj = new Date(new Date(`2024-03-01 ${this.appointmentForm.appointment_starttime}`).getTime() + this.appointmentForm.duration * 60000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

			let endDate = new Date(new Date(`2024-03-01 ${this.appointmentForm.appointment_starttime}`).getTime() + this.appointmentForm.duration * 60000)

			this.appointmentForm.appointment_endtime = moment(endDate).format("HH:mm:ss");
			$("#appointment_endtime").val(durObj);
		},
		// Method called on input change for event end time
		schedulerEndTimeChange(args) {
			let startDate = new Date(new Date(args.value).getTime() - this.appointmentForm.duration * 60000)

			this.appointmentForm.appointment_starttime = moment(startDate).format("HH:mm:ss");
			$("#appointment_starttime").val(moment(startDate).format("LT"));

			this.appointmentForm.appointment_endtime = moment(new Date(args.value)).format("HH:mm:ss");
			$("#appointment_endtime").val(moment(new Date(args.value)).format("LT"));
		},
		// Method to get recurring dates for an event
		getRecurringDates: function (startDate, RecurrenceRule) {
			let recObject = new RecurrenceEditor({});
			var rules = RecurrenceRule;
			let dates = recObject.getRecurrenceDates(new Date(startDate), rules);
			let recurrencedates = [];
			for (let index = 0; index < dates.length; index++) {
				recurrencedates.push(moment(String(new Date(dates[index]).toString())).format('YYYY-MM-DD'));
			}
			recurrencedates.push(moment(String(new Date(startDate).toString())).format('YYYY-MM-DD'));
			return recurrencedates;
		},
		// Method to change the status of an event
		changeStatus(details) {
			let data = details;
			data.appointment_starttime = moment(data.StartTime).format("HH:mm:ss");
			data.appointment_endtime = moment(data.EndTime).format("HH:mm:ss");
			data.appointment_date = moment(data.StartTime).format("YYYY-MM-DD");
			data.status_change = true;
			let scheduleObj = this.$refs.scheduleObj.ej2Instances;
			axios.post("appointment/update", data)
				.then(() => {
					this.sendMsgToSocket();
					scheduleObj.refreshEvents();
				})
		},
		// Method to send a message to the WebSocket
		sendMsgToSocket() {
			try {
				const newMsg = {
					type: "appointmentTrigger",
					clinicId: localStorage.getItem("clinicId"),
					userId: localStorage.getItem("userId"),
					timeStamp: Date.now(),
				}
				this.$socket.send(JSON.stringify(newMsg));
			} catch (error) {
				this.$filters.moshaToast('WebSocket send error:' + error, "error");
			}
		},
		// Method to reset the user inactivity timer
		resetTimer() {
			this.userInactive = false;
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.userInactive = true
				this.refreshScheduler();
			}, 5000);
		},
		// Method to refresh the scheduler on user inactivity and calendar changes
		refreshScheduler() {
			if (this.userInactive === true && this.changeInCalendar === true) {
				let scheduleObj = this.$refs.scheduleObj.ej2Instances;
				scheduleObj.refreshEvents();
				this.userInactive = false;
				this.changeInCalendar = false;
			}
		},
		// Method to handle received data from the WebSocket
		handleReceivedData(socketData) {
			var msgDetail = JSON.parse(socketData);
			if (msgDetail.type == 'appointmentTrigger' && msgDetail.clinicId == localStorage.getItem("clinicId") && msgDetail.userId != localStorage.getItem("userId")) {
				this.changeInCalendar = true;
				this.refreshScheduler();
				this.resetTimer();
			}
		},
		schedulerPatientSearch() {
			if (this.appointmentForm.patient_name.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.appointmentForm.patient_name })
					.then((response) => {
						this.schedulerPatientList = response.data.data;
					})
			}
		},
		schedulerPatientSelection($event, item) {
			if (!$($event.target).hasClass('prevent')) {
				this.appointmentForm.patient_id = item.patient_id;
				this.appointmentForm.patient_name = item.full_name;
				this.schedulerPatientList = [];
			}
		},
		async downloadPDF(sortBy){
			const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/appointment/print-todays-appointment', {
				method: 'POST',
				headers: {
					'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify({ "sortBy": sortBy }),
			});
			const blob = await res.blob();
			const urlObject = URL.createObjectURL(blob);
			window.open(urlObject, '_blank')
		},
		showEncounterDropdown($event){
			// Find the parent element with class 'parent-element'
			const parentElement = ($($event.target).hasClass('drop-trigger')) ? $($event.target) : $($event.target).parent();
			if (parentElement) {
				// Find the child element with class 'encView'
				const encViewElement = parentElement.find('.encounterDropdown');
				if (encViewElement) {
					if(encViewElement.hasClass('d-none')){
						encViewElement.removeClass('d-none');
					}else{
						encViewElement.addClass('d-none');
					}
				}
			}
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				this.patient_search = '';
				this.patientList = [];
				this.isShowCreateDemographics = false;
			}
			const dropdownEle = this.$refs.dropdownStatusRef;
			if (dropdownEle && !dropdownEle.contains(event.target)) {
				// console.log('Clicked outside');
				this.showStatusList = false
			}

			const dropdownModeEle = this.$refs.dropdownModeRef;
			if (dropdownModeEle && !dropdownModeEle.contains(event.target)) {
				// console.log('Clicked outside');
				this.showTypeList = false
			}
			
			const dropdownSProviderEle = this.$refs.dropdownSProviderRef;
			if (dropdownSProviderEle && !dropdownSProviderEle.contains(event.target)) {
				// console.log('Clicked outside');
				this.showProviderList = false
			}
			
			
			const globalModalSearchEle = this.$refs.globalModalSearch;
			if (globalModalSearchEle && !globalModalSearchEle.contains(event.target) && !event.target.closest(`.search_drop_box`)) {

				// console.log('Clicked outside');
				this.appointmentForm.patient_id = null;
				this.schedulerPatientList = [];
			}
		},
		checkAvailbilitySlot(){
			axios.post("appointment/check-availability", { 
				provider_id: this.appointmentForm.provider_id,
				appointment_date: this.appointmentForm.appointment_date,
				appointment_starttime: this.appointmentForm.appointment_starttime,
				})
					.then((response) => {
						let detail = response.data.data;
						if (!detail.is_available){
							this.$filters.moshaToast("The slot is currently unavailable. Please try another slot.", "error");
						}
			}).catch(error => {
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
		},
		confirmDelete() {
			if (this.appointmentToDelete) {
				const $this = this;
				let scheduleObj = $this.$refs.scheduleObj.ej2Instances;
				axios.post("appointment/delete", $this.appointmentForm)
					.then(() => {
						$this.sendMsgToSocket();
						scheduleObj.refreshEvents();
					}).catch(error => {
						if (error.response.status === 422) {
							$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
							console.log(Object.values(error.response.data.data).join(', '))
						} else {
							$this.$filters.moshaToast(error.response.data.message, "error");
						}
					})
				this.appointmentToDelete = null;
				window.$("#appointment-confirm-delete").modal("hide");
			}
		},
		cancelDelete() {
			this.appointmentToDelete = null;
			window.$("#appointment-confirm-delete").modal("hide");
		},
		checkMultiNext() {
			// Find the indices of the checked items
			const checkedIndices = this.provider_list.reduce((indices, item, index) => {
				if (item.checked) {
					indices.push(index);
				}
				return indices;
			}, []);
			
			// If there are no checked items, exit the function
			if (checkedIndices.length === 0) return;
			
			// Calculate the index of the next item(s) based on the number of checked items
			const lastCheckedIndex = checkedIndices[checkedIndices.length - 1];
			let nextIndices = [];
			let num;
			if(this.currentView == 'Day'){
				num = 7;
			}else{
				num = 1;
			}

			for (let i = lastCheckedIndex + 1, count = 0; count < num; i++) {
				if (!this.provider_list[i % this.provider_list.length].checked) {
					nextIndices.push(i % this.provider_list.length);
					count++;
				}
			}
			nextIndices = [...new Set(nextIndices)];
			
			// Uncheck all items
			this.provider_list.forEach(item => {
				item.checked = false;
			});
			
			// Check the next item(s) based on the number of checked items
			nextIndices.forEach(index => {
				this.provider_list[index].checked = true;
			});
			this.selected_provider(this.provider_list,"off")

			// let checkedItemNo = this.provider_list.filter(item => item.checked == true).length;
			// if (checkedItemNo == 0) {
			// 	checkedItemNo = this.provider_list.length
			// }
			
			// this.totalPages = Math.ceil(this.provider_list.length/checkedItemNo)

			this.currentPage += 1;
			// if (this.currentPage  > this.totalPages) {
			// 	this.currentPage = 1;
			// }
		},
		checkMultiPrev() {
			// Find the indices of the checked items
			const checkedIndices = this.provider_list.reduce((indices, item, index) => {
				if (item.checked) {
					indices.push(index);
				}
				return indices;
			}, []);
			
			// If there are no checked items, exit the function
			if (checkedIndices.length === 0) return;
			
			// Calculate the index of the previous item(s) based on the number of checked items

			const firstCheckedIndex = checkedIndices[0];
			let previousIndices = [];
			let num;
			if(this.currentView == 'Day'){
				num = 7;
			}else{
				num = 1;
			}

			for (let i = firstCheckedIndex - 1, count = 0; count < num; i--) {
				if (i < 0) {
					i = this.provider_list.length - 1;
				}
				if (!this.provider_list[i].checked) {
					previousIndices.unshift(i);
					count++;
				}
			}

			previousIndices = [...new Set(previousIndices)];
			// const previousIndices = checkedIndices.map(index => {
			// 	const previousIndex = index - 1;
			// 	return previousIndex < 0 ? this.provider_list.length - checkedIndices.length : previousIndex;
			// });
			
			// Uncheck all items
			this.provider_list.forEach(item => {
				item.checked = false;
			});
			
			// Check the previous item(s) based on the number of checked items
			previousIndices.forEach(index => {
				this.provider_list[index].checked = true;
			});
			this.selected_provider(this.provider_list,'off')

			// let checkedItemNo = this.provider_list.filter(item => item.checked == true).length;
			// if (checkedItemNo == 0) {
			// 	checkedItemNo = this.provider_list.length
			// }
			// this.totalPages = Math.ceil(this.provider_list.length/checkedItemNo)

			this.currentPage -= 1;
			// if (this.currentPage  == 0) {
			// 	this.currentPage = this.totalPages;
			// }
		},
		extractNumberFromString(str) {
			const match = str.match(/\d+/);
			if (match) {
				return parseInt(match[0]);
			} else {
				return "";
			}
		},
		validateDuration() {
			this.isValidDuration = this.appointmentForm.duration % 5 === 0;
		},
		closeAppPrefrences(){
			window.$("#appointmentView-setting").modal("hide");
		},
		activateEventListener(){
			let providercnt = this.checkedProviderCount()
			let totalProvider = this.providerDataSource.length
			document.querySelectorAll('.seePrevAppointment').forEach(function(element) {
				if (providercnt < 4 || (providercnt == 0 && totalProvider > 4)) {
					element.parentNode.classList.add('paginationline');
				} else {
					element.parentNode.classList.remove('paginationline');
				}
				element.addEventListener('click', function() {
					$(this).next().text("1 / 2");
					var clickedDataName = $(this).attr('data-name');
					$('.pageTwoAppointment[data-name="' + clickedDataName + '"]').css("display", "none");
					$('.pageOneAppointment[data-name="' + clickedDataName + '"]').css({"display":"block","left": "0%"});
				});
			});
			document.querySelectorAll('.seeNextAppointment').forEach(function(element) {
				element.addEventListener('click', function() {
					$(this).prev().text("2 / 2");
					var clickedDataName = $(this).attr('data-name');
					$('.pageOneAppointment[data-name="' + clickedDataName + '"]').css("display", "none");
					$('.pageTwoAppointment[data-name="' + clickedDataName + '"]').css({"display":"block","left": "0%"});
				});
			});
		},
		checkedProviderCount(){
			return this.provider_list.filter(item => item.checked).length;
		},
		checkEndTime(){
			if (this.preferenceForm.startHour && this.preferenceForm.endHour) {
				if (this.compareTimes(this.preferenceForm.startHour, this.preferenceForm.endHour) > 0) {
					// If end time is less than start time, set end time to start time
					// this.preferenceForm.endHour = this.preferenceForm.startHour;
					this.$filters.moshaToast("End time should geather than start time", "danger")
				}
			}
		},
		compareTimes(time1, time2) {
			const [hours1, minutes1] = time1.split(':').map(Number);
			const [hours2, minutes2] = time2.split(':').map(Number);
			
			if (hours1 < hours2) {
				return -1;
			} else if (hours1 > hours2) {
				return 1;
			} else {
				// If hours are equal, compare minutes
				if (minutes1 < minutes2) {
				return -1;
				} else if (minutes1 > minutes2) {
				return 1;
				} else {
				return 0;
				}
			}

		},
		redirectActiveTask(patientId){
			const activeTaskUrl = this.$router.resolve({ name: 'PatientEChartActiveTaskList', params: { 'patient_id':patientId} }).href;

			// Open the URL in a new tab
			window.open(activeTaskUrl, '_blank');
		},
		initializeTableObserver() {
			const intervalId = setInterval(() => {
				const tables = $('div.e-date-header-wrap tbody tr:first-child');
				if (tables.length > 0) {
					const allTdsFound = tables.toArray().every(table => $(table).find('td').length > 0);
					if (allTdsFound) {
						clearInterval(intervalId);
						this.attachEventListenersToTables(tables);
					}
				}
			}, 1000);
		},
		attachEventListenersToTables(tables) {
			tables.each((index, table) => {
				const cells = $(table).find('td');
				cells.each((index, cell) => {
					if (!cell.hasAttribute('data-event-listener-attached')) {
						$(cell).on('click', this.handleTableClick);
						$(cell).attr('data-event-listener-attached', 'true');
					}
				});
			});
		},
		handleTableClick(event) {
			event.stopPropagation();
			if (event.target.classList.contains('e-text-ellipsis')) {
				const nameToMatch = event.target.textContent.trim();
				
				const matchedItem = this.provider_list.find(item => item.name === nameToMatch);
				if (matchedItem) {
					const id = matchedItem.id;
					const isChecked = matchedItem.checked;

					let newData = [];
					if (isChecked === true) {
						newData = this.provider_list.map(provider => ({
							...provider,
							checked: false,
						}));
					} else if (isChecked === false) {
						newData = this.provider_list.map(provider => ({
							...provider,
							checked: provider.id === id,
						}));
					}
					this.selected_provider(newData, "on");
				} else {
					console.log('No match found for:', nameToMatch);
				}
			}
		},
		handleLastSelectedItem(item) {
			console.log('Last selected item:', item);
			this.lastSelectedItem = item
		}
	},
	created() {
		if (localStorage.getItem('preference')) {
			this.preferenceForm = JSON.parse(localStorage.getItem('preference'));
			this.startHour = this.preferenceForm.startHour;
			this.endHour = this.preferenceForm.endHour;
			this.defaultDuration = this.preferenceForm.defaultDuration;
			this.timeScale.interval = this.defaultDuration;
		}		
		$(".encounterDropdown").hide();
	},
	mounted() {
		this.initializeTableObserver();
		this.getProviderList();
		this.getStatusList();
		this.getTypeList();
		const $this = this;
		document.body.addEventListener("click", function (evt) {
			if (!$(evt.target).closest('.selectCheckBox').length) {
				$this.displayDiv = false;
				$this.select_box_key = $this.select_box_key + 1;
			}
			let time = null;
			if($(evt.target).hasClass('e-time-slots')){
				time = $(evt.target).children().text()
				console.log(time,1);
			}else if($(evt.target).parent().hasClass('e-time-slots')){
				time  = $(evt.target).text();
				console.log(time,2);
			}

			if(time){
				let date = moment($this.selectedDate).format("YYYY-MM-DD");

				var newTime = new Date(new Date("1970/01/01 " + time) + ($this.defaultDuration * 60000 *2)).toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'});


				let endDate = new Date(`${date} ${newTime}`);
				let startDate = new Date(`${date} ${time}`);

				let scheduleObj = $this.$refs.scheduleObj.ej2Instances;
                let cellData ={
                      startTime: startDate,
                      endTime: endDate,
                };
                scheduleObj.openEditor(cellData,'Add');
			}
			
		});
		this.resetTimer();
		window.addEventListener('mousemove', this.resetTimer);
		window.addEventListener('keydown', this.resetTimer);
		window.addEventListener('scroll', this.resetTimer);

		document.addEventListener('click', this.handleClickOutside);
	},
	beforeUnmount() {
		window.removeEventListener('mousemove', this.resetTimer);
		window.removeEventListener('keydown', this.resetTimer);
		window.removeEventListener('scroll', this.resetTimer);
		clearTimeout(this.timeout);
	},
	computed: {
		// Computed property to get socket data from the store
		socketData() {
			return this.$store.getters.getSocketData;
		},
	},
	watch: {
		// Watcher for socket data changes
		socketData: {
			handler(newData) {
				if (newData) {
					this.handleReceivedData(newData);
				}
			},
			immediate: true,
		},
		"appointmentForm.reason_for_visit"(newVal){
            this.appointmentForm.reason_for_visit = newVal.toUpperCase();
        },
		"appointmentList"(list){
			const grouped = {};
			list.forEach(appointment => {
				const key = `${appointment.provider_name}_${appointment.appointment_date}`;
				if (!grouped[key]) {
				grouped[key] = [];
				}
				grouped[key].push(appointment);
			});
			this.groupedList =  grouped;
		},
		"isMultiAppointment"(newVal){
			let $this = this;
			$('.e-text-ellipsis').each(function() {
				var pName = $(this).text();
				if(newVal.includes(pName) && ['Today','Day'].includes($this.currentView)){
					if (!$(this).next().hasClass('paginationDiv')) {
						$(this).after('<div class="icon paginationDiv"><span class="seePrevAppointment" data-name="'+pName+'"><img src="/images/calender-left-sub-arrow.svg" class="img-fluid"></span><span class="text">1 / 2</span><span class="seeNextAppointment" data-name="'+pName+'" ><img src="/images/calender-right-sub-arrow.svg" class="img-fluid"></span></div>');
					}
				}
			});
			this.activateEventListener()
		}
	}
};
</script>
<style>
	/* calander css start */
	.paginationDiv{display: flex;align-items: center;color: #0E2641;font-size: 14px;font-weight: 600;line-height: normal;justify-content: center;}
	.paginationDiv.paginationline {position: absolute;top: 0;right: 42px;bottom: 2px;}
	.paginationDiv .text{margin: 0px 42px;}
	.paginationDiv.paginationline .text{margin: 0px 22px;}
	.paginationDiv img{width: 25px;}
	.calender_page_btn{color: #0E2641;font-size: 14px;font-style: normal;font-weight: 600;line-height: normal;text-align: center;}
	.calender_page_btn .left_icon,.calender_page_btn .right_icon{margin: 0px 15px;border-radius: 50%;cursor: pointer;position: absolute;z-index: 11;}
	/* .calender_page_btn .left_icon:hover,.calender_page_btn .right_icon:hover{background: rgba(236, 227, 209, 0.50);} */
	.calender_page_btn img.left_icon{left: 0;}
	.calender_page_btn img.right_icon{left: auto;right: 0;}
	.header-schedule {
		border: 1px solid rgb(255 255 255 / 12%);
	}

	.e-left-indent-wrap {
		display: none;
	}

	.e-schedule .e-vertical-view .e-content-wrap table td.e-work-cells:hover {
		background:rgb(236 227 209 / 50%);
		position: relative;
	}

	.e-schedule .e-vertical-view .e-work-cells:hover {
		color: rgba(209, 195, 166, 1);
	}
	.e-schedule .e-vertical-view .e-left-indent {width: 98px;}

	.e-schedule .e-vertical-view .e-content-wrap table td.e-work-cells:hover:after {
		content: '+';
		position: absolute;
		top: 0;
		font-weight: 900;
		font-size: 20px;
	}
	.e-schedule .e-vertical-view .e-work-cells, .e-schedule .e-vertical-view .e-date-header-wrap table tbody td {
		background: #fff;
		border-right: 1px solid #687789;
		border-bottom: 1.5px dashed #ECE3D1;
		border-left: 0;
	}
	.e-schedule .e-virtual-scroll .e-content-table,.e-date-header-wrap .e-schedule-table{padding-right: 50px;}
	.e-schedule .e-month-view{padding-left: 50px;}
	.e-schedule .e-vertical-view .e-content-wrap,.e-schedule .e-vertical-view .e-date-header-wrap{margin-right: 28px;}

	.e-schedule .e-vertical-view .e-date-header-wrap table tbody tr:nth-child(3) td {
		border-color: white !important;
	}
	.e-schedule .e-vertical-view .e-time-cells-wrap tr:first-child .e-time-cells {border-top: 0;}
	.e-schedule .e-vertical-view .e-time-cells-wrap .e-time-cells {
		border-bottom: 1.5px dashed #ECE3D1;
		font-size: 16px;
		border-right: 1px solid;
		color: #687789;

	}
	.e-schedule .e-vertical-view .e-time-cells-wrap .e-time-cells:hover {background: rgb(236 227 209 / 50%);}

	.header-schedule.e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-resource-cells,.e-schedule .e-vertical-view .e-date-header-wrap.e-all-day-auto,.e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells {
		border: 0;
	}

	.e-schedule .e-text-ellipsis {
		color: #0E2641;
		text-align: center;
		font-weight: 600;
		font-size: 18px;
		cursor: pointer;
	}

	.search {
		position: relative;
	}

	.search input {
		text-indent: 35px;
		padding: 8.5px 15px !important;
		border-radius: 5px;
		border: 0.5px solid #0E2641;
		color: #687789;
		line-height: normal;
		font-size: 18px;
		cursor: pointer;
		font-weight: 300;
		background: unset;
	}
	.search input:hover {
		background: #fff;
	}

	.search input:focus {
		box-shadow: none;
		border: 0.50px solid #0E2641;
	}

	.search .serch_icon {
		position: absolute;
		top: 0;
		left: 15px;
		width: 20px;
		bottom: 0;
		margin: auto;
	}

	.search_drop_box {
		border-radius: 5px;
		width: 500px;
		padding: 5px;
		position: absolute;
		left: 2rem;
		top: 50px;
		z-index: 111;
		max-height: 365px;
		overflow-x: hidden;
		overflow-y: auto;
		cursor: pointer;
		background: #FFF;
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
	}

	.search_drop_box .man_haeding {
		font-weight: 600;
		font-size: 18px;
		line-height: normal;margin-bottom: 5px;
	}

	.search_drop_box .man_haeding>span {
		font-size: 14px;
	}
	.search_drop_box .con_drop_line
	{
		border-bottom: 0.5px solid #0E2641;
	}
	.search_drop_box .con_drop_line:hover{
		background: rgba(236, 227, 209, 0.50);
	}
	.search_drop_box .create_grpc:hover {
		background:#ECE3D1;
	}

	.search_drop_box .con_drop_line:hover ol li {
		background: #0E2641;
	}

	.search_drop_box .con_drop_line:hover ol li a {
		color: #F4D09E;
	}
	.search_drop_box ul,
	.search_drop_box ol {
		padding: 0;
		margin: 0;
	}

	.search_drop_box ul {
		border-left: 3px solid #000;
		padding-left: 8px;
		display: flex;
		flex-wrap: wrap;
	}

	.search_drop_box ul li {
		list-style-type: none;
		font-size: 14px;
		font-weight: 300;
		padding-right: 15px;
		line-height: 17px;
	}

	.search_drop_box ol li {
		list-style-type: none;
		display: inline-block;
		font-size: 16px;
		font-weight: 500;
		border-radius: 5px;
		border: 1px solid #0E2641;
		margin-right: 7px;
	}

	.search_drop_box ol li:last-child {
		margin-right: 0;
	}

	.create_grpc {
		font-size: 18px;
		font-weight: 600;
		text-align: center;
		padding: 9px 0px;
		position: sticky;
		bottom: -5px;
		background: #fff;
		line-height: normal;
	}
	.create_grpc a{text-decoration: none;color: #0E2641;}

	.calender_header{background: #F6F6F7;padding: 20px;}
	.calender_header .patient_icon {
		margin-left: 25px;
		padding: 3px 9px 5px 9px;
		border-radius: 5px;
	}
	.calender_header .patient_icon:hover {
		background: rgba(236, 227, 209, 0.50);
	}
	.calender_header .patient_icon img {
		width: 25px;
	}

	.selact_dropdown {
		height: 40px;
	}

	.selact_dropdown .selectCheckBox {
		color: #0E2641;
		border: 0.5px solid #687789;
		padding: 6.5px 15px;
		border-radius: 5px;
		line-height: normal;
		font-weight: 600;
		cursor: pointer;
	}
	.selact_dropdown .selectCheckBox:hover{background: #fff;}

	.selact_dropdown .select-check-list.selectCheckBox {
		position: absolute;
		width: 100%;
		z-index: 11;
		background: #fff;
		text-align: center;
		padding: 0;
		border: 0;
		overflow: hidden;
		box-shadow: 0px 0px 6px 0px #00000040;
	}

	.selact_dropdown .select-check-option {
		font-size: 16px;
		font-weight: 600;
	}

	.selact_dropdown .select-check-option .chk-col-info {
		display: none;
	}

	.selact_dropdown .select-check-option label {
		width: 100%;
		padding: 9px 4px;
		border-bottom: 0.5px solid #ECE3D1;
		cursor: pointer;
		text-transform: uppercase;
	}

	.selact_dropdown .select-check-option label:hover {
		background-color: #ece3d180;
	}

	.selact_dropdown .select-check-option input[type="checkbox"]:checked+label {
		background: #0E2641;
		width: 100%;
		color: #F4D09E;
		border-bottom: 1px solid #f5f1e85c;
	}

	.selact_dropdown .select-check-option input[type="checkbox"]:checked+label:hover {
		background: #4A5D71;
		color: #F4D09E;
	}

	.selact_dropdown .selectCheckBox .fa-caret-down {
		padding-left: 2rem;
	}
	.selact_dropdown .selectCheckBox .doc_icon{margin-right: 10px;}
	.selact_dropdown .selectCheckBox img {
		width: 25px;
	}

	.calendar .e-datepicker.e-lib {
		font-size: 18px !important;
		font-weight: 600 !important;
		font-family: 'Inter' !important;
		order: 1;
		color: #0E2641 !important;
		cursor: pointer;
	}

	.calendar .e-input-group-icon.e-date-icon {
		margin: 0 10px 1px 0px !important;
		position: relative;
	}

	.calendar .e-input-group-icon.e-date-icon::before {
		background-image: url(/images/calendar-search.svg);
		content: '' !important;
		height: 25px;
		width: 25px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		background-size: cover;
		margin: auto;
	}

	.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
		color: #0E2641 !important;
	}

	.menu_list li {
		list-style-type: none;
		display: inline-block;
		padding: 8.5px 10px;
		line-height: normal;
		border: 1px solid #0E2641;
		margin: 0px 5px;
		border-radius: 5px;
		font-weight: 600;
		vertical-align: middle;
		text-transform: uppercase;
		cursor: pointer;
	}
	.menu_list li:first-child{margin-right: 100px;}
	.menu_list li:hover{
		background: #fff;
	}

	.menu_list li.active {
		background-color: #0E2641;
		color: #F4D09E;
	}

	.calender_header .dot_icon {
		width: 40px;
		margin: 0px 50px;
		height: 40px;
	}
	.calender_header .dot_icon:hover{background: unset;border: 1px solid #0E2641;}

	.e-calendar .e-content.e-year td:hover span.e-day, .e-calendar .e-content td.e-cell:hover span.e-day, .e-calendar .e-content td:focus span.e-day, .e-bigger.e-small .e-calendar .e-content td:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
		background-color: #fff;
	}

	.e-calendar .e-content td.e-focused-date.e-today span.e-day,
	.e-calendar .e-content td.e-focused-date.e-today span.e-day:hover {
		background-color: #0E2641;
		color: #F4D09E !important;
		border: none !important;
	}

	.calendar .e-input-group {
		padding: 0px 15px;
		height: 40px;
		border-radius: 5px;
		margin: 0;
		max-width: 340px;
	}

	.e-calendar .e-content td.e-selected span.e-day,.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,.e-calendar .e-content td.e-selected.e-focused-date:hover span.e-day,.e-calendar .e-content td.e-selected.e-focused-date span.e-day,.e-calendar .e-content.e-year td.e-selected span.e-day,.e-calendar .e-content.e-year td.e-selected:hover span.e-day {
		background-color: #0E2641;
		color: #F4D09E !important;
	}

	.e-calendar .e-content td.e-today span.e-day {
		border: 1px solid #0E2641;
		color: #0E2641;
	}

	.e-btn.e-flat.e-primary,
	.e-css.e-btn.e-flat.e-primary {
		color: #0E2641;
	}

	.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
	.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before {
		content: unset !important;
	}

	.calendar .e-input-group.e-input-focus {
		background-color: #F6F1E8;
		border: none;
	}

	.e-calendar,
	.e-bigger.e-small .e-calendar {
		max-width: 400px !important;
	}

	.e-btn.e-flat.e-primary:hover,
	.e-css.e-btn.e-flat.e-primary:hover {
		background: #0E2641;
		border-color: #0E2641;
		color: #F4D09E;
	}
	.calendar_data_table {
		font-size: 16px;
		font-weight: 500;
		padding: 0;
		font-family: 'Inter';
		color: #0E2641;
		margin: 0;
	}
	.calendar_data_table .target-click span{font-size: 5px;vertical-align: middle;margin-right: 4px;}
	.toltip_dtl_prdr:hover{text-decoration: underline}

	.calendar_data_table>li {
		display: inline-block;
		width: 51%;
		padding: 0px 11px 0px 3px;
		color: #0E2641;
	}
	.weekView .calendar_data_table>li{width: 100%;}
	.list_menu_txt .drop-trigger .drop-options a{color: #0e2641;text-decoration: none;}
	.calender_plushsign{position: absolute;right: 0;top: -4px;color: #0E2641;font-size: 14px;font-weight: 600;line-height: normal;}
	.weekView .calender_plushsign{position: absolute;right: 0;top: -4;color: #0E2641;font-size: 14px;font-weight: 600;line-height: normal;}
	.e-schedule .e-month-view .e-work-cells:hover .e-appointment {background: #f5f1e8 !important;box-shadow: none;}
	.monthView .calendar_data_table{font-size: 10px;font-weight: 600;white-space: break-spaces;}
	.monthView .calendar_data_table .sub_heading{font-size: 10px;font-weight: 500;}
	.e-schedule .e-month-view .e-appointment{border: 0;}
	.e-schedule .e-month-view .e-work-cells{background: #fff;}
	.e-schedule .e-month-view .e-work-cells, .e-schedule .e-month-agenda-view .e-work-cells,.e-schedule .e-month-view .e-date-header-wrap table td, .e-schedule .e-month-agenda-view .e-date-header-wrap table td{border-width: 0px 0px 2px 2px;border-color: #ECE3D1;}
	.e-schedule .e-month-view .e-work-cells, .e-schedule .e-month-agenda-view .e-work-cells{border-width: 0px 1px 2px 2px;}
	.e-schedule .e-month-view .e-work-cells:hover{background: rgba(236, 227, 209, 0.50);}
	.e-schedule .e-month-view .e-work-cells:hover .e-date-header{color: #0E2641;}
	.e-schedule .e-month-view .e-current-date .e-date-header {border-radius: 5px;background: #0E2641;color: #F4D09E !important;margin: 0;text-decoration: none;padding-top: 3px;}
	.e-schedule .e-month-view .e-date-header{color: #A0A3A7;font-size: 12px;font-weight: 600;line-height: normal;}
	.e-schedule .e-month-view .e-appointment .e-appointment-details,.e-schedule .e-month-view .e-appointment{overflow: unset;}
	.e-schedule .e-month-view .e-date-header-wrap table td:first-child, .e-schedule .e-month-view .e-content-wrap table td:first-child {border-left-width: 1px;}

	.calendar_data_table>li:nth-child(2) {
		background-color: rgba(255, 255, 255, 0.50);
		font-size: 14px;
		font-weight: 300;
		padding: 5px 6px 5px 6px;
	}
	.list_menu_txt .drop-trigger{position: relative;}
	.list_menu_txt .drop-trigger img{width: 25px;}
	.list_menu_txt .drop-trigger .drop-options{position: absolute;left: 0;top: 30px;z-index: 11111;border-radius: 5px;border: 0.5px solid #A0A3A7;background: #FFF;text-align: center;padding: 5px 0px;}
	.list_menu_txt .drop-trigger .drop-options	.drop-option{height: 28px;padding: 0px 20px;cursor: pointer;}
	.list_menu_txt .drop-trigger .drop-options	.drop-option:hover{background-color: #ECE3D1;}

	.list_menu_txt li {
		display: inline-block;
	}

	.list_menu_txt .fasr_latter {
		padding: 8px 5px 7px 5px;
		background: rgba(255, 255, 255, 0.50);
		margin-right: 7px;
		line-height: normal;
	}

	.list_menu_txt .fasr_latter a{
		text-decoration: none;
		color: #0E2641;
	}

	.calendar_data_table .circle_count {
		background-color: #0E2641;
		color: #fff;
		border-radius: 50%;
		padding: 2px 8px;
		line-height: 21px;
		font-size: 13px;
		font-weight: 600;
		height: 25px;
		width: 25px;
		display: inline-block;
		margin: 0px 7px 0 0;
		text-align: center;
		position: relative;
		top: -1.5px;
	}

	/*APPOINTMENT POPUP DESIGN  */
	.e-dialog .e-dlg-header {
		width: unset;
	}

	.e-schedule-dialog .e-dlg-header-content {
		padding: 0;
	}

	.e-dialog .e-dlg-header .e-title-text {
		text-align: center;
		color: #0E2641;
		font-family: Inter;
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 42px;
	}
	.appointment_popup{max-width: 690px;margin: auto;}
	.appointment_popup #patient_id,.appointment_popup .Selact_drodwn{max-width:300px;}

	.appointment_popup,
	input.e-input,
	textarea.e-input,
	.e-input-group,
	.e-input-group.e-control-wrapper {
		color: #0E2641;
		font-family: 'Inter';
	}

	.appointment_popup .form-control,
	.appointment_popup .e-input-group,
	.appointment_popup .e-input-group.e-control-wrapper {
		border-radius: 5px !important;
		border: 0.5px solid #0E2641 !important;
		box-sizing: border-box !important;
		min-height: 40px;
		font-size: 16px;
		font-weight: 400;
		padding: 8px 15px;
		line-height: normal;
		margin-bottom: 2px;

	}

	.appointment_popup .form-control:focus {
		box-shadow: none;
	}
	#appointment-confirm-delete .modal-body{padding: 60px 95px;}
	#appointment-confirm-delete .m_title{font-size: 20px;font-weight: 600;line-height: normal;}
	.patient_card_detail .card_status {padding-left: 11px}
	.patient_card_detail .card_status .txt{font-size: 14px;font-weight: 400;cursor: alias;}
	.patient_card_detail .card_status .icon{font-size: 18px;vertical-align: middle;margin-right: 18px;}
	.patient_card_detail .card_status .icon.red{color:#C00;}
	.Patient_task_link{font-size: 14px;font-weight: 600;color: #0E2641;}
	.Patient_task_link span{text-decoration: underline;padding-left: 10px;cursor: pointer;}
	.appointment_popup .form-label {
		font-weight: 600;
		margin-bottom: 10px;
	}

	.appointment_popup .e-input-group input.e-input {
		padding: 0 !important;
		text-align: center;
	}
	input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.appointment_popup .e-date-wrapper:not(.e-filled) .e-date-icon.e-icons,
	.e-time-wrapper .e-time-icon.e-icons,
	*.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons {
		margin: 0;
		/* display: none; */
	}

	.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {position: absolute;right: 20px;top: 20px;left: auto;height: 58px;width: 58px;border-radius: 5px;}
	.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn:hover{background-color: #ece3d180;}
	.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn .e-btn-icon {font-size: 18px;}

	.e-input-group:not(.e-float-icon-left),
	.e-input-group.e-control-wrapper:not(.e-float-icon-left) {
		border-radius: 5px;
		border: none;
		padding: 7px;
		align-items: center;
	}

	.e-dialog .e-dlg-content {font-size: 16px;overflow: unset;}
	.e-control, .e-css{font-family:'Inter' !important;}

	.e-dialog .e-dlg-content {font-size: 16px;overflow: unset;}
	.e-control, .e-css{font-family:'Inter' !important;}

	.e-popup.e-popup-open.e-dialog {
		width: 1190px !important;
		border-radius: 5px;
		overflow: hidden;
		padding: 50px 20px;
	}

	.e-footer-content {
		display: flex !important;
		justify-content: space-evenly;
		padding: 0px 120px 30px 120px !important;
	
	}
	.e-footer-content .e-event-cancel{order: 0;}
	.e-footer-content .e-schedule-dialog.e-control.e-event-save{order: 2;background-color: #0E2641;color: #F4D09E;}
	.e-footer-content .e-schedule-dialog.e-control.e-event-save:hover {background-color: #253B4A;border: 1px solid #253B4A;}
	.e-footer-content .e-event-delete{order: 1}
	
	.e-footer-content .e-schedule-dialog.e-control {
		border: 1px solid #0E2641;color: #0E2641;padding: 11.5px 33px;min-width: 170px;border-radius: 5px;font-weight: 600;text-align: center;font-size: 16px;line-height: normal;text-transform: capitalize;}

	.e-footer-content .e-schedule-dialog.e-control:hover {
		background-color: #F6F1E8;;
	}

	@media (min-width: 1650px) {
		.search input {width: 400px;}
		.selact_dropdown .selectCheckBox{width: 290px;}
	}

	@media (max-width: 1650px) {
		.header_new .navbar-nav .nav-link{margin: 0px 15px;font-size: 16px;}
		.header_new .navbar-brand{margin-right: 30px;}
		.menu_list li{font-size: 16px;padding: 9px 10px;}
		.calender_header .dot_icon{margin: 0px 20px;}
		.menu_list li:first-child{margin-right: 30px;}
		.calendar .e-datepicker.e-lib {font-size: 16px !important;}

		/* master recored */
		.patient_chart .left_side_bar .side-bar-menu{padding-left: 10px;}
		.patient_chart .left_side_bar .side-bar-menu li a{font-size: 18px;}
		.patient_chart .box-title{font-size: 22px;}
		.PatientChart_header .logo{font-size: 22px;}

		/* e-chart */
		.allergies .a_header{padding: 0;}
		.allergies .a_header button{padding: 15px 40px;}
		.allergies .a_header .search input{width: auto;padding: 15px 15px !important;font-size: 18px;text-indent: 35px;}
		.allergies .a_header .search .serch_icon {width: 20px;}

		.consult_add_page .btn_part button{padding: 17px 10px;}

		
	}

	@media (max-width: 1400px) {
		.header_new .navbar-nav .nav-link {margin: 0px 10px;padding: 15px;}
		.menu_list li {font-size: 14px;padding: 8px;}
		.menu_list li:first-child {margin-right: 50px;}
		.calendar .e-datepicker.e-lib {font-size: 14px !important;}
		.selact_dropdown .selectCheckBox {font-size: 14px;}
		.selact_dropdown .selectCheckBox img {width: 19px;}
		.calender_header .dot_icon {width: 35px;height: 35px;margin: 0px 30px;}
		.calendar .e-input-group-icon.e-date-icon::before{height: 20px;width: 20px;}
		.search input{padding: 8px 15px !important;font-size: 14px;text-indent: 25px;}
		.calendar .e-input-group {height: 35px;}
		.search .serch_icon{width: 15px;}
		.header_nbr_count {width: 15px;height: 15px;font-size: 10px;line-height: 15px;top: 0;}

		/* master recored */
		.patient_chart .containt_detail p > span {padding-left: 10px;}
		.patient_chart .containt_detail p label{min-width: unset;}
		.patient_chart .containt_detail .two_inputs{display: block;background: #cfd1d34d;}
		.patient_chart .box-title{font-size: 20px;}
		
		/* e-chart */
		.allergies .a_header button, .allergies .a_header div label.comman_btn {padding: 12px 30px;font-size: 18px;margin-left: 20px;}
		.allergies .a_header .search input {padding: 12px !important;}
		.PatientChart_header .close_sign{margin-left: 20px;}
		.PatientChart_header .detail_list{font-size: 22px;}
		.PatientChart_header .patient_icon {width: 22px;}
		.e-chart_page .start_ai_scribe .no_appointment{margin: 0;}
		.allergies .a_header .search_drop_box{width: 600px;}
		.edit_delet_link{text-align: center;}
		.edit_delet_link .popup_link {margin: 3px 0px;display: inline-block;}

		#add-allergies-model .btn_part button, #add-specialist-model .btn_part button, #add-exportconsultation-model .btn_part button, .consult_add_page .btn_part button, .add_temp_header .btn_part button, #add_measurement .btn_part .comman_brdr_btn, #add_measurement .btn_part .comman_btn, .addprescription .btn_part button, .preferred_pharmacy .custom-form .btn_part button, #Immunization_view_model .btn_part button, #add-task-model .btn_part button, #other-model .btn_part button, #family-history-model .btn_part button, #create_metric_group .btn_part button {font-size: 18px;padding: 17px 30px;}

		.consult_add_page .btn_part button{min-width: unset;padding: 17px 30px;}

	}

	@media (max-width: 1200px) {

		.menu_list li {padding: 8px 5px;font-weight: 500;font-size: 14px;}
		.calendar .e-input-group {height: 35px;}
		.header_new .navbar-nav .nav-link {padding: 10px;font-size: 16px;}

	}

	/* calander css end */

	/* selact dropdown */
	.Selact_drodwn .dropdown-div .caption {
		border-radius: 5px;
		border: 0.5px solid #0E2641;
		cursor: pointer;
	}

	.Selact_drodwn .dropdown-div .caption img {
		width: 20px;
		margin-left: 20px;
	}

	.Selact_drodwn .dropdown-div.open .caption img {
		transform: rotate(180deg);
	}

	.Selact_drodwn .dropdown-div .list {
		position: absolute;
		margin-top: 7px;
		background: #FFF;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
		width: 100%;
		border-radius: 0 0 3px 3px;
		z-index:11;
		max-height: 300px;
		overflow: auto;
	}

	.Selact_drodwn .dropdown-div .list .item {
		padding: 9px 32px;
		cursor: pointer;
		border-bottom: 0.5px solid #ECE3D1;
		line-height: normal;
	}
	.Selact_drodwn.unline_remove .dropdown-div .list .item{border-bottom: 0}

	/* .Selact_drodwn .dropdown .list .item.selected {font-weight: bold;} */
	.Selact_drodwn .dropdown-div .list .item:hover {
		background-color: rgba(236, 227, 209, 0.5);
	}

	/* .Selact_drodwn .dropdown.open .caption {border-radius: 3px 3px 0 0;border-bottom: solid 1px #999;} */
	.Selact_drodwn .dropdown-div.open .list {
		display: block;
	}

	/* selact dropdown end*/
	input.e-input::selection,
	textarea.e-input::selection,
	.e-input-group input.e-input::selection,
	.e-input-group.e-control-wrapper input.e-input::selection,
	.e-float-input input::selection,
	.e-float-input.e-control-wrapper input::selection,
	.e-input-group textarea.e-input::selection,
	.e-input-group.e-control-wrapper textarea.e-input::selection,
	.e-float-input textarea::selection,
	.e-float-input.e-control-wrapper textarea::selection {
		background: #F5F1E8;
		color: #0E2641;
	}
	.e-schedule .e-vertical-view .e-day-wrapper .e-appointment:focus {border: 0;box-shadow: none;}
	.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {overflow: unset;width: 99.8% !important;display: flex;align-items: center;}

	.target-click{
		cursor: pointer;
		text-transform: uppercase;
	}
	.prevent a{
		text-decoration: none;
		color: #0E2641;
		padding: 7px 8.5px;
		line-height: normal;
		height: 35px;
		min-width: 40px;
		text-align: center;
		display: inline-block;
	}
	.rotateIcon{
		transform: rotate(45deg);
	}
	.e-date-header.e-navigate {
		float: right;
	}
	
	.e-spinner-pane {
		display: none !important;
	}

	/* Month view each day cell box */
	td.e-work-cells.e-work-days {
		cursor: pointer;
	}
</style>