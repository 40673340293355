<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7 createinvic_tabs">
			<ul class="export_tabs d-flex justify-content-around">
				<li :class="{ active: isServiceFax }" @click="changeTabsOpt('service_code')"><span>Service Codes</span></li>
				<li :class="{ active: isAdvanceSettings }" @click="changeTabsOpt('advance_settings')"><span v-if="form.type == 'Ontario'">Advanced Settings</span></li>
			</ul>
			<div class="d-flex justify-content-between flex-column tab_contain">
				
			
				<div class="fax_menu overflow-auto h-100 service-code-form" v-if="isServiceFax">
					<div class="srvc-table">
						<table class="table text-center">
							<thead>
								<tr>
									<th style="width:160px;" v-if="form.type == 'Ontario'">Billing Code</th>
									<th style="width:160px;" v-if="form.type != 'Ontario'">Code</th>
									<th style="width:130px;">Quantity</th>
									<th style="width:90px;" v-if="form.type == 'Ontario'">%</th>
									<th style="width:144px;">Value</th>
									<th style="width:350px;" v-if="form.type != 'Ontario'">Description</th>
									<th style="width:160px;" v-if="form.type == 'Ontario'">Dx Code</th>
									<th style="width:150px;border-right: 0;">Date</th>
									<th style="border: 0;background: transparent;width: 170px;"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,index) in finalizedItems" :key="index">
									<td>{{ item.service_code }}</td>
									<td>{{ item.number_of_service }}</td>
									<td v-if="form.type == 'Ontario'">{{ item.discount }}</td>
									<td>{{ item.fee_submitted_format }}</td>
									<td>{{ item.dx_code }}</td>
									<td>{{ item.service_date }}</td>
									<td class="edit_delet_link">
										<span class="popup_link" @click="editEle(index, item)">Edit</span>
										<span class="popup_link" @click="deleteEle(index)">Delete</span>
									</td>
								</tr>
								<tr data-v-f6d43346="" v-if="isShowBillingFrmEle">
									<td data-v-f6d43346="" class="position-relative seach_input_bar input_box vh-drop-list actvbr-none"><input data-v-f6d43346="" type="text" class="form-control" v-model="itemForm.service_code" @keyup="searchBillingCode" @focus="searchBillingCode">
										<div class="search_drop_box new_big_drop" v-if="billingCodeList.length > 0 " ref="billingCodeRef">
											<slot v-for="(item, index) in billingCodeList" :key="index">
												<div class="con_drop_line text-start" @click="selectBillingCode(item)">
													<div class="man_haeding d-flex align-items-center blgcode-list-dropList"><p class="mb-0">{{ item.code }} | {{ item.formatted_fee }} |</p> <span> {{ item.description }}</span></div>
												</div>
											</slot>
										</div>
									</td>
									<td data-v-f6d43346=""><input data-v-f6d43346="" type="number" class="form-control text-center shdw-inpt-box" v-model="itemForm.number_of_service" @change="calculateGrnadTotal" style="width:60px;margin: 0 auto;"></td>
									<td data-v-f6d43346="" v-if="form.type == 'Ontario'"><input data-v-f6d43346="" type="number" class="form-control shdw-inpt-box" v-model="itemForm.discount" step="0.01" min="0" max="100" @change="calculateGrnadTotal"></td>
									<td data-v-f6d43346="">{{itemForm.fee_submitted_format}}</td>
									<td data-v-f6d43346="" class="position-relative seach_input_bar input_box drp-stl-text-adjst actvbr-none" v-if="form.type == 'Ontario'"><input data-v-f6d43346="" type="text" class="form-control" v-model="itemForm.dx_code" @keyup="searchDxCode" @focus="searchDxCode">
										<div class="search_drop_box new_big_drop vh-drop-list-r" v-if="dxCodeList.length > 0 " ref="dxCodeRef">
											<slot v-for="(item, index) in dxCodeList" :key="index">
												<div class="con_drop_line" @click="selecDxCode(item)">
													<div class="man_haeding mb-0 dxCode-list-drop d-flex align-items-center"><p class="code-big-f mb-0">{{ item.code }} |</p> <span> {{ item.description }}</span></div>
												</div>
											</slot>
										</div>
									</td>
									<td data-v-f6d43346="" class="position-relative seach_input_bar input_box vh-drop-list actvbr-none" v-if="form.type != 'Ontario'"><input data-v-f6d43346="" type="text" class="form-control" v-model="itemForm.service_code_description" @keyup="searchBillingDesc" @focus="searchBillingDesc">
										<div class="search_drop_box new_big_drop" v-if="billingCodeDescList.length > 0 ">
											<slot v-for="(item, index) in billingCodeDescList" :key="index">
												<div class="con_drop_line d-flex justify-content-between align-items-center text-start" @click="selectBillingCodeDesc(item)">
													<div class="man_haeding">{{ item.code }} | <span> {{ item.description }}</span></div>
												</div>
											</slot>
										</div>
									</td>
									<td data-v-f6d43346="" class=" actvbr-none datepicker_bx">
										<div class="calendar dt-cldr-ads cldr-date-pick-tbl" style="width: 150px;margin: 0 auto;">
                                             <ejs-datepicker class="e-field form-control" :value="itemForm.service_date" @change="handleDate" v-model="itemForm.service_date" :showClearButton="false" format="yyyy-MM-dd" @focus="openDatePicker" ref="datepicker" readonly></ejs-datepicker>
                                        </div>
									</td>
									<td class="text-center" style="padding: 20px 16px;">
										<button type="button" class="comman_btn serv-addbtn" @click="addNewItemToFinalized">Add</button>
									</td>
								</tr>
								<tr>
									<td class="w-auto border-0" colspan="7">
										<div data-v-f6d43346="" class="plus_sign_row ontario-plus-sign" @click="createNewItem" v-if="form.type == 'Ontario'">+</div>
										<div data-v-f6d43346="" class="plus_sign_row private-plus-sign" @click="createNewItem" v-else>+</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="fax_menu" v-if="isAdvanceSettings">
					<div class="ad-from-sect creatinv-main">
						<div class="row">
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>HEALTH NUMBER</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.health_insurance_no">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>VERSION CODE</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.health_card_ver">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from sm-prv-code">
									<label>PROVINCE CODE</label>
									<CustomDropDown :options="stateData"
											:initialValue="form.province_code_text"
											v-model="form.province_code" @item-selected="handleItemSelected"
											fieldName="province_code"></CustomDropDown>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>CLAIM PAYEE</label>
									<!-- <CustomDropDown :options="claim_payee_option"
											:initialValue="form.claim_payee_text"
											v-model="form.claim_payee" @item-selected="handleItemSelected"
											fieldName="claim_payee"></CustomDropDown> -->
									<input type="text" class="form-control" v-model="form.claim_payee">	
									<ValidationErrorMessageList :errors="v$.form.claim_payee.$errors" />
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>LAST NAME</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.rmb_patient_last_name">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>FIRST NAME</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.rmb_patient_first_name">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from birth-date-ipt">
									<label>DATE OF BIRTH</label>
									<div class="calendar dt-cldr-ads">
										<ejs-datepicker class="e-field form-control" @change="handleDate" v-model="form.dob" :showClearButton="false" format="yyyy-MM-dd"></ejs-datepicker>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>GENDER</label>
									<CustomDropDown :options="gender_option" :initialValue="this.form.gender_text"
											v-model="form.rmb_patient_sex" @item-selected="handleItemSelected"
											fieldName="rmb_patient_sex"></CustomDropDown>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from overhd-drop-tect drp-width-big">
									<label>CLAIM TYPE</label>
									<CustomDropDown :options="claim_type_option" :initialValue="this.form.claim_type_text"
											v-model="form.claim_type" @item-selected="handleItemSelected"
											fieldName="claim_type"></CustomDropDown>
									<ValidationErrorMessageList :errors="v$.form.claim_type.$errors" />
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>REFERRAL ID</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.referral_hcp_number">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>REGISTRATION NUMBER (FOR RMB)</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.rmb_registration_number">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>VISIT LOCATION</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="this.selected_provider_detail.visit_location" readonly>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>SERVICE LOCATION INDICATOR</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.service_location">

									<!-- <CustomDropDown :options="service_location_options" :initialValue="this.form.service_location_text"
										v-model="form.service_location" @item-selected="handleItemSelected"
											fieldName="service_location"></CustomDropDown> -->
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>ADMISSION DATE</label>
									<div class="calendar dt-cldr-ads">
										<ejs-datepicker class="e-field form-control" @change="handleDate" v-model="form.inpatient_admission_date" :showClearButton="false" format="yyyy-MM-dd"></ejs-datepicker>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>REFERRING LAB LICENSE NUMBER</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.referring_lab_license_number" >
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>GROUP NUMBER OVERRIDE</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.group_number">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>SPECIALTY OVERRIDE</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.specialty_code">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>MASTER NUMBER</label>
									<input data-v-f6d43346="" type="text" class="form-control" v-model="form.master_number">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>MANUAL REVIEW</label>
									<label class="switch d-block">
										<input type="checkbox" id="darkModeToggle" v-model="form.manual_review">
										<span class="sw-slider"></span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="ttl-sect">
						<div class="d-flex justify-content-end">
							<p class="t-ttl-h">TOTAL |</p>
							<p class="ttl-price">{{this.grandTotal}}</p>
						</div>
					</div>
					<div class="btn_part text-end">
						<button class="comman_brdr_btn big_btn mx40" @click="cancelBtn">Cancel</button>
						<button class="comman_btn big_btn mx40 me-0" @click="save">{{this.btnlbl}}
							<img src="images/loader.gif" v-if="loader" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 invoice-left-bar">
			<div>
				<div class="pt-dashbrd">
					<p class="lbl-iput"><span>INVOICE TYPE</span></p>
					<div class="heading_selact_drodwn inv-type-drop-li">
						<CustomDropDown :options="invoice_type_opt" :initialValue="this.invoice_type_text" @item-selected="handleItemSelected" fieldName="type" ></CustomDropDown>
						<ValidationErrorMessageList :errors="v$.form.type.$errors" />
					</div>
				</div>

				<p class="lbl-iput"><span>DEMOGRAPHIC</span></p>
				<div class="position-relative">
					<div class="search">
						<img class="serch_icon" src="/images/search-icon.svg" alt="">
						<input type="text" @keyup="patientSearch" class="form-control w-100" placeholder="Search Demographic"
							v-model="patient_search" ref="globalSearch">
						<ValidationErrorMessageList :errors="v$.form.patient_id.$errors" />
					</div>
					<div class="search_drop_box src_new_box" v-if="patientList.length > 0">
						<slot v-for="(item, index) in patientList" :key="index">
							<div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name+', '+item.first_name)">
								<div class="row px-2 py-3 align-items-center">
									<div class="col-md-12">
										<div class="man_haeding">
											{{ item.last_name }}, {{ item.first_name }}
											<span>({{ item.gender }})</span>
										</div>
										<ul>
											<li>
												DOB: {{ item.dob }}
											</li>
											<li>
												HIN: {{item.health_insurance_no}}
											</li>
										</ul>
									</div>								
								</div>
							</div>
						</slot>
					</div>
				</div>
			</div>

			<div class="mt-20 pt-dashbrd">
				<p class="ptnt-dtls"><span>Health # |</span>{{this.patient_detail.health_insurance_no}} {{this.patient_detail.health_card_ver}} 
					<slot v-if="patient_detail.health_card_ver === 1">(ON)</slot>
					<slot v-else>(OFF)</slot>
				</p>
				<div class="">
					<div class="lft-de-sct">
						<p class="ptnt-dtls"><span>Date of Birth |</span>{{this.patient_detail.dob}}</p>
					</div>
					<div class="rgt-de-sct">
						<p class="ptnt-dtls ms-0"><span class="w-auto">Age |</span>{{$filters.patientCalculatedAge(this.patient_detail.dob, true)}}</p>
					</div>
				</div>
			</div>
			<div>
				<p class="lbl-iput"><span>PROVIDER</span></p>
				<div class="selact_dropdown bigs-slct-dr h-auto d-inline-block w-100 prvdr-drp-list">
					<selectCheckBox :dataSource='provider_list' :labelName="tagProviderLabel" @clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key" :isShowDoctorIcon="true" :isUnselectAll="isUnselectAll" divMainClass="" :isMultiSelection="isMultiSelection"/>
					<ValidationErrorMessageList :errors="v$.form.provider_id.$errors" />
				</div>
			</div>
			<div class="mt-10 pt-dashbrd">
				<div class="">
					<div class="left-pr-sect">
						<p class="ptnt-dtls"><span>Provider ID |</span>{{this.selected_provider_detail.cpsid}}</p>
					</div>
					<div class="rigt-pr-sect">
						<p class="ptnt-dtls"><span>Specialty |</span> {{ this.selected_provider_detail.specialty_code }}</p>
					</div>
				</div>
				<div class="">
					<div class="left-pr-sect">
						<p class="ptnt-dtls"><span>Visit Location | </span>{{ this.selected_provider_detail.visit_location_short }}</p>
					</div>
					<div class="rigt-pr-sect">
						<p class="ptnt-dtls"><span>Group # | </span> {{ this.selected_provider_detail.group_billing_no }}</p>
					</div>
				</div>
			</div>
			<div class="mt-20">
				<p class="smallhead">PREVIOUS INVOICES</p>
				<div class="table-responsive previ-innce">
					<table class="table text-center mb-0">
						<thead>
							<tr>
								<th style="width: 105px;">Date</th>
								<th>Codes</th>
								<th>Dx</th>
								<th style="width: 100px;">Type</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item,index) in this.previousInvoices" :key="index">
								<td class="white-space-pre">{{ item.service_date }}</td>
								<td>{{ item.service_codes }}</td>
								<td>{{ item.dx_codes }}</td>
								<td>{{ item.type }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import sidebar from './sidebar.vue'
// import useVuelidate from "@vuelidate/core";
	import CustomDropDown from '../base/formFields/CustomDropDown.vue';
	import axios from "@/scripts/axios.js";
	import selectCheckBox from "../base/selectCheckBox.vue";
	import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
	import $ from "jquery";
	import useVuelidate from "@vuelidate/core";
	import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';

	export default {
		setup: () => {
			return { v$: useVuelidate() };
		},
		validations() {
			return this.$store.state.billing.validationRules;
		},
		data() {
			return {
				isServiceFax:true,
				isAdvanceSettings:false,
				invoice_type_opt:[
					{ value: "Ontario", title: "ONTARIO" },
					{ value: "Private", title: "PRIVATE" },
				],
				invoice_type_text:"ONTARIO",
				patientList:[],
				patient_search:"",
				provider_list:[],
				tagProviderLabel:"Select Provider",
				isUnselectAll:false,
				isMultiSelection:false,
				finalizedItems:[],
				previousInvoices:[],
				patient_detail:[],
				selected_provider_id:null,
				isShowBillingFrmEle:true,
				loader:false,
				billingCodeList:[],
				dxCodeList:[],
                stateData:[{ value: "AB", title: "AB - Alberta" },{ value: "BC", title: "BC - British Columbia" },{ value: "MB", title: "MB - Manitoba" },{ value: "NB", title: "NB - New Brunswick" },{ value: "NL", title: "NL - Newfoundland" },{ value: "NS", title: "NS - Nova Scotia" },{ value: "ON", title: "ON - Ontario" },{ value: "PE", title: "PE - Prince Edward Island" },{ value: "SK", title: "SK - Saskatchewan" },{ value: "NT", title: "NT - Northwest Territories" },{ value: "NU", title: "NU - Nunavut" },{ value: "YT", title: "YT - Yukon" },
				],
				gender_option: [
					{ value: "M", title: "Male" },
					{ value: "F", title: "Female" },
					{ value: "T", title: "Transgendered" },
					{ value: "O", title: "Other" },
					{ value: "U", title: "Undefined" }
				],
				claim_type_option:[
					{ value: "HCP", title: "HCP - Health Claims Program | <span class='fw-300 fs-14'> Ontario </span>" },
					{ value: "WCB", title: "WCB - Workplace Compensation Board" },
					{ value: "RMB", title: "RMB - Reciprocal Medical Billing | <span class='fw-300 fs-14'>Other Provinces besides Quebec</span>" },
				],
				// claim_payee_option:[
				// 	{ value: "P", title: "Provider" },
				// 	{ value: "S", title: "Patient" },
				// ],
				service_location_options:[
					{ value: "HDS", title: "HDS - Hospital Day Surgery" },
					{ value: "HED", title: "HED - Hospital Emergency Department" },
					{ value: "HIP", title: "HIP - Hospital In-Patient" },
					{ value: "HOP", title: "HOP - Hospital Out-Patient" },
					{ value: "HRP", title: "HRP - Hospital Referred Patient" },
					{ value: "IHF", title: "IHF - Independent Health Facility" },
					{ value: "OFF", title: "OFF - Office of Community Physician" },
					{ value: "OTN", title: "OTN - Ontario Telemedicine Network" },
					{ value: "PDF", title: "PDF - Private Diagnostic Facility" },
					{ value: "RTF", title: "RTF - Rehabilitation Treatment Facility" },

				],
				grandTotal:"$0.00",
				billingCodeDescList:[],
				btnlbl:'Save & Send',
				selected_provider_detail:[]

			}
		},
		components: {
			sidebar,
			CustomDropDown,
			selectCheckBox,
			'ejs-datepicker': DatePickerComponent,
			ValidationErrorMessageList,
		},
		methods: {
			changeTabsOpt(type){
				this.isServiceFax = false
				this.isAdvanceSettings = false
				if (type == 'service_code') {
					this.isServiceFax = true
				} else if (type == 'advance_settings') {
					this.isAdvanceSettings = true
				}
			},
			patientSearch() {
				if (this.patient_search.length > 2) {
					axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
						.then((response) => {
							this.patientList = response.data.data;
					})
				}
				if (this.patient_search.length == 0) {
					this.patientList = [];
				}
			},
			handleItemSelected(values) {
				if(values.fieldName == "claim_type")
				{
					let claimTypeVal = values.item.title;
					this.form.claim_type_text = claimTypeVal.split('|')[0].trim();
					this.form.claim_type = claimTypeVal.split('-')[0].trim();
				} else if(values.fieldName == "province_code")
				{
					this.form.province_code_text = values.item.title
					this.form[values.fieldName] = values.item.value
				}
				else
				{

					if (values.fieldName == 'type') {
						this.resetForm()
						this.billingCodeList = []
						this.dxCodeList = []
						if(values.item.value != "Ontario"){
							this.changeTabsOpt('service_code')
							this.btnlbl = 'Save & Submit'
						} else {
							this.btnlbl = 'Save & Send'
						}
						
					}

					this.form[values.fieldName] = values.item.value

				}
			},
			selectPatient(id, patient_name){
				// this.$store.state.patient_document.form.patient_id = id
				this.patient_search = patient_name
				this.form['patient_id'] = id
				this.fetchPatientDetail(id)
				this.fetchPreviousInvoice()
				this.patientList = [];
			},
			selected_provider(result){
				console.log(result)
				let obj = result.filter(item => item.checked)
				let checkedItems = obj.map(item => item.name);
				if (checkedItems.length > 0 ){
					this.tagProviderLabel = checkedItems[0]
					this.form['provider_id'] = obj[0].id
					this.selected_provider_detail = obj[0].extInfo
					const [code, description] = this.selected_provider_detail.visit_location.split(' - ');
					this.selected_provider_detail.visit_location_short = code
					console.log(description)
				}
				this.fetchPreviousInvoice()
			},
			getProviderList() {
				axios.post("json_list/provider", { roleId: 2 })
					.then((response) => {
						this.provider_list = response.data.data.map((item) => {
								return { id: item.id, name: item.full_name, checked: false, extInfo:item };
						});
				})

			},
			save(){
				this.v$.$validate();
				const $this = this
				if (!this.v$.$error) {
					$this.loader = true;
					this.form['items'] = this.finalizedItems
					console.log(this.form)
	
					let url = ''
					if(this.form.type == "Ontario"){
						url = 'invoice/public/store'
					} else {
						url = 'invoice/private/store'
					}
	
					axios.post(url, this.form)
					.then((response) => {
						if (response.status == 200) {
							$this.loader = false;
							// let invoiceId = response.data.data.id;

							this.$router.push(`/invoice/list`);
							// const url = `/invoice/billing-receipt-pdf/${invoiceId}/${this.form.type}`;
							// this.$router.push(url).catch(err => {
							// 	if (err.name !== 'NavigationDuplicated') {
							// 	console.error(err);
							// 	}
							// });this.$router.push(url).catch(err => {
							// 	if (err.name !== 'NavigationDuplicated') {
							// 	console.error(err);
							// 	}
							// });
							this.$filters.moshaToast(response.data.message, "success");
	
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						$this.loader = false;
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
							console.log(Object.values(error.response.data.data).join(', '))
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					});
				} else {
					for (const [key] of Object.entries(this.v$.form)) {
						if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
			},
			addNewItemToFinalized(){
				if (!this.itemForm.service_code || !this.itemForm.service_code_description) {
					if(this.form.type == "Ontario"){
						this.$filters.moshaToast("Please select billing code", "error")
					} else {
						this.$filters.moshaToast("Please select code", "error")
					}
					return false
				}
				if(this.form.type == "Ontario"){
					if (!this.itemForm.dx_code || !this.itemForm.dx_code_description) {
						this.$filters.moshaToast("Please select Dx code", "error")
						return false
					}
				}

				
				if (!this.itemForm.service_date ) {
					this.$filters.moshaToast("Please select date", "error")
					return false
				}

				if (!this.itemForm.fee_submitted_format ) {
					this.$filters.moshaToast("Please select billing code", "error")
					return false
				}

				this.isShowBillingFrmEle = false
				this.finalizedItems.push({...this.itemForm});
				this.calculateGrnadTotal()
			},
			fetchPatientDetail(patientId) {
				axios.post("patient/detail", { 'id': patientId })
					.then((response) => {
						if (response.data.status === 200) {
							this.patient_detail = response.data.data
							this.form.health_insurance_no = this.patient_detail.health_insurance_no
							this.form.health_card_ver = this.patient_detail.health_card_ver
							this.form.rmb_patient_first_name = this.patient_detail.first_name
							this.form.rmb_patient_last_name = this.patient_detail.last_name
							this.form.dob = this.patient_detail.dob
							this.form.rmb_patient_sex = this.patient_detail.gender
							this.form.gender_text = (this.gender_option.find(item => item.value === this.patient_detail.gender) || {}).title || "Select Gender";
						} else {
							this.$filters.moshaToast(response.data.message, "error")
						}
					})
					.catch(error => {
						this.$filters.moshaToast(error.message, "error")
					});
			},
			createNewItem(){
				Object.assign(
					this.$store.state.billing.itemForm,
					this.$store.state.billing.defaultItemForm,
				);
				this.isShowBillingFrmEle = true
			},
			deleteEle(index){
				let total = 0;
				$.each(this.finalizedItems, function (key, detail) {
					if(key != index)
					{
						let tempTotal = (detail.number_of_service * detail.fee_submitted)
						let discountPer = detail.discount
						let discountAmt = 0;
						if(discountPer)
						{
							discountAmt = ((discountPer * tempTotal) / 100)
							total += discountAmt;
						}
						else
						{
							total += tempTotal;
						}
					}
				})
				this.grandTotal = `$${parseFloat(total).toFixed(2)}`;
				this.finalizedItems.splice(index, 1);
			},
			searchBillingCode(){
				this.billingCodeList = []
				// if (this.itemForm.service_code.length > 0){
					axios.post("/invoice/search-billing-code", { term: this.itemForm.service_code,type: this.form.type })
						.then((response) => {
							this.billingCodeList = response.data.data;
					})
				// }
			},
			searchBillingDesc(){
				this.billingCodeDescList = []
				// if (this.itemForm.service_code.length > 0){
					axios.post("/invoice/search-billing-code", { term: this.itemForm.service_code_description,type: this.form.type })
						.then((response) => {
							this.billingCodeDescList = response.data.data;
					})
				// }
			},
			searchDxCode(){
				this.dxCodeList = []
				// if (this.itemForm.dx_code.length > 0){
					axios.post("/invoice/dx-code", { term: this.itemForm.dx_code,type: this.form.type })
						.then((response) => {
							this.dxCodeList = response.data.data;
					})
				// }

			},
			selectBillingCode(item){
				this.itemForm.service_code = item.code
				this.itemForm.service_code_description = item.description
				this.itemForm.fee_submitted_format = item.formatted_fee
				this.itemForm.fee_submitted = item.fee
				this.billingCodeList = []
				this.calculateGrnadTotal()
				if(this.form.type != "Ontario"){
					this.billingCodeDescList = []
				}
			},
			selecDxCode(item){
				this.itemForm.dx_code = item.code
				this.itemForm.dx_code_description = item.description
				this.dxCodeList = []
			},
			editEle(index, item){
				this.$store.state.billing.itemForm = item 
				this.finalizedItems.splice(index, 1);
				this.isShowBillingFrmEle = true
			},
			handleDate(){
				if (this.itemForm.service_date) {
                    this.itemForm.service_date = this.formatDate(this.itemForm.service_date);
                }
				if (this.form.dob) {
                    this.form.dob = this.formatDate(this.form.dob);
                }
				if (this.form.inpatient_admission_date) {
					this.form.inpatient_admission_date = this.formatDate(this.form.inpatient_admission_date);
				}
			},
			formatDate(date) {
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month as it starts from 0
				const day = String(date.getDate()).padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			fetchStateData() {
				let countryId = 38
				let stateTempData = []
				axios.post("json_autocomplete/" + countryId + "/state", { 'countryId': countryId })
					.then((response) => {
						const details = response.data.data;
						$.each(details, function (key, detail) {
							stateTempData.push({
								value: detail['id'],
								title: detail['sortname']+" - "+detail['name']
							})
						})
						this.stateData = stateTempData
					})
			},
			calculateGrnadTotal(){
				let total = 0;
				$.each(this.finalizedItems, function (key, detail) {

					let tempTotal = (detail.number_of_service * detail.fee_submitted)
					let discountPer = detail.discount
					let discountAmt = 0;
					if(discountPer)
					{
						discountAmt = ((discountPer * tempTotal) / 100)
						total += discountAmt;
					}
					else
					{
						total += tempTotal;
					}
				})
				this.grandTotal = `$${parseFloat(total).toFixed(2)}`
			},
			fetchPreviousInvoice() {
				axios.post("/invoice/fetch-previous-invoices", { 'type':this.form.type, 'patient_id': this.form.patient_id, 'provider_ids':[this.form.provider_id]})
					.then((response) => {
						this.previousInvoices = response.data.data;
					})
			},
			handleClickOutside(event) {
				const globalSearchElement = this.$refs.globalSearch;
				if (globalSearchElement && !globalSearchElement.contains(event.target) && !event.target.closest(`.search_drop_box`)) {
					this.patientList = [];
				}
				
				const billingCodeRefElement = this.$refs.billingCodeRef;
				if (billingCodeRefElement && !billingCodeRefElement.contains(event.target) && !event.target.closest(`.seach_input_bar`)) {
					if (this.billingCodeList.length > 0) {
						this.billingCodeList = [];
					}
				}

				
				const dxCodeRefElement = this.$refs.dxCodeRef;
				if (dxCodeRefElement && !dxCodeRefElement.contains(event.target) && !event.target.closest(`.seach_input_bar`)) {
					if (this.dxCodeList.length > 0) {
						this.dxCodeList = [];
					}
				}

			},
			selectBillingCodeDesc(item){
				this.itemForm.service_code = item.code
				this.itemForm.service_code_description = item.description
				this.billingCodeDescList = []
			},
			cancelBtn(){
				this.$router.push(`/invoice/list`);
			},
			resetForm(){
				Object.assign(
					this.$store.state.billing.itemForm,
					this.$store.state.billing.defaultItemForm,
				);
				Object.assign(
					this.$store.state.billing.form,
					this.$store.state.billing.defaultFormData,
				);	
				this.finalizedItems = []
				this.isShowBillingFrmEle = true
				this.grandTotal = '$0.00'
				this.isAdvanceSettings = false
				this.isServiceFax = true

			},
			openDatePicker() {
				this.$refs.datepicker.show();
			}
		},
		mounted() {
			document.addEventListener('click', this.handleClickOutside);
			this.resetForm()
			this.getProviderList()
			// this.fetchStateData()
		},
		computed: {
			form() {
				return this.$store.state.billing.form;
			},
			itemForm(){
				return this.$store.state.billing.itemForm;
			}
		},
	}
</script>
