<template>
    <div>
      <h1>Speech to Text</h1>
      <button @click="startRecording" :disabled="recording">Start Recording</button>
      <button @click="stopRecording" :disabled="!recording">Stop Recording</button>
      <div v-if="transcription">{{ transcription }}</div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        recording: false,
        audioChunks: [],
        mediaRecorder: null,
        transcription: ''
      };
    },
    methods: {
      async startRecording() {
        try {
          this.audioChunks = [];
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.addEventListener('dataavailable', event => {
            this.audioChunks.push(event.data);
          });
          this.mediaRecorder.addEventListener('stop', this.stopRecording);
          this.mediaRecorder.start();
          this.recording = true;
        } catch (error) {
          console.error('Error starting recording:', error);
        }
      },
      async stopRecording() {
        this.mediaRecorder.stop();
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
        const formData = new FormData();
        formData.append('audio', audioBlob);
        try {
          const response = await fetch('https://api.shopyq.xyz/api/transcribe', {
            method: 'POST',
            body: formData
          });
          const data = await response.json();
          this.transcription = data.transcription;
        } catch (error) {
          console.error('Error sending audio to server:', error);
        }
        this.recording = false;
      }
    }
  }
  </script>
  