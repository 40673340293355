<template>
	<div class="row" v-if="documentPath!=null">
        <ejs-pdfviewer 
				id="pdfViewer" 
				ref="pdfViewer"
				:documentPath="documentPath"
				:resourceUrl="resourceUrl"								
				:openFile='false'
				:toolbarSettings="toolbarSettings"
				> 
        </ejs-pdfviewer>
    </div>
	<div class="row pt-20">
		<div class="d-flex align-items-center justify-content-center">
			<div class="col-md-6 syncfusion-component">
				<ejs-multiselect
				v-model="selectedValues"
				:dataSource="providerFaxList"
				:fields="fields"
				placeholder="Select Providers"
				></ejs-multiselect>
			</div>
		</div>
	</div>
    <div class="row">
		<div class="col-md-12 text-center pt-10">
        <form-btn
            title="Submit"
            btnCls="btn btn-primary text-white btn-sm col-md-1"
            @click="save()"
        ></form-btn>
		<form-btn
            title="Submit & Fax"
            btnCls="btn btn-primary text-white btn-sm col-md-1"
            @click="save('save_fax')"
        ></form-btn>
		<form-btn
            title="Fax"
            btnCls="btn btn-primary text-white btn-sm col-md-1"
            @click="save('fax')"
        ></form-btn>
		<button class="btn btn-danger text-white btn-sm col-md-2 me-1" @click="closeWindow"> Cancel &amp; EXIT </button>
		</div>
    </div>
</template>
<script>
import axios from "@/scripts/axios.js";
import FormBtn from "../../base/formFields/FormBtn.vue";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import { Toolbar, Magnification, Navigation, LinkAnnotation, 
         BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, 
         Annotation } from '@syncfusion/ej2-vue-pdfviewer';

export default {
	data() {
		return {
            documentPath:null,
			resourceUrl:"https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
			selectedValues: [],
			providerFaxList: [],
			fields: { text: 'text', value: 'id' },
			toolbarSettings: {
				showTooltip: false,
				toolbarItems: [
					"UndoRedoTool",
					"PageNavigationTool",
					"MagnificationTool",
					"PanTool",
					"SelectionTool",
					"AnnotationEditTool",
					"FormDesignerEditTool",
					"FreeTextAnnotationOption",
					"InkAnnotationOption",
					"ShapeAnnotationOption",
					"StampAnnotation",
					"SignatureOption",
					"PrintOption",
					"DownloadOption"
				],
			},
		};
	},
    components: {
        FormBtn,
		'ejs-multiselect' : MultiSelectComponent,
    },
    setup: () => {
	},
    mounted() {
		this.getProviders()
        this.retrieve()
    },
    methods: {
        retrieve() {
            axios.post("patient/eform/retrieve", { 'patient_id': this.$route.params.patient_id, 'id': this.$route.params.id})
			.then((response) => {
				if (response.data.status === 200) {
					var detail = response.data.data;
					this.documentPath = this.documentUrl(detail.file_path)
				}
			}).catch(error => {
				this.$filters.moshaToast(error.message, "error")
			})
        },
        documentUrl(documentPath) {
            // Assuming your documents are stored in the 'public' directory
            return `${process.env.VUE_APP_STORAGE_URL}/eform/document/view/${documentPath}`;
        },
        save(action='save') {
			if (action != 'save') {
				if (this.selectedValues.length == 0) {
					this.$filters.moshaToast("Please select at least one provider for send fax", "error");
					return false
				}
			}
			const formData = new FormData();
			const pdfViewerInstance = this.$refs.pdfViewer.ej2Instances;
			if (pdfViewerInstance) {
				const annotatedPdfBlob = pdfViewerInstance.saveAsBlob();
				if (annotatedPdfBlob instanceof Promise) {
					annotatedPdfBlob.then((result) => {
						if (result instanceof Blob) {
							formData.append('pdfData', result, 'edited_file.pdf');
						}
						formData.append('patient_id', this.$route.params.patient_id);
						formData.append('id', this.$route.params.id);
						formData.append('action', action);
						if (action != 'save') {
							formData.append('provider_fax_ids', this.selectedValues);
						}

						try {
							const config = {
								headers: {
									'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
									'Content-Type': 'multipart/form-data'
								},
							};

							axios.post('patient/eform/update', formData, config).then((response) => {
								if (response.status == 200) {
									this.$filters.moshaToast(response.data.message, "success");
									this.closeWindow()
								}

							}).catch(error => {
								if (error.response.status === 422) {
									this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
								} else {
									this.$filters.moshaToast(error.response.data.message, "error");
								}
							});
						}
						catch (error) {
							console.error('Something went wrong');
						}
					});
				}
			}
		},
        closeWindow(){            
			window.close();
        },
		getProviders() {
			console.log("here")
			// axios.post("json_list/provider")
			// .then((response) => {
			// 	this.providerFaxList = response.data.data.map((item) => {
			// 		if (item.fax_phone) {
			// 			let providerName = item.full_name
			// 			providerName += ' ['+item.fax_phone+']'
			// 			return { id: item.user_id, text: providerName };
			// 		}
			// 	});
			// });
        },
    },
    provide: {
		PdfViewer: [ Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation ]
	}
}
</script>

