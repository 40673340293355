<!-- Dropdown.vue -->
<template>
        <div class="dropdown-div">
            <div class="form-control caption" :class="{ light_bg: this.showList }" @click="toggleList">
              <img src="/images/down-arrow_r_new.svg" class="img-fluid" :class="{ rotate90: this.showList }">
              <span>{{ this.$filters.strToUpperCase(this.title) }}</span> 
            </div>
              <router-link :to="{ name: this.urlRouteName,  params: { patient_id: this.$route.params.patient_id }}">
                <img id="settingIcon" src="/images/dots-icon.svg" class="img-fluid dot_icon mt-1">
              </router-link>
          </div>
          <div class="list_box" v-show="showList">
            <div  v-for="(item, index) in options" :key="index" class="contain">
              <span>{{ item.title }}</span>
              <span v-if="item.desc">{{ item.desc }}</span>
              <span>{{ item.date }}</span>
            </div>
          </div>
        
  </template>
  
  <script>
  export default {
    props: {
      options: {
        type: Array,
        required: true,
      },
      title:{
        type: String, // Adjust the type based on your data structure
        default: 'Title', // Default value when not provided
      },
      urlRouteName:{
        type: String, // Adjust the type based on your data structure
        default: '', // Default value when not provided
      }

    },
    data() {
      return {
        showList: false,
      };
    },
    methods: {
      toggleList() {
        this.showList = !this.showList;
      },
     
    },
    watch: {
       
    },
  };
  </script>
  
  <style scoped>
  /* Add your component-specific styles here */
  </style>
  