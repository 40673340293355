<template>
	<div class="row">
		<div class="box">
			<div class="box-body">
                <div class="row">
                    <div class="col-10">
                        <h3 >Task</h3> 
                    </div>

                    <div class="col-2">
                        <router-link v-if="this.$route.name=='TaskList' && this.$filters.permissioncheck([this.$store.state.permission.task_task_crud_all])" :to="{ name: 'TaskCreate'}" class="waves-effect waves-light btn btn-info d-block mb-10 btn-sm ">
						<i class="fa fa-plus fs-14"></i> Create Task
                        </router-link>
                        <router-link v-else-if="this.$route.name=='PatientTaskList' && this.$filters.permissioncheck([this.$store.state.permission.task_task_crud_all])" :to="{ name: 'PatientTaskForm', params: { patient_id: this.uniqueId,  }}" class="waves-effect waves-light btn btn-info d-block mb-10 btn-sm ">
						<i class="fa fa-plus fs-14"></i> Create Task
                        </router-link>
                    </div>

                </div>

			</div>
		</div>
	</div>

    <div class="box">
        <div class="box-body">
          <div class="row justify-content-sm-left">

            <div class="col-md-3" v-if="this.$route.name=='TaskList'">
                <div class="form-group">
                    <label class="form-label">Patient</label>
                    
                    <div class="syncfusion-component">
                        <ejs-dropdownlist id="patient_id" placeholder='Select a Patient' v-model="form.patient_id" :showClearButton="true" :allowFiltering='allowFiltering' :filtering='filtering' :dataSource='dataSource' :fields='fields' @filtering="onFilter" :value="filter.patient_id" @change="selectPatient"></ejs-dropdownlist>
                    </div>
                </div>
            </div>
            
            <SelectBoxStatic divClass="col-md-3" title="Status" v-model="filter.status" :option="status_options"></SelectBoxStatic>

            <SelectBoxStatic divClass="col-md-3" title="Creator" v-model="filter.created_user" :option="providerDataSource"></SelectBoxStatic>

            <SelectBoxStatic divClass="col-md-3" title="Task Assigned to" v-model="filter.assigned_to" :option="providerDataSource"></SelectBoxStatic>

            <SelectBoxStatic divClass="col-md-3" title="Priority" v-model="filter.priority" :option="priority_options" ></SelectBoxStatic>


            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 mt-30">
              <div class="">
                  <button
                    class="b-0 waves-effect waves-light btn btn-sm btn-info py-7 me-1"
                    @click="applyFilter">
                    Search
                  </button>
                  <button
                    class="b-0 waves-effect waves-light btn btn-sm btn-warning-light py-7"
                    @click="resetFilter">
                    Clear
                </button>
              </div>
            </div>
  
          </div>
        </div>
      </div>

    <Datatable dataType="task" dataMethod="post" :key="key">
		<template v-slot="slotProps">

            <td>
                {{ slotProps.item.patient_name }} | 
                <a href="#" @click="launchEchart(slotProps.item.patient_id)"> E </a>
            </td>
            <td>{{ slotProps.item.creator }}</td>
            <td>{{ slotProps.item.service_date }}</td>
            <td>{{(slotProps.item.formatted_created_at) }}</td>
            <td>{{ slotProps.item.priority }}</td>
            <td>{{ slotProps.item.assigned_to }}</td>
            <td>{{ slotProps.item.status }}</td>
            <td>{{ slotProps.item.reminder_message }}</td>
            <td>
                <button class="btn btn-primary btn-sm me-1" href="javascript::void(0)" @click="editTask(slotProps.item.id, slotProps.item.patient_id)" v-if="this.$filters.permissioncheck([this.$store.state.permission.task_task_crud_all, this.$store.state.permission.task_task_crud, this.$store.state.permission.task_task_read])"><i class="fa fa-pencil"></i>&nbsp;Edit</button>
                <button class="btn btn-info btn-sm me-1" @click="editTaskNote(slotProps.item.id, slotProps.item.patient_id)" href="javascript::void(0)"  data-bs-toggle="modal" data-bs-target="#taskNoteModel" v-if="this.$filters.permissioncheck([this.$store.state.permission.task_task_crud_all, this.$store.state.permission.task_task_crud,this.$store.state.permission.task_task_read])"><i class="fa fa-file-text-o"></i>&nbsp;Note</button>
                <a href="#" class="btn btn-danger btn-sm" @click="deleteRecord(slotProps.item.id)" v-if="this.$filters.permissioncheck([this.$store.state.permission.task_task_crud_all])"> <i class="fa fa-trash-o"></i>&nbsp;Delete </a>
            </td>
        </template>

    </Datatable>

	<!-- Modals Area Start -->
	<div class="modal center-modal fade" id="taskNoteModel" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog  modal-dialog-scrollable">
			<div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Task Note</h5>
                </div>

				<div class="modal-body">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Reminder Message<small class="asterisksign" style="">*</small></label>
                            <TextArea divClass="col-12" v-model="form.reminder_message" :rows="6" :errors="v$.form.reminder_message.$errors" @input="this.changeMsg()"></TextArea>
                        </div>
                    </div>
				</div>
				<div class="modal-footer modal-footer-uniform">
					<button type="button" class="btn btn-danger float-end" data-bs-dismiss="modal">Exit</button>
					<button type="button" class="btn btn-primary float-end"
						data-bs-dismiss="modal" :disabled="disableBtn" @click="submit()"  v-if="this.$filters.permissioncheck([this.$store.state.permission.task_task_crud_all, this.$store.state.permission.task_task_crud])">Save</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Datatable from '../../base/formFields/Datatable.vue';
// import moment from "moment";
import axios from "@/scripts/axios.js";
import TextArea from '../../base/formFields/TextArea.vue';
import useVuelidate from "@vuelidate/core";
import $ from "jquery";
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
// import InputField from "../../base/formFields/InputField.vue"
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";

export default {
    props: {
        uniqueId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            disableBtn : false,
            key: 0,
            search_drop: true,
            providerDataSource:[],
            status_options:[
                { value: "Active", title: "Active" },
                { value: "Complete", title: "Complete" },
                { value: "Deleted", title: "Deleted" },
            ],
            searchKey:0,
            priority_options:[
                { value: "High", title: "High" },
                { value: "Normal", title: "Normal" },
                { value: "Low", title: "Low" },
            ],
            patient_detail:[],
            dataSource : [{ text: "Select A Patient", value: "" }],
            fields : { text: "title", value: "value" },
            allowFiltering: true,
            searchText:'',
            minSearchLength: 0, // Minimum length for filtering
        }
    },
    setup: () => {
		return { v$: useVuelidate() };
	},
	components: {
		Datatable,
        TextArea,
        SelectBoxStatic,
        'ejs-dropdownlist': DropDownListComponent,
        // InputField
	},
    methods: {
        fetchProviderData() {
			axios.post("json_list/provider", { 'roleId': '' })
				.then((response) => {
					const providerTempData = []
					const details = response.data.data;
					$.each(details, function (key, detail) {
						providerTempData.push({
							value: detail.user_id,
							title: detail.full_name,
						})
					})
					this.providerDataSource = providerTempData
				})
		},
        resetFilter(){
            this.filter.status = ''
            this.filter.assigned_to = ''
            this.filter.created_user=''
            this.filter.priority=''
            this.filter.patient_name=''
            this.searchKey = this.searchKey + 1;
            if (this.$route.name=='TaskList'){
                this.filter.patient_id=''
                this.filter.patient_id=''
            }
            this.applyFilter()
            this.dataSource = []
        },
        applyFilter() {
            this.key++;
            this.search_drop = false;
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            };
            return new Intl.DateTimeFormat('en-US', options).format(date);

        },
        editTask(id, uniqueId) {
            let qs='';
            if (this.$route.name=='TaskList') {
                qs='?display=all'
            }
            this.$router.push(`/patient/task/${uniqueId}/retrieve/${id}${qs}`);   
        },
        async editTaskNote(id, uniqueId) {
            axios.post("patient/task/retrieve", { 'patient_id': uniqueId, 'id': id})
            .then((response) => {
                if (response.data.status === 200) {
                    let detail = response.data.data
                    this.form.patient_id = uniqueId
                    this.$store.state.task.form = detail;
                    let note = ''
                    if (detail.statusDetail && detail.statusDetail.length > 0) {
                        note = detail.statusDetail[0]['message']
                    }
                    this.$store.state.task.form.reminder_message = note.replace(/<br\/>/g, '\n')
                } else {
                    this.$filters.moshaToast(response.data.message, "error")
                }
            }).catch(error => {
                this.$filters.moshaToast(error.message, "error")
            });
        },
        submit() {
            this.v$.$validate();
			if (!this.v$.$error) {
                this.loader = true;
                // this.form.patient_id = this.uniqueId;

                let url = "patient/task/update";

                this.form.action="note"
                axios.post(url, this.form)
				.then((response) => {
                    this.loader = false;
					if (response.status == 200) {
                        this.$filters.moshaToast(response.data.message, "success");
                        location.reload()
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }  else {
                return false;
            }
        },
        changeMsg() {
            if (this.form.reminder_message.length == 0) {
                this.disableBtn = true
            } else {
                this.disableBtn = false
            }
        },
        deleteRecord(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to revert this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/task/delete";
                    axios.post(url, {'id':id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.applyFilter()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });


        },
        fetchPatientDetail() {
			axios.post("patient/detail", { 'id': this.uniqueId })
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
                        this.filter.patient_name = response.data.data.full_name
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		}, 
        getPatientList(){
            axios.post("patient/list", {'filter_patient':this.searchText, })
				.then((response) => {
					this.patientList = response.data.data.map((item) => {
						return { value: item.id, title: item.full_name+' ['+item.patient_id+']' };
					});
                    this.dataSource = this.patientList
				});
        },
        onFilter(args) {
            // Update the searchText based on user input during filtering
            this.searchText = args.text;
            if (this.searchText.length > this.minSearchLength) {
                this.getPatientList()
            } else {
                this.dataSource = [{ text: "Select A Patient", value: "" }]
            }
        },
        selectPatient(){
            let selectedUser = document.getElementById("patient_id").value;

            selectedUser = selectedUser.match(/\[(\d+)\]/);
            selectedUser =  selectedUser ? selectedUser[1] : "";
            this.filter.patient_id = selectedUser
        },
        launchEchart(patient_id) {
			this.search_text =''
			const { protocol, hostname, port } = window.location;			
			let baseUrl = `${protocol}//${hostname}`;			
			if (port && !['80', '443'].includes(port)) {
				baseUrl += `:${port}`;
			}
			baseUrl += `/#/patient/e-chart/${patient_id}`
			const newWindow = window.open(baseUrl, `_blank`);
			if (newWindow) {
				newWindow.focus();
			}
		},
    },
    computed: {
        form() {
			return this.$store.state.task.form;
		},
        vuelidateExternalResults() {
			return this.$store.state.task.vuelidateExternalResults;
		},
        filter() {
            return this.$store.state.task.filter;
        },
    },
    validations() {
		return this.$store.state.task.validationRules;
	},
    beforeCreate() {
        
    },
    created() {
        // this.fetchPatientDetail()
        this.filter.patient_id = this.uniqueId
    },
    mounted(){
        this.fetchProviderData()
    },

}
</script>