<template>
  <div class="container-fluid">
    <Header></Header>
  </div>
	<div class="content">
		<div class="container-full">
			<section class="content">

        <div class="main row m-0">
          <Home  />
        </div>

			</section>
		</div>
	</div>
</template>
<script>
import Home from '../../components/chat/Home.vue';
import Header from '../Header.vue';

export default {
  data() {
		return {
      
    }
  },
	components: {
    Header,
    Home,
	},
	created() {

	},
  mounted() {
    
  },
  methods: {
  }
}
</script>