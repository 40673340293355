<template>
	<div :class="divClass">
		<div class="form-group" :class="{ error: errors.length }">
			<label :class="labelClass" v-if="!titleHide">{{ title }}
			<small v-show="isRequired" class="asterisksign">*</small></label>
			<div class="controls">
				<slot v-if="mask === null">
					<input :id="title" :type="type" class="form-control" :value="modelValue" :style="style" @input="$emit('update:modelValue', $event.target.value)" :placeholder="placeholderText" :max="max" :min="min" autocomplete="chrome-off" :readonly="readonly" />					
				</slot>
				<slot v-else>
					<input :id="title" :type="type" name="text" class="form-control" :value="modelValue" :readonly="readonly" :style="style" @input="$emit('update:modelValue', $event.target.value)" :min="min"  :placeholder="placeholderText" :max="max" autocomplete="chrome-off" v-mask="mask" />
				</slot>
				<span class="form-text text-muted" v-if="notes != null"><b>{{ notes }}</b></span>
			</div>
			<div class="error-msg">
				<ul class="mb-0 p-0 fs-14">
					<li class="d-block" v-for="(error, index) of errors" :key="index">
						{{ error.$message }}
					</li>
				</ul>
			</div>
			<div v-show="toolTip != null">
				<Popper arrow :content="toolTip" :hover="true">
					<i class="fa fa-info-circle"></i>
				</Popper>
			</div>
			<div :class="noteClass" v-show="note != null" style="{vertical-align:-webkit-baseline-middle}">
				<small class="form-text text-muted">{{ note }}</small>
			</div>
		</div>
	</div>
</template>

<script>
import Popper from "vue3-popper";
export default {
	components: { Popper },
	props: {
		type:{
			type: String,
			default: "text"
		},
		title: {
			type: String,
			default: ""
		},
		divClass: {
			type: String,
			default: "col-md-6"
		},
		modelValue: {
			type: [String,Number],
			default: ""
		},
		errors: {
			type: Array,
			default: () => []
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		style: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ""
		},
		noteClass: {
			type: String,
			default: "col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5"
		},
		note: {
			type: String,
			default: null,
		},
		labelClass: {
			type: String,
			default: "form-label"
		},		
		notes: {
			type: String,
			default: null
		},
		toolTip: {
			type: String,
			default: null
		},
		mask: {
			type: String,
			default: null
		},
		max: {
			type: Number,
			default: null
		},
		min: {
			type: Number,
			default: null
		},
		titleHide: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		placeholderText() {
			return this.placeholder ? this.placeholder : this.title;
		},
	},
	data() {
		return {

		};
	},
	mounted() {
		this.id = this.title.replace(" ", "_");
	}
};
</script>