<template>
	<div class="content">
		<div class="container-full">
            <div class="row">
                <div class="col-2">
                    <Sidebar></Sidebar>
                </div>
                <div class="col-10">
                    <div class="box">
                        <div class="box-body">
                            <div class="row">
                                <div class="col-10">
                                    <div class="row d-flex align-items-center me-auto">
                                        <h3 class="page-title">Service</h3>
                                    </div>
                                </div>

                                <div class="col-2">
                                    <button class="waves-effect waves-light btn btn-primary d-block mb-10 btn-sm" href="javascript::void(0)" @click="createService" ><i class="fa fa-plus fs-14"></i>&nbsp;Create Service</button>
                                </div>

                            </div>

                            <div class="row">
                                <Datatable dataType="service" dataMethod="post" :key="key">
                                    <template v-slot="slotProps">
                                        <td>{{slotProps.item.name}}</td>
                                        <td>
                                            <button @click="toggleSwitch($event, slotProps.item.id)" type="button" :class="['btn', 'btn-sm', 'btn-toggle', {'active': slotProps.item.status === 'A', 'btn-success': slotProps.item.status === 'A', 'btn-danger': slotProps.item.status !== 'A'}]" data-bs-toggle="button" :aria-pressed="slotProps.item.status === 'A'" autocomplete="off">
                                                <div class="handle"></div>
                                            </button>
                                            <div class="statusLbl" v-if="slotProps.item.status == 'A'">
                                                Active
                                            </div>
                                            <div class="statusLbl" v-else>
                                                Deactive
                                            </div>
                                        </td>
                                        <td>
                                            <a class="btn btn-sm btn-primary me-1" href="#" @click="editRecord(slotProps.item.id)"> <i class="fa fa-pencil"></i>&nbsp;Edit</a>

                                            <!-- <a class="btn btn-sm btn-danger" v-if="!slotProps.item.deleted_datetime" href="#" @click="deleteRecord(slotProps.item.id)"> <i class="fa fa-trash"></i>&nbsp;Delete</a> -->
                                        </td>
                                    </template>    
                                </Datatable>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

		</div>
	</div>
</template>

<script>
import Sidebar from './../Sidebar.vue';
import Datatable from '../../../base/formFields/Datatable.vue';
import axios from "@/scripts/axios.js";
import $ from "jquery";

export default {
    data() {
        return {
            key: 0,
            searchKey:0,
            search_drop: true,
        }
    },
    setup() {
        
    },
	components: {
        Sidebar,
        Datatable
	},
	created() {
	},
    methods:{
        applyFilter() {
            this.key++;
            this.search_drop = false;
        },
        resetFilter(){
            // this.filter.provider_id='';
            this.applyFilter()
        },
        deleteRecord(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to revert this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "consultations-services/delete";
                    axios.post(url, {'id':id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.applyFilter()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });


        },
        createService(){
            this.$router.push(`/administration/system/service/create`);
        },
        editRecord(id){
            this.$router.push(`/administration/system/service/retrieve/${id}`);
        },
        toggleSwitch($event, id){
            let status = 'inactive';
            if ($($event.target).attr("aria-pressed") == 'true') {
                status = 'active';
            }
            
            this.$swal({
                title: 'Are you sure you want to change status?',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, change it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "consultations-services/change-status";
                    axios.post(url, {'id':id, 'status':status})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            $($event.target).toggleClass('active btn-danger btn-success');

                            if (status == 'active') {
                                $event.target.classList.remove('btn-danger');
                                $event.target.classList.add('btn-success');
                                $event.target.classList.add('active')
                                $($event.target).parent().find('.statusLbl').text('Active')
                            } else {
                                $event.target.classList.remove('btn-success');
                                $event.target.classList.add('btn-danger');
                                $event.target.classList.remove('active')
                                $($event.target).parent().find('.statusLbl').text('Deactive')
                            }
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                    if (status == 'active') {
                        $event.target.classList.remove('btn-success');
                        $event.target.classList.add('btn-danger');
                        $event.target.classList.remove('active')
                    } else {
                        $event.target.classList.remove('btn-danger');
                        $event.target.classList.add('btn-success');
                        $event.target.classList.add('active')
                    }
                }
            });

		},
    }
}
</script>