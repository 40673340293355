<template>
	<div class="row">
        <Sidebar></Sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
            <Datatable dataType="all_eform"  dataMethod="post" :key="key">
                <template v-slot="slotProps">
                    <td>
                        <router-link :to="{ name: 'PatientAddEform', params: { patient_id: $route.params.patient_id, id: slotProps.item.id}}" class="">
                            {{slotProps.item.title}}
                        </router-link>
                    </td>
                    <td>{{slotProps.item.additional_info}}</td>
                    <td>{{slotProps.item.formatted_updated_at}}</td>
                </template>    
            </Datatable>

        </div>
    </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import Datatable from '../../base/formFields/Datatable.vue';

export default {
    setup() {
        
    },
    components: { 
        Sidebar,
        Datatable
    },
}
</script>
