import {
	helpers,
	required,
} from "@vuelidate/validators";

const chat = {
	namespaced: true,

	state: {
		form: {
			newMessage: null,
		},
		validationRules: {
			form: {
				newMessage: {
					required: helpers.withMessage("Please Enter Group Name.", required),
				},
				file:{

				}
			}
		},
		vuelidateExternalResults: {
			form: {
				newMessage: '',
			}
		},
		getters: {},
		mutations: {},
		actions: {},
	}
};

export default chat;