<template>
	<Datatable></Datatable>
</template>

<script>
import Datatable from '../base/formFields/Datatable.vue';
export default {
	components: {
		Datatable,
	},
}
</script>