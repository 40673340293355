<template>
	<div :class="divClass">
		<div class="form-group row" :class="{ error: errors.length }">
			<label :class="labelClass" v-if="!titleHide">{{ title }} <small v-show="isRequired" class="asterisksign">*</small>
			</label>
			<div class="controls">
				<textarea :id="id" name="textarea" class="form-control" :value="modelValue" :placeholder="placeholder"
					:rows="rows" :cols="cols" autocomplete="chrome-off"
					@input="$emit('update:modelValue', $event.target.value)" :readonly="readonly"></textarea>
			</div>
			<div class="error-msg">
				<ul class="mb-0 p-0 fs-14">
					<li class="d-block" v-for="(error, index) of errors" :key="index">
						{{ error.$message }}
					</li>
				</ul>
			</div>
		</div>
		<div :class="noteClass">
			<small class="form-text text-muted" v-if="notes != ''">{{ notes }}</small>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
			default: "",
		},
		modelValue: {
			type: String,
			default: "",
		},
		errors: {
			type: Array,
			default: () => []
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		labelClass: {
			type: String,
			default: "form-label",
		},
		divClass: {
			type: String,
			default: "col-md-6",
		},
		noteClass: {
			type: String,
			default: "col-12 col-sm-8 col-md-8 col-lg-4 col-xl-5",
		},
		notes: {
			type: String,
			default: "",
		},
		rows: {
			type: [String,Number],
			default: "2",
		},
		cols: {
			type: [String,Number],
			default: "10",
		},
		id: {
			type: String,
		},
		placeholder:{
			type: String,
		},
		readonly: {
			type: Boolean,
			default: false
		},
		titleHide: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
