<template>
	<div :class="divClass">
		<div class="form-group" :class="{ error: errors.length }">
			<label :class="labelClass" v-if="!titleHide">{{ title }}
				<small v-show="isRequired" class="asterisksign">*</small>
			</label>
			<div class="controls">
				<select :disabled="disabled" :name="title" :id="checkID" :title="title" class="form-select" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)">
					<option value="" :disabled="optDisabled" v-if="!optHide">Select {{ title }}</option>
					<option :value="item.value" v-for="(item,index) in option" :key="index">{{ item.title }}</option>
				</select>
				<span class="form-text text-muted" v-if="notes != ''">{{ notes }}</span>
			</div>
			<div class="error-msg">
				<ul class="mb-0 p-0 fs-14">
					<li class="d-block" v-for="(error, index) of errors" :key="index">
						{{ error.$message }}
					</li>
				</ul>
			</div>
			<div :class="noteClass" v-show="note != ''" style="{vertical-align:-webkit-baseline-middle}">
				<small class="form-text text-muted">{{ note }}</small>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		optDisabled: {
			type: Boolean,
			default: false,
		},
		optHide: {
			type: Boolean,
			default: false,
		},
		titleHide: {
			type: Boolean,
			default: false,
		},
		modelValue: {},
		errors: {
			type: Array,
			default: () => []
		},
		title: {
			type: String,
		},
		option: {
			type: Object,
		},
		isRequired: {
			type: Boolean,
		},
		labelClass: {
			type: String,
			default: "form-label",
		},
		divClass: {
			type: String,
			default: "col-md-6",
		},
		notes: {
			type: String,
			default: "",
		},
		noteClass: {
			type: String,
			default: "col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5",
		},
		note: {
			type: String,
			default: "",
		},
		id: {
			type: String,
		}
	},
	data() {
		return {
			data: "",
			error: false,
			checkID: (this.id) ? this.id : this.title
		};
	},
};
</script>
