<template>
	<SubPageHeader :headerTitle="getHeaderTitle"></SubPageHeader>
	<div class="content">
		<div class="container-full">
			<component :is="$store.state.component" :uniqueId="0">

			</component>
		</div>
	</div>
</template>
<script>
// import TaskCreate from '../../components/patient/task/TaskForm.vue';
// import TaskList from '../../components/patient/task/TaskList.vue';
import SubPageHeader from '../SubPageHeader.vue'
import AllConsultationList from '../../components/consultation/allConsultationList.vue';
import AllSpecialistList from '../../components/consultation/allSpecialistList.vue';

export default {
	data() {
			return {
		
		}
	},
	components: {
		// TaskCreate,
		// TaskList,
		SubPageHeader,
		AllConsultationList,
		AllSpecialistList,

	},
	created() { 
		this.$store.state.component = this.$route.name;
	},
	mounted() {

	},
	methods: {
	},
	computed: {
		getHeaderTitle() {
			const currentRoute = this.$route;
			if (currentRoute.meta && currentRoute.meta.headerTitle) {
				return currentRoute.meta.headerTitle;
			} else {
				const parentRoute = currentRoute.matched[0];
				if (parentRoute.meta && parentRoute.meta.headerTitle) {
					return parentRoute.meta.headerTitle;
				} else {
					return 'Default Header Title';
				}
			}
		},
  },
}
</script>