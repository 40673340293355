<template>
    <div class="box">
        <div class="box-header">
            <div class="row">
                <div class="col-11">
                    <h3 class="box-title">Patient Alternative Contact</h3>
                </div>
                <div class="col-1">
                    <router-link :to="{ name: 'PatientAlternativeContactCreate', params: { patient_id: $route.params.patient_id,  }}" class="waves-effect waves-light btn btn-info d-block mb-10 btn-sm">
                        <i class="fa fa-plus fs-14"></i> Add Contact
                    </router-link>
                </div>
            </div>
        </div>


        <div class="box-body">
            <Datatable dataType="alt_contact"  dataMethod="post" :key="key">
                <template v-slot="slotProps">
                    <td>{{ slotProps.item.first_name }}</td>
                    <td>{{ slotProps.item.last_name }}</td>
                    <td>{{ slotProps.item.email }}</td>
                    <td>
                        <button class="btn btn-primary btn-sm me-1" href="javascript::void(0)" @click="editDocument(slotProps.item.id)" ><i class="fa fa-pencil"></i>&nbsp;Edit</button>
                        <a href="#" v-if="!slotProps.item.deleted_datetime" class="btn btn-danger btn-sm me-1" @click="deleteRecord(slotProps.item.id)"> <i class="fa fa-trash-o"></i>&nbsp;Delete </a>
    
                    </td>
                </template>
            </Datatable>
        </div>
    </div>

</template>


<script>


import Datatable from '../../base/formFields/Datatable.vue';
import axios from "@/scripts/axios.js";

export default {
    data(){
        return {
            key: 0,
            search_drop: true,
            searchKey:0,
        }
    },
	components: {
		Datatable,
	},
    methods:{
        deleteRecord(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to revert this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/alternative-contact/delete";
                    axios.post(url, {'id':id, 'patient_id': this.$route.params.patient_id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.applyFilter()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });


        },
        resetFilter(){
            this.searchKey = this.searchKey + 1;
            this.applyFilter()
        },
        applyFilter() {
            this.key++;
            this.search_drop = false;
        },
        editDocument(id){
            this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}/retrieve/`+id);   

        },
    },
    mounted() {
    },
    computed: {
        filter() {
            return this.$store.state.patient_document.filter;
        },
    }
};
</script>
