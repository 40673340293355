<template>
    <div class="search_drop_box">
        <div v-for="index in loopCount" :key="index">
            <CustomFilterEle :options="options" :fieldName="index" :initialValue="initialValue" @array-updated="handleArrayUpdate" @text-updated="handleTextUpdate" @remove-item="handleRemoveFilEle" :selectedOpt="selectedOpt"></CustomFilterEle>
        </div>
        <div @click="addFilterEle" class="text-center plus_btn">+</div>
    </div>
</template>

<script>
import CustomFilterEle from '../base/CustomFilterEle.vue';

export default {
    data() {
        return {
            componetsRenderCnt: 1, // The number of times you want to loop
            selectedFieldNames:[],
            selectectedFields:[],
        }
  },
  props: {
    options: {
        type: Array,
        required: true,
    },
    fieldName:{
        type: String,
        required: true,
    },
    initialValue:{
        type: String,
        required: true,  
    },
    selectedOpt:{
      type: Array,
      required: true, 
    }
  },
  components:{
    CustomFilterEle
  },
  methods:{
    addFilterEle(){
        this.componetsRenderCnt++
    },
    handleArrayUpdate(arrayData) {
      // Handle the received array data here
      console.log('Received array from child:', arrayData);

      if (this.selectectedFields.includes(arrayData.value)) {
        console.log("Filter is already selected")
        this.$filters.moshaToast("Please change another Field for filter", "error");
        return false
      }

      if (this.selectectedFields[arrayData.field]) {
        this.selectectedFields[arrayData.field] = arrayData.value;
      } else {
        this.selectectedFields[arrayData.field] = ''
        this.selectectedFields[arrayData.field] = arrayData.value
      }

 
      if (this.selectedFieldNames[arrayData.field]) {
        this.selectedFieldNames[arrayData.field]['field'] = arrayData.value
      } else {
        this.selectedFieldNames[arrayData.field] = []
        this.selectedFieldNames[arrayData.field]['field'] = arrayData.value
      }

      console.log('selectedFieldNames: -', this.selectedFieldNames);
      console.log('selectectedFields: -', this.selectectedFields);

      this.$emit('custom-filter', this.selectedFieldNames);
    },
    handleTextUpdate(value){
      console.log('Received string from child:', value);
      console.log('--------', this.selectedFieldNames);
      if (this.selectedFieldNames[value.field]) {
        this.selectedFieldNames[value.field]['value'] = value.value
      } else {
        this.selectedFieldNames[value.field] = []
        this.selectedFieldNames[value.field]['value'] = value.value
      }

      console.log(this.selectedFieldNames)
      
      this.$emit('custom-filter', this.selectedFieldNames);
    },
    // handleTextUpdate(value){
    //   console.log('--------', this.selectedFieldNames);
    //   console.log('Hereee-------------------:', value);

    //   if (value.value === '') {
    //       // If value is empty, remove the entire array for the field
    //       delete this.selectedFieldNames[value.field];
    //   } else {
    //       // If value is not empty, update the value in the selectedFieldNames
    //       if (this.selectedFieldNames[value.field]) {
    //           this.selectedFieldNames[value.field]['value'] = value.value;
    //       } else {
    //           this.selectedFieldNames[value.field] = [];
    //           this.selectedFieldNames[value.field]['value'] = value.value;
    //       }
    //   }

    //   console.log(this.selectedFieldNames);
      
    //   this.$emit('custom-filter', this.selectedFieldNames);
    // },
    handleRemoveFilEle(){
      this.componetsRenderCnt--;
      this.$emit('minus-filter-length');
    },
  },
  computed: {
    loopCount() {
      // Create an array with the desired length
      return Array.from({ length: this.componetsRenderCnt }, (_, index) => index + 1);
    }
  },
  created() {
    if (this.selectedOpt.length > 0) {
        this.componetsRenderCnt  = this.selectedOpt.length - 1
    }
  }
}
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
