<template>
	<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
		<h3>Prescriptions</h3>
	</div>
	<div class="box">
		<div class="box-body">
			Preferred Pharmacy:
			<SelectBoxStatic title="Select Pharmacy" class="col-md-6 d-inline-block" v-model="selectedPharmacy"
				:option="pharmacy_options" :titleHide="true"></SelectBoxStatic>
			<a href="#" data-bs-toggle="modal" data-bs-target="#link-pharmacy" class="btn btn-primary float-end m-1">Link
				Pharmacy</a>
			<div class="modal center-modal fade" id="link-pharmacy" tabindex="-1" style="display: none;" aria-hidden="true">
				<div class="modal-dialog  modal-dialog-scrollable modal-xl">
					<div class="modal-content">
						<div class="modal-body">
							<button type="button" class="btn-close float-right" data-bs-dismiss="modal"
								aria-label="Close"></button>
							<div class="box">
								<div class="box-body">
									<div class="page-card-container" v-if="section1">
										<div class="page-card-header "><span>Preferred Pharmacies</span></div>
										<div class="page-card-body">
											<div class="mb-4" v-if="selectedPharmacyobj != null">
												<div class="pharmacy-card-wrapper">
													<div>
														<div class="d-flex align-items-center mb-2">
															<i class="fa fa-map-marker"
																style="color: #15b3ff; font-size: 22px;"></i>
															<div class="fs-16 fw-500 m-10">
																{{ selectedPharmacyobj.pharmacy_name }}
															</div>
														</div>
														<div class="d-flex">
															<div class="text-uppercase fw-500 mb-3 me-3"
																style="color: #9e9e9e;">Fax</div>
															<div class="fw-500">
																{{ selectedPharmacyobj.fax }}
															</div>
														</div>
														<div class="d-flex">
															<div class="text-uppercase fw-500 mb-3 me-3"
																style="color: #9e9e9e;">ADR</div>
															<div class="fw-500">
																<div>{{ selectedPharmacyobj.address }}</div>
																<div>{{ selectedPharmacyobj.city }} </div>
																<div>{{ selectedPharmacyobj.zip_code }} </div>
																<div>{{ selectedPharmacyobj.state }} </div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="text-uppercase fw-500 mb-3" style="color: #9e9e9e;">Linked
												Pharmacies</div>
											<div class="link-bar-wrapper">
												<i class="fa fa-search"></i>
												<div class="auto-complete-section selectcontrols">
													<Autocomplete @input="searchInPharmacyList" @onSelect="pharmacySelected"
														:results="searchPatientPharmaciesList" :max="30" ref="autocomplete2"
														placeholder="Link Pharmacy" class="form-control"
														:use-html-for-results="true" />
												</div>
												<draggable tag="div" :list="PatientPharmacyList" item-key="id" @end="onEnd">
													<template #item="{ element }">
														<div class="preferred-pharmacy-item">
															<div class="w-p100 d-flex align-items-center">
																<i class="fa fa-navicon p-10"></i>
																<div class="w-p100">
																	<div class="d-flex justify-content-between"><span
																			class="font-weight-500">{{ element.pharmacy_name
																			}}</span><span>Fax: {{ element.fax }}</span>
																	</div>
																	<div class="d-flex justify-content-between">
																		<div><span>{{ element.address }}</span><span>, {{
																			element.state }}</span><span>,
																				{{ element.city }}</span><span>&nbsp;{{
																					element.zip_code }}</span></div>
																		<span>Phone: {{ element.phone }}</span>
																	</div>
																</div>
															</div>
															<a href="#" @click="removeFromPatientPharmacyList(element.id)"
																class="p-10"> <i class="mdi mdi-link-off"></i> </a>
														</div>
													</template>
												</draggable>
											</div>
										</div>
										<div class="page-card-footer text-center">
											<a href="#" @click="managePharmacy">Manage Pharmacy Address Book</a>
										</div>
									</div>
									<div class="page-card-container" v-if="section2">
										<div class="page-card-header "><span>View All Pharmacies</span><a href="#"
												class="float-right text-white" @click="viewPatientPharmacy">Go Back</a>
										</div>
										<div class="page-card-body">
											<div class="p-3 background-content-background-light">
												<div class="search-bar-wrapper">
													<div class="color-text-hint uppercase mx-3">Search</div>
													<input type="text" placeholder="Search"
														@keyup="filterPharmacyList($event)">
												</div>
											</div>
											<div class="section-loader" v-show="loader2">
												<img src="images/page_load.gif"
													style="position: absolute; height: -webkit-fill-available;" />
											</div>
											<div>
												<slot v-for="(item, index) in pharmacy_list_filtered" :key="index">
													<div class="manage-pharmacy-item">
														<div class="w-p100">
															<div class="d-flex justify-content-between">
																<div class="d-flex align-items-center">
																	<span class="fw-500">{{ item.pharmacy_name }}</span>
																</div>
																<span>Fax: {{ item.fax }}</span>
															</div>
															<div class="d-flex align-items-center justify-content-between">
																<div>
																	<span>{{ item.address }}</span>
																	<span>, {{ item.state }}</span>
																	<span>, {{ item.city }}</span>
																	<span>&nbsp; {{ item.zip_code }}</span>
																</div>
																<span>Phone: {{ item.phone }}</span>
															</div>
														</div>
														<div class="d-flex align-items-center p-10">
															<a class="d-flex align-items-center p-5" href="#"
																@click="editPharmacy(item.id)">
																<i class="fa fa-pencil"></i>
															</a>
															<a class="d-flex align-items-center p-5" href="#"
																@click="deletePharmacy(item.id)">
																<i class="fa fa-trash"></i>
															</a>
														</div>
													</div>
												</slot>
											</div>
										</div>
										<div class="page-card-footer text-center">
											<a href="#" class="float-right p-10 m-10" @click="createPharmacy">CREATE
												PHARMACY</a>
										</div>
									</div>
									<div class="page-card-container" v-if="section3">
										<div class="page-card-header ">
											<span>
												<slot v-if="pharmacyform.id != null">Update</slot>
												<slot v-else>Create</slot> Pharmacy
											</span>
											<a href="#" class="float-right text-white" @click="managePharmacy">Go Back</a>
										</div>
										<div class="page-card-body">
											<div class="section-loader" v-show="loader3">
												<img src="images/page_load.gif"
													style="position: absolute; height: -webkit-fill-available;" />
											</div>
											<div class="row p-20">
												<InputField class="col-md-12" title="Name:" id="pharmacy_name"
													:isRequired="true" v-model="v$.pharmacyform.pharmacy_name.$model"
													:errors="v$.pharmacyform.pharmacy_name.$errors"> </InputField>
												<InputField title="Address:" id="pharmacy_address" :isRequired="true"
													v-model="v$.pharmacyform.address.$model"
													:errors="v$.pharmacyform.address.$errors"> </InputField>
												<InputField title="City:" id="pharmacy_city" :isRequired="true"
													v-model="v$.pharmacyform.city.$model"
													:errors="v$.pharmacyform.city.$errors"> </InputField>
												<InputField title="Province:" id="pharmacy_state" :isRequired="true"
													v-model="v$.pharmacyform.state.$model"
													:errors="v$.pharmacyform.state.$errors"> </InputField>
												<InputField title="Postal Code:" id="pharmacy_zip_code" :isRequired="true"
													v-model="v$.pharmacyform.zip_code.$model"
													:errors="v$.pharmacyform.zip_code.$errors"> </InputField>
												<InputField v-model="pharmacyform.phone" title="Phone:" id="pharmacy_phone"
													mask="(000) 000-0000"> </InputField>
												<InputField v-model="pharmacyform.alternative_phone" title="Phone 2:"
													id="pharmacy_alternative_phone" mask="(000) 000-0000"> </InputField>
												<InputField v-model="pharmacyform.fax" title="Fax:" id="pharmacy_fax">
												</InputField>
												<InputField title="Email:" id="pharmacy_email"
													v-model="v$.pharmacyform.email.$model"
													:errors="v$.pharmacyform.email.$errors"> </InputField>
												<InputField v-model="pharmacyform.identificator" title="Identificator:"
													id="pharmacy_identificator"> </InputField>
											</div>
										</div>
										<div class="page-card-footer text-center">
											<slot v-if="pharmacyform.id != null">
												<a href="#" @click="savePharmacy" class="m-5">UPDATE</a>
												<a href="#" @click="deletePharmacy(pharmacyform.id)" class="m-5"
													style="color: red;">DELETE</a>
											</slot>
											<slot v-else>
												<a href="#" @click="savePharmacy" class="m-5">CREATE</a>
											</slot>
											<a href="#" @click="managePharmacy" class="m-5">CANCEL</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="box">
		<div class="box-body">
			<div class="p-15">
				<div class="table-responsive">
					<table class="table table-bordered mb-0">
						<tbody>
							<tr>
								<th>DIN | PIN | NPN</th>
								<th>Generic Name</th>
								<th>Drug Name</th>
								<th>Manufacture</th>
								<th>Drug List Date</th>
								<th>Unit Price</th>
								<th>Covered Price</th>
								<th>Strength</th>
								<th>Dosage Form</th>
								<th><span class="glyphicon glyphicon-list-alt"></span></th>
							</tr>
						</tbody>
						<tbody>
							<slot v-if="prescriptionsList.length > 0">
								<slot v-for="(item, index) in prescriptionsList" :key="index">
									<tr>
										<td> 
											<a href="javascript:void(0);" @click="getDrugLUDetails(item.generic_id)" data-bs-toggle="modal" data-bs-target="#lcc_details">
												{{ item.din_pin_npn }}
											</a> 
										</td>
										<td>{{ item.generic_name }}</td>
										<td>{{ item.drug_name }}</td>
										<td>{{ item.manufacture }}</td>
										<td>{{ $filters.inputDateTime(item.drug_list_date, 'YYYY-MM-DD') }}</td>
										<td>{{ item.unit_price }}</td>
										<td>{{ item.covered_price }}</td>
										<td>{{ item.strength }}</td>
										<td>{{ item.dosage_form }}</td>
										<td>
											<a href="#" class="glyphicon glyphicon-list-alt" @click="showAnnotation('prescriptions', item.id, item.annotation_note)"></a>
										</td>
									</tr>
								</slot>
							</slot>
							<slot v-else>
								<tr>
									<td colspan="13" class="text-center"> No data </td>
								</tr>
							</slot>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="box" v-if="this.$filters.permissioncheck([this.$store.state.permission.demographics_prescription_crud_all, this.$store.state.permission.demographics_prescription_crud])">
		<h4 class="box-header text-center">
			Add an Prescription
		</h4>
		<div class="box-body">
			<div class="row">
				<div class="col-md-5" id="generic_name">
					<div class="form-group" :class="{ error: v$.form.generic_name.$errors.length }">
						<label class="form-label">Generic Name <small class="asterisksign">*</small></label>
						<div class="controls">
							<div class="auto-complete-section selectcontrols">
								<Autocomplete @input="getmedicationList" @onSelect="medicationSelected"
									:results="medicationList" :max="30" ref="autocomplete" placeholder="Generic Name"
									class="form-control" />
							</div>
						</div>
						<div class="error-msg" v-if="v$.form.generic_name.$errors.length > 0">
							<ul class="mb-0 p-0 fs-14">
								<li class="d-block" v-for="(error, index) of v$.form.generic_name.$errors" :key="index">
									{{ error.$message }}
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-md-1" id="drug_info" v-if="form.generic_id">
					<a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#lcc_details"
						@click="getDrugLUDetails(form.generic_id)"><i class="fa fa-info-circle" aria-hidden="true"></i></a>
				</div>
				<InputField title="Drug Name" id="drug_name" v-model="form.drug_name"> </InputField>
				<InputField title="manufacture" id="manufacture" v-model="form.manufacture"> </InputField>
				<DatePicker title="Start Date" v-model="form.start_date"></DatePicker>
				<InputField title="Qty/Mitte" id="qty" v-model="form.qty" type="number"> </InputField>
				<InputField title="Repeats" id="repeats" v-model="form.repeats" type="number"> </InputField>
				<SelectBoxStatic title="eTreatment Type" v-model="form.eTreatment_type" :option="etreatment_type_options">
				</SelectBoxStatic>
				<SelectBoxStatic title="Status" v-model="form.status" :option="rx_status_options"></SelectBoxStatic>
				<DatePicker title="Drug Listing Date" v-model="form.drug_listing_date"></DatePicker>
				<InputField title="Unit Price" id="unit_price" v-model="form.unit_price" type="number"> </InputField>
				<InputField title="Covered Price" id="covered_price" v-model="form.covered_price" type="number">
				</InputField>
				<InputField title="Strength" id="strength" v-model="form.strength"> </InputField>
				<InputField title="Dosage Form" id="dosage_form" v-model="form.dosage_form"> </InputField>
				<InputField title="Coverage Status" id="coverage_status" v-model="form.coverage_status"> </InputField>
				<TextArea title="Notes" divClass="col-6" v-model="form.notes" :rows="6"></TextArea>
			</div>
			<button class="btn btn-primary text-white m-5" @click="submit"> Submit <img src="images/loader.gif"
					v-if="loader" /> </button>
			<button class="btn btn-danger text-white m-5" @click="closeWindow"> Cancel </button>
		</div>
	</div>
	<div class="modal center-modal fade" id="lcc_details" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog  modal-dialog-scrollable modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">LU Clinical Criteria</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<table class="table table-success table-striped">
						<thead>
							<tr>
								<th>LU Code</th>
								<th>Auth. Period</th>
								<th>Clinical Criteria</th>
							</tr>
						</thead>
						<tbody>
							<slot v-if="lu_details != null">
								<tr v-for="(item, index) in lu_caution" :key="index">
									<td></td>
									<td></td>
									<td>
										<slot v-for="(item4, index4) in item" :key="index4">
											<b>Note: </b>  CAUTION:<div class="mb-10" style="white-space: pre-wrap;" v-html="item4"></div>
										</slot>										
									</td>
								</tr>
								<tr v-for="(item, index) in lu_details" :key="index">
									<td>{{ index }}</td>
									<td v-if="item.R">
										<slot v-for="(item1, index1) in item.R" :key="index1">
											<slot v-if="item1.includes('LU Authorization Period')">
												{{ forAuthPeriod(item1) }}
											</slot>
										</slot>
									</td>
									<td v-else></td>
									<td>
										<slot v-if="item.T">
											<slot v-for="(item5, index5) in item.T" :key="index5">
												<div class="mb-10" style="white-space: pre-wrap;font-weight: 900;" v-html="item5"></div>
											</slot>
										</slot>
										<div class="mb-10" style="white-space: pre-wrap;" v-html="item.reason"></div>
										<slot v-if="item.R">
											<slot v-for="(item2, index2) in item.R" :key="index2">
												<slot v-if="!item2.includes('LU Authorization Period')">
													<div class="mb-10" style="white-space: pre-wrap;" v-html="item2"></div>
												</slot>
											</slot>
										</slot>
										<slot v-if="item.N">
											<slot v-for="(item3, index3) in item.N" :key="index3">
												<b>Note: </b><div class="mb-10" style="white-space: pre-wrap;" v-html="item3"></div>
											</slot>
										</slot>										
										<slot v-if="item.W">
											<slot v-for="(item6, index6) in item.W" :key="index6">
												<b>Network Note: </b><div class="mb-10" style="white-space: pre-wrap;" v-html="item6"></div>
											</slot>
										</slot>
									</td>
								</tr>
							</slot>
							<slot v-else>
								<tr>
									<td colspan="3" class="text-center">NO</td>
								</tr>
							</slot>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import InputField from "../base/formFields/InputField.vue"
import useVuelidate from "@vuelidate/core";
import DatePicker from "../base/formFields/DatePicker.vue";
import SelectBoxStatic from "../base/formFields/SelectBoxStatic.vue";
import axios from "@/scripts/axios.js";
import TextArea from "../base/formFields/TextArea.vue";
import Autocomplete from 'vue3-autocomplete';
import draggable from 'vuedraggable';

export default {
	components: {
		InputField,
		DatePicker,
		SelectBoxStatic,
		TextArea,
		Autocomplete,
		draggable
	},
	computed: {
		form() {
			return this.$store.state.prescriptions.form;
		},
		pharmacyform() {
			return this.$store.state.pharmacy.form;
		},
	},
	validations() {
		return {
			form: this.$store.state.prescriptions.validationRules.form,
			pharmacyform: this.$store.state.pharmacy.validationRules.form
		};

	},
	data() {
		return {
			searchPatientPharmaciesList: [],
			section1: true,
			section2: false,
			section3: false,
			selectedPharmacy: null,
			selectedPharmacyobj: null,
			pharmacy_options: [],
			prescriptionsList: [],			
			loader: false,
			loader2: false,
			loader3: false,			
			etreatment_type_options: [
				{ value: "CHRON", title: "Continuous/chronic" },
				{ value: "ACU", title: "Acute" },
				{ value: "ONET", title: "One Time" },
				{ value: "PRNL", title: "Long-term - As needed" },
				{ value: "PRNS", title: "Short-term - As needed" },
			],
			rx_status_options: [
				{ value: "New", title: "New" },
				{ value: "Active", title: "Active" },
				{ value: "Suspended", title: "Suspended" },
				{ value: "Completed", title: "Completed" },
				{ value: "Obsolete", title: "Obsolete" },
				{ value: "Nullified", title: "Nullified" },
			],
			medicationList: [],
			pharmacy_list: [],
			pharmacy_list_filtered: [],
			PatientPharmacyList: [],
			lu_details: null,
			lu_caution: null,
		};
	},
	watch: {
		"pharmacy_list": function (newValue) {
			if (newValue.length > 0) {
				this.getPatientPharmacy();
			}
		},
	},
	setup: () => {
		return { v$: useVuelidate() };
	},
	created() {
		this.resetforms('form');
		this.resetforms('pharmacyform');
		this.getPharmacyList();
	},
	mounted() {
		this.getList();
	},
	methods: {
		forAuthPeriod(string){
			return string.replace("LU Authorization Period: ", "");			
		},
		sortObjectByKey(obj) {
			const emptyKey = obj['']
			delete obj[''];
			const sortedKeys = Object.keys(obj).sort();

			const reorderedData = {
				...Object.fromEntries(sortedKeys.map(key => [key, obj[key]]))
			};
			return [reorderedData,emptyKey];
		},
		getDrugLUDetails(id) {
			axios.post("medication/detail", { id: id })
				.then((response) => {
					let data = response.data.data;
					if(data){
						const reorderedData = this.sortObjectByKey(data);
						this.lu_details = reorderedData[0];
						this.lu_caution = reorderedData[1];
						console.log(this.lu_caution)
					}else{
						this.lu_details = null;
						this.lu_caution = null;
					}
				});
		},
		removeFromPatientPharmacyList(id) {
			const PatientPharmacyList = this.PatientPharmacyList.filter(obj => obj.id !== id);
			this.PatientPharmacyList = PatientPharmacyList;
			this.onEnd();
		},
		filterPharmacyList(event) {
			const searchTerm = event.target.value.toLowerCase();
			const searchPatientPharmaciesList = [];
			for (const pharmacyObj of this.pharmacy_list) {
				const matchesSearchTerm = Object.values(pharmacyObj).some((value) => {
					if (value != null) {
						if (value.toString().toLowerCase().includes(searchTerm)) {
							return true;
						}
					}
				});
				if (matchesSearchTerm) {
					searchPatientPharmaciesList.push(pharmacyObj);
				}
			}
			if (searchPatientPharmaciesList.length > 0) {
				const uniqueObjects = Array.from(new Set(searchPatientPharmaciesList.map(obj => JSON.stringify(obj))));
				const PatientPharmaciesUniqueList = uniqueObjects.map(objString => JSON.parse(objString));
				this.pharmacy_list_filtered = PatientPharmaciesUniqueList.map((item) => {
					return { ...item }
				});
			} else {
				this.pharmacy_list_filtered = [];
			}
		},
		searchInPharmacyList(obj) {
			const searchTerm = obj.toLowerCase();
			const searchPatientPharmaciesList = [];
			for (const pharmacyObj of this.pharmacy_list) {
				const matchesSearchTerm = Object.values(pharmacyObj).some((value) => {
					if (value != null) {
						if (value.toString().toLowerCase().includes(searchTerm)) {
							return true;
						}
					}
				});
				if (matchesSearchTerm) {
					searchPatientPharmaciesList.push(pharmacyObj);
				}
			}
			if (searchPatientPharmaciesList.length > 0) {
				const uniqueObjects = Array.from(new Set(searchPatientPharmaciesList.map(obj => JSON.stringify(obj))));
				const PatientPharmaciesUniqueList = uniqueObjects.map(objString => JSON.parse(objString));

				this.searchPatientPharmaciesList = PatientPharmaciesUniqueList.map((item, index) => {
					const srt = '<a id="link-pharmacy-item-' + index + '" class="dropdown-item" href="#"><div class="d-flex justify-content-between"><span class="fw-500">' + item.pharmacy_name + '</span><span>Fax: ' + item.fax + '</span></div><div><span>' + item.address + '</span> <span>, ' + item.state + '</span> <span>, ' + item.city + '</span> <span>&nbsp; ' + item.zip_code + '</span></div></a>'
					return { ...item, name: srt }
				});
			}
			return null;
		},
		pharmacySelected(obj) {
			this.$refs.autocomplete2.searchText = null;
			if (Object.prototype.hasOwnProperty.call(obj, "name")) {
				delete obj["name"];
			}
			this.PatientPharmacyList.push(obj);
			const uniqueObjects = Array.from(new Set(this.PatientPharmacyList.map(item => JSON.stringify(item))));
			this.PatientPharmacyList = uniqueObjects.map(objString => JSON.parse(objString));
			this.onEnd();
			const btn = this.$refs.autocomplete2;
			btn.$el.querySelector('.vue3-autocomplete-container > .vue3-input').blur();
			this.searchPatientPharmaciesList = [];
		},
		onEnd() {
			let linked_pharmacy_ids = this.PatientPharmacyList.map(obj => obj.id);
			axios.post("patient/pharmacy/store-or-update", { patient_id: this.$route.params.patient_id, linked_pharmacy_ids: linked_pharmacy_ids });
			this.selectedPharmacyobj = this.PatientPharmacyList[0];
			this.selectedPharmacy = this.PatientPharmacyList[0].id;
		},
		getPatientPharmacy() {
			axios.post("patient/pharmacy/retrieve", { patient_id: this.$route.params.patient_id })
				.then((response) => {
					if (response.data.data != null) {
						let desiredIds = response.data.data.linked_pharmacy_ids.split(",");
						this.PatientPharmacyList = this.pharmacy_list.filter(obj => {
							if (desiredIds.includes(obj.id.toString())) {
								return obj;
							}
						});
						this.selectedPharmacyobj = this.PatientPharmacyList[0];
						this.selectedPharmacy = this.PatientPharmacyList[0].id;
					}
				});
		},
		deletePharmacy(id) {
			const confirmMsg = confirm("Are you sure you want to delete this pharmacy from your Clinic's address book?");
			if (confirmMsg) {
				axios.post("pharmacy/delete", { 'id': id })
					.then((response) => {
						this.$filters.moshaToast(response.data.message, "success");
						this.getPharmacyList();
					})
			}
		},
		getPharmacyList() {
			axios.post("pharmacy/list")
				.then((response) => {
					this.pharmacy_list = response.data.data;
					this.pharmacy_list_filtered = response.data.data;
					this.pharmacy_options = response.data.data.map((item) => {
						return { value: item.id, title: item.pharmacy_name };
					});
				});
		},
		editPharmacy(id) {
			this.loader2 = true;
			axios.post("pharmacy/retrieve", { 'id': id })
				.then((response) => {
					if (response.data.status === 200) {
						this.$store.state.pharmacy.form = response.data.data;
						this.loader2 = false;
						this.section1 = false;
						this.section2 = false;
						this.section3 = true;
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
		},
		viewPatientPharmacy() {
			this.section1 = true;
			this.section2 = false;
			this.section3 = false;
		},
		managePharmacy() {
			this.section1 = false;
			this.section2 = true;
			this.section3 = false;
			this.resetforms("pharmacyform");
			this.v$.pharmacyform.$reset();
		},
		createPharmacy() {
			this.section1 = false;
			this.section2 = false;
			this.section3 = true;
		},
		savePharmacy() {
			const formName = 'pharmacyform';
			this.v$[formName].$validate();
			if (!this.v$[formName].$error) {
				this.loader3 = true;
				const form = this[formName];
				let url
				if (this[formName].id != null) {
					url = "pharmacy/update";
				} else {
					url = "pharmacy/store";
				}
				axios.post(url, form)
					.then((response) => {
						this.$filters.moshaToast(response.data.message, "success");
						this.resetforms(formName);
						this.v$[formName].$reset();
						this.$refs.autocomplete.searchText = null;
						this.loader3 = false;
						this.getPharmacyList();
						this.managePharmacy();
					});
			} else {
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
		},
		medicationSelected(obj) {
			this.form.med_id = obj.id;
			this.form.generic_name = obj.generic_name;
			this.form.generic_id = obj.generic_id;
			this.form.drug_name = obj.drug_name;
			this.form.manufacture = obj.manufacturer_name;
			this.form.strength = obj.strength;
			this.form.dosage_form = obj.dosage_form;
			this.form.drug_listing_date = obj.drug_listing_date;
			this.form.unit_price = obj.unit_price;
			this.form.covered_price = obj.covered_price;
			this.form.coverage_status = obj.coverage_status;
			this.$refs.autocomplete.searchText = obj.generic_name;
			const btn = this.$refs.autocomplete;
			btn.$el.querySelector('.vue3-autocomplete-container > .vue3-input').blur();
		},
		getmedicationList(obj) {
			this.$refs.autocomplete.$el.classList.add('selectloader');
			axios.post("/json_autocomplete/medication/list", { term: obj }).then(response => {
				this.medicationList = response.data.data.map((item) => {
					return { ...item, name: item.drug_name + ' (' + item.manufacturer_name + ') ' + item.strength + ' ' + item.dosage_form }
				});
				this.$refs.autocomplete.$el.classList.remove('selectloader');
			});
		},
		showAnnotation(moduleName, history_id, annotation_note) {
			const { protocol, hostname, port } = window.location;
			// Construct the base URL using the parts obtained above
			let baseUrl = `${protocol}//${hostname}`;

			// Add the port to the base URL if it's not the default for the protocol
			if (port && !['80', '443'].includes(port)) {
				baseUrl += `:${port}`;
			}
			let encryptedMessage;
			if (annotation_note != null) {
				encryptedMessage = this.$filters.encryptString(annotation_note);
				baseUrl += `/#/patient/annotation-store/${moduleName}/${this.$route.params.patient_id}/${history_id}/${encryptedMessage}`
			} else {
				baseUrl += `/#/patient/annotation-store/${moduleName}/${this.$route.params.patient_id}/${history_id}`
			}

			const newWindow = window.open(baseUrl, `_blank`);
			if (newWindow) {
				newWindow.focus();
			}
		},
		resetforms(formName) {
			if (formName == 'form') {
				Object.assign(
					this.$store.state.prescriptions.form,
					this.$store.state.prescriptions.defaultFormData
				);
			}
			if (formName == 'pharmacyform') {
				Object.assign(
					this.$store.state.pharmacy.form,
					this.$store.state.pharmacy.defaultFormData
				);
			}
		},
		getList() {
			this.$store.state.loader = true;
			axios.post("patient/prescriptions/list", { patient_id: this.$route.params.patient_id })
				.then((response) => {
					this.prescriptionsList = response.data.data;
					this.$store.state.loader = false;
				});
		},
		submit() {
			const formName = 'form';
			this.v$[formName].$validate();
			if (!this.v$[formName].$error) {
				this.loader = true;
				this[formName].patient_id = this.$route.params.patient_id;
				const form = this[formName];
				let url
				if (this[formName].id != null) {
					url = "patient/prescriptions/update";
				} else {
					url = "patient/prescriptions/store";
				}
				axios.post(url, form)
					.then((response) => {
						this.$filters.moshaToast(response.data.message, "success");
						this.getList();
						this.resetforms(formName);
						this.v$[formName].$reset();
						this.$refs.autocomplete.searchText = null;
						this.loader = false;
					});
			} else {
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
		},
		closeWindow() {
			window.close();
		}
	},
};
</script>
<style scoped>
.background-content-background-light {
	background: #fafafa;
}

.search-bar-wrapper {
	display: flex;
	align-items: center;
}

.color-text-hint {
	color: #9e9e9e;
}

.page-card-header {
	background: #27364c;
	color: #fff;
	padding: 8px 12px;
	font-weight: 500;
}

.manage-pharmacy-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
}

.manage-pharmacy-item:nth-child(2n) {
	background: #fafafa;
}

.preferred-pharmacy-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
}
</style>