<template>
	<h3 style="text-align: center;">Issue Annotation</h3>
	<div class="p-10" style="background: aliceblue;">
		<div class="float-right" v-if="annotation_count > 0">Annotation rev: <a href="#" @click="displayAnnotationList">
				{{ annotation_count }}
			</a>
		</div>
		<TextArea divClass="col-12" title="Annotation:" v-model="annotation_note" :rows="6"></TextArea>
	</div>
	<button type="button" class="btn btn-danger float-end m-5" @click="closeWindow">Exit</button>
	<button type="button" class="btn btn-primary float-end m-5" @click="submit" >Save</button>
</template>
<script>
import axios from "@/scripts/axios.js";
import TextArea from '../base/formFields/TextArea.vue';

export default {
	components: {
		TextArea,		
	},
	data() {
		return {
			annotation_note: null,
			annotation_count: 0,
		}
	},
	methods: {
		getAnnotation() {
			const url = "patient/" + this.$route.params.moduleName + "/annotation-count";
			const patient_id = this.$route.params.patient_id;
			axios.post(url, { patient_id: patient_id, history_id: this.$route.params.history_id })
				.then((response) => {
					this.annotation_count = response.data.data;					
				});
		},
		displayAnnotationList() {
			const { protocol, hostname, port } = window.location;

			// Construct the base URL using the parts obtained above
			let baseUrl = `${protocol}//${hostname}`;

			// Add the port to the base URL if it's not the default for the protocol
			if (port && !['80', '443'].includes(port)) {
				baseUrl += `:${port}`;
			}

			baseUrl += `/#/patient/annotation-list/${this.$route.params.moduleName}/${this.$route.params.patient_id}/${this.$route.params.history_id}`

			const newWindow = window.open(baseUrl, `_blank`);
			if (newWindow) {
				newWindow.focus();
			}
		},
		closeWindow(){
			window.close();
		},
		submit(){
			const url = "patient/" + this.$route.params.moduleName + "/annotation-store";
			const patient_id = this.$route.params.patient_id;
			axios.post(url, { patient_id: patient_id, history_id: this.$route.params.history_id, annotation_note: this.annotation_note })
				.then((response) => {
					this.$filters.moshaToast(response.data.message, "success");
					this.getAnnotation();			
				});
		}
	},
	mounted() {
		this.getAnnotation();
		this.annotation_note = this.$filters.decryptString(this.$route.params.annotation_note);
	},
}
</script>