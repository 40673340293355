<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" @keyup="taskSearch" class="form-control" placeholder="Search Task" v-model="form.keyword" @blur="taskSearch" ref="globalSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList"></CustomFilter>
                    </span>
                </div>
                <div>
                    <button class="comman_brdr_btn" v-if="this.selectedItems.length > 0" @click="activeTask('Active')"> Active </button>
                </div>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col" class="active" @click="changeSort('service_date', $event)"> Create Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('reminder_message', $event)"> Message <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('patient_name', $event)"> Demographic <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('assigned_to', $event)"> Assigned Providers <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('priority', $event)"> Status <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.allCompDelTasksList" :key="index" :class="{ 'active': (selectChk[item.id] || selectedItems.includes(item.id) ) }">
                            <td><input class="form-check-input" type="checkbox" :checked="isChecked(item.id)" v-model="selectChk[item.id]" @change="checkSelect(item.id)"></td>
                            <td>{{ $filters.inputDateTime(item.service_date, 'YYYY-MM-DD') }}</td>
                            <td>{{ $filters.capitalizeFirstWordFirstLetter(item.reminder_message) }}</td>
                            <td>{{ item.patient_name }}</td>
                            <td>{{ item.provider_ids }}</td>
                            <td>{{ item.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>
<script>
import sidebar from './sidebar.vue';
import CustomFilter from '../../base/CustomFilter.vue';
import axios from "@/scripts/axios.js";

export default {
        components: {
            sidebar,
            CustomFilter
        },
        data() {
            return {
                selectAllChk: false,
                selectChk: [],
                selectedItems: [],
                allItems: [],
                urgency:[
					{ value: "Urgent", title: "Urgent" },
					{ value: "Normal", title: "Normal" },
				],
                status: [
                    { value: "Active", title: "Active" },
                    { value: "Complete", title: "Complete" },
                    { value: "Delete", title: "Delete" },
                ],
                filterOptions:[
                    { value: "reminder_message", title: "Message" },
                    { value: "priority", title: "Urgency" },
                    { value: "patient_name", title: "Demographic" },
                ],
                isShowCustomFilter:false,

                allCompDelTasksList: [],
                loader: false,
                filterList:[]
            }
        },
        mounted() {
            this.getCompDelTaskList();
        },
        methods:{
            activeTask(changeStatus) {
                this.$swal({
                    title: 'Are you sure you want to activate task?',
                    text: '',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Yes, activate it!',
                    cancelButtonText: 'No, cancel please!',
                    closeOnConfirm: false,
                    closeOnCancel: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log('selectedItems:-  ', this.selectedItems);
                        if(this.selectedItems)
                        {
                            axios.post("patient/task/update-status", { status: changeStatus, ids: this.selectedItems, patient_id: this.$route.params.patient_id } )
                            .then((response) => {
                                console.log('response:- ', response.data.data);
                                this.getCompDelTaskList();
                                this.$filters.moshaToast(response.data.message, "success");
                            });
                        }
                    }
                });
            },
            isChecked(id){
                return this.selectedItems.includes(id);
            },
            checkSelectAll(){
                if (this.selectAllChk) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            checkSelect(id){
                if (this.selectChk[id]) {
                    this.selectedItems.push(id)
                } else {
                    const index = this.selectedItems.indexOf(id);
                    if (index !== -1) {
                        this.selectedItems.splice(index, 1);
                    }
                }

                if (this.selectedItems.length == this.allItems.length) {
                    this.selectAllChk = true;
                } else if (this.selectedItems.length == 0) {
                    this.selectAllChk = false;
                } else {
                    this.selectAllChk = false;
                }
            },
            changeSort(orderColumnName, event){
                event.stopPropagation();
                const th = event.target;

                this.form.sort_by = orderColumnName;
                if (th.classList.contains('active')) {
                    th.classList.remove('active');
                    this.form.sort_order = 'asc';
                } else {
                    th.classList.add('active');
                    this.form.sort_order = 'desc';
                }

                this.getCompDelTaskList();
            },
            capitalizeData(data) {
                return data.map(item => ({
                    ...item,
                    patient_name: this.$filters.strToUpperCase(item.patient_name),
                    assigned_to: this.$filters.strToUpperCase(item.assigned_to)
                }));
            },
            getCompDelTaskList() {
                this.form.from =  'compDelTaskList',
                this.form.patient_id = this.$route.params.patient_id;
                axios.post('patient/task/list', this.form)
                    .then((response) => {
                        if (response.status == 200) {
                            this.allCompDelTasksList = response.data.data;
                            if(this.allCompDelTasksList) {
                                this.allCompDelTasksList = this.capitalizeData(this.allCompDelTasksList);
                            }
                            this.allCompDelTasksList.forEach(item => {
                                this.allItems.push(item.id)
                            });
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            console.log(Object.values(error.response.data.data).join(', '))
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            taskSearch() {
                if(this.form.keyword.length > 2)
                {
                    this.getCompDelTaskList();
                }
                if(this.form.keyword.length == 0)
                {
                    this.getCompDelTaskList();
                    if(this.allCompDelTasksList) {
                        this.allCompDelTasksList = this.capitalizeData(this.allCompDelTasksList);
                    }
                }
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
                this.getCompDelTaskList();
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    advanceFilter[item.field] = item.value
                });
                this.form.advanceFilter = advanceFilter
            },
        },
        computed: {
            form() {
                return this.$store.state.tasks.form;
            },
        },
        validations() {
            return this.$store.state.tasks.validationRules;
        },
}
</script>