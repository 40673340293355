<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 px-0">
            <div class="admin-left-content management_settings">
                <div class="row align-items-center m-0">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0">
                        <h1 class="h1-admin_head mb-0">Shift Hours Setting</h1>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 text-end">
                        <button class="comman_btn crt-user-bttn big_btn_new" @click="createSetting()" v-if="this.checkPermission()">Create Setting</button>
                    </div>
                </div>
                <div class="table-responsive acct-stng-tbl mt-30">
                    <table class="table table-borderless text-center mb-0 ">
                        <thead>
                            <tr>
                                <th>USER TYPE</th>
                                <th>LAST NAME</th>
                                <th>FIRST NAME</th>
                                <th>SHIFT SCHEDULE</th>
                                <th>ACTIVITY</th>
                                <th style="width: 65px;"></th>
                            </tr>
                        </thead>
                        <tbody v-if="shiftHoursSettingsList.length > 0">
                            <tr v-for="(item,index) in this.shiftHoursSettingsList" :key="index">
                                <td>{{ item.role_name }}</td>
                                <td>{{ item.last_name }}</td>
                                <td>{{ item.first_name }}</td>
                                <td>{{ item.shift_schedule_start_date }} : {{ item.shift_schedule_end_date }} | {{ item.day_of_week }}</td>
                                <td>{{ (item.is_active)? 'Active' : 'Inactive' }}</td>
                                <td>
                                    <img src="/images/dots-icon.svg" class="dot_icon" 
                                        @click="viewCreateSettings('shift-hours', item.user_id, item.recurrence_ids)"
                                        :class="{ 'invisible': !this.checkPermission() && (item.user_id != this.authUserId) }"
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-end">
                    <ul class="page-pagination p-0" :class="{ 'invisible': this.shiftHourTotalPages == 1 }">
                        <li style="margin-right: 40px;"
                            :class="{ 'disabled': this.shiftHourCurrentPage === 1 }"
                            @click="this.shiftHourTotalPages > 1 && this.shiftHourCurrentPage > 1 && getShiftHoursSettingsList(this.shiftHourCurrentPage - 1)">
                            <img src="/images/left-arrow-pagen.svg" class="img-fluid" v-if="this.shiftHourCurrentPage != 1">
                        </li>
                        <li>{{ this.shiftHourCurrentPage }}</li>
                        <li>/</li>
                        <li>{{ this.shiftHourTotalPages || 1 }}</li>
                        <li style="margin-left: 40px;"
                            :class="{ 'disabled': this.shiftHourCurrentPage == this.shiftHourTotalPages }"
                            @click="this.shiftHourTotalPages > 1 && this.shiftHourCurrentPage < this.shiftHourTotalPages && getShiftHoursSettingsList(this.shiftHourCurrentPage + 1)">
                            <img src="/images/right-arrow-pagen.svg" class="img-fluid" v-if="this.shiftHourCurrentPage != this.shiftHourTotalPages">
                        </li>
                    </ul>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <h1 class="h1-admin_head mb-0 mr-100">Compensation Management</h1>
                    <span class="popup_link fw-600" @click="viewHrReport"> View HR Reports</span>
                </div>
                <div class="table-responsive acct-stng-tbl mt-30">
                    <table class="table table-borderless text-center mb-0 ">
                        <thead>
                            <tr>
                                <th>USER TYPE</th>
                                <th>LAST NAME</th>
                                <th>FIRST NAME</th>
                                <th>SALARY CONFIGURATION</th>
                                <th>ACTIVITY</th>
                                <th style="width: 65px;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in this.compensationSettingsList" :key="index">
                                <td>{{ item.role_name }}</td>
                                <td>{{ item.last_name }}</td>
                                <td>{{ item.first_name }}</td>
                                <td>{{ item.salary_configuration }}</td>
                                <td>{{ (item.is_active)? 'Active' : 'Inactive' }}</td>
                                <td>
                                    <img src="/images/dots-icon.svg" class="dot_icon" 
                                        @click="viewCreateSettings('compensation-management', item.user_id, [])"
                                        :class="{ 'invisible': !this.checkPermission() && (item.user_id != this.authUserId) }"
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-end">
					<ul class="page-pagination p-0" :class="{ 'invisible': this.compensationTotalPages == 1 }">
                        <li  style="margin-right: 40px;"
                            :class="{ 'disabled': this.compensationCurrentPage === 1 }"
                            @click="this.compensationTotalPages > 1 && this.compensationCurrentPage > 1 && getCompensationSettingsList(this.compensationCurrentPage - 1)">
                            <img src="/images/left-arrow-pagen.svg" class="img-fluid" v-if="this.compensationCurrentPage != 1">
                        </li>
                        <li>{{ this.compensationCurrentPage }}</li>
                        <li>/</li>
                        <li>{{ this.compensationTotalPages || 1 }}</li>
                        <li  style="margin-left: 40px;"
                            :class="{ 'disabled': this.compensationCurrentPage == this.compensationTotalPages }"
                            @click="this.compensationTotalPages > 1 && this.compensationCurrentPage < this.compensationTotalPages && getCompensationSettingsList(this.compensationCurrentPage + 1)">
                            <img src="/images/right-arrow-pagen.svg" class="img-fluid" v-if="this.compensationCurrentPage != this.compensationTotalPages">
                        </li>
                    </ul>
				</div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="create-setting" tabindex="-1" style="display: none;" aria-hidden="true" ref="createSettingsPop">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content manage_cmn_pup">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closePop"></button>
                    <div class="box">
                        <div>
                            <ul class="comn_tab_menu">
                                <li :class="{ 'active': this.isShowShiftHour }" @click="changeActiveTab($event,'shift_hour')">Regular Hours</li>
                                <li :class="{ 'active': this.isShowSpecialHour }"  @click="changeActiveTab($event,'special_hour')">Special Hours</li>
                                <li :class="{ 'active': this.isShowCompensation }"  @click="changeActiveTab($event,'compensation')">Compensation Management</li>
                            </ul>
                            <section class="con_detl">
                                <div class="row regular_hours" :class="{ 'special_hour': this.isShowSpecialHour, 'composation': this.isShowCompensation }">
                                    <div class="col-md-12">
                                        <div class="detail_main_box select_user mb-35">
                                            <div class="data_label">SELECT USER <small class="asterisksign">*</small></div>
                                            <div class="data_con" style="padding: 19.5px 0px;" v-if="this.form.user_name_role">{{  this.form.user_name_role  }}</div>
                                                <CustomDropDown v-else :options="providerUserListData" :initialValue="this.form.user_name_role_test" @item-selected="handleItemSelected" fieldName="provider_list"></CustomDropDown>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="this.isShowShiftHour">
                                        <div class="detail_main_box">
                                            <div class="data_label">START DATE <small class="asterisksign">*</small></div>
                                            <!-- <div class="data_con"><input type="text" class="form-control" v-model="this.form.shift_schedule_start_date"></div> -->
                                            <div class="calendar text_calender">
                                                <ejs-datepicker 
                                                    class="e-field form-control" 
                                                    :value="form.shift_schedule_start_date" 
                                                    @change="handleDate($event, 'start')" 
                                                    v-model="form.shift_schedule_start_date" 
                                                    :showClearButton="false" 
                                                    format="yyyy-MM-dd" 
                                                    @focus="openDatePicker('shift_schedule_start_datepicker')" 
                                                    ref="shift_schedule_start_datepicker" 
                                                    readonly>
                                                </ejs-datepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 pr-72" v-if="this.isShowShiftHour">
                                        <div class="detail_main_box justify-content-end">
                                            <div class="data_label w-auto">END DATE <small class="asterisksign">*</small></div>
                                            <!-- <div class="data_con"><input type="text" class="form-control" v-model="this.form.shift_schedule_end_date"></div> -->
                                            <div class="calendar text_calender">
                                                <ejs-datepicker 
                                                    class="e-field form-control" 
                                                    :value="form.shift_schedule_end_date" 
                                                    @change="handleDate($event, 'end')" 
                                                    v-model="form.shift_schedule_end_date" 
                                                    :showClearButton="false" 
                                                    format="yyyy-MM-dd" 
                                                    @focus="openDatePicker('shift_schedule_end_datepicker')" 
                                                    ref="shift_schedule_end_datepicker" 
                                                    readonly>
                                                </ejs-datepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-25" v-if="this.isShowShiftHour">
                                        <div class="detail_main_box align-items-sm-start">
                                            <div class="data_label mt-30">SHIFT SCHEDULE <small class="asterisksign">*</small></div>
                                            <div class="data_con shft_schedule">
                                                <div :class="{'scrl_con': form.regular_hours.length > 6}">
                                                    <slot v-for="(item, index) in form.regular_hours" :key="index">
                                                        <div class="contn_dx mb-20">
                                                            <input type="hidden" v-model="item.id">
                                                            <div class="mr-37 drop-list-scroll">
                                                                <CustomDropDown :isOpenUpSide="isDropdownOpenUpside(index)" :options="days_opt"  @item-selected="handleItemSelected" :fieldName="`week_day_${index}`" :initialValue="item.day_of_week" v-model="item.day_of_week" ></CustomDropDown>
                                                            </div>
                                                            <div class="time_picker">
                                                                <label for="" class="shift_label">Start Time</label>                                                        
                                                                <ejs-timepicker 
                                                                    :ref="`appointment_starttime_${index}`"
                                                                    class="e-field e-input" 
                                                                    :step="5" 
                                                                    :showClearButton="false" 
                                                                    :name="`appointment_starttime_${index}`" 
                                                                    :id="`appointment_starttime_${index}`" 
                                                                    @change="schedulerStartTimeChange($event, `start_time_${index}`, index)" 
                                                                    v-model="item.start_time">
                                                                </ejs-timepicker>
                                                            </div> 
                                                            <div class="dash_line">-</div>                                               
                                                            <div class="time_picker mr-37">
                                                                <label for="" class="shift_label">End Time</label>
                                                                <ejs-timepicker 
                                                                    :ref="`appointment_endtime_${index}`"
                                                                    class="e-field e-input" 
                                                                    :step="5" 
                                                                    :showClearButton="false" 
                                                                    :name="`appointment_endtime_${index}`" 
                                                                    :id="`appointment_endtime_${index}`" 
                                                                    @change="schedulerStartTimeChange($event, `end_time_${index}`, index)" 
                                                                    v-model="item.end_time">
                                                                </ejs-timepicker>
                                                            </div>
                                                            <div class="week_number">
                                                                <label for="" class="shift_label">Repeats (Week)</label>
                                                                <input type="number" class="form-control focus_none" v-model="item.recurrent_week_interval">
                                                            </div>
                                                            <div class="close_sign_icon" @click="removeRegularHourObj(index, item.id)">
                                                                <img src="/images/close-icon.svg" class="img-fluid">
                                                            </div>
                                                        </div>
                                                    </slot >
                                                </div>
                                                <div class="plus_sign_row" @click="addRegularHourObj">+</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row special_hour" v-if="this.isShowSpecialHour">
                                    <div class="col-md-12">
                                        <div class="detail_main_box align-items-sm-start">
                                            <div class="data_label mt-30">SHIFT SCHEDULE <small class="asterisksign">*</small></div>
                                            <div class="data_con shft_schedule">
                                                <div :class="{'scrl_con': form.special_hours.length > 4}">
                                                    <slot v-for="(item, index) in form.special_hours" :key="index">
                                                        <div class="contn_dx mb-20">
                                                            <div :class="{'mr-20': item.is_day_off, 'mr-37': !item.is_day_off}">
                                                                <label class="shift_label invisible"></label>
                                                                <div class="calendar text_calender">
                                                                    <ejs-datepicker 
                                                                        class="e-field form-control" 
                                                                        :value="item.date" 
                                                                        @change="handleSpecialHourDate($event, 'special_hour_date', index)" 
                                                                        v-model="item.date" 
                                                                        :showClearButton="false" 
                                                                        format="yyyy-MM-dd" 
                                                                        @focus="openDatePicker(`special_hours_datepicker_${index}`)" 
                                                                        :ref="`special_hours_datepicker_${index}`" 
                                                                        readonly>
                                                                    </ejs-datepicker>
                                                                </div>
                                                            </div>
                                                            <div class="time_picker" v-if="item.is_day_off === false">
                                                                <label for="" class="shift_label">Start Time</label>                                                        
                                                                <ejs-timepicker 
                                                                    :ref="`special_hour_start_time_${index}`"
                                                                    class="e-field e-input" 
                                                                    :step="5" 
                                                                    :showClearButton="false" 
                                                                    :name="`special_hour_start_time_${index}`" 
                                                                    :id="`special_hour_start_time_${index}`" 
                                                                    @change="schedulerStartTimeChange($event, `special_hour_start_time_${index}`, index)" 
                                                                    v-model="item.start_time">
                                                                </ejs-timepicker>
                                                            </div> 
                                                            <div class="dash_line" v-if="item.is_day_off === false">-</div>                                               
                                                            <div class="time_picker mr-37" v-if="item.is_day_off === false">
                                                                <label for="" class="shift_label">End Time</label>
                                                                <ejs-timepicker 
                                                                    :ref="`special_hour_end_time_${index}`"
                                                                    class="e-field e-input" 
                                                                    :step="5" 
                                                                    :showClearButton="false" 
                                                                    :name="`special_hour_end_time_${index}`" 
                                                                    :id="`special_hour_end_time_${index}`" 
                                                                    @change="schedulerStartTimeChange($event, `special_hour_end_time_${index}`, index)" 
                                                                    v-model="item.end_time">
                                                                </ejs-timepicker>
                                                            </div>
                                                            <div class="close_sign_icon cursor-pointer">
                                                                <img src="/images/close-icon.svg" class="img-fluid" @click="removeSpecialHourObj(index)">
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center mb-18 fs-14 fw-600 txt_gry">
                                                            <span class="mr-37">Day Off</span>
                                                            <div class="usr-dtls-switch accs-swtch">
                                                                <label class="switch d-block">
                                                                    <input type="checkbox"
                                                                        :checked="item.is_day_off === true" 
                                                                        @change="onChangeDayOffToggleEvent(index)"
                                                                        v-model="item.is_day_off"
                                                                    >
                                                                    <span class="sw-slider"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </slot>
                                                </div>
                                                <div class="plus_sign_row" @click="addSpecialHourObj">+</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row composation" v-if="this.isShowCompensation">
                                    <div class="col-md-12 mb-46">
                                        <div class="detail_main_box">
                                            <div class="d-flex align-items-center">
                                                <div class="data_label">
                                                    SALARY CONFIGURATIONS (RATE)
                                                </div>
                                                <div class="usr-dtls-switch accs-swtch">
                                                    <label class="switch d-block">
                                                    <input type="checkbox" 
                                                            :checked="form.is_active_salary_rate_toggle === true" 
                                                            @change="onChangeToggleEvent('salary_rate')">
                                                    <span class="sw-slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="data_con d-flex align-items-center" v-if="form.is_active_salary_rate_toggle === true">
                                                <div class="edit-fild-nw price-w-dollar position-relative">
                                                    <span class="dollar-sign">$</span>
                                                <input type="text" placeholder="" class="form-control" v-model="form.salary_rate">
                                                </div>
                                                <p class="mb-0 fils_value"> / Hour</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="detail_main_box" v-if="this.form.user_role == 'Doctor' || this.form.user_role == 'Nurse'">
                                            <div class="d-flex align-items-center">
                                                <div class="data_label d-flex align-items-center">
                                                    SALARY CONFIGURATIONS (SPLIT)
                                                </div>
                                                <div class="usr-dtls-switch accs-swtch">
                                                    <label class="switch d-block">
                                                    <input type="checkbox" 
                                                            :checked="form.is_active_salary_split_toggle === true" 
                                                            @change="onChangeToggleEvent('salary_split')">
                                                    <span class="sw-slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="data_con d-flex align-items-center" v-if="form.is_active_salary_split_toggle === true">
                                                <div class="edit-fild-nw">
                                                <input type="text" placeholder="" class="form-control" v-model="form.salary_split">
                                                </div>
                                                <p class="mb-0 fils_value">% to MD</p>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                            </section>
                        </div>
                        <div class="btn_part text-center d-flex justify-content-center align-items-center">
                            <button class="comman_brdr_btn big_btn mx40" @click="closePop"> Cancel </button>
                            <div class="position-relative">
                                <button class="comman_btn big_btn mx40" 
                                    @click="storeManagementSettings"
                                    :class="{ 'invisible': (!this.checkPermission() && (this.form.selected_user_id != this.authUserId)) }"
                                    >Save
                                    <img src="images/loader.gif" v-if="loader" />
                                </button>
                                <span class="popup_link ml-20 fs-20 fw-600" style="position: absolute;white-space: nowrap;padding: 17px 0px;line-height: normal;" 
                                    @click="seePreview()"
                                    v-if="this.isShowShiftHour || this.isShowSpecialHour"
                                >
                                    See Preview
                                </span>  
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
    <div class="modal fade main_popup" id="see-preview" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close z-1" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="box">
                        <ejs-schedule 
                            ref="schedule"
                            :selectedDate="selectedDate" 
                            :eventSettings="eventSettings"
                            :currentView="currentView"
                            :views = "views"
                            :renderCell='onRenderCell'
                        >
                            <template v-slot:eventTemplate="{ data }">
                                <div class="calendar_data_table">
                                    <div class="sub_heading">{{ data.Subject }}</div>
                                    <div v-for="(item, index) in data.Description" :key="index" class="sub_heading">
                                        {{ item }}
                                    </div>
                                </div>
                            </template>
                        </ejs-schedule>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './sidebar.vue';
import $ from "jquery";
import moment from "moment";
import axios from "@/scripts/axios.js";
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import { TimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { ScheduleComponent, Month } from '@syncfusion/ej2-vue-schedule';
export default {
    data() {
        return {
            selectedDate: new Date(),
            currentView: 'Month',
            views: [ 'Month' ],
            eventSettings: {
                template: "eventTemplate",
                dataSource: [],
            },
            loader: false,
            userRoles: [{ 
                0 : 'System',
                1 : 'Admin',
                2 : 'Doctor',
                3 : 'Nurse',
                4 : 'Staff'
            }],
            shiftHourObj: {
                day_of_week: '',
                start_time: '',
                end_time: '',
                recurrent_week_interval: '',
            },
            isShowShiftHour:true,
            isShowSpecialHour:false,
            isShowCompensation:false,
            days_opt:[
                { value: 0, title: "Sunday" },
                { value: 1, title: "Monday" },
                { value: 2, title: "Tuesday" },
                { value: 3, title: "Wednesday" },
                { value: 4, title: "Thursday" },
                { value: 5, title: "Friday" },
                { value: 6, title: "Saturday" },
            ],
            providerUserListData: [],
            shiftHoursSettingsList: [],
            shiftHourTotalPages: 1,
            shiftHourCurrentPage: 1,
            compensationSettingsList: [],
            compensationTotalPages: 1,
            compensationCurrentPage: 1,
            regularHours: {
                day_of_week: '',
                start_time: '',
                end_time: '',
                recurrent_week_interval: null,
            },
            specialHours: {
                date: '',
                start_time: '',
                end_time: '',
                is_day_off: false,
            },
            form:{
                selected_user_id: '',
                user_name_role: '',
                user_role: '',
                user_name_role_test: 'Select Users',
                shift_schedule_start_date: '',
                shift_schedule_end_date: '',
                regular_hours: [{
                    id: null,
                    day_of_week: '',
                    start_time: '',
                    end_time: '',
                    recurrent_week_interval: null,
                }],
                special_hours: [],
                is_active_salary_rate_toggle: false,
                is_active_salary_split_toggle: false,
                salary_rate: '',
                salary_split: '',
                is_setting_create_or_update: 'create',
                removed_recurrence_ids: [],
            },
            managementCalendarEventData: [],
            authUserId: null,
            authUserRole: '',
            permissionList: [],
        }
    },
    components: {
        sidebar,
        CustomDropDown,
        'ejs-timepicker': TimePickerComponent,
        'ejs-datepicker': DatePickerComponent,
        'ejs-schedule': ScheduleComponent,
    },
    provide: {
		schedule: [ Month ]
	},
    computed: {
        
    },
    mounted() {
        this.authUserId = localStorage.getItem("userId");
        this.authUserRole = localStorage.getItem("authUserRole");
        this.providerUserList();
        this.getPermissionList()
        this.getShiftHoursSettingsList(this.shiftHourCurrentPage);
        this.getCompensationSettingsList(this.compensationCurrentPage);
    },
    watch: {
        handleClickOutside(event) {
            const popupElement = this.$refs.createSettingsPop;
            if(popupElement && !popupElement.contains(event.target))
            {
                this.closePop();
            }
		},
        managementCalendarEventData(newData) {
            this.eventSettings = {
                dataSource: newData
            };
        }
    },
    methods: {
        viewHrReport() {
            this.$router.push(
                `/administration/hr-analysis-report`
            );
        },
        isCurrentMonth(dateStr) {
			const currentDate = new Date();
			const currentMonth = currentDate.getMonth();
			const currentYear = currentDate.getFullYear();
			
			const dateToCheck = new Date(dateStr);
			const checkMonth = dateToCheck.getMonth();
			const checkYear = dateToCheck.getFullYear();
			
			return currentMonth === checkMonth && currentYear === checkYear;
		},
        onRenderCell(args) {
			
			if (args.elementType === "monthCells") {
				setTimeout(() => {
					$(args.element).find('.e-appointment-wrapper .e-appointment:not(:last)').remove();
				}, 2000);
				if (!this.isCurrentMonth(args.date)) {
					if ($(args.element).hasClass('e-other-month')) {
						$(args.element).html('');
					}
				}
			}
		},
        getPermissionList(){
            axios.post('user/get-permission')
            .then((response) => {
                if (response.status == 200) {
                    this.permissionList = response.data.data
                } else {
                    this.permissionList = []
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.permissionList = []
                } else {
                    this.permissionList = []
                }
            });
        },
        checkPermission(){
            if (this.permissionList && this.permissionList.administration_access) {
                return true
            } else {
                return false
            }
        },
        isDropdownOpenUpside(index) {
            return index > 5;
        },
        onChangeToggleEvent(type) {
            if (type === 'salary_rate') {
                this.form.is_active_salary_rate_toggle = !this.form.is_active_salary_rate_toggle;
            } else if (type === 'salary_split') {
                this.form.is_active_salary_split_toggle = !this.form.is_active_salary_split_toggle;
            }
        },
        onChangeDayOffToggleEvent(index)
        {
            console.log('index-----', index);
            console.log('this.form.special_hours[index].is_day_off', index, this.form.special_hours[index].is_day_off, this.form.special_hours);
        },
        seePreview(){
            window.$("#see-preview").modal("show")
            setTimeout(() => {
                this.$refs.schedule.ej2Instances.refreshEvents();
                $('td').has('.e-appointment-wrapper').addClass('td-has-event');
            }, 500);
        },
        checkForConflicts() {
            const timeFormat = 'h:mm A';
            const parseTime = (time) => moment(time, timeFormat);
            
            const scheduleMap = {};

            for (const schedule of this.form.regular_hours) {
                const { day_of_week, start_time, end_time } = schedule;

                if (!scheduleMap[day_of_week]) {
                    scheduleMap[day_of_week] = [];
                }

                const startTime = parseTime(start_time);
                const endTime = parseTime(end_time);

                for (const existing of scheduleMap[day_of_week]) {
                    const existingStartTime = parseTime(existing.start_time);
                    const existingEndTime = parseTime(existing.end_time);

                    if ((startTime.isBefore(existingEndTime) && endTime.isAfter(existingStartTime)) || 
                        (startTime.isSame(existingStartTime) && endTime.isSame(existingEndTime))) {
                        return true;
                    }
                }

                scheduleMap[day_of_week].push({ start_time, end_time });
            }

            return false;
        },
        convertTo24HourFormat(time) {
            const [timeString, modifier] = time.split(' ');
            let [hours, minutes] = timeString.split(':');
            
            // Convert hours to string for padStart to work
            hours = hours.toString();

            if (hours === '12') {
                hours = '00';
            }

            if (modifier === 'PM') {
                hours = (parseInt(hours, 10) + 12).toString();
            }

            return `${hours.padStart(2, '0')}:${minutes}`;
        },
        validateFromData(){
            if(this.form.selected_user_id === '')
            {
                this.$filters.moshaToast('Required, To select user.', "error");
                return false;
            }

            /* Regular Hours Validation */
            let skipRegularHoursValidation = false;
            if(this.form.regular_hours.length > 0)
            {
                const checkFirstItem = this.form.regular_hours[0];
                if (checkFirstItem.day_of_week === '' && checkFirstItem.start_time === '' && checkFirstItem.end_time === '' && checkFirstItem.recurrent_week_interval == null) {
                    if (this.form.shift_schedule_start_date === '' && this.form.shift_schedule_end_date === '') {
                        skipRegularHoursValidation = true;
                    }
                }

                if (!skipRegularHoursValidation) {
                    if(this.form.shift_schedule_start_date === '')
                    {
                        this.$filters.moshaToast('Start date selection is required.', "error");
                        return false;
                    }

                    if(this.form.shift_schedule_end_date === '')
                    {
                        this.$filters.moshaToast('End date selection is required.', "error");
                        return false;
                    }
    
                    if(this.form.shift_schedule_end_date != '')
                    {
                        const startDate = moment(this.form.shift_schedule_start_date);
                        const endDate = moment(this.form.shift_schedule_end_date);
    
                        if (!endDate.isAfter(startDate)) {
                            this.$filters.moshaToast('End date must be greater than start date.', "error");
                            return false;
                        }
                    }
                    for (let i = 0; i < this.form.regular_hours.length; i++) {
                        const item = this.form.regular_hours[i];
                        if(item.day_of_week === '')
                        {
                            this.$filters.moshaToast('Required to select day of week', "error");
                            return false;
                        }
                        if(item.start_time === '')
                        {
                            this.$filters.moshaToast('Required to select start time', "error");
                            return false;
                        }
                        if(item.end_time === '')
                        {
                            this.$filters.moshaToast('Required to select end time', "error");
                            return false;
                        }
                        const startTime24 = this.convertTo24HourFormat(item.start_time);
                        const endTime24 = this.convertTo24HourFormat(item.end_time);

                        if (endTime24 <= startTime24) {
                            this.$filters.moshaToast('End time must be greater than start time', "error");
                            return false;
                        }
                        if(item.recurrent_week_interval === '' || item.recurrent_week_interval < 1 || item.recurrent_week_interval > 5)
                        {
                            this.$filters.moshaToast('Required to enter week interval between 1 to 5', "error");
                            return false;
                        }
                    }
    
                    const hasConflict = this.checkForConflicts();
                    if(hasConflict === true)
                    {
                        this.$filters.moshaToast('According to day of week selected time is conflict.', "error");
                        return false;
                    }
                }
            }

            /* Special Hours Validation */
            if(this.form.special_hours.length > 0)
            {
                for (let i = 0; i < this.form.special_hours.length; i++) {
                    const item = this.form.special_hours[i];
                    if(item.date == '')
                    {
                        this.$filters.moshaToast('Required to select date on special hour', "error");
                        return false;
                    }
                    if(item.is_day_off === false)
                    {
                        if(item.start_time === '')
                        {
                            this.$filters.moshaToast('Required to select start time on special hour', "error");
                            return false;
                        }
                        if(item.end_time === '')
                        {
                            this.$filters.moshaToast('Required to select end time on special hour', "error");
                            return false;
                        }
                        const startTime24 = this.convertTo24HourFormat(item.start_time);
                        const endTime24 = this.convertTo24HourFormat(item.end_time);

                        if (endTime24 <= startTime24) {
                            this.$filters.moshaToast('End time must be greater than start time on special hour', "error");
                            return false;
                        }
                    }
                }
            }
            else
            {
                this.form.special_hours = [];
            }

            /* Compensation validation */
            if(this.form.is_active_salary_rate_toggle)
            {
                if(this.form.salary_rate == '' && this.form.salary_rate == '$')
                {
                    this.$filters.moshaToast('Required to input salary rate', "error");
                    return false;
                }
            }
            if(this.form.is_active_salary_split_toggle)
            {
                if(this.form.salary_split == '')
                {
                    this.$filters.moshaToast('Required to input split salary', "error");
                    return false;
                }
            }
            // if(this.form.is_active_salary_rate_toggle === false && this.form.is_active_salary_split_toggle === false)
            // {
            //     this.$filters.moshaToast('Required, To fill-up compensation settings.', "error");
            //     return false;
            // }

            /* Common validation */
            if(skipRegularHoursValidation && this.form.special_hours.length === 0 && this.form.is_active_salary_rate_toggle === false && this.form.is_active_salary_split_toggle === false)
            {
                this.$filters.moshaToast('Required, To fill-up any one settings.', "error");
                return false;
            }
            return true;
        },
        storeManagementSettings() {
            
            this.loader = true;
            if(!this.validateFromData()){
                this.loader = false;
                return false;
            }
            else
            {
                axios.post('administration/management-setting-store', this.form )
                .then((response) => {
                    this.loader = false;
                    if (response.status == 200) {
                        // const data = response.data.data;
                        window.$("#create-setting").modal("hide");
                        this.$filters.moshaToast(response.data.message, "success");
                        this.closePop();
                        this.getShiftHoursSettingsList(1);
                        this.getCompensationSettingsList(1);
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.$store.state.loader = false;
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }
        },
        schedulerStartTimeChange(args, field, index) {
            if (field === `start_time_${index}`) {
                this.form.regular_hours[index].start_time = args.text;
            } else if (field === `end_time_${index}`) {
                this.form.regular_hours[index].end_time = args.text;
            } else if (field === `special_hour_end_time_${index}`) {
                this.form.special_hours[index].end_time = args.text;
            } else if (field === `special_hour_start_time_${index}`) {
                this.form.special_hours[index].start_time = args.text;
            }
        },
        handleDate(event, type) {
            if (type === 'start') {
                this.form.shift_schedule_start_date = this.formatDate(event.value);
            } else if (type === 'end') {
                this.form.shift_schedule_end_date = this.formatDate(event.value);
            }
        },
        handleSpecialHourDate(event, type, index)
        {
            if (type === 'special_hour_date') {
                this.form.special_hours[index].date = this.formatDate(event.value);
            }
        },
        formatDate(date) {
            const d = new Date(date);
            const month = '' + (d.getMonth() + 1);
            const day = '' + d.getDate();
            const year = d.getFullYear();
            
            return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
        },
        openDatePicker(type) {
			if (type === 'shift_schedule_start_datepicker') {
				this.$refs.shift_schedule_start_datepicker.show();
			} else if (type === 'shift_schedule_end_datepicker') {
				this.$refs.shift_schedule_end_datepicker.show();
			} else if (type.startsWith('special_hours_datepicker_')) {
                const index = type.split('_').pop();
                const refName = `special_hours_datepicker_${index}`;
                if (this.$refs[refName]) {
                    this.$refs[refName][0].show();  // Accessing the first element of the array returned by $refs
                }
            }
		},
        addRegularHourObj() {
            this.form.regular_hours.push({
                id: null,
                day_of_week: '',
                start_time: '',
                end_time: '',
                recurrent_week_interval: null,
            });
        },
        addSpecialHourObj() {
            this.form.special_hours.push({
                date: '',
                start_time: '',
                end_time: '',
                is_day_off: false,
            });
        },
        removeRegularHourObj(index, recurrenceId) {
            if (index > 0 && index < this.form.regular_hours.length) {
                this.form.regular_hours.splice(index, 1);
                this.form.removed_recurrence_ids.push(recurrenceId);
            }
        },
        removeSpecialHourObj(index) {
            if (index > 0 && index < this.form.special_hours.length) {
                this.form.special_hours.splice(index, 1);
            }
        },
        closePop() {
            window.$("#create-setting").modal("hide");
            this.form.selected_user_id = '';
            this.form.user_name_role = '';
            this.form.user_role = '';
            this.form.user_name_role_test = 'Select Users';
            this.form.shift_schedule_start_date = '';
            this.form.shift_schedule_end_date = '';
            this.form.regular_hours = [{
                id: null,
                day_of_week: '',
                start_time: '',
                end_time: '',
                recurrent_week_interval: null,
            }];
            this.form.special_hours = [];
            this.form.is_active_salary_rate_toggle = false;
            this.form.is_active_salary_split_toggle = false;
            this.form.salary_rate = '';
            this.form.salary_split = '';
            this.isShowShiftHour = false;
            this.isShowSpecialHour = false;
            this.isShowCompensation = false;
            this.managementCalendarEventData = [];
            this.eventSettings.dataSource = [];
            this.form.is_setting_create_or_update = 'create';
            this.form.removed_recurrence_ids = [];
        },
        viewCreateSettings(type, userId, recurrenceIds){
            this.getCalendarEvents(userId);
            this.form.selected_user_id = userId;
            this.$store.state.loader = true;
            axios.post('administration/management-setting-retrieve', { selected_user_id: userId, recurrence_ids: recurrenceIds } )
            .then((response) => {
                this.$store.state.loader = false;
                if (response.status == 200) {
                    const data = response.data.data;
                    /* Regular Hours Settings Data */
                    this.form.is_setting_create_or_update = 'update';
                    this.form.regular_hours = data.regular_hours;
                    this.form.shift_schedule_start_date = data.regular_hours[0].shift_schedule_start_date;
                    this.form.shift_schedule_end_date = data.regular_hours[0].shift_schedule_end_date;
                    this.form.user_name_role = data.regular_hours[0].user_name_role;
                    this.form.user_role = (data.regular_hours[0].user_name_role).split(' | ')[0].trim();

                    /* Special Hours Settings Data */
                    this.form.special_hours = data.special_hours;

                    /* Compensation Settings Data */
                    if (data.compensation_management.length > 0) {
                        this.form.salary_rate = data.compensation_management[0].salary_rate;
                        this.form.salary_split = data.compensation_management[0].salary_split;

                        // Set the toggles based on the presence of values
                        this.form.is_active_salary_rate_toggle = !!this.form.salary_rate;
                        this.form.is_active_salary_split_toggle = !!this.form.salary_split;
                    }
                    if(type === 'shift-hours')
                    {
                        this.isShowShiftHour = true;
                    }
                    else if(type === 'compensation-management')
                    {
                        this.isShowCompensation = true;
                    }
                    window.$("#create-setting").modal("show");
                } else {
                    this.$filters.moshaToast(response.data.message, "error");
                }
            }).catch(error => {
                this.$store.state.loader = false;
                if (error.response.status === 422) {
                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    console.log(Object.values(error.response.data.data).join(', '))
                } else {
                    this.$filters.moshaToast(error.response.data.message, "error");
                }
            });
        },
        getCalendarEvents(userId){
            axios.post('administration/preview-calendar-events', { selected_user_id: userId } )
            .then((response) => {
                if (response.status == 200) {
                    const data = response.data.data;
                    
                    let ejsEvents = [];
                    data.forEach(event => {
                            let [year, month, day] = event.Date.split('-').map(Number);
                            month -= 1;

                            let [startHour, startMinute, startSecond] = event.start_time.split(':').map(Number);
                            let [endHour, endMinute, endSecond] = event.end_time.split(':').map(Number);

                            let descriptionArray = [];

                            event.time_slots.forEach(slot => {
                                descriptionArray.push(slot);
                            });

                            ejsEvents.push({
                                Id: event.Id,
                                Subject: `${event.Subject}`,
                                Description: descriptionArray,
                                StartTime: new Date(year, month, day, startHour, startMinute, startSecond),
                                EndTime: new Date(year, month, day, endHour, endMinute, endSecond),
                                Date: `${year}-${month + 1}-${day}`
                            });
                    });

                    this.managementCalendarEventData = ejsEvents;
                } else {
                    this.$filters.moshaToast(response.data.message, "error");
                }
            }).catch(error => {
                this.$store.state.loader = false;
                if (error.response.status === 422) {
                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    console.log(Object.values(error.response.data.data).join(', '))
                } else {
                    this.$filters.moshaToast(error.response.data.message, "error");
                }
            });
        },
        createSetting(){
            this.isShowShiftHour = true;
            window.$("#create-setting").modal("show")
        },
        changeActiveTab(event, type){
            event.stopPropagation();

            this.isShowShiftHour = false
            this.isShowSpecialHour = false
            this.isShowCompensation = false
            if (type == 'shift_hour'){
                this.isShowShiftHour = true
            }else if (type == 'special_hour'){
                this.isShowSpecialHour = true
            }else if (type == 'compensation'){
                this.isShowCompensation = true
            }
        },
        providerUserList(){
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                'Content-Type': 'application/json',
            };

            axios.post("json_list/provider",{'type':''}, headers)
                .then((response) => {
                    const tempUserData = []
                    $.each(response.data.data, function(key, detail) {
                        if (localStorage.getItem("userId") != detail.user_id) {
                            let fullName = detail.full_name
                            tempUserData.push({
                                'value':detail.user_id,
                                'title':detail.role_name + ' | ' + fullName.toUpperCase(),
                            });
                        }
                    });
                    this.providerUserListData = tempUserData;
            })
        },
        getShiftHoursSettingsList(currentPage) {
            this.$store.state.loader = true;
            axios.post('administration/shift-hours-list', {'current_page': currentPage})
            .then((response) => {
                this.$store.state.loader = false;
                if (response.status == 200) {
                    this.shiftHoursSettingsList = response.data.data.shift_hours_settings_list;
                    this.shiftHourTotalPages = response.data.data.total_pages;
                    this.shiftHourCurrentPage = response.data.data.current_page;
                } else {
                    this.$filters.moshaToast(response.data.message, "error");
                }
            }).catch(error => {
                this.$store.state.loader = false;
                if (error.response.status === 422) {
                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    console.log(Object.values(error.response.data.data).join(', '))
                } else {
                    this.$filters.moshaToast(error.response.data.message, "error");
                }
            });
        },
        getCompensationSettingsList(currentPage) {
            this.$store.state.loader = true;
            axios.post('administration/compensation-list', {'current_page': currentPage})
            .then((response) => {
                this.$store.state.loader = false;
                if (response.status == 200) {
                    this.compensationSettingsList = response.data.data.compensation_settings_list;
                    this.compensationTotalPages = response.data.data.total_pages;
                    this.compensationCurrentPage = response.data.data.current_page;
                } else {
                    this.$filters.moshaToast(response.data.message, "error");
                }
            }).catch(error => {
                this.$store.state.loader = false;
                if (error.response.status === 422) {
                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    console.log(Object.values(error.response.data.data).join(', '))
                } else {
                    this.$filters.moshaToast(error.response.data.message, "error");
                }
            });
        },
        handleItemSelected(values) {
            if(values.fieldName == 'provider_list' && (values.item.value && values.item.title))
            {
                this.form.selected_user_id = values.item.value;
                this.form.user_name_role = values.item.title;
                this.form.user_role = (values.item.title).split(' | ')[0].trim();
            }
            if (values.fieldName.includes('week_day') && values.item.value !== null && values.item.value !== undefined && values.item.title) {
                const index = values.fieldName.split('_')[2];
                this.form.regular_hours[index].day_of_week = values.item.title;
            }
        },
    },
};
</script>

<style>
</style>