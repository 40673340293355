<template>
	<div class="PatientChart_header">
		<div class="row align-items-center">
			<div class="col-md-3">
				<span class="logo">{{ headerTitle }}</span>
			</div>
			<div class="col-md-9">
                <div class="d-flex align-items-center justify-content-end">
                    <span @click="cancelBtn" class="close_sign">
							<img src="/images/close_sign.svg">
						</span>
                </div>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	components: {},
	data() {
		return {
		};
	},
	props: {
		headerTitle: {
			type: String,
			required: true
		},
	},
	validations() { },
	created() { },
	methods: {
        cancelBtn(){
				this.$router.push(
					'/'
				);
        }
    }
};
</script>
