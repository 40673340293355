<template>
	<slot v-if="this.$route.meta.headerTitle != false">
		<PatientHeader :headerTitle="getHeaderTitle"></PatientHeader>
	</slot>
	<div class="content">
		<div :class="containerClass">
			<component :is="$store.state.component">

			</component>
		</div>
	</div>
</template>
<script>
import PatientHeader from '../PatientHeader.vue';
import PatientCreate from '../../components/patient/Form.vue';
import PatientList from '../../components/patient/List.vue';
import PatientChart from '../../components/patient/Chart.vue';
import PatientAppointmentHistory from '../../components/patient/AppointmentHistory.vue';
import PatientEdit from '../../components/patient/Form.vue';
import PatientEChart from '../../components/patient/EChart.vue';
import PatientAnnotationList from '../../components/patient/AnnotationList.vue';
import PatientAnnotationForm from '../../components/patient/AnnotationForm.vue';
import PatientRevisionList from '../../components/patient/RevisionList.vue';
// import PatientAllergies from '../../components/patient/Allergies.vue';
import PatientPrescriptions from '../../components/patient/Prescriptions.vue';
import PatientPreventions from '../../components/patient/Preventions.vue';
// import PatientConsultations from '../../components/patient/Consultations.vue';
// import PatientConsultationsForm from '../../components/patient/ConsultationsForm.vue';
// import PatientConsultationsEdit from '../../components/patient/ConsultationsForm.vue';
import PatientTaskForm from '../../components/patient/task/TaskForm.vue';
import PatientTaskList from '../../components/patient/task/TaskList.vue';
import PatientTaskEdit from '../../components/patient/task/TaskEditForm.vue';
import PatientVital from '../../components/patient/Vital/PatientVital.vue';
import PatientVitalList from '../../components/patient/Vital/PatientVitalList.vue';
import PatientVitalEdit from '../../components/patient/Vital/PatientVitalEdit.vue';
import PatientSet from '../../components/patient/PatientSet.vue';
import PatientDocumentList from '../../components/patient/document/list.vue';
import PatientDocumentAdd from '../../components/patient/document/form.vue';
import PatientDocumentEdit from '../../components/patient/document/edit.vue';
import PatientEformList from '../../components/patient/eform/List.vue';
import PatientCurrentEformList from '../../components/patient/eform/CurrentList.vue';
import PatientDeletedEformList from '../../components/patient/eform/DeletedList.vue';
import PatientAddEform from '../../components/patient/eform/add.vue';
import PatientEditEform from '../../components/patient/eform/edit.vue';
import PatientAlternativeContactList from '../../components/patient/alternative-contact/list.vue';
import PatientAlternativeContactCreate from '../../components/patient/alternative-contact/form.vue';
import PatientAlternativeContactEdit from '../../components/patient/alternative-contact/form.vue';
import PatientEnrollmentHistory from '../../components/patient/enrollment-history/list.vue';
import EditPatientEnrollmentHistory from '../../components/patient/enrollment-history/form.vue';
import axios from "@/scripts/axios.js";
import { mapActions } from 'vuex';
import PatientEChartAllergies from '../../components/patient/echart/allergies.vue';
import PatientEChartConsultList from '../../components/patient/echart/consultation/consultList.vue';
import PatientEChartConsultAdd from '../../components/patient/echart/consultation/consultAdd.vue';
import PatientEChartSpecialistsList from '../../components/patient/echart/specialistsList.vue';
import PatientEChartFilesOverview from '../../components/patient/echart/files/filesOverview.vue';
import PatientEChartFilesEdit from '../../components/patient/echart/files/filesAdd.vue';
import PatientEChartFilesDelete from '../../components/patient/echart/files/filesOverview.vue';
import patientImagingList from '../../components/patient/echart/imaging.vue';
import patientLabsList from '../../components/patient/echart/LabList.vue';
// import patientLabEdit from '../../components/patient/echart/LabEdit.vue';
import patientTemplateList from '../../components/patient/echart/template.vue';
import patientTemplateEdit from '../../components/patient/echart/templateEdit.vue';
import patientMeasurementList from '../../components/patient/echart/measurement/list.vue';
import patientMeasurementGroupList from '../../components/patient/echart/measurement/group-list.vue';
import editModuleTemplate from '../../components/patient/echart/templateModuleEdit.vue'
import PatientEChartPrescriptionList from '../../components/patient/echart/Medications/prescriptionList.vue'
import PatientEChartDrugList from '../../components/patient/echart/Medications/drugList.vue'
import PatientEChartPreferredPharmacy from '../../components/patient/echart/Medications/preferredPharmacy.vue'
import PatientEChartPrescriptionAdd from '../../components/patient/echart/Medications/prescriptionAdd.vue'
import PatientEChartPrescriptionView from '../../components/patient/echart/Medications/prescriptionView.vue'
import PatientEChartImmunizationList from '../../components/patient/echart/preventions/immunizationList.vue'
import PatientEChartScreeningList from '../../components/patient/echart/preventions/screeningList.vue';
import PatientEChartActiveTaskList from '../../components/patient/echart/activeTaskList.vue';
import PatientEChartCompleteDeleteTaskList from '../../components/patient/echart/completeDeleteTaskList.vue';

export default {
	...mapActions(['updatePatientHeaderData']),
	data() {
		return {
		}
	},
	components: {
		PatientHeader,
		PatientCreate,
		PatientList,
		PatientChart,
		PatientAppointmentHistory,
		PatientEdit,
		PatientEChart,
		PatientAnnotationList,
		PatientRevisionList,
		// PatientAllergies,
		PatientPrescriptions,
		PatientAnnotationForm,
		PatientPreventions,
		// PatientConsultations,
		// PatientConsultationsForm,
		// PatientConsultationsEdit,
		PatientTaskForm,
		PatientTaskList,
		PatientTaskEdit,
		PatientVital,
		PatientVitalList,
		PatientVitalEdit,
		PatientSet,
		PatientDocumentList,
		PatientDocumentAdd,
		PatientDocumentEdit,
		PatientEformList,
		PatientCurrentEformList,
		PatientDeletedEformList,
		PatientAddEform,
		PatientEditEform,
		PatientAlternativeContactList,
		PatientAlternativeContactCreate,
		PatientAlternativeContactEdit,
		PatientEnrollmentHistory,
		EditPatientEnrollmentHistory,
		PatientEChartAllergies,
		PatientEChartConsultList,
		PatientEChartConsultAdd,

		PatientEChartPrescriptionList,
		PatientEChartDrugList,
		PatientEChartPreferredPharmacy,
		PatientEChartPrescriptionAdd,
		PatientEChartPrescriptionView,
		PatientEChartImmunizationList,
		PatientEChartScreeningList,



		PatientEChartSpecialistsList,
		PatientEChartFilesOverview,
		PatientEChartFilesEdit,
		PatientEChartFilesDelete,
		patientImagingList,
		patientLabsList,
		patientTemplateList,
		patientTemplateEdit,
		// patientLabEdit,
		editModuleTemplate,
		patientMeasurementList,
		patientMeasurementGroupList,
		PatientEChartActiveTaskList,
		PatientEChartCompleteDeleteTaskList,
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
	mounted() {
		this.updatePatientHeaderData();
	},
	methods: {
		updatePatientHeaderData() {
			if ((this.$store.state.patient_id != "") && (this.$store.state.patient_id != this.$route.params.patient_id)) {
				axios.post("patient/retrieve", { 'id': this.$route.params.patient_id })
					.then((response) => {
						if (response.data.status === 200) {
							this.$store.state.patientHeaderData = response.data.data;
						} else {
							this.$filters.moshaToast(response.data.message, "error")
						}
					})
					.catch(error => {
						this.$filters.moshaToast(error.message, "error")
					});
			}
		}
	},
	computed: {
		containerClass() {
			return {
				'container-fluid': (this.$route.name != 'PatientEChartPrescriptionView' && this.$route.name !=  'PatientEChartPrescriptionAdd'),
			};
		},
		getHeaderTitle() {
			const currentRoute = this.$route;
			if (currentRoute.meta && currentRoute.meta.headerTitle) {
				return currentRoute.meta.headerTitle;
			} else {
				const parentRoute = currentRoute.matched[0];
				if (parentRoute.meta && parentRoute.meta.headerTitle) {
					return parentRoute.meta.headerTitle;
				} else {
					return 'Default Header Title';
				}
			}
		},
	},
}
</script>