import {
	helpers,
	required,
	email,
} from "@vuelidate/validators";
import moment from "moment";

const social_history = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			note: null,
			start_date: null,
			resolution_date: null,
		},
		form: {},
		validationRules: {
			form: {
				start_date: {
					required: helpers.withMessage("Please Select Start Date.", required),
				},
				note: {
					required: helpers.withMessage("Please Enter Note.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
		getters: {},
		mutations: {},
		actions: {},
	}
};

const medical_history = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			note: null,
			start_date: null,
			resolution_date: null,
			procedure_date: null,
			problem_status: null,
			procedure_text: null,
			life_stage: null
		},
		form: {},
		validationRules: {
			form: {
				start_date: {
					required: helpers.withMessage("Please Select Start Date.", required),
				},
				note: {
					required: helpers.withMessage("Please Enter Note.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const ongoing_concerns = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			name: null,
			note: null,
			problem_description: null,
			start_date: null,
			resolution_date: null,
			problem_status: null,
			life_stage: null,
			diagnosis_code_system: null,
			diagnosis_code: null,
			diagnosis_code_description:null
		},
		form: {},
		validationRules: {
			form: {
				note: {
					required: helpers.withMessage("Please Enter Note.", required),
				},
				problem_description:{
					required: helpers.withMessage("Please Enter Problem Description.", required),
				},
				name:{
					required: helpers.withMessage("Please Enter Name.", required),
				},
				start_date:{
					required: helpers.withMessage("Please select start date.", required),
				}
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const reminders = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			note: null,
			encounter_date:null,
			start_date: null,
			resolution_date: null,
			description: null,
		},
		form: {},
		validationRules: {
			form: {
				start_date: {
					required: helpers.withMessage("Please Select Start Date.", required),
				},
				description: {
					required: helpers.withMessage("Please Enter Description.", required),
				},
				// note: {
				// 	required: helpers.withMessage("Please Enter Note.", required),
				// },
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const other_med = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			note: null,
			start_date: null,
			resolution_date: null,
			insert_position: 1,
			is_archive: false,
			annotation_note: null,
			showAnnotation: false,
			revision_count: 0,
		},
		form: {},
		validationRules: {
			form: {
				note: {
					required: helpers.withMessage("Please Enter Note.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const risk_factors = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			risk_factor_name:null,
			note:null,
			start_date:null,
			resolution_date:null,
			age_at_onset:null,
			exposure_details:null,
			life_stage:null
		},
		form: {},
		validationRules: {
			form: {
				note: {
					required: helpers.withMessage("Please enter note.", required),
				},
				risk_factor_name:{
					required: helpers.withMessage("Please enter risk factoer name.", required),
				},
				start_date:{
					required: helpers.withMessage("Please select start date.", required),
				}
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const family_history = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			note: null,
			start_date: null,
			resolution_date: null,
			age_at_onset: null,
			treatment: null,
			relationship: null,
			life_stage: null,
			problem_diagnosis_procedure_description: null,
			diagnosis_code_system: null,
			diagnosis_code:null,
			diagnosis_code_description:null,
		},
		form: {},
		validationRules: {
			form: {
				start_date: {
					required: helpers.withMessage("Please Select Start Date.", required),
				},
				relationship: {
					required: helpers.withMessage("Please Select Any Relationship.", required),
				},
				note: {
					required: helpers.withMessage("Please Enter Note.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const prevention = {
	namespaced: true,

	state: {
		defaultFormData: {
			prevention_id:null,
			code:null,
			brand_name:null,
			type:null,
			date:moment().format('YYYY-MM-DD'),
			status:'Completed',
			isRefused:0,
			manufacturer:null,
			lotNumber:null,
			route:null,
			site:null,
			dose:null,
			instructions:null,
			notes:null,
			keyword:null,
			advanceFilter:null,
		},
		form: {
			prevention_id:null,
			code:null,
			brand_name:null,
			type:null,
			date:null,
			status:null,
			isRefused:null,
			manufacturer:null,
			lotNumber:null,
			route:null,
			site:null,
			dose:null,
			instructions:null,
			notes:null,
			keyword:null,
			advanceFilter:null,
		},
		validationRules: {
			form: {
				date: {
					required: helpers.withMessage("Please select date.", required),
				},
				type:{
					required: helpers.withMessage("Please select vaccine.", required),
				},
				brand_name:{
					required: helpers.withMessage("Please enter branch name.", required),
				},
				lotNumber:{
					required: helpers.withMessage("Please enter lot.", required),
				},
				dose:{
					required: helpers.withMessage("Please enter dose.", required),
				}
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const task = {
	namespaced: true,

	state: {
		listUrl: "/patient/task/list",
		header: [
			{ "patient_name": "Demographic Name" },
			{ "creator": "Creator" },
			{ "service_date": "Service Date" },
			{ "created_datetime": "Creation Date" },
			{ "priority": "Priority" },
			{ "assigned_to": "Task Assigned To" },
			{ "status": "Status" },
			{ "reminder_message": "Message" },
			{ "status": "Action" },
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
			id: null,
			comments: null,
			assigned_to: null,
			status: null,
		},
		form: {},
		validationRules: {
			form: {
				service_date: {
					required: helpers.withMessage("Please select service date.", required),
				},
				priority:{
					required: helpers.withMessage("Please select priority.", required),
				},
				reminder_message:{
					required: helpers.withMessage("Please enter reminder message.", required),
				},
				assigned_to:{
					required: helpers.withMessage("Please select task assigned to.", required),
				},
				patient_id:{
					required: helpers.withMessage("Please select patient.", required),
				}
			},
		},
		validationRulesEdit: {
			form: {
				service_date: {
					required: helpers.withMessage("Please select service date.", required),
				},
				priority:{
					required: helpers.withMessage("Please select priority.", required),
				},
				assigned_to:{
					required: helpers.withMessage("Please select task assigned to.", required),
				},
				status:{
					required: helpers.withMessage("Please select status.", required),
				},
				message:{
					required: helpers.withMessage("Please enter message.", required),
				}
			},
		},
		validationRulesNote:{
			reminder_message:{
				required: helpers.withMessage("Please enter reminder message.", required),
			},
		},
		vuelidateExternalResults: {
			form: {
				service_date: '',
				last_name: '',
				gender: '',
				status: ''
			}
		},
		filter:{
			'status':'',
			'assigned_to':'',
			'created_user':'',
			'priority':'',
			'patient_name':'',
			'patient_id':''
		}
	},

	getters: {},
	mutations: {},
	actions: {},
};

const patientVital = {
	namespaced: true,

	state: {
		listUrl: "/patient/vital/get-history-by-type",
		header: [
			{ "patient_name": "Type" },
			// { "creator": "Provider" },
			{ "service_date": "Measuring Instruction" },
			{ "created_datetime": "Data" },
			{ "priority": "Comments" },
			{ "assigned_to": "Observation Date" },
			{ "status": "Date Entered	" },
			{ "status": "Action" },
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {

		},
		form: {
			vital_type:'',
			value:'',
			selected_unit:'',
			comment:'',
			id:''
		},
		validationRules: {
			form: {
				
			},
		},
		vuelidateExternalResults: {
			form: {
			}
		},
		filter:{
		}
	},

	getters: {},
	mutations: {},
	actions: {},
};

const patient_set = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			set_name: null,
		},
		form: {},
		validationRules: {
			form: {
				set_name:{
					required: helpers.withMessage("Please enter patient set name.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const patient_document = {
	namespaced: true,

	state: {
		listUrl: "/patient/document/list",
		header: [
			{ "description": "Document Description" },
			{ "creator": "Content" },
			{ "service_date": "Type" },
			{ "created_datetime": "Creator" },
			{ "priority": "Responsible" },
			{ "assigned_to": "Date" },
			{ "status": "Reviewer" },
			{ "status": "Action" },
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
			id: null,
			comments: null,
			provider_ids: null,
			status: null,
			document_type_id:null
		},
		form: {
			tracking_request:"Select Link with Tracing Requests",
			document_type_id:null
		},
		validationRules: {
			form: {
				// description: {
				// 	required: helpers.withMessage("Please enter document title.", required),
				// },
				// observation_date:{
				// 	required: helpers.withMessage("Please select observation date.", required),
				// },
				document_type_id:{
					required: helpers.withMessage("Please select document type.", required),
				},
				// file_path:{
				// 	required: helpers.withMessage("Please select document file.", required),
				// },
				// received_datetime:{
				// 	required: helpers.withMessage("Please select receive datetime.", required),
				// }
			},
			mailForm:{
				name:{
					required: helpers.withMessage("Please enter name.", required),
				},
				address:{
					required: helpers.withMessage("Please enter address.", required),
				},
				city:{
					required: helpers.withMessage("Please enter city.", required),
				},
				state:{
					required: helpers.withMessage("Please enter state.", required),
				},
				zip:{
					required: helpers.withMessage("Please enter zip.", required),
				}
			},
			faxForm:{
			},
			emailForm:{
				email_header:{
					required: helpers.withMessage("Please enter email header.", required),
				},
				email_id:{
					required: helpers.withMessage("Please enter email id.", required),
					email: helpers.withMessage("Please enter valid email", email),
				}
			}
		},
		filter:{
			'observation_date':'',
		},
		faxForm:{
			specialist_id:null,
			fax_number:null
		},
		emailForm:{
			email_id:null,
			email_header:null
		},
		mailForm:{
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null
		},
		defaultexportfrm:{
			specialist_id:null,
			fax_number:null,
			email_id:null,
			email_header:null,
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null

		}
	},

	getters: {},
	mutations: {},
	actions: {},
};

const encounter_note = {
	namespaced: true,

	state: {
		defaultFormData: {
			encounter_datetime: new Date(),
			note: '['+new Date().toISOString().slice(0, 10)+' '+new Date().toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', second:'2-digit' })+': Tel-Progress Note]',
			encounter_type:''
		},
		form: {
			encounter_datetime: new Date(),
			note: '['+new Date().toISOString().slice(0, 10)+' '+new Date().toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', second:'2-digit' })+': Tel-Progress Note]',
			encounter_editors:localStorage.getItem('authUserName'),
			encounter_type:''
		},
		validationRules: {
			form: {
				note:{
					required: helpers.withMessage("Please enter encounter note.", required),
				},
				encounter_datetime:{
					required: helpers.withMessage("Please select encounter date.", required),
				},
				encounter_type:{
					required: helpers.withMessage("Please select encounter type.", required),
				}
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const alt_contact = {
	namespaced: true,

	state: {
		listUrl: "/patient/alternative-contact/list",
		header: [
			{ "first_name": "First Name" },
			{ "last_name": "Last Name" },
			{ "email": "Email" },
			{ "status": "Action" },
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
			relationship: null,
			first_name: null,
			last_name: null,
			contact_purpose: null,
			residence_phone: null,
			cell_phone:null,
			work_phone:null,
			work_phone_ext:null,
			email:null,
			contact_note:null
		},
		form: {
			relationship: null,
			first_name: null,
			last_name: null,
			contact_purpose: null,
			residence_phone: null,
			cell_phone:null,
			work_phone:null,
			work_phone_ext:null,
			email:null,
			contact_note:null
		},
		validationRules: {
			form: {
				relationship: {
					required: helpers.withMessage("Please select contact reltionship.", required),
				},
				first_name:{
					required: helpers.withMessage("Please enter contact first name.", required),
				},
				last_name:{
					required: helpers.withMessage("Please enter contact last name.", required),
				},
				contact_purpose:{
					required: helpers.withMessage("Please select contact purpose.", required),
				},
				cell_phone:{
					required: helpers.withMessage("Please enter cell phone.", required),
				},
				email:{
					email: helpers.withMessage("Please enter valid email.", email),
				}
			},
		},
		filter:{
			'observation_date':'',
		},
		faxFrm:{
			provider_id:'',
			fax_no:''
		}
	},

	getters: {},
	mutations: {},
	actions: {},
};

const enrollment_history = {
	namespaced: true,

	state: {
		listUrl: "/patient/provider-status/list",
		header: [
			{ "provider_name": "Provider" },
			{ "enrolment_start_date": "Enrollment Start Date" },
			{ "enrolment_termination_date": "Enrollment Termination Date" },
			{ "enrolment_status": "Enrollment Status" },
			{"action":"Action"},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
			patient_id:null,
			provider_id:null,
			enrolment_status:null,
			enrolment_start_date:null,
			enrolment_termination_date:null,
			termination_reason:null,
		},
		form: {
			patient_id:null,
			provider_id:null,
			enrolment_status:null,
			enrolment_start_date:null,
			enrolment_termination_date:null,
			termination_reason:null,
		},
		validationRules: {
			form: {
				patient_id:{
					required: helpers.withMessage("Please select patient.", required),
				},
				provider_id:{
					required: helpers.withMessage("Please select provider.", required),
				},
				enrolment_status:{
					required: helpers.withMessage("Please select enrollment status.", required),
				},
				enrolment_start_date:{
					required: helpers.withMessage("Please select enrollment start date.", required),
				},
			},
		},
		filter:{
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const screening= {
	namespaced: true,

	state: {
		defaultFormData: {
			patient_id:null,
			screening_type:null,
			start_date:moment().format('YYYY-MM-DD'),
			next_date:null,
			result:'Normal',
			comments:null,
			instructions:null,
			isRefused:0,
			keyword:null,
			advanceFilter:null,
		},
		form: {
			patient_id:null,
			screening_type:null,
			start_date:null,
			next_date:null,
			result:null,
			comments:null,
			instructions:null,
			isRefused:null,
			keyword:null,
			advanceFilter:null,
		},
		validationRules: {
			form: {
				screening_type:{
					required: helpers.withMessage("Please enter screening type.", required),
				},

			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const lab = {
	namespaced: true,
	state: {
		faxForm:{
		},
		emailForm:{
			email_id:null,
			email_header:null
		},
		mailForm:{
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null
		},
		validationRules: {
			mailForm:{
				name:{
					required: helpers.withMessage("Please enter name.", required),
				},
				address:{
					required: helpers.withMessage("Please enter address.", required),
				},
				city:{
					required: helpers.withMessage("Please enter city.", required),
				},
				state:{
					required: helpers.withMessage("Please enter state.", required),
				},
				zip:{
					required: helpers.withMessage("Please enter zip.", required),
				}
			},
			faxForm:{

			},
			emailForm:{
				email_header:{
					required: helpers.withMessage("Please enter email header.", required),
				},
				email_id:{
					required: helpers.withMessage("Please enter email id.", required),
					email: helpers.withMessage("Please enter valid email", email),
				}
			}
		}
	}
};

const imaging = {
	namespaced: true,
	state: {
		faxForm:{
			specialist_id:null,
			fax_number:null
		},
		emailForm:{
			email_id:null,
			email_header:null
		},
		mailForm:{
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null
		},
		validationRules: {
			mailForm:{
				name:{
					required: helpers.withMessage("Please enter name.", required),
				},
				address:{
					required: helpers.withMessage("Please enter address.", required),
				},
				city:{
					required: helpers.withMessage("Please enter city.", required),
				},
				state:{
					required: helpers.withMessage("Please enter state.", required),
				},
				zip:{
					required: helpers.withMessage("Please enter zip.", required),
				}
			},
			faxForm:{
			},
			emailForm:{
				email_header:{
					required: helpers.withMessage("Please enter email header.", required),
				},
				email_id:{
					required: helpers.withMessage("Please enter email id.", required),
					email: helpers.withMessage("Please enter valid email", email),
				}
			}
		}
	}
};


const disease_reg = {
	namespaced: true,
	state: {
		defaultFormData: {
		},
		form:{

		},
		validationRules: {
			form: {
				notes: {
					required: helpers.withMessage("Please enter notes.", required),
				},
				diagnosis:{
					required: helpers.withMessage("Please enter diagnosis.", required),
				},
				start_date:{
					required: helpers.withMessage("Please select start date.", required),
				}
			},
		}
	}
};

export { social_history, medical_history, ongoing_concerns, reminders, other_med, risk_factors, family_history, prevention, task, patientVital, patient_set, patient_document, encounter_note, alt_contact,enrollment_history, screening, lab, imaging, disease_reg };