<template>
	<div class="blig-pdf-wdt">
		<header class="brand-top-section position-relative">
			<div class="d-inline-block">
				<div class="lft-logopart" style="margin-top:6px;">
					<img src="/images/e-chart-transfer.svg" class="img-fluid e-chart-t-toplogo" alt="Billing Receipt">
				</div>
				<div class="rgt-loct-part">
					<div class="text-end">
						<p class="mb-0 mdcal-cntr">MAJOR MACKENZIE MEDICAL CENTRE</p>
						<p class="mb-0 cmpn-add">3420 MAJOR MACKENZIE DR WEST, UNIT 101/102,<br>
							WOODBRDIGE, ONTARIO  L4H 4J6<br>
							<span class="fw-600">TEL</span> 905-222-2222  <span class="fw-600 ms-2">FAX</span> 905-222-2222</p>
						</div>
					</div>
				</div>
			</header>
			<div class="divider-line"></div>
			<div class="position-relative">
				<div class="main-lft-brd">
					<img src="/images/polygon-2.svg" class="dots-polygon-01">
					<div class="billing-dtls" style="padding-top: 30px;">
						<p class="bill-to-nm mb-0">Basic Info <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
					</div>
					<div class="d-inline-block bsc-leftpart float-start">
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">patient</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-700">JORDAN LIU </p>
								<p class="mb-0 fw-300">1111-A Yonge Street
									<br>
									Toronto, Ontario M7A 8A8<br>
								</p>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">gender</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300">Male</p>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">date of birth</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300">1999-01-01</p>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">hin</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300">3067830678  BB  (ON)</p>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">cell phone</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300">647-308-6700</p>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">home phone</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300"></p>
							</div>
						</div>
						<div>
							<div class="work-pn-lft">
								<div class="ptnt-blig-detls">
									<p class="basic-ttl mb-0">work phone</p>
									<div class="loct-pt-rg">
										<p class="mb-0 fw-300"></p>
									</div>
								</div>
							</div>
							<div class="ext-pn-rgt">
								<div class="ptnt-blig-detls m-0">
									<p class="basic-ttl mb-0" style="width: 20px;padding-right: 0;margin-right: 6px;">ext.</p>
									<div class="loct-pt-rg">
										<p class="mb-0 fw-300"></p>
									</div>
								</div>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">email</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300">welcome@verismohealth.com</p>
							</div>
						</div>
					</div>
					<div class="d-inline-block bsc-rightpart">
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">alternative contact 1</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-700">WANG ALEXANDER</p>
								<p class="mb-0 fw-300">Substitute Decision Maker <br> Mother</p>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">contact 1 cell phone</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300">647-777-7777</p>
							</div>
						</div>
						<div>
							<div class="work-pn-lft">
								<div class="ptnt-blig-detls">
									<p class="basic-ttl mb-0">contact 1 work phone</p>
									<div class="loct-pt-rg">
										<p class="mb-0 fw-300"></p>
									</div>
								</div>
							</div>
							<div class="ext-pn-rgt">
								<div class="ptnt-blig-detls m-0">
									<p class="basic-ttl mb-0" style="width: 20px;padding-right: 0;margin-right: 6px;">ext.</p>
									<div class="loct-pt-rg">
										<p class="mb-0 fw-300"></p>
									</div>
								</div>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">contact 1 home phone</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300"></p>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">contact 1 email</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300"></p>
							</div>
						</div>
						<div class="ptnt-blig-detls" style="margin-top: 20px;margin-bottom: 30px;">
							<p class="basic-ttl mb-0">alternative contact 2</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300"></p>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">contact 2 cell phone</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300"></p>
							</div>
						</div>
						<div>
							<div class="work-pn-lft">
								<div class="ptnt-blig-detls">
									<p class="basic-ttl mb-0">contact 2 work phone</p>
									<div class="loct-pt-rg">
										<p class="mb-0 fw-300"></p>
									</div>
								</div>
							</div>
							<div class="ext-pn-rgt">
								<div class="ptnt-blig-detls m-0">
									<p class="basic-ttl mb-0" style="width: 20px;padding-right: 0;margin-right: 6px;">ext.</p>
									<div class="loct-pt-rg">
										<p class="mb-0 fw-300"></p>
									</div>
								</div>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">contact 2 home phone</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300"></p>
							</div>
						</div>
						<div class="ptnt-blig-detls">
							<p class="basic-ttl mb-0">contact 2 email</p>
							<div class="loct-pt-rg">
								<p class="mb-0 fw-300"></p>
							</div>
						</div>
					</div>
					<div class="sevs-ttl mt-0">
						<p class="bill-to-nm mb-0">encounter notes <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
					</div>
					<div class="encuntr-nts-table">
						<table class="table">
							<thead>
								<tr>
									<th style="width:78px;">date</th>
									<th style="width: 135px;">provider</th>
									<th>encounter notes</th>
								</tr>
							</thead>
							<tbody style="height:auto;">
								<tr>
									<td>2024-01-01</td>
									<td>HONG, JAMES</td>
									<td>S: Mother here with son as interpreter, all poor historians, pt has no gp or peds<br>
										O: looks well. bright eyes, respond to commands, a bit hyperactive<br>
										A: concerns with speech, no peds<br>
									P: child appears bright, no dev concerns today, referral to ENT for eharing and peds</td>
								</tr>
								<tr>
									<td>2024-01-01</td>
									<td>HONG, JAMES</td>
									<td>S: Mother here with son as interpreter, all poor historians, pt has no gp or peds<br>
										O: looks well. bright eyes, respond to commands, a bit hyperactive<br>
										A: concerns with speech, no peds<br>
									P: child appears bright, no dev concerns today, referral to ENT for eharing and peds</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="" style="position: absolute;bottom: 40px;right: 4px;">
				<div class="position-relative" style="">
					<div class="and-brd-w"></div>
					<div class="and-brd-h"></div>
					<img src="/images/polygon-2.svg" class="dots-polygon">
				</div>
			</div>
			<div class="docttypesect text-end" style="">
				<p class="mb-0 doct-gnrt-tag">Document Generated By |</p>
				<img src="/images/black-font-Logo.svg" class="img-fluid">
			</div>
		</div>

		<div style="page-break-before:always">&nbsp;</div> 

		<div class="blig-pdf-wdt">
			<header class="brand-top-section position-relative">
				<div class="d-inline-block">
					<div class="lft-logopart" style="margin-top:6px;">
						<img src="/images/e-chart-transfer.svg" class="img-fluid e-chart-t-toplogo" alt="Billing Receipt">
					</div>
					<div class="rgt-loct-part">
						<div class="text-end">
							<p class="mb-0 mdcal-cntr">MAJOR MACKENZIE MEDICAL CENTRE</p>
							<p class="mb-0 cmpn-add">3420 MAJOR MACKENZIE DR WEST, UNIT 101/102,<br>
								WOODBRDIGE, ONTARIO  L4H 4J6<br>
								<span class="fw-600">TEL</span> 905-222-2222  <span class="fw-600 ms-2">FAX</span> 905-222-2222</p>
							</div>
						</div>
					</div>
				</header>
				<div class="divider-line"></div>
				<div class="position-relative">
					<div class="main-lft-brd">
						<img src="/images/polygon-2.svg" class="dots-polygon-01">
						<div class="billing-dtls" style="padding-top: 30px;">
							<p class="bill-to-nm mb-0">measurements <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
						</div>
						<div class="d-inline-block w-100">
							<div class="sevs-tablelist" style="font-size: 8px;">
								<table class="table">
									<thead>
										<tr>
											<th style="width:168px;">metric</th>
											<th style="width:180px;">recorded date</th>
											<th class="text-end" style="width:120px;">values</th>
											<th class="text-end" style="width:165px;">unit</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Height</td>
											<td>2024-01-01</td>
											<td class="text-end">173</td>
											<td class="text-end">cm</td>
										</tr>
										<tr>
											<td>Weight</td>
											<td>2024-01-01</td>
											<td class="text-end">85</td>
											<td class="text-end">kg</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="sevs-ttl mt-0">
							<p class="bill-to-nm mb-0">medications <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
						</div>
						<div class="d-inline-block w-100">
							<div class="sevs-tablelist" style="font-size: 8px;">
								<table class="table">
									<thead>
										<tr>
											<th style="width:88px;">start date</th>
											<th style="width:209px;">generic name</th>
											<th style="width:227px;">brand name</th>
											<th class="text-end"  style="width:88px;">provider</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>2024-01-01</td>
											<td>CEPHALEXIN</td>
											<td>CEPHALEXIN 500 MG</td>
											<td class="text-end">HONG JAMES</td>
										</tr>
										<tr>
											<td>2024-01-01</td>
											<td>BETA-CAROTENE 3000.0 UNIT / VITAMIN A (VITAMIN A ACETATE) 2000 DOSAGE</td>
											<td>18 ESSENTIAL VITAMINS AND MINERALS</td>
											<td class="text-end">HONG JAMES</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div class="sevs-ttl mt-0">
							<p class="bill-to-nm mb-0">preventions <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
						</div>
						<div class="d-inline-block w-100">
							<div class="sevs-tablelist" style="font-size: 8px;">
								<table class="table">
									<thead>
										<tr>
											<th style="width:88px;">date</th>
											<th style="width:168px;">vaccine name</th>
											<th style="width:180px;">brand name</th>
											<th class="text-end"  style="width:60px;">dose</th>
											<th class="text-end"  style="width:76px;">route</th>
											<th class="text-end"  style="width:70px;">provider</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>2024-01-01</td>
											<td>COVID-19 mRNA Vaccine</td>
											<td>Medigen</td>
											<td class="text-end">1</td>
											<td class="text-end">IM</td>
											<td class="text-end">HONG JAMES</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div class="sevs-ttl mt-0">
							<p class="bill-to-nm mb-0">screenings <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
						</div>
						<div class="d-inline-block w-100">
							<div class="sevs-tablelist" style="font-size: 8px;">
								<table class="table">
									<thead>
										<tr>
											<th style="width:88px;">date</th>
											<th style="width:168px;">screening type</th>
											<th class="text-end" style="width:200px;">status</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>2024-01-01</td>
											<td>Diabetes Screening</td>
											<td class="text-end">Pending</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>


						<div class="sevs-ttl mt-0">
							<p class="bill-to-nm mb-0">other infos <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
						</div>
						<div class="d-inline-block w-100">
							<div class="sevs-tablelist" style="font-size: 8px;">
								<table class="table">
									<thead>
										<tr>
											<th style="width:110px;">module</th>
											<th style="width:124px;">start date</th>
											<th>notes / problem description / diagnosis</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Medical History</td>
											<td>2023-12-11</td>
											<td>ER for hand injury from car accident</td>
										</tr>
										<tr>
											<td>Family History</td>
											<td>2022-08-11</td>
											<td>grandmother | died of Type II diabetes (mother side)</td>
										</tr>
										<tr>
											<td>Family History</td>
											<td>2022-08-11</td>
											<td>grandfather | high blood pressure</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div class="sevs-ttl mt-0">
							<p class="bill-to-nm mb-0">files <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
						</div>
						<p class="fw-600" style="font-size:8px;margin-left: 30px;">all of this patient’s files are attached at the back of this report.</p>
						
					</div>
				</div>
				<div class="" style="position: absolute;bottom: 40px;right: 4px;">
					<div class="position-relative" style="">
						<div class="and-brd-w"></div>
						<div class="and-brd-h"></div>
						<img src="/images/polygon-2.svg" class="dots-polygon">
					</div>
				</div>
				<div class="docttypesect text-end" style="">
					<p class="mb-0 doct-gnrt-tag">Document Generated By |</p>
					<img src="/images/black-font-Logo.svg" class="img-fluid">
				</div>
			</div>
		</template>

		<script>

			export default ({
				data(){
					return {
						printPreview() {
							window.print();
						},
					}
				},
				methods:{
				},
				mounted(){
					setTimeout(() => {
						this.printPreview();
					}, 200);
				}
			});
		</script>