<template>
	<div class="wrapper">
		<div class="page-loader" v-show="$store.state.loader">
				<img src="images/loader.svg" />
				<span class="load_txt">Loading ...</span>
		</div>

		<Navbar v-if="isLogin && isNavbarVisible && !isAdmin"></Navbar>

		<!-- <AdminNavbar v-if="isLogin && isAdmin"></AdminNavbar> -->
		<!-- <div class="container-fluid"> -->
			<!-- Router view for main content layout -->
			<router-view :key="$route.fullPath" />
			<!-- <vue-progress-bar></vue-progress-bar> -->
		<!-- </div> -->

		<!-- <ChatWidget v-if="isChatWidgetShow" /> -->
	</div>
</template>

<script>
import Navbar from './views/Navbar.vue';
// import ChatWidget from "./components/chat/chatWidget.vue";
// import AdminNavbar from './views/AdminNavbar.vue';

export default {	
	name: 'App',
	components: {
		Navbar,
		// ChatWidget,
		// AdminNavbar,
	},
	computed: {
		isLogin() {
			return localStorage.getItem("authToken") ? true : false;
		},
		isNavbarVisible() {
			const myArray = ['PatientEChart', 'PatientAnnotationList', 'PatientRevisionList', 'PatientAllergies', 'PatientAnnotationForm', 'PatientChart', 'PatientAppointmentHistory', 'PatientEdit', 'PatientEnrollmentHistory', 'PatientCreate'];
			if (myArray.includes(this.$route.name)) {
				return false;
			} else {
				return true;
			}
		},
		isChatWidgetShow() {
			if (this.isLogin) {
				const myArray = ['Chat'];
				if (myArray.includes(this.$route.name)) {
					return false;
				} else {
					return true;
				}
			}
			return false;

		},
		isAdmin() {
			if (this.$route.fullPath.includes('/administration')) {
				return true
			}
			return false;
		},
	},
	mounted() {
		this.$socket.setMessageCallback(this.handleReceivedData);
	},
	methods:{
		handleReceivedData(socketData) {
			this.$store.dispatch('updateSocketData', socketData);
		},
	}
}
</script>
<style>
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
</style>