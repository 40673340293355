<template>
	<div class="content">
		<div class="container-full">
			<component :is="$store.state.component">

			</component>
		</div>
	</div>
</template>
<script>
import AdminUserList from '../../components/administrator/user/list.vue';
import CreateAdminUser from '../../components/administrator/user/form.vue';
import EditAdminUser from '../../components/administrator/user/edit.vue';
import EditAdminUserPermission from '../../components/administrator/user/permission.vue';

export default {
	components: {
		AdminUserList,
        CreateAdminUser,
        EditAdminUser,
		EditAdminUserPermission
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
}
</script>