<template>
	<div class="content">
		<div class="container-full">
			<component :is="$store.state.component">

			</component>
		</div>
	</div>
</template>
<script>
import AdminBillingAccSettings from '../../components/administrator/billing/account-settings.vue';
import AdminBillingCodeList from '../../components/administrator/billing/billing-code.vue';
import CreateAdminBillingCode from '../../components/administrator/billing/billing-code-form.vue'
import EditAdminBillingCode from '../../components/administrator/billing/billing-code-form.vue'

export default {
	components: {
		AdminBillingAccSettings,
		AdminBillingCodeList,
		CreateAdminBillingCode,
		EditAdminBillingCode
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
}
</script>