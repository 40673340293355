<template>
  <div class="form-group row" :class="{ error: errors.length }">
    <label :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="asterisksign"
        >*</small
      ></label
    >
    <div :class="divClass">
      <div class="controls">
        <input
          id="file"
          type="file"
          name="file"
          class="form-control"
          required
          @change="$emit('update:modelValue', $event.target.files[0])"
        />
        <span class="form-text text-muted" v-if="notes != ''"><div v-html="notes"></div></span>
      </div>
      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
          <li class="d-block" v-if="coustom_error">
            {{coustom_error}}
          </li>
        </ul>
      </div>
    </div>

    <div :class="noteClass">
      <span class="form-text text-muted align-sub l-h-n">{{ note }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: File,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    labelClass: {
      type: String,
      default:
        "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end",
    },
    note: {
      type: String,
      default: "",
    },
    noteClass: {
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3",
    },
    divClass: {
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3",
    },
    notes: {
      type: String,
      default: "",
    },
    coustom_error:{
      type: String,
      default: "",
    }
  },
  methods: {
    // onFileChange(e) {
    //   const files = e.target.files || e.dataTransfer.files;
    //   if (!files.length) return;
    //   this.createImage(files[0]);
    // },
    // createImage(file) {
    //   let image = new Image();
    //   let reader = new FileReader();
    //   let self = this;
    //   reader.onload = (e) => {
    //     self.image = e.target.result;
    //     self.$emit("update:modelValue", self.image);
    //   };
    //   reader.readAsDataURL(file);
    // },
    // removeImage: function(e) {
    //   self.$emit("update:modelValue", "");
    // },
  },
};
</script>
