
<template>
<!-- row colom Start -->
     <div class="consult_add_page">
          <div class="row">
               <div class="col-lg-6"></div>
               <div class="col-lg-5">
                    <div class="text-end">
                         <div class="status-btn"><span>Status</span>   
                              <slot v-if="patient_detail.status == 'AC'">Active</slot>
                              <slot v-else-if="patient_detail.status == 'IN'">Inactive</slot>
                              <slot v-else-if="patient_detail.status == 'DE'">Deceased</slot>
                         </div>
                    </div>
               </div>
          </div>
          <div class="row patient_chart justify-content-center edit_record_page">
             <!-- col-lg-7 colom start -->
                <div class="col-lg-6 ">
                     <h4 class="box-title mt-0">Referral Basic Information</h4>
                     <div class="box-body containt_detail">
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Referring Practitioner</label>
                             <div class="col-sm-8">
                                   <CustomDropDown :options="mrpProviderDataSource"
                                        :initialValue="this.physician_text" v-model="form.referring_practitioner_id"
                                        @item-selected="handleItemSelected" fieldName="referring_practitioner_id">
                                   </CustomDropDown>
                              </div>
                         </div>
                         <ValidationErrorMessageList :errors="v$.form.referring_practitioner_id.$errors" />

                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Referral Date</label>
                             <div class="col-sm-8">
                                 <span class="two_inputs">
                                        <div class="calendar">
                                             <ejs-datepicker class="e-field form-control" @change="handleReferralDate"
                                                  :maxDate="currentDate" v-model="form.referral_date"
                                                  :showClearButton="false" format="yyyy-MM-dd"></ejs-datepicker>
                                        </div>
                                        <aside class="mute_text ms-3 fw-normal">(YYYY-MM-DD)</aside>
                                   </span>
                             </div>
                         </div>
                         <ValidationErrorMessageList :errors="v$.form.referral_date.$errors" />
     
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Service</label>
                              <div class="col-sm-8">
                                   <CustomDropDown :options="serviceList" v-model="form.service_id" @item-selected="handleItemSelected" fieldName="service_id"></CustomDropDown>
                              </div>
                         </div>
                         <ValidationErrorMessageList :errors="v$.form.service_id.$errors" />

                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Specialist</label>
                              <div class="col-sm-8">
                                   <CustomDropDown :options="specialistsList" v-model="form.specialist_id" @item-selected="handleItemSelected" fieldName="specialist_id"></CustomDropDown>
                              </div>
                         </div>
                         <ValidationErrorMessageList :errors="v$.form.specialist_id.$errors" />

                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Urgency</label>
                              <div class="col-sm-8">
                                   <CustomDropDown :options="urgencyList" v-model="form.urgency" @item-selected="handleItemSelected" fieldName="urgency"></CustomDropDown>
                              </div>
                         </div>
                         <ValidationErrorMessageList :errors="v$.form.urgency.$errors" />

                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Phone</label>
                              <div class="col-sm-8"><input type="number" class="form-control" v-model="form.phone"></div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Fax</label>
                              <div class="col-sm-8"><input type="text" class="form-control" v-model="form.fax"></div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Address</label>
                              <div class="col-sm-8"><input type="text" class="form-control" v-model="form.address"></div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">City</label>
                              <div class="col-sm-8"><input type="text" class="form-control" v-model="form.city"></div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Province</label>
                              <div class="col-sm-8"><input type="text" class="form-control" v-model="form.province"></div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Postal Code</label>
                              <div class="col-sm-8"><input type="text" class="form-control" v-model="form.zip"></div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Appointment Instructions</label>
                              <div class="col-sm-8"><input type="text" class="form-control" v-model="form.referrer_instructions"></div>
                         </div>
                         <div class="mb-0 row pd-bottom ">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Appointment Date</label>
                              <div class="col-sm-8">
                                   <span class="two_inputs">
                                        <div class="calendar">
                                             <ejs-datepicker class="e-field form-control" @change="handleAppointmentDate"
                                                  :maxDate="currentDate" v-model="form.appointment_date"
                                                  :showClearButton="false" format="yyyy-MM-dd"></ejs-datepicker>
                                        </div>
                                        <aside class="mute_text ms-3 fw-normal">(YYYY-MM-DD)</aside>
                                   </span>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Appointment Time</label>
                              <div class="col-sm-8">
                                   <span class="two_inputs">
                                        <div class="calendar">
                                             <ejs-timepicker class="e-field e-input form-control" :step="5" :showClearButton="false" :strictMode='Strict' v-model="form.appointment_time"></ejs-timepicker>
                                        </div>
                                   </span>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Reason for Consultation</label>
                              <div class="col-sm-8">
                                 <textarea class="form-control" id="exampleFormControlTextarea1" rows="12" v-model="form.reason_for_consultation" ></textarea>
                              </div>
                         </div>
                     </div>
                </div>
             <!-- col-lg-7 colom end -->
     
             <!-- col-lg-5 colom start -->
                <div class="col-lg-4">
                 <!-- Patient Information Start -->
                     <h4 class="box-title mt-0">Patient Information</h4>
                     <div class="box-body containt_detail">
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Patient Name</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">{{ patient_detail.last_name }} {{ patient_detail.first_name }}</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Gender</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">Male</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Date Of Birth</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">{{ patient_detail.dob }}</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Address</label>
                              <div class="col-sm-6">
                                 <div class="pi-info" v-html="patient_detail.full_address"></div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Cell Phone</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">{{ patient_detail.cell_phone }}</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Home Phone</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">{{ patient_detail.home_phone }}</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Work Phone</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">{{ patient_detail.work_phone }}</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Email</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">{{ patient_detail.email }}</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Health Card Info</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">{{ patient_detail.health_insurance_no }}  {{ patient_detail.health_card_ver }}  ({{ patient_detail.is_hcv_verified === 1 ? 'ON' : 'OFF' }})</div>
                              </div>
                         </div>
                    </div>
                    <!-- Patient Information End -->
                     <!-- Letterhead Start -->
                    <h4 class="box-title">Letterhead</h4>
                     <div class="box-body containt_detail">
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Letterhead Name</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">ABC Medical Walk-in Centre</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Letterhead Address</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">3420 Major Mackenzie Dr East, Unit 144/155 <br> Woodbridge, Ontario<br>L4H 4E4</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Letterhead Phone</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">647-777-7777</div>
                              </div>
                         </div>
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-6 col-form-label right-bor">Letterhead Fax</label>
                              <div class="col-sm-6">
                                 <div class="pi-info">905-777-7777</div>
                              </div>
                         </div>
                    </div>
             <!-- Letterhead end -->
     
     
             <!-- Signature Start -->
                    <h4 class="box-title">Signature</h4>
                    <img :src="this.sigImgBase64" alt="Base64 Image" class="e-control e-signature e-lib" height="60" width="429" v-if="this.sigImgBase64">
                    <canvas id="signature" class="e-control e-signature e-lib" role="img" aria-label="signature" tabindex="-1" height="60" width="429" v-else></canvas>

                </div>
             <!-- col-lg-5 colom end -->
         </div>
          <div class="row patient_chart justify-content-center edit_record_page align-items-end">
               <div class="col-lg-1"></div>
               <div class="col-lg-6">
                    <h4 class="box-title">Other Information</h4>
                     <div class="box-body containt_detail">
                         <div class="mb-0 row pd-bottom">
                              <label for="text" class="col-sm-4 col-form-label right-bor">Other Information</label>
                              <div class="col-sm-8">
                                 <textarea class="form-control h-232" id="exampleFormControlTextarea1" rows="11" v-model="form.other_information"></textarea>
                              </div>
                         </div>
                     </div>
               </div>
               <div class="col-lg-5">
                    <div class="text-center">
                         <button class="comman_brdr_btn big_btn"> Cancel </button>
                         <button class="comman_btn big_btn mx42" @click="save('save')"> Save <img src="images/loader.gif" v-if="savingloader" /></button>
                          <button class="comman_btn big_btn" @click="save('save_print')"> Save & Print / Fax <img src="images/loader.gif" v-if="savingPrintloader" /> </button>
                         <!-- <button class="comman_btn" @click="save('save_fax')">Save & Fax <img src="images/loader.gif" v-if="savingFaxloader" /></button> -->
                    </div>
               </div>
          </div>
     </div>
    
<!-- row colom end -->


</template>
<script>
import { DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import axios from "@/scripts/axios.js";
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';
import $ from "jquery";
// import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';

export default {
        data() {
            return {
               serviceList:[],
               specialistsList:[],
               patient_detail:[],
               gender_option: [
				{ value: "M", title: "Male" },
				{ value: "F", title: "Female" },
				{ value: "T", title: "Transgendered" },
				{ value: "O", title: "Other" },
				{ value: "U", title: "Undefined" }
			],
               urgencyList:[],
               mrpProviderDataSource: [],
               physician_text:'Select Primary Physician',
               savingloader:false,
               savingPrintloader:false,
               savingFaxloader:false,
               allCustomSpecialist:[],
               allergiesList:[],
               familyHistoryList:[],
               medicalHistoryList:[],
               socialHistoryList:null,
               otherInformation:'',
               sigImgBase64:''
            }
        },
        setup: () => {
		return { v$: useVuelidate() };
        },
        validations() {
          return {
                form: this.$store.state.consultations.validationRules.form,
          }
        },
        components: {
            'ejs-datepicker': DatePickerComponent,
            CustomDropDown,
          //   "ejs-button": ButtonComponent,
            'ejs-timepicker': TimePickerComponent,
            ValidationErrorMessageList
        },
        methods:{
          getConsultationsServices(){
                axios.post("consultations-services/list")
				.then((response) => {
					this.serviceList = response.data.data.map((item) => {
						return { value: item.id, title: item.name };
					});
				});
          },
          fetchPatientDetail() {
			axios.post("patient/detail", { 'id': this.$route.params.patient_id })
			.then((response) => {
				if (response.data.status === 200) {
					var detail = response.data.data
                         detail.gender_text = (this.gender_option.find(item => item.value === detail.gender) || {}).title || "Select Gender";
                         let fullAddArr = []
                         if (detail.address) { fullAddArr.push(detail.address)}
                         if (detail.city) { fullAddArr.push(detail.city)}
                         if (detail.state) { fullAddArr.push(detail.state)}
                         if (detail.zip) { fullAddArr.push(detail.zip)}
                         detail.full_address = fullAddArr.join('<br/> ')
					this.patient_detail = detail
				} else {
					this.$filters.moshaToast(response.data.message, "error")
				}
			})
			.catch(error => {
				this.$filters.moshaToast(error.message, "error")
			});
		},
          getUrgencyList() {
               axios.post("consultations-urgency/list")
				.then((response) => {
					this.urgencyList = response.data.data.map((item) => {
						return { value: item.name, title: item.name };
					});
				});
          },
          async fetchProviderData() {
               var $this = this
               axios.post("json_list/provider", { roleId: 2 })
                    .then((response) => {
                         const providerTempData = [];
                         const details = response.data.data;
                         $.each(details, function (key, detail) {
                              if (!($this.$route.name == "PatientEChartConsultAdd" && detail.deleted_datetime)) {
                                   providerTempData.push({
                                        value: detail.user_id,
                                        title: detail.full_name,
                                   })
                              }
                         })
                         this.mrpProviderDataSource = providerTempData

               })
          },
          handleItemSelected({ fieldName, item }) {
               this.form[fieldName] = item.value;
               if (fieldName == 'referring_practitioner_id') {
                    this.physician_text = item.title
                    this.fetchUserDetail()
               }

               if (fieldName == 'service_id'){
                    axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
                    .then((response) => {
                         if (response.status == 200) {
                              const details = response.data.data;
                              const specialistTempData = [];
                              $.each(details, function (key, detail) {
                                   detail.value = detail.id
                                   detail.title = detail.name
                                   specialistTempData.push(detail)
                              })
                              this.specialistsList = specialistTempData;
                         } else {
                              this.$filters.moshaToast(response.data.message, "error");
                         }
                    }).catch(error => {
                         this.loader = false;
                         console.log(error)
                         if (error.response.status === 422) {
                              this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                         } else {
                              this.$filters.moshaToast(error.response.data.message, "error");
                         }
                    });
               }
               if (fieldName == 'specialist_id'){
                    this.form.phone = (item.phone_ext || '') + (item.phone || '')
                    this.form.fax = item.fax
                    this.form.address = item.address
                    this.form.city = item.city
                    this.form.province = item.province
                    this.form.zip = item.zip
               }        
          },
          save(action='save'){
               let formName = 'form'
               this.v$[formName].$validate();
               if (!this.v$[formName].$error) {
                    this.changeLoaderStatus(action, true)
				this.form.patient_id = this.$route.params.patient_id;
				let url
				if (this.form.id != null) {
					url = "patient/consultations/update";
				} else {
					url = "patient/consultations/store";
				}

                    this.form.specialist_is_customized = 1
                    this.form.status = 'Appointment Booked'
                    this.form.include_fax_cover_page = 0
                    let $this = this

                    axios.post(url, this.form)
					.then((response) => {
                              let detail = response.data.data
                              $this.changeLoaderStatus(action, false)
                              if (action == 'save_print') {
                                   $this.viewPDF(detail.id)
                              }
                              setTimeout(function () {
                                   $this.$filters.moshaToast(response.data.message, "success")
                                   $this.v$.$reset();
                                   $this.$router.push(
                                        `/patient/e-chart/${$this.$route.params.patient_id}/consultation/consult-list`
                                   );
                              }, 400);
                    });

               } else {
                    console.log(Object.entries(this.v$[formName]))
                    for (const [key] of Object.entries(this.v$[formName])) {
                        if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                            const myEl = document.getElementById(key);
                            if (myEl) {
                                this.$smoothScroll({
                                    scrollTo: myEl,
                                });
                                break;
                            }
                        }
                    }
			}
          },
          handleReferralDate(){
               if (this.form.referral_date) {
                    this.form.referral_date = this.formatDate(this.form.referral_date);
               }
          },
          handleAppointmentDate() {
               if (this.form.appointment_date) {
                    this.form.appointment_date = this.formatDate(this.form.appointment_date);
               }
          },
          formatDate(date) {
               const year = date.getFullYear();
               const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month as it starts from 0
               const day = String(date.getDate()).padStart(2, '0');
               return `${year}-${month}-${day}`;
          },
          resetforms() {
			Object.assign(
				this.$store.state.consultations.form,
				this.$store.state.consultations.defaultFormData
			);
		},
          changeLoaderStatus(action, status){
               if (action == 'save') {
                    this.savingloader = status
               } else if (action == 'save_print'){
                    this.savingPrintloader = status
               } else if (action == 'save_fax'){
                    this.savingFaxloader = status
               }
          },
          viewPDF(id) {
			axios.post("patient/consultations/viewPDF", { id: id })
				.then((response) => {
                    axios.post("/get-asset-link", { filename: response.data.data })
                         .then((response) => {
                              window.open(response.data, '_blank').focus();
                         });
               });
		},
          async getList() {
               try {
                    const [allergiesResponse, socialHistoryResponse, medicalHistoryResponse, familyHistoryResponse, otherMedicationResponse] = await Promise.all([
                         axios.post("patient/allergies/list", { patient_id: this.$route.params.patient_id }),
                         axios.post("patient/social-history/list", { patient_id: this.$route.params.patient_id }),
                         axios.post("patient/medical-history/list", { patient_id: this.$route.params.patient_id }),
                         axios.post("patient/family-history/list", { patient_id: this.$route.params.patient_id }),
                         axios.post("patient/other-medication/list", { patient_id: this.$route.params.patient_id })
                    ]);

                    // Process allergies response
                    const allergiesList = allergiesResponse.data.data
                         .filter(item => item.status === "Active")
                         .map(item => item.description)
                         .filter(Boolean)
                         .join('\n');
                    this.allergiesList = allergiesList;
                    this.otherInformation += "Allergies: " + allergiesList;

                    const otherMedicationList = otherMedicationResponse.data.data
                    .filter(item => item.status === "Active")
                         .map(item => item.description)
                         .filter(Boolean)
                         .join('\n');
                    this.otherMedicationList = otherMedicationList;
                    this.otherInformation += "\n\nMedications: " + otherMedicationList;

                    // Process medical history response
                    const medicalHistoryList = medicalHistoryResponse.data.data
                         .map(item => item.note)
                         .filter(Boolean)
                         .join('\n');
                    this.medicalHistoryList = medicalHistoryList;
                    this.otherInformation += "\n\nMedical History: " + medicalHistoryList;


                    // Process social history response
                    const socialHistoryList = socialHistoryResponse.data.data
                         .map(item => item.note)
                         .filter(Boolean)
                         .join('\n');
                    this.socialHistoryList = socialHistoryList;
                    this.otherInformation += "\n\nSocial History: " + socialHistoryList;

                    // Process family history response
                    const familyHistoryList = familyHistoryResponse.data.data
                         .map(item => item.note)
                         .filter(Boolean)
                         .join('\n');
                    this.familyHistoryList = familyHistoryList;
                    this.otherInformation += "\n\nFamily History: " + familyHistoryList;

                    this.form.other_information = this.otherInformation;
               } catch (error) {
                    console.error("Error fetching data:", error);
               }
		},
          async fetchData() {
               await this.getList();
          },
          fetchUserDetail(){
               this.$store.state.loader = true;
               let url = "user/retrieve";
               axios.post(url, {id:this.form.referring_practitioner_id})
				.then((response) => {
					this.$store.state.loader = false;
                         if (response.status == 200) {
                              this.sigImgBase64 = response.data.data.base64_sign
                         }

                    }).catch(error => {
					this.$store.state.loader = false;
                         if (error.response.status === 422) {
                              this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                              console.log(Object.values(error.response.data.data).join(', '))
                         } else {
                              this.$filters.moshaToast(error.response.data.message, "error");
                         }
                    });
          }
        },
        created(){
            this.resetforms()
            this.fetchProviderData()
            this.getConsultationsServices()
            this.fetchPatientDetail()
            this.getUrgencyList()
        },
        computed: {
		form() {
			return this.$store.state.consultations.form;
		},
		btnname() {
			return this.$route.name === "PatientConsultationsEdit" ? "Update" : "Save";
		},
       },
       mounted(){
          if (this.$route.name == "PatientEChartConsultAdd") {
               this.fetchData();
          }
          // if (this.$route.name == "PatientEChartConsultAdd") {
          //      if (localStorage.getItem("authUserRole") == 'Doctor') {
          //           this.form.referring_practitioner_id = localStorage.getItem("userId")
          //           setTimeout(() => {
          //                this.physician_text = (this.mrpProviderDataSource.find(item => item.value === this.form.referring_practitioner_id) || {}).title || "Select Primary Physician";
          //           }, 500);
          //      }

          //   }
       }
     //    clearSignature(){
     //      this.$refs.signature.clear();
     //    }
}
</script>
