// src/router/index.js
import { createRouter, createWebHashHistory } from "vue-router";
import Login from '../views/Login.vue';
import Patient from '../views/patient/Home.vue'
import Provider from '../views/provider/Home.vue'
import Administrator from '../views/administrator/Home.vue'
import Calendar from '../views/appointment/Home.vue'
import Chat from '../views/chat/Home.vue'
import Inbox from '../views/inbox/List.vue'
import Task from '../views/task/Home.vue'
import Consultation from '../views/consultation/Home.vue'
import AdministratorFax from '../views/administrator/Fax.vue'
import AdministratorUser from '../views/administrator/User.vue'
import AdminBilling from '../views/administrator/Billing.vue'
import AdminSystem from '../views/administrator/System.vue'
import pageNotFound from "../views/404.vue";
import store from "@/store";
import voiceConvert from "../views/voice-to-text.vue";
import templateMaster from "../views/template-master.vue";
import PrescriptionPrint from "../views/prescription-print.vue";
import EchartPrint from "../views/echart-print.vue";
import InvoiceMaster from "../views/invoice/Home.vue";
import BillingReceiptPrint from "../views/BillingReceiptPrint.vue"

const guest = (to, from, next) => {
	if (!localStorage.getItem("authToken")) {
		return next();
	} else {
		return next("/");
	}
};

const auth = (to, from, next) => {
	if (localStorage.getItem("authToken")) {
		return next();
	} else {
		return next("/login");
	}
};

// async function permissioncheck(to, from, next) {
// 	console.log(from)
// 	console.log(next)
	
// 	var id = to.meta.id;
// 	var userPermissionIds= to.meta.ids;
// 	// var optional_id = to.meta.optional_id;

// 	const permission = await getUserPermission();
// 	if (permission !== null) {
// 		const permissionArr = permission.split(",");

// 		let hasAllPermissions = false

// 		if (typeof userPermissionIds !== 'undefined' && userPermissionIds !== null){
// 			userPermissionIds.forEach((permissionId) => {
// 				if (!hasAllPermissions) {
// 					hasAllPermissions = permissionArr.includes(permissionId.toString())
// 				}
// 			});
// 		}

// 		if (hasAllPermissions || (id != null && permissionArr.includes(id.toString()))) {
// 			return next();
// 		} else {
// 			return next("/Unauthorized");
// 		}

// 		// if (permissionArr.includes(id.toString()) || (typeof optional_id !== 'undefined' && permissionArr.includes(optional_id))) {
// 		// 	return next();
// 		// } else {
// 		// 	return next("/Unauthorized");
// 		// }
// 	} else {
// 		next();
// 	}

// }

// function getUserPermission() {
// 	return new Promise((resolve, reject) => {
// 	// if (store.state.auth.permission_ids === undefined || store.state.auth.permission_ids == null) {
// 	// const unwatch = store.state.auth.watch(
// 	//   () => store.state.auth.permission_ids,
// 	//   (value) => {
// 	//     unwatch();
// 	//     resolve(value);
// 	//   }
// 	// );
// 	console.log(reject)
// 		// recurWatch();
// 		// function recurWatch() {

// 		// 	let permission = localStorage.getItem('permission')
// 		// 	if(permission === undefined || permission == null) {
// 		// 		setTimeout(function() {
// 		// 			recurWatch();
// 		// 		}, 1000);
// 		// 	} else {
// 		// 		resolve(permission);
// 		// 	}
// 		// }

// 	});
// }

const routes = [
	{
		path: "/login",
		name: "Login",
		beforeEnter: guest,
		component: Login,
	},
	{
		path: "/Unauthorized",
		name: "Unauthorized",
		component: pageNotFound,
		// beforeEnter: auth,
	},
	{
		path: '/',
		name: 'Calendar',
		component: Calendar,
		beforeEnter: auth,
	},
	{
		path: "/patient",
		name: "Patient",
		component: Patient,
		beforeEnter: auth,
		meta: {
			headerTitle: 'MASTER RECORD',
		},
		children: [
			{
				path: "create",
				name: "PatientCreate",
				component: Patient,
				meta: {
					headerTitle: 'CREATING MASTER RECORD',
				}
			},
			{
				path: "list",
				name: "PatientList",
				component: Patient,
			},
			{
				path: "chart/:patient_id",
				name: "PatientChart",
				component: Patient,
				meta: {
					headerTitle: 'MASTER RECORD',
				}
			},
			{
				path: "appointment-history/:patient_id",
				name: "PatientAppointmentHistory",
				component: Patient,
				meta: {
					headerTitle: 'MASTER RECORD',
				}
			},
			{
				path: "edit/:patient_id",
				name: "PatientEdit",
				component: Patient,
				meta: { headerTitle: 'MASTER RECORD' },
			},
			{
				path: "e-chart",
				component: Patient,
				beforeEnter: auth,
				children: [
					{
						path: ":patient_id",
						name: "PatientEChart",
						component: Patient,
						meta: { headerTitle: 'E-Chart' },
					},
					{
						path: ":patient_id/allergies/list",
						name: "PatientEChartAllergies",
						component: Patient,
						meta: { headerTitle: 'ALLERGIES' },
					},
					{
						path: ":patient_id/consultation/consult-list",
						name: "PatientEChartConsultList",
						component: Patient,
						meta: { headerTitle: 'CONSULTATION', module:"consultation" },
					},
					{
						path: ":patient_id/consultation/consult-add",
						name: "PatientEChartConsultAdd",
						component: Patient,
						meta: { headerTitle: 'Add Consultation' },
					},
					{
						path: ":patient_id/consultation/specialist-list",
						name: "PatientEChartSpecialistsList",
						component: Patient,
						meta: { headerTitle: 'Consultation' },
					},
					{
						path: ":patient_id/medication/prescription-list",
						name: "PatientEChartPrescriptionList",
						component: Patient,
						meta: { headerTitle: 'MEDICATIONS' },
					},
					{
						path: ":patient_id/medication/drug-list",
						name: "PatientEChartDrugList",
						component: Patient,
						meta: { headerTitle: 'MEDICATION' },
					},
					{
						path: ":patient_id/medication/preferred-pharmacy",
						name: "PatientEChartPreferredPharmacy",
						component: Patient,
						meta: { headerTitle: 'Add Medication' },
					},
					{
						path: ":patient_id/medication/prescription-add",
						name: "PatientEChartPrescriptionAdd",
						component: Patient,
						meta: { headerTitle: 'Add Prescription' },
					},
					{
						path: ":patient_id/medication/prescription-view/:id",
						name: "PatientEChartPrescriptionView",
						component: Patient,
						meta: { headerTitle: 'VIEW PRESCRIPTION' },
					},
					{
						path: ":patient_id/preventions/immunization-list",
						name: "PatientEChartImmunizationList",
						component: Patient,
						meta: { headerTitle: 'PREVENTIONS' },
					},
					{
						path: ":patient_id/preventions/screening-list",
						name: "PatientEChartScreeningList",
						component: Patient,
						meta: { headerTitle: 'PREVENTIONS' },
					},
					{
						path: ":patient_id/files/overview",
						name: "PatientEChartFilesOverview",
						component: Patient,
						meta: { headerTitle: 'Files' },
					},
					{
						path: ":patient_id/files/retrieve/:id",
						name: "PatientEChartFilesEdit",
						component: Patient,
						meta: { headerTitle: false },
					},
					{
						path: ":patient_id/files/delete-files",
						name: "PatientEChartFilesDelete",
						component: Patient,
						meta: { headerTitle: 'Files' },
					},
					{
						path: ":patient_id/imaging/list",
						name: "patientImagingList",
						component: Patient,
						meta: { headerTitle: 'Imaging', module:"imaging" },
					},
					{
						path: ":patient_id/labs/list",
						name: "patientLabsList",
						component: Patient,
						meta: { headerTitle: 'Labs', module: "lab" },
					},
					{
						path: ":patient_id/:module/template/edit/:template_id",
						name: "editModuleTemplate",
						component: Patient,
						meta: { headerTitle: false, module: "lab" },
					},
					{
						path: ":patient_id/template/:module/list",
						name: "patientTemplateList",
						component: Patient,
						meta: { headerTitle: 'Template' },
					},
					{
						path: ":patient_id/measurement/list",
						name: "patientMeasurementList",
						component: Patient,
						meta: { headerTitle: 'Measurement', module: "MeasurementList" },
					},
					{
						path: ":patient_id/measurement/group/list",
						name: "patientMeasurementGroupList",
						component: Patient,
						meta: { headerTitle: 'Measurement', module: "MeasurementGroupList" },
					},
					{
						path: ":patient_id/consultation/consult-list",
						name: "PatientEChartConsultList",
						component: Patient,
						meta: { headerTitle: 'CONSULTATION' },
					},
					{
						path: ":patient_id/tasks/active-task-list",
						name: "PatientEChartActiveTaskList",
						component: Patient,
						meta: { headerTitle: 'TASKS' },
					},
					{
						path: ":patient_id/tasks/other-task-list",
						name: "PatientEChartCompleteDeleteTaskList",
						component: Patient,
						meta: { headerTitle: 'TASKS' },
					},
				]
			},
			{
				path: "annotation-store/:moduleName/:patient_id/:history_id/:annotation_note?",
				name: "PatientAnnotationForm",
				component: Patient,
			},
			{
				path: "annotation-list/:moduleName/:patient_id/:history_id",
				name: "PatientAnnotationList",
				component: Patient,
			},
			{
				path: "revision-list/:moduleName/:patient_id/:id",
				name: "PatientRevisionList",
				component: Patient,
			},
			{
				path: "allergies/:patient_id",
				name: "PatientAllergies",
				component: Patient,
			},
			{
				path: "prescriptions/:patient_id",
				name: "PatientPrescriptions",
				component: Patient,
			},
			{
				path: "preventions/:patient_id",
				name: "PatientPreventions",
				component: Patient,
			},
			{
				path: "consultations/:patient_id",
				name: "PatientConsultations",
				component: Patient,
			},
			{
				path: "consultations/:patient_id/edit/:id",
				name: "PatientConsultationsEdit",
				component: Patient,
			},
			{
				path: "consultations-form/:patient_id",
				name: "PatientConsultationsForm",
				component: Patient,
			},
			{
				path: "task/:patient_id/create",
				name: "PatientTaskForm",
				component: Patient,
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.task_task_crud_all] },
			},
			{
				path: "task/:patient_id/list",
				name: "PatientTaskList",
				component: Patient,
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read] },	
					},
			{
				path: "task/:patient_id/retrieve/:id",
				name: "PatientTaskEdit",
				component: Patient,
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud] },		
			},
			{
				path: "vital/:patient_id/create",
				name: "PatientVital",
				component: Patient,
			},
			{
				path: "vital/:patient_id/list/:id",
				name: "PatientVitalList",
				component: Patient,
			},
			{
				path: "vital/:patient_id/retrieve/:vitalMasterId/:id",
				name: "PatientVitalEdit",
				component: Patient,
			},
			{
				path: "set/:patient_id",
				name: "PatientSet",
				component: Patient,
			},
			{
				path: "document/:patient_id/list",
				name: "PatientDocumentList",
				component: Patient,
			},
			{
				path: "document/:patient_id/create",
				name: "PatientDocumentAdd",
				component: Patient,
			},
			{
				path: "document/:patient_id/retrieve/:id",
				name: "PatientDocumentEdit",
				component: Patient,
			},
			{
				path: "eform/:patient_id/list",
				name: "PatientEformList",
				component: Patient,
			},
			{
				path: "eform/:patient_id/current/list",
				name: "PatientCurrentEformList",
				component: Patient,
			},
			{
				path: "eform/:patient_id/deleted/list",
				name: "PatientDeletedEformList",
				component: Patient,
			},
			{
				path: "eform/:patient_id/create/:id",
				name: "PatientAddEform",
				component: Patient,
			},
			{
				path: "eform/:patient_id/retrieve/:id",
				name: "PatientEditEform",
				component: Patient,
			},
			{
				path: "alter-native-contact/:patient_id",
				name: "PatientAlternativeContactList",
				component: Patient,
			},
			{
				path: "alter-native-contact/:patient_id/create",
				name: "PatientAlternativeContactCreate",
				component: Patient,
			},
			{
				path: "alter-native-contact/:patient_id/retrieve/:id",
				name: "PatientAlternativeContactEdit",
				component: Patient,
			},
			{
				path: "enrollment-history/:patient_id",
				name: "PatientEnrollmentHistory",
				component: Patient,
				meta: {
					headerTitle: 'MASTER RECORD',
				}
			},
			{
				path: "enrollment-history/:patient_id/retrieve/:id",
				name: "EditPatientEnrollmentHistory",
				component: Patient,
				meta: {
					headerTitle: 'MASTER RECORD',
				}
			},
			{
				path: "enrollment-history/:patient_id/order",
				name: "EditPatientEnrollmentHistory",
				component: Patient,
				meta: {
					headerTitle: 'MASTER RECORD',
				}
			},
		]
	},	
	{
		path: "/provider",
		name: "Provider",
		component: Provider,
		beforeEnter: auth,
		children: [
			{
				path: "create",
				name: "ProviderCreate",
				component: Provider,
			},
			{
				path: "list",
				name: "ProviderList",
				component: Provider,
			},
		]
	},
	{
		path: '/chat',
		name: 'Chat',
		component: Chat,
		beforeEnter: auth,
	},
	{
		path: "/manage",
		name: "Manage",
		component: Administrator,
		beforeEnter: auth,
		children: [
			{
				path: "e-forms",
				name: "ManageEForms",
				component: Administrator,
			},
			{
				path: "e-forms/edit/:id",
				name: "EditEForms",
				component: Administrator,
			},
		]
	},
	{
		path: "/inbox",
		name: "Inbox",
		component: Inbox,
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.inbox_inbox_crud_all, store.state.permission.inbox_inbox_crud, store.state.permission.inbox_inbox_read], headerTitle: 'Inbox' },	
		children: [
			{
				path: "deleted",
				name: "InboxDeletedList",
				component: Inbox,
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.inbox_inbox_crud_all], headerTitle: 'Inbox'  },	
			},
			{
				path: "retrieve/:id",
				name: "InboxEdit",
				component: Inbox,
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.inbox_inbox_crud_all], headerTitle: 'Inbox'  },	
			}		
		]
	},
	{
		path: "/task/active-task-list",
		name: "AllPatientActiveTaskList",
		component: Task,
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'TASK' },	
		// children: [
		// 	{
		// 		path: "create",
		// 		name: "TaskCreate",
		// 		component: Task,
		// 		beforeEnter: [auth],
		// 		meta: { ids: [store.state.permission.task_task_crud_all] },	
		// 	},
		// ]
	},
	{
		path: "/task/complete-delete-list",
		name: "AllPatientCompleteDeleteTaskList",
		component: Task,
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'TASK' },	
		// children: [
		// 	{
		// 		path: "create",
		// 		name: "TaskCreate",
		// 		component: Task,
		// 		beforeEnter: [auth],
		// 		meta: { ids: [store.state.permission.task_task_crud_all] },	
		// 	},
		// ]
	},
	{
		path: "/all-consultation",
		name: "AllConsultationList",
		component: Consultation,
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'CONSULTATION' },	
	},
	{
		path: "/all-specialist",
		name: "AllSpecialistList",
		component: Consultation,
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'CONSULTATION' },	
	},
	{
		path: "/administration",
		children: [
			{
				path: "account-settings",
				name: "AccountSettings",
				component: Administrator,
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration' },
			},
			{
				path: "clinic-settings",
				name: "clinicSettings",
				component: Administrator,
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration' },
			},
			{
				path: "management-settings",
				name: "managementSettings",
				component: Administrator,
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration' },
			},
			{
				path: "billing-custom-code",
				name: "billingCustomCode",
				component: Administrator,
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration' },
			},
			{
				path: "third-party-integrations",
				name: "thirdPartyIntegrations",
				component: Administrator,
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration' },
			},
			{
				path: "payment-preferences",
				name: "paymentPreferences",
				component: Administrator,
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration' },
			},
			{
				path: "hr-analysis-report",
				name: "hrAnalysisReports",
				component: Administrator,
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration' },
			},
			{
				path: "audit-logs",
				name: "auditLogs",
				component: Administrator,
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration' },
			},
			{
				path: "data-management",
				name: "dataManagement",
				component: Administrator,
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration' },
			},
			{
				path: "dashboard",
				name: "AdminDashboard",
				beforeEnter: [auth],
				component: Administrator,
			},
			{
				path: "fax",
				name: "AdminFax",
				component: AdministratorFax,
				children: [{
					path: "config/list",
					name: "AdminFaxConfig",
					beforeEnter: [auth],
					meta: { ids: [store.state.permission.admin_acount_setting_crud_all, store.state.permission.admin_inbox_crud, store.state.permission.admin_inbox_read] },	
				},
				{
					path: "config/create",
					name: "CreateAdminFaxConfig",
					beforeEnter: [auth],
					meta: { ids: [store.state.permission.admin_acount_setting_crud_all] },	
				},
				{
					path: "config/retrieve/:id",
					name: "EditAdminFaxConfig",
					beforeEnter: [auth],
					meta: { ids: [store.state.permission.admin_acount_setting_crud_all, store.state.permission.admin_inbox_crud] },	
				},
			]
			},
			{
				path: "clinic/detail",
				name: "AdminClinic",
				component: Administrator,
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.admin_clinic_detail_crud_all, store.state.permission.admin_clinic_detail_crud, store.state.permission.admin_clinic_detail_read] },
			},
			{
				path: "user",
				name: "AdminUser",
				children: [{
						path: "list",
						name: "AdminUserList",
						component: AdministratorUser,
						beforeEnter: [auth],
						meta: { ids: [store.state.permission.admin_acount_setting_crud_all, store.state.permission.admin_acount_setting_crud, store.state.permission.admin_acount_setting_read] },
					},
					{
						path: "retrieve/:id",
						name: "EditAdminUser",
						component: AdministratorUser,
						beforeEnter: [auth],
						meta: { ids: [store.state.permission.admin_acount_setting_crud_all, store.state.permission.admin_acount_setting_crud] },
					},
					{
						path: "create",
						name: "CreateAdminUser",
						component: AdministratorUser,
						beforeEnter: [auth],
						meta: { ids: [store.state.permission.admin_acount_setting_crud_all] },
					},
					{
						path: "permission/:id",
						name: "EditAdminUserPermission",
						component: AdministratorUser,
						beforeEnter: [auth],
						meta: { ids: [store.state.permission.admin_acount_setting_crud_all] },
					},
				]
			},
			{
				path: "billing",
				beforeEnter: auth,
				children: [{
					path: "account-setting",
					name: "AdminBillingAccSettings",
					component: AdminBilling,
					beforeEnter: [auth],
					meta: { ids: [store.state.permission.admin_billing_crud_all,store.state.permission.admin_billing_crud,store.state.permission.admin_billing_read] },
				},
				{
					path: "code/list",
					name: "AdminBillingCodeList",
					component: AdminBilling,
					beforeEnter: [auth],
					meta: { ids: [store.state.permission.admin_billing_crud_all,store.state.permission.admin_billing_crud,store.state.permission.admin_billing_read] },
				},
				{
					path: "code/create",
					name: "CreateAdminBillingCode",
					component: AdminBilling,
					beforeEnter: [auth],
					meta: { ids: [store.state.permission.admin_billing_crud_all] },
				},
				{
					path: "code/retrieve/:id",
					name: "EditAdminBillingCode",
					component: AdminBilling,
					beforeEnter: [auth],
					meta: { ids: [store.state.permission.admin_billing_crud_all] },
				},
				]
			},
			{
				path: "appointment-status",
				name: "AppointmentStatus",
				component: Administrator,
				beforeEnter: auth,
			},
			{
				path: "system",
				beforeEnter: auth,
				children: [
				{
					path: "role/list",
					name: "AdminRoleList",
					component: AdminSystem,
				},
				// {
				// 	path: "role/create",
				// 	name: "CreateAdminRole",
				// 	component: AdminSystem,
				// },
				{
					path: "role/retrieve/:id",
					name: "EditAdminRole",
					component: AdminSystem,
				},
				{
					path: "specialist/list",
					name: "AdminSpecialistList",
					component: AdminSystem,
				},
				{
					path: "specialist/create",
					name: "CreateAdminSpecialist",
					component: AdminSystem,
				},
				{
					path: "specialist/retrieve/:id",
					name: "EditAdminSpecialist",
					component: AdminSystem,
				},
				{
					path: "document-type/list",
					name: "AdminDocTypeList",
					component: AdminSystem,
				},
				{
					path: "document-type/create",
					name: "CreateAdminDocType",
					component: AdminSystem,
				},
				{
					path: "document-type/retrieve/:id",
					name: "EditAdminDocType",
					component: AdminSystem,
				},
				{
					path: "service/list",
					name: "AdminServiceList",
					component: AdminSystem,
				},
				{
					path: "service/create",
					name: "CreateService",
					component: AdminSystem,
				},
				{
					path: "service/retrieve/:id",
					name: "EditService",
					component: AdminSystem,
				},
				{
					path: "vital/list",
					name: "AdminVitalList",
					component: AdminSystem,
				},
				{
					path: "vital/retrieve/:id",
					name: "EditAdminVital",
					component: AdminSystem,
				},
				]
			},
			{
				path: "appointment-types",
				name: "AppointmentType",
				component: Administrator,
				beforeEnter: auth,
			},
			{
				path: "audit-log",
				name: "AuditLog",
				component: Administrator,
				beforeEnter: auth,
			}
		]
	},
	{
		path: "/voice-to-text",
		name: "voiceConvert",
		component: voiceConvert,
	},
	{
		path: "/template/list",
		name: "templateMasterList",
		component: templateMaster,
		beforeEnter: auth,
		meta: { headerTitle: 'Template' },
	},
	{
		path: "/template/edit/:template_id",
		name: "templateMasterEdit",
		component: templateMaster,
		beforeEnter: auth,
		meta: { headerTitle: false },
	},
	{
		path: "/patient/:patient_id/medication/prescription-pdf/:id",
		name: "PatientEChartPrescriptionPrint",
		component: PrescriptionPrint,
		meta: {  },
	},
	{
		path: "/patient/:patient_id/e-chart/print",
		name: "PatientEChartPrint",
		component: EchartPrint,
		meta: {  },
	},
	{
		path: "/invoice",
		name: "InvoiceMaster",
		component: InvoiceMaster,
		beforeEnter: auth,
		children: [
			{
				path: "dashboard",
				name: "InvoiceMasterDashboard",
				component: InvoiceMaster,
				meta: { headerTitle: 'Billing' },
			},
			{
				path: "create",
				name: "InvoiceMasterCreate",
				component: InvoiceMaster,
				meta: { headerTitle: 'Billing' },
			},
			{
				path: "list",
				name: "InvoiceList",
				component: InvoiceMaster,
				meta: { headerTitle: 'Billing' },
			},
			{
				path: "analysis-reports",
				name: "InvoiceAnalysisReport",
				component: InvoiceMaster,
				meta: { headerTitle: 'Billing' },
			},
			
		]},
		{
			path: "/invoice/billing-receipt-pdf/:id/:type",
			name: "BillingReceiptPrint",
			component: BillingReceiptPrint,
			meta: {  },
		},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;