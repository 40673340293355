<template>
	<div class="row">
		<h3>Edit Document</h3>

		<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
		<div class="box">
			<div class="box-body p-0">
				<div class="media-list media-list-hover">
					<SelectBoxStatic divClass="col-md-12" title="Document Type" v-model="form.document_type_id" :option="document_type_options" :errors="v$.form.document_type_id.$errors" :isRequired="true"></SelectBoxStatic>

                    <SelectBoxStatic divClass="col-md-12" title="Report Class" v-model="form.report_class_id" :option="report_class_options" ></SelectBoxStatic>

					<InputField title="Report SubClass" v-model="form.report_sub_class_name" divClass="col-md-12"> </InputField>


					<InputField title="Description" v-model="form.description" :errors="v$.form.description.$errors" divClass="col-md-12"> </InputField>

					<div class="col-md-12">
						<div class="form-group">
							<label class="form-label">Received DateTime<small class="asterisksign">*</small></label>
							<div class="syncfusion-component">
								<ejs-datetimepicker v-model="form.received_datetime" :format="dateTimeFormat" class="e-field form-control" :errors="v$.form.received_datetime.$errors" :isRequired="true" @change="handleTimeChange"></ejs-datetimepicker>
							</div>
							<div class="error-msg" v-if="v$.form.received_datetime.$errors.length > 0">
								<ul class="mb-0 p-0 fs-14">
									<li class="d-block" v-for="(error, index) of v$.form.received_datetime.$errors" :key="index">
										{{ error.$message }}
									</li>
								</ul>
							</div>
						</div>
					</div>

					<DatePicker divClass="col-md-12" title="Observer Date" v-model="form.observation_date" :dateFormat="dateFormat" :isRequired="true" :errors="v$.form.observation_date.$errors" ></DatePicker>

                    <div class="form-group row">
                        <label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Added By:	{{form.creator}} </label>
                    </div>

					<!-- <file-upload title="Document" v-model="form.file_path" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" :isRequired="true" :errors="v$.form.file_path.$errors" ref="fileInput" @change="handleFileChange"></file-upload> -->

                    <SelectBoxStatic divClass="col-md-12" title="Responsible Provider" v-model="form.responsible_provider_id" :option="providerList" ></SelectBoxStatic>

					<InputField title="Source Author" v-model="form.source_author"  divClass="col-md-12"> </InputField>

					<InputField title="Source Facility" v-model="form.source_facility"  divClass="col-md-12"> </InputField>

					<CheckBox title="Restrict to current program" v-model="form.is_restricted_to_program"></CheckBox>
					
				</div>
			</div>
		</div>

        <div class="row">
        <form-btn
                v-if="!form.reviewed_provider_id"
                title="Reviewed"
                btnCls="btn btn-primary text-white btn-sm col-md-3"
				@click="save('reviewed')"
            ></form-btn>
        </div>

		<div class="row">
			<div class="col-md-12 text-center pt-10">
				<button class="btn btn-danger text-white btn-sm col-md-2 me-1" @click="closeWindow"> Cancel &amp; EXIT </button>
				<form-btn
					title="Update"
					btnCls="btn btn-primary text-white btn-sm col-md-2 pl-1 ml-1"
					@click="save"
				></form-btn>
			</div>
        </div>
		</div>

		<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" v-if = "!this.isDocFile(this.form.content)">
			<div class="row">
				<div class="col-md-6">
					<div class="syncfusion-component">
						<ejs-dropdownlist v-model="faxFrm.provider_id" placeholder='Select a Provider' :showClearButton="true" :allowFiltering='true' :filtering='filtering' :dataSource='providerFaxList' :fields='fields' @filtering="onFilter" @change="selectProvider"></ejs-dropdownlist>
					</div>
				</div>
				<InputField title="Fax" v-model="faxFrm.fax_no" divClass="col-3" placeholder="(___) ___-____" @input="formatFaxNumber" :titleHide="true" :isRequired="true" > </InputField>
				<div class="col-md-3">
					<button class="btn btn-sm btn-primary text-white me-1" :class="disableSendBtnCls" @click="sendFax">
						Send Fax
						<img src="images/loader.gif" v-if="sendFaxLoader" />
					</button>
				</div>
			</div>

			<ejs-pdfviewer 
				id="pdfViewer" 
				ref="pdfViewer"
				:documentPath="documentPath"
				:resourceUrl="resourceUrl"
				@documentLoad="onDocumentLoad"
				:serviceUrl="serviceBaseUrl"
				:enableTextSearch="true"
				:enableHyperlink="true"
				:enableMagnification="true"
				:enableToolbar="true"
				:enableNavigation="true"
				:enableThumbnail="false"
				:enableBookmark="true"
				:enableTextSelection="true"
				:enablePrint="true"
				:enableAnnotation="true"
				:enableFormFields="false"
				:enableFormDesigner="true" 
				:openFile='false'
				> 
			</ejs-pdfviewer>

		</div>

	</div>
</template>

<script>
import InputField from "../../base/formFields/InputField.vue"
import FormBtn from "../../base/formFields/FormBtn.vue";
import DatePicker from '../../base/formFields/DatePicker.vue';
import useVuelidate from "@vuelidate/core";
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import CheckBox from "../../base/formFields/CheckBox.vue";
// import FileUpload from "../../base/formFields/FileUpload.vue";
import axios from "@/scripts/axios.js";
import $ from "jquery";
import {  Toolbar, Magnification, Navigation, LinkAnnotation, 
         BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, 
         Annotation } from '@syncfusion/ej2-vue-pdfviewer';
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { DateTimePickerComponent } from "@syncfusion/ej2-vue-calendars";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data(){
        return {
			document_type_options:[],
			report_class_options:[],
            providerList:[],
			providerFaxList:[],
			publicPath: process.env.BASE_URL,
			documentPath:"",
			resourceUrl:"https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
            fields : { text: "title", value: "value" },
			searchText:"",
			sendFaxLoader:false,
			disableSendBtnCls:'',
			dateFormat:"yyyy-MM-dd",
			dateTimeFormat:"yyyy-MM-dd hh:mm a",
			userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Get the user's timezone
			// serviceBaseUrl: 'http://api.emr.chirag/api/document',
			// serviceUrl:"https://services.syncfusion.com/vue/production/api/pdfviewer",
        }
    },
    components: {
        InputField,
        FormBtn,
		DatePicker,
		SelectBoxStatic,
		CheckBox,
		'ejs-dropdownlist': DropDownListComponent,
		'ejs-datetimepicker': DateTimePickerComponent,
		// FileUpload,
    },
    methods:{
		onDocumentLoad() {
		},
		async uploadDocument() {
			const formData = new FormData();

			const pdfViewerInstance = this.$refs.pdfViewer.ej2Instances;
			if (pdfViewerInstance) {
				const annotatedPdfBlob = pdfViewerInstance.saveAsBlob();
				if (annotatedPdfBlob instanceof Promise) {
					annotatedPdfBlob.then((result) => {
						if (result instanceof Blob) {
							formData.append('pdfData', result, 'edited_file.pdf');
						}
						formData.append('patient_id', this.$route.params.patient_id);
						formData.append('id', this.$route.params.id);

						try {
							const config = {
								headers: {
									'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
									'Content-Type': 'multipart/form-data'
								},
							};

							const response = axios.post('/patient/document/upload', formData, config);
							if (response.status == 200) {
								var detail = response.data.data
								this.form.file_path = detail.pdfUrl
							}

							console.log('PDF with notes saved on the server:', response.data);
						}
						catch (error) {
							console.error('Error saving PDF with notes on the server:', error);
						}
					});
				}
			}
		},
		getDocumentType() {
			this.document_type_options = []
			axios.post("json_autocomplete/document-type/list", { })
				.then((response) => {
					if (response.data.status === 200) {
						const tempData = []
						$.each(response.data.data, function (key, detail) {
							tempData.push({
								value: detail.id,
								title: detail.type,
							})
						})
						this.document_type_options = tempData
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		getReportType() {
			this.report_class_options = []
			axios.post("json_autocomplete/doc-report-type/list", { })
				.then((response) => {
					if (response.data.status === 200) {
						const tempData = []
						$.each(response.data.data, function (key, detail) {
							tempData.push({
								value: detail.id,
								title: detail.name,
							})
						})
						this.report_class_options = tempData
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		async save(action='') {
			this.v$.$validate();
			
			if (!this.v$.$error) {

				this.uploadDocument()
				this.form.patient_id = this.$route.params.patient_id;

                let url='';
				if (this.$route.name === "PatientDocumentEdit") {
                    this.form.id = this.$route.params.id;
                    if (action == 'reviewed') {
                        this.form.reviewed_provider_id = localStorage.getItem('userId');
                    }
					url = "patient/document/update";
					this.form.file_path = ''
				} else {
					url = "patient/document/store";
				}

				const config = {
					headers: {
						'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
						'Content-Type': 'multipart/form-data'
					},
				};

				axios.post(url, this.form, config)
				.then((response) => {
					if (response.status == 200) {
                        this.$filters.moshaToast(response.data.message, "success");
                        this.closeWindow()
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });

			}

		},
		handleTimeChange(args) {
			const date = new Date(args.value);
			const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
			this.form.received_datetime = formattedDate
		},
		closeWindow(){
			this.$router.push(`/patient/document/${this.$route.params.patient_id}/list`);
            // if (window.opener === null) {
            //     // console.log('This page was opened in a new window or tab.');
            //     this.$router.push(`/patient/document/${this.$route.params.patient_id}/list`);

            // } else {
            //     // console.log('This page was opened from another page.');
            //     window.close();
            // }
        },
        async edit(){
            axios.post("patient/document/retrieve", { 'patient_id': this.$route.params.patient_id, 'id': this.$route.params.id})
                    .then((response) => {
                        if (response.data.status === 200) {
							var detail = response.data.data;
                            this.$store.state.patient_document.form = detail;
							this.documentPath = this.documentUrl(detail.file_path)
							
							if (this.isDocFile(detail.content)) {
								
								const link = document.createElement('a');
								link.href = this.documentPath;
								// link.setAttribute('download', "65602eb1b83aa.docx");
								link.download = "";

								document.body.appendChild(link);
								link.click();

								document.body.removeChild(link);

							}

                        } else {
                            this.$filters.moshaToast(response.data.message, "error")
                        }
                    }).catch(error => {
                        this.$filters.moshaToast(error.message, "error")
                   
			}).catch(error => {
				if (error.response.status === 422) {
					this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
				} else {
					this.$filters.moshaToast(error.response.data.message, "error");
				}
			});
        },
        getProviders() {
            axios.post("json_list/provider")
				.then((response) => {
					this.providerList = response.data.data.map((item) => {
						return { value: item.user_id, title: item.full_name };
					});
					this.providerFaxList = response.data.data.map((item) => {
						if (item.fax_phone) {
							let providerName = item.full_name
							providerName += ' ['+item.fax_phone+']'
							return { value: item.id, title: providerName };
						}
					});
				});
        },
        documentUrl(documentPath) {
            // Assuming your documents are stored in the 'public' directory
            return `${process.env.VUE_APP_STORAGE_URL}/document/view/${documentPath}`;
        },
		isDocFile(contentType) {
			const documentMimeTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
			if (documentMimeTypes.includes(contentType)) {
				return true
			} else {
				return false
			}
		},
		onFilter(args) {
            // Update the searchText based on user input during filtering
            this.searchText = args.text;
            if (this.searchText.length > this.minSearchLength) {
                this.getProviders()
            }
        },
		selectProvider(args){
			let providerName = args.itemData.title
			const regex = /\[(.*?)\]/;
			const matches = providerName.match(regex);
			this.faxFrm.fax_no=matches ? matches[1] : ''
			this.formatFaxNumber()
		},
		formatFaxNumber() {
			// Remove non-numeric characters from the input
			const numericValue = this.faxFrm.fax_no.replace(/\D/g, '');

			// Format the fax number using a regular expression
			const formattedValue = numericValue.replace(
				/^(\d{3})(\d{3})(\d{4})$/,
				'($1) $2-$3'
			);

			// Update the data property with the formatted value
			this.faxFrm.fax_no = formattedValue;
		},
		sendFax(){
			if (this.faxFrm.fax_no.length == 0){
				this.$filters.moshaToast("Please enter fax number", "error");
			} else if (this.faxFrm.fax_no.length != 14){
				this.$filters.moshaToast("Please enter valid fax number", "error");
			} else {
				this.sendFaxLoader = true
				this.disableSendBtnCls = 'disabled'
				const config = {
					headers: {
						'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
						'Content-Type': 'multipart/form-data'
					},
				};

				this.faxFrm.patient_id = this.$route.params.patient_id
				this.faxFrm.id = this.$route.params.id
				axios.post("patient/document/send-fax", this.faxFrm, config)
				.then((response) => {
					this.sendFaxLoader = false
					this.disableSendBtnCls = ''
					if (response.status == 200) {
                        this.$filters.moshaToast(response.data.message, "success");
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
						this.sendFaxLoader = false
						this.disableSendBtnCls = ''
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					});
			}
		}
	},
	computed: {
		form() {
			return this.$store.state.patient_document.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.patient_document.vuelidateExternalResults;
		},
		faxFrm(){
			return this.$store.state.patient_document.faxFrm;
		}
		
    },
    validations() {
		return this.$store.state.patient_document.validationRules;
	},
    mounted() {
		this.getDocumentType()
		this.getReportType()
        this.getProviders()
        this.edit()
	},
	created(){

	},
	provide: {
		PdfViewer: [ Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation ]
	}
}
</script>


<style>
  @import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
  @import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
  @import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
  @import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
  @import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
  @import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
  @import "../../../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css";

#pdfViewer_fileUploadElement {
	display: none !important;
}
</style>
