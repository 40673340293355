<template>
	<div class="content">

        <div class="row">
            <div class="box">
                <div class="box-body">
                    <div class="row">
                        <div class="col-10">
                            <h3 >Fax Configure</h3> 
                        </div>

                        <div class="col-2" v-if="this.showCreateBtn">
                            <button class="waves-effect waves-light btn btn-info d-block mb-10 btn-sm" v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_inbox_crud_all])" href="javascript::void(0)" @click="createFaxConfig"><i class="fa fa-plus fs-14"></i>&nbsp;Create Fax Config</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <Datatable dataType="fax" dataMethod="post" :key="key">
            <template v-slot="slotProps">
                <td>{{slotProps.item.internal_name}}<br/>{{ slotProps.item.respomse_fax_number }}</td>
                <td>{{slotProps.item.internal_name}}<br/>{{ slotProps.item.account_number }}</td>
                <td v-if="slotProps.item.is_enable_incoming_fax == 1">
                    <i  class='text text-success fa fa-check-circle'></i>Enabled
                </td>
                <td v-else>
                    <i class='text text-danger fa fa-check-circle'></i>Disabled
                </td>
                <td v-if="slotProps.item.is_enable_outgoing_fax == 1">
                    <i  class='text text-success fa fa-check-circle'></i>Enabled
                </td>
                <td v-else>
                    <i class='text text-danger fa fa-check-circle'></i>Disabled
                </td>
                <td>
                    <button class="waves-effect waves-light btn btn-info d-block mb-10 btn-sm editFaxConfig" :data-id="slotProps.item.id" href="javascript::void(0)" @click="editFaxConfig( slotProps.item.id)" v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_inbox_crud_all, this.$store.state.permission.admin_inbox_crud])"><i class="fa fa-pencil fs-14"></i>&nbsp;Edit</button>
                </td>
            </template>
        </Datatable>
	</div>
</template>

<script>
import Datatable from '../../base/formFields/Datatable.vue';
import $ from "jquery";

export default {
    data() {
        return {
            key: 0,
            showCreateBtn:false,
            authUserRole:localStorage.getItem("authUserRole"),
        }
    },
    components: {
        Datatable
    },
    mounted(){
        this.showCreateBtn = true
        this.$nextTick(() => {
            console.log($("#fax tbody td .editBtn").length)
        })
    },
    methods:{
        createFaxConfig(){
            if (this.authUserRole != 'Admin' && $("#fax tbody td").length > 0) {
                this.editFaxConfig($(".editFaxConfig").data('id'))
            } else {
                this.$router.push(`/administration/fax/config/create`);  
            }
        },
        editFaxConfig(id){
            this.$router.push(`/administration/fax/config/retrieve/${id}`);  
        }
    }
}
</script>