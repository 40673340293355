<template>
    <div class="viewprescription">
        <div class="addprescription">
            <div class="search">
                <img class="serch_icon" src="/images/search-icon.svg" alt="">
                <input type="text" @keyup="drugSearch" class="form-control" placeholder="Search Drug" v-model="drug_search" @blur="drugSearch" ref="drugSearch">
                <div class="search_drop_box" v-if="drugList.length > 0 ">
                        <slot v-for="(item, index) in drugList" :key="index">
                        <div class="con_drop_line d-flex justify-content-between align-items-center" @click="selectDrug(item)">
                            <div class="man_haeding">{{ item.generic_name }} {{ item.strength }} {{ item.dosage_form }}</div>
                            <div class="lu_box" v-if="item.coverage_status_code">{{ item.coverage_status_code }}</div>
                        </div>
                    </slot>
                </div>
            </div>
            <div class="btn_part">
                <button class="comman_brdr_btn big_btn" @click="cancelFrm"> Cancel </button>
                <button class="comman_btn big_btn" @click="this.save('save')" v-if="this.prescriptionList.length > 0"> Save <img src="images/loader.gif" v-if="savingloader" /></button>
                <button class="comman_btn big_btn" @click="this.save('save_print')" v-if="this.prescriptionList.length > 0"> Save & Print/Fax<img src="images/loader.gif" v-if="savingPrintloader" /></button>
            </div>
        </div>
        <div class="addprescription_line" v-if="this.prescriptionList.length == 0">Search Drug to Add Prescription</div>
        <div class="prescription_box_scroll" v-else>
            <slot v-for="(item, index) in prescriptionList" :key="index">
                <div class="prescription_box">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="left_prescription">
                                <div class="haeding_line d-flex align-items-center justify-content-between">
                                    <aside>Prescription {{ index+1 }}</aside>
                                    <span class="popup_link" @click="removeItem(item.id)">Cancel</span>
                                </div>
                                <div class="mr-30">
                                    <div class="mb-0 row pd-bottom">
                                        <label for="text" class="col-sm-4 col-form-label right-bor">Generic Name</label>
                                        <div class="col-sm-8"><input type="text" class="form-control" v-model="item.generic_name" @change="generateNotes(item)"></div>
                                    </div>
                                    <div class="mb-10 row">
                                        <label for="text" class="col-sm-4 col-form-label right-bor">Brand Name</label>
                                        <div class="col-sm-8"><input type="text" class="form-control" v-model="item.drug_name" @change="generateNotes(item)"></div>
                                    </div>
                                    <div class="mb-10 row align-items-center l-h-n">
                                        <label for="text" class="col-sm-4 col-form-label right-bor mt-30">Instruction</label>
                                        <div class="col-sm-8">
                                            <div class="pr-25">
                                                <div class="row">
                                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3">
                                                        <div class="presxrpt-int-drop">
                                                            <label for="text" class="drop_label mb-5p">Method</label>
                                                            <CustomDropDown :options="method_option" :initialValue="this.method_text"
                                                                v-model="item.method" @item-selected="handleItemSelected" :fieldName="`${index}.method`" index="1" @change="this.calculateNextRefilDate(item)">
                                                            </CustomDropDown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-0 row">
                                        <label for="text" class="col-sm-4"></label>
                                        <div class="col-sm-8">
                                            <div class="pr-25">
                                                <div class="dropdown_list_ins">
                                                    <div class="row">
                                                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                            <div class="instru-inpt-box">
                                                                <label for="text" class="drop_label">Dosage</label>
                                                                <input type="number" class="form-control" v-model="item.dosage" min="0" @change="generateNotes(item)">
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                            <div class="presxrpt-int-drop">
                                                                <label for="text" class="drop_label">Dosage Unit</label>
                                                                <CustomDropDown :options="dosage_unit_option" :initialValue="this.dosage_unit_text"
                                                                    v-model="item.dosage_unit" @item-selected="handleItemSelected" :fieldName="`${index}.dosage_unit`" >
                                                                </CustomDropDown>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                            <div class="presxrpt-int-drop">
                                                                <label for="text" class="drop_label">Route</label>
                                                                <CustomDropDown :options="route_option" :initialValue="this.route_text"
                                                                    v-model="item.route" @item-selected="handleItemSelected" :fieldName="`${index}.route`">
                                                                </CustomDropDown>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                            <div class="presxrpt-int-drop">
                                                                <label for="text" class="drop_label">Frequency</label>
                                                                <CustomDropDown :options="frequency_option" :initialValue="this.frequency_text"
                                                                    v-model="item.frequency" @item-selected="handleItemSelected" :fieldName="`${index}.frequency`">
                                                                </CustomDropDown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                            <div>
                                                                <label for="text" class="drop_label">Duration</label>
                                                                <input type="number" class="form-control" v-model="item.duration" @change="this.calculateNextRefilDate(item)" step="1" min="0">
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                            <div class="presxrpt-int-drop">
                                                                <label for="text" class="drop_label">Duration Unit</label>
                                                                <CustomDropDown :options="duration_unit_option" :initialValue="this.duration_text"
                                                                    v-model="item.duration_unit" @item-selected="handleItemSelected" :fieldName="`${index}.duration_unit`"  @change="this.calculateNextRefilDate(item)">
                                                                </CustomDropDown>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                            <div>
                                                                <label for="text" class="drop_label">Quantity</label>
                                                                <input type="number" class="form-control" v-model="item.quantity" step="1" min="0" @change="generateNotes(item)">
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                            <div>
                                                                <label for="text" class="drop_label">Repeats</label>
                                                                <input type="number" class="form-control" v-model="item.repeats" step="1" min="0" @change="this.calculateNextRefilDate(item)">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="next_rfl_date mt-10" v-if="(item.is_long_term || item.repeats >=1) && item.next_refill_date">
                                    <span class="mr-10">Next Refill Date:</span>
                                    <label>{{ item.next_refill_date }}</label>
                                </div>
                                <div class="pl-10 pr-30">
                                    <div class="check_box">
                                        <span class="ps-0"><label>Long Term Medication</label><span class="ms-3"><input class="form-check-input m-0 align-middle" type="checkbox" v-model="item.is_long_term" @change="this.calculateNextRefilDate(item)"></span></span>
                                        <span><label>Substitution Now Allowed</label><span class="ms-3"><input class="form-check-input m-0 align-middle" type="checkbox" v-model="item.is_patient_compliant"></span></span>
                                        <span><label>Past Medication</label><span class="ms-3"><input class="form-check-input m-0 align-middle" type="checkbox" v-model="item.is_past_med"></span></span>
                                    </div>
                                    <textarea class="form-control" rows="4" v-model="item.notes"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="right_prescription h-100">

                            </div>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
    </div>

    <!-- <div class="modal fade" id="prescription-fax-model" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    <div class="box">
                        <div class="row prescription_html">

                            <div class="col-12">
                                <h2 class="popup_title">Prescription Rx</h2>
                                <p>
                                    {{ this.provider_detail.full_name }} | CPS ID - {{ this.provider_detail.cpsid }} {{ this.provider_detail.address }} {{ this.provider_detail.cell_phone }} FAX  {{ this.provider_detail.fax_phone }}
                                </p>

                                <h2 class="popup_title">Basic Info</h2>

                                <p>
                                    <label>Patient</label> 
                                    <span>{{ this.patient_detail.full_name }} </span>
                                    <span>{{ this.patient_detail.address }}
                                        {{ this.patient_detail.city }}, {{ this.patient_detail.state }}, {{ this.patient_detail.country }}
                                        {{ this.patient_detail.zip }}
                                        Tel {{ this.patient_detail.cell_phone }}</span>
                                </p>
                                <p>
                                    <label>date of birth</label> 
                                    <span>{{this.patient_detail.dob}} </span>
                                </p>
                                <p>
                                    <label>hin</label> 
                                    <span>{{this.patient_detail.health_insurance_no}} </span>
                                </p>
                                <p>
                                    <label>Service Date</label> 
                                    <span>{{ this.patient_detail.written_date }} </span>
                                </p>

                                <h2 class="popup_title">Medication</h2>
                                <slot v-for="(item, index) in prescriptionList" :key="index">
                                    <p>
                                        <label>prescription {{ index + 1  }} </label> 
                                        <span>{{ item.drug_name }} </span>
                                    </p>
    
                                    <p>
                                        <label>instruction</label> 
                                        <span v-html="item.notes"></span>
                                    </p>
                                </slot>

                            </div>
                            

                        </div>

                        <div class="btn_part">
                            <button class="comman_brdr_btn" @click="cancelPrescription"> Cancel </button>
                            <button class="comman_btn" @click="this.printPreview()"> Print <img src="images/loader.gif" v-if="savingloader" /></button>
                            <button class="comman_btn"> Fax <img src="images/loader.gif" v-if="savingloader" /></button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="modal fade" id="prescription-fax-model" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    <div class="box">
                        <div class="row patient_chart justify-content-center edit_record_page">
                            <div class="col-lg-5">
                                <h4 class="box-title mt-0">Preferred Pharmacy</h4>
                                <div class="box-body containt_detail">
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Pharmacy Name</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.pharmacy_name}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Address</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.address}} {{this.pharmacy_detail.city}} {{this.pharmacy_detail.state}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Postal Code</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.zip_code}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Fax Number</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.fax}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Phone Number</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.phone}}</div>
                                    </div>
                                    </div>
                                </div>
                                <h4 class="box-title">Signature</h4>
                                <div class="box-body containt_detail mb-3">
                                    <div id="signature-control">
                                    <div class="e-sign-heading"><span id="signdescription"></span></div>
                                    <div class="col-9 me-0 ms-auto" style="height: 60px;">
                                        <img :src="this.sigImgBase64" alt="Base64 Image" class="e-control e-signature e-lib" height="60" style="height: 100%; width: 100%;" width="429">
                                        <!-- <canvas id="signature" class="e-control e-signature e-lib" role="img" aria-label="signature" tabindex="-1" height="60" style="height: 100%; width: 100%;" width="429"></canvas> -->
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="pdf_viewer">
                                    <ejs-pdfviewer 
                                        id="pdfViewer" 
                                        ref="pdfviewer" 
                                        :documentPath="documentPath" 
                                        :resourceUrl="resourceUrl"
                                        @documentLoad="onDocumentLoad" 						
                                        :enableTextSearch="true"
                                        :enableHyperlink="true"
                                        :enableMagnification="true"
                                        :enableToolbar="true"
                                        :enableNavigation="true"
                                        :enableThumbnail="false"
                                        :enableBookmark="true"
                                        :enableTextSelection="true"
                                        :enablePrint="true"
                                        :enableAnnotation="true"
                                        :enableFormFields="false"
                                        :enableFormDesigner="false" 
                                        :openFile='false'
                                    >
                                    </ejs-pdfviewer>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="btn_part d-flex justify-content-around">
                                    <button class="comman_brdr_btn">Cancel</button>
                                    <button class="comman_brdr_btn">Print</button>
                                    <button class="comman_btn">Fax</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import axios from "@/scripts/axios.js";
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';
import $ from "jquery";
import {  Toolbar, Magnification, Navigation, LinkAnnotation, 
         BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, 
         Annotation } from '@syncfusion/ej2-vue-pdfviewer';

export default ({
    setup() {
        
    },
    data(){
        return {
            drugList: [],
            drug_search:'',
            prescriptionList:[],
            method_option:[
                { value: "Take", title: "Take" },
                { value: "Apply", title: "Apply" },
                { value: "Rub Well in", title: "Rub Well in" },
            ],
            method_text:'Select',
            route_option:[
                { value: "PO", title: "PO" },
                { value: "IM", title: "IM" },
                { value: "IV", title: "IV" },
                { value: "SC", title: "SC" },
                { value: "Patch", title: "Patch" },
                { value: "Cream", title: "Cream" },
                { value: "Drops", title: "Drops" },
                { value: "INH", title: "INH" },
                { value: "Nasal Medications", title: "Nasal Medications" },
                { value: "Puff", title: "Puff" }
            ],
            route_text:"Select",
            dosage_unit_option:[
                { value: "mL", title: "mL" },
                { value: "mg", title: "mg" },
                { value: "g", title: "g" },
                { value: "ucg", title: "ucg" },
                { value: "capsule", title: "capsule" },
                { value: "tablet", title: "tablet" },
                { value: "teaspoon", title: "teaspoon" },
                { value: "tablespoon", title: "tablespoon" },
                { value: "activation", title: "activation" }
            ],
            dosage_unit_text:"Select",
            frequency_option:[
                { value: "Every Hour", title: "Every Hour" },
                { value: "Every 2 Hours", title: "Every 2 Hours" },
                { value: "Every Morning", title: "Every Morning" },
                { value: "Every Evening", title: "Every Evening" },
                { value: "Every Bedtime", title: "Every Bedtime" },
                { value: "Daily", title: "Daily" },
                { value: "2x Daily", title: "2x Daily" },
                { value: "3x Daily", title: "3x Daily" },
                { value: "4x Daily", title: "4x Daily" },
                { value: "Weekly", title: "Weekly" },
                { value: "2x Weekly", title: "2x Weekly" },
                { value: "Monthly", title: "Monthly" },
                { value: "2x Monthly", title: "2x Monthly" }
            ],
            frequency_text:"Select",
            duration_unit_option:[
                { value: "day (s)", title: "day (s)" },
                { value: "week (s)", title: "week (s)" },
                { value: "month (s)", title: "month (s)" }, 
            ],
            duration_text:"Select",
            current_date: new Date(),
            savingloader:false,
            savingPrintloader:false,
            patient_detail:[],
            pharmacy_detail:[],
            // provider_detail:[]
            documentPath: null,
			resourceUrl: "https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
            sigImgBase64:null,
        }
            
    },
    components: {
        CustomDropDown
    },
    methods:{
        drugSearch(){
            if (this.drug_search && this.drug_search.length > 2) {
				axios.post("/json_autocomplete/medication/list", { term: this.drug_search })
					.then((response) => {
						this.drugList = response.data.data;
					})
			}

			if(!this.drug_search || this.drug_search.length == 0){
				this.drugList = [];
			}
        },
        selectDrug(item){
            this.drug_search = item.substance_name
            item.medication_id = item.id
            this.prescriptionList.push(item)
            this.drug_search = ''
            this.drugList = []
            this.generateNotes(item)
        },
        removeItem(id) {
            const indexToRemove = this.prescriptionList.findIndex(item => item.id === id);

            if (indexToRemove !== -1) {
                this.prescriptionList.splice(indexToRemove, 1);
            }
        },
        save(action='save'){
            let url = ''
            if (this.$route.name == 'PatientEChartPrescriptionView') {
                url = 'patient/prescriptions/update-multiple'
            } else {
                url = 'patient/prescriptions/store-multiple'
            }
            let form = {}
            form.patient_id       = this.$route.params.patient_id
            form.prescriptionList = this.prescriptionList
            if (!this.validateDosage()){
                this.$filters.moshaToast("Please enter dosage", "error");
                return false
            }

            this.changeLoaderStatus(action, true)
            axios.post(url, form)
				.then((response) => {
                    this.changeLoaderStatus(action, false)
					if (response.status == 200) {
                        this.loader = false;
                        this.$filters.moshaToast(response.data.message, "success");
                        if (action == 'save_print') {
                            // this.generatePdf()
                            this.documentPath = response.data.data.patient_prescription_file;
                            console.log(response.data.data.base64_sign)
                            console.log('this.documentPath', this.documentPath);
                            if (this.$route.name != 'PatientEChartPrescriptionView') {
                                this.sigImgBase64 = response.data.data.base64_sign
                            }
                            console.log(this.sigImgBase64)
                            window.$("#prescription-fax-model").modal("show");
                        } else {
                            this.$router.push({ name: 'PatientEChartPrescriptionList', params: {patient_id: this.$route.params.patient_id } });
                        }

                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.changeLoaderStatus(action, false)
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
        },
        documentUrl(documentPath) {
			return `${process.env.VUE_APP_STORAGE_URL}/e-forms/document/view/${documentPath}`;
		},
        calculateNextRefilDate(item){
            if (item.is_long_term || item.repeats >=1) {
                let nextRefillDate = new Date(this.current_date);
                switch (item.duration_unit) {
                    case 'day (s)':
                        nextRefillDate.setDate(nextRefillDate.getDate() + parseInt(item.duration));
                        console.log(nextRefillDate)
                        break;
                    case 'week (s)':
                        nextRefillDate.setDate(nextRefillDate.getDate() + parseInt(item.duration * 7));
                        break;
                    case 'month (s)':
                        nextRefillDate.setMonth(nextRefillDate.getMonth() + parseInt(item.duration));
                        break;
                    default:
                        break;
                }
                const year = nextRefillDate.getFullYear();
                const month = String(nextRefillDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
                const day = String(nextRefillDate.getDate()).padStart(2, '0');

                item.next_refill_date = `${year}-${month}-${day}`;
            } else {
                item.next_refill_date = ''
            }
            this.generateNotes(item)
        },
        handleItemSelected(values) {
            const digit = values.fieldName.match(/^(\d+)\.(.+)/); // Regular expression to match digits at the beginning
            if (digit) {
                this.prescriptionList[parseInt(digit[1])][digit[2]] = values.item.value;
                if (digit[2] == 'duration' || digit[2] == 'duration_unit') {
                    this.calculateNextRefilDate(this.prescriptionList[parseInt(digit[1])])
                }
                this.generateNotes(this.prescriptionList[parseInt(digit[1])])
            }
        },
        validateDosage() {
            for (const item of this.prescriptionList) {
                if (!item.dosage) {
                    // Dosage is not entered for this item
                    return false; // Validation failed
                }
            }
            // Dosage is entered for all items
            return true; // Validation passed
        },
        changeLoaderStatus(action, status){
            if (action == 'save') {
                this.savingloader = status
            } else {
                this.savingPrintloader = status
            }
        },
        generateNotes(item){
            let notes = ''
            if (item.drug_name) {
                notes += item.drug_name
            }
            if (item.method || parseInt(item.dosage) > 0 || item.dosage_unit || item.route || parseInt(item.duration) > 0 || item.duration_unit || item.frequency) {
                notes += "\n"
                if (item.method) {
                    notes += item.method
                }
                if (parseInt(item.dosage) > 0) {
                    notes += " "+parseInt(item.dosage)
                }
                if (item.dosage_unit) {
                    notes += " "+item.dosage_unit
                }
                if (item.route) {
                    notes += " "+item.route
                }
                if (item.frequency) {
                    notes += " "+item.frequency
                }
                if (parseInt(item.duration) > 0) {
                    notes += " for "+parseInt(item.duration)
                }
                if (item.duration_unit) {
                    notes += " "+item.duration_unit
                }
            }
            if (item.quantity && parseInt(item.quantity) > 0) {
                notes += "\nQuantity: "+item.quantity
            }
            if (item.repeats && parseInt(item.repeats) > 0) {
                notes += "\nRepeats: "+item.repeats
            }
            item.notes = notes
        },
        edit() {
            axios.post("patient/prescriptions/retrieve", {id:this.$route.params.id})
                    .then((response) => {
                         if (response.status == 200) {
                              const details = response.data.data;
                              this.prescriptionList = [details];

                         } else {
                              this.$filters.moshaToast(response.data.message, "error");
                         }
                    }).catch(error => {
                         this.loader = false;
                         console.log(error)
                         if (error.response.status === 422) {
                              this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                         } else {
                              this.$filters.moshaToast(error.response.data.message, "error");
                         }
                    });
        },
        cancelFrm(){
            this.$router.push({ name: 'PatientEChartPrescriptionList', params: { patient_id: this.$route.params.patient_id} });
        },
        fetchPatientDetail() {
            let params = { 'id': this.$route.params.patient_id }
			axios.post("patient/detail", params)
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
        // getProvider(){
        //     axios.post("json_list/provider", { type: 'Doctor', 'id':this.form.provider_id })
        //         .then((response) => {
        //         this.provider_detail = response.data.data[0];
        //     })
        // },
        printPreview() {
            const printContent = $(".prescription_html").html();
            // Create a new window for print preview
            const printWindow = window.open('', '_blank');

            // Write the HTML content to the print window
            printWindow.document.open();
            printWindow.document.write(`
            <html>
                <head>
                <title>Print Preview</title>
                </head>
                <body>
                ${printContent}
                </body>
            </html>
            `);
            printWindow.document.close();

            // Call the print function after a short delay to ensure the content is loaded
            setTimeout(() => {
                printWindow.print();
                printWindow.close();
            }, 500);
        },
        cancelPrescription(){
            window.$("#prescription-fax-model").modal("hide");
        },
        getPreferredPharamcy(){
            axios.post("patient/get-preferred-pharamcy", { patient_id: this.$route.params.patient_id })
                .then((response) => {
                this.pharmacy_detail = response.data.data
            })
        },
        generatePdf() {
            axios.post("patient/prescriptions/generate-pdf", { patient_id: this.$route.params.patient_id })
                .then((response) => {
                let detail = response.data.data
                this.documentPath = detail.documentPath
            })
        }
        
    },
    mounted(){
        if (this.$route.name == 'PatientEChartPrescriptionView') {
            this.edit();
        }
        this.fetchPatientDetail()
        // this.getProvider()
        this.getPreferredPharamcy()
    },
    provide: {
		PdfViewer: [ Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation ]
	}
})
</script>
<style scoped>

</style>