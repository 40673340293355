import {
	helpers,
	required,
} from "@vuelidate/validators";

const appointmenttype = {
    namespaced: true,
    state: {
		defaultFormData: {
            name:null,
            duration:null,
            reason:null,            
        },
        form:{},
        validationRules: {
			form: {
				name: {
					required: helpers.withMessage("Please enter type label.", required),
				},
            }
        }
    }
}

export default appointmenttype;
