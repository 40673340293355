import {
	helpers,
	required,
	email,
    maxLength,
    minLength,
    sameAs,
    requiredIf
} from "@vuelidate/validators";

// Custom validator to check for at least one uppercase letter, one lowercase letter, and one digit
const passwordComplexity = helpers.withMessage(
    'Password must contain at least one uppercase letter, one lowercase letter, and one digit',
    value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(value)
);

const noSpaces = helpers.withMessage(
    'Username must not contain spaces.',
    value => !/\s/.test(value)
  );

  const user = {
    namespaced: true,

    state: {
		defaultFormData: {
            id:null,
            user_id:null,
            provider_id:null,
            specialty_code:null,
            specialty_description:null,
            visit_location:'0000 – No Location',
            first_name:null,
            last_name:null,
            roleId:null,
            address:null,
            phone_number:null,
            provincial_billing_no:null,
            group_billing_no:null,
            cpsid:null,
            cnoid:null,
            administration_access:0,
            prescription_access:0,
            billing_province_code:'ON',
            gosecure_email:null,
            gosecure_password:null,
            olis_first_name:null,
            olis_last_name:null,
            olis_provider_role:null,
            one_id:null,
            user_role_name:'Select User Type',
            username:null,
            status:'AC',
            signatureData:null,
            province_code_text:'ON - Ontario',
            specialty_code_text:'Select Specialty Code',
            specialty:'Family Practice & Practice In General',
            email:null,
            pin:null,
            password:null
        },
        form:{
            id:null,
            user_id:null,
            provider_id:null,
            specialty_code:null,
            specialty_description:null,
            visit_location:'0000 – No Location',
            first_name:null,
            last_name:null,
            roleId:null,
            address:null,
            phone_number:null,
            provincial_billing_no:null,
            group_billing_no:null,
            cpsid:null,
            cnoid:null,
            administration_access:0,
            prescription_access:0,
            billing_province_code:'ON',
            gosecure_email:null,
            gosecure_password:null,
            olis_first_name:null,
            olis_last_name:null,
            olis_provider_role:null,
            one_id:null,
            user_role_name:'Select User Type',
            username:null,
            status:'AC',
            signatureData:null,
            province_code_text:'ON - Ontario',
            specialty_code_text:'Select Specialty Code',
            specialty:'Family Practice & Practice In General',
            email:null,
            pin:null,
            password:null
        },
        validationRules: {
			form: {
				roleId: {
					required: helpers.withMessage("Please select user type.", required),
				},
                username:{
                    required: helpers.withMessage("Please enter username.", required),
                    // maxLength:helpers.withMessage("Please enter username max 16 char.", maxLength(16)),
                    minLength:helpers.withMessage("Please enter username atleast 8 char.", minLength(8)),
                    noSpaces
                },
                // password:{
                //     required: helpers.withMessage("Please enter password.", required),
                //     // maxLength:helpers.withMessage("Please enter password max 16 char.", maxLength(16)),
                //     minLength:helpers.withMessage("Please enter password atleast 8 char.", minLength(8)),
                //     passwordComplexity
                // },
                // pin:{
                //     required: helpers.withMessage("Please enter pin.", required),
                //     maxLength:helpers.withMessage("Please enter pin max 4 char.", maxLength(4)),
                //     minLength:helpers.withMessage("Please enter pin atleast 4 char.", minLength(4)),
                    
                // },
                first_name:{
                    required: helpers.withMessage("Please enter first name.", required),
                },
                last_name:{
                    required: helpers.withMessage("Please enter last name.", required),
                },
                email:{
                    required: helpers.withMessage("Please enter email.", required),
                    email: helpers.withMessage("Please enter valid email.", email),
                },
                phone_number:{
                    maxLength:helpers.withMessage("Please enter phone number max 20 char.", maxLength(20)),
                },
                cpsid: {
                    required: helpers.withMessage("Please enter cps id.", requiredIf(function() {
                        return this.$store.state.user.form.roleId == 2 ? true : false;
                    })),
                },
                cnoid: {
                    required: helpers.withMessage("Please enter cno id.", requiredIf(function() {
                        return this.$store.state.user.form.roleId == 3 ? true : false;
                    })),
                },
                billing_province_code:{
                    required: helpers.withMessage("Please select service province", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 || this.$store.state.user.form.roleId == 3) ? true : false;
                    })),
                },
                provincial_billing_no:{
                    required: helpers.withMessage("Please select provincial billing #", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 || this.$store.state.user.form.roleId == 3) ? true : false;
                    })),
                },
                specialty_code:{
                    required: helpers.withMessage("Please select specialty code", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 || this.$store.state.user.form.roleId == 3) ? true : false;
                    })),
                },
                gosecure_email:{
                    required: helpers.withMessage("Please enter go-secure email", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 ) ? true : false;
                    })),
                },
                gosecure_password:{
                    required: helpers.withMessage("Please enter go-secure password", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2) ? true : false;
                    })),
                }
                
            },
            passwordChangeForm:{
                old_password:{
                    required: helpers.withMessage("Please enter password.", required),
                },
                new_password:{
                    required: helpers.withMessage("Please enter password.", required),
                    // maxLength:helpers.withMessage("Please enter password max 16 char.", maxLength(16)),
                    minLength:helpers.withMessage("Please enter password atleast 8 char.", minLength(8)),
                    passwordComplexity
                },
                new_password_confirmation: {
                    sameAsPassword: helpers.withMessage(() => 'Password confirmation does not match.', sameAs('new_password')),
                }
            },
        },
        filter:{

        }
    }
}

export default user;
