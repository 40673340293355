<template>
	<div class="container-fluid">
		<Header></Header>
	</div>
	<div class="content">
		<div class="container-full">
			<section class="content">
				<component :is="$store.state.component">

				</component>
			</section>
		</div>
	</div>
</template>
<script>
import Calendar from '../../components/calendar/Calendar.vue';
import Header from '../Header.vue';
export default {
	components: {
		Header,
		Calendar,
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
}
</script>