<template>
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
		<h3>Patient Vital</h3>
	</div>

    <Datatable dataType="patientVital">
        <template v-slot="slotProps">
            <td>{{ slotProps.item.vital_type }}</td>
            <!-- <td>{{ slotProps.item.measure_date }}</td> -->
            <td>{{ slotProps.item.measure_date }}</td>
            <td>{{ slotProps.item.value }}<slot v-if="slotProps.item.selected_unit">{{slotProps.item.selected_unit}}</slot></td>
            <td>{{ slotProps.item.comment }}</td>
            <td>{{ slotProps.item.measure_date }}</td>
            <td>{{ slotProps.item.formatted_created_at }}</td>
            <td>
                <!-- <button class="btn btn-primary btn-sm me-1" href="javascript::void(0)" @click="editVital(slotProps.item.vital_master_id, slotProps.item.id)" ><i class="fa fa-pencil"></i>&nbsp;Edit</button> -->
                <a href="#" class="btn btn-danger btn-sm" @click="deleteRecord(slotProps.item.id)"> <i class="fa fa-trash-o"></i>&nbsp;Delete </a>
            </td>
        </template>
    </Datatable>

</template>

<script>
// Use the beforeunload event to notify the parent window when it's being closed
window.addEventListener("beforeunload", () => {
    // Notify the parent window that the child window is being closed
    if (window.opener) {
        window.opener.postMessage("Patient Vital window is closing", "*");
    }
});

import Datatable from '../../base/formFields/Datatable.vue';
import axios from "@/scripts/axios.js";

export default {
	components: {
		Datatable,
	},
    methods:{
        deleteRecord(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to revert this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/vital/delete-by-ids";
                    axios.post(url, {'ids':[id], 'patient_id': this.$route.params.patient_id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            location.reload()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });


        },
        editVital(vitalMasterId,id) {
            this.$router.push(`/patient/vital/${this.$route.params.patient_id}/retrieve/`+vitalMasterId+`/`+id);
        }
    },
    mounted() {
        
    },
}
</script>
