<template>
    <div class="row">

        <div class="box">
			<div class="box-body">
				<h3>Billing Code</h3>
            </div>
        </div>

        <div class="box">
			<div class="box-body">
                <InputField title="Billing Code" :maxlength="3" v-model="form.code" :isRequired="true" :errors="v$.form.code.$errors" divClass="col-6"> </InputField>
                <TextArea title="Description" divClass="col-6" v-model="form.description" :rows="4"></TextArea>
                <InputField title="Billing Price" type="number" v-model="form.price" :isRequired="true" :errors="v$.form.price.$errors" divClass="col-6"> </InputField>

                <div class="row">
                    <div class="col-md-2 pt-10">
                        <button class="btn btn-primary text-white" v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_billing_crud_all])" @click="submit">
                            {{saveBtnText}}
                            <img src="images/loader.gif" v-if="loader" />
                        </button>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import InputField from "../../base/formFields/InputField.vue"
import TextArea from "../../base/formFields/TextArea.vue";
import axios from "@/scripts/axios.js";

export default {
    setup() {
		return { v$: useVuelidate() };
	},
    data(){
        return {
            saveBtnText:'Save'
        }
    },
    components: {
		InputField,
        TextArea
	},
    computed: {
        form() {
			return this.$store.state.billing_code_settings.form;
		},
    },
    validations() {
		return this.$store.state.billing_code_settings.validationRules;
	},
    mounted(){
        if (this.$route.params.id) {
            this.saveBtnText='Update'
            this.edit()
        } else {
            this.$store.state.billing_code_settings.form={}
        }
    },
    methods: {
        submit(){
            this.v$.$validate();
            const formName = 'form';
            if (!this.v$.$error) {
                const $this = this
                this.loader = true;

                let url = "/admin/billing-code/save";
                if (this.$route.params.id) {
                    url = "/admin/billing-code/update";
                }

                axios.post(url, this.form)
				.then((response) => {
					if (response.status == 200) {
                        this.loader = false;
                        this.$filters.moshaToast(response.data.message, "success");
                        $this.$router.push(
                                `/administration/billing/code/list`
                            );
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });

            }else {
                for (const [key] of Object.entries(this.v$[formName])) {
                    if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                        const myEl = document.getElementById(key);
                        if (myEl) {
                            this.$smoothScroll({
                                scrollTo: myEl,
                            });
                            break;
                        }
                    }
                }
            }
        },
        edit(){
            let params = { 'id': this.$route.params.id }

			axios.post("/admin/billing-code/retrieve", params)
				.then((response) => {
					if (response.data.status === 200) {
                        this.$store.state.billing_code_settings.form=response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
        }
    }
}
</script>