<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies files_overview">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Files" v-model="form.keyword" @blur="documentSearch" ref="documentSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList"></CustomFilter>
                        <button class="comman_brdr_btn" v-if="this.$route.name === 'InboxDeletedList' && this.selectedItems.length > 0" @click="restoreMultiRecord()"> Restore </button>
                        <button class="comman_brdr_btn" v-if="this.$route.name === 'Inbox' && this.selectedItems.length > 0" @click="deleteMultiRecord()"> Delete </button>
                    </span>
                </div>

            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'received_datetime' }"  @click="changeSort('received_datetime')">Received Date Time <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col"  :class="{ 'active': this.sortBy == 'source_author' }" @click="changeSort('source_author')">From <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'description' }" @click="changeSort('description')">Fax Subject <img src="/images/down-arrow-new.svg" class="img-fluid" ></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'source_type' }" @click="changeSort('source_type')">Type <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.filesList" :key="index" :class="{ 'active': (selectChk[item.id] || selectedItems.includes(item.id) ) }">
                            <td><input class="form-check-input" type="checkbox" :checked="isChecked(item.id)" v-model="selectChk[item.id]" @change="checkSelect(item.id)"></td>
                            <td>{{ item.received_datetime }}</td>
                            <td>{{ item.source_author }}</td>
                            <td>{{ item.description }}</td>
                            <td> {{item.source_type}}</td>
                            <td class="edit_delet_link">
                                <span class="popup_link" @click="edit(item.id)">Edit</span>
                                <span class="popup_link" @click="deleteRecord(item.id)"  v-if="this.$route.name === 'Inbox'">Delete</span>
                                <span class="popup_link" @click="restoreRecord(item.id)"  v-if="this.$route.name === 'InboxDeletedList'">Restore</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>
<script>
import sidebar from './sidebar.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../base/CustomFilter.vue';

export default {
        data() {
            return {
                filesList:[],
                selectedItems:[],
                allItems:[],
                selectChk:[],
                sortBy:'',
                sortOrder:'',
                docTypeList: [],
                filterList:[],
                isShowCustomFilter:false,
                filterOptions:[
                    { value: "patient_document.report_class_id", title: "Document Report Class" },
                    { value: "patient_document.report_sub_class_name", title: "Document Report Sub Class" },
                    { value: "patient_document.description", title: "Fax Subject" },
                    { value: "patient_document.source_author", title: "From" },
                ],
            }
        },
        components: {
            sidebar,
            CustomFilter
        },
        methods:{
            getFilesList(){
                this.$store.state.loader = true;

                let status = 'unlabeled'
                if (this.$route.name === 'InboxDeletedList') {
                    status = 'deleted'
                }

                axios.post("patient/document/list", 
                {
                     patient_id: this.$route.params.patient_id,
                     keyword: this.form.keyword,
                     advanceFilter:this.form.advanceFilter,
                     sort_by: this.sortBy,
                     sort_order:this.sortOrder,
                     status:status
                })
                .then((response) => {
                    this.filesList = response.data.data;
                    this.filesList.forEach(item => {
                        this.allItems.push(item.id)
                    })

                    this.$store.state.loader = false;
                });
            },
            isChecked(id){
                return this.selectedItems.includes(id);
            },
            checkSelectAll(){
                if (this.selectAllChk) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            checkSelect(id){
                if (this.selectChk[id]) {
                    this.selectedItems.push(id)
                } else {
                    const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
                    if (index !== -1) {
                        this.selectedItems.splice(index, 1); // Remove id from selectedItems
                    }
                }

                if (this.selectedItems.length == this.allItems.length) {
                    this.selectAllChk = true;
                } else if (this.selectedItems.length == 0) {
                    this.selectAllChk = false;
                } else {
                    this.selectAllChk = false;
                }
            },
            handleFileChange(event) {
                const selectedFile = event.target.files[0];
                if (selectedFile && selectedFile.type === 'application/pdf') {
                    // Handle the PDF file
                    const formData = new FormData();
                    formData.append('patient_id', this.$route.params.patient_id)
                    formData.append('pdfData', selectedFile);
                    axios.post('patient/document/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                    }).then(response => {
                        let detail = response.data.data
                        this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}/files/retrieve/${detail.id}`);
                    }).catch(error => {
                    // Handle errors
                    console.error('Error uploading file:', error);
                    });
                } else {
                    // Notify the user that only PDF files are allowed
                    this.$filters.moshaToast('Please select a PDF file.', "error");
                }
            },
            edit(id) {
                this.$router.push(`/inbox/retrieve/${id}`);
            },
            documentSearch(){
                this.getFilesList();
            },
            addFilter(){
                if (this.isShowCustomFilter) {
                    this.getFilesList()
                }
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    advanceFilter[item.field] = item.value
                });
                this.form.advanceFilter = advanceFilter
            },
            changeSort(fieldName){
                if (fieldName != this.sortBy) {
                    this.sortOrder = 'desc'
                } else if (this.sortOrder == 'desc') {
                    this.sortOrder = 'asc'
                } else{
                    this.sortOrder = 'desc'
                }

                this.sortBy = fieldName

                this.getFilesList()
            },
            restoreMultiRecord(){
                this.$swal({
					title: 'Are you sure you want to restore records?',
					text: '',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Yes, restore it!',
					cancelButtonText: 'No, cancel please!',
					closeOnConfirm: false,
					closeOnCancel: false,
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/restore";
						axios.post(url, {'ids':this.selectedItems})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.getFilesList();
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
            },
            deleteMultiRecord(){
                this.$swal({
					title: 'Are you sure you want to delete records?',
					text: '',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Yes, delete it!',
					cancelButtonText: 'No, cancel please!',
					closeOnConfirm: false,
					closeOnCancel: false,
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/delete";
						axios.post(url, {'ids':this.selectedItems})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.getFilesList();
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
            },
            handleItemSelected(values){
                console.log(values)

            },
            deleteRecord(id){
				this.$swal({
					title: 'Are you sure you want to delete record?',
					text: 'You can restore this record',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Yes, delete it!',
					cancelButtonText: 'No, cancel please!',
					closeOnConfirm: false,
					closeOnCancel: false,
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/delete";
						axios.post(url, {'ids':[id]})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.getFilesList()
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
			},
            restoreRecord(id){
				this.$swal({
					title: 'Are you sure you want to restore record?',
					text: 'You can restore this record',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Yes, restore it!',
					cancelButtonText: 'No, cancel please!',
					closeOnConfirm: false,
					closeOnCancel: false,
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/restore";
						axios.post(url, {'ids':[id]})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.getFilesList()
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
			},

        },
        mounted(){
            this.getFilesList()
        },
        computed: {
            form() {
                return this.$store.state.consultations.form;
            },
            faxForm(){
                return this.$store.state.consultations.faxForm;
            },
            emailForm(){
                return this.$store.state.consultations.emailForm;
            },
            mailForm(){
                return this.$store.state.consultations.mailForm;
            }
        },
}
</script>
<style scoped>
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f8f8f8;
  border-radius: 4px;
}
</style>