<template>
    <div class="row">
        <div class="box">
            <div class="box-body">
                <div class="row">
                    <div class="col-10">
                        <h3 >Billing Code</h3> 
                    </div>

                    <div class="col-2">
                        <router-link :to="{ name: 'CreateAdminBillingCode'}" class="waves-effect waves-light btn btn-info d-block mb-10 btn-sm" v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_billing_crud_all])">
                        <i class="fa fa-plus fs-14"></i> Create Billing Code
                        </router-link>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <Datatable dataType="billing_code_settings" dataMethod="post" :key="key">
        <template v-slot="slotProps">
            <td>{{slotProps.item.code}}</td>
            <td>{{slotProps.item.description}}</td>
            <td>{{slotProps.item.price}}</td>
            <td>
                <button class="btn btn-primary btn-sm me-1" v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_billing_crud_all])" href="javascript::void(0)" @click="editBillingCode(slotProps.item.id)" ><i class="fa fa-pencil"></i>&nbsp;Edit</button>
            </td>
        </template>
    </Datatable>

</template>

<script>
import Datatable from '../../base/formFields/Datatable.vue';
export default {
    data(){
        return {
        }
    },
    components: {
		Datatable,
	},
    methods: {
        editBillingCode(id){
            this.$router.push(`/administration/billing/code/retrieve/${id}`);   
        }
    }

}
</script>