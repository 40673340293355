<template>
	<div class="content">



        <div class="row">
            <div class="col-2">
                <Sidebar></Sidebar>
            </div>
            <div class="col-10">
                <div class="box">
                    <div class="box-body">
                        <h3>{{this.title}}</h3>
                    </div>
                </div>
                <div class="box">
                    <div class="box-body">
                        <InputField title="First Name" divClass="col-6" v-model="form.first_name" :isRequired="true" :errors="v$.form.first_name.$errors"> </InputField>
                        <InputField title="Last Name" divClass="col-6" v-model="form.last_name" :isRequired="true" :errors="v$.form.last_name.$errors"> </InputField>
                        <InputField title="Specialist Type" divClass="col-6" v-model="form.specialist_type"> </InputField>

                        <TextArea title="Address" divClass="col-6" v-model="form.address" :rows="4"></TextArea>
                        <TextArea title="Annotation" divClass="col-6" v-model="form.annotation" :rows="4"></TextArea>	

                        <InputField title="Email" v-model="form.email" :isRequired="true" :errors="v$.form.email.$errors"> </InputField>
                        <InputField title="Phone" v-model="form.phone" mask="(000) 000-0000"></InputField>
                        <InputField title="Fax" v-model="form.fax" mask="(000) 000-0000"></InputField>

                        <InputField type="url" title="Website" v-model="form.website" placeholder="https://example.com"></InputField>

                        <div class="row">
                            <div class="col-md-2 pt-10">
                                <button class="btn btn-primary text-white" @click="submit">
                                    {{saveBtn}}
                                    <img src="images/loader.gif" v-if="loader" />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>



	</div>
</template>

<script>
import InputField from "../../../base/formFields/InputField.vue"
import useVuelidate from "@vuelidate/core";
import TextArea from "../../../base/formFields/TextArea.vue";
import axios from "@/scripts/axios.js";
import Sidebar from './../Sidebar.vue';

export default {
    setup: () => {
		return { v$: useVuelidate() };
	},
    data(){
        return{
            saveBtn:'Save',
            title:'Create Referral Physicians'
        }
    },
    components: {
        InputField,
        TextArea,
        Sidebar
    },
    mounted() {
        this.resetforms()
        if (this.$route.name === "EditAdminSpecialist") {
            this.edit()
        }
    },
    methods:{
        resetforms(){
			Object.assign(
				this.$store.state.specialist.form,
				this.$store.state.specialist.defaultFormData
			);
        },
        submit(){
            this.v$.$validate();
            const formName = 'form';
			if (!this.v$.$error && !this.confirmerror) {
                this.loader = true
                let url = '/specialists/store'
                if (this.$route.name === "EditAdminSpecialist") {
                    url = '/specialists/update'
                }

                axios.post(url, this.form)
				.then((response) => {
					if (response.status == 200) {
                        this.loader = false;
                        this.$filters.moshaToast(response.data.message, "success");
                        this.$router.push(`/administration/system/specialist/list`);

                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }else {
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
        },
        edit(){
            this.saveBtn='Update';
            this.title='Edit Referral Physicians';

            axios.post("specialists/retrieve", { 'id': this.$route.params.id})
                    .then((response) => {
                        if (response.data.status === 200) {
							var detail = response.data.data;
                            this.$store.state.specialist.form = detail;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error")
                        }
                    }).catch(error => {
                        this.$filters.moshaToast(error.message, "error")
                   
			}).catch(error => {
				if (error.response.status === 422) {
					this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
				} else {
					this.$filters.moshaToast(error.response.data.message, "error");
				}
			});
        }
    },
    computed: {
        form() {
			return this.$store.state.specialist.form;
		},
        vuelidateExternalResults() {
			return this.$store.state.specialist.vuelidateExternalResults;
		},
    },
    validations() {
		return this.$store.state.specialist.validationRules;
	},
    beforeCreate() {
    }
}
</script>