<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies mesurement">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Metric Group" ref="globalSearch"
                            v-model="search_metric_group"
                            @keyup="searchMatricGroup">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList"></CustomFilter>
                    </span>
                </div>
                <div>
                    <button class="comman_btn" @click="createGroup"> Create Group </button>
                </div>
            </div>

            <div class="table-responsive consult-list template">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"></th>
                            <th scope="col" class="active" @click="getVitalGroupOrderBy('name', $event)">Group <img src="/images/down-arrow-new.svg" class="img-fluid">
                            </th>
                            <th scope="col" @click="getVitalGroupOrderBy('included_metrics', $event)">Included Metrics <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody v-if="vitalGroupData.length > 0">
                        <tr v-for="(item, listIndex) in this.vitalGroupData" :key="listIndex">
                            <td></td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.included_metric_names }}</td>
                            <td class="edit_delet_link">
                                <span class="popup_link" @click="editGroup(item.id)">Edit</span>
                                <span class="popup_link" @click="deleteGroup(item.id)">Delete</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal fade" id="create_metric_group" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" @click="closeCreateModel"
                        aria-label="Close"></button>
                    <div class="box">
                        <div>
                            <h2 class="popup_title">Create Metric Group</h2>
                            <div class="custom-form">
                                <div class="input_box">
                                    <input type="text" required="" id="typeunique_idX-2"
                                        class="form-control form-control-lg" v-model="form.name">
                                    <label class="form-label" for="typeunique_idX-2">
                                        Group Name
                                        <small class="asterisksign">*</small>
                                    </label>
                                </div>
                                <hr class="mt-4" style="border: 1px solid #0E2641; opacity: 1;">
                                <div class="add_fild position-relative" v-for="(item, index) in matrixInpObj" :key="index">
                                    <button class="comman_brdr_btn"
                                        v-if="item.is_custom === true">
                                        {{ item.name }}
                                    </button>
                                    <span class="popup_link" v-if="item.is_custom === false"
                                            @click="setCostumeMetric(index)">
                                        Add
                                    </span>
                                    <div class="input_box mt-0"
                                        v-if="item.is_custom === false">
                                        <input type="test" required="" id="typeEmailX-2" class="form-control form-control-lg"
                                            v-model="item.name"
                                            @keyup="vitalSearch(index)">
                                        <label class="form-label" for="typeEmailX-2">
                                            New Metric
                                            <small class="asterisksign">*</small>
                                        </label>
                                    </div>
                                    <div class="search_drop_box new_big_drop" v-if="vitalList.length > 0">
                                        <slot v-for="(item, listIndex) in vitalList" :key="listIndex">
                                            <div class="con_drop_line" @click="selectVital(item.id, item.name)">
                                                <div class="row px-2 py-3 align-items-center">
                                                    <div class="col-md-6">
                                                        <div class="man_haeding">
                                                            <span>{{ item.name }} - ({{ item.unit }})</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </slot>
                                    </div>
                                </div>
                                <div class="plus_sign_row" @click="addNewVitalInput">+</div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeCreateModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="createVitalGroup">
                                <img src="images/loader.gif" v-if="loader" />
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="edit_metric_group" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" @click="closeEditModel"
                        aria-label="Close"></button>
                    <div class="box">
                        <div class="tabs_part">
                            <h2 class="popup_title">Edit Measurement Group</h2>
                            <div class="main_tagline">{{ singleVitalGroupData.name }}</div>
                            <hr class="mt-4" style="border: 1px solid #0E2641;opacity: 1;">
                            <div class="tab_btn" v-for="  (item, index) in this.singleVitalGroupData.included_metric" :key="index">
                                <button class="comman_brdr_btn"> {{ item.name }} </button>
                                <span class="plus_sign_btn" 
                                    v-if="index === (singleVitalGroupData.included_metric.length - 1)"
                                    @click="addNewVitalInput">+</span>
                            </div>
                            <div class="custom-form" v-if="this.matrixInpObj.length > 0">
                                <slot v-for="(item, index) in matrixInpObj" :key="index">
                                    <div class="input_box w-100" v-if="item.is_custom === false">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg"
                                                v-model="item.name"
                                                @keyup="vitalSearch(index)">
                                        <label class="form-label" for="typeunique_idX-2">New Metric</label>
                                    </div>
                                    <div class="d-flex" v-if="item.is_custom === false">
                                        <span class="popup_link"
                                            @click="setCostumeMetric(index)">Add</span>
                                        <span class="popup_link" 
                                            @click="removeInpMatric(index)" >Cancel</span>
                                    </div>
                                </slot>
                                <div class="search_drop_box" v-if="vitalList.length > 0">
                                    <slot v-for="(item, listIndex) in vitalList" :key="listIndex">
                                        <div class="con_drop_line" @click="selectEditVital(item.id, item.name)">
                                            <div class="row px-2 py-3 align-items-center">
                                                <div class="col-md-6">
                                                    <div class="man_haeding">
                                                        <span>{{ item.name }} - ({{ item.unit }})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </slot>
                                </div>
                            </div>
                        </div>

                        <div class="text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeEditModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="updateMetricGroup">
                                <img src="images/loader.gif" v-if="loader" />
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sidebar from '../sidebar.vue';
import CustomFilter from '../../../base/CustomFilter.vue';
import axios from "@/scripts/axios.js";

export default {
    data() {
        return {
            vitalGroupData: [],
            matrixInpObj: [],
            vitalList: [],
            loader: false,
            form: {
                id: null,
                name: '',
                included_metrics: [],
                advanceFilter : {},
            },

            /* Filter Data */
            search_metric_group: '',
            singleVitalGroupData: [],

            isShowCustomFilter: false,
            filterList: [],
            filterOptions: [
                { value: "group_name", title: "Group Name" },
                { value: "vital_matrix", title: "Matrix Name" },
            ],

            orderBy: '',
            orderByColumnName: '',
            onFetchSingalVitalCount: '',

        }
    },
    components: {
        sidebar,
        CustomFilter,
    },
    mounted() {
        this.getVitalGroupList()
    },
    methods: {
        getVitalGroupList() {
            axios.post("vital-group/list", this.form)
            .then((response) => {
                this.vitalGroupData = response.data.data;
            })
            .catch(error => {
                this.loader = false;
                if (error.response.status == 422) {
                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                } else {
                    this.$filters.moshaToast(error.response.data.message, "error");
                }
            });
        },
        addNewVitalInput() {
            if(this.matrixInpObj.length == 0)
            {
                let matrixObj = {
                    id: null,
                    name: null,
                    is_custom: false,
                }
                this.matrixInpObj.push(matrixObj);
            }
            else
            {
                const lastItemIndex = this.matrixInpObj.length - 1;
                if(this.matrixInpObj[lastItemIndex].id != null && this.matrixInpObj[lastItemIndex].name != null)
                {
                    let matrixObj = {
                        id: null,
                        name: null,
                        is_custom: false,
                    }
                    this.matrixInpObj.push(matrixObj);
                }
                else
                {
                    this.$filters.moshaToast("Required To Fill Empty Input Matrix.", "error");
                }
            }
        },
        vitalSearch(index) {
            let search_text = this.matrixInpObj[index].name;
            if (search_text.length > 2) {
                axios.post("json_autocomplete/vital-search", { search_text: search_text })
                    .then((response) => {
                        this.vitalList = response.data.data;
                    })
                    .catch((error) => {
                        this.loader = false;
                        if (error.response.status == 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            }
            if (search_text.length == 0) {
                this.vitalList = [];
            }
        },
        selectVital(id, name) {
            const exists = this.matrixInpObj.some(item => item.id === id && item.name === name);

            if (!exists) {
                const lastItemIndex = this.matrixInpObj.length - 1;
                console.log('lastItemIndex:- ', lastItemIndex);

                this.matrixInpObj[lastItemIndex].id = id;
                this.matrixInpObj[lastItemIndex].name = name;
                this.vitalList = [];
            }
            else 
            {
                this.$filters.moshaToast("These vital is already exist", "error");
            }
        },
        setCostumeMetric(index) {
            if(this.matrixInpObj[index].is_custom === false && this.matrixInpObj[index].id != null && this.matrixInpObj[index].name != null)
            {
                this.matrixInpObj[index].is_custom = true;
                this.singleVitalGroupData.included_metric.push(this.matrixInpObj[index]);
            }
            else
            {
                if(this.matrixInpObj[index].id == null && this.matrixInpObj[index].name == null)
                {
                    this.$filters.moshaToast("Required, To Input Metric Name", "error");
                }
                if(this.matrixInpObj[index].id == null && this.matrixInpObj[index].name != null)
                {
                    this.$filters.moshaToast(this.matrixInpObj[index].name + " Entered, Metric Does Does Not Exist", "error");
                }
            }
        },
        createGroup() {
            window.$("#create_metric_group").modal("show");
        },
        createVitalGroup() {
            if(this.form.included_metrics.length == 0)
            {
                this.matrixInpObj.forEach(item => {
                    if(item.id != null && item.name != null)
                    {
                        this.form.included_metrics.push(item.id);
                    }
                });
            }
            if(this.form.included_metrics.length === 0 || this.form.name == '')
            {
                if(this.form.included_metrics.length === 0)
                {
                    this.$filters.moshaToast("Required To Add Input Matrix.", "error");
                }
                if(this.form.name == '')
                {
                    this.$filters.moshaToast("Required To Fill Matrix Group Name.", "error");
                }
            }
            else
            {
                this.loader = true;
                axios.post("vital-group/store", this.form)
                .then((response) => {
                    if(response.data.status == 200)
                    {
                        console.log('response-data:- ', response);
                        this.loader = false;
                        this.getVitalGroupList();
                        this.resetFormData();
                        this.$filters.moshaToast(response.data.message, "success");
                        window.$("#create_metric_group").modal("hide");
                    }
                })
                .catch(error => {
                    this.loader = false;
                    if (error.response.status == 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }
        },
        editGroup(id) {
            this.retrieveGroup(id);
        },
        resetFormData() {
            this.matrixInpObj = [];
            this.form.name = '';
            this.form.included_metrics = [];
            this.form.advanceFilter = [];
        },
        searchMatricGroup() {
            if(this.search_metric_group.length > 2)
            {
                axios.post("vital-group/list", { search_text: this.search_metric_group })
                .then((response) => {
                    console.log('vital-group-list-data:- ', response);
                    this.vitalGroupData = response.data.data;
                    console.log('this.vitalGroupData', this.vitalGroupData);
                    console.log('this.vitalGroupData.length:-   ', this.vitalGroupData.length);
                })
                .catch(error => {
                    this.loader = false;
                    if (error.response.status == 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }
            if (this.search_metric_group.length == 0) {
                this.vitalGroupData = [];
                this.getVitalGroupList();
            }
        },
        retrieveGroup(id)
        {
            this.loader = true;
            this.form.id = id;
            axios.post("vital-group/retrieve", { id: id })
            .then((response) => {
                if(response.data.status === 200)
                {
                    this.singleVitalGroupData = response.data.data[0];
                    this.form.name = this.singleVitalGroupData.name;

                    let includedMetricsArray = this.singleVitalGroupData.included_metrics.split(',').map(Number);
                    this.onFetchSingalVitalCount = includedMetricsArray.length;
                    window.$("#edit_metric_group").modal("show");
                    this.loader = false;
                }
            })
            .catch(error => {
                this.loader = false;
                if (error.response.status == 422) {
                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                } else {
                    this.$filters.moshaToast(error.response.data.message, "error");
                }
            });
        },
        selectEditVital(id, name) {
            const exists = this.matrixInpObj.some(item => item.id === id && item.name === name);
            const inFetchExists = this.singleVitalGroupData.included_metric.some(item => item.id === id && item.name === name);

            if (!exists && !inFetchExists) {
                const lastItemIndex = this.matrixInpObj.length - 1;
                console.log('lastItemIndex:- ', lastItemIndex);

                this.matrixInpObj[lastItemIndex].id = id;
                this.matrixInpObj[lastItemIndex].name = name;
                this.vitalList = [];
            }
            else 
            {
                this.$filters.moshaToast("These vital metric is already exist", "error");
            }
        },
        removeInpMatric(index)
        {
            this.matrixInpObj.splice(index, 1);
        },
        closeEditModel()
        {
            this.matrixInpObj = [];
            this.form.id = null;
            this.form.name = '';
            this.form.included_metrics = [];
            this.singleVitalGroupData = [];
            window.$("#edit_metric_group").modal("hide");
        },
        closeCreateModel()
        {
            this.matrixInpObj = [{
                id: null,
                name: null,
                is_custom: false,
            }];
            this.form.id = null;
            this.form.name = '';
            this.form.included_metrics = [];
            window.$("#create_metric_group").modal("hide");
        },
        updateMetricGroup()
        {
            let isNotMetricFound = false;
            if(this.form.included_metrics.length == 0)
            {
                this.singleVitalGroupData.included_metric.forEach(item => {
                    if(item.id != null && item.name != null)
                    {
                        this.form.included_metrics.push(item.id);
                    }
                    else
                    {
                        isNotMetricFound = true;
                        if(item.name != null)
                        {
                            this.$filters.moshaToast("Input, Metric Name Does Not Exist.", "error");
                        }
                        else
                        {
                            this.$filters.moshaToast("Required To Add Input Matrix.", "error");
                        }
                    }
                });
            }
            let onUpdateSingalVitalCount = this.form.included_metrics.length;
            if((this.onFetchSingalVitalCount == onUpdateSingalVitalCount))
            {
                this.$filters.moshaToast("Required To Add Input Matrix.", "error");
            }
            else
            {
                if(isNotMetricFound == false)
                {
                    this.loader = true;
                    axios.post("vital-group/update", this.form)
                    .then((response) => {
                        console.log('response-data:- ', response);
                        if(response.data.status == 200)
                        {
                            this.loader = false;
                            this.getVitalGroupList();
                            this.closeEditModel();
                            this.$filters.moshaToast(response.data.message, "success");
                            window.$("#edit_metric_group").modal("hide");
                        }
                    })
                    .catch(error => {
                        this.loader = false;
                        if (error.response.status == 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
                }
            }
        },
        deleteGroup(id)
        {
            this.$swal({
                title: 'Are you sure you want to delete records?',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => { 
                if (result.isConfirmed) {
                    this.$store.state.loader = true;
                    console.log('id:- ', id);
                    axios.post("vital-group/delete", { id: id })
                    .then((response) => {
                        console.log('response-data:- ', response);
                        if(response.data.status == 200)
                        {
                            this.getVitalGroupList();
                            this.$store.state.loader = false;
                            this.$filters.moshaToast(response.data.message, "success");
                        }
                    })
                    .catch(error => {
                        this.loader = false;
                        if (error.response.status == 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
                }
            });
        },
        addFilter(){
            this.isShowCustomFilter = !this.isShowCustomFilter;
            this.getVitalGroupList();
        },
        handleCustomField(values){
            this.filterList = values
            let advanceFilter={}
            values.forEach(item => {
                advanceFilter[item.field] = item.value
            });
            this.form.advanceFilter = advanceFilter
        },
        getVitalGroupOrderBy(orderColumnName, event){
            event.stopPropagation(); 

            const th = event.target;
            this.orderByColumnName = orderColumnName;
            if (th.classList.contains('active')) {
                th.classList.remove('active');
                this.orderBy = 'asc';
            } else {
                th.classList.add('active');
                this.orderBy = 'desc';
            }

            axios.post('vital-group/list', { order_by_column: this.orderByColumnName, order_by: this.orderBy })
            .then((response) => {
                if (response.status == 200) {
                    this.vitalGroupData = response.data.data;
                } else {
                    this.$filters.moshaToast(response.data.message, "error");
                }
            }).catch(error => {
                this.loader = false;
                if (error.response.status === 422) {
                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    console.log(Object.values(error.response.data.data).join(', '))
                } else {
                    this.$filters.moshaToast(error.response.data.message, "error");
                }
            });
        },
        
    },
    computed: {
    }
}
</script>