<template>
	<div class="content">
		<div class="container-full">
            <div class="row">
                <div class="col-2">
                    <Sidebar></Sidebar>
                </div>
                <div class="col-10">
                    <div class="box">
                        <div class="box-body">
                            <div class="row">
                                <div class="col-10">
                                    <div class="row d-flex align-items-center me-auto">
                                        <h3 class="page-title">Referral Physicians</h3>
                                    </div>
                                </div>

                                <div class="col-2">
                                    <button class="waves-effect waves-light btn btn-primary d-block mb-10 btn-sm" href="javascript::void(0)" @click="createSpecialist" ><i class="fa fa-plus fs-14"></i>&nbsp;Create Referral Physicians</button>
                                </div>

                            </div>

                            <div class="row">
                                <Datatable dataType="specialist" dataMethod="post" :key="key">
                                    <template v-slot="slotProps">
                                        <td>{{slotProps.item.full_name}}</td>
                                        <td>{{slotProps.item.specialist_type}}</td>
                                        <td>{{slotProps.item.address}}</td>
                                        <td>{{slotProps.item.phone}}</td>
                                        <td>{{slotProps.item.fax}}</td>
                                        <td>
                                            <a class="btn btn-sm btn-primary me-1" href="#" @click="editRecord(slotProps.item.id)"> <i class="fa fa-pencil"></i>&nbsp;Edit</a>

                                            <a class="btn btn-sm btn-danger" v-if="!slotProps.item.deleted_datetime" href="#" @click="deleteRecord(slotProps.item.id)"> <i class="fa fa-trash"></i>&nbsp;Delete</a>
                                        </td>
                                    </template>    
                                </Datatable>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

		</div>
	</div>
</template>

<script>
import Sidebar from './../Sidebar.vue';
import Datatable from '../../../base/formFields/Datatable.vue';
import axios from "@/scripts/axios.js";

export default {
    data() {
        return {
            key: 0,
            searchKey:0,
            search_drop: true,
        }
    },
    setup() {
        
    },
	components: {
        Sidebar,
        Datatable
	},
	created() {
	},
    methods:{
        applyFilter() {
            this.key++;
            this.search_drop = false;
        },
        resetFilter(){
            // this.filter.provider_id='';
            this.applyFilter()
        },
        deleteRecord(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to revert this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "specialists/delete";
                    axios.post(url, {'id':id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.applyFilter()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });


        },
        createSpecialist(){
            this.$router.push(`/administration/system/specialist/create`);
        },
        editRecord(id){
            this.$router.push(`/administration/system/specialist/retrieve/${id}`);
        }
    }
}
</script>