<template>
	<div :class="divClass">
		<div class="form-group" :class="{ error: errors.length }">
			<label :class="labelClass">{{ title }}
				<small v-show="isRequired" class="asterisksign">*</small>
			</label>
			<div class="controls">
				<div class="syncfusion-component">
					<ejs-datepicker :placeholder="waterMark" :value="$filters.inputDateTime(modelValue)" :format="dateFormat" :openOnFocus="openOnFocus" @change="getdate($event)" :max="maxDate" :min="minDate"></ejs-datepicker>
				</div>
				<span class="form-text text-muted" v-if="notes != ''">{{ notes }}</span>
			</div>
			<div class="error-msg fs-14">
				<ul class="mb-0 p-0">
					<li class="d-block" v-for="(error, index) of errors" :key="index">
						{{ error.$message }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import moment from "moment";
export default {
	components: {
		'ejs-datepicker': DatePickerComponent,
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		modelValue: {
			type: [String,Date],
			default: ""
		},
		errors: {
			type: Array,
			default: () => []
		},
		labelClass: {
			type: String,
			default: "form-label"
		},
		divClass: {
			type: String,
			default: "col-md-6"
		},
		notes: {
			type: String,
			default: ""
		},
		minDate: {
			type: [Date,String],
			default: ""
		},
		maxDate: {
			type: [Date,String],
			default: ""
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		dateFormat: {
			type: String,
			default: 'yyyy-MM-dd'
		},
		waterMark: {
			type: String,
			default: 'Select a date'
		},
		openOnFocus: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		getdate($event) {
			if ($event.value) {
				this.$emit('update:modelValue', moment($event.value).format('YYYY-MM-DD'));
			}
		},
	}
};
</script>