<template>
<aside class="main-sidebar">
    <section class="sidebar position-relative">
        <div class="multinav">
            <div class="multinav-scroll ps ps--active-y" style="height: 100%;">
                <ul class="sidebar-menu tree mt-20" data-widget="tree">
                    <li class="treeview" v-bind:class="(routeNm=='AdminRoleList') ? 'active' : ''">
                       <router-link :to="{ name: 'AdminRoleList'}" v-bind:class="(routeNm=='AdminRoleList') ? 'current' : ''"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Role</router-link>
                    </li>
                    <li class="treeview"  v-bind:class="(routeNm=='AdminDocTypeList') ? 'active' : ''">
                        <router-link :to="{ name: 'AdminDocTypeList'}" v-bind:class="(routeNm=='AdminDocTypeList') ? 'current' : ''"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Document Category</router-link>
                    </li>
                    <li class="treeview" v-bind:class="(routeNm=='AdminSpecialistList') ? 'active' : ''">
                        <router-link :to="{ name: 'AdminSpecialistList'}" v-bind:class="(routeNm=='AdminSpecialistList') ? 'current' : ''"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Referral Physicians</router-link>
                    </li>
                    <li class="treeview" v-bind:class="(routeNm=='AdminServiceList') ? 'active' : ''">
                        <router-link :to="{ name: 'AdminServiceList'}" v-bind:class="(routeNm=='AdminServiceList') ? 'current' : ''"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Services</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</aside>

</template>

<script>
export default {
    data() {
        return {
            routeNm: this.$route.name
        };
    },
    computed: {
        
    },
}
</script>
