<!-- Dropdown.vue -->
<template>
	<div ref="dropdown" class="Selact_drodwn" :class="{ 'Selact_drodwn_upside': isOpenUpSide }">
		<div class="dropdown-div" :class="{ 'disabled': isDisabled }" @click="toggleList">
			<div :class="['form-control caption', divClass, { 'cur_po_arrow': isFromViewInvoice }]">
				<label v-if="labelText" v-html="labelText"></label>
				<span v-html="selectedValue" :class="{ active: isSelectInvoiceType || isSelectInvoiceStatus }"></span>
				<img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': showList }">
			</div>
			<div class="list" v-show="showList">
				<div v-for="(item, index) in options" :key="index" class="item" @click="selectItem(item)"
					v-html="item.title" :class="{ active: this.selectedValue == item.title  }">
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
export default {
	props: {
		options: {
			type: Array,
			required: true,
		},
		initialValue: {
			type: String, // Adjust the type based on your data structure
			default: 'Select', // Default value when not provided
		},
		fieldName: {
			type: String,
			required: true,
		},
		divClass: {
			type: String,
			default: "",
		},
		labelText: {
			type: String,
			default: "",
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		isFromViewInvoice: {
			type: Boolean,
			default: false,
		},
		isOpenUpSide: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedValue: this.initialValue,
			showList: false,
			isSelectInvoiceStatus: false,
			isSelectInvoiceType: false
		};
	},
	methods: {
		toggleList() {
			if (!this.isDisabled) {
				this.showList = !this.showList;
			}
		},
		selectItem(item) {
			if (this.fieldName == 'claim_type') {
				this.selectedValue = item.title.split('|')[0].trim();
			}else if (this.fieldName == 'type') {
				this.isSelectInvoiceType = true;
				this.selectedValue = item.title;
			}else if (this.fieldName == 'status') {
				this.isSelectInvoiceStatus = true;
				this.selectedValue = item.title;
			}else if (this.fieldName == 'roleId') {
				this.isSelectInvoiceStatus = true;
				this.selectedValue = item.title;
			}else {
				this.selectedValue = item.title;
			}
			
			this.showList = false;
			this.$emit('item-selected', { fieldName: this.fieldName, item }); // Pass field name and selected item
			this.$emit('item-change', this.selectedValue);
			this.toggleList()
		},
		handleClickOutside(event) {
			const dropdownEle = this.$refs.dropdown;
				if (dropdownEle && !dropdownEle.contains(event.target)) {
					// console.log('Clicked outside');
					this.showList = false
				}
				//  else {
				// 	console.log('Clicked inside');
				// 	// this.showList = true
				// }
			},
	},
	watch: {
		initialValue(newValue) {
			if(newValue){
				this.selectedValue = newValue;
			}
		},
	},
	mounted(){
		document.addEventListener('click', this.handleClickOutside);
	}
};
</script>