<template>
	<div class="content">
		<div class="container-full">
			<component :is="$store.state.component">

			</component>
		</div>
	</div>
</template>

<script>
import AdminSpecialistList from '../../components/administrator/system/specialist/list.vue';
import CreateAdminSpecialist from '../../components/administrator/system/specialist/form.vue';
import EditAdminSpecialist from '../../components/administrator/system/specialist/form.vue';
import AdminDocTypeList from '../../components/administrator/system/document-type/list.vue';
import CreateAdminDocType from '../../components/administrator/system/document-type/form.vue';
import EditAdminDocType from '../../components/administrator/system/document-type/form.vue';
import AdminRoleList from '../../components/administrator/system/role/list.vue';
import CreateAdminRole from '../../components/administrator/system/role/form.vue';
import EditAdminRole from '../../components/administrator/system/role/form.vue';
import AdminServiceList from '../../components/administrator/system/service/list.vue';
import CreateService from '../../components/administrator/system/service/form.vue';
import  EditService from '../../components/administrator/system/service/form.vue';

export default {
	components: {
		AdminSpecialistList,
		CreateAdminSpecialist,
		EditAdminSpecialist,
		EditAdminDocType,
		CreateAdminDocType,
		AdminDocTypeList,
		AdminRoleList,
		CreateAdminRole,
		EditAdminRole,
		AdminServiceList,
		CreateService,
		EditService,
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
}
</script>