<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 px-0">
			<div class="admin-left-content account_seeting">
				<div class="d-flex align-items-center justify-content-between">
					<h1 class="h1-admin_head mb-0">Username & Password Settings</h1>
					<button class="comman_btn crt-user-bttn big_btn_new" @click="createUser" v-if="this.checkPermission()">Create User</button>
				</div>
				<div class="mt-30">
					<div class="table-responsive acct-stng-tbl">
						<table class="table table-borderless text-center cursor-alias mb-0">
							<thead>
								<tr>
									<th style="width: 130px;">USER ID</th>
									<th>USERNAME</th>
									<th>LAST NAME</th>
									<th>FIRST NAME</th>
									<th>USER TYPE</th>
									<th>ACTIVITY</th>
									<th style="width: 115px;"></th>
								</tr>
							</thead>
							<tbody v-if="userList.length">
								<tr v-for="(item,index) in userList" :key="index">
									<td>{{ item.user_id }}</td>
									<td>{{ item.username }}</td>
									<td>{{ item.last_name }}</td>
									<td>{{ item.first_name }}</td>
									<td>{{ item.role }}</td>
									<td>{{ item.status_text }}</td>
									<td><img src="/images/dots-icon.svg" class="dot_icon" :class="{ 'invisible': !this.checkPermission() && (item.user_id!=this.authUserId) }" @click="this.editUser(item.user_id)"></td>
								</tr>

							</tbody>
						</table>
					</div>
				</div>
				<div class="text-end" v-if="this.lastpage!=1">
					<ul class="page-pagination p-0">
						<li style="margin-right: 40px;"><img src="/images/left-arrow-pagen.svg" class="img-fluid" @click="fetchUserList(this.currentPage - 1 )" v-if="this.currentPage != 1"></li>
						<li>{{ this.currentPage }}</li>
						<li>/</li>
						<li>{{ this.lastpage }}</li>
						<li style="margin-left: 40px;"><img src="/images/right-arrow-pagen.svg" class="img-fluid" @click="fetchUserList(this.currentPage + 1 )" v-if="this.currentPage != this.lastpage"></li>
					</ul>
				</div>
				
			</div>
		</div>
	</div>
	<div class="modal fade" id="create-user" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl crtusr-modal">
			<div class="modal-content manage_cmn_pup">
				<div class="modal-body adminuser-slide">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="arrow_img left-arrow-slide" v-if="this.isShowPrevIcon">
						<img src="/images/down-arrow_l.svg" class="img-fluid" @click="switchPrevTab">
					</div>
					<div class="box">
						<div>
							<ul class="comn_tab_menu">
								<li :class="{ 'active': this.isShowLogin }" @click="changeActiveTab('login_info')">
									<span class="step-tabs-user">1</span> Login Information</li>
								<li :class="{ 'active': this.isShowContact }" @click="changeActiveTab('contact_info')">
									<span class="step-tabs-user">2</span> Contact Information</li>
								<li :class="{ 'active': this.isShowBilling }" @click="changeActiveTab('billing_info')">
									<span class="step-tabs-user">3</span> Billing Information</li>
							</ul>
							<div class="login-info-cont" v-if="this.isShowLogin">
								<div class="left-specs">
									<div class="d-flex align-items-center mb-28">
										<div class="lft-ttl-de">
											<p class="mb-0 cursor-alias">USER TYPE </p>
										</div>
										<div class="admn-usr-selct-box position-relative">
											<CustomDropDown :options="user_role_opt" :initialValue="form.user_role_name" @item-selected="handleItemSelected" fieldName="roleId" :isDisabled="this.form.id"></CustomDropDown>
											<ValidationErrorMessageList :errors="v$.form.roleId.$errors" />
										</div>
									</div>
								</div>
								<div class="left-specs">
									<div class="d-flex mb-40">
										<div class="lft-ttl-de">
											<p class="mb-0 cursor-alias mt-7">USERNAME</p>
										</div>
										<div class="login-username-inp position-relative">
											<input type="text" class="form-control cursor-alias" v-model="form.username" @change="checkUsername">
											<div class="position-absolute l-h-n">
												<ValidationErrorMessageList :errors="v$.form.username.$errors" />
												<div class="error-msg" v-if="this.userNameAvailbilityErrorMsg">
													<ul class="mb-0 p-0 fs-14"><li class="d-block text-danger">{{ this.userNameAvailbilityErrorMsg }}</li></ul>
												</div>
												<div class="success-msg" v-if="this.userNameAvailbilitySuccessMsg">
													<ul class="mb-0 p-0 fs-14"><li class="d-block text-success">{{ this.userNameAvailbilitySuccessMsg }}</li></ul>
												</div>
											</div>
										</div>
										<div>
											<p class="mb-0 lst-8-chract cursor-alias mt-7">at least 8 characters</p>
										</div>
									</div>
								</div>
								<div class="mb-30 position-relative">
									<div class="d-flex align-items-center">
										<p class="change-pass-pin cursor-pointer mb-0" @click="this.changePassModelShow" v-if="this.form.id">Change Password</p>
										<div class="d-flex align-items-center">
											<div class="lft-ttl-de">
												<p class="mb-0 cursor-alias">PASSWORD</p>
											</div>
											<div class="usr-dtls-inp psw-inpt-fill position-relative" :class="{ 'psw-ipt-disabled': this.form.id }">
												<input :type="inputPasswordType" class="form-control cursor-alias height-25" v-model="form.password" @input="this.passwordValidate" :disabled="this.form.id">
												<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPasswordEnc" @click="changePasEle('enc')">
												<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePasEle('desc')">
											</div>
											<div>
												<p class="mb-0 lst-8-chract cursor-alias" :class="{ 'text-danger': this.passwordValidationErrorMsg && this.passwordValidationErrorMsg != 'Password must contain at least one uppercase letter, one lowercase letter, and one digit' }">at least 8 characters</p>
											</div>
										</div>
									</div>
									<div class="validtn-sect ml-20 position-relative">
										<p class="accs-evr-usr-tagline mb-0 cursor-alias" :class="{ 'text-danger': this.passwordValidationErrorMsg == 'Password must contain at least one uppercase letter, one lowercase letter, and one digit' }">(Must include 1 upper-case, 1 lower-case, and 1 number)</p>
										<!-- <div class="error-msg cursor-alias position-absolute" v-if="this.passwordValidationErrorMsg"><ul class="mb-0 p-0 fs-14"><li class="d-block text-danger">{{ this.passwordValidationErrorMsg }}</li></ul></div> -->

									</div>
								</div>
								<div class="d-flex align-items-center mb-40 position-relative">
									<p class="change-pass-pin cursor-pointer mb-0" @click="this.changePinModelShow" v-if="this.form.id">Change Pin</p>
									<div class="d-flex align-items-center">
										<div class="lft-ttl-de">
											<p class="mb-0 cursor-alias">PIN</p>
										</div>
										<div class="usr-dtls-inp position-relative " :class="{ 'psw-ipt-disabled': this.form.id }">
											<input :type="inputPinType" class="form-control cursor-alias height-25" v-model="form.pin" @input="this.pinValidate" :disabled="this.form.id">
											<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPinEnc" @click="changePinEle('enc')">
											<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePinEle('desc')">
											<!-- <ValidationErrorMessageList :errors="v$.form.pin.$errors" /> -->
											<!-- <div class="error-msg cursor-alias position-absolute" v-if="this.pinValidationErrorMsg"><ul class="mb-0 p-0 fs-14"><li class="d-block text-danger">{{ this.pinValidationErrorMsg }}</li></ul></div> -->
										</div>
										<div>
											<p class="mb-0 lst-8-chract cursor-alias" :class="{ 'text-danger': this.pinValidationErrorMsg }">at least 4 characters</p>
										</div>
									</div>
								</div>
								<div class="left-specs">
									<div class="accse-sect-infom">
										<div class="d-flex align-items-center stts-actv-div">
											<div class="lft-ttl-bigs">
												<p class="mb-0 cursor-alias">STATUS</p>
											</div>
											<div class="usr-dtls-switch">
												<div class="acv-switch-btn l-h-n d-flex align-items-center">
													<input class='input-switch' type="checkbox" :checked="form.status === 'AC'" id="user_status" @change="changeStatus" :disabled="!this.checkPermission()"/>
													<label class="label-switch" for="user_status"></label>
													<span class="info-text">{{ displayStatus }}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="left-specs">
									<div class="mb-30">
										<div class="d-flex align-items-center">
											<div class="lft-ttl-bigs">
												<p class="mb-0 cursor-alias">ADMINISTRATION ACCESS</p>
											</div>
											<div class="usr-dtls-switch accs-swtch">
												<label class="switch d-block">
													<input type="checkbox" id="administration_access_status" :checked="this.form.administration_access==1" :disabled="!this.checkPermission()" @change="changeAdminStatus">
													<span class="sw-slider"></span>
												</label>
											</div>
										</div>
										<div class="ml-20">
											<p class="accs-evr-usr-tagline cursor-alias mb-0">(Access to every user / management settings, Payment Preferences, Audit Logs. Suggest Admin only)</p>
										</div>
									</div>
								</div>
								<div class="left-specs">
									<div class="d-flex align-items-center">
										<div class="lft-ttl-bigs">
											<p class="mb-0 cursor-alias">PRESCRIPTION ACCESS</p>
										</div>
										<div class="usr-dtls-switch accs-swtch">
											<label class="switch d-block">
												<input type="checkbox" id="prescription_access_status" :checked="this.form.prescription_access==1" :disabled="!this.checkPermission()" @change="changePresStatus">
												<span class="sw-slider"></span>
											</label>
										</div>
									</div>
									<div class="ml-20">
										<p class="accs-evr-usr-tagline cursor-alias mb-0">(Ability to create, update, delete prescriptions. Suggest Doctor / Nurse only)</p>
									</div>
								</div>
							</div>

							<div class="contact-info-cont" v-if="this.isShowContact">
								<div class="ctct-details-fill">
									<div class="d-flex align-items-center mb-35">
										<div class="contact-frm-label">
											<p class="mb-0 cursor-alias">FIRST NAME</p>
										</div>
										<div class="contact-dtls-inp position-relative">
											<input type="text" v-model="form.first_name" class="form-control cursor-alias">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.first_name.$errors" />
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="contact-frm-label">
											<p class="mb-0 cursor-alias">LAST NAME</p>
										</div>
										<div class="contact-dtls-inp position-relative">
											<input type="text" v-model="form.last_name" class="form-control cursor-alias">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.last_name.$errors" />
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="contact-frm-label">
											<p class="mb-0 cursor-alias">PHONE NUMBER</p>
										</div>
										<div class="contact-dtls-inp position-relative">
											<input type="text" v-model="form.phone_number" class="form-control cursor-alias">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.phone_number.$errors" />
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="contact-frm-label">
											<p class="mb-0 cursor-alias">EMAIL</p>
										</div>
										<div class="contact-dtls-inp position-relative">
											<input type="text" v-model="form.email" class="form-control cursor-alias">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.email.$errors" />
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-40">
										<div class="contact-frm-label">
											<p class="mb-0 cursor-alias">ADDRESS</p>
										</div>
										<div class="contact-dtls-inp">
											<input type="text" v-model="form.address" class="form-control cursor-alias">
										</div>
									</div>
									<div class="d-flex">
										<div class="contact-frm-label">
											<p class="mb-0 cursor-alias">SIGNATURE</p>
										</div>
										<div class="contact-dtls-inp d-flex align-items-end">
											<div class="signature-div d-flex justify-content-center align-items-center">
												<ejs-signature id="signature" ref="signatureObj" width="430" height="115" @change="onDraw"></ejs-signature>
											</div>
											<p class="signclear-link text-underline cursor-alias" @click="clearSignature">clear</p>
										</div>
									</div>
									
								</div>
								
								<p class="signtags mb-0 cursor-alias">This signature will be used in paperwork such as Files or Templates for Doctors & Nurses 

									<span class="d-block text-danger mt-2" v-if="this.signatureValidationMsg">{{ this.signatureValidationMsg }}</span>
								</p>
							</div>

							<div class="billing-info-cont" :class="{ 'disabled-form': !this.isBillingVisible }" v-if="this.isShowBilling">
								<div class="blng-from-sect">
									<div class="d-inline-block w-100">
										<div class="d-flex align-items-center">
											<div class="d-flex align-items-center mb-35" style="max-width: 530px;float: left;margin-right: 70px;">
												<div class="billing-info-label">
													<p class="mb-0">CPS ID / CNO ID <span class="asterisk-red">*</span></p>
												</div>
												<div class="billing-dtls-inp w-100 position-relative">
													<input type="text" class="form-control cursor-alias" v-model="form.cpsid" v-if="form.roleId==2">
													<input type="text" class="form-control cursor-alias" v-model="form.cnoid" v-else>
													<div class="position-absolute">
														<ValidationErrorMessageList :errors="v$.form.cpsid.$errors" v-if="form.roleId==2"/>
														<ValidationErrorMessageList :errors="v$.form.cnoid.$errors" v-else />
													</div>
												</div>
											</div>
											<div class="d-flex align-items-center mb-35" style="max-width: 530px;float: left;">
												<div class="billing-info-label" style="min-width: 210px;">
													<p class="mb-0">SERVICE PROVINCE <span class="asterisk-red">*</span></p>
												</div>
												<div class="service-pro-selct-box">
													<CustomDropDown :options="stateData"
													:initialValue="form.province_code_text"
													v-model="form.billing_province_code" @item-selected="handleItemSelected"
													fieldName="billing_province_code"></CustomDropDown>
													<ValidationErrorMessageList :errors="v$.form.billing_province_code.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
												</div>
											</div>
										</div>
									</div>
									<div class="d-inline-block w-100">
										<div class="d-flex align-items-center mb-30" style="max-width: 530px;float: left;margin-right: 70px">
											<div class="billing-info-label">
												<p class="mb-0">PROVINCIAL BILLING # <span class="asterisk-red">*</span></p>
											</div>
											<div class="billing-dtls-inp w-100 position-relative">
												<input type="text" class="form-control cursor-alias" v-model="form.provincial_billing_no">
												<div class="position-absolute">
													<ValidationErrorMessageList :errors="v$.form.provincial_billing_no.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
												</div>
											</div>
										</div>										
										<div class="d-flex align-items-center mb-30" style="max-width: 480px;float: left;">
											<div class="billing-info-label" style="min-width: 210px;">
												<p class="mb-0">GROUP BILLING # </p>
											</div>
											<div class="billing-dtls-inp w-100">
												<input type="text" class="form-control cursor-alias" v-model="form.group_billing_no">
											</div>
										</div>
									</div>
								</div>
								<div class="blng-from-second">
									<div class="d-flex align-items-center mb-30">
										<div class="billing-info-admin-label">
											<p class="mb-0">VISIT LOCATION</p>
										</div>
										<div class="billing-admin-inp w-100">
											<input type="text" class="form-control cursor-alias" v-model="form.visit_location">
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="billing-info-admin-label">
											<p class="mb-0">SPECIALTY CODE <span class="asterisk-red">*</span></p>
										</div>
										<div class="specialty-code-selct-box w-100 position-relative">
											<CustomDropDown :options="specialityCodes"
												:initialValue="form.specialty_code_text"
												v-model="form.specialty_code" @item-selected="handleItemSelected"
												fieldName="specialty_code"></CustomDropDown>
												<div class="position-absolute">
													<ValidationErrorMessageList :errors="v$.form.specialty_code.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
												</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="billing-info-admin-label">
											<p class="mb-0">GO-SECURE EMAIL <span class="asterisk-red">*</span></p>
										</div>
										<div class="billing-admin-inp w-100 position-relative">
											<input type="text" class="form-control cursor-alias" v-model="form.gosecure_email">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.gosecure_email.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="billing-info-admin-label">
											<p class="mb-0">GO-SECURE PASSWORD <span class="asterisk-red">*</span></p>
										</div>
										<div class="go-secure-psw-inpt w-100 position-relative">
											<input :type="inputGoSecurePasswordType" class="form-control cursor-alias" v-model="form.gosecure_password">
											<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="this.inputGoSecurePasswordType!='password'" @click="changeGoSecurePasEle('enc')">
											<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changeGoSecurePasEle('desc')">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.gosecure_password.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="billing-info-admin-label">
											<p class="mb-0">OLIS FIRST NAME</p>
										</div>
										<div class="billing-admin-inp w-100">
											<input type="text" class="form-control cursor-alias" v-model="form.olis_first_name">
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="billing-info-admin-label">
											<p class="mb-0">OLIS LAST NAME</p>
										</div>
										<div class="billing-admin-inp w-100">
											<input type="text" class="form-control cursor-alias" v-model="form.olis_last_name">
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="billing-info-admin-label">
											<p class="mb-0">OLIS PROVIDER ROLE</p>
										</div>
										<div class="billing-admin-inp w-100">
											<input type="text" class="form-control cursor-alias" v-model="form.olis_provider_role">
										</div>
									</div>
									<div class="d-flex align-items-center">
										<div class="billing-info-admin-label">
											<p class="mb-0">ONEID</p>
										</div>
										<div class="billing-admin-inp w-100">
											<input type="text" class="form-control cursor-alias" v-model="form.one_id">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn mx40" v-if="this.isShowBtn" @click="cancelModel">Cancel</button>
							<button class="comman_btn big_btn mx40" v-if="this.isShowBtn" @click="save">Save <img src="images/loader.gif" v-if="this.loader" /></button>
						</div>
					</div>
					<div class="arrow_img right-arrow-slide" v-if="this.isShowNextIcon">
						<img src="/images/down-arrow_r_new.svg" class="img-fluid" @click="switchNextTab">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade main_popup" id="change-password-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl chng-password_model">
			<div class="modal-content manage_cmn_pup ">
				<div class="modal-body adminuser-slide">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="password-new-update">
							<div class="d-flex align-items-center old-password-row">
								<p class="mb-0 password-label cursor-alias">OLD PASSWORD</p>
								<div class="change-sicur-pass position-relative">
									<input :type="inputPasswordType" class="form-control cursor-alias height-25" v-model="form.password">
									<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPasswordEnc" @click="changePasEle('enc')">
									<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePasEle('desc')">
								</div>
							</div>
							<div class="new-password-row">
								<div class="d-flex align-items-center">
									<p class="mb-0 password-label cursor-alias">NEW PASSWORD</p>
									<div class="change-sicur-pass position-relative">
										<input :type="inputPasswordType" class="form-control cursor-alias height-25" v-model="form.password">
										<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPasswordEnc" @click="changePasEle('enc')">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePasEle('desc')">
									</div>
								</div>
								<p class="accs-evr-usr-tagline cursor-alias mb-0">(At least 8 characters. Must include 1 upper-case, 1 lower-case, and 1 number)</p>
							</div>
							<div class="confirm-password-row">
								<div class="d-flex align-items-center">
									<p class="mb-0 password-label cursor-alias">CONFIRM NEW PASSWORD</p>
									<div class="change-sicur-pass position-relative">
										<input :type="inputPasswordType" class="form-control cursor-alias height-25" v-model="form.password">
										<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPasswordEnc" @click="changePasEle('enc')">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePasEle('desc')">
									</div>
								</div>
							</div>
							<p class="forgot-psswd-link mb-0 cursor-alias text-end">Forgot Password?</p>
						</div>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn mx40" @click="cancelModel">Cancel</button>
							<button class="comman_btn big_btn mx40" @click="save">Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade main_popup" id="change-pin-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl chng-pin_model">
			<div class="modal-content manage_cmn_pup ">
				<div class="modal-body adminuser-slide">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="password-new-update">
							<div class="d-flex align-items-center old-password-row">
								<p class="mb-0 pin-label cursor-alias">OLD PIN</p>
								<div class="change-sicur-pass position-relative">
									<input :type="inputPasswordType" class="form-control cursor-alias" v-model="form.password">
									<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPasswordEnc" @click="changePasEle('enc')">
									<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePasEle('desc')">
								</div>
							</div>
							<div class="new-pin-row">
								<div class="d-flex align-items-center">
									<p class="mb-0 pin-label cursor-alias">NEW PIN</p>
									<div class="change-sicur-pass position-relative">
										<input :type="inputPasswordType" class="form-control cursor-alias" v-model="form.password">
										<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPasswordEnc" @click="changePasEle('enc')">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePasEle('desc')">
									</div>
								</div>
								<p class="accs-evr-usr-tagline cursor-alias mb-0">(At least 8 characters. Must include 1 upper-case, 1 lower-case, and 1 number)</p>
							</div>
							<div class="confirm-password-row">
								<div class="d-flex align-items-center">
									<p class="mb-0 pin-label cursor-alias">CONFIRM NEW PIN</p>
									<div class="change-sicur-pass position-relative">
										<input :type="inputPasswordType" class="form-control cursor-alias" v-model="form.password">
										<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPasswordEnc" @click="changePasEle('enc')">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePasEle('desc')">
									</div>
								</div>
							</div>
							<p class="forgot-psswd-link mb-0 cursor-alias text-end">Forgot Password?</p>
						</div>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn mx40" @click="cancelModel">Cancel</button>
							<button class="comman_btn big_btn mx40" @click="save">Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import sidebar from './sidebar.vue'
	import axios from "@/scripts/axios.js";
	import CustomDropDown from '../base/formFields/CustomDropDown.vue';
	import useVuelidate from "@vuelidate/core";
	import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';
	import { SignatureComponent } from "@syncfusion/ej2-vue-inputs";

	export default {
		setup: () => {
			return { v$: useVuelidate() };
		},
		data(){
			return{
				isShowLogin:true,
				isShowContact:false,
				isShowBilling:false,
				userList:[],
				currentPage:1,
				lastpage:1,
				user_role_opt:[
					{ value: 1, title: "Administrator" },
					{ value: 2, title: "Doctor" },
					{ value: 3, title: "Nurse" },
					{ value: 4, title: "Staff" },
				],
				isPasswordEnc:true,
				isPinEnc:true,
				inputPasswordType:'password',
				inputPinType:'password',
				isShowBtn:false,
				isBillingVisible:false,
				isShowPrevIcon:false,
				isShowNextIcon:true,
				passwordValidationErrorMsg:null,
				pinValidationErrorMsg:null,
				loader:false,
				stateData:[{ value: "AB", title: "AB - Alberta" },{ value: "BC", title: "BC - British Columbia" },{ value: "MB", title: "MB - Manitoba" },{ value: "NB", title: "NB - New Brunswick" },{ value: "NL", title: "NL - Newfoundland" },{ value: "NS", title: "NS - Nova Scotia" },{ value: "ON", title: "ON - Ontario" },{ value: "PE", title: "PE - Prince Edward Island" },{ value: "SK", title: "SK - Saskatchewan" },{ value: "NT", title: "NT - Northwest Territories" },{ value: "NU", title: "NU - Nunavut" },{ value: "YT", title: "YT - Yukon" },
				],
				specialityCodes:[],
				inputGoSecurePasswordType:"password",
				userNameAvailbilityErrorMsg:null,
				userNameAvailbilitySuccessMsg:null,
				permissionList:[],
				authUserId:null,
				signatureImage: null,
				isImageLoaded:true,
				isSignatureValidate:false,
				signatureValidationMsg:null
			}
		},
		components:{
			sidebar,
			CustomDropDown,
			ValidationErrorMessageList,
			"ejs-signature": SignatureComponent,
		},
		methods: {
			createUser(){
				this.isImageLoaded = true
				this.resetForm()
				if ([1,4].includes(this.form.roleId)) {
					this.isBillingVisible = false
				} else {
					this.isBillingVisible = true
				}
				window.$("#create-user").modal("show")
				this.changeActiveTab('contact_info')
				setTimeout(() => {
					this.clearSignature()
				}, 50);
				setTimeout(() => {
					this.changeActiveTab('login_info')
				}, 100);
			},
			editUser(id){
				this.resetForm()
				this.edit(id)
				this.isShowBtn = true
			},
			changeActiveTab(type){
				this.isShowBtn = false
				this.isShowLogin = false
				this.isShowContact = false
				this.isShowBilling = false
				if (type == 'login_info'){
					this.isShowLogin = true
					this.isShowPrevIcon = false
					this.isShowNextIcon = true
				}else if (type == 'contact_info'){
					this.isShowContact = true
					this.isShowPrevIcon = true
					this.isShowNextIcon = true

					if (this.form.id && !this.isImageLoaded) {
						setTimeout(() => {
							this.openSignature()
							this.isImageLoaded = true
						}, 200);
					} else {
						setTimeout(() => {
							this.openSignature()
						}, 200);
					}
				}if (type == 'billing_info'){
					this.isShowBilling = true
					this.isShowBtn = true
					this.isShowPrevIcon = true
					this.isShowNextIcon = false					
				}
				if (this.form.id) {
					this.isShowBtn = true
				}
			},
			fetchUserList(pageNo=1){
				if (pageNo <= 1) {
					pageNo = 1
				}

				let params = {
					'page':pageNo
				}
				this.$store.state.loader = true;
				axios.post('user/list', params)
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						let responseDetail = response.data.data;
						this.userList = responseDetail.data;
						this.currentPage = responseDetail.current_page
						this.lastpage = responseDetail.last_page
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
			},
			handleItemSelected(values) {
				this.form[values.fieldName] = values.item.value
				if (values.fieldName == 'roleId') {
					this.form['user_role_name'] = values.item.title
					if ([1,4].includes(this.form.roleId)) {
						this.isBillingVisible = false
						this.isSignatureValidate = false
						this.signatureValidationMsg = null
					} else {
						this.isBillingVisible = true
					}
				} else if(values.fieldName == "billing_province_code"){
					this.form.province_code_text = values.item.title
				}
			},
			changePasEle(action) {
				if (this.form.id) {
					return false
				}
				if (action == 'enc') {
					this.isPasswordEnc = true
					this.inputPasswordType = 'password'
				} else {
					this.isPasswordEnc = false
					this.inputPasswordType = 'text'
				}
			},
			changePinEle(action) {
				if (this.form.id) {
					return false
				}
				if (action == 'enc') {
					this.isPinEnc = true
					this.inputPinType = 'password'
				} else {
					this.isPinEnc = false
					this.inputPinType = 'text'
				}

			},
			resetForm(){
				Object.assign(
					this.$store.state.user.form,
					this.$store.state.user.defaultFormData,
				);
				this.v$.$reset();
				this.changeActiveTab('login_info')
				this.pinValidationErrorMsg = null
				this.passwordValidationErrorMsg = null
				this.userNameAvailbilityErrorMsg = null
				this.userNameAvailbilitySuccessMsg = null
				
			},
			cancelModel(){
				this.resetForm()
				window.$("#create-user").modal("hide")
			},
			async save(){

				const $this = this
				let formName = 'form'
				let isValidate = true
				this.v$[formName].$validate();
				isValidate = !this.v$[formName].$error
				if (!this.form.id) {
					this.passwordValidate()
					this.pinValidate()
				}			
				
				if ((this.form.roleId == 2 || this.form.roleId == 3) && !this.isSignatureValidate) {
					this.signatureValidationMsg = "Signature Required";
					this.changeActiveTab('contact_info');
					return false
				}
				
				if (isValidate) {
					this.loader = true
					const form = this.form;

					let url
					if (this.form.id) {
						url = "user/update";
					} else {
						url = "user/store";
					}

					axios.post(url, form,  {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
						})
						.then((response) => {
							if (response.status == 200) {
								this.loader = false
								$this.$filters.moshaToast(response.data.message, "success")
								$this.cancelModel();
								$this.fetchUserList(this.currentPage)
							} else {
								this.loader = false
								this.$filters.moshaToast(response.data.message, "error")
							}
						}).catch(error => {
							this.loader = false
							if (error.response.status === 422) {
								this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
								console.log(Object.values(error.response.data.data).join(', '))
							} else {
								this.$filters.moshaToast(error.response.data.message, "error");
							}
						})
				} else {
					let validateform = null;
					console.log(Object.entries(this.v$[formName]))
					for (const [key] of Object.entries(this.v$[formName])) {
						if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
							if (['roleId', 'username', 'password', 'pin'].includes(key)){
								validateform = 'login_info'
							} else if (validateform != 'login_info' && ['first_name', 'last_name', 'email'].includes(key)){
								if (this.passwordValidationErrorMsg || this.pinValidationErrorMsg) {
									validateform = 'login_info'
								} else {
									validateform = 'contact_info'
								}
							}

							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
					if (validateform) {
						this.changeActiveTab(validateform)
					}
				}
			},
			edit(id){
				this.isImageLoaded = false
				this.$store.state.loader = true;
				let url = "user/retrieve";

				axios.post(url, {id:id})
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						let detail = response.data.data
                        this.$store.state.user.form = response.data.data;
						detail.user_role_name = (this.user_role_opt.find(item => item.value === detail.roleId) || {}).title || "Select User Type";
						this.form.specialty_code_text = (this.specialityCodes.find(item => item.value == detail.specialty_code) || {}).title || "Select Speciality Code";
						this.form.province_code_text = (this.stateData.find(item => item.value == detail.billing_province_code) || {}).title || "Select Service Province";

						this.signatureImage = detail.base64_sign
						this.onDraw()
						
						if ([1,4].includes(this.form.roleId)) {
							this.isBillingVisible = false
						} else {
							this.isBillingVisible = true
						}
						window.$("#create-user").modal("show");

                    }
                }).catch(error => {
					this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
			},
			switchPrevTab(){
				if (this.isShowBilling) {
					this.changeActiveTab('contact_info')
				} else {
					this.changeActiveTab('login_info')
				}
			},
			switchNextTab(){
				if (this.isShowLogin) {
					this.changeActiveTab('contact_info')
				} else {
					this.changeActiveTab('billing_info')
				}
			},
			changePassModelShow(){
				window.$("#change-password-model").modal("show")
			},
			changePinModelShow(){
				window.$("#change-pin-model").modal("show")
			},
			changeStatus(event){
				this.form.status = event.target.checked ? 'AC' : 'IN';
			},
			changeAdminStatus(event){
				this.form.administration_access = event.target.checked ? 1 : 0;
			},
			changePresStatus(event){
				this.form.prescription_access = event.target.checked ? 1 : 0;
			},
			clearSignature(){
				this.$refs.signatureObj.clear();
				this.onDraw()
			},
			passwordValidate(){
				if (!this.form.password) {
					this.passwordValidationErrorMsg = 'Please enter password.'
					return false
				} else {
					if (this.form.password.length < 8) {
						this.passwordValidationErrorMsg = 'Please enter password atleast 8 char.'
						return false
					}

					const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\s]+$/;
					if(!pattern.test(this.form.password)){
						this.passwordValidationErrorMsg = 'Password must contain at least one uppercase letter, one lowercase letter, and one digit'
						return false
					}

				}

				this.passwordValidationErrorMsg = ''
				return true
			},
			pinValidate(){
				if (!this.form.pin) {
					this.pinValidationErrorMsg = 'Please enter pin.'
					return false
				} else {
					if (this.form.pin.length < 4) {
						this.pinValidationErrorMsg = 'Please enter pin atleast 4 char.'
						return false
					}
					// if (this.form.pin.length > 4) {
					// 	this.pinValidationErrorMsg = 'Please enter pin max 4 char.'
					// 	return false
					// }
					// if (!this.checkIfDigitsOnly(this.form.pin)) {
					// 	this.pinValidationErrorMsg = 'Please enter pin digit only.'
					// 	return false
					// }

				}

				this.pinValidationErrorMsg = ''
				return true
			},
			checkIfDigitsOnly(value) {
				// Regular expression to match only digits
				const digitPattern = /^\d+$/;
				
				// Check if the value consists only of digits
				return digitPattern.test(value);
			},
			async onDraw(){
				// Get a reference to the ejs-signature component
				const signatureComponent = this.$refs.signatureObj;

				// Check if the ref is defined
				if (signatureComponent) {
					// Save the signature data as a Blob
					const signatureData = signatureComponent.saveAsBlob();
					console.log(signatureData);

					this.signatureValidationMsg = null
					if (signatureData.size <= 1224) {
						this.isSignatureValidate = false
					} else {
						this.isSignatureValidate = true
					}

					const signatureBase64 = await this.blobToBase64(signatureData);
					this.signatureImage = signatureBase64;
					console.log(signatureBase64);


					// Update local form data
					this.form.signatureData = signatureData;
				} else {
					console.error("Signature component is not mounted or ref is not set");
				}
			},
			blobToBase64(blob) {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						resolve(reader.result);
					};
					reader.onerror = reject;
					reader.readAsDataURL(blob);
				});
			},
			fetchSpecialityCodes(){
				axios.get("json_autocomplete/speciality-code?is_front_format=1")
						.then((response) => {
							this.specialityCodes = response.data.data;

					})
			},
			changeGoSecurePasEle(action){
				if (action == 'enc') {
					this.inputGoSecurePasswordType = 'password'
				} else {
					this.inputGoSecurePasswordType = 'text'
				}
			},
			checkUsername(){
				this.userNameAvailbilityErrorMsg = this.userNameAvailbilitySuccessMsg = null
				if (!this.form.username.includes(' ') && this.form.username.length > 7) {
					axios.post('user/check-username-availbility', {'username':this.form.username, 'user_id':this.form.user_id})
					.then((response) => {
						this.$store.state.loader = false;
						if (response.status == 200) {
							this.userNameAvailbilitySuccessMsg = response.data.message
						} else {
							this.userNameAvailbilityErrorMsg = response.data.message
						}
					}).catch(error => {
						this.$store.state.loader = false;
						if (error.response.status === 422) {
							console.log(Object.values(error.response.data.data).join(', '))
						} else {
							this.userNameAvailbilityErrorMsg = error.response.data.message
						}
					});
				}
			},
			getPermissionList(){
                axios.post('user/get-permission')
                .then((response) => {
                    if (response.status == 200) {
                        this.permissionList = response.data.data
						console.log(this.permissionList)
                    } else {
                        this.permissionList = []
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.permissionList = []
                    } else {
                        this.permissionList = []
                    }
                });
            },
            checkPermission(){
                if (this.permissionList && this.permissionList.administration_access) {
                    return true
                } else {
                    return false
                }
            },
			openSignature() {
				try {
					const signatureComponent = this.$refs.signatureObj;
					if (signatureComponent) {
						const ej2Instance = signatureComponent.ej2Instances;
						if (ej2Instance) {
							if (this.signatureImage) {
								ej2Instance.load(this.signatureImage);
								this.errorMessage = null;  // Clear error message if successful
								this.isImageLoaded = true
							} else {
								this.isImageLoaded = false
								this.errorMessage = 'No signature to load.';
								console.log("outside if3")
							}
						} else {
							this.isImageLoaded = false
							console.log("outside if2")
						}
					} else {
						this.isImageLoaded = false
						console.log("outside if")
					}
				} catch (error) {
					this.errorMessage = 'Failed to load signature.';
					console.error(error);
				}
			},
			
		},
		mounted(){
			this.authUserId = localStorage.getItem("userId")
			this.getPermissionList()
			this.fetchSpecialityCodes()
			this.fetchUserList()
		},
		computed: {
			form() {
				return this.$store.state.user.form;
			},
			displayStatus() {
				return this.form.status === 'AC' ? 'Active' : 'Inactive';
			}
		},
		validations() {
			return this.$store.state.user.validationRules; 
		},
	};
</script>