import {
	helpers,
	required,
	// email,
} from "@vuelidate/validators";

const customSpecialist = {
	namespaced: true,

	state: {		
		defaultFormData: {
			is_preferred_specialist: false,
			name: '',
			specialist_type: null,
			phone: null,
			fax: null,
			address: null,
			city: null,
			province: null,
			zip: null,
			id: null,
		},
		form: {
			keyword:'',
			advanceFilter:[]
		},
		validationRules: {
			form: {
				name: {
					required: helpers.withMessage("Please Enter Name.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {
				
			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};


export default customSpecialist;
