<template>
    <div class="row mb-1">
        <div class="col-md-6">
            <CustomDropDown :options="options" :initialValue="this.localInitialValue" @item-selected="handleItemSelected" :fieldName="this.fieldName" :selectedOpt="selectedOpt"></CustomDropDown>
        </div>
        <div class="col-md-6 ps-0">
            <div class="position-relative">
                <input type="text" class="form-control" @input="handleInputChange" v-model="this.localFilterValue">
                <i class="fa-solid fa-xmark" @click="this.removeItem"></i>
            </div>
        </div>
    </div>

</template>

<script>
import CustomDropDown from '../base/formFields/CustomDropDown.vue';

export default {
    data() {
        return {
            localInitialValue:this.initialValue,
            localFilterValue:this.initialValue
        }
  },
  props: {
    options: {
        type: Array,
        required: true,
    },
    fieldName:{
        type: String,
        required: true,
    },
    initialValue:{
        type: String,
        required: true,  
    },
    selectedOpt:{
        type: Array,
        required: true, 
    }
  },
  components:{
    CustomDropDown
  },
  methods:{
    handleItemSelected({ fieldName, item }) {
        item.field = fieldName
        this.$emit('array-updated', item);
    },
    handleInputChange(event){
        const newValue = event.target.value;
        this.$emit('text-updated', {'field':this.fieldName, 'value':newValue});
    },
    removeItem(){
        // this.localFilterValue = ''
        this.$emit('text-updated', {'field':this.fieldName, 'value':''});
        this.$emit('remove-item')
        
    }
  },
  created(){
    if (this.selectedOpt[this.fieldName] && this.selectedOpt[this.fieldName]['field']) {
        const foundObject = this.options.find(item => item.value === this.selectedOpt[this.fieldName]['field']);
        if (foundObject) {
            this.localInitialValue=foundObject.title
            let obj = {value: this.selectedOpt[this.fieldName]['field'], title: foundObject.title, field: this.fieldName}
            this.handleItemSelected({ fieldName: this.fieldName, item: obj });
            this.$emit('text-updated', {'field':this.fieldName, 'value':this.selectedOpt[this.fieldName]['value']});
        }
    }

    if (this.selectedOpt[this.fieldName] && this.selectedOpt[this.fieldName]['value']) {
        this.localFilterValue=this.selectedOpt[this.fieldName]['value']
    }

  },

}

</script>