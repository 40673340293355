<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" @keyup="specialistSearch" class="form-control" placeholder="Search Specilalist" v-model="specialist_search" @blur="specialistSearch" ref="globalSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList"></CustomFilter>
                    </span>
                </div>
                <button class="comman_btn" @click="add"> Add Specialist </button>
            </div>
            <div class="table-responsive specialist-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="hover_unset w-30px"></th>
                            <th scope="row" @click="getSpecialistOrderBy('specialist_type', $event)" class="active">Service Type <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="row" @click="getSpecialistOrderBy('name', $event)">Specialist <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="row" @click="getSpecialistOrderBy('address', $event)">Address <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="row" @click="getSpecialistOrderBy('phone', $event)">Phone <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="row" @click="getSpecialistOrderBy('fax', $event)">Fax <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="row" @click="getSpecialistOrderBy('notes', $event)">Note <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="row" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.allCustomSpecialist" :key="index" :class="{ 'active': item.is_preferred_specialist === 1 }">
                            <td></td>
                            <td>{{ item.specialist_type }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.address }}</td>
                            <td>{{ item.phone }}</td>
                            <td>{{ item.fax }}</td>
                            <td>{{ item.notes }}</td>
                            <td><img src="/images/dots-icon.svg" class="dot_icon" @click="editSpecialist(item.id)"></td>
                        </tr>
                        <!-- <tr>
                            <td>Cardiology</td>
                            <td>Adam, David</td>
                            <td>11 Newton Dr., Toronto, ON, L4A 3Y1</td>
                            <td>647-777-7777</td>
                            <td>647-777-7777</td>
                            <td>Auto Populated </td>
                            <td><img src="/images/dots-icon.svg" class="dot_icon" @click="edit"></td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="add-specialist-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
                    <div class="box">
						<h2 class="popup_title">{{ this.popupHeadingText }}</h2>
						<div class="address_line">
                            <label class="text">Set this specialist as preferred </label>
                            <input class="form-check-input m-0 align-middle" type="checkbox" id="checkboxNoLabel" v-model="form.is_preferred_specialist">
                        </div>
                        <div class="custom-form">
                            <div class="input_box">
                                <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.name">
                                <label class="form-label" for="typeunique_idX-2">Specialist Name</label>
                                <ValidationErrorMessageList :errors="v$.form.name.$errors" />
                            </div>
                            <div class="input_box">
                                <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.specialist_type">
                                <label class="form-label" for="typeunique_idX-2">Specialist Service Type</label>
                            </div>
                            <div class="input_box">
                                <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.phone">
                                <label class="form-label" for="typeunique_idX-2">Phone Number</label>
                            </div>
                            <div class="input_box">
                                <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.fax">
                                <label class="form-label" for="typeunique_idX-2">Fax Number</label>
                            </div>
                            <div class="input_box">
                                <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.address">
                                <label class="form-label" for="typeunique_idX-2">Address</label>
                            </div>
                            <div class="input_box">
                                <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.city">
                                <label class="form-label" for="typeunique_idX-2">City</label>
                            </div>
                            <div class="input_box">
                                <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="specialistFormProp.province">
                                <label class="form-label" for="typeunique_idX-2">Province</label>
                            </div>
                            <div class="input_box">
                                <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="specialistFormProp.zip">
                                <label class="form-label" for="typeunique_idX-2">Postal Code</label>
                            </div>
                        </div>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeModal()"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="submitSpecialistForm">{{ this.buttonText }}
                                <img src="images/loader.gif" v-if="loader" />
                            </button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import sidebar from './sidebar.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../../base/CustomFilter.vue';
import ValidationErrorMessageList from '../../base/ValidationErrorMessageList.vue';
import useVuelidate from "@vuelidate/core";

export default {
        data() {
            return {
                allCustomSpecialist: [],
                specialistFormProp: {
                    is_preferred_specialist: false,
                    name: '',
                    specialist_type: null,
                    phone: null,
                    fax: null,
                    address: null,
                    city: null,
                    province: null,
                    zip: null,
                    patient_id: this.$route.params.patient_id,
                    id: null,
                },
                loader: false,
                action: "Add",
                buttonText: "Add",
                popupHeadingText: "Add New Specialist",
                specialist_search: '',

                filterOptions:[
                    { value: "name", title: "Name" },
                    { value: "specialist_type", title: "Specialist Type" },
                ],
                isShowCustomFilter: false,
                filterList:[],
            }
        },
        components: {
            sidebar,
            CustomFilter,
            ValidationErrorMessageList
        },
        setup: () => {
            return { v$: useVuelidate() };
        },
        validations() {
            return this.$store.state.customSpecialist.validationRules;
        },
        methods:{
            handleClickOutside() {
                // this.specialist_search = '';
                // this.getAllCustomSpecialist();
            },
            getSpecialistOrderBy(orderColumnName, event) {
                event.stopPropagation();

                const th = event.target;
                this.form.orderByColumnName = orderColumnName;
                if (th.classList.contains('active')) {
                    th.classList.remove('active');
                    this.form.orderBy = 'asc';
                } else {
                    th.classList.add('active');
                    this.form.orderBy = 'desc';
                }
                this.getAllCustomSpecialist();
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
                this.getAllCustomSpecialist();
            },
            handleCustomField(values){
                this.filterList = values;
                let advanceFilter = {};
                values.forEach(item => {
                    advanceFilter[item.field] = item.value;
                });
                this.specialistFormProp.advanceFilter = advanceFilter;
            },
            specialistSearch()
            {
                if(this.specialist_search.length > 2)
                {
                    this.getAllCustomSpecialist();
                }
            },
            closeModal() {
                this.action = "Add";
                this.buttonText = "Add";
                this.popupHeadingText = "Add New Specialist";

                this.id = null;
                this.form.is_preferred_specialist = false;
                this.form.name = '';
                this.form.specialist_type = null;
                this.form.phone = null;
                this.form.fax = null;
                this.form.address = null;
                this.form.city = null;
                this.form.province = null;
                this.form.zip = null;
                window.$("#add-specialist-model").modal("hide");
            },
            capitalizeData(data) {
                return data.map(item => ({
                    ...item,
                    name: item.name ? this.$filters.capitalizeFirstLetter(item.name) : null,
                    specialist_type: item.specialist_type ? this.$filters.capitalizeFirstLetter(item.specialist_type) : null,
                }));
            },
            submitSpecialistForm() {
                if(this.action == 'Add')
                    {
                        this.v$.$validate();
                        if (!this.v$.$error) {
                            this.loader = true;
                            axios.post("custom-specialists/store", this.form)
                                .then((response) => {
                                    if (response.status == 200) {
                                        
                                        this.getAllCustomSpecialist();                                    
                                        this.loader = false;
                                        window.$("#add-specialist-model").modal("hide");

                                        this.form.is_preferred_specialist = false;
                                        this.form.name = '';
                                        this.form.specialist_type = null;
                                        this.form.phone = null;
                                        this.form.fax = null;
                                        this.form.address = null;
                                        this.form.city = null;
                                        this.form.province = null;
                                        this.form.zip = null;

                                        this.$filters.moshaToast(response.data.message, "success");

                                    } else {
                                        this.$filters.moshaToast(response.data.message, "error");
                                    }
                                }).catch(error => {
                                    this.loader = false;
                                    if (error.response.status === 422) {
                                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                                        console.log(Object.values(error.response.data.data).join(', '))
                                    } else {
                                        this.$filters.moshaToast(error.response.data.message, "error");
                                    }
                                });
                        }
                        else
                        {
                            for (const [key] of Object.entries(this.v$.form)) {
                                if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
                                    const myEl = document.getElementById(key);
                                    if (myEl) {
                                        this.$smoothScroll({
                                            scrollTo: myEl,
                                        });
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    else
                    {
                        this.v$.$validate();
                        if (!this.v$.$error) {
                            this.loader = true;
                            axios.post('custom-specialists/update', this.form)
                            .then((response) => {
                                if (response.status == 200) {
                                    this.getAllCustomSpecialist();
                                    this.loader = false;
                                    this.$filters.moshaToast(response.data.message, "success");
                                    
                                    this.form.id = null;
                                    this.form.is_preferred_specialist = false;
                                    this.form.name = '';
                                    this.form.specialist_type = null;
                                    this.form.phone = null;
                                    this.form.fax = null;
                                    this.form.address = null;
                                    this.form.city = null;
                                    this.form.province = null;
                                    this.form.zip = null;

                                    this.buttonText = "Add";
                                    this.action = "Add";
                                    this.popupHeadingText = "Add New Specialist";
                                    window.$("#add-specialist-model").modal("hide");
                                } else {
                                    console.log('submit-note-error-response:- ', response);
                                    this.$filters.moshaToast(response.data.message, "error");
                                }
                            }).catch(error => {
                                this.loader = false;
                                if (error.response.status === 422) {
                                    console.log('submit-note-catch-error-response:- ', error);
                                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                                    console.log(Object.values(error.response.data.data).join(', '))
                                } else {
                                    console.log('submit-note-else-catch-error-response:- ', error);
                                    this.$filters.moshaToast(error.response.data.message, "error");
                                }
                            });
                        }
                        else
                        {
                            for (const [key] of Object.entries(this.v$.form)) {
                                if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
                                    const myEl = document.getElementById(key);
                                    if (myEl) {
                                        this.$smoothScroll({
                                            scrollTo: myEl,
                                        });
                                        break;
                                    }
                                }
                            }
                        }
                    }
            },
            editSpecialist(specialistId) {
                this.v$.$reset();
                this.loader = true;
                axios.post("custom-specialists/retrieve", { patient_id: this.$route.params.patient_id, id: specialistId })
                .then((response) => {
                    if (response.status == 200) {
                        console.log('custom-specialists:- ', response.data.data);
                        this.loader = false;
                        this.popupHeadingText = "Specialist Details";
                        this.buttonText = "Update";
                        this.action = "Update";
                        const editResponseData = response.data.data;
                        
                        this.form.id = editResponseData.id;
                        this.form.is_preferred_specialist = (editResponseData.is_preferred_specialist == 1)? true : false;
                        this.form.name = editResponseData.name;
                        this.form.specialist_type = editResponseData.specialist_type;
                        this.form.phone = editResponseData.phone;
                        this.form.fax = editResponseData.fax;
                        this.form.address = editResponseData.address;
                        this.form.city = editResponseData.city;
                        this.form.province = editResponseData.province;
                        this.form.zip = editResponseData.zip;
                        window.$("#add-specialist-model").modal("show");

                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            getAllCustomSpecialist() {
                this.form.specialist_search = this.specialist_search;
                axios.post("custom-specialists/list", this.form )
                .then((response) => {
                    if (response.status == 200) {
                        this.allCustomSpecialist = this.capitalizeData(response.data.data);
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            add(){
                window.$("#add-specialist-model").modal("show");
            }, 
            edit(){
                window.$("#add-specialist-model").modal("show");
            }
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside);
            this.getAllCustomSpecialist();
        },
        computed: {
            form() {
                return this.$store.state.customSpecialist.form;
            },
        }
}
</script>