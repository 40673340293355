<template>

  <div class="col-2 d-flex flex-column p-0 users" >
    <div class="box">
      <div class="box-header">
        <ul class="nav nav-tabs customtab nav-justified" role="tablist">
            <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#chatWidget" role="tab" aria-selected="true">Chat </a> </li>
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#groupWidget" role="tab" aria-selected="false">Group</a> </li>
        </ul>
      </div>
       <div class="box-body">
            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane active" id="chatWidget" role="tabpanel">
                <div class="chat-box-one-side3" style="overflow: hidden; width: auto; height: 685px;">
                  <div class="media-list media-list-hover">
                    <div class="media py-10 px-0 align-items-center" v-for="user in channelUsers" :key="user.id"  :id="user.id">
                     
                      <a class="avatar avatar-lg " :class="{'status-success': user.is_online, 'status-danger': !user.is_online}" href="#">
                        <!-- <img :src="user?.avatar || avatar1" width="38" height="38" alt="avatar" /> -->
                         <i :class="{'online-icon': user.is_online, 'offline-icon': !user.is_online}" class="fa fa-user"></i>
                      </a>

                      <div class="media-body">
                        <p class="fs-16">
                        <a
                          href="#"
                          class="hover-primary"
                          :id="user.id"
                          :class="{ current: user.id == currentUserIdVal && chatType =='user'}"
                          @click="selectuser"
                        >
                          {{ user.full_name }}
                         
                        </a>
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="groupWidget" role="tabpanel">
                <div class="box-header no-border">
									<h4 class="box-title">
										Group
									</h4>
                   <a href="#" class="btn btn-primary btn-sm float-right" data-bs-toggle="modal" data-bs-target="#groupModal" @click="this.$emit('modalOpen', 'groupModal');"><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Create Group</a>
								</div>

                <div class="chat-box-one-side3" style="overflow: hidden; width: auto;">
                  <div class="media-list media-list-hover">
                    <div class="media">
                      <div class="media-body">
                        <p v-for="groupDetail in groupsNames" :key="groupDetail.id">
                          <a
                            :id="groupDetail.id"
                            href="#"
                            class="hover-primary"
                            :class="{ current: groupDetail.id == currentUserIdVal && chatType =='group'}"
                            @click="selectGroup(groupDetail.id,groupDetail.group_name)"
                          >
                            <span class="fw-bold">{{ groupDetail.group_name }}</span>
                          </a>
                          <span class="float-end fs-10" >
                            <a class="btn btn-primary btn-sm" href="javascript::void(0)" data-bs-toggle="modal" data-bs-target="#groupModal" @click="this.$emit('modalOpen', 'EditGroup', groupDetail.id);" >Edit</a>
                          </span>
                        </p>
                      </div>
                    </div>

                  </div>
                </div>


              </div>

            </div>
       </div>


    

    <hr/>

    </div>
  </div> 


</template>
  
  <script>
//   import { inject } from '@vue/runtime-core';
//   import avatar1 from '../assets/avatar1.svg';
import $ from "jquery";
import axios from "@/scripts/axios.js";

  export default {
    props: ['currentUserVal', 'currentUserIdVal', 'chatType', 'groupsNames'],
    name: 'Users',
    data() {
		return {
            channelUsers:[],
        }
    },
    mounted() {
        this.fetchProviderData();
        this.$emit('fetchGroupData');
    },
    methods: {
        fetchProviderData(){
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                'Content-Type': 'application/json', // Adjust this as needed for your API
            };

            axios.post("json_list/provider",{'roleId':''}, headers)
                .then((response) => {
                    const tempUserData = []
                    $.each(response.data.data, function(key, detail) {
                      if (localStorage.getItem("userId") != detail.user_id) {
                        let fullName = detail.full_name
                        if (detail.userName != null) {
                          fullName += ' ('+detail.userName+')'
                        }
                        tempUserData.push({
                          'id':detail.user_id,
                          'full_name':fullName,
                          'is_online':detail.is_online
                        })
                      }
                    });
                    this.channelUsers = tempUserData

                    if (this.currentUserIdVal == '') {
                      this.setCurrentUser(this.channelUsers[0]['id'], this.channelUsers[0]['full_name'])
                    }
            })

        },
        setCurrentUser(userId, userName, type='user') {
          this.$emit('updateUserIdValue', userId);
          this.$emit('updateUserValue', userName);
          this.$emit('updateChatType', type);
          this.$emit('fetchMessages', userId);
        },
        selectuser(e){
          let id = e.target.closest('a').id;
          this.setCurrentUser(id, e.target.closest('a').text)
          this.scrollToBottom()
        },
        selectGroup(id, grpName) {
          this.setCurrentUser(id, grpName, 'group')
          this.scrollToBottom()
        },
        scrollToBottom() {
          setTimeout(() => {
            $(".message-container")[0].scrollTop = $(".message-container")[0].scrollHeight;
          }, 10);
        }

    }

  };
  </script>

<style scoped>
.users {
  background: var(--white2);
}

a:hover {
  background: var(--purple1hover);
  color: var(--white1);
  border-radius: 5px;
}

.profile span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.current {
    border-color: #ccc;
    background-color: #ddd;
    text-align: left;
  }

.online-icon {
  color: green; /* Use an appropriate color for online status */
  margin-left: 5px; /* Add some spacing */
}

.offline-icon {
  color: red; /* Use an appropriate color for offline status */
  margin-left: 5px; /* Add some spacing */
}
@media screen and (max-width: 968px) {
  .users {
    position: absolute;
    top: 0;
    right: 0;
    width: 16rem;
    height: 100vh;
    filter: drop-shadow(-5px 0 20px #aaaaaa);
    z-index: 2;
  }

  .users.hide {
    display: none !important;
  }

}
</style>