<template>
    <div class="row">
         <div class="col-12">
             <div class="box">
                 <div class="box-header">
                     <h4 class="box-title">Enrollment History</h4>  
                 </div>

                 <div class="box-body">
                    <div class="col-4">
                        <label class="form-label">Patient</label>
                        <div class="syncfusion-component">
                            <ejs-dropdownlist divClass="col-4" id="patient_id" placeholder='Select a Patient' v-model="form.patient_id" :showClearButton="true" :allowFiltering='allowFiltering' :dataSource='dataSource' :fields='fields' :filtering="onFilter" @change="selectPatient" :value="form.patient_id"></ejs-dropdownlist>
                        </div>
                        <div class="error-msg" v-if="v$.form.patient_id.$errors.length > 0">
                            <ul class="mb-0 p-0 fs-14">
                                <li class="d-block" v-for="(error, index) of v$.form.patient_id.$errors" :key="index">
                                    {{ error.$message }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <SelectBoxStatic divClass="col-4" title="Provider" v-model="form.provider_id" :option="providerList" :isRequired="true" :errors="v$.form.provider_id.$errors"></SelectBoxStatic>

                    <DatePicker title="Enrollment Start Date" divClass="col-4" :maxDate="currentDate" v-model="form.enrolment_start_date" :isRequired="true" :errors="v$.form.enrolment_start_date.$errors"></DatePicker>
                    <DatePicker title="Enrollment Termination Date" divClass="col-4" :maxDate="currentDate" v-model="form.enrolment_termination_date"></DatePicker>

                    <SelectBoxStatic divClass="col-4" title="Termination Reason" v-model="form.termination_reason" :option="termination_reason_option"></SelectBoxStatic>

                    <SelectBoxStatic divClass="col-4" title="Status" v-model="form.enrolment_status" :option="statusList" :isRequired="true" :errors="v$.form.enrolment_status.$errors"></SelectBoxStatic>

                    <div class="row">
                        <button class="btn btn-sm btn-primary text-white me-1 col-1"  @click="save">
                            {{this.saveBtnText}}
                            <img src="images/loader.gif" v-if="loader" />
                        </button>
                        <button class="btn btn-sm btn-warning text-white me-1 col-1"  @click="cancelBtn">
                            Cancel
                        </button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import DatePicker from "../../base/formFields/DatePicker.vue";
import $ from "jquery";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
	data() {
		return {
            loader:false,
            saveBtnText:'Save',
            currentDate: new Date(),
            providerList:[],
            allowFiltering: true,
            dataSource : [],
            fields : { text: "title", value: "value" },
            searchText:'',
            minSearchLength:2,
            statusList:[
                {'title':'Enrolled', 'value':'1'},
                {'title':'Not Enrolled', 'value':'0'}
            ],
            termination_reason_option:[]
        }
    },
    components: {
        DatePicker,
        SelectBoxStatic,
        'ejs-dropdownlist': DropDownListComponent,
    },
    computed: {
		form() {
			return this.$store.state.enrollment_history.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.enrollment_history.vuelidateExternalResults;
		},
    },
    validations() {
		return this.$store.state.enrollment_history.validationRules;
	},
    mounted(){
        this.getPatientList()
        if (this.$route.params.id) {
            this.saveBtnText='Update';
            this.edit();
        } else {
            // this.resetforms()
        }
        this.fetchProviderData()
        this.fetchTerminationReasonData();

    },
    methods:{
        save() {
            const formName = 'form';
			this.v$.$validate();
			if (!this.v$.$error) {
                // const $this = this
                this.loader = true;

                this.form.user_id = localStorage.getItem("userId")
                let url = `/patient/provider-status/update`;
                axios.post(url, this.form)
				.then((response) => {
					if (response.status == 200) {
                        this.loader = false;
                        this.$filters.moshaToast(response.data.message, "success");
                        this.$router.push(`/patient/enrollment-history/${this.$route.params.patient_id}`);

                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }else {
                for (const [key] of Object.entries(this.v$[formName])) {
                    if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                        const myEl = document.getElementById(key);
                        if (myEl) {
                            this.$smoothScroll({
                                scrollTo: myEl,
                            });
                            break;
                        }
                    }
                }
            }
        },
        edit(){
            axios.post(`/patient/provider-status/retrieve`, { 'id': this.$route.params.id , 'patient_id': this.$route.params.patient_id})
				.then((response) => {
					if (response.data.status === 200) {
                        this.$store.state.enrollment_history.form = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$filters.moshaToast(error.message, "error");
				});

        },
        cancelBtn(){
            this.$router.push(`/patient/enrollment-history/${this.$route.params.patient_id}`);
        },
        fetchProviderData(){
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                'Content-Type': 'application/json', // Adjust this as needed for your API
            };

            axios.post("json_list/provider",{'roleId':''}, headers)
                .then((response) => {
                    const tempUserData = []
                    $.each(response.data.data, function(key, detail) {
                      if (localStorage.getItem("userId") != detail.user_id) {
                        let fullName = detail.full_name
                        if (detail.userName != null) {
                          fullName += ' ('+detail.userName+')'
                        }
                        tempUserData.push({
                          'value':detail.id,
                          'title':fullName,
                        })
                      }
                    });
                    this.providerList = tempUserData

            })

        },
        onFilter(args) {
            // Update the searchText based on user input during filtering
            this.searchText = args.text;
            console.log(this.searchText)
            if (this.searchText.length > this.minSearchLength) {
                this.getPatientList()
            } else {
                this.dataSource = [{ text: "Select A Patient", value: "" }]
            }
        },
        selectPatient(){
            let selectedUser = document.getElementById("patient_id").value;
            selectedUser = selectedUser.match(/\[(\d+)\]/);
            selectedUser =  selectedUser ? selectedUser[1] : "";
            this.patient_id = selectedUser
            // console.log(this.form.patient_id)
        },
        getPatientList(){
            var $this=this
            axios.post("patient/list", {'filter_patient':this.searchText, })
				.then((response) => {
					this.patientList = response.data.data.map((item) => {
                        if (!($this.$route.name == "InboxDocUpload" && item.deleted_datetime)) {
                            return { value: item.id, title: item.full_name+' ['+item.patient_id+']' };
                        }
					});
                    this.dataSource = this.patientList
				});
        },
        fetchTerminationReasonData() {
            axios.get("json_autocomplete/get-termination-reason")
                .then((response) => {
                    const reasonTempData = []
                    const details = response.data.data;
                    $.each(details, function (key, detail) {
                        reasonTempData.push({
                            value: detail['id'],
                            title: detail['reason_name']
                        })
                    })
                    this.termination_reason_option = reasonTempData;
                })
        }
    }
};
</script>