<template>
	<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
		<h3>Create Document</h3>

		<div class="box">
			<div class="box-body p-0">
				<div class="media-list media-list-hover">
					<SelectBoxStatic divClass="col-md-12" title="Document Type" v-model="form.document_type_id" :option="document_type_options" :errors="v$.form.document_type_id.$errors" :isRequired="true"></SelectBoxStatic>
					<button class="btn btn-primary btn-sm" type="button" @click="addDocumentType">Add New</button>

					<InputField title="Title" v-model="form.description" :isRequired="true" :errors="v$.form.description.$errors" divClass="col-12"> </InputField>

					<div class="col-md-12">
						<div class="form-group">
							<label class="form-label">Received DateTime<small class="asterisksign">*</small></label>
							<div class="syncfusion-component">
								<ejs-datetimepicker v-model="form.received_datetime" :format="dateTimeFormat" class="e-field form-control" :errors="v$.form.received_datetime.$errors" :isRequired="true" @change="handleTimeChange"></ejs-datetimepicker>
							</div>
							<div class="error-msg" v-if="v$.form.received_datetime.$errors.length > 0">
								<ul class="mb-0 p-0 fs-14">
									<li class="d-block" v-for="(error, index) of v$.form.received_datetime.$errors" :key="index">
										{{ error.$message }}
									</li>
								</ul>
							</div>
						</div>
					</div>

					<DatePicker divClass="col-md-12" title="Observer Date" v-model="form.observation_date" :isRequired="true" :errors="v$.form.observation_date.$errors" ></DatePicker>

					<file-upload title="Document" v-model="form.file_path" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" :isRequired="true" :errors="v$.form.file_path.$errors" ref="fileInput" @change="handleFileChange"></file-upload>

					<SelectBoxStatic divClass="col-md-12" title="Report Class" v-model="form.report_class_id" :option="report_class_options" ></SelectBoxStatic>

					<InputField title="Report SubClass" v-model="form.report_sub_class_name" divClass="col-12"> </InputField>

					<CheckBox title="Restrict to current program" v-model="form.is_restricted_to_program"></CheckBox>
					
				</div>
			</div> 
		</div>

		<div class="row">
            <button class="btn btn-danger text-white btn-sm col-md-3 me-1" @click="closeWindow"> Cancel &amp; EXIT </button>
            <form-btn
                title="Save"
                btnCls="btn btn-primary text-white btn-sm col-md-3"
				@click="save"
            ></form-btn>
        </div>

	</div>
</template>

<script>
import InputField from "../../base/formFields/InputField.vue"
import FormBtn from "../../base/formFields/FormBtn.vue";
import DatePicker from '../../base/formFields/DatePicker.vue';
import useVuelidate from "@vuelidate/core";
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import CheckBox from "../../base/formFields/CheckBox.vue";
import FileUpload from "../../base/formFields/FileUpload.vue";
import axios from "@/scripts/axios.js";
import $ from "jquery";
import { DateTimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import moment from "moment";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data(){
        return {
			document_type_options:[],
			report_class_options:[],
			dateFormat:"yyyy-dd-MM",
			dateTimeFormat:"yyyy-dd-MM hh:mm a",

        }
    },
    components: {
        InputField,
        FormBtn,
		DatePicker,
		SelectBoxStatic,
		CheckBox,
		FileUpload,
		'ejs-datetimepicker': DateTimePickerComponent,
    },
    methods:{
		getDocumentType() {
			this.document_type_options = []
			axios.post("json_autocomplete/document-type/list", { })
				.then((response) => {
					if (response.data.status === 200) {
						const tempData = []
						$.each(response.data.data, function (key, detail) {
							if (!detail.deleted_datetime){
								tempData.push({
									value: detail.id,
									title: detail.type,
								})
							}
						})
						this.document_type_options = tempData
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		getReportType() {
			this.report_class_options = []
			axios.post("json_autocomplete/doc-report-type/list", { })
				.then((response) => {
					if (response.data.status === 200) {
						const tempData = []
						$.each(response.data.data, function (key, detail) {
							tempData.push({
								value: detail.id,
								title: detail.name,
							})
						})
						this.report_class_options = tempData
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		save() {
			this.v$.$validate();
			if (!this.v$.$error) {
				this.form.patient_id = this.$route.params.patient_id;
				this.form.received_datetime = moment(this.form.received_datetime).format("YYYY-MM-DD HH:mm:ss")
                let url='';
				if (this.$route.name === "PatientDocumentEdit") {
                    this.form.id = this.$route.params.id;
					url = "patient/document/update";
				} else {
					url = "patient/document/store";
				}

				const config = {
					headers: {
						'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
						'Content-Type': 'multipart/form-data'
					},
				};

				axios.post(url, this.form, config)
				.then((response) => {
					if (response.status == 200) {
                        this.$filters.moshaToast(response.data.message, "success");
                        this.closeWindow()
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });

			}

		},
		closeWindow(){
			this.$router.push(`/patient/document/${this.$route.params.patient_id}/list`);

            // if (window.opener === null) {
            //     // console.log('This page was opened in a new window or tab.');
            //     this.$router.push(`/patient/document/${this.$route.params.patient_id}/list`);

            // } else {
            //     // console.log('This page was opened from another page.');
            //     window.close();
            // }
        },
		resetforms() {
			Object.assign(
				this.$store.state.patient_document.form,
				this.$store.state.patient_document.defaultFormData
			);
			this.$store.state.patient_document.form = {}
		},
		addDocumentType() {
			let typeName = prompt("Enter new document type:");
			if (typeName == null) {
				alert("Please enter document type name.");
			} else {
				axios.post("document-type/store", { type: typeName })
					.then((response) => {
						if (response.status == 200) {
							let detail=response.data.data
							this.document_type_options.push({
								value: detail.id,
								title: detail.type,
							});
							this.form.document_type_id = detail.id;
						}
					});
			}
		},
		handleTimeChange(args) {
			const date = new Date(args.value);
			const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
			this.form.received_datetime = formattedDate
		},
	},
	computed: {
		form() {
			return this.$store.state.patient_document.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.patient_document.vuelidateExternalResults;
		},
    },
    validations() {
		return this.$store.state.patient_document.validationRules;
	},
    mounted() {
		this.getDocumentType()
		this.getReportType()
	},
	created(){
		this.resetforms()
	}
}
</script>
