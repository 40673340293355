<template>
	<div class="content">
		<div class="container-full">
			<component :is="$store.state.component">

			</component>
		</div>
	</div>
</template>
<script>
import AdminFaxConfig from '../../components/administrator/fax/Config.vue';
import EditAdminFaxConfig from '../../components/administrator/fax/Edit.vue';
import CreateAdminFaxConfig from '../../components/administrator/fax/Create.vue';

export default {
	components: {
		AdminFaxConfig,
		EditAdminFaxConfig,
		CreateAdminFaxConfig
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
}
</script>