<template>
	<div class="row patient_chart add_local_files">
		<div class="col-lg-5 edit_record_page">
			<div class="box-body containt_detail">
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Uploaded
						Date</label>
					<div class="col-sm-7">
						<div class="pi-info">{{this.form.created_datetime_format}}</div>
					</div>
				</div>
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Document
						Description</label>
					<div class="col-sm-7"><input type="text" class="form-control" v-model="form.description"></div>
				</div>
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Demographic</label>
					<div class="col-sm-7">
						<div class="position-relative">
							<div class="search">
								<img class="serch_icon" src="/images/search-icon.svg" alt="">
								<input type="text" @keyup="patientSearch" class="form-control w-100" placeholder="Search Patient"
									v-model="patient_search" @blur="patientSearch" ref="globalSearch">
							</div>
							<div class="search_drop_box" v-if="patientList.length > 0">
								<slot v-for="(item, index) in patientList" :key="index">
									<div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name+', '+item.first_name)">
										<div class="row px-2 py-3 align-items-center">
											<div class="col-md-6">
												<div class="man_haeding">
													{{ item.last_name }}, {{ item.first_name }}
													<span>({{ item.gender }})</span>
												</div>
												<ul>
													<li>
														DOB: {{ item.dob }}
													</li>
													<li>
														HIN: {{item.health_insurance_no}}
													</li>
													<li>
														Tel: {{item.cell_phone}}
													</li>
												</ul>
											</div>
											<div class="col-md-6 text-end">
												<ol class="">
													<li class="prevent">
														<router-link
															:to="{ name: 'PatientChart', params: { patient_id: item.patient_id, } }"
															target="_blank">M</router-link>
													</li>
													<li class="prevent">
														<router-link
															:to="{ name: 'PatientEChart', params: { patient_id: item.patient_id, } }"
															target="_blank">E</router-link>
													</li>
													<li class="prevent">
														<router-link
															:to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }"
															target="_blank">Rx</router-link>
													</li>
													<li class="prevent">
														<router-link
															:to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }"
															target="_blank">Label</router-link>
													</li>
												</ol>
											</div>
										</div>
									</div>
								</slot>
								<div class="create_grpc border-top"><router-link target="_blank" to="/patient/create">CREATE
										DEMOGRAPHIC</router-link></div>
							</div>
						</div>
					</div>
				</div>
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Document Type</label>
					<div class="col-sm-7">
						<CustomDropDown :options="docTypeList" :initialValue="form.document_type_name" v-model="form.document_type_id" @item-selected="handleItemSelected" fieldName="document_type_id"></CustomDropDown>
						<ValidationErrorMessageList :errors="v$.form.document_type_id.$errors" />
					</div>
				</div>
				<div class="row pd-bottom" v-if="this.isOtherType">
					<label for="text" class="col-sm-5 col-form-label right-bor">Document Sub-Type</label>
					<div class="col-sm-7"><input type="text" class="form-control" v-model="form.report_sub_class_name" :initialValue="form.report_sub_class_name"></div>
				</div>
				<div class="row pd-bottom" v-if="!this.isOtherType">
					<label for="text" class="col-sm-5 col-form-label right-bor">Link with Tracing Requests</label>
					<div class="col-sm-7">
						<CustomDropDown :options="trackingRequest" @item-selected="handleItemSelected" :initialValue="form.tracking_request" fieldName="tracking_request"></CustomDropDown>
					</div>
				</div>
				<div class="row pd-bottom" v-if="!this.isOtherType">
					<label for="text" class="col-sm-5 col-form-label right-bor">Tracing Document Role</label>
					<div class="col-sm-7">
						<CustomDropDown :options="trackingDocRole" v-model="form.tracing_role" :initialValue="form.tracing_role" fieldName="tracing_role" @item-selected="handleItemSelected"></CustomDropDown>
					</div>
				</div>

				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Tag Provider</label>
					<div class="col-sm-7">
						<div class="selact_dropdown">
							<selectCheckBox :dataSource='provider_list' :labelName="tagProviderLabel" @clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key" :isShowDoctorIcon="true" :isUnselectAll="isUnselectAll" divMainClass=""/>
						</div>
					</div>
				</div>
				<div class="row pd-bottom align-items-start">
					<label for="text" class="col-sm-5 col-form-label right-bor">Notes</label>
					<div class="col-sm-7">
						<textarea class="form-control" rows="5" v-model="form.other_information"></textarea>
					</div>
				</div>
			</div>
			<div class="btn_part text-center">
				<button class="comman_brdr_btn big_btn mx36" @click="cancel"> Cancel </button>
				<!-- <button class="comman_brdr_btn big_btn mx36" @click="deleteFile(form.id)"> Delete </button> -->
				<button class="comman_btn big_btn mx36" @click="save">Save
					<img src="images/loader.gif" v-if="loader" />
				</button>
			</div>
		</div>
		<div class="col-lg-7">
			<div class="slide_deatil_box">
				<ejs-pdfviewer 
				id="pdfViewer" 
					ref="pdfviewer" 
					:documentPath="documentPath" 
					:resourceUrl="resourceUrl"
					@documentLoad="onDocumentLoad" 						
					:openFile='false'
					:toolbarSettings="toolbarSettings"
					:height = "height"
					:enableTextSearch="true"
					:enableHyperlink="true"
					:enableMagnification="true"
					:enableToolbar="true"
					:enableNavigation="true"
					:enableThumbnail="false"
					:enableBookmark="true"
					:enableTextSelection="true"
					:enablePrint="true"
					:enableAnnotation="true"
					:enableFormFields="false"
					:enableFormDesigner="false" 
					>
				</ejs-pdfviewer>
			</div>
			
		</div>
	</div>
</template>
<script>
	import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';
	import axios from "@/scripts/axios.js";
	import $ from "jquery";
	import useVuelidate from "@vuelidate/core";
	import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';
	import {  Toolbar, Magnification, Navigation, LinkAnnotation, 
         BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, 
         Annotation } from '@syncfusion/ej2-vue-pdfviewer';
	import selectCheckBox from "../../../base/selectCheckBox.vue";

	export default {
		setup: () => {
			return { v$: useVuelidate() };
        },
		validations() {
			return {
                form: this.$store.state.patient_document.validationRules.form,
			}
        },
		data() {
			return {
				docTypeList: [],
				patientList: [],
				provider_list: [],
				provider_text: "Select Provider(s)",
				loader:false,
				resourceUrl: "https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
				documentPath:null,
				toolbarSettings: {
					showTooltip: true,
					toolbarItems: [
						"UndoRedoTool",
						"PageNavigationTool",
						"MagnificationTool",
						"PanTool",
						"SelectionTool",
						"AnnotationEditTool",
						"FreeTextAnnotationOption",
						"InkAnnotationOption",
						"ShapeAnnotationOption",
						"StampAnnotation",
						"SignatureOption",
						"PrintOption",
						"DownloadOption"
					],
				},
				height: 2000,
				trackingDocRole:[],
				trackingDocTypeRole:{
					Consultation : [
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Specialist Rejected', title: 'Specialist Rejected' },
						{ value: 'Specialist Appointment Booked', title: 'Specialist Appointment Booked' },
						{ value: 'Completed', title: 'Completed' }
					],
					Imaging:[
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Result Received', title: 'Result Received' },
					],
					Lab:[
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Result Received', title: 'Result Received' },
					],
					Rx:[
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Result Received', title: 'Result Received' },
					]
				},
				isOtherType:false,
				trackingRequest:[],
				// Display flag for provider selection dropdown
				displayDiv: false,
				// Key to force re-rendering of the selectCheckBox component
				select_box_key: 0,
				isUnselectAll:false,
				tagProviderLabel:"Select Provider(s)"
			}
		},
		components: {
			CustomDropDown,
			ValidationErrorMessageList,
			selectCheckBox
		},
		methods: {
			// onDocumentLoad: function () {
			// 	let viewer = this.$refs.pdfviewer.ej2Instances;
			// 	$.each(this.dynamicFields, function(key, detail) {
			// 		console.log(key)
			// 		viewer.formDesignerModule.addFormField(detail.type, detail);
			// 	});
			// 	viewer.formDesignerModule.updateFormField(viewer.formFieldCollections[0], {  });
			// },
			patientSearch() {
				if (this.patient_search.length > 2) {
					axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
						.then((response) => {
							this.patientList = response.data.data;
					})
				}
				if (this.patient_search.length == 0) {
					this.patientList = [];
				}
			},
			handleClickOutside(event) {
				const globalSearchElement = this.$refs.globalSearch;
				if (globalSearchElement && !globalSearchElement.contains(event.target)) {
					// this.patient_search = '';
					this.patientList = [];
				}
			},
			getProviderList() {
				axios.post("json_list/provider", { roleId: 2 })
					.then((response) => {
						this.provider_list = response.data.data.map((item) => {
								return { id: item.id, name: item.full_name, checked: false };
						});
				})

			},
			getDocumentType() {
				this.docTypeList = []
				axios.post("json_autocomplete/document-type/list", { })
				.then((response) => {
						if (response.data.status === 200) {
								const tempData = []
								$.each(response.data.data, function (key, detail) {
										if (!detail.deleted_datetime){
												tempData.push({
														value: detail.id,
														title: detail.type,
												})
										}
								})
								this.docTypeList = tempData
						} else {
								this.$filters.moshaToast(response.data.message, "error")
						}
				})
				.catch(error => {
						this.$filters.moshaToast(error.message, "error")
				});
			},
			edit(){
				if (this.$route.name === "PatientEChartFilesEdit") {
					axios.post("patient/document/retrieve", { 'id': this.$route.params.id,'patient_id': this.$route.params.patient_id })
						.then((response) => {
							if (response.data.status === 200) {
								let detail = response.data.data
								this.$store.state.loader = false;
								this.patient_search = detail.patient_name
								this.$store.state.patient_document.form = detail;
								this.documentPath = this.documentUrl(detail.file_path);
								this.handleItemSelected({fieldName: 'document_type_id', item:{value:detail.document_type_id,title:detail.document_type_name}});
								this.provider_text = "Select Provider(s)"
								if (detail.recipientName) {
									this.provider_text = detail.recipientName
								}
								// this.handleItemSelected({fieldName: 'provider_ids', item:{id:detail.provider_ids,title:this.provider_text}});
							} else {
								this.$filters.moshaToast(response.data.message, "error")
								this.$store.state.loader = false;
							}
						}).catch(error => {
							this.$filters.moshaToast(error.message, "error")
							this.$store.state.loader = false;
						});
				}
			},
			selectPatient(id, patient_name){
				this.$store.state.patient_document.form.patient_id = id
				this.patient_search = patient_name
			},
			save(){
				let formName = 'form'
				this.v$[formName].$validate();
				const $this = this
				if (!this.v$[formName].$error) {
					this.loader = true
					this.form.recipientName =  this.provider_text

					const form = this.form;
					let url
					if (this.$route.name === "PatientEChartFilesEdit") {
						url = "patient/document/update";
					} else {
						url = "patient/document/save";
					}

					axios.post(url, form)
						.then((response) => {
							this.loader = true
							if (response.status == 200) {
								this.loader = false
								setTimeout(function () {
									$this.$filters.moshaToast(response.data.message, "success")
									$this.$router.push(
										`/patient/e-chart/${$this.$route.params.patient_id}/files/overview`
									);
								}, 400);
							} else {
								this.loader = false
								this.$filters.moshaToast(response.data.message, "error")
							}
						}).catch(error => {
							this.$filters.moshaToast(error.message, "error")
							this.loader = false
						}); 

				}else {
                    console.log(Object.entries(this.v$[formName]))
                    for (const [key] of Object.entries(this.v$[formName])) {
                        if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                            const myEl = document.getElementById(key);
                            if (myEl) {
                                this.$smoothScroll({
                                    scrollTo: myEl,
                                });
                                break;
                            }
                        }
                    }
				}
			},
			deleteFile(id){
				this.$swal({
					title: 'Are you sure you want to delete records?',
					text: 'You can restore this record',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Yes, delete it!',
					cancelButtonText: 'No, cancel please!',
					closeOnConfirm: false,
					closeOnCancel: false,
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/delete";
						axios.post(url, {'ids':[id]})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.$router.push(
									`/patient/e-chart/${this.$route.params.patient_id}/files/overview`
								);
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
			},
			cancel(){
				this.$router.push(
					`/patient/e-chart/${this.$route.params.patient_id}/files/overview`
				);
			},
			handleItemSelected(values) {
				if (values.fieldName == 'document_type_id'){

					if (this.form.document_type_id != values.item.value) {
						this.form.tracking_request = "Select Link with Tracing Requests"
					}

					this.form.document_type_id = values.item.value
					this.form.document_type_name = values.item.title

					if (values.item.title == 'Others') {
						this.isOtherType = true
					} else {
						this.isOtherType = false
						if (values.item.title ==  'Consultation'){
							this.getconsultationList();
						}else if (values.item.title ==  'Imaging'){
							this.getImagingList();
						}else if (values.item.title ==  'Lab'){
							this.getLabList();
						}else if (values.item.title ==  'Rx'){
							this.getPrescriptionList();
							this.isOtherType = true
						}
					}

					this.trackingDocRole = []
					if (this.trackingDocTypeRole[values.item.title]) {
						this.trackingDocRole = this.trackingDocTypeRole[values.item.title]
					}

				}
				if (values.fieldName == 'provider_ids'){
					this.provider_text = values.item.title
					this.form.provider_ids = values.item.id
				}
				if (values.fieldName == 'tracing_role'){
					this.form.tracing_role = values.item.value
				}

				if (values.fieldName == 'tracking_request'){
					this.form.lab_id = null
					this.form.imaging_id = null
					this.form.consultation_id = null
					this.form.prescription_id = null

					if (this.form.document_type_name ==  'Consultation'){
						this.form.consultation_id = values.item.id
					}else if (this.form.document_type_name ==  'Imaging'){
						this.form.imaging_id = values.item.id
					}else if (this.form.document_type_name ==  'Lab'){
						this.form.lab_id = values.item.id
					}else if (this.form.document_type_name ==  'Rx'){
						this.form.prescription_id = values.item.id
					}
				}

			},
			documentUrl(documentPath) {
				// Assuming your documents are stored in the 'public' directory
				return `${process.env.VUE_APP_STORAGE_URL}/document/view/${documentPath}`;
			},
			getconsultationList(){
				axios.post("patient/consultations/list", {'patient_id': this.$route.params.patient_id})
					.then((response) => {
							this.trackingRequest = response.data.data.map((item) => {
									return { id: item.id, title: item.referral_date+" | "+item.specialist_name, checked: false };
							});

					})
			},
			getImagingList(){
				axios.post("patient/imaging/list", {'patient_id': this.$route.params.patient_id, 'is_future_date':1  })
					.then((response) => {
							this.trackingRequest = response.data.data.map((item) => {
									return { id: item.id, title: item.referral_date+" | "+item.service, checked: false };
							});

					})
			},
			getLabList(){
				axios.post("patient/lab/list", {'patient_id': this.$route.params.patient_id, 'is_future_date':1  })
					.then((response) => {
							this.trackingRequest = response.data.data.map((item) => {
								return { id: item.id, title: item.ordered_datetime+" | "+item.lab_name, checked: false };
							});

					})
			},
			getPrescriptionList(){
				axios.post("patient/prescriptions/list", {'patient_id': this.$route.params.patient_id, 'is_future_date':1  })
					.then((response) => {
							this.trackingRequest = response.data.data.map((item) => {
									return { id: item.id, title: item.written_date+" | "+item.drug_name, checked: false };
							});

					})
			},
			selected_provider(result){
				this.form.provider_ids = result.filter(item => item.checked).map(item => item.id);
				if (this.form.provider_ids.length == 1) {
					this.tagProviderLabel = `${this.form.provider_ids.length } Provider Selected`;
				} else if (this.form.provider_ids.length > 1){
					this.tagProviderLabel = `${this.form.provider_ids.length } Providers Selected`;
				} else {
					this.tagProviderLabel = "Select Provider(s)";
				}
			}
		},
		mounted() {
				this.$store.state.loader = true;
				this.getProviderList()
				document.addEventListener('click', this.handleClickOutside);
				this.getDocumentType()
				if (this.$route.name === "PatientEChartFilesEdit") {
					this.edit();
				} else {
					this.$store.state.loader = false;
				}
		},
		computed: {
			form() {
				return this.$store.state.patient_document.form;
			},

		},
		provide: {
			PdfViewer: [ Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation ]
		}
	}
</script>