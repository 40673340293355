<template>
  <button type="submit" :class="getCssClass">
    {{ title }}
  </button>
</template>
<script>
export default {
  props: ["title", "btnCls"],
  computed: {
    getCssClass() {
      return `waves-effect waves-light btn me-1 ${this.btnCls}`;
    },
  },
};
</script>
