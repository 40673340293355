<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Screenings" v-model="form.keyword" @blur="screeningsSearch" ref="screeningsSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList"></CustomFilter>
                    </span>
                </div>
                <button class="comman_btn" @click="createScreening"> Add Screenings </button>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="hover_unset w-50px"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'start_date' }"  @click="changeSort('start_date')">Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'screening_type' }"  @click="changeSort('screening_type')"> Screening Type <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'result' }"  @click="changeSort('result')"> Status <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset"> Provider</th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.screeningList" :key="index">
                            <td></td>
                            <td>{{ item.start_date }}</td>
                            <td>{{ item.screening_type }}</td>
                            <td>{{ item.result }}</td>
                            <td>{{ item.provider_name }}</td>
                            <td class="edit_delet_link"><span class="popup_link" @click="editScreening(item.id)">View</span><span class="popup_link" @click="deleteRecord(item.id)">Delete</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="Immunization_view_model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="cancelScreening"></button>
                    <div class="box">
						<h2 class="popup_title">{{this.immunizationLbl}}</h2>
                        <div class="col-7 m-auto">
                            <div class="d-flex justify-content-between readonly-form">
                                <div>
                                    
                                </div>
                                <div class="Selact_drodwn">
                                    <div class="dropdown-div" @click="toggleStatusList">
                                        <div class="form-control caption"> {{ (form.result != null) ?
                                            form.result : 'Status' }} <img src="/images/down-arrow-new.svg"
                                                class="img-fluid"></div>
                                        <div class="list" v-show="showStatusList">
                                            <div v-for="(item, index) in this.statusOpt" :key="index" class="item"
                                                @click="selectStatus(item)">
                                                {{ item.title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row custom-form readonly-form">
                                <div class="col-12">
                                    <div class="input_box">
                                        <input type="text" class="form-control form-control-lg" v-model="form.start_date" readonly>
                                        <label class="form-label">Date<small class="asterisksign">*</small> </label>
                                    </div>
                                </div>
                                <!-- <div class="col-12">
                                    <div class="input_box">
                                        <ejs-datepicker class="e-field form-control form-control-lg" v-model="form.start_date"
                                                    :showClearButton="false" format="yyyy-MM-dd" @change="onFieldChange('start_date')"></ejs-datepicker>
                                        <label class="form-label">Date </label>
                                    </div>
                                </div> -->
                                <div class="col-12">
                                    <div class="input_box">
                                        <input type="text" class="form-control form-control-lg" required v-model="form.screening_type">
                                        <label class="form-label">Screening Type<small class="asterisksign">*</small> </label>
                                        <ValidationErrorMessageList :errors="v$.form.screening_type.$errors" />
                                    </div>
                                </div>
                                <!-- <div class="col-12">
                                    <div class="input_box">
                                        <input type="text" class="form-control form-control-lg" v-model="form.next_date" required>
                                        <label class="form-label">Next Screening Date<small class="asterisksign">*</small> </label>
                                    </div>
                                </div> -->
                                <div class="col-12">
                                    <div class="input_box input_date_picker">
                                        <ejs-datepicker class="e-field form-control form-control-lg" v-model="form.next_date"
                                                    :showClearButton="false" format="yyyy-MM-dd" @change="onFieldChange('next_date')" placeholder="Next Screening Date"></ejs-datepicker>
                                    </div>
                                </div>
                                <div class="text_area">
                                    <label  class="txt_label">Instructions</label>
                                    <textarea class="form-control" rows="3" v-model="form.instructions"></textarea>
                                </div>
                                <div class="text_area">
                                    <label  class="txt_label">Comments</label>
                                    <textarea class="form-control" rows="3" v-model="form.comments"></textarea>
                                </div>
                                <div class="mt-5 mb-4 text-center">
                                    <button class="comman_brdr_btn big_btn mx36" @click="cancelScreening"> Cancel </button>
                                    <button class="comman_btn big_btn mx36" @click="saveScreening"> {{ this.lblText }} <img src="images/loader.gif" v-if="loader" /></button>
                                </div>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from '../sidebar.vue';
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import CustomFilter from '../../../base/CustomFilter.vue';
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';

export default ({
    setup: () => {
        return { 
            v$: useVuelidate()
        };
    },
    validations() {
        return this.$store.state.screening.validationRules;
    },
    data() {
        return {
            isShowCustomFilter:false,
            screeningList:[],
            filterList:[],
            filterOptions:[
                { value: "screening_type", title: "Screening Type" },
                { value: "result", title: "Status" },
            ],
            loader: false,
            showStatusList: false,
            statusOpt:[
                { value: "Pending", title: "Pending" },
                { value: "Normal", title: "Normal" },
                { value: "Abnormal", title: "Abnormal" },
                { value: "Others", title: "Others" },
            ],
            lblText:'Create'
        }
    },
    components: {
        sidebar,
        CustomFilter,
        'ejs-datepicker': DatePickerComponent,
        ValidationErrorMessageList
    },
    mounted() {
        this.fetchPatientScreenings()
    },
    methods:{
        createScreening(){
            this.resetModelForm()
            this.immunizationLbl = 'Add New Screening'
            this.lblText = 'Create'
            window.$("#Immunization_view_model").modal("show");
        },
        editScreening(id){
            this.resetModelForm()
            this.edit(id)
            this.lblText = 'Save'
            this.immunizationLbl = 'Edit Screening'
            window.$("#Immunization_view_model").modal("show");
        },
        fetchPatientScreenings(){
            this.screeningList = []
			axios.post("patient/screening/list", {
                'patient_id':this.$route.params.patient_id,
                'keyword' : this.form.keyword,
                advanceFilter:this.form.advanceFilter,
                sort_by: this.sortBy,
                sort_order:this.sortOrder,
            })
            .then((response) => {
                this.screeningList = response.data.data
            })
        },
        screeningsSearch(){
            this.fetchPatientScreenings()
        },
        addFilter(){
            if (this.isShowCustomFilter) {
                this.fetchPatientScreenings()
            }
            this.isShowCustomFilter = !this.isShowCustomFilter;
        },
        resetModelForm(){
            Object.assign(
				this.$store.state.screening.form,
				this.$store.state.screening.defaultFormData
			);
            // $this.v$.$reset();
        },
        changeSort(fieldName){
            if (fieldName != this.sortBy) {
                this.sortOrder = 'desc'
            } else if (this.sortOrder == 'desc') {
                this.sortOrder = 'asc'
            } else{
                this.sortOrder = 'desc'
            }

            this.sortBy = fieldName

            this.fetchPatientScreenings()
        },
        deleteRecord(id) {
            this.$swal({
                title: 'Are you sure you want to delete record?',
                text: 'You can not restore this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/screening/delete";
                    axios.post(url, {'id':id, 'patient_id':this.$route.params.patient_id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.fetchPatientScreenings()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });
        },
        edit(id){
            const url = "patient/screening/retrieve";
                axios.post(url, { id: id })
                .then((response) => {
                    if (response.status == 200) {
                        this.$store.state.screening.form = response.data.data
                    }
                })
        },
        cancelScreening(){
            window.$("#Immunization_view_model").modal("hide");
        },
        saveScreening(){
            this.v$.$validate();
            const $this = this
            if (!this.v$.$error) {
                this.form.patient_id = this.$route.params.patient_id
                $this.loader = true
                let url
                if (this.$store.state.screening.form.id) {
                    url = "patient/screening/update";
                } else {
                    url = "patient/screening/store";
                }

                axios.post(url, this.form)
                    .then((response) => {
                    if (response.status == 200) {
                        this.loader = false
                        setTimeout(function () {
                            $this.fetchPatientScreenings()
                            $this.$filters.moshaToast(response.data.message, "success")
                            $this.cancelScreening()
                        }, 400);
                    } else {
                        this.loader = false
                        this.$filters.moshaToast(response.data.message, "error")
                    }
                })

            }else {
					for (const [key] of Object.entries(this.v$.form)) {
						if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
        },
        toggleStatusList() {
			this.showStatusList = !this.showStatusList;
		},
        selectStatus(item){
            this.$store.state.screening.form.result = item.value
        },
        onFieldChange(fieldName) {
            this.formatDate(fieldName);
        },
        formatDate(fieldName) {
            const dateObject = new Date(this.form[fieldName]);
            const year = dateObject.getFullYear();
            const month = String(dateObject.getMonth() + 1).padStart(2, '0');
            const day = String(dateObject.getDate()).padStart(2, '0');
            this.form[fieldName] = `${year}-${month}-${day}`;
        },
        handleCustomField(values){
            this.filterList = values
            let advanceFilter={}
            values.forEach(item => {
                advanceFilter[item.field] = item.value
            });
            this.form.advanceFilter = advanceFilter
        },

    },
    computed: {
        form() {
			return this.$store.state.screening.form;
		}
    },
})
</script>
