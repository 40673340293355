<template>
	<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 left_side_bar e_chart_sidebar px-0">
		<div class="sticky-scroll-box">
			<ul class="side-bar-menu" data-widget="tree">
				<li :class="{ 'active': this.$route.name === 'AllConsultationList' }" v-if='this.isConsultRoute()'>
					<router-link :to="{ name: 'AllConsultationList',params: { patient_id: $route.params.patient_id,  }}"> Consults </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AllConsultationList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'AllSpecialistList' }" v-if='this.isConsultRoute()'>
					<router-link :to="{ name: 'AllSpecialistList',params: { patient_id: $route.params.patient_id,  }}"> Specialist </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AllSpecialistList'">
				</li>
			</ul>
		</div>
	</div>
</template>
<script>


export default {
	data() {
		return {
			options:[
			]
		};
	},
	watch: {
		
	},
	mounted() {

    },
	methods: {
		isConsultRoute(){
			return (
				this.$route.name === "AllConsultationList" ||
				this.$route.name === "AllSpecialistList"
			)
		},

	},
	components: { 
		
	}
};
</script>