<template>
	<div class="row patient_chart add_local_files">
		<div class="col-lg-5 edit_record_page">
			<div class="box-body containt_detail">
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Template Description<small class="asterisksign">*</small></label>
					<div class="col-sm-7"><input type="text" v-model="form.title" class="form-control"></div>
				</div>
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Module<small class="asterisksign">*</small></label>
					<div class="col-sm-7">
						<CustomDropDown :options="moduleList" :initialValue="form.module" fieldName="module" @item-selected="handleItemSelected"></CustomDropDown>
					</div>
				</div>
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Notes</label>
					<div class="col-sm-7"><input type="text" class="form-control" v-model="form.additional_info"></div>
				</div>
			</div>
			<div class="btn_part text-center">
				<button class="comman_brdr_btn big_btn mx30" @click="backToList"> Cancel </button>
				<!-- <button class="comman_brdr_btn"> Delete </button> -->
				<button class="comman_btn big_btn mx30" @click="uploadDocument">Save</button>
			</div>
		</div>
		<div class="col-lg-7">
			<div class="slide_deatil_box" v-if="documentPath!=null">
				<ejs-pdfviewer 
					id="pdfViewer" 
					ref="pdfviewer" 
					:documentPath="documentPath" 
					:resourceUrl="resourceUrl"
					@documentLoad="onDocumentLoad"
					:enableBookmark="false"
					:openFile='false'
					@beforeTooltipRender="beforeTooltipRender"
				>
				</ejs-pdfviewer>
			</div>
		</div>
	</div>
</template>
<script>
import CustomDropDown from '../../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
// import $ from "jquery";
import {  Toolbar, Magnification, Navigation, LinkAnnotation, 
         BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, 
         Annotation, FormDesigner, FormFields } from '@syncfusion/ej2-vue-pdfviewer';
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			form: {
				title: null,
				additional_info: null,
				module: null,
				dynamicFields:[]
			},
			moduleList : [
				{ value: "Consultation", title: "Consultation" },
                { value: "Imaging", title: "Imaging" },
                { value: "Lab", title: "Lab" },
                { value: "Others", title: "Others" },
			],
			resourceUrl: "https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
			documentPath: null,
		}
	},
	components: {
		CustomDropDown
	},
	validations() {
		return {
			form: {
				title: {
					required: helpers.withMessage("Please enter document title.", required),
				},
			},
		};
	},
	methods: {
		handleItemSelected(obj){
			this.form.module = obj.item.value;
		},
		onDocumentLoad: function () {
			// let viewer = this.$refs.pdfviewer.ej2Instances;
			// $.each(this.dynamicFields, function(key, detail) {
			// 	console.log(key)
			// 	viewer.formDesignerModule.addFormField(detail.type, detail);
			// });
			// viewer.formDesignerModule.updateFormField(viewer.formFieldCollections[0], {  });
		},
		async uploadDocument() {
			this.v$.$validate();
			if (!this.v$.$error) {
				let $this = this
				const formData = new FormData();
				const pdfViewerInstance = this.$refs.pdfviewer.ej2Instances;
				if (pdfViewerInstance) {
					const annotatedPdfBlob = pdfViewerInstance.saveAsBlob();
					if (annotatedPdfBlob instanceof Promise) {
						annotatedPdfBlob.then((result) => {
							if (result instanceof Blob) {
								formData.append('pdfData', result, 'edited_file.pdf');
							}
							formData.append('id', this.$route.params.template_id);
							formData.append('title', this.form.title);
							formData.append('additional_info', this.form.additional_info);
							formData.append('module', this.form.module);
							try {
								const config = {
									headers: {
										'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
										'Content-Type': 'multipart/form-data'
									},
								};
								axios.post('e-forms/upload', formData, config).then((response) => {
									if (response.status == 200) {
										$this.$filters.moshaToast(response.data.message, "success");
										if ($this.$route.name !== 'templateMasterEdit') {
											$this.$router.push({ name: 'patientTemplateList', params: { patient_id: $this.$route.params.patient_id, module:$this.form.module.toLowerCase()} });
										} else {
											$this.$router.push({ name: 'templateMasterList', params: { }});
										}
									}
								});
							}
							catch (error) {
								console.error('Error saving PDF with notes on the server:', error);
							}
						});
					}
				}
			}
		},
		async edit() {
			this.$store.state.loader = true;
			axios.post("e-forms/retrieve", { 'id': this.$route.params.template_id })
				.then((response) => {
					this.$store.state.loader = false;
					if (response.data.status === 200) {
						this.dynamicFields = response.data.data.formfields
						this.form.title = response.data.data.title;
						this.form.additional_info = response.data.data.additional_info;
						this.form.module = response.data.data.module;
						this.documentPath = this.documentUrl(response.data.data.file_path);
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					this.$filters.moshaToast(error.message, "error");
				});
		},
		documentUrl(documentPath) {
			// Assuming your documents are stored in the 'public' directory
			return `${process.env.VUE_APP_STORAGE_URL}/e-forms/document/view/${documentPath}`;
		},
		backToList() {
			if (this.$route.name !== 'templateMasterEdit') {
				this.$router.push({ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module:this.form.module.toLowerCase() } });
			} else {
				this.$router.push({ name: 'templateMasterList', params: { }});
			}
		},
		beforeTooltipRender(args) {
			// Customize the tooltip content based on the element
			if (args.element.classList.contains('e-pv-annotation-icon')) {
				args.content = 'Custom tooltip content for annotation icon';
			} else if (args.element.classList.contains('e-pv-bookmark-icon')) {
				args.content = 'Custom tooltip content for bookmark icon';
			} else {
				args.content = 'Default tooltip content';
			}
		}
		
	},
	mounted() {
		this.edit();
	},
	provide: {
		PdfViewer: [ Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation,  FormDesigner, FormFields ]
	}
}
</script>
<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css";

#pdfViewer_fileUploadElement {
	display: none !important;
}

.e-checkbox-wrapper {
	display: none !important;
}

.pdf_search_section {
	position: absolute;
	top: 31px;
	width: 100%;
	background: #f5f5f5;
	padding: 10px;
	box-shadow: 0px 0px 6px 1px #e1e1e1;
	border-radius: 2px;
	border-top: none;
}

.pdf_search {
	padding-left: 0;
	margin-bottom: -10px;
    margin-top: -7px;
}

.pdf_search_option {
	list-style-type: none;
	line-height: normal;
	border-bottom: 1px solid #e7e4e4;
	padding: 10px;
	cursor: pointer;
}

.pdf_search_option:hover {
	background: #e1e1e1;
}
</style>