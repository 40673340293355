<template>
    <div class="row">
        <div class="col-12">
            <div class="box">
                <div class="box-header">
                    <h4 class="box-title">Patient Vital</h4>  
                </div>

                <div class="box-body">
                    <SelectBoxStatic  divClass="col-4" title="Vital Name" :option="this.vitalNamesList" @change="vitalNameChange" v-model="vitalName"></SelectBoxStatic>

                    <div class="row">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                    <th>Glycemic Control</th>
                                    <th>Value</th>
                                    <th>Comments</th>
                                    <th>Most Recent</th>
                                    <th>Previous</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in this.form" :key="index">
                                        <slot v-if="this.visibleVital[item.vital_type]">
                                            <td>{{ item.vital_type }}</td>
                                            <td>
                                                <div class="input-group col-md-12">
                                                    <InputField placeholder="Enter Value" title="Value" :divClass="col-md-12" v-model="this.form[index]['value']"> </InputField>
                                                    <div class="input-group-append" v-if="item.selected_unit">
                                                        <div class="form-group">
                                                            <label class="form-label">Unit</label>
                                                            <span class="input-group-text">{{item.selected_unit}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td><InputField placeholder="Enter Comment" :divClass="col-md-12" v-model="this.form[index]['comment']"> </InputField></td> 
                                            <td>
                                                <slot v-if="this.mostRecentData[item.vital_type]" >
                                                    <span v-html="this.mostRecentData[item.vital_type]"></span>
                                                </slot>
                                            </td>
                                            <td>
                                                <slot v-for="(item, index) in this.previosRecentData[item.vital_type]" :key="index">
                                                    <span v-if="item" v-html="item" class="badge badge-secondary ml-1 pl-1"></span>
                                                </slot>
                                            </td>
                                        </slot>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-center pt-10 pb-2">
                        <button class="btn btn-danger text-white col-md-2 btn-sm" @click="closeWindow"> Cancel &amp; EXIT </button>
                        <form-btn
                            title="save"
                            btnCls="btn btn-primary text-white col-md-2 ml-2 btn-sm"
                            style="margin-left:1vw"
                            @click="submit"
                        ></form-btn>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import axios from "@/scripts/axios.js";
import InputField from "../../base/formFields/InputField.vue"
import $ from "jquery";
import FormBtn from "../../base/formFields/FormBtn.vue";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
		return {
            visibleVital:[],
            vitalName:null,
            vitalNamesList:[],
            vitalData:[],
            form: {
                patient_vital: {
                    // value: {},
                    // selected_unit:{},
                    // comment:{}
                }
            },
            mostRecentData:[],
            previosRecentData:[],
        }
    },
    components: {
        SelectBoxStatic,
        InputField,
        FormBtn
    },
    computed: {
        // form() {
		// 	return this.$store.state.alt_contact.form;
		// },
		// vuelidateExternalResults() {
		// 	return this.$store.state.alt_contact.vuelidateExternalResults;
		// },
    },
    validations() {
		// return this.$store.state.alt_contact.validationRules;
	},
    mounted(){
        this.getVitalForm()
        this.getPreviosVitalData()
    },
    methods:{
        getVitalForm(){
            axios.post("patient/vital/get-form", { patient_id: this.$route.params.patient_id })
                .then((response) => {
                    let temp = []
                    response.data.data.forEach((detail) => {
                        temp.push({title:detail.vital_type, value:detail.vital_type})
                    });
                    this.vitalNamesList = temp
                    this.form = response.data.data
                });
        },
        submit() {
            let $this = this
            let url = "patient/vital/store"
            axios.post(url, {patient_id: this.$route.params.patient_id, 'vital': this.form})
                .then((response) => {
                    if (response.status == 200) {
                        this.loader = false
                        setTimeout(function () {
                            $this.$filters.moshaToast(response.data.message, "success")
                            $this.$router.push(
                                `/patient/e-chart/${$this.$route.params.patient_id}`
                            );
                        }, 400);
                    } else {
                        this.loader = false
                        this.$filters.moshaToast(response.data.message, "error")
                    }
                })

        },
        vitalNameChange(){
            this.visibleVital[this.vitalName] = 1;
        },
        getPreviosVitalData() {
            axios.post("patient/vital/get-history-by-type", { patient_id: this.$route.params.patient_id })
                .then((response) => {
                    let data = response.data.data
                    let _this = this
                    $.each(data, function (key, detail) {
                        let note = "<b>"+detail.value+"</b>:"+detail.measure_date
                        if (detail.comment != null) {
                            note += "<br/>"+detail.comment
                        }

                        if (detail.is_latest == 1) {
                            _this.mostRecentData[detail.vital_type] = note
                        } else {
                            if (!_this.previosRecentData[detail.vital_type]) {
                                _this.previosRecentData[detail.vital_type] = []
                            }
                            _this.previosRecentData[detail.vital_type][detail.id] = note
                        }
                    })
                });
            
        },
        closeWindow(){
            if (window.opener === null) {
                // console.log('This page was opened in a new window or tab.');
                this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}`);

            } else {
                // console.log('This page was opened from another page.');
                window.close();
            }
        },
    }
};
</script>
