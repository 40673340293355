import {
	helpers,
	required,
	// email,
} from "@vuelidate/validators";

const pharmacy = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			pharmacy_name: null,
			address: null,
			city: null,
			state: null,
			zip_code: null,
			phone: null,
			phone_ext:null,
			fax: null,
			email: null,
		},
		form: {
		},
		validationRules: {
			form: {
				pharmacy_name: {
					required: helpers.withMessage("Please enter pharmacy name", required),
				},
				address:{
					required: helpers.withMessage("Please enter address", required),
				},
				city:{
					required: helpers.withMessage("Please enter city", required),
				},
				zip_code:{
					required: helpers.withMessage("Please enter postal code", required),
				},
				fax:{
					required: helpers.withMessage("Please enter fax number", required),
				}
			},
		},
		vuelidateExternalResults: {
			form: {
				
			}
		},
		list: [],
	},

	getters: {},
	mutations: {},
	actions: {},
};


export default pharmacy;
