<template>
    <SubPageHeader :headerTitle="getHeaderTitle"></SubPageHeader>
	<div class="content">
		<div class="container-fluid">
			<component :is="$store.state.component">

			</component>
		</div>
	</div>
</template>

<script>
import SubPageHeader from '../SubPageHeader.vue'
import InvoiceMasterCreate from '../../components/billing/createForm.vue';
import InvoiceList from '../../components/billing/list.vue';
import InvoiceMasterDashboard from '../../components/billing/dashboard.vue';
import InvoiceAnalysisReport from '../../components/billing/analysis-report.vue';

export default {
  data() {
		return {
      
    }
  },
	components: {
		SubPageHeader,
		InvoiceMasterCreate,
		InvoiceList,
        InvoiceMasterDashboard,
        InvoiceAnalysisReport
	},
	created() {    
		this.$store.state.component = this.$route.name;
	},
  mounted() {
    
  },
  methods: {
  },
  computed: {
		getHeaderTitle() {
			const currentRoute = this.$route;
			if (currentRoute.meta && currentRoute.meta.headerTitle) {
				return currentRoute.meta.headerTitle;
			} else {
				const parentRoute = currentRoute.matched[0];
				if (parentRoute.meta && parentRoute.meta.headerTitle) {
					return parentRoute.meta.headerTitle;
				} else {
					return 'Default Header Title';
				}
			}
		},
  },
}
</script>