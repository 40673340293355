<template>
	<h3 style="text-align: center;">Note Revision History</h3>
	<div class="media-list media-list-hover">
		<slot v-for="(item,index) in RevisionList" :key="index">
			<a class="media media-single" href="#">
				<div class="media-body ps-15 bs-5 rounded" :class="item.classColor">
					<p>{{ item.note }}</p>
					<p>Documentation Date: {{ $filters.inputDateTime(item.created_at) }}</p>
					<p>Saved by: {{ item.created_by }}</p>
				</div>
			</a>
		</slot>
	</div>	
</template>
<script>
// eslint-disable-next-line no-unused-vars
import axios from "@/scripts/axios.js";

export default {
	data() {
		return {
			RevisionList: [],
			classList: ['border-primary', 'border-success', 'border-info', 'border-danger', 'border-warning'],
		}
	},
	methods: {
		getAnnotation() {
			const url = "patient/" + this.$route.params.moduleName + "/revision-list";
			const patient_id = this.$route.params.patient_id;
			const id = this.$route.params.id;
			let $this = this;
			axios.post(url, { patient_id: patient_id, id: id })
				.then((response) => {
					let myArray = response.data.data.map((item) => {						
						return {...item, classColor: $this.getRandomValueFromArray() };
					});
					$this.RevisionList = myArray;
				});
		},
		getRandomValueFromArray() {
			let array = this.classList;
			// Generate a random index within the array's bounds
			const randomIndex = Math.floor(Math.random() * array.length);

			// Return the element at the random index
			return array[randomIndex];
		}
	},
	mounted() {
		this.getAnnotation()
	},
}
</script>