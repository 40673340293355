<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" @keyup="taskSearch" class="form-control" placeholder="Search Tasks" v-model="form.keyword" @blur="taskSearch" ref="globalSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" @minus-filter-length="minusFilterLen" :selectedOpt="this.filterList"></CustomFilter>
                    </span>
                    <button class="comman_brdr_btn" v-if="this.selectedItems.length > 0" @click="completeTask('Complete')"> Complete </button>
                </div>
                <div>
                    <button class="comman_btn" @click="addTask"> Add Tasks </button>
                </div>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col" class="active" @click="changeSort('service_date', $event)"> Create Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('reminder_message', $event)"> Message <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('patient_name', $event)"> Demographic <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('provider_ids', $event)"> Assigned Providers <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('priority', $event)"> Urgency <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.allActiveTasksList" :key="index" :class="{ 'active': (selectChk[item.id] || selectedItems.includes(item.id) ) }">
                            <td><input class="form-check-input" type="checkbox" :checked="isChecked(item.id)" v-model="selectChk[item.id]" @change="checkSelect(item.id)"></td>
                            <td>{{ $filters.inputDateTime(item.service_date, 'YYYY-MM-DD') }}</td>
                            <td>{{ $filters.capitalizeFirstWordFirstLetter(item.reminder_message) }}</td>
                            <td>{{ item.patient_name }}</td>
                            <td>{{ item.provider_ids }}</td>
                            <td>{{ item.priority }}</td>
                            <td class="text-decoration-underline cursor-pointer" @click="viewTask(item.id)">View</td>
                            <td class="text-decoration-underline cursor-pointer" @click="deleteTask(item.id)">Delete</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="add-task-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeAddTaskModel"></button>
                    <div class="box">
                        <div class="col-7 mx-auto">
                            <h2 class="popup_title">{{ popupHeadingText }}</h2>
                            <div class="row">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <CustomDropDown :options="status" :initialValue="'Active'"
                                                v-model="form.status" @item-selected="handleStatusField" fieldName="Status" divClass="form-label"></CustomDropDown>
                                    </div>
                                    <div>
                                        <CustomDropDown :options="urgency" :initialValue="'Urgent'"
                                                v-model="form.urgency" @item-selected="handlePriorityField" fieldName="Urgency" divClass="form-label"></CustomDropDown>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-form">
                                <div class="input_box">
                                    <input required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.service_date" :isRequired="true" :errors="v$.form.service_date.$errors">
                                    <label class="form-label" for="typeunique_idX-2">Date</label>
                                </div>
                                <div class="input_box">
                                    <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" 
                                    @keyup="patientSearch" v-model="patient_search" @blur="patientSearch">
                                    <label class="form-label" for="typeunique_idX-2">Demographic</label>
                                    <div class="position-relative">
                                        <div class="search_drop_box" v-if="patientList.length > 0">
                                            <slot v-for="(item, index) in patientList" :key="index">
                                                <div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name+', '+item.first_name)">
                                                    <div class="row px-2 py-3 align-items-center">
                                                        <div class="col-md-6">
                                                            <div class="man_haeding">
                                                                {{ item.last_name }}, {{ item.first_name }}
                                                                <span>({{ item.gender }})</span>
                                                            </div>
                                                            <ul>
                                                                <li>
                                                                    DOB: {{ item.dob }}
                                                                </li>
                                                                <li>
                                                                    HIN: {{item.health_insurance_no}}
                                                                </li>
                                                                <li>
                                                                    Tel: {{item.cell_phone}}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-6 text-end">
                                                            <ol class="">
                                                                <li class="prevent">
                                                                    <router-link
                                                                        :to="{ name: 'PatientChart', params: { patient_id: item.patient_id, } }"
                                                                        target="_blank">M</router-link>
                                                                </li>
                                                                <li class="prevent">
                                                                    <router-link
                                                                        :to="{ name: 'PatientEChart', params: { patient_id: item.patient_id, } }"
                                                                        target="_blank">E</router-link>
                                                                </li>
                                                                <li class="prevent">
                                                                    <router-link
                                                                        :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }"
                                                                        target="_blank">Rx</router-link>
                                                                </li>
                                                                <li class="prevent">
                                                                    <router-link
                                                                        :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }"
                                                                        target="_blank">Label</router-link>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </slot>
                                            <div class="create_grpc border-top"><router-link target="_blank" to="/patient/create">CREATE
                                                    DEMOGRAPHIC</router-link></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="selact_dropdown">
                                    <selectCheckBox :dataSource="provider_list" labelName="Select Provider(s)" @clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key"></selectCheckBox>
                                </div>
                                <div class="text_area">
                                    <label class="txt_label">Message</label>
                                    <textarea class="form-control" rows="9" spellcheck="false" v-model="form.reminder_message" :isRequired="true" :errors="v$.form.reminder_message.$errors"></textarea>
                                </div>
                                <div class="" v-if="form.document_id">
                                    <label class="txt_label">Associated Document  </label>
                                    <span @click="viewTaskDocument(form.document_id)">{{ document_type }} | {{ document_from }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeAddTaskModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="addTaskCall">
                                {{ this.buttonName }}
                                <img src="images/loader.gif" v-if="loader" />
                            </button>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import sidebar from './sidebar.vue';
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import CustomFilter from '../base/CustomFilter.vue';
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";

export default {
        components: {
            sidebar,
            CustomDropDown,
            CustomFilter,
            selectCheckBox
        },
        data() {
            return {
                selectAllChk: false,
                selectChk: [],
                selectedItems: [],
                allItems: [],
                urgency:[
					{ value: "Urgent", title: "Urgent" },
					{ value: "Normal", title: "Normal" },
				],
                status: [
                    { value: "Active", title: "Active" },
                    { value: "Complete", title: "Complete" },
                    { value: "Delete", title: "Delete" },
                ],
                filterOptions:[
                    { value: "reminder_message", title: "Message" },
                    { value: "priority", title: "Urgency" },
                    { value: "patient_name", title: "Demographic" },
                ],
                isShowCustomFilter:false,

                allActiveTasksList: [],
                filterList:[],
                loader: false,
                action: "Add",
                buttonName: "Create",
                popupHeadingText: "Add New Task",
                provider_list: [],
                displayDiv: false,
                filter: {
                    provider_list: {},
                },
                document_from: '',
                document_type: '',

                patientList: [],
            }
        },
        mounted() {
            this.getActiveTaskList();
            this.getProviderList();
            document.addEventListener('click', this.handleClickOutside);
        },
        methods:{
            getActiveTaskList() {
                this.form.from =  'ActiveTaskList',
                this.form.from_module = 'calendar';
                axios.post('patient/task/list', this.form)
                    .then((response) => {
                        if (response.status == 200) {
                            this.allActiveTasksList = response.data.data;
                            console.log('this.allActiveTasksList:- ', this.allActiveTasksList);
                            if(this.allActiveTasksList) {
                                this.allActiveTasksList = this.capitalizeData(this.allActiveTasksList);
                            }
                            this.form.demographic = this.allActiveTasksList[0].patient_name;
                            this.allActiveTasksList.forEach(item => {
                                this.allItems.push(item.id)
                            });
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            console.log(Object.values(error.response.data.data).join(', '))
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            getProviderList() {
                axios.post("json_list/provider", { roleId: 2 })
                .then((response) => {
                    this.provider_list = response.data.data.map((item) => {
                        return { id: item.id, name: item.full_name, checked: false };
                    });
                    console.log('this.provider_list:- ', this.provider_list);
                });
            },
            patientSearch() {
				if (this.patient_search.length > 2) {
					axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
						.then((response) => {
							this.patientList = response.data.data;
					})
				}
				if (this.patient_search.length == 0) {
					this.patientList = [];
				}
			},
            selectPatient(id, patient_name){
				this.form.patient_id = id
				this.patient_search = patient_name
			},
            handleClickOutside(event) {
				const globalSearchElement = this.$refs.globalSearch;
				if (globalSearchElement && !globalSearchElement.contains(event.target)) {
					this.patientList = [];
				}
			},
            addTaskCall() {
                this.loader = true;
                if(this.action == "Add")
                {
                    axios.post('patient/task/store', this.form)
                        .then((response) => {
                            if (response.status == 200) {
                                this.loader = false;
                                this.$filters.moshaToast(response.data.message, "success");

                                this.patient_search = '';
                                this.form.advanceFilter = {};
                                this.filter.provider_list = {};
                                this.getActiveTaskList();
                                this.getProviderList();
                                
                                this.closeAddTaskModel();
                                this.resetTaskForm();
                            } else {
                                this.$filters.moshaToast(response.data.message, "error");
                            }
                        }).catch(error => {
                            this.loader = false;
                            if (error.response.status === 422) {
                                console.log(Object.values(error.response.data.data).join(', '));
                                this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            } else {
                                this.$filters.moshaToast(error.response.data.message, "error");
                            }
                        });

                }
                else
                {
                    axios.post('patient/task/update', this.form)
                        .then((response) => {
                            if (response.status == 200) {
                                this.loader = false;
                                this.$filters.moshaToast(response.data.message, "success");

                                this.patient_search = '';
                                this.form.advanceFilter = {};
                                this.closeAddTaskModel();
                                this.getActiveTaskList();
                                this.resetTaskForm();
                                this.getProviderList();

                                this.popupHeadingText = "Add New Task";
                                this.buttonName = "Create";
                                this.action = "Add";
                            } else {
                                this.$filters.moshaToast(response.data.message, "error");
                            }
                        }).catch(error => {
                            this.loader = false;
                            if (error.response.status === 422) {
                                console.log(Object.values(error.response.data.data).join(', '));
                                this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            } else {
                                this.$filters.moshaToast(error.response.data.message, "error");
                            }
                        });
                }
            },
            viewTask(taskId){
                this.popupHeadingText = "Task Details";
                this.buttonName = "Update";
                this.action = "Update";
                window.$("#add-task-model").modal("show");
                this.form.provider_ids = {};
                axios.post('patient/task/retrieve', { from_module: 'calendar', id: taskId })
                .then((response) => {
                    if (response.data.status == 200) {
                        const responseData = response.data.data;

                        if(responseData) {
                            this.patient_search = responseData.patient_name;

                            this.form.id = responseData.id;
                            this.form.patient_id = responseData.patient_id;
                            this.form.document_id = responseData.document_id;
                            this.form.service_date = responseData.service_date;
                            this.form.demographic = responseData.patient_name;
                            this.form.status = responseData.status;
                            this.form.priority = responseData.priority;
                            this.form.reminder_message = responseData.reminder_message;
                            this.form.provider_ids = responseData.provider_ids;
                            this.document_type = responseData.document_type;
                            this.document_from = responseData.document_from;
                            
                            let assignedToIds;
                            if (responseData.provider_ids.includes(',')) {
                                assignedToIds = responseData.provider_ids.split(',').map(id => parseInt(id, 10));
                            } else {
                                assignedToIds = [parseInt(responseData.provider_ids, 10)];
                            }

                            this.provider_list = this.provider_list.map(provider => {
                                if (assignedToIds.includes(provider.id)) {
                                    return { ...provider, checked: true };
                                } else {
                                    return { ...provider, checked: false };
                                }
                            });
                        }
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.loader = false;
                    if (error.response.data.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },


            viewTaskDocument(documentId) {
                const route = this.$router.resolve({
                    name: 'PatientEChartFilesEdit',
                    params: { patient_id: this.$route.params.patient_id, id: documentId }
                });

                window.open(route.href, '_blank');
            },
            completeTask(changeStatus) {
                this.$swal({
                    title: 'Are you sure you want to complete task?',
                    text: '',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Yes, complete it!',
                    cancelButtonText: 'No, cancel please!',
                    closeOnConfirm: false,
                    closeOnCancel: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log('selectedItems:-  ', this.selectedItems);
                        if(this.selectedItems)
                        {
                            axios.post("patient/task/update-status", { status: changeStatus, ids: this.selectedItems, patient_id: this.$route.params.patient_id } )
                            .then((response) => {
                                console.log('response:- ', response.data.data);
                                this.getActiveTaskList();
                                this.$filters.moshaToast(response.data.message, "success");
                            });
                        }
                    }
                })
            },
            handleStatusField(values) {
                console.log('handleStatusField:-', values.item.value);
                this.form.status = values.item.value;
            },
            handlePriorityField(values) {
                console.log('handlePriorityField:- ', values);
                this.form.priority = values.item.value;
            },
            selected_provider(result) {
                this.provider_list = result;
                this.filter.provider_list = this.provider_list.map((item) => {
                    return (item.checked == true) ? item.id : null;
                });
                this.filter.provider_list = this.filter.provider_list.filter(n => n);
                this.form.provider_ids = this.filter.provider_list;
            },
            
            
            
            deleteTask(id){
                this.$swal({
					title: 'Are you sure you want to delete records?',
					text: '',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Yes, delete it!',
					cancelButtonText: 'No, cancel please!',
					closeOnConfirm: false,
					closeOnCancel: false,
				}).then((result) => {
					if (result.isConfirmed) {
                        axios.post('patient/task/delete', {'patient_id': this.$route.params.patient_id, 'id': id} )
                            .then((response) => {
                                if (response.data.status == 200) {
                                    this.$filters.moshaToast(response.data.message, "success");
                                    this.getActiveTaskList();
                                } else {
                                    this.$filters.moshaToast(response.data.message, "error");
                                }
                            }).catch(error => {
                                if (error.response.status === 422) {
                                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                                    console.log(Object.values(error.response.data.data).join(', '))
                                } else {
                                    this.$filters.moshaToast(error.response.data.message, "error");
                                }
                            });
                    }
                });
            },
            isChecked(id){
                return this.selectedItems.includes(id);
            },
            checkSelectAll(){
                if (this.selectAllChk) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            checkSelect(id){
                if (this.selectChk[id]) {
                    this.selectedItems.push(id)
                } else {
                    const index = this.selectedItems.indexOf(id);
                    if (index !== -1) {
                        this.selectedItems.splice(index, 1);
                    }
                }

                if (this.selectedItems.length == this.allItems.length) {
                    this.selectAllChk = true;
                } else if (this.selectedItems.length == 0) {
                    this.selectAllChk = false;
                } else {
                    this.selectAllChk = false;
                }
            },
            changeSort(orderColumnName, event){
                event.stopPropagation();
                const th = event.target;

                this.form.sort_by = orderColumnName;
                if (th.classList.contains('active')) {
                    th.classList.remove('active');
                    this.form.sort_order = 'asc';
                } else {
                    th.classList.add('active');
                    this.form.sort_order = 'desc';
                }

                this.getActiveTaskList();
            },
            addTask() {
                window.$("#add-task-model").modal("show");
            },
            closeAddTaskModel() {
                window.$("#add-task-model").modal("hide");
                this.resetTaskForm();
            },
            resetTaskForm() {
                this.loader = false;
                this.form.document_id = null;
                this.form.demographic = '';
                this.form.reminder_message = '';
                this.form.provider_ids = {};
                this.form.id = null;
                this.document_type = '';
                this.document_from = '';
            },
            deleteTemplate(id) {
                console.log('id:-', id);
                const confirmMsg = confirm("Are you sure you want to delete this Task?");
                if (confirmMsg) {
                    // axios.post("task/delete", { 'id': id })
                    //     .then((response) => {
                    //         this.$filters.moshaToast(response.data.message, "success");
                    //         this.getList();
                    //     })
                }
            },
            capitalizeData(data) {
                return data.map(item => ({
                    ...item,
                    patient_name: this.$filters.strToUpperCase(item.patient_name),
                    provider_ids: this.$filters.strToUpperCase(item.provider_ids)
                }));
            },
            taskSearch() {
                if(this.form.keyword.length > 2)
                {
                    this.getActiveTaskList();
                }
                if(this.form.keyword.length == 0)
                {
                    this.getActiveTaskList();
                    if(this.allActiveTasksList) {
                        this.allActiveTasksList = this.capitalizeData(this.allActiveTasksList);
                    }
                }
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
                this.getActiveTaskList();
            },
            minusFilterLen()
            {
                this.filterList.length--;
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    advanceFilter[item.field] = item.value
                });
                this.form.advanceFilter = advanceFilter
            },
        },
        computed: {
            form() {
                return this.$store.state.tasks.form;
            },
            defaultFormData() {
                return this.$store.state.tasks.defaultFormData;
            },
        },
        validations() {
            return this.$store.state.tasks.validationRules;
        },
        setup() {
            return { v$: useVuelidate() };
        },
}
</script>