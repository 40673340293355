<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 billing_module_table">
            <div class="header_boxes">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="range_calc">
                        <div class="form-group syncfusion-component">
                            <ejs-daterangepicker id="daterangepicker" :placeholder="waterMarkText" :format="dateFormat" :openOnFocus="true" ref="daterangepicker" @select="changeDateRangePicker" @change="changeDatePicker" :htmlAttributes="htmlAttributes" :showCustomRangeLabel=false >
                                <e-presets>
                                    <e-preset label="Today" :start='today' :end='today'></e-preset>
                                    <e-preset label="Yesterday" :start='yesterday' :end='yesterday'></e-preset>
                                    <e-preset label="This Week" :start='last7days' :end='today'></e-preset>
                                    <e-preset label="Last 7 days" :start='last7days' :end='today'></e-preset>
                                    <e-preset label="Last 30 days" :start='last30days' :end='today'></e-preset>
                                    <e-preset label="Last 90 days" :start='last30days' :end='today'></e-preset>
                                    <e-preset label="Last 180 days" :start='last30days' :end='today'></e-preset>
                                    <e-preset label="This Month" :start='thisMonth' :end='today'></e-preset>
                                    <e-preset label="Last Month" :start='lastStart' :end='lastEnd'></e-preset>
                                    <e-preset label="All Time" :start='lastStart' :end='lastEnd'></e-preset>
                                </e-presets>
                            </ejs-daterangepicker>
                        </div>
                    </div>
                    <div>
                        <button class="comman_btn big_btn" @click="manageSubmit" v-if="this.selectedItems.length > 0">Resubmit</button>
                    </div>
                </div>
                <div class="d-flex align-items-center my-3">
                    <div class="position-relative">
                        <div class="search">
                            <img class="serch_icon" src="/images/search-icon-gray.svg" alt="">
                            <input type="text" @keyup="patientSearch" class="form-control" placeholder="Search Demographic"
                                v-model="patient_search" ref="globalSearch" :class="{ active: isSelectPatient }">
                        </div>
                        <div class="search_drop_box src_new_box" v-if="patientList.length > 0">
                            <slot v-for="(item, index) in patientList" :key="index">
                                <div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name+', '+item.first_name)">
                                    <div class="row px-2 py-3 align-items-center">
                                        <div class="col-md-12">
                                            <div class="man_haeding">
                                                {{ item.last_name }}, {{ item.first_name }}
                                                <span>({{ item.gender }})</span>
                                            </div>
                                            <ul>
                                                <li>
                                                    DOB: {{ item.dob }}
                                                </li>
                                                <li>
                                                    HIN: {{item.health_insurance_no}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </slot>
                        </div>
                    </div>
                    <div class="selact_dropdown">
                        <selectCheckBox :dataSource='provider_list' :labelName="tagProviderLabel" @clicked="selected_provider" :key="select_box_key" :isShowDoctorIcon="true" :isOpenDivClassChange="true" :isUnselectAll="isUnselectAll" divMainClass="" doctorImgIcon="/images/doctor-man-icon-gray.svg"/>
                    </div>
                    <CustomDropDown :options="status_option" :initialValue="this.status_text" @item-selected="handleItemSelected" fieldName="status"></CustomDropDown>
                    <CustomDropDown :options="type_option" :initialValue="this.type_text" @item-selected="handleItemSelected" fieldName="type"></CustomDropDown>
				</div>
            </div>
            <div class="table-responsive billing-list mang-tbl-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col">SERVICE DATE </th>
                            <th scope="col">DEMOGRAPHIC</th>
                            <th scope="col">PROVIDER </th>
                            <th scope="col">SERVICE </th>
                            <th scope="col">AMOUNT </th>
                            <th scope="col">STATUS </th>
                            <th scope="col" class="text-end">TYPE </th>
                            <th scope="col" class="hover_unset text-end" style="width: 121.5px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.allInvoiceList" :key="index" :class="{ 'active': (selectChk[item.invoice_id] || selectedItems.includes(item.invoice_id) ) }">
                            <td>
                                <input class="form-check-input" type="checkbox" :checked="isChecked(item.invoice_id)" v-model="selectChk[item.invoice_id]" @change="checkSelect(item.invoice_id)" :disabled="item.status !== 'Rejected'">
                            </td>
                            <td>{{ item.service_date }}</td>
                            <td>{{ $filters.strToUpperCase(item.patient_name) }}</td>
                            <td>{{ $filters.strToUpperCase(item.provider_name) }}</td>
                            <td><span class="max-srvc-code">{{ $filters.strToUpperCase(item.service_codes) }}</span></td>
                            <td>{{ '$'+item.total_fee_submitted }}</td>
                            <td>{{ $filters.strToUpperCase(item.status) }}</td>
                            <td class="text-end">
                                {{ 
                                    item.type.toLowerCase() === 'ontario' 
                                    ? (item.claim_type === 'HCP' ? 'OHIP' : item.claim_type) 
                                    : $filters.strToUpperCase(item.type) 
                                }}
                            </td>
                            <td class="text-end">
                                <img src="/images/dots-icon.svg" @click="manageInvoice(item.invoice_id, item.type)" class="dot_icon">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal fade" id="manage-invoice-model" tabindex="-1" style="display: none;" aria-hidden="true" ref="invoicePop">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeInvoiceView" ></button>
                    <div class="d-flex mb-2">
                        <div class="manage_status mr-150">STATUS | <span>{{ $filters.strToUpperCase(this.invoiceDetailsData.status) }}</span></div>
                        <div class="health-num-inl" v-if="this.invoiceDetailsData.status =='Rejected'">EH2 |  <span>Health Card Number is ineligible</span></div>
                    </div>
                    <div class="box">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-9 col-lg-7 col-xl-8 createinvic_tabs">
                                <ul class="export_tabs">
                                    <li :class="{ active: isServiceFax }" @click="changeTabsOpt('service_code')"><span>Service Codes</span></li>
                                    <li :class="{ active: isAdvanceSettings }" @click="changeTabsOpt('advance_settings')" v-if="this.invoice_type_text == 'ONTARIO'"><span>Advanced Settings</span></li>
                                </ul>
                                <div class="d-flex justify-content-between flex-column tab_contain">
                                    
                                
                                    <div class="fax_menu overflow-auto h-100" v-if="isServiceFax">
                                        <div class="srvc-table mang-status-invoice">
                                            <table class="table text-center">
                                                <thead>
                                                    <tr>
                                                        <th style="width:160px;">Billing Code</th>
                                                        <th style="width:130px;">Quantity</th>
                                                        <th style="width:90px;" v-if="this.invoice_type_text == 'ONTARIO'">%</th>
                                                        <th style="width:144px;">Value</th>
                                                        <th style="width:160px;" v-if="this.invoice_type_text != 'ONTARIO'">Description</th>
                                                        <th style="width:160px;" v-if="this.invoice_type_text == 'ONTARIO'">Dx Code</th>
                                                        <th style="width:150px;border-right: 0;">Date</th>
                                                        <th style="border: 0;background: transparent;width: 223px;"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item,index) in this.serviceCodeDetails" :key="index">
                                                        <td>{{ $filters.strToUpperCase(item.service_code) }}</td>
                                                        <td>{{ item.number_of_service }}</td>
                                                        <td v-if="this.invoice_type_text == 'ONTARIO'">{{ item.discount }}</td>
                                                        <td>{{ '$'+ item.fee_submitted  }}</td>
                                                        <td >{{ item.dx_code }}</td>
                                                        <td>{{ item.service_date }}</td>
                                                        <td></td>
                                                    </tr> 
                                                    <!-- <tr>
                                                        <td>Z188A</td>
                                                        <td>1</td>
                                                        <td></td>
                                                        <td>$5.25</td>
                                                        <td>355</td>
                                                        <td>2024-03-06</td>
                                                        <td class="edit_delet_link">
                                                            <span class="popup_link hidan_design">View</span>
                                                            <span class="popup_link hidan_design">Delete</span>
                                                        </td>
                                                    </tr>                                                    -->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
    
                                    <div class="fax_menu" v-if="isAdvanceSettings">
                                        <div class="ad-from-sect">
                                            <div class="row">
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div class="adv-stg-from">
                                                        <label>HEALTH NUMBER</label>
                                                        <input v-model="this.advanceSettingData.healthNumber" type="text" class="form-control" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div class="adv-stg-from">
                                                        <label>VERSION CODE</label>
                                                        <input v-model="this.advanceSettingData.versionCode" type="text" class="form-control" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div class="adv-stg-from">
                                                        <!-- <label>PROVINCE CODE</label>
                                                        <input v-model="this.advanceSettingData.provinceCode" type="text" class="form-control" readonly> -->
                                                        <label>PROVINCE CODE</label>
                                                        <CustomDropDown :options="stateData"
											:initialValue="this.invoice_details.province_code_text" :isDisabled="true"
											v-model="this.invoice_details.province_code" @item-selected="handleItemSelected"
											fieldName="province_code"></CustomDropDown>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div class="adv-stg-from">
                                                        <label>CLAIM PAYEE</label>
                                                        <input v-model="this.advanceSettingData.claimPayee" type="text" class="form-control" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div class="adv-stg-from">
                                                        <label>LAST NAME</label>
                                                        <input v-model="this.advanceSettingData.lastName" type="text" class="form-control" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div class="adv-stg-from">
                                                        <label>FIRST NAME</label>
                                                        <input v-model="this.advanceSettingData.firstName" type="text" class="form-control" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div class="adv-stg-from">
                                                        <label>DATE OF BIRTH</label>
                                                        <input v-model="this.advanceSettingData.dateOfBirth" type="text" class="form-control" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div class="adv-stg-from">
                                                        <label>GENDER</label>
                                                        <input v-model="this.advanceSettingData.gender" type="text" class="form-control" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from overhd-drop-tect drp-width-big">
                                                        <label>CLAIM TYPE</label>
                                                        <CustomDropDown :options="claim_type_option" :initialValue="claim_type_option[0].title"
                                                                v-model="this.advanceSettingData.claimType" @item-selected="handleItemSelected" :isDisabled="true" :isFromViewInvoice="true"
                                                                fieldName="claim_type"></CustomDropDown>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>REFERRAL ID</label>
                                                    <input type="text" class="form-control" readonly v-model="this.invoice_details.referral_hcp_number">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>REGISTRATION NUMBER (FOR RMB)</label>
                                                        <input data-v-f6d43346="" type="text" class="form-control" v-model="this.invoice_details.rmb_registration_number" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>VISIT LOCATION</label>
                                                        <input data-v-f6d43346="" type="text" class="form-control" readonly v-model="this.invoice_details.visit_location">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>SERVICE LOCATION INDICATOR</label>
                                                        <input data-v-f6d43346="" type="text" class="form-control" readonly v-model="this.invoice_details.service_location">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>ADMISSION DATE</label>
                                                        <input data-v-f6d43346="" type="text" class="form-control" readonly v-model="this.invoice_details.inpatient_admission_date">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>REFERRING LAB LICENSE NUMBER</label>
                                                        <input data-v-f6d43346="" type="text" class="form-control" readonly v-model="this.invoice_details.referring_lab_license_number">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>GROUP NUMBER OVERRIDE</label>
                                                        <input data-v-f6d43346="" type="text" class="form-control" readonly v-model="this.invoice_details.group_number">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>SPECIALTY OVERRIDE</label>
                                                        <input data-v-f6d43346="" type="text" class="form-control" readonly v-model="this.invoice_details.specialty_code">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>MASTER NUMBER</label>
                                                        <input data-v-f6d43346="" type="text" class="form-control" readonly v-model="this.invoice_details.master_number">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <div class="adv-stg-from">
                                                        <label>MANUAL REVIEW</label>
                                                        <label class="switch d-block">
                                                            <input type="checkbox" id="darkModeToggle" disabled :checked="this.invoice_details.manual_review=='1'">
                                                            <span class="sw-slider"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="ttl-sect">
                                            <div class="d-flex justify-content-end">
                                                <p class="t-ttl-h">TOTAL |</p>
                                                <p class="ttl-price">${{ this.serviceCodeTotalFees }}</p>
                                            </div>
                                        </div>
                                        <div class="btn_part d-flex justify-content-between">
                                            <button class="comman_brdr_btn big_btn" @click="invoiceDelete()">Delete</button>
                                            <div>
                                                <button class="comman_btn big_btn mx20" v-if="this.invoiceDetailsData.status =='Rejected'" @click="closeInvoiceView">Edit</button>
                                                <button class="comman_brdr_btn big_btn mx20" v-if="this.invoiceDetailsData.status =='Completed'" @click="printInvoice">Print</button>
                                                <button class="comman_brdr_btn big_btn mx20 me-0" @click="closeInvoiceView">Close</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-4 invoice-left-bar">
                                <div>
                                    <div class="pt-dashbrd">
                                        <p class="lbl-iput"><span>INVOICE TYPE</span></p>
                                        <div class="heading_selact_drodwn inv-type-drop-li">
                                            <CustomDropDown :options="invoice_type_opt" :initialValue="this.invoice_type_text" @item-selected="handleItemSelected" fieldName="invoice_type"></CustomDropDown>
                                        </div>
                                    </div>
    
                                    <p class="lbl-iput"><span>DEMOGRAPHIC</span></p>
                                    <div class="position-relative">
                                        <div class="search">
                                            <img class="serch_icon" src="/images/search-icon.svg" alt="">
                                            <input type="text" @keyup="patientSearch" class="form-control w-100" placeholder="Search Demographic"
                                                v-model="invoiceViewPatientName" @blur="patientSearch" readonly>
                                        </div>
                                        <div class="search_drop_box src_new_box" v-if="patientList.length > 0">
                                            <slot v-for="(item, index) in patientList" :key="index">
                                                <div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name+', '+item.first_name)">
                                                    <div class="row px-2 py-3 align-items-center">
                                                        <div class="col-md-12">
                                                            <div class="man_haeding">
                                                                {{ item.last_name }}, {{ item.first_name }}
                                                                <span>({{ item.gender }})</span>
                                                            </div>
                                                            <ul>
                                                                <li>
                                                                    DOB: {{ item.dob }}
                                                                </li>
                                                                <li>
                                                                    HIN: {{item.health_insurance_no}}
                                                                </li>
                                                            </ul>
                                                        </div>                                                       
                                                    </div>
                                                </div>
                                            </slot>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="mt-20 pt-dashbrd">
                                    <div class="row p-0">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                                            <p class="ptnt-dtls"><span>Health # |</span>{{ this.patientHcvDetails }}</p>
                                        </div>
                                        <div class="col-8 col-sm-8 col-md-8 col-lg-8 p-0">
                                            <p class="ptnt-dtls"><span>Date of Birth |</span>{{ this.patientDateOfBirth }}</p>
                                        </div>
                                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 p-0">
                                            <p class="ptnt-dtls ms-0"><span class="w-auto">Age |</span>{{ this.patientAge }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p class="lbl-iput"><span>PROVIDER</span></p>
                                    <div class="selact_dropdown bigs-slct-dr h-auto d-inline-block w-100">
                                        <selectCheckBox :isDisabled="true"  :dataSource='provider_list' :labelName="tagProviderLabel" @clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key" :isShowDoctorIcon="true" :isUnselectAll="isUnselectAll" divMainClass="" :isMultiSelection="isMultiSelection"/>
                                    </div>
                                </div>
                                <div class="mt-10 pt-dashbrd">
                                    <div class="row p-0">
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 p-0">
                                            <p class="ptnt-dtls"><span>Provider ID |</span>{{this.invoice_details.cpsid}}</p>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 p-0">
                                            <p class="ptnt-dtls"><span>Specialty |</span>{{this.invoice_details.specialty_code}}</p>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 p-06">
                                            <p class="ptnt-dtls"><span class="w-auto">Visit Location |</span>{{this.invoice_details.visit_location_short}}</p>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 p-0">
                                            <p class="ptnt-dtls"><span>Group # |</span>{{this.invoice_details.group_billing_no}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-20">
                                    <p class="smallhead">PREVIOUS INVOICES</p>
                                    <div class="table-responsive previ-innce">
                                        <table class="table text-center">
                                            <thead>
                                                <tr>
                                                    <th style="width: 105px;">Date</th>
                                                    <th>Codes</th>
                                                    <th>Dx</th>
                                                    <th style="width: 100px;">Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr  v-for="(item,index) in this.previousInvoiceData" :key="index">
                                                    <td class="white-space-pre">{{ item.service_date }}</td>
                                                    <td>{{ $filters.strToUpperCase(item.service_code) }}</td>
                                                    <td>{{ item.dx_code }}</td>
                                                    <td>{{ $filters.strToUpperCase(item.type) }}</td>
                                                </tr>
                                                <!-- <tr>
                                                    <td>2024-01-01</td>
                                                    <td>A007A</td>
                                                    <td>250</td>
                                                    <td>ONTARIO</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="prescription-fax-model" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    <div class="box">
                        <div class="row patient_chart justify-content-center edit_record_page">
                            <div class="col-lg-5">
                                <h4 class="box-title mt-0">Preferred Pharmacy</h4>
                                <div class="box-body containt_detail">
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Pharmacy Name</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">ABC pvt. ltd</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Address</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">101 jophn's st san jose califonia</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Postal Code</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">9001</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Fax Number</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">987654120</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Phone Number</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">4555</div>
                                    </div>
                                    </div>
                                </div>
                                <h4 class="box-title">Signature</h4>
                                <div class="box-body containt_detail mb-3">
                                    <div id="signature-control">
                                    <div class="e-sign-heading"><span id="signdescription"></span></div>
                                    <div class="col-9 me-0 ms-auto" style="height: 60px;">
                                        <canvas id="signature" class="e-control e-signature e-lib" role="img" aria-label="signature" tabindex="-1" height="60" style="height: 100%; width: 100%;" width="429"></canvas>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="pdf_viewer">
                                    <ejs-pdfviewer 
                                        id="pdfViewer" 
                                        ref="pdfviewer" 
                                        :documentPath="documentPath" 
                                        :resourceUrl="resourceUrl"
                                        @documentLoad="onDocumentLoad" 						
                                        :enableTextSearch="true"
                                        :enableHyperlink="true"
                                        :enableMagnification="true"
                                        :enableToolbar="true"
                                        :enableNavigation="true"
                                        :enableThumbnail="false"
                                        :enableBookmark="true"
                                        :enableTextSelection="true"
                                        :enablePrint="true"
                                        :enableAnnotation="true"
                                        :enableFormFields="false"
                                        :enableFormDesigner="false" 
                                        :openFile='false'
                                    >
                                    </ejs-pdfviewer>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="btn_part d-flex justify-content-around">
                                    <button class="comman_brdr_btn">Cancel</button>
                                    <button class="comman_brdr_btn">Print</button>
                                    <button class="comman_btn">Fax</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sidebar from './sidebar.vue'
// import useVuelidate from "@vuelidate/core";
import moment from "moment";
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
import selectCheckBox from "../base/selectCheckBox.vue";

export default {
    // setup: () => {
    //     return { v$: useVuelidate() };
    // },
    // validations() {
    //     return {
    //         form: this.$store.state.patient_document.validationRules.form,
    //     }
    // },
    data() {
        return {
            waterMarkText: "Select a Range",
			dateFormat: "yyyy/MM/dd",
			htmlAttributes: { showCustomRangeLabel: true },
            today: new Date(new Date().toDateString()),
			yesterday: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()),
			last7days: new Date(new Date(new Date().setDate(new Date().getDate() - 7)).toDateString()),
			weekStart: new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString()),
			weekEnd: new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
				- (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()),
			last30days: new Date(new Date(new Date().setDate(new Date().getDate() - 30)).toDateString()),
			lastStart: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
			lastEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
			thisMonth: new Date(moment().subtract(1, 'month').startOf('month')),
            minDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),

            type_option:[
                { value: "M", title: "OHIP" },
                { value: "F", title: "Ontario - WCB" },
                { value: "F", title: "Ontario - RMB" },
                { value: "F", title: "Private" },
                { value: "F", title: "All" },
            ],
            status_option:[
                { value: "M", title: "Pending" },
                { value: "F", title: "Submitted" },
                { value: "F", title: "Rejected" },
                { value: "F", title: "Completed" },
                { value: "F", title: "Deleted" },
                { value: "F", title: "All" },
            ],
            claim_type_option:[
                { value: "HCP", title: "HCP - Health Claims Program | <span class='fw-300 fs-14'>Ontario</span>" },
                { value: "WCB", title: "WCB - Workplace Compensation Board" },
                { value: "RMB", title: "RMB - Reciprocal Medical Billing | <span class='fw-300 fs-14'>Other Provinces besides Quebec<span>" },
            ],
            stateData:[
                { value: "AB", title: "AB - Alberta" },
                { value: "BC", title: "BC - British Columbia" },
                { value: "MB", title: "MB - Manitoba" },
                { value: "NB", title: "NB - New Brunswick" },
                { value: "NL", title: "NL - Newfoundland" },
                { value: "NS", title: "NS - Nova Scotia" },
                { value: "ON", title: "ON - Ontario" },
                { value: "PE", title: "PE - Prince Edward Island" },
                { value: "SK", title: "SK - Saskatchewan" },
                { value: "NT", title: "NT - Northwest Territories" },
                { value: "NU", title: "NU - Nunavut" },
                { value: "YT", title: "YT - Yukon" }
            ],
            status_text:'Select Status',
            type_text:'Select Type',
            patientList:[],
            patient_search:"",
            provider_list:[],
            tagProviderLabel:"Select Provider(s)",
            form:{
                provider_ids:[],
                status: '',
                type: '',
                patient_id: null,
                start_date: '',
                end_date: '',
            },
            allInvoiceList: [],
            isServiceFax:true,
            isAdvanceSettings:false,
            serviceCodeDetails: [],
            serviceCodeTotalFees: null,
            invoiceDetailsData: [],
            invoiceViewPatientName: '',
            patientAge: null,
            patientDateOfBirth: '',
            patientHcvDetails: '',
            patientHCV: '',
            patientProviderId: null,
            patientProviderName: '',
            previousInvoiceData: [],
            advanceSettingData: {
                healthNumber: '',
                versionCode: '',
                provinceCode: '',
                claimPayee: '',
                lastName: '',
                firstName: '',
                dateOfBirth: '',
                gender: '',
                claimType: '',
                billingStatus: '',
                referralHcpNumber: '',
            },
            retrieveInvoiceId: null,
            retrieveInvoiceType: '',
            selectAllChk: false,
            selectChk: [],
            allItems:[],
            selectedItems:[],
            isUnselectAll:false,
            isSelectPatient: false,
            displayProviderDiv:"active",
            invoice_details:[],
        }     
    },
    components: {
        sidebar,
        CustomDropDown,
        selectCheckBox
    },
    methods: {
        checkSelect(id){
            if (this.selectChk[id]) {
                this.selectedItems.push(id)
            } else {
                const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
                if (index !== -1) {
                    this.selectedItems.splice(index, 1); // Remove id from selectedItems
                }
            }

            if (this.selectedItems.length == this.allItems.length) {
                this.selectAllChk = true;
            } else if (this.selectedItems.length == 0) {
                this.selectAllChk = false;
            } else {
                this.selectAllChk = false;
            }
        },
        isChecked(id){
            return this.selectedItems.includes(id);
        },
        checkSelectAll(){
            if (this.selectAllChk) {
                this.selectedItems = [...this.allItems];
            } else {
                this.selectedItems = [];
                this.selectChk = [];
            }
        },
        closeInvoiceView(){
            window.$("#manage-invoice-model").modal("hide");
            this.serviceCodeDetails = [];
            this.serviceCodeTotalFees = null;
            this.invoiceDetailsData = [];
            this.invoiceViewPatientName = '';
            this.patientAge = null;
            this.patientHcvDetails = '';
            this.patientHCV = '';
            this.patientProviderId = null;
            this.patientProviderName = '';
            this.previousInvoiceData = [];
            this.advanceSettingData.healthNumber = '';
            this.advanceSettingData.versionCode = '';
            this.advanceSettingData.provinceCode = '';
            this.advanceSettingData.claimPayee = '';
            this.advanceSettingData.lastName = '';
            this.advanceSettingData.firstName = '';
            this.advanceSettingData.dateOfBirth = '';
            this.advanceSettingData.gender = '';
            this.advanceSettingData.claimType = '';
            this.advanceSettingData.billingStatus = '';
            this.advanceSettingData.referralHcpNumber = '';
            this.retrieveInvoiceType = '';
            this.retrieveInvoiceId = null;
            this.isAdvanceSettings = false;
            this.isServiceFax = true;
        },
        changeDateRangePicker(){
            const dateRangePicker = this.$refs.daterangepicker.ej2Instances;
            const range = dateRangePicker.getSelectedRange();
            let startDateVal = this.formatDate(range.startDate);
            let endDateVal = this.formatDate(range.endDate);
            // this.selectedRange = `Start Date: ${this.formatDate(range.startDate)}, End Date: ${this.formatDate(range.endDate)}`;
            if(startDateVal && endDateVal)
            {
                if((this.form.start_date && this.form.start_date != startDateVal) && (this.form.end_date && this.form.end_date != endDateVal))
                {
                    this.form.start_date = startDateVal;
                    this.form.end_date =  endDateVal;
                    this.getInvoiceList();
                }
                else
                {
                    this.form.start_date = startDateVal;
                    this.form.end_date =  endDateVal;
                    this.getInvoiceList();
                }
            }
        },
        formatDate(date) {
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        changeTabsOpt(type){
            this.isServiceFax = false
            this.isAdvanceSettings = false
            if (type == 'service_code') {
                this.isServiceFax = true
            } else if (type == 'advance_settings') {
                this.isAdvanceSettings = true
            }
        },
        patientSearch() {
            this.isSelectPatient = false;
            if (this.patient_search.length > 2) {
                axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
                    .then((response) => {
                        this.patientList = response.data.data;
                })
            }
            if (this.patient_search.length == 0) {
                this.patientList = [];
                this.getInvoiceList();
            }
            if (this.patient_search.length > 0) {
                this.isSelectPatient = true;
            }
        },
        handleItemSelected(values) {
            console.log(values);
            if(values.fieldName == 'status' && (values.item.value && values.item.title))
            {
                this.form.status = values.item.title;
                this.getInvoiceList();
            }
            if(values.fieldName == 'type' && (values.item.value && values.item.title))
            {
                this.form.type = values.item.title;
                this.getInvoiceList();
            }
           else if(values.fieldName == "province_code")
				{
					this.form.province_code_text = values.item.title
					this.form[values.fieldName] = values.item.value
				}
            console.log(values.fieldName, values.item.value, values.item.title);
        },
        selectPatient(id, patient_name){
            // this.$store.state.patient_document.form.patient_id = id
            if(id && patient_name)
            {
                this.patient_search = patient_name;
                this.form.patient_id = id;
                this.isSelectPatient = true;
                this.getInvoiceList();
                this.patientList = [];
                // this.patient_search = '';
            }
        },
        selected_provider(result){
            this.form.provider_ids = result.filter(item => item.checked).map(item => item.id);
            if (this.form.provider_ids.length == 1) {
                this.tagProviderLabel = `${this.form.provider_ids.length } Provider Selected`;
            } else if (this.form.provider_ids.length > 1){
                this.tagProviderLabel = `${this.form.provider_ids.length } Providers Selected`;
            } else {
                this.tagProviderLabel = "Select Provider(s)";
            }
            this.getInvoiceList();
        },
        getProviderList() {
            axios.post("json_list/provider", { roleId: 2 })
                .then((response) => {
                    this.provider_list = response.data.data.map((item) => {
                        return { id: item.id, name: item.full_name, checked: false };
                    });
                });
        },
        invoiceDelete(){
            this.$swal({
                title: 'Are you sure you want to delete invoice?',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('invoice/delete', { 'invoice_id' : this.retrieveInvoiceId, 'type' : this.retrieveInvoiceType } )
                    .then((response) => {
                        if (response.status == 200) {
                            this.closeInvoiceView();
                            this.getInvoiceList();
                            this.$store.state.loader = false;
                            this.$filters.moshaToast(response.data.message, "success");
                            window.$("#manage-invoice-model").modal("hide");
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            console.log(Object.values(error.response.data.data).join(', '))
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
                }
                else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            })
        },
        manageInvoice(invoiceId, type) {
            this.invoice_type_text = this.$filters.strToUpperCase(type);
            axios.post('invoice/retrieve', { 'invoice_id' : invoiceId, 'type' : type } )
            .then((response) => {
                if (response.status == 200) {
                    this.retrieveInvoiceId = invoiceId;
                    this.retrieveInvoiceType = type;
                    this.serviceCodeDetails = response.data.data.invoice_details;
                    this.invoice_details = response.data.data.invoice_details_data;
                    const [code, description] = this.invoice_details.visit_location.split(' - ');
                    this.invoice_details.visit_location_short = code
                    console.log(description)

                    this.serviceCodeTotalFees = response.data.data.total;
                    this.invoiceDetailsData = response.data.data.invoice_details_data;
                    this.invoiceViewPatientName = this.$filters.strToUpperCase(this.invoiceDetailsData.patient_name);
                    this.patientDateOfBirth = this.invoiceDetailsData.dob;
                    this.patientAge = this.$filters.patientCalculatedAge(this.invoiceDetailsData.dob, true);
                    this.patientHCV = (this.invoiceDetailsData.is_hcv_verified ==  1)? "(ON)" : "(OFF)";
                    this.patientHcvDetails = this.$filters.strToUpperCase(this.invoiceDetailsData.health_insurance_no + ' ' +this.invoiceDetailsData.health_card_ver) + ' ' + this.patientHCV;
                    this.patientProviderId = this.invoiceDetailsData.provider_id;
                    this.patientProviderName = this.invoiceDetailsData.provider_name;
                    this.previousInvoiceData = response.data.data.previous_invoice_data;
                    this.tagProviderLabel = this.patientProviderName;

                    this.advanceSettingData.healthNumber = this.$filters.strToUpperCase(this.invoiceDetailsData.health_insurance_no);
                    this.advanceSettingData.versionCode = this.$filters.strToUpperCase(this.invoiceDetailsData.health_card_ver);
                    this.advanceSettingData.dateOfBirth = this.invoiceDetailsData.dob;
                    this.advanceSettingData.billingStatus = this.invoiceDetailsData.status;
                    if(this.invoiceDetailsData.claim_type)
                    {
                        let claimOption = this.claim_type_option.filter(option => option.value === this.invoiceDetailsData.claim_type);
                        if (claimOption.length > 0) {
                            console.log(claimOption[0].title);
                        }
                        this.advanceSettingData.claimType = claimOption[0].title;
                    }

                    this.advanceSettingData.referralHcpNumber = (this.invoiceDetailsData.referral_hcp_number != "")? this.invoiceDetailsData.referral_hcp_number : '';
                    if(this.invoiceDetailsData.state_name && this.invoiceDetailsData.state_sort_name)
                    {
                        this.advanceSettingData.provinceCode = this.invoiceDetailsData.state_sort_name + ' - ' + this.invoiceDetailsData.state_name;
                    }else if(this.invoiceDetailsData.state_name)
                    {
                        this.advanceSettingData.provinceCode = this.invoiceDetailsData.state_name;
                    }
                    this.advanceSettingData.provinceCode = this.invoiceDetailsData.province_code;
                    console.log('state_name:- ', this.invoiceDetailsData.state_name, 'state_sort_name:- ', this.invoiceDetailsData.state_sort_name, this.advanceSettingData.provinceCode);
                    if(this.invoiceDetailsData.claim_payee)
                    {
                        if(this.invoiceDetailsData.claim_payee == "P")
                        {
                            this.advanceSettingData.claimPayee = this.invoiceDetailsData.claim_payee + " - Provider"; 
                        }else if(this.invoiceDetailsData.claim_payee == "S")
                        {
                            this.advanceSettingData.claimPayee = this.invoiceDetailsData.claim_payee + " - Patient"; 
                        }
                    }
                    console.log('claim_type:- ', this.invoiceDetailsData.claim_type, 'claim_payee:- ', this.invoiceDetailsData.claim_payee, this.advanceSettingData.claimPayee);
                    if(this.invoiceDetailsData.gender)
                    {
                        switch (this.invoiceDetailsData.gender) {
                            case 'M':
                                this.advanceSettingData.gender = 'Male';
                                break;
                            case 'F':
                                this.advanceSettingData.gender = 'Female';
                                break;
                            case 'O':
                                this.advanceSettingData.gender = 'Other';
                                break;
                            case 'U':
                                this.advanceSettingData.gender = 'Unknown';
                                break;
                        
                            default:
                                break;
                        }
                    }
                    this.advanceSettingData.firstName = this.$filters.strToUpperCase(this.invoiceDetailsData.first_name);
                    this.advanceSettingData.lastName = this.$filters.strToUpperCase(this.invoiceDetailsData.last_name);
                    this.$store.state.loader = false;
                    window.$("#manage-invoice-model").modal("show");
                } else {
                    this.$filters.moshaToast(response.data.message, "error");
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    console.log(Object.values(error.response.data.data).join(', '))
                } else {
                    this.$filters.moshaToast(error.response.data.message, "error");
                }
            });
        },
        manageSubmit() {
            this.$store.state.loader = true;
            axios.post('invoice/resubmit', { 'invoice_ids': this.allItems })
                .then((response) => {
                    if (response.status == 200) {
                        this.allItems = [];
                        this.selectChk = [];
                        this.selectAllChk = false;
                        this.getInvoiceList();
                        this.$store.state.loader = false;

                        this.$filters.moshaToast(response.data.message, "success");
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
        },
        getInvoiceList() {
            this.$store.state.loader = true;
            axios.post('invoice/list', this.form)
                .then((response) => {
                    if (response.status == 200) {
                        this.allInvoiceList = response.data.data;
                        this.$store.state.loader = false;

                        this.allInvoiceList.forEach(item => {
                            if(item.status === 'Rejected')
                            {
                                this.allItems.push(item.invoice_id)
                            }
                        });
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
        },
        changeDatePicker() {
            const dateRangePicker = this.$refs.daterangepicker.ej2Instances;
            const range = dateRangePicker.getSelectedRange();
            let startDateVal = this.formatDate(range.startDate);
            let endDateVal = this.formatDate(range.endDate);
            // this.selectedRange = `Start Date: ${this.formatDate(range.startDate)}, End Date: ${this.formatDate(range.endDate)}`;
            if(startDateVal && endDateVal)
            {
                this.form.start_date = startDateVal;
                this.form.end_date =  endDateVal;
            }

            this.getInvoiceList();
		},
        handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target) && !event.target.closest(`.search_drop_box`)) {
				this.patientList = [];
			}
            const popupeElement = this.$refs.invoicePop;
            if(popupeElement && !popupeElement.contains(event.target))
            {
                this.closeInvoiceView();
            }
		},
        printInvoice(){
            let invoiceId = this.retrieveInvoiceId
            const url = this.$router.resolve({
                        name: 'BillingReceiptPrint', params: { id:invoiceId, type:'private'}
                    }).href;

            window.open(url, '_blank');

        }

    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        this.getProviderList(),
        this.getInvoiceList()
    },
    computed: {

    }
}
</script>

<style scoped>
.mang-tbl-list .form-check-input:disabled {background-color: transparent;opacity: 0.4;}
</style>