<template>
	<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
        <div class="sticky-scroll-box">
            <ul class="side-bar-menu" data-widget="tree">
                <li :class="{ 'current': $route.name === 'PatientEformList' }"><b><router-link :to="{ name: 'PatientEformList', params: { patient_id: $route.params.patient_id,  }}" class="">Add eForm</router-link></b></li>
                <li :class="{ 'current': $route.name === 'PatientCurrentEformList' }"><b><router-link :to="{ name: 'PatientCurrentEformList', params: { patient_id: $route.params.patient_id,  }}" class="">Current eForms</router-link></b></li>
                <li><b>eForm Revisions</b></li>
                <li :class="{ 'current': $route.name === 'PatientDeletedEformList' }"><b><router-link :to="{ name: 'PatientDeletedEformList', params: { patient_id: $route.params.patient_id,  }}" class="">Deleted eForms</router-link></b></li>
            </ul>
        </div>
    </div>
</template>