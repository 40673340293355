<template>
	<div class="row">
		<div class="col-12 col-sm-6 col-md-6">
			<div class="d-flex align-items-center">
				<span class="me-1">Show: </span>
				<select class="form-control me-1" style="width: 80px" v-model="per_page" @change="getData(1)">
					<option value="10">10</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="100">100</option>
					<option value="500">500</option>
					<option value="1000">1000</option>
				</select>
				<span class="me-1"> entries</span>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-6">
			<div class="d-flex align-items-center float-end">
				<span class="me-1">Search:</span>
				<input class="form-control" type="text" name="search_text" id="search_text" v-model="search_text"
					@input="getData(1)" />
			</div>
		</div>
	</div>
	<div class="table-responsive mt-20">
		<table :id="dataType" class="table table-hover">
			<thead>
				<tr>
					<th v-for="(item, index) in getTableHeaders" :key="index">
						<span v-for="(col, i) in item" :key="i">
							<template v-if="col != ''">
								{{ col }}
								<span class="float-end sorting" :id="index" @click="sorting(i, $event.currentTarget, index)"
									v-if="col != 'Action' && i != ''">
									<a href="#" class="text-mute" :id="'1_' + i"><i class="mdi mdi-arrow-up"></i></a>
									<a href="#" class="text-mute" :id="'2_' + i"><i class="mdi mdi-arrow-down"></i></a>
								</span>
							</template>
							<template v-else>
								<input id="checkAll" type="checkbox" class="filled-in chk-col-info" @click="checkall">
								<label for="checkAll" class="m-0 text-start fs-16" style="padding-left: 18px;"></label>
							</template>
						</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in getTableContents" :key="index" class="hover-primary">
					<slot v-bind:item="item"></slot>
				</tr>
			</tbody>
		</table>
		<div style="float: left; margin-top: 5px" class="dataTables_info" id="example1_info" role="status"
			aria-live="polite">
			{{ `Showing ${getPagination.from != null ? getPagination.from : 0} to ${getPagination.to != null ?
				getPagination.to : 0} of ${getPagination.total} entries` }}
		</div>
		<div class="float-end">
			<ul class="pagination">
				<li class="paginate_button page-item" :class="isDisabled('previous')"
					@click="getData(getPagination.current_page - 1)">
					<a href="#" aria-controls="example1" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
				</li>
				<template v-if="getPagination.current_page >= 5 && getPagination.last_page != 5">
					<li>
						<a @click="getData(1)" class=""> 1 </a>
					</li>
					<span class="ellipsis">...</span>
				</template>
				<li>
					<a v-for="pageNumber in pageNumbers" :key="pageNumber" @click="getData(pageNumber)"
						:class="getPagination.current_page == pageNumber ? 'current' : ''"> {{ pageNumber }} </a>
				</li>
				<template
					v-if="getPagination.current_page <= (getPagination.last_page - 4) && getPagination.last_page != 5">
					<span class="ellipsis">...</span>
					<li>
						<a @click="getData(getPagination.last_page)" class=""> {{ getPagination.last_page }} </a>
					</li>
				</template>
				<li class="paginate_button page-item next" :class="isDisabled('next')" id="example1_next"
					@click="getData(getPagination.current_page + 1)">
					<a href="#" aria-controls="example1" data-dt-idx="2" tabindex="0" class="page-link">Next</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import $ from "jquery";

export default {
	emits: ['checkall'],
	props: {
		dataType: {
			type: String
		},
		url: {
			type: String
		},
		dataMethod: {
			type: String,
			default: "post"
		},
		onLoadCall: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			isNextDisable: false,
			isPrevDisable: false,
			search_text: "",
			per_page: 10,
			sort_by: "",
			sort_order: "",
			localTimezone: null,
			queryStr: null,
		};
	},
	computed: {
		getTableHeaders() {
			return this.$store.state[this.dataType].header;
		},
		getTableContents() {
			const arry = this.$store.state[this.dataType].list.map((obj, index) => ({ ...obj, sr_no: index + 1 }));
			return arry;
		},
		getPagination() {
			return this.$store.state[this.dataType].pagination;
		},
		getFilter() {
			return this.$store.state[this.dataType].filter;
		},
		pageNumbers() {
			let pageRange = 5; // Number of visible pages
			let halfRange = Math.floor(pageRange / 2);
			let firstPage = Math.max(this.getPagination.current_page - halfRange, 1);
			if (this.getPagination.current_page < 5) {
				firstPage = 1;
			}
			let lastPage = Math.min(firstPage + pageRange - 1, this.getPagination.last_page);
			let pages = [];
			if (lastPage - firstPage + 1 < pageRange) {
				// If the range is less than pageRange, adjust the start page accordingly
				let diff = pageRange - (lastPage - firstPage + 1);
				firstPage = Math.max(firstPage - diff, 1);
			}
			for (let page = firstPage; page <= lastPage; page++) {
				pages.push(page);
			}
			if (this.getPagination.current_page >= 5 && this.getPagination.current_page <= (this.getPagination.last_page - 4)) {
				pages = pages.slice(1, pages.length - 1);
			}
			return pages;
		}
	},

	methods: {
		checkall() {
			this.$emit('checkall', true);
		},
		isDisabled(type) {
			if (type === "previous") {
				if (this.getPagination.current_page === 1) {
					this.isPrevDisable = true;
					return "disabled";
				}
			}
			if (type === "next") {
				if (this.getPagination.current_page === this.getPagination.last_page) {
					this.isNextDisable = true;
					return "disabled";
				}
			}
		},
		sorting(name, ele, index) {
			$(".sorting").each(function (i) {
				if (i != index) {
					$("#" + i + ">a").addClass("text-mute");
				} else {
					$("#" + i + ">a>").removeClass("text-mute");
				}
			});
			if ($(ele).children().eq(0).hasClass("text-dark")) {
				$(ele).children().eq(0).removeClass("text-dark").addClass("text-mute");
				$(ele).children().eq(1).removeClass("text-mute").addClass("text-dark");
				this.sort_order = "DESC";
			} else {
				$(ele).children().eq(0).removeClass("text-mute").addClass("text-dark");
				$(ele).children().eq(1).removeClass("text-dark").addClass("text-mute");
				this.sort_order = "ASC";
			}

			this.sort_by = name;
			this.getData(1);
		},
		async getData(page) {
			if ((page > 0 && page <= this.getPagination.last_page) || page === 1) {
				if (this.dataMethod == "post") {
					let date = new Date();
					let timezoneOffset = date.getTimezoneOffset();
					let hoursOffset = Math.abs(timezoneOffset / 60);
					let timeZone = timezoneOffset > 0 ? '-' : '+';
					timeZone += ('0' + hoursOffset).slice(-1) + ':' + ('0' + (timezoneOffset % 60)).slice(-2);
					this.queryStr = {
						page: page,
						search_text: this.search_text,
						per_page: this.per_page,
						sort_by: this.sort_by,
						sort_order: this.sort_order,
						localTimezone: timeZone,
					};

					if (this.dataType == 'patientVital') {
						this.queryStr.patient_id = this.$route.params.patient_id;
						this.queryStr.vital_type = this.$route.params.id;
					} else if (this.dataType == 'task') {
						this.queryStr.patient_id = this.getFilter.patient_id
						// this.queryStr.patient_name=this.getFilter.patient_name
						this.queryStr.status = this.getFilter.status
						this.queryStr.created_user = this.getFilter.created_user
						this.queryStr.assigned_to = this.getFilter.assigned_to
						this.queryStr.priority = this.getFilter.priority
					} else if (this.dataType == 'patient_document') {
						this.queryStr.patient_id = this.$route.params.patient_id
						this.queryStr.document_type_id = this.getFilter.document_type_id
						this.queryStr.view_status = this.getFilter.view_status
					} else if (this.dataType == 'current_eform') {
						this.queryStr.patient_id = this.$route.params.patient_id
					} else if (this.dataType == 'deleted_eform') {
						this.queryStr.patient_id = this.$route.params.patient_id
						this.queryStr.only_deleted = true
					}else if(this.dataType == 'inbox'){
						this.queryStr.filter_provider_id=this.getFilter.filter_provider_id;
						this.queryStr.patient_name=this.getFilter.patient_name;
						// this.queryStr.observation_date=this.getFilter.observation_date;
						this.queryStr.assigned_status=this.getFilter.assigned_status;

						if (this.getFilter.observation_date && this.getFilter.observation_date != 'Invalid date') {
							let startDate = new Date(this.getFilter.observation_date[0]).toISOString().split('T')[0]
							let endDate = new Date(this.getFilter.observation_date[1]).toISOString().split('T')[0]

							this.queryStr.observation_date=[startDate,endDate]
						}

						if (this.getFilter.patient_dob && this.getFilter.patient_dob != 'Invalid date') {
							let startDate = new Date(this.getFilter.patient_dob[0]).toISOString().split('T')[0]
							let endDate = new Date(this.getFilter.patient_dob[1]).toISOString().split('T')[0]

							this.queryStr.patient_dob=[startDate,endDate]
							// this.queryStr.patient_dob=this.getFilter.patient_dob;
						}
					} else if(this.dataType == 'fax'){
						this.queryStr.user_id=this.getFilter.user_id;
					} else if(this.dataType == 'user'){
						this.queryStr.provider_no=this.getFilter.provider_no;
						this.queryStr.roleId=this.getFilter.roleId;
						this.queryStr.username=this.getFilter.username;
						this.queryStr.provider_name=this.getFilter.provider_name;
						this.queryStr.status=this.getFilter.status;
					} else if(this.dataType == 'documentType'){
						this.queryStr.filter='all';
					} else if (this.dataType == 'alt_contact'){
						this.queryStr.patient_id = this.$route.params.patient_id;
					} else if (this.dataType == 'enrollment_history'){
						this.queryStr.patient_id = this.$route.params.patient_id;
					} 
				} else {
					let date = new Date();
					let timezoneOffset = date.getTimezoneOffset();
					let hoursOffset = Math.abs(timezoneOffset / 60);
					let timeZone = timezoneOffset > 0 ? 'sub' : 'add';
					timeZone += ('0' + hoursOffset).slice(-1) + ':' + ('0' + (timezoneOffset % 60)).slice(-2);
					this.queryStr = `?page=${page}&search_text=${this.search_text}&per_page=${this.per_page}&sort_by=${this.sort_by}&sort_order=${this.sort_order}&localTimezone=${timeZone}`;
				}
				if (this.onLoadCall) {
					await this.$store.dispatch("getDataTableContents", {
						dataType: this.dataType,
						method: this.dataMethod,
						queryStr: this.queryStr,
					});
				}
			}
		},
	},

	async created() {
		this.getData(1);
	},
};
</script>

<style scoped>
.pagination li a {
	min-width: auto;
	padding: 5px 10px;
}

.pagination li a.current {
	background-color: #0265a0;
}

.pagination {
	padding-left: 50px;
}

span.ellipsis {
	padding: 0 1em;
}
</style>
