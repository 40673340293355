<template>
	<div class="row">
        <Sidebar></Sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
            <Datatable dataType="deleted_eform"  dataMethod="post" :key="key">
                <template v-slot="slotProps">
                    <td>{{slotProps.item.form_name}}</td>
                    <td>{{slotProps.item.additional_info}}</td>
                    <td>{{slotProps.item.formatted_updated_at}}</td>
                    <td><a class="d-flex align-items-center p-5 txt text-warning" href="#" @click="restoreRecord(slotProps.item.id)"> <i class="fa fa-trash-restore"></i>&nbsp;Restore</a></td>
                </template>    
            </Datatable>

        </div>

    </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import Datatable from '../../base/formFields/Datatable.vue';
import axios from "@/scripts/axios.js";

export default {
    data() {
        return {
            key: 0,
            searchKey:0,
            search_drop: true,
        }
    },
    setup() {
        
    },
    components: { 
        Sidebar,
        Datatable
    },
    methods:{
        restoreRecord(id){
            this.$swal({
                title: 'Are you sure you want to restore records?',
                text: 'You will restore this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, restore it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/eform/restore";
                    axios.post(url, {'id':id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.applyFilter()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });
        },
        applyFilter() {
            this.key++;
            this.search_drop = false;
        },
    }
}
</script>
