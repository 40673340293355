<template>
	<div class="row">
		<div class="box">
			<div class="box-body">
				<h3>GoSecure Account Setting</h3>
			</div>
		</div>

		<div class="box">
			<div class="box-body">
                <InputField title="MUID" type="number" :maxlength="6" v-model="form.muid" :isRequired="true" :errors="v$.form.muid.$errors" divClass="col-6"> </InputField>
                <InputField title="GoSecure User Login" v-model="form.email" :isRequired="true" :errors="v$.form.email.$errors" divClass="col-6"> </InputField>
                <Password title="Password" :isRequired="true" v-model="v$.form.password.$model" :errors="v$.form.password.$errors" :showPassword="true"></Password>
                <CheckBox title="Is Global" v-model="form.is_global"></CheckBox>

                <div class="row" >
                    <button class="btn btn-sm btn-primary text-white me-1 col-1" v-if="this.$filters.permissioncheck([this.$store.state.permission.admin_billing_crud_all])" @click="save">
                        Save
                        <img src="images/loader.gif" v-if="loader" />
                    </button>
                </div>
            </div>
		</div>

	</div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import InputField from "../../base/formFields/InputField.vue"
import CheckBox from "../../base/formFields/CheckBox.vue";
import axios from "@/scripts/axios.js";
import Password from "../../base/formFields/Password.vue";

export default {
	setup() {
		return { v$: useVuelidate() };
	},
    data(){
        return {
            loader: false,

        }
    },
    components: {
		InputField,
        CheckBox,
        Password
	},
    computed: {
        form() {
			return this.$store.state.billing_account_settings.form;
		},
    },
    validations() {
		return this.$store.state.billing_account_settings.validationRules;
	},
    mounted(){
        if (this.$route.params.id) {
            this.edit();
        }
    },
    methods:{
        save() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loader = true


                let url = "/admin/billing/account-detail/save";

                axios.post(url, this.form)
				.then((response) => {
					if (response.status == 200) {
                        this.loader = false;
                        this.$filters.moshaToast(response.data.message, "success");

                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });

            }
        },
        async edit() {
			axios.post("/admin/billing/account-detail/retrieve", { 'id': this.$route.params.id })
				.then((response) => {
					if (response.data.status === 200) {
						this.$store.state.billing_account_settings.form = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$filters.moshaToast(error.message, "error");
				});
		},
    }
}
</script>
