<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies files_overview">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Files" v-model="form.keyword" @blur="documentSearch" ref="documentSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList"></CustomFilter>
                        <button class="comman_brdr_btn cmn_mute_clr" v-if="this.$route.name !== 'PatientEChartFilesDelete' && this.selectedItems.length > 0" @click="exportconsults()"> Export </button>
                        <button class="comman_brdr_btn" v-if="this.$route.name === 'PatientEChartFilesDelete' && this.selectedItems.length > 0" @click="restore()"> Restore </button>
                    </span>
                </div>
                <div v-if="this.$route.name !== 'PatientEChartFilesDelete'">
                    <label for="fileInput" class="comman_btn">
                        Browse From Local ...
                    </label>
                    <input id="fileInput" type="file" @change="handleFileChange" class="d-none" accept=".pdf, .jpeg, .jpg, .png">
                </div>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'observation_datetime' }"  @click="changeSort('observation_datetime')">Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'description' }" @click="changeSort('description')">Description <img src="/images/down-arrow-new.svg" class="img-fluid" ></th>
                            <th scope="col"  :class="{ 'active': this.sortBy == 'report_class_id' }" @click="changeSort('report_class_id')">Type <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col">Associated Requisition <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.filesList" :key="index" :class="{ 'active': (selectChk[item.id] || selectedItems.includes(item.id) ) }">
                            <td><input class="form-check-input" type="checkbox" :checked="isChecked(item.id)" v-model="selectChk[item.id]" @change="checkSelect(item.id)"></td>
                            <td>{{ item.observation_datetime }}</td>
                            <td>{{ item.description }}</td>
                            <td>{{ item.report_class_id }}</td>
                            <td> {{item.tracking_request}}</td>
                            <td><img src="/images/dots-icon.svg" class="dot_icon" @click="edit(item.id)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="add-exportconsultation-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="box">
                        <ul class="export_tabs">
                            <li :class="{ active: isFaxFormVisible }" @click="changeExportOpt('fax')">Fax</li>
                            <li :class="{ active: isEmailFormVisible }" @click="changeExportOpt('email')">Email</li>
                            <li :class="{ active: isMailFormVisible }" @click="changeExportOpt('mail')">Mail</li>
                        </ul>
                        <section class="con_detl_sec">
                            <div class="fax_menu" v-if="isFaxFormVisible">
                                <div class="row align-items-center">
                                    <label for="text" class="col-sm-4 col-form-label right-bor py-0">Specialist</label>
                                    <div class="col-sm-8 position-relative">
                                        <CustomDropDown :options="specialist_option" :initialValue="specialist_text"
											@item-selected="handleItemSelected" v-model="faxForm.specialist_id"
											fieldName="specialist_id">
                                        </CustomDropDown>
                                        <!-- <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                                    </div>
                                 </div>   

                                <div class="or_line"><span>OR</span></div>

                                <!-- disable-fax -->
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="faxForm.fax_number" @change="changeReceiptFaxnumber">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Fax Number</label>
                                    </div>
                                </div>

                                
                                <div class="error-msg" v-if="this.faxValidationMsg">
                                    <ul class="mb-0 p-0 fs-14">
                                        <li class="d-block text-danger">
                                        {{ this.faxValidationMsg }}
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div class="email_menu" v-if="isEmailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_id">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Email</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_id.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_header">
                                        <label class="form-label" for="typeunique_idX-2">Email Header</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_header.$errors" />
                                    </div>
                                </div>

                            </div>
                            <div class="mail_menu" v-if="isMailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.name">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Name</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.name.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.address">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Address</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.address.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.city">
                                        <label class="form-label" for="typeunique_idX-2">Receipient City</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.city.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.state">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Province</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.state.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.zip">
                                        <label class="form-label" for="typeunique_idX-2">Receipient Postal Code</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.zip.$errors" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeExportModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="exportSubmit">Confirm</button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import sidebar from '../sidebar.vue';
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../../../base/CustomFilter.vue';
import $ from "jquery";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';

export default {
        setup: () => {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                filesList:[],
                selectedItems:[],
                allItems:[],
                selectChk:[],
                isFaxFormVisible:true,
                isEmailFormVisible:false,
                isMailFormVisible:false,
                sortBy:'',
                sortOrder:'',
                docTypeList: [],
                trackingDocTypeRole:{
					Consultation : [
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Specialist Rejected', title: 'Specialist Rejected' },
						{ value: 'Specialist Appointment Booked', title: 'Specialist Appointment Booked' },
						{ value: 'Completed', title: 'Completed' }
					],
					Imaging:[
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Result Received', title: 'Result Received' },
					],
					Lab:[
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Result Received', title: 'Result Received' },
					],
					Rx:[
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Result Received', title: 'Result Received' },
					]
				},
                filterList:[],
                isShowCustomFilter:false,
                filterOptions:[
                    { value: "patient_document.report_class_id", title: "Document Report Class" },
                    { value: "patient_document.report_sub_class_name", title: "Document Report Sub Class" },
                    { value: "patient_document.description", title: "Description" },
                ],
                loader:false,
                faxValidationMsg:''
            }
        },
        components: {
            sidebar,
            CustomDropDown,
            CustomFilter,
            ValidationErrorMessageList,
        },
        methods:{
            getFilesList(){
                this.$store.state.loader = true;
                let viewStatus = ''
                if (this.$route.name === 'PatientEChartFilesDelete') {
                    viewStatus = 'deleted'
                }
                
                axios.post("patient/document/list", 
                {
                     patient_id: this.$route.params.patient_id,
                     view_status:viewStatus,
                     keyword: this.form.keyword,
                     advanceFilter:this.form.advanceFilter,
                     sort_by: this.sortBy,
                     sort_order:this.sortOrder,
                     status:'labeled'
                })
                .then((response) => {
                    this.filesList = response.data.data;
                    this.filesList.forEach(item => {
                        this.allItems.push(item.id)
                    })

                    this.$store.state.loader = false;
                });
            },
            isChecked(id){
                return this.selectedItems.includes(id);
            },
            checkSelectAll(){
                if (this.selectAllChk) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            checkSelect(id){
                if (this.selectChk[id]) {
                    this.selectedItems.push(id)
                } else {
                    const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
                    if (index !== -1) {
                        this.selectedItems.splice(index, 1); // Remove id from selectedItems
                    }
                }

                if (this.selectedItems.length == this.allItems.length) {
                    this.selectAllChk = true;
                } else if (this.selectedItems.length == 0) {
                    this.selectAllChk = false;
                } else {
                    this.selectAllChk = false;
                }
            },
            exportconsults(){
                this.v$['faxForm'].$reset();
                this.v$['emailForm'].$reset();
                this.v$['mailForm'].$reset();
                Object.assign(
                    this.$store.state.patient_document.faxForm,
                    this.$store.state.patient_document.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.patient_document.emailForm,
                    this.$store.state.patient_document.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.patient_document.mailForm,
                    this.$store.state.patient_document.defaultexportfrm
                );
                window.$("#add-exportconsultation-model").modal("show");
            },
            changeExportOpt(type){
                this.isFaxFormVisible=false
                this.isEmailFormVisible=false
                this.isMailFormVisible=false

                if (type == 'fax') {
                    this.isFaxFormVisible=true
                } else if (type == 'email') {
                    this.isEmailFormVisible=true
                } else if (type == 'mail') {
                    this.isMailFormVisible=true
                }
            },
            closeExportModel(){
                window.$("#add-exportconsultation-model").modal("hide");
            },
            exportSubmit(){
                this.faxValidationMsg = ''
                let form = null
                let formName = 'faxForm'
                if (this.isFaxFormVisible) {
                    form = this.faxForm
                    form.type = "fax"
                    formName = 'faxForm';
                } else if(this.isEmailFormVisible){
                    form = this.emailForm
                    form.type = "email"
                    formName = 'emailForm';
                } else if (this.isMailFormVisible){
                    form = this.mailForm
                    form.type = "mail"
                    formName = 'mailForm';
                }
                form.ids = this.selectedItems
                form.patient_id = this.$route.params.patient_id
                this.v$[formName].$validate();
                this.loader = true

                if (formName == 'faxForm' || !this.v$[formName].$error) {
                    if (formName == 'faxForm') {
                        if (!this.faxForm.specialist_id && !this.faxForm.fax_number){
                            this.faxValidationMsg = "Please select specialist or enter fax number"
                            return false
                        }
                    }
                    this.loader = true

                    let url = "patient/document/export"
                    axios.post(url, form)
                        .then((response) => {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.loader = false;
                            window.$("#add-exportconsultation-model").modal("hide");
                        });
                } else {
                    console.log(Object.entries(this.v$[formName]))
                    for (const [key] of Object.entries(this.v$[formName])) {
                        if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                            const myEl = document.getElementById(key);
                            if (myEl) {
                                this.$smoothScroll({
                                    scrollTo: myEl,
                                });
                                break;
                            }
                        }
                    }
                }

            },
            handleFileChange(event) {
                const selectedFile = event.target.files[0];
                if (selectedFile && (selectedFile.type === 'application/pdf' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg' || selectedFile.type === 'image/jpeg') ) {
                    // Handle the PDF file
                    const formData = new FormData();
                    formData.append('patient_id', this.$route.params.patient_id)
                    formData.append('pdfData', selectedFile);
                    axios.post('patient/document/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                    }).then(response => {
                        let detail = response.data.data
                        this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}/files/retrieve/${detail.id}`);
                    }).catch(error => {
                    // Handle errors
                    console.error('Error uploading file:', error);
                    });
                } else {
                    // Notify the user that only PDF files are allowed
                    this.$filters.moshaToast('Please select a PDF, jpg, jpeg or png file.', "error");
                }
            },
            edit(id) {
                this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}/files/retrieve/${id}`);
            },
            documentSearch(){
                this.getFilesList();
            },
            addFilter(){
                if (this.isShowCustomFilter) {
                    this.getFilesList()
                }
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    advanceFilter[item.field] = item.value
                });
                this.form.advanceFilter = advanceFilter
            },
            changeSort(fieldName){
                if (fieldName != this.sortBy) {
                    this.sortOrder = 'desc'
                } else if (this.sortOrder == 'desc') {
                    this.sortOrder = 'asc'
                } else{
                    this.sortOrder = 'desc'
                }

                this.sortBy = fieldName

                this.getFilesList()
            },
            restore(){
                this.$swal({
					title: 'Are you sure you want to restore records?',
					text: '',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Yes, restore it!',
					cancelButtonText: 'No, cancel please!',
					closeOnConfirm: false,
					closeOnCancel: false,
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/restore";
						axios.post(url, {'ids':this.selectedItems})
						.then((response) => {
							if (response.status == 200) {
								this.getFilesList();
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
            },
            handleItemSelected(values){
                this.faxForm.specialist_id = values.item.id
                this.faxValidationMsg = ''
            },
            getSpecialistList(){
                axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
                    .then((response) => {
                        if (response.status == 200) {
                            const details = response.data.data;
                            const specialistTempData = [];
                            $.each(details, function (key, detail) {
                                detail.value = detail.id
                                let name = detail.name;
                                if (detail.is_preferred_specialist) {
                                    name += "<p class='float-end'>(Preferred)</p>"
                                }
                                detail.title = name
                                specialistTempData.push(detail)
                            })
                            this.specialist_option = specialistTempData;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        console.log(error)
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            changeReceiptFaxnumber(){
                this.faxValidationMsg = ''
                if (!this.faxForm.fax_number) {
                    this.faxValidationMsg = "Please select specialist or enter fax number"
                }
            },

        },
        mounted(){
            this.getFilesList()
            this.getSpecialistList()
        },
        computed: {
            form() {
                return this.$store.state.patient_document.form;
            },
            faxForm(){
                return this.$store.state.patient_document.faxForm;
            },
            emailForm(){
                return this.$store.state.patient_document.emailForm;
            },
            mailForm(){
                return this.$store.state.patient_document.mailForm;
            }
        },
        validations() {
            return {
                faxForm: this.$store.state.patient_document.validationRules.faxForm,
                emailForm: this.$store.state.patient_document.validationRules.emailForm,
                mailForm: this.$store.state.patient_document.validationRules.mailForm,
            };

        },
}
</script>
<style scoped>
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f8f8f8;
  border-radius: 4px;
}
</style>