<template>
	<div class="row patient_chart">
		<Sidebar></Sidebar>
		<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 edit_record_data">
			<div class="row" v-if="user_info">
				<div class="col-12 edit_record">
					<router-link :to="{ name: 'PatientEdit',params: { patient_id: user_info.patient_id,  }}"> Edit Record</router-link>
					<!-- <router-link :to="{ name: 'PatientEdit',params: { patient_id: user_info.patient_id,  }}" class="float-end"> Print Label</router-link> -->
					<a href="javascript:void(0);" class="float-end" @click="openPrintLabel">Print Label</a>
                </div>
			</div>
			<div class="scrlbar">
				<div class="row" v-if="user_info">
					<div class="col-6">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title mt-0">Demographic</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Last Name</label> <span>{{ user_info.last_name }}</span></p>
								<p><label>Middle Name</label> <span>{{ user_info.middle_name }}</span></p>
								<p><label>First Name</label> <span>{{ user_info.first_name }}</span></p>
								<p><label>Gender</label>
									
									<span>
										<slot v-if="user_info.gender == 'M'">Male</slot>
										<slot v-else-if="user_info.gender == 'F'">Female</slot>
										<slot v-else-if="user_info.gender == 'O'">Other</slot>
										<slot v-else-if="user_info.gender == 'U'">Unknown</slot>
									</span>
								</p>
								
								<p><label>Date Of Birth</label> <span>{{ user_info.dob }}</span></p>
								<p><label>Age</label> <span>{{ $filters.patientCalculatedAge(user_info.dob) }}</span></p>
								<p><label>Title</label> <span>{{ user_info.title }}</span></p>

							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">Health Insurance 
								<!-- <div class="ms-5 icon_tooltip remove_tooltip_arrow" v-if="!user_info.health_insurance_no">
									<Popper placement="bottom" :content="healthInsToolTip" hover arrow>
										<span class="qua_icon un_elg">?</span>
									</Popper>
								</div> -->
								<div class="icon_tooltip leftalign_tooltip_arrow" v-if="user_info.is_hcv_verified">
									<Popper placement="bottom" :content="healthCardEligible" hover arrow>
										<span class="qua_icon ps_elg">?</span>
									</Popper>
								</div>
								<div class="icon_tooltip remove_tooltip_arrow" style="" v-else>
									<Popper placement="bottom" :content="healthCardNotEligible" hover arrow>
										<span class="qua_icon nt_elg">?</span>
									</Popper>
								</div>
								</h4>	
							</div>
							<div class="box-body containt_detail">
								<div class="two_inputs">
									<p><label>Health Card Number </label><span>{{ user_info.health_insurance_no }}</span></p>
									<p><label>Ver.</label><span>{{ user_info.health_card_ver }}</span></p>
								</div>
								<p><label>Health Card Province </label><span>{{ user_info.health_card_type }}</span></p>
							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">Clinic Status</h4>
							</div>
							<div class="box-body containt_detail">
								<p>
									<label>Roster Status</label>
									<span>{{ user_info.roster_status }}</span>
								</p>
								<p><label>Family Physician Last Name</label> <span>{{ user_info.family_physician_last_name }}</span></p>
								<p><label>Family Physician First Name </label> <span>{{ user_info.family_physician_first_name }}</span></p>
								<p>
									<label>Patient Status</label>
									<span>
										<slot v-if="user_info.status == 'AC'">Active</slot>
										<slot v-else-if="user_info.status == 'IN'">Inactive</slot>
										<slot v-else-if="user_info.status == 'DE'">Deceased</slot>
									</span>
								</p>
								<p><label>Patient Status Date</label> <span>{{ user_info.enrolment_start_date }}</span></p>
								<p><label>Roster Termination Date</label> <span>{{ user_info.enrolment_termination_date }}</span></p>
								<p><label>Roster Termination Reason</label> <span>{{ user_info.termination_reason }}</span></p>
								<p><label>Primary Physician</label> <span>{{ user_info.primary_physician }}</span></p>
							</div>
						</div>

					</div>
					<div class="col-6">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title mt-0">Contact Information</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Cell Phone </label> <span>{{ user_info.cell_phone }} </span></p>
								<p><label>Email </label> <span>{{ user_info.email }}</span></p>
								<p><label>Residential Address </label> <span>{{ user_info.residential_address }}</span></p>
								<p><label>Residential City </label> <span>{{ user_info.residential_city }}</span></p>
								<p><label>Residential Province </label> <span>{{ user_info.residential_province_name }}</span></p>
								<p><label>Residential Country </label> <span>{{ user_info.residential_country_name }}</span></p>
								<p><label>Residential Postal </label> <span>{{ user_info.residential_zip }}</span></p>
								<p><label>Home Phone</label> <span>{{ user_info.home_phone }}</span></p>
								<div class="two_inputs">
									<p><label>Work Phone</label> <span>{{ user_info.work_phone }}</span></p>
									<p><label>Ext</label><span>{{ user_info.work_phone_ext }}</span></p>
								</div>
								<p class="address_line">
									<label class="border-0 p-0">Mailing Address Same as Residential</label>
									<span class="ms-3 p-0 w-auto">
										<input class="form-check-input disable_input m-0 align-middle" type="checkbox" id="checkboxNoLabel" 
										:checked="user_info.is_same_residential_mailing" 
										:disabled="true" :class="{'check_ntdsbl': !user_info.is_same_residential_mailing}"
										>
										<!-- <input class="form-check-input disable_input m-0 align-middle" type="checkbox" id="checkboxNoLabel" v-else disabled> -->
									</span>
								</p>

								<slot v-if="!user_info.is_same_residential_mailing">
								<p><label>Mailing Address </label> <span>{{ user_info.mailing_address }}</span></p>
								<p><label>Mailing City </label> <span>{{ user_info.mailing_city }}</span></p>
								<p><label>Mailing Province </label> <span>{{ user_info.mailing_province_name }}</span></p>
								<p><label>Mailing Country </label> <span>{{ user_info.mailing_country_name }}</span></p>
								</slot>

							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">PATIENT’S NOTE</h4>
							</div>
							<div class="box-body containt_detail">
								<p class="align-items-start mb-3"><label>Notes</label> <span>{{ user_info.notes }}</span></p>
								<p class="align-items-start mb-3"><label>Alerts</label> <span>{{ user_info.alert }}</span></p>
							</div>
						</div>	
					</div>
				</div>
				<div class="row" v-if="user_info && this.altContactInfo.length > 0">
					<slot v-for="(item,index) in this.altContactInfo" :key="index">
						<div class="col-6">
							<div class="box box-bordered ">
								<div class="box-header with-border">
									<h4 class="box-title" :class="{ 'opacity-0': index > 0 }">ALTERNATIVE CONTACT</h4>
								</div>
								<div class="box-body containt_detail" :class="{ 'right_collumn': shouldHighlightRow(index) }">
									<p><label>Contact {{ index + 1 }} Last Name</label> <span>{{ item.last_name }}</span></p>
									<p><label>Contact {{ index + 1 }} First Name</label> <span>{{ item.first_name }}</span></p>
									<p><label>Contact {{ index + 1 }} Purpose</label> 
										<span>
											<slot v-if="item.contact_purpose == 'SDM'">Substitute Decision Maker</slot>
											<slot v-else-if="item.contact_purpose == 'EC'">Emergency Contact</slot>
										</span>
									</p>
									<p><label>Contact {{ index + 1 }} Relationship</label> 
										<span>{{ item.relationship }}</span></p>
									<p><label>Contact {{ index + 1 }} Cell Phone</label> <span>{{ item.cell_phone }}</span></p>
									<p><label>Contact {{ index + 1 }} Home Phone</label> <span>{{ item.residence_phone }}</span></p>
									<div class="two_inputs">
										<p><label>Contact {{ index +1 }} Work Phone</label> <span>{{ item.work_phone }}</span></p>
										<p><label>Ext</label><span>{{ item.work_phone_ext }}</span></p>
									</div>
									<p><label>Contact {{ index + 1 }} Email</label><span>{{ item.email }}</span></p>
									<p><label>Contact {{ index + 1 }} Note</label> <span>{{ item.contact_note }}</span></p>
								</div>
							</div>
						</div>
					</slot>
				</div>

				<div class="row" v-if="user_info">
					<div class="col-6">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">Additional Details</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Name Suffix</label> <span>{{ user_info.suffix }}</span></p>
								<p><label>Chart ID<small class="asterisksign_blue m-0">*</small></label> <span>{{ user_info.chart_id }}</span></p>
								<p><label>Spoken Language</label> 
								<span>
									<slot v-if="user_info.spoken_language == 'ENG'">English</slot>
									<slot v-else-if="user_info.spoken_language == 'FRE'">French</slot>
									<slot v-else-if="user_info.spoken_language == 'OTH'">Other</slot>
								</span>
								</p>
							</div>
						</div>	
					</div>
					<div class="col-6">
						<div class="box box-bordered ">
							<div class="box-header with-border opacity-0">
								<h4 class="box-title">Additional Details</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Referred Physician Last Name </label> <span>{{ user_info.referred_physician_last_name }}</span></p>
								<p><label>Referred Physician First Name </label> <span>{{ user_info.referred_physician_first_name }}</span></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="print-label-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <div class="box position-relative">
						<div class="main_hd">{{ user_info.last_name }}  {{ user_info.first_name }}</div>
						<div class="d-flex align-items-center">
							<span class="bold_title m-0">HEALTH #</span> <span class="bold_line">|</span> <span class="con_detail">{{ user_info.health_insurance_no }} {{ user_info.health_card_ver }}</span> 
							<span class="bold_title">GENDER</span> <span class="bold_line">|</span>  
							<span class="con_detail">
								<template v-if="user_info.gender === 'M'">Male</template>
								<template v-else-if="user_info.gender === 'F'">Female</template>
								<template v-else-if="user_info.gender === 'O'">Other</template>
								<template v-else-if="user_info.gender === 'U'">Unknown</template>
							</span> 
							<span class="bold_title">DOB</span> <span class="bold_line">|</span> <span class="con_detail">{{ user_info.dob }}</span>
						</div>
						<div class="mt-5">
							<div class="con_detail mb-1">{{ user_info.residential_address }}</div>
							<div class="con_detail mb-1">{{ user_info.residential_city }}, {{ user_info.residential_province_name }} {{ user_info.residential_zip }} </div>
							<div class="bold_title m-0"> TEL <span class="con_detail"> {{ user_info.cell_phone }}</span></div>
						</div>
                    </div>
					<img src="/images/gray_logo.svg" class="img-fluid watermark_logo">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Sidebar from './Sidebar.vue';
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
export default {
	data() {
		return {
			user_info: null,
			altContactInfo: [],
			healthInsToolTip: "Health Card Eligibility Unknown(Click To Verify)",
			healthCardEligible: 'Health Card Passed Eligibility',
			healthCardNotEligible: 'Health Card Not Eligible',
		}
	},
	components: { 
		Sidebar,
		Popper
	},
	async created() {
		this.$store.state.loader = true;
		// this.$store.state.loader = true;
		let user_info = await axios.post("patient/retrieve", {  id: this.$route.params.patient_id });
		this.user_info = user_info.data.data;
		// this.$store.state.loader = false;
		this.getOtherContact()
		window.addEventListener("message", (event) => {
			if (event.data === "Alt contact is updated") {				
				this.getOtherContact();
			} 
		});
		this.$store.state.loader = false;
	},
	computed: {
		...mapState(['patientId'])
	},
	watch: {

	},
	methods: {
		openPrintLabel() {
			window.$("#print-label-model").modal("show");
		},
		getOtherContact(){
			axios.post(`/patient/alternative-contact/list`, { 'patient_id': this.$route.params.patient_id})
				.then((response) => {
					if (response.data.status === 200) {
						this.altContactInfo = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$filters.moshaToast(error.message, "error");
				});
		},
		createContact(){
			const url = this.$router.resolve({
				path: `/patient/alter-native-contact/${this.$route.params.patient_id}/create`,
				// query: { target: '_blank' }
			}).href;

			window.open(url, '_blank');
            // this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}/create`);   
        },
		editContact(id){
            // this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}/retrieve/${id}`);   
			const url = this.$router.resolve({
				path: `/patient/alter-native-contact/${this.$route.params.patient_id}/retrieve/${id}`,
				// query: { target: '_blank' }
			}).href;

			window.open(url, '_blank');
        },
		contactList(){
			const url = this.$router.resolve({
				path: `/patient/alter-native-contact/${this.$route.params.patient_id}`,
				// query: { target: '_blank' }
			}).href;

			window.open(url, '_blank');
            // this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}`);   
        },
		enrollmentHistory(){
			const url = this.$router.resolve({
				path: `/patient/enrollment-history/${this.$route.params.patient_id}`,
				// query: { target: '_blank' }
			}).href;

			window.open(url, '_blank');
            // this.$router.push(`/patient/enrollment-history/${this.$route.params.patient_id}`);   
		},
		dateFormat(date){
			if (date == '' || date == null) {
				return ''
			}
			const dateObject = new Date(date);
			const options = { year: 'numeric', month: 'long', day: 'numeric' };

			// Adjust the options based on your desired date format
			return dateObject.toLocaleDateString('en-US', options);
		},
		getAltContactClass(index){
			return index % 2 === 0 ? 'me-1' : 'me-4';
		},
		shouldHighlightRow(index) {
				// Return true for rows with indices 1, 3, 5, 7, ...
			return (index % 2 !== 0);
		},
	}
}
</script>