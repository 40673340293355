import {
	helpers,
	required,
	// email,
} from "@vuelidate/validators";

const tasks = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			document_id: null,
			status: 'Active',
			priority: 'Urgent',
			service_date: new Date().toISOString().slice(0, 10),
			provider_ids: {},
			demographic: null,
            reminder_message: null,
			advanceFilter : {},
			keyword: '',
		},
		form: {
            id: null,
			document_id: null,
            status: 'Active',
            priority: 'Urgent',
            service_date: new Date().toISOString().slice(0, 10),
            provider_ids: {},
            demographic: null,
            reminder_message: null,
			keyword: '',
			sort_order: 'desc',
			sort_by: 'service_date',
            advanceFilter : {},
			from: 'ActiveTaskList',
			from_module: 'e-chart',
		},
		validationRules: {
			form: {
                service_date: {
					required: helpers.withMessage("Please Select Service Data.", required),
				},
                demographic: {
					required: helpers.withMessage("Please Enter Demographic.", required),
				},
                provider_ids: {
					required: helpers.withMessage("Please Select At Least One Provider.", required),
				},
                reminder_message: {
					required: helpers.withMessage("Please Enter Message.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {
				created_date: {
					required: helpers.withMessage("Please Created Select Data.", required),
				},
                demographic: {
					required: helpers.withMessage("Please Select Demographic.", required),
				},
                provider_ids: {
					required: helpers.withMessage("Please Select At Least One Provider.", required),
				},
                reminder_message: {
					required: helpers.withMessage("Please Enter Message.", required),
				},
			},
		},
		list: [],
	},

	getters: {},
	mutations: {},
	actions: {},
};


export default tasks;
