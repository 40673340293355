<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" @keyup="allergenSearch" class="form-control" placeholder="Search Allergies" v-model="allergy_search" @blur="allergenSearch" ref="globalSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList"></CustomFilter>
                    </span>
                </div>
                <button class="comman_btn" @click="addAllergy"> Add Allergy </button>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="hover_unset w-30px"></th>
                            <th scope="col" class="active" @click="getAllergiesOrderBy('created_datetime', $event)">Create Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getAllergiesOrderBy('description', $event)">Allergen <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getAllergiesOrderBy('reaction', $event)">Reaction <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getAllergiesOrderBy('severity_of_reaction', $event)">Severity <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getAllergiesOrderBy('life_stage', $event)">Life Stage <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.allAllergiesList" :key="index">
                            <td></td>
                            <td>{{ $filters.inputDateTime(item.created_datetime, 'YYYY-MM-DD') }}</td>
                            <td>{{ item.description }}</td>
                            <td>{{ item.reaction }}</td>
                            <td>{{ item.severity_of_reaction }}</td>
                            <td>{{ item.life_stage }}</td>
                            <td><img src="/images/dots-icon.svg" class="dot_icon" @click="editAllergy(item.id)"></td>
                        </tr>
                        <!-- <tr>
                            <td>2024-05-12</td>
                            <td>A-Tetracycline Antibiotics</td>
                            <td>Sunburn-like reaction to sunlight or UV light</td>
                            <td>Severe</td>
                            <td>A - Adult</td>
                            <td><img src="/images/dots-icon.svg" class="dot_icon" @click="editAllergy()"></td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="add-allergies-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeAllergyModel"></button>
                    <div class="box">
						<h2 class="popup_title">{{ popupHeadingText }}</h2>
						<div class="date_line"><label>Create Date</label> <span>2024-03-21</span></div>
                        <div class="custom-form">
                            <div class="position-relative seach_input_bar input_box">
                                <div class="search">
                                    <img class="serch_icon" src="/images/search-icon.svg" alt="">
                                    <input type="text" @keyup="drugSearch" class="form-control w-100" placeholder="Allergy Description"
                                        v-model="drug_search" @blur="drugSearch" ref="globalSearch">
                                </div>
                                <div class="search_drop_box new_big_drop" v-if="drugList.length > 0">
                                    <slot v-for="(item, index) in drugList" :key="index">
                                        <div class="con_drop_line" @click="selectDrug(item.id, item.substance_name, item.allergy_type)">
                                            <div class="row px-2 py-3 align-items-center">
                                                <div class="col-md-6">
                                                    <div class="man_haeding">
                                                        {{ item.substance_name + ' | ' + item.allergy_type }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </slot>
                                </div>
                            </div>

                            <div class="input_box">
                                <input type="test" required="" id="typeEmailX-2" class="form-control form-control-lg" v-model="form.reaction">
                                <label class="form-label" for="typeEmailX-2">Reaction Description</label>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="heading_selact_drodwn">
                                <CustomDropDown :options="severity_option" :initialValue="this.form.severity_of_reaction"
                                            v-model="form.severity_of_reaction" @item-selected="handleItemSelected" labelText="Severity"
											fieldName="severity_of_reaction" divClass="form-label"></CustomDropDown>
                            </div>
                            <div class="heading_selact_drodwn">
                                <CustomDropDown :options="life_stage_options" :initialValue="this.form.life_stage_text"
                                            v-model="form.life_stage" @item-selected="handleItemSelected" labelText="Life Stage"
											fieldName="life_stage" divClass="form-label"></CustomDropDown>
                            </div>
                        </div>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx42" @click="closeAllergyModel"> Cancel </button>
                            <button class="comman_btn big_btn mx42" @click="addAllergyCall">
                                {{ buttonName }}
                                <img src="images/loader.gif" v-if="loader" />
                            </button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import sidebar from './sidebar.vue';
import CustomDropDown from '../../base/formFields/CustomDropDown.vue';
import CustomFilter from '../../base/CustomFilter.vue';
import axios from "@/scripts/axios.js";

export default {
        data() {
            return {
                severity_option:[
					{ value: "", title: "Not Set" },
					{ value: "Mild", title: "Mild" },
					{ value: "Moderate", title: "Moderate" },
					{ value: "Severe", title: "Severe" },
				],
                life_stage_options: [
                    { value: "N", title: "Newborn: Birth to 28 days" },
                    { value: "I", title: "Infant: 29 days to 2 years" },
                    { value: "C", title: "Child: 2 years to 15 years" },
                    { value: "T", title: "Adolescent: 16 to 17" },
                    { value: "A", title: "Adult: 18 years" },
                ],
                filterOptions:[
                    { value: "allergen", title: "Allergen" },
                    { value: "reaction_desc", title: "Reaction Description" },
                    { value: "serverity", title: "Severity" },
                    { value: "life_stage", title: "Life Stage" },
                ],
                isShowCustomFilter:false,

                allAllergiesList: [],
                orderBy: 'desc',
                orderByColumnName: 'created_datetime',
                buttonName: "Add",
                action: "Add",
                popupHeadingText: "Add New Allergies",
                loader: false,
                filterList:[],

                drugList: [],
            }
        },
        components: {
            sidebar,
            CustomDropDown,
            CustomFilter
        },
        mounted() {
            this.getAllergiesList();
            document.addEventListener('click', this.handleClickOutside);
        },
        methods:{
            handleClickOutside(event) {
				const globalSearchElement = this.$refs.globalSearch;
				if (globalSearchElement && !globalSearchElement.contains(event.target)) {
					this.drugList = [];
				}
			},
            selectDrug(id, drugSubstanceName, drugAllergyType){
				this.form.description = drugSubstanceName + ' | ' + drugAllergyType;
				this.drug_search = drugSubstanceName + ' | ' + drugAllergyType;
			},
            drugSearch() {
				if (this.drug_search.length > 2) {
					axios.post("json_autocomplete/drug-class/list", { search_text: this.drug_search })
						.then((response) => {
							this.drugList = response.data.data;
					})
				}
				if (this.drug_search.length == 0) {
					this.drugList = [];
				}
			},
            resetAllergyForm() {
                this.form.patient_id = null;
                this.form.description = null;
                this.form.reaction = null;
                this.form.severity_of_reaction = this.getSeverityReactionVal();
                this.form.life_stage = this.getLifeStageTitleVal();
                this.form.start_date = null;
                this.form.id = null;
            },
            getSeverityReactionVal(value) {
                if(value == "")
                {
                    return "Not Set";
                }
                else
                {
                    const option = this.severity_option.find(option => option.title === value);
                    return option ? option.title : "Not Set";
                }
            },
            getValueFromLifeStages(targetValue) {
                for (const option of this.life_stage_options) {
                    if (option.title.includes(targetValue)) {
                        return option.value;
                    }
                }
                return null;
            },
            getLifeStageTitleVal(value) {
                const option = this.life_stage_options.find(option => option.value === value);
                if (option) {
                    const title = option.title;
                    const index = title.indexOf(":");
                    return index !== -1 ? title.substring(0, index).trim() : "Not Set";
                } else {
                    return "Not Set";
                }
            },
            getLifeStageTitle() {
                this.allAllergiesList.forEach(item => {
                    const lifeStageOption = this.life_stage_options.find(option => option.value === item.life_stage);
                    if (lifeStageOption) {
                        const titleParts = lifeStageOption.title.split(':');
                        item.life_stage = `${item.life_stage} - ${titleParts[0].trim()}`;
                    }
                });
            },
            closeAllergyModel() {
                window.$("#add-allergies-model").modal("hide");
                this.action = "Add";
                this.buttonName = "Add";
                this.popupHeadingText = "Add New Allergies";
                this.resetAllergyForm();
            },
            addAllergy(){
                window.$("#add-allergies-model").modal("show");
            },
            getAllergiesList() {
                this.form.patient_id = this.$route.params.patient_id;
                this.form.allergy_search = this.allergy_search;  
                axios.post('patient/allergies/list', this.form)
                    .then((response) => {
                        if (response.status == 200) {
                            this.allAllergiesList = response.data.data;
                            if(this.allAllergiesList) {
                                this.allAllergiesList = this.capitalizeData(this.allAllergiesList);
                                this.getLifeStageTitle();
                            }
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            console.log(Object.values(error.response.data.data).join(', '))
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            getAllergiesOrderBy(orderColumnName, event){
                event.stopPropagation(); 

                const th = event.target;
                this.orderByColumnName = orderColumnName;
                if (th.classList.contains('active')) {
                    th.classList.remove('active');
                    this.orderBy = 'asc';
                } else {
                    th.classList.add('active');
                    this.orderBy = 'desc';
                }

                axios.post('patient/allergies/list', { patient_id: this.$route.params.patient_id, order_by_column: this.orderByColumnName, order_by: this.orderBy })
                    .then((response) => {
                        if (response.status == 200) {
                            this.allAllergiesList = response.data.data;
                            if(this.allAllergiesList) {
                                this.allAllergiesList = this.capitalizeData(this.allAllergiesList);
                                this.getLifeStageTitle();
                            }
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.loader = false;
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            console.log(Object.values(error.response.data.data).join(', '))
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            addAllergyCall() {
                this.loader = true;
                if(this.action == "Add")
                {
                    this.form.patient_id = this.$route.params.patient_id;
                    this.form.life_stage = this.getValueFromLifeStages(this.form.life_stage);
                    this.form.start_date = this.$filters.inputDateTime(this.$filters.getCurrentDateTime(), 'YYYY-MM-DD')
                    axios.post('patient/allergies/store', this.form)
                        .then((response) => {
                            if (response.status == 200) {
                                this.loader = false;

                                this.drug_search = '';
                                this.form.advanceFilter = {};
                                this.getAllergiesList();
                                if(this.allAllergiesList) {
                                    this.allAllergiesList = this.capitalizeData(this.allAllergiesList);
                                    this.getLifeStageTitle();
                                }
                                
                                this.$filters.moshaToast(response.data.message, "success");
                                this.closeAllergyModel();
                                this.resetAllergyForm();
                            } else {
                                this.$filters.moshaToast(response.data.message, "error");
                            }
                        }).catch(error => {
                            this.loader = false;
                            if (error.response.status === 422) {
                                console.log(Object.values(error.response.data.data).join(', '));
                                this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            } else {
                                this.$filters.moshaToast(error.response.data.message, "error");
                            }
                        });

                }
                else
                {
                    this.form.life_stage = this.getValueFromLifeStages(this.form.life_stage);
                    axios.post('patient/allergies/update', this.form)
                        .then((response) => {
                            if (response.status == 200) {
                                this.loader = false;

                                this.form.advanceFilter = {};
                                this.getAllergiesList();
                                if(this.allAllergiesList) {
                                    this.allAllergiesList = this.capitalizeData(this.allAllergiesList);
                                    this.getLifeStageTitle();
                                }
                                this.action = "Add";
                                this.buttonName = "Add";
                                this.popupHeadingText = "Add New Allergies";
                                this.closeAllergyModel();
                                this.$filters.moshaToast(response.data.message, "success");
                                this.resetAllergyForm();
                            } else {
                                this.$filters.moshaToast(response.data.message, "error");
                            }
                        }).catch(error => {
                            this.loader = false;
                            if (error.response.status === 422) {
                                console.log(Object.values(error.response.data.data).join(', '));
                                this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            } else {
                                this.$filters.moshaToast(error.response.data.message, "error");
                            }
                        });
                }
            },
            editAllergy(allergyId){
                axios.post('patient/allergies/retrieve', { patient_id: this.$route.params.patient_id, id: allergyId })
                .then((response) => {
                    if (response.status == 200) {
                        window.$("#add-allergies-model").modal("show");
                        this.buttonName = "Update";
                        this.action = "Update";
                        this.popupHeadingText = "Update Existing Allergy";
                        this.getAllergy = response.data.data;

                        if(this.getAllergy) {
                            console.log('this.getAllergy:- ', this.getAllergy);
                            this.form.id = this.getAllergy.id;
                            this.form.patient_id = this.getAllergy.patient_id;
                            this.form.description = this.getAllergy.description;
                            this.form.reaction = this.getAllergy.reaction;

                            this.form.severity_of_reaction = this.getAllergy.severity_of_reaction;
                            this.form.start_date = this.getAllergy.start_date;

                            this.form.life_stage = this.getLifeStageTitleVal(this.getAllergy.life_stage);
                            this.form.life_stage_text = this.getLifeStageTitleVal(this.getAllergy.life_stage);
                        }
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            allergenSearch() {
                if(this.allergy_search.length > 2)
                {
                    this.getAllergiesList();
                }
                if(this.allergy_search.length == 0)
                {
                    this.getAllergiesList();
                    if(this.allAllergiesList) {
                        this.allAllergiesList = this.capitalizeData(this.allAllergiesList);
                        this.getLifeStageTitle();
                    }
                }
            },
            handleItemSelected(payload) {
                const { fieldName, item } = payload;
                if(fieldName == "severity_of_reaction")
                {
                    this.form.severity_of_reaction = item.value;
                }
                if(fieldName == "life_stage")
                {
                    this.form.life_stage = this.getLifeStageTitleVal(item.value);
                    this.form.life_stage_text = this.getLifeStageTitleVal(item.value);
                }
            },
            capitalizeData(data) {
                return data.map(item => ({
                    ...item,
                    description: this.$filters.strToUpperCase(item.description),
                    reaction: this.$filters.strToUpperCase(item.reaction)
                }));
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
                this.getAllergiesList();
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    advanceFilter[item.field] = item.value
                });
                this.form.advanceFilter = advanceFilter
            },
        },
        computed: {
            form() {
                return this.$store.state.allergies.form;
            },
        },
        validations() {
            return this.$store.state.allergies.validationRules;
        },
}
</script>