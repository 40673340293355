<template>
	<div class="row patient_chart third-party-main">
		<sidebar></sidebar>
		<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 px-0">
			<div class="third_prt_itgn">
				<div class="main_bx_con">
					<div class="d-flex align-items-center"  :class="{ 'justify-content-between': this.isShowSrForm }">
						<h1 class="h1-admin_head cursor-alias mb-0">SRFax Account Settings (Incoming & Outgoing Faxes)</h1>
						<span class="popup_link ml-50" @click="showSRFaxForm" v-if="!this.isShowSrForm">Edit</span>
						<div class="mx15">
							<span class="popup_link mr-50" v-if="this.isShowSrForm" @click="cancelSrfax">Cancel</span>
							<span class="popup_link" v-if="this.isShowSrForm">Save</span>
						</div>
					</div>
					<div class="dtl_con">
						<div class="srfax-account-dtls mt-25 mb-25 " v-if="this.isShowSrForm">
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label cursor-alias">
									<p class="mb-0">ACCOUNT NUMBER</p>
								</div>
								<div class="srfax-dtls-edit w-100">
									<input type="number" placeholder="" class="form-control cursor-alias" v-model="form.account_number">
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">PASSWORD</p>
								</div>
								<div class="srfax-dtls-edit w-100">
										<div class="usr-dtls-inp position-relative">
										<input type="password" class="form-control w-100 cursor-alias" v-model="form.password">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer">
									</div>
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">NOTIFICATION EMAIL</p>
								</div>
								<div class="srfax-dtls-edit w-100">
									<input type="email" placeholder="" class="form-control cursor-alias" v-model="form.notification_email">
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">RESPONSE FAX NUMBER</p>
								</div>
								<div class="srfax-dtls-edit  w-100">
									<input type="number" placeholder="" class="form-control cursor-alias" v-model="form.response_fax_number">
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">COVER PAGE</p>
								</div>
								<CustomDropDown :options="coverPageOpt"
											:initialValue="form.cover_letter"
											v-model="form.cover_letter" @item-selected="handleItemSelected"
											fieldName="cover_letter"></CustomDropDown>
								<!-- <div class="cover-page-box cursor-alias">
									<p class="mb-0 cursor-pointer">Not Set</p>
								</div> -->
							</div>
							<div class="d-flex align-items-center">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">CONNECTION STATUS</p>
								</div>
								<div class="status-show popper-tips-hvr cursor-pointer">
									<div class="d-flex align-items-center">
										<span class="check-span">
											<img src="images/check-mark.svg">
										</span>
										<p class="mb-0 cnct-status-ttl">Success</p>
									</div>
								</div>
								<div class="status-show popper-tips-hvr cursor-pointer d-none">
									<div class="d-flex align-items-center">
										<span class="clode-span">
											<img src="images/close-icon.svg">
										</span>
										<p class="mb-0 cnct-status-ttl">Success</p>
									</div>
								</div>
								<div class="status-show popper-tips-hvr cursor-pointer d-none">
									<div class="d-flex align-items-center">
										<span class="question-span">
											<img src="images/question-mark.svg">
										</span>
										<p class="mb-0 cnct-status-ttl">Success</p>
									</div>
								</div>
							</div>
						</div>
						<div class="srfax-account-dtls mt-25 mb-25 " v-if="!this.isShowSrForm">
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label cursor-alias">
									<p class="mb-0">ACCOUNT NUMBER</p>
								</div>
								<div class="srfax-dtls-show cursor-alias">
									<p class="mb-0">{{ this.form.account_number }}</p>
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">PASSWORD</p>
								</div>
								<div class="srfax-dtls-show">
									<p class="mb-0 cursor-alias">********</p>
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">NOTIFICATION EMAIL</p>
								</div>
								<div class="srfax-dtls-show">
									<p class="mb-0 cursor-alias">{{ this.form.notification_email }}</p>
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">RESPONSE FAX NUMBER</p>
								</div>
								<div class="srfax-dtls-show">
									<p class="mb-0 cursor-alias">{{ this.form.response_fax_number }}</p>
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cur_po_arrow cursor-alias">COVER PAGE</p>
								</div>
								<div class="cover-page-box cursor-alias">
									<p class="mb-0 cursor-alias cursor-alias">{{ this.form.cover_letter }}</p>
								</div>
							</div>
							<div class="d-flex align-items-center">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">CONNECTION STATUS</p>
								</div>
								<div class="status-show popper-tips-hvr cursor-pointer">
									<div class="d-flex align-items-center">
										<span class="check-span">
											<img src="images/check-mark.svg">
										</span>
										<p class="mb-0 cnct-status-ttl">Success</p>
									</div>
								</div>
								<div class="status-show popper-tips-hvr cursor-pointer d-none">
									<div class="d-flex align-items-center">
										<span class="clode-span">
											<img src="images/close-icon.svg">
										</span>
										<p class="mb-0 cnct-status-ttl">Success</p>
									</div>
								</div>
								<div class="status-show popper-tips-hvr cursor-pointer d-none">
									<div class="d-flex align-items-center">
										<span class="question-span">
												<img src="images/question-mark.svg">
											</span>
										<p class="mb-0 cnct-status-ttl">Success</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="main_bx_con">
					<div class="d-flex align-items-center " :class="{ 'justify-content-between': this.isShowStannpForm }">
						<h2 class="h1-admin_head mb-0 cursor-alias">Stannp Account Settings</h2>
						<span class="popup_link ml-50" @click="showStannpForm" v-if="!this.isShowStannpForm">Edit</span>
						<div class="mx15">
							<span class="popup_link mr-50" v-if="this.isShowStannpForm" @click="cancelStannp">Cancel</span>
							<span class="popup_link" v-if="this.isShowStannpForm">Save</span>
						</div>
					</div>
					<div class="dtl_con">
						<div class="srfax-account-dtls mt-25 mb-25" v-if="this.isShowStannpForm">
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">ACCOUNT NUMBER</p>
								</div>
								<div class="srfax-dtls-edit w-100">
									<input type="number" placeholder="" value="12345" class="form-control cursor-alias">
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">PASSWORD</p>
								</div>
								<div class="srfax-dtls-edit w-100">
									<div class="usr-dtls-inp position-relative">
										<input type="password" class="form-control cursor-alias height-25 w-100" v-model="form.pa">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer">
									</div>
								</div>
							</div>
							<div class="d-flex align-items-center">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">API Key</p>
								</div>
								<div class="srfax-dtls-show">
									<p class="mb-0 cursor-alias">********</p>
								</div>
							</div>
						</div>
						<div class="srfax-account-dtls mt-25 mb-25" v-if="!this.isShowStannpForm">
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">ACCOUNT NUMBER</p>
								</div>
								<div class="srfax-dtls-show">
									<p class="mb-0 cursor-alias">112345</p>
								</div>
							</div>
							<div class="d-flex align-items-center mb-30">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">PASSWORD</p>
								</div>
								<div class="srfax-dtls-show">
									<p class="mb-0 cursor-alias">********</p>
								</div>
							</div>
							<div class="d-flex align-items-center">
								<div class="srfax-label">
									<p class="mb-0 cursor-alias">API Key</p>
								</div>
								<div class="srfax-dtls-show">
									<p class="mb-0 cursor-alias">********</p>
								</div>
							</div>
						</div>						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './sidebar.vue'
import axios from "@/scripts/axios.js";
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
export default {
	data(){
		return{
			healthCardEligible:'Health Card Passed Validation',
			healthCardclose:'Health Card Does not Exists/ Invalid ',
			isShowSrForm:false,
			isShowStannpForm:false,
			srFaxData:[],
			coverPageOpt:[
					{ value: "Not Set", title: "Not Set" },
					{ value: "Basic", title: "Basic" },
					{ value: "Standard", title: "Standard" },
					{ value: "Company", title: "Company" },
					{ value: "Personal", title: "Personal" },
				],
		}
	},
	components:{
		sidebar,
		CustomDropDown
	},
	methods:{
		showSRFaxForm(){
			this.isShowSrForm = true
		},
		cancelSrfax(){
			this.isShowSrForm = false
		},
		showStannpForm(){
			this.isShowStannpForm = true
		},
		cancelStannp(){
			this.isShowStannpForm = false
		},
		fetchSRFaxRecords(){
			this.$store.state.loader = true;
				axios.post('admin/fax-config/list')
                    .then((response) => {
                        this.$store.state.loader = false;
                        if (response.status == 200) {
							console.log(response.data.data)
							if (Array.isArray(response.data.data) && response.data.data.length === 0) {
								this.$store.state.fax.form = this.$store.state.user_site_details.defaultFormData;
							} else {
								this.$store.state.fax.form = response.data.data;
							}
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
		},
		handleItemSelected(values) {
			this.form[values.fieldName] = values.item.value
		}
	},
	mounted(){
		this.fetchSRFaxRecords()
	},
	computed: {
        form() {
			return this.$store.state.fax.form;
		},
    },
    validations() {
		return this.$store.state.fax.validationRules;
	},
};
</script>