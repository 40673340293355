<template>
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h3 style="text-align: center;">Task</h3>
        <div class="row">		
            <div class="col-md-6">
                <div class="row">
                    <label class="col-md-6">Demographic Name</label>
                    <label class="col-md-6">{{this.patient_detail.full_name}}</label>
                </div>

                <div class="row">
                    <label class="col-md-6">Chart No.</label>
                    <label class="col-md-6">{{this.patient_detail.chart_id}}</label>
                </div>

                <div class="row">
                    <label class="col-md-6">Age</label>
                    <label class="col-md-6">{{this.patient_detail.age}}({{this.patient_detail.dob}})</label>
                </div>

            </div>

            <div class="col-md-6">
                <div class="row">
                    <label class="col-md-6">Phone No. (Primary)	</label>
                    <label class="col-md-6">{{this.patient_detail.cell_phone}}</label>
                </div>

                <div class="row">
                    <label class="col-md-6">Phone No. (Secondary)</label>
                    <label class="col-md-6">{{this.patient_detail.home_phone}}</label>
                </div>

                <div class="row">
                    <label class="col-md-6">Email</label>
                    <label class="col-md-6">{{this.patient_detail.email}}</label>
                </div>

            </div>

        </div>

        <div class="row mt-5" v-if="form.statusDetail">
            <table width="100%">
                <tr>
                    <th>Messages</th>
                    <th>Added By</th>
                    <th>Update Date</th>
                </tr>
                <tr v-for="(item,index) in form.statusDetail" :key="index">
                    <th>{{item.message}}</th>
                    <th>{{item.created_by}}</th>
                    <th>{{item.created_datetime}}</th>
                </tr>
            </table>
        </div>

        <div class="row" style="margin-top:5vw;">
                <div class="col-md-6">
                        <SelectBoxStatic divClass="col-md-12" v-model="form.suggested_msg_op" title="Suggested Text" :option="suggested_msg" ></SelectBoxStatic>

                        <button class="btn btn-primary btn-sm text-white col-md-2" @click="pasteMessage">Paste Message</button>

                        <TextArea divClass="col-12" v-model="form.message"  :rows="6" placeholder="Enter Message" style="width: 100%;  resize: vertical" :errors="v$.form.message.$errors"></TextArea>
                </div>

                <div class="col-md-6">
                    <SelectBoxStatic divClass="col-md-12" title="Status" v-model="form.status" :option="status_options" :errors="v$.form.status.$errors" :isRequired="true"></SelectBoxStatic>


                    <SelectBoxStatic divClass="col-md-12" title="Priority" v-model="form.priority" :option="priority_options" :errors="v$.form.priority.$errors" :isRequired="true"></SelectBoxStatic>

                    <SelectBoxStatic divClass="col-md-12" title="Task Assigned to	" v-model="form.assigned_to" :option="providerDataSource" :errors="v$.form.assigned_to.$errors" :isRequired="true"></SelectBoxStatic>

                    <DatePicker divClass="col-md-12" title="Service Date" v-model="form.service_date" :isRequired="true" :errors="v$.form.service_date.$errors"></DatePicker>
                </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center pt-10">
                <button class="btn btn-danger text-white col-md-1 btn-sm" @click="closeWindow"> Cancel &amp; EXIT </button>
                <form-btn
                    :title="this.saveBtn"
                    btnCls="btn btn-primary text-white col-md-1 ml-2 btn-sm"
                    style="margin-left:1vw"
                    @click="update"
                    v-if="this.$filters.permissioncheck([this.$store.state.permission.task_task_crud_all, this.$store.state.permission.task_task_crud])"
                ></form-btn>
            </div>
        </div>

    </div>
</template>

<script>
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import $ from "jquery";
import DatePicker from '../../base/formFields/DatePicker.vue';
import TextArea from '../../base/formFields/TextArea.vue';
import FormBtn from "../../base/formFields/FormBtn.vue";

export default {
	data() {
		return {
            saveBtn: "",
            providerDataSource:[],
            patient_detail:[],
            priority_options:[
                { value: "High", title: "High" },
                { value: "Normal", title: "Normal" },
                { value: "Low", title: "Low" },
            ],
            status_options:[
                { value: "Active", title: "Active" },
                { value: "Complete", title: "Complete" },
                { value: "Deleted", title: "Deleted" },
            ],
            suggested_msg:[
                {"value": "Advised RTC for immunization", "title": "Advised RTC for immunization"},
                {"value": "Advised RTC for Lab Work", "title": "Advised RTC for Lab Work"},
                {"value": "Advised RTC for Rx", "title": "Advised RTC for Rx"},
                {"value": "Advised RTC see INFO", "title": "Advised RTC see INFO"},
                {"value": "Advised RTC see MD", "title": "Advised RTC see MD"},
                {"value": "Advised Test Results", "title": "Advised Test Results"},
                {"value": "Declined treatment", "title": "Declined treatment"},
                {"value": "Don't call", "title": "Don't call"},
                {"value": "Letter sent", "title": "Letter sent"},
                {"value": "Msg on ans. mach. to call clinic", "title": "Msg on ans. mach. to call clinic"},
                {"value": "Msg with roomate to call clinic", "title": "Msg with roomate to call clinic"},
                {"value": "Notified", "title": "Notified"},
                {"value": "Notified. Patient is asymptomatic.", "title": "Notified. Patient is asymptomatic."},
                {"value": "Phone - No Answer", "title": "Phone - No Answer"},
                {"value": "Prescription given", "title": "Prescription given"},
                {"value": "Prescription phoned in to:", "title": "Prescription phoned in to:"},
                {"value": "Re-Booked for followup", "title": "Re-Booked for followup"},
                {"value": "Referral Booked", "title": "Referral Booked"},
                {"value": "Returned for Lab Work", "title": "Returned for Lab Work"},
                {"value": "Telephone Busy", "title": "Telephone Busy"}
            ]

        };
    },
    setup: () => {
		return { v$: useVuelidate() };
	},
    components: {
        TextArea,
        DatePicker,
        SelectBoxStatic,
        FormBtn
    },
    computed: {
        form() {
			return this.$store.state.task.form;
		},
        vuelidateExternalResults() {
			return this.$store.state.task.vuelidateExternalResults;
		},
    },
    watch: {
		
	},
	mounted() {
        this.edit()
        this.fetchProviderData()
        this.fetchPatientDetail()
    },
    methods: {
        fetchProviderData() {
			axios.post("json_list/provider", { 'roleId': '' })
				.then((response) => {
					const providerTempData = []
					const details = response.data.data;
					$.each(details, function (key, detail) {
						providerTempData.push({
							value: detail.id,
							title: detail.full_name,
						})
					})
					this.providerDataSource = providerTempData
				})
		},
        update() {
            this.v$.$validate();
			if (!this.v$.$error) {
                this.loader = true;
                this.form.patient_id = this.$route.params.patient_id;

                let url
				if (this.$route.name === "PatientTaskEdit") {
                    this.form.id = this.$route.params.id;
					url = "patient/task/update";
				} else {
					url = "patient/task/store";
				}

                axios.post(url, this.form)
				.then((response) => {
                    this.loader = false;
					if (response.status == 200) {
                        this.$filters.moshaToast(response.data.message, "success");
                        this.closeWindow()
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            }
        },
        closeWindow(){
            if (Object.prototype.hasOwnProperty.call(this.$route.query, 'display')) {
                this.$router.push(`/task`);
            } else {
                this.$router.push(`/patient/task/${this.$route.params.patient_id}/list`);
            }
            // if (window.opener === null) {
            //     // console.log('This page was opened in a new window or tab.');
            //     this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}`);

            // } else {
            //     // console.log('This page was opened from another page.');
            //     window.close();
            // }
        },
        edit() {
            if (this.$route.name === "PatientTaskEdit") {
                this.saveBtn = "Update & EXIT";
                axios.post("patient/task/retrieve", { 'patient_id': this.$route.params.patient_id, 'id': this.$route.params.id})
                    .then((response) => {
                        if (response.data.status === 200) {
                            this.$store.state.task.form = response.data.data;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error")
                        }
                    }).catch(error => {
                        this.$filters.moshaToast(error.message, "error")
                    });
            } else {
                this.saveBtn = "Submit & EXIT";
            }
        },
        pasteMessage() {
            this.form.message = this.form.suggested_msg_op
        },
        fetchPatientDetail() {
			axios.post("patient/detail", { 'id': this.$route.params.patient_id })
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},

	},
    validations() {
		return this.$store.state.task.validationRulesEdit;
	},
    beforeCreate() {
    }
}
</script>