<template>

<div class="col-10">
  <div class="box">
    <div class="box-header">
      <div class="media align-items-top p-0">
        <!-- <a class="avatar avatar-lg status-success mx-0" href="#">
        <img src="../images/avatar/2.jpg" class="rounded-circle" alt="...">
        </a> -->
        <div class="d-lg-flex d-block justify-content-between align-items-center w-p100">
          <div class="media-body mb-lg-0 mb-20">
            <p class="fs-16">
              <a class="hover-primary" href="#"><strong>{{ currentUserVal }}</strong></a>
            </p>
              <!-- <p class="fs-12">Last Seen 10:30pm ago</p> -->
          </div>
          <div>
            <!-- <ul class="list-inline mb-0 fs-18">
              <li class="list-inline-item"><a href="#" class="hover-primary"><i class="fa fa-phone"></i></a></li>
              <li class="list-inline-item"><a href="#" class="hover-primary"><i class="fa fa-video-camera"></i></a></li>
              <li class="list-inline-item"><a href="#" class="hover-primary"><i class="fa fa-ellipsis-h"></i></a></li>
            </ul> -->
          </div>
        </div>				  
      </div> 
    </div>
<!-- 
    <div class="box-body">
      <div class="slimScrollDiv " ref="messageContainer" style="position: relative; overflow-y: scroll; width: auto; height: 685px;">
          <div class="chat-box-one2" style="overflow: hidden; width: auto; height: 685px;" >

            <slot  v-for="message in messages" :key="message.id">
            <div class="card d-inline-block mb-3 me-2 no-shadow  max-w-p80" :class="{ 'float-end bg-primary': message.isSent, 'float-start bg-lighter': !message.isSent }">
                <div class="position-absolute pt-1 pe-2 r-0">
                  <span class="text-extra-small " :class="{ '': message.isSent, 'text-muted': !message.isSent }">{{ message.sent_time }}</span>
                </div>
                <div class="card-body">
                  <div class="d-flex flex-row pb-2">
                      <a class="d-flex" href="#">-->
                      <!-- <img alt="Profile" src="../images/avatar/1.jpg" class="avatar me-10"> -->
                      <!--</a>
                      <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="m-2 ps-0 align-self-center d-flex flex-column flex-lg-row justify-content-between">
                            <div class="min-width-zero">
                              <p class="mb-0 fs-16 " :class="{ '': message.isSent, 'text-dark': !message.isSent }">
                                  <slot v-if="!message.isSent && message.group_id">
                                    {{ message.sender_name }}
                                  </slot>
                                </p>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div class="chat-text-start ps-55">
                      <p class="mb-0 text-semi-muted">{{ message.message }}</p>
                      <div v-if="message.document_path && message.document_path.length > 0">
                      <a :href="documentUrl(message.document_path)" target="_blank">View Document</a>
                    </div>
                    <small v-if="message.isSent && !message.group_id" class=" "
                      :class="message.status === 'Failed' ? 'text-danger' : message.status === 'Sent' ? 'text-warning' : 'text-success'">{{ message.status }}</small>
                    <br />
                    <small>{{ message.sent_time }}</small>
                  </div>
                </div>
            </div>
            <div class="clearfix"></div>
            </slot>

          </div>
          <div class="slimScrollBar" style="background: rgb(0, 0, 0); width: 7px; position: absolute; top: 0px; opacity: 0.1; display: none; border-radius: 7px; z-index: 99; right: 1px; height: 286.542px;"></div>
          <div class="slimScrollRail" style="width: 7px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"></div>
      </div>
    </div> -->

    <!-- <div class="box-footer no-border">
        <p v-if="!isValid" style="color: red;">Please enter message.</p>

        <div class="chatbox-wrapper input-group mb-3" style="display:flex; position:relative">

          <div class="input-group-prepend">
            <div class="custom-file input-group-text" v-if="this.file === null">
              <input type="file" id="file" ref="fileInput" @change="uploadDocument" accept=".pdf" style="display: none;">
              <label class="custom-file-label" for="file" title="Upload a file">
                <i class="fa fa-upload"></i>
              </label>
            </div>

            <button class="input-group-text" v-if="this.file !== null" aria-label="Remove the file" title="Remove the file"
              @click="removeFile"><i class="fa fa-close"></i></button>

          </div>


          <input ref="newMessage" id="newMessage" type="text" class="form-control" v-model="newMessage"
            @keyup.enter="sendMessage" placeholder="Type a message..." />

          <div class="input-group-prepend">
            <button aria-label="Send" class="input-group-text" title="Send" @click="sendMessage"><i class="fa fa-paper-plane"
                aria-hidden="true"></i></button>
          </div>
    </div> -->


  <div class="box-body">
    <div class="slimScrollDiv message-container" ref="messageContainer">
          <div class="message chat-box-one2" v-for="message in messages" :key="message.id">
            <div :class="{ 'sent': message.isSent, 'received': !message.isSent }">
              <div v-if="(message.isSent) || (!message.isSent && message.sender_user_id == this.currentUserIdVal)">
                <div v-if="!message.isSent && message.group_id">
                  <b>{{ message.sender_name }}</b>
                </div>

                {{ message.message }}
                <div v-if="message.document_path && message.document_path.length > 0">
                  <a :href="documentUrl(message.document_path)" target="_blank">View Document</a>
                </div>
                <small v-if="message.isSent && !message.group_id" class=" "
                  :class="message.status === 'Failed' ? 'text-danger' : message.status === 'Sent' ? 'text-warning' : 'text-success'">{{ message.status }}</small>
                <br />
                <small>{{ message.sent_time }}</small>
              </div>
            </div>


          </div>
        </div>

        <p v-if="!isValid" style="color: red;">Please enter message.</p>

        <div class="chatbox-wrapper input-group mb-3" style="display:flex; position:relative">

          <div class="input-group-prepend">
            <div class="custom-file input-group-text" v-if="this.file === null">
              <input type="file" id="file" ref="fileInput" @change="uploadDocument" accept=".pdf" style="display: none;">
              <label class="custom-file-label" for="file" title="Upload a file">
                <i class="fa fa-upload"></i>
              </label>
            </div>

            <button class="input-group-text" v-if="this.file !== null" aria-label="Remove the file" title="Remove the file"
              @click="removeFile"><i class="fa fa-close"></i></button>

          </div>


          <input ref="newMessage" id="newMessage" type="text" class="form-control" v-model="newMessage"
            @keyup.enter="sendMessage" placeholder="Type a message..." />

          <div class="input-group-prepend">
            <button aria-label="Send" class="input-group-text" title="Send" @click="sendMessage"><i class="fa fa-paper-plane"
                aria-hidden="true"></i></button>
          </div>

                

        </div>
    </div>
  </div>

</div>


</template>
  
<script>
import axios from "@/scripts/axios.js";
import FileUpload from "../base/formFields/FileUpload.vue";
import useVuelidate from "@vuelidate/core";
import InputField from "../base/formFields/InputField.vue"
import { encryptMessage, decryptMessage } from '@/cryptoUtils';
import $ from "jquery";

export default {
  props: ['currentUserVal', 'currentUserIdVal', 'messages', 'chatType',],
  data() {
    return {
      newMessage: "",
      file: null,
      isValid: true,
    };
  },
  setup: () => {
    return {  v$: useVuelidate() };
  },
  componets: {
    InputField,
    FileUpload,
  },
  async created() {},
  methods: {
    handleReceivedData(socketData) {
      console.log("chat", socketData)
      if (typeof socketData === 'string') {
        var msgDetail = JSON.parse(socketData);
        console.log(msgDetail)
        if (msgDetail.type == 'message' && msgDetail.status != 'Failed' && msgDetail.sender_user_id != localStorage.getItem('userId')) {
          msgDetail.message = decryptMessage(msgDetail.message)
          this.updateStatus(msgDetail.id)
          msgDetail.status = 'Received'
          msgDetail.isSent = false
          this.$emit('updateMessage', msgDetail);
          this.scrollToBottom()
        }
      }
    },
    focusInput() {
      this.$refs.newMessage.focus();
      if (this.$refs.newMessage) {
        // Check if the element exists before focusing
        this.$refs.newMessage.focus();
      }
    },
    async sendMessage() {
      this.isValid = (this.newMessage && this.newMessage.length > 0);
      this.v$.$validate();
      if (this.isValid) {

        const config = {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
            'Content-Type': 'multipart/form-data'
          },
        };

        const encMsg = await encryptMessage(this.newMessage)
        // Prepare form data
        const formData = new FormData();
        formData.append('message', encMsg);
        formData.append('receiver_user_id', this.currentUserIdVal);
        if (this.file) {
          formData.append('file', this.file);
        }
        if (this.chatType == 'group') {
          formData.append('group_id', this.currentUserIdVal);
        }

        const fileInput = this.$refs.fileInput; // Reference to the file input
        // Send the message to the Laravel backend

        axios.post("chat/send-message", formData, config).then((response) => {
          this.scrollToBottom()
          if (response.data.status == 200) {
            const detail = response.data.data
            detail.type = 'message';
            this.$emit('sendMsgToSocket', detail);
            detail.type = 'notification';
            this.$emit('sendMsgToSocket', detail);
            detail.message = decryptMessage(detail.message)
            this.$emit('updateMessage', detail);

            setTimeout(() => {
              // Your function to be called after 'n' seconds
              this.$emit('fetchMessages', this.currentUserIdVal);
            }, 2 * 1000); // 'n' is in seconds, so we convert it to milliseconds


            this.newMessage = "";
            this.file = null;
            fileInput.value = ''; // Reset the file input
          } else {
            this.$filters.moshaToast(response.data.message, "error");
          }

        }).catch(error => {
          this.$emit('updateMessage', {
            "id": 0,
            "message": this.newMessage,
            "isSent": true,
            "status": "Failed"
          });
          if (error.response.status === 422) {
            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
            console.log(Object.values(error.response.data.data).join(', '))
          } else {
            this.$filters.moshaToast(error.response.data.message, "error");
          }
        });

      }
    },
    uploadDocument(event) {
      this.file = event.target.files[0];
      if (this.file) {
        if (this.validateFile(this.file)) {
          // Do something with the uploaded file, e.g., send it to a server or process it.
          console.log("Uploaded file:", this.file);
        } else {
          this.$filters.moshaToast("Please upload file max 5 MB only", "error");
          this.file = null;
          this.$refs.fileInput.value = null;
        }
      }
    },
    validateFile(file) {
      // Perform file validation here, e.g., check file type and size
      const allowedTypes = ['application/pdf', 'application/msword'];
      const maxFileSize = 2 * 1024 * 1024; // 5 MB

      if (allowedTypes.includes(file.type) && file.size <= maxFileSize) {
        return true; // File is valid
      } else {
        return false; // File is invalid
      }
    },
    documentUrl(documentPath) {
      // Assuming your documents are stored in the 'public' directory
      return `${process.env.VUE_APP_STORAGE_URL}/chat/document/view/${documentPath}`;
    },
    updateStatus(msgId) {
      const headers = {
        'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
        'Content-Type': 'application/json', // Adjust this as needed for your API
      };
      axios.post("chat/update-message-status", { id: msgId, status: 'R' }, headers).then((response) => {
        console.log(response)
      });
    },
    removeFile() {
      this.file = null;
      this.$refs.fileInput.value = null;
    },
    scrollToBottom() {
      // const container = this.$refs.messageContainer;
      // container.scrollTop = container.scrollHeight;
      setTimeout(() => {
        $(".message-container")[0].scrollTop = $(".message-container")[0].scrollHeight;
      }, 50);
    }
  },
  mounted() {
    this.$emit('fetchMessages', this.currentUserIdVal);
    this.focusInput();
  },
  computed: {
    form() {
      return this.$store.state.chat.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.chat.vuelidateExternalResults;
    },
    socketData() {
			return this.$store.getters.getSocketData;
		},
  },
  watch: {
		socketData: {
			handler(newData) {
				if (newData) {
					this.handleReceivedData(newData);
				}
			},
			immediate: true,
		},
	},
  validations() {
    return this.$store.state.group.validationRules;
  },
  beforeUnmount() {

  },
};


</script>
  
<style>
 .message-container {
  max-height: 685px;
  position: relative;
  overflow-y: scroll;
  height: 685px; 
  width: auto;
  /* top:50px; */
  
 }

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}

.message {
  margin: 5px;
  text-align: right;
}

.sent {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
}

.received {
  border-color: #ccc;
  background-color: #ddd;
  text-align: left;
}

.chat-header {
  /* position: relative;
    top: 70px;
    left: 0;
    right: 0; */
  height: 60px;
  /* Adjust the height according to your design */
  border-bottom: 1px solid #ccc;
  /* Add a bottom border for separation */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  /* Add a subtle box shadow */
}

.custom-file-label {
  cursor: pointer;
}</style>