<template>
	<div class="d-flex justify-content-between align-items-center e_c-header">
		<div class="d-flex align-items-center">
			<div class="selact_dropdown">
				<selectCheckBox :dataSource='echartElement' labelName="Select Your E-Chart Elements" @clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key" :isShowDoctorIcon="false" :isUnselectAll="isUnselectAll"/>
			</div>
			<span class="text_link ps-3" @click="handleButtonClick">{{ boxElementToggleBtnText }}</span>
		</div>
		<div class="search">
			<img class="serch_icon" src="/images/search-icon.svg" alt="">
			<!-- <input type="text" @keyup="patientSearch" class="form-control" placeholder="Search UpdateToDate" v-model="patient_search" @blur="patientSearch" ref="globalSearch"> -->
			<input type="text" @keyup.enter="querySearch" class="form-control" placeholder="Search UpdateToDate" v-model="query_search" ref="globalSearch">
		</div>
		<button class="comman_brdr_btn" @click="echartransfer"> TRANSFER </button>
	</div>

	<div class="modal fade" id="e-chart-transfer" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="box">
						<h2 class="popup_title">E-CHART TRANSFER</h2>
						<div class="hdng_con">
							<div class="heading">Date Range</div>
							<span class="con_tx">Please select the date range you would like the E-Chart report to cover.</span>
						</div>
						<div class="row align-items-center justify-content-center time_set_part">
							<div class="col-md-6 text-center">
								<div class="contain_bx">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel"></span>
										<label>All Time Range</label>
									</div>
								</div>
							</div>
							<div class="col-md-6 text-center left_con_box">
								<div>
									<div class="contain_bx">
										<label for="text">From</label>
										<div class="calendar" @click="openDatePicker('start')">
											<ejs-datepicker class="text-center e-field form-control" ref="startDatePicker"
												:format="{ skeleton: 'full' }" :showClearButton="false"
												:value="selectedStartDate" />
										</div>
									</div>
									<div class="contain_bx">
										<label for="text">To</label>
										<div class="calendar" @click="openDatePicker('end')">
											<ejs-datepicker class="text-center e-field form-control" ref="endDatePicker"
												:format="{ skeleton: 'full' }" :showClearButton="false"
												:value="selectedEndDate" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="hdng_con">
							<div class="heading">E-Chart Report Content</div>
							<span class="con_tx">Please select the content you would like the E-chart report to include.</span>
						</div>
						<div class="row justify-content-center selact_box_part">
							<div class="col-md-4">
								<div class="address_line">
									<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" checked></span>
									<label>Demographic</label>
								</div>
								<div class="address_line">
									<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" checked></span>
									<label>Encounter Notes</label>
								</div>
								<div class="address_line">
									<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" checked></span>
									<label>Files</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="address_line">
									<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" checked></span>
									<label>Measurements</label>
								</div>
								<div class="address_line">
									<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" checked></span>
									<label>Medications</label>
								</div>
								<div class="address_line">
									<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" checked></span>
									<label>Other Infos</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="address_line">
									<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" checked></span>
									<label>Preventions</label>
								</div>
								<div class="address_line">
									<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" checked></span>
									<label>Screenings</label>
								</div>
							</div>
						</div>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn mx36"> Cancel </button>
                            <button class="comman_btn mx36" @click="printEchart">Print</button>
                            <button class="comman_btn mx36">Fax</button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";

export default {
	props: [
		'echartElement'
	],
	data() {
		return {
			query_search: '',
			displayDiv: false,
			select_box_key: 0,
			isUnselectAll:false,
			boxElementToggleBtnText: 'Show All Elements',
			selectedStartDate: new Date(),
			selectedEndDate: new Date(),
		};
	},
	watch: {
		
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);
	},
	methods: {
		openDatePicker(type) {
			if (type === 'start') {
				this.$refs.startDatePicker.show();
			} else if (type === 'end') {
				this.$refs.endDatePicker.show();
			}
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				this.query_search = '';
			}
		},
		querySearch() {
			const query = encodeURIComponent(this.query_search.trim());
			if (query) {
				const searchUrl = process.env.VUE_APP_QUERY_SEARCH_URL + query;
				window.open(searchUrl, '_blank');
			}
		},
		selected_provider(result) {
			console.log(result)
		}, 
		echartransfer(){
			window.$("#e-chart-transfer").modal("show");
		},
		handleButtonClick() {
			this.$emit('buttonClick');
			this.boxElementToggleBtnText = (this.boxElementToggleBtnText == "Return To Default")? "Show All Elements" : "Return To Default";
		},
		printEchart(){
			let url = this.$router.resolve({
				name: 'PatientEChartPrint', params: { patient_id: this.$route.params.patient_id}
			}).href;

			window.open(url, '_blank');
		}
	},
	components: {
		selectCheckBox,
		'ejs-datepicker': DatePickerComponent,
	}
};
</script>

<style scoped>
.text_link {
	cursor: pointer;
}
</style>