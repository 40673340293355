import {
	helpers,
	required,
} from "@vuelidate/validators";

const appointmentstatus = {
    namespaced: true,
    state: {
		defaultFormData: {
            id: null,
            status_label:null,
            color_code:null,
        },
        form:{},
        validationRules: {
			form: {
				status_label: {
					required: helpers.withMessage("Please enter status label.", required),
				},
            }
        }
    }
}

export default appointmentstatus;
