<template>
	<div class="row" v-if="documentPath != null">
		<ejs-pdfviewer id="pdfViewer" ref="pdfViewer" :documentPath="documentPath" :resourceUrl="resourceUrl" @documentLoad="onDocumentLoad" :openFile='false' :toolbarSettings="toolbarSettings"></ejs-pdfviewer>
	</div>
	<div class="row">
		<div class="text-center pt-10">
			<form-btn title="Save" btnCls="btn btn-primary text-white btn-sm col-md-1" @click="save()"></form-btn>
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";
import FormBtn from "../../base/formFields/FormBtn.vue";
import $ from "jquery";

import {
	Toolbar, Magnification, Navigation, LinkAnnotation,
	BookmarkView, ThumbnailView, Print, TextSelection, TextSearch,
	Annotation, FormFieldDataFormat
} from '@syncfusion/ej2-vue-pdfviewer';

export default {
	data() {
		return {
			documentPath: null,
			resourceUrl: "https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
			patient_detail: [],
			clinic_detail: [],
			extra_detail: [],
			pdfViewerInstance: null,
			dynamicFields: [],
			toolbarSettings: {
				showTooltip: false,
				toolbarItems: [
					"UndoRedoTool",
					"PageNavigationTool",
					"MagnificationTool",
					"PanTool",
					"SelectionTool",
					"AnnotationEditTool",
					"FormDesignerEditTool",
					"FreeTextAnnotationOption",
					"InkAnnotationOption",
					"ShapeAnnotationOption",
					"StampAnnotation",
					"SignatureOption",
					"PrintOption",
					"DownloadOption"
				],
			},
		};
	},
	components: {
		FormBtn,
	},
	setup: () => { },
	mounted() {
		this.retrieve()
		this.fetchPatientDetail()
		this.fetchClinicDetail()
	},
	watch: {
	},
	methods: {
		onDocumentLoad() {
			this.pdfViewerInstance = this.$refs.pdfViewer.ej2Instances
			this.import();
		},
		retrieve() {
			axios.post("e-forms/retrieve", { 'patient_id': this.$route.params.patient_id, 'id': this.$route.params.id })
				.then((response) => {
					if (response.data.status === 200) {
						var detail = response.data.data;
						this.dynamicFields = detail
						this.documentPath = this.documentUrl(detail.file_path)
					}
				}).catch(error => {
					this.$filters.moshaToast(error.message, "error")
				})
		},
		documentUrl(documentPath) {
			// Assuming your documents are stored in the 'public' directory
			return `${process.env.VUE_APP_STORAGE_URL}/e-forms/document/view/${documentPath}`;
		},
		save() {
			const formData = new FormData();
			if (this.pdfViewerInstance) {
				const annotatedPdfBlob = this.pdfViewerInstance.saveAsBlob();
				if (annotatedPdfBlob instanceof Promise) {
					annotatedPdfBlob.then((result) => {
						if (result instanceof Blob) {
							formData.append('pdfData', result, 'edited_file.pdf');
						}
						formData.append('patient_id', this.$route.params.patient_id);
						formData.append('id', this.$route.params.id);
						try {
							const config = {
								headers: {
									'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
									'Content-Type': 'multipart/form-data'
								},
							};
							axios.post('patient/eform/store', formData, config).then((response) => {
								if (response.status == 200) {
									this.closeWindow()
								}
							});
						}
						catch (error) {
							console.error('Something went wrong');
						}
					});
				}
			}
		},
		closeWindow() {			
			window.close();
		},
		export() {
			this.pdfViewerInstance.exportFormFields('Data', FormFieldDataFormat.Json);
		},
		import() {
			let replaceFields = {}
			let $this = this
			$.each(this.dynamicFields.formfields, function (key, field) {
				let fieldName = field.name
				let fieldValue = field.value
				if ($this.isDynamicPattern(fieldValue)) {
					var convertedString = field.value.replace(/[[\]]/g, '');
					convertedString = convertedString.replace(/ /g, '_').toLowerCase();
					if (convertedString.toLowerCase().startsWith('patient_sex')) {
						convertedString = 'patient_sex';
					}
					if (Object.prototype.hasOwnProperty.call($this.patient_detail, convertedString)) {
						replaceFields[fieldName] = $this.patient_detail[convertedString];
					}
					if (Object.prototype.hasOwnProperty.call($this.clinic_detail, convertedString)) {
						replaceFields[fieldName] = $this.clinic_detail[convertedString];
					}
					if (Object.prototype.hasOwnProperty.call($this.extra_detail, convertedString)) {
						replaceFields[fieldName] = $this.extra_detail[convertedString];
					}
				}
			});
			var viewer = document.getElementById('pdfViewer').ej2_instances[0];
			this.pdfViewerInstance.formFieldCollections.forEach(field => {
				if (Object.prototype.hasOwnProperty.call(replaceFields, field.name)) {
					if (field.name == 'Gender') {
						if (field.value == '[Patient Sex - Female]') {
							viewer.formDesignerModule.updateFormField(field, { isSelected: replaceFields[field.name] == 'F' });
						} else {
							viewer.formDesignerModule.updateFormField(field, { isSelected: replaceFields[field.name] == 'M' });
						}
					} else {
						viewer.formDesignerModule.updateFormField(field, { value: replaceFields[field.name] });
					}
				}
			})
		},
		fetchPatientDetail() {
			axios.post("patient/detail", { 'id': this.$route.params.patient_id })
			.then((response) => {
				if (response.data.status === 200) {
					var detail = response.data.data
					var address = detail.address
					if (detail.city && detail.city.length > 0) address += ", " + detail.city;
					if (detail.state && detail.state.length > 0) address += ", " + detail.state;
					if (detail.country && detail.country.length > 0) address += ", " + detail.country;
					if (detail.zip && detail.zip.length > 0) address += ", " + detail.zip;
					this.patient_detail = {
						patient_first_name: detail.first_name,
						patient_last_name: detail.last_name,
						patient_middle_name: detail.middle_name,
						patient_phone: detail.cell_phone,
						patient_email: detail.email,
						patient_sex: detail.gender,
						patient_dob: detail.dob,
						patient_address_line: detail.address,
						patient_address: address,
						patient_city: detail.city,
						patient_province: detail.state,
						patient_postal: detail.zip,
						patient_hin: detail.health_insurance_no,
						patient_hin_ver: detail.health_card_ver,
						patient_hin_and_ver: detail.health_insurance_no + " " + detail.health_card_ver,
					}
				} else {
					this.$filters.moshaToast(response.data.message, "error")
				}
			})
			.catch(error => {
				this.$filters.moshaToast(error.message, "error")
			});
		},
		fetchClinicDetail() {
			axios.post("clinic/detail", {})
			.then((response) => {
				if (response.data.status === 200) {
					var detail = response.data.data
					var address = detail.address
					if (detail.city && detail.city.length > 0) address += ", " + detail.city;
					if (detail.state && detail.state.length > 0) address += ", " + detail.state;
					if (detail.country && detail.country.length > 0) address += ", " + detail.country;
					if (detail.zipcode && detail.zipcode.length > 0) address += ", " + detail.zipcode;
					this.clinic_detail = {
						clinic_name: detail.organization_name,
						clinic_phone: detail.phone_number,
						clinic_fax_number: detail.fax,
						clinic_label: detail.organization_name + ", " + address,
						clinic_address_line: detail.address,
						clinic_address: address,
						clinic_city: detail.city,
						clinic_province: detail.state,
						clinic_postal: detail.zipcode,
						clinic_email: detail.email,
					}
				} else {
					this.$filters.moshaToast(response.data.message, "error")
				}
			})
			.catch(error => {
				this.$filters.moshaToast(error.message, "error")
			});
		},
		isDynamicPattern(inputString) {
			if (typeof inputString === 'undefined') {
				return false
			}
			return (
				inputString.length > 0 &&
				inputString[0] === '[' &&
				inputString[inputString.length - 1] === ']'
			);
		},
	},
	beforeCreate() {
		const today = new Date();
		this.extra_detail = {
			'today_date': today.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
			'current_time': today.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false })
		}
	},
	provide: {
		PdfViewer: [Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation]
	}
}


</script>
<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css";

#pdfViewer_fileUploadElement {
	display: none !important;
}

.dynamicDropdown {
	position: absolute;
	margin-top: 2.5vw;
}
</style>