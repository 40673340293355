<template>
	<h4 class="box-title">Create User</h4>
	<div class="row">
		<div class="col-lg-6 col-12">
			<div class="box">
				<div class="box-body">
					<h4 class="box-title text-info mb-0"><i class="ti-user me-15"></i> Provider Information</h4>
					<hr class="my-15">
					<div class="row">						
						<InputField title="Title" id="title" v-model="form.title"> </InputField>

						<SelectBoxStatic title="Type" v-model="form.type" :option="type_option"></SelectBoxStatic>
						
						<InputField title="First Name" id="first_name" :isRequired="true" v-model="v$.form.first_name.$model" :errors="v$.form.first_name.$errors"> </InputField>
							
						<InputField title="Last Name" id="last_name" :isRequired="true" v-model="v$.form.last_name.$model" :errors="v$.form.last_name.$errors"> </InputField>
							
						<InputField title="Email" id="email" v-model="v$.form.email.$model" :errors="v$.form.email.$errors"></InputField>
						
						<InputField title="Name Suffix" id="suffix" v-model="form.suffix"></InputField>
						
						<SelectBoxStatic title="Gender" v-model="form.gender" :option="gender_option"></SelectBoxStatic>
						
						<InputField title="Specialty" id="specialty" v-model="form.specialty"></InputField>
					
						<InputField id="username" title="Username" :isRequired="true" v-model="v$.form.username.$model" :errors="v$.form.username.$errors"></InputField>

						<Password id="password" title="Password" :isRequired="true" v-model="v$.form.password.$model" :errors="v$.form.password.$errors" :placeholder="getPassPlaceholder" v-on:keyup="usercheck" :showPassword="true" :suggestion="true"></Password>

						<Password id="password_confirmation" title="Confirm Password" :isRequired="true" v-model="v$.form.password_confirmation.$model" :errors="v$.form.password_confirmation.$errors" :placeholder="getPassPlaceholder" @focusout="passcheck"></Password>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6 col-12">
			<div class="box">
				<div class="box-body">
					<h4 class="box-title text-info mb-0"><i class="ti-user me-15"></i> Provider Contact </h4>
					<hr class="my-15">
					<div class="row">
						<InputField title="Cell Phone" id="cell_phone" v-model="form.cell_phone" mask="(000) 000-0000"></InputField>

						<InputField title="Home Phone" id="home_phone" v-model="form.home_phone" mask="(000) 000-0000"></InputField>

						<InputField title="Work Phone" id="work_phone" v-model="form.work_phone" mask="(000) 000-0000"></InputField>

						<InputField title="Fax" id="fax" v-model="form.fax" mask="(000) 000-0000"></InputField>

						<InputField title="Zip Code" id="zip_code" v-model="form.zip_code" @focusout="getZipCodeInfo"></InputField>

						<InputField title="Country" id="country" v-model="form.country"></InputField>

						<InputField title="State" id="state" v-model="form.state"></InputField>

						<InputField title="City" id="city" v-model="form.city"></InputField>

						<TextArea title="Address" id="address" v-model="form.address"></TextArea>
					</div>
				</div>
			</div>
		</div>
		<div class="box align-items-center">
			<div class="box-footer">
				<form-btn :title="btnname" btnCls="btn btn-primary" @click="submit"></form-btn>
			</div>
		</div>
	</div>
</template>
<script>
import InputField from "../base/formFields/InputField.vue"
import Password from "../base/formFields/Password.vue"
import FormBtn from "../base/formFields/FormBtn.vue";
import useVuelidate from "@vuelidate/core";
import SelectBoxStatic from "../base/formFields/SelectBoxStatic.vue";
import TextArea from "../base/formFields/TextArea.vue";
import axios from "@/scripts/axios.js";


export default {
	components: {
		InputField,
		FormBtn,
		SelectBoxStatic,
		TextArea,
		Password,
	},
	data() {
		return {
			gender_option: [
				{ value: "M", title: "Male" },
				{ value: "F", title: "Female" },
				{ value: "T", title: "Transgendered" },
				{ value: "O", title: "Other" },
				{ value: "U", title: "Undefined" }
			],
			type_option: [
				{ value: 1, title: "Administrator" },
				{ value: 2, title: "Doctor" },
				{ value: 3, title: "Midwife" },
				{ value: 4, title: "Nurse" },
				{ value: 5, title: "Nurse Practitioner" },
				{ value: 6, title: "Receptionist" },
				{ value: 7, title: "Resident" },
				{ value: 8, title: "Counsellor" },
				{ value: 8, title: "Case Manager" },
			]
		}
	},
	setup: () => {
		return { v$: useVuelidate() };
	},
	computed: {
		form() {
			return this.$store.state.provider.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.provider.vuelidateExternalResults;
		},
		btnname() {
			return this.$route.name === "ProviderEdit" ? "Update" : "Save";
		},
	},
	validations() {
		return this.$store.state.provider.validationRules;
	},
	methods: {
		submit() {
			if (this.v$.form.$validate()) {
				console.log(this.form)
			}
		},
		getZipCodeInfo() {
			if (!!this.form.zip_code && this.form.zip_code.trim() !== '') {
				axios.post("state-city-auto-populations", { zip_code: this.form.zip_code })
					.then((response) => {
						this.form.city = response.data.city;
						this.form.state = response.data.id
					});
			} else {
				this.form.city = null;
				this.form.state = null;
			}
		}
	}
}
</script>