import App from './App.vue';
import store from "./store";
import router from "./router";
import { createApp } from 'vue'
import VueMask from "@devindex/vue-mask";
import moment from "moment";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import VueNativeSock from 'vue-native-websocket-vue3';
import VueHtmlToPaper from 'vue-html-to-paper';
import axios from "@/scripts/axios.js";
import VueSmoothScroll from "vue3-smooth-scroll";
import { PdfViewerPlugin } from '@syncfusion/ej2-vue-pdfviewer';
import WebSocketService from "@/scripts/websocket.js";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";


axios.interceptors.response.use(
	(response) => response,
	(error) => {
		console.log(error.message);
		if (error.response.status === 422) {
			store.commit("setErrors", error.response.data.data);
			return Promise.reject(error);
		} else if (error.response.status === 401) {
			localStorage.clear();
			setTimeout(function () {
				location.reload();
			}, 100);
		} else {
			return Promise.reject(error);
		}
	}
);
const app = createApp(App);
const key = "mhJkU";
app.use(store);
app.use(router);
app.use(VueMask);
app.use(VueSweetalert2);
app.use(VueSmoothScroll, {
	updateHistory: false,
	offset: -250,
	easingFunction: (t) => t * t,
});
app.use(PdfViewerPlugin);
app.use(DateRangePickerPlugin);

// real time chat
// app.use(VueNativeSock, process.env.VUE_APP_WEB_SOCKET_URL, {
// 	reconnection: true,
// 	reconnectionAttempts: Infinity, // (Number) number of reconnection attempts before giving up (Infinity),
// 	reconnectionDelay: 5000, // (Number) how long to initially wait before attempting a new (1000)
// 	reconnectionDelayMax: 60000, // Maximum delay between reconnection attempts
// });

app.config.errorHandler = function (err, vm, info) {
console.log(err);	
console.log(info);
}

// Html 2 PDF
const options = {
	name: '_blank',
	specs: [
		'fullscreen=yes',
		'titlebar=yes',
		'scrollbars=yes'
	]
}
app.use(VueHtmlToPaper, options);

const socket = new WebSocketService();
app.config.globalProperties.$socket = socket;


app.mount("#app");

app.config.globalProperties.$filters = {
	inputDateTime(date, format) {
		if (date) {
			let dt_format = format;
			const datePattern = /^\d{4}-\d{2}-\d{2}$/;
			const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?$/;
			
			if(format == 'hh:mm A'){
				let dateString = date.toString();
				const parts = dateString.split(' ');
				const timePart = parts[parts.length - 1];
				const formattedTime = moment(timePart, 'hh:mm:ss').format('hh:mm A');
				return formattedTime;
			}else{
				if (datePattern.test(date)) {
					dt_format = format ? format : "YYYY-MM-DD";
				} else if (dateTimePattern.test(date)) {
					dt_format = format ? format : "YYYY-MM-DD hh:mm A";
				}				
			}
			return moment(date).format(dt_format);
		} else {
			return "";
		}
	},
	moshaToast(msg, type) {
		if (type == 'error') {
			type = 'danger';
		}
		createToast(msg, {
			type: type, // or 'info', 'warning', 'error'
			position: 'top-right', // toast position
			timeout: 3000, // toast duration in milliseconds
			showIcon: true, // show an icon in the toast
		});
	},
	reloadPage() {
		this.window.location.reload(true);
	},
	encryptString(input) {
		let result = '';
		for (let i = 0; i < input.length; i++) {
			const charCode = input.charCodeAt(i) ^ key.charCodeAt(i % key.length);
			result += String.fromCharCode(charCode);
		}
		return btoa(result); // Encode to Base64 to ensure '/' is not present
	},
	decryptString(input) {
		const decodedInput = atob(input); // Decode from Base64
		let result = '';
		for (let i = 0; i < decodedInput.length; i++) {
			const charCode = decodedInput.charCodeAt(i) ^ key.charCodeAt(i % key.length);
			result += String.fromCharCode(charCode);
		}
		return result;
	},
	permissioncheck(userPermissionIds){
		let hasAllPermissions = false
		let permission = localStorage.getItem('permission')
		if(permission !== undefined && permission != null) {
			const permissionArr = permission.split(",");
			userPermissionIds.forEach((permissionId) => {
				if (!hasAllPermissions) {
					hasAllPermissions = permissionArr.includes(permissionId.toString())
				}
			});
		}

		return hasAllPermissions
	},
	patientCalculatedAge(dob, is_only_int=false) {
		if (dob) {
			const birthDate = new Date(dob);
			const currentDate = new Date();

			let years = currentDate.getFullYear() - birthDate.getFullYear();
			let months = currentDate.getMonth() - birthDate.getMonth();
			let days = currentDate.getDate() - birthDate.getDate();

			// Adjust age if birthday hasn't occurred yet this month
			if (currentDate.getDate() < birthDate.getDate()) {
				months--;

				// Adjust months to be positive
				if (months < 0) {
					years--;
					months += 12;
				}
			}

			const cal_years = years > 1 ? `${years} years` : years === 1 ? '1 year' : '';
			const cal_months = months > 1 ? `${months} months` : months === 1 ? '1 year' : '';
			const cal_days = days > 1 ? `${days} months` : days === 1 ? '1 year' : '';

			if (cal_years == "" && cal_months == "" && cal_days == ""){
				console.log(new Date())
				console.log(birthDate)
				let isToday =
					birthDate.getDate() === currentDate.getDate() &&
					birthDate.getMonth() === currentDate.getMonth() &&
					birthDate.getFullYear() === currentDate.getFullYear();
				if (isToday) {
					if (is_only_int) {
						return 0;
					}
					return "0 day";
				}
			}

			if (is_only_int) {
				return cal_years.split(' ')[0]
			}
			return `${cal_years}`;
		}
		return 0;
	},
	strToUpperCase(strVal) {
		if(strVal){
			return strVal.toUpperCase();
		}
	},
	getCurrentDateTime() {
		const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];	 

		const currentDate = new Date();
		const monthIndex = currentDate.getMonth();
		const month = months[monthIndex];
		const day = currentDate.getDate();
		const year = currentDate.getFullYear();

		let hour = currentDate.getHours();
		const minute = currentDate.getMinutes();
		const period = hour >= 12 ? 'pm' : 'am';
		hour = hour % 12 || 12;

		const formattedDateTime = `${month} ${day}, ${year}, ${hour}:${minute.toString().padStart(2, '0')} ${period}`;
		return formattedDateTime;
	},
	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	capitalizeFirstWordFirstLetter(string) {
		if (string === null || string === undefined) {
			return '';
		}
		const words = string.split(' ');
		if (words.length > 0) {
			words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
		}
	
		return words.join(' ');
	}
}