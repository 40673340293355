import {
	helpers,
	required,
	email,
	maxLength
} from "@vuelidate/validators";

const billing_account_settings = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			muid: '',
			email: null,
			password: null,
			is_global: 0,
		},
		form: {
            id: null,
			muid: '',
			email: null,
			password: null,
			is_global: 0,
        },
		validationRules: {
			form: {
                muid: {
					required: helpers.withMessage("Please enter muid.", required),
                    isSixDigitNumber: helpers.withMessage('Must be a 6-digit number', (value) => {
                        // Check if it's a number and has exactly 6 digits
                        return /^\d{6}$/.test(value);
                      }),
				},
                email:{
                    required: helpers.withMessage("Please enter email.", required),
                    email: helpers.withMessage("Please enter valid email.", email),
                },
                password:{
                    required: helpers.withMessage("Please enter password.", required),
                },
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const billing_code_settings = {
	namespaced: true,

	state: {
		listUrl: "/admin/billing-code/list",
        header: [
            { "code": "Billing Code" },
            { "description": "Description" },
            { "price": "Price" },
			{ "action": "action" },
        ],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],

		defaultFormData: {
			id: null,
			code: null,
			description: null,
			price: null,
		},
		form: {
			id: null,
			code: null,
			description: null,
			price: null,
        },
		validationRules: {
			form: {
                code: {
					required: helpers.withMessage("Please enter code.", required),
					maxLength:helpers.withMessage("Please enter 3 char code only.", maxLength(3)),
				},
                description:{
                    required: helpers.withMessage("Please enter description.", required),
                },
                price:{
                    required: helpers.withMessage("Please enter price.", required),
                },
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

export { billing_account_settings, billing_code_settings}