<template>
	<div class="row patient_chart add_local_files">
		<div class="col-lg-5 edit_record_page">
			<dv class="containt_detail_title">ADD CONSULTATION</dv>
			<div class="box-body containt_detail">
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Selected Template Description<small class="asterisksign">*</small></label>
					<div class="col-sm-7">
						<input type="text" v-model="form.title" class="form-control">
						<ValidationErrorMessageList :errors="v$.form.title.$errors" />
					</div>
				</div>
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Module<small class="asterisksign">*</small></label>
					<div class="col-sm-7">
						<CustomDropDown :options="moduleList" :initialValue="form.module" fieldName="module" @item-selected="handleItemSelected"></CustomDropDown>
						<ValidationErrorMessageList :errors="v$.form.module.$errors" />
					</div>
				</div>
                <div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Service<small class="asterisksign">*</small></label>
					<div class="col-sm-7">
						<CustomDropDown :options="serviceList" :initialValue="form.service" fieldName="service" @item-selected="handleItemSelected"></CustomDropDown>
						<ValidationErrorMessageList :errors="v$.form.service.$errors" />
					</div>
				</div>
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Demographic<small class="asterisksign">*</small></label>
					<div class="col-sm-7">
						<div class="position-relative">
							<div class="search">
								<img class="serch_icon" src="/images/search-icon.svg" alt="">
								<input type="text" @keyup="patientSearch" class="form-control w-100" placeholder="Search Patient"
									v-model="patient_search" @blur="patientSearch" ref="globalSearch">
							</div>
							<div class="search_drop_box" v-if="patientList.length > 0">
								<slot v-for="(item, index) in patientList" :key="index">
									<div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name+', '+item.first_name)">
										<div class="row px-2 py-3 align-items-center">
											<div class="col-md-6">
												<div class="man_haeding">
													{{ item.last_name }}, {{ item.first_name }}
													<span>({{ item.gender }})</span>
												</div>
												<ul>
													<li>
														DOB: {{ item.dob }}
													</li>
													<li>
														HIN: {{item.health_insurance_no}}
													</li>
													<li>
														Tel: {{item.cell_phone}}
													</li>
												</ul>
											</div>
											<div class="col-md-6 text-end">
												<ol class="">
													<li class="prevent">
														<router-link
															:to="{ name: 'PatientChart', params: { patient_id: item.patient_id, } }"
															target="_blank">M</router-link>
													</li>
													<li class="prevent">
														<router-link
															:to="{ name: 'PatientEChart', params: { patient_id: item.patient_id, } }"
															target="_blank">E</router-link>
													</li>
													<li class="prevent">
														<router-link
															:to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }"
															target="_blank">Rx</router-link>
													</li>
													<li class="prevent">
														<router-link
															:to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }"
															target="_blank">Label</router-link>
													</li>
												</ol>
											</div>
										</div>
									</div>
								</slot>
								<div class="create_grpc border-top"><router-link target="_blank" to="/patient/create">CREATE
										DEMOGRAPHIC</router-link></div>
							</div>
							<ValidationErrorMessageList :errors="v$.form.patient_id.$errors" />
						</div>
					</div>
				</div>

				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Referring Provider<small class="asterisksign">*</small></label>
					<div class="col-sm-7">
						<CustomDropDown :options="referringProviderList" :initialValue="form.provider_name" fieldName="referring_practitioner_id" @item-selected="handleItemSelected"></CustomDropDown>
						<ValidationErrorMessageList :errors="v$.form.referring_practitioner_id.$errors" />
					</div>
				</div>

				<!-- <div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Notes</label>
					<div class="col-sm-7"><input type="text" class="form-control" v-model="form.additional_info"></div>
				</div> -->

				<label for="text" class="col-sm-5 col-form-label popup_link">Fax</label>
				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Fax Subject</label>
					<div class="col-sm-7"><input type="text" v-model="form.fax_subject" class="form-control"></div>
				</div>

				<div class="row pd-bottom">
					<label for="text" class="col-sm-5 col-form-label right-bor">Specialist<small class="asterisksign">*</small></label>
					<div class="col-sm-7">
						<CustomDropDown :options="specialist_option" :initialValue="specialist_text"
							@item-selected="handleItemSelected" v-model="form.specialist_id"
							fieldName="specialist_id">
						</CustomDropDown>
						<ValidationErrorMessageList :errors="v$.form.specialist_id.$errors" />
					</div>
				</div>

				<div class="or_line"><span>OR</span></div>
				<div class="custom-form">
					<div class="input_box">
						<input type="text" id="typeunique_idX-2" class="form-control form-control-lg" required v-model="form.fax_no">
						<label class="form-label" for="typeunique_idX-2">Receipient Fax Number</label>
					</div>
				</div>

			</div>
			<div class="btn_part text-center">
				<button class="comman_brdr_btn big_btn mx36" @click="backToList"> Cancel </button>
				<!-- <button class="comman_brdr_btn"> Delete </button> -->
				<button class="comman_btn big_btn mx36" @click="uploadDocument">Save <img src="images/loader.gif" v-if="savingloader" /></button>
                <button class="comman_btn big_btn mx36" @click="uploadDocument('save_fax')">Save & Fax <img src="images/loader.gif" v-if="savingFaxloader" /></button>
			</div>
		</div>
		<div class="col-lg-7">
			<div class="slide_deatil_box" v-if="documentPath!=null">
				<ejs-pdfviewer 
					id="pdfViewer" 
					ref="pdfviewer" 
					:documentPath="documentPath" 
					:resourceUrl="resourceUrl"
					@documentLoad="onDocumentLoad"
					:enableBookmark="false"
					:openFile='false'
				>
				</ejs-pdfviewer>
			</div>
		</div>
	</div>
</template>
<script>
import CustomDropDown from '../../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
// import $ from "jquery";
import {  Toolbar, Magnification, Navigation, LinkAnnotation, 
         BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, 
         Annotation, FormDesigner, FormFields } from '@syncfusion/ej2-vue-pdfviewer';
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";
import ValidationErrorMessageList from '../../base/ValidationErrorMessageList.vue';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			form: {
				title: null,
				// additional_info: null,
				module: null,
				dynamicFields:[],
                referringProviderList: [],
			},
			moduleList : [
				{ value: "Consultation", title: "Consultation" },
                { value: "Imaging", title: "Imaging" },
                { value: "Lab", title: "Lab" },
                { value: "Others", title: "Others" },
			],
			resourceUrl: "https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
			documentPath: null,
            savingloader:false,
            savingFaxloader:false,
            patientList:[],
            serviceList:[],
            imagingServiceList:[
                { value: "Bone Density Scan", title: "Bone Density Scan" },
                { value: "CT Scan", title: "CT Scan" },
                { value: "MRI", title: "MRI" },
                { value: "Mammography", title: "Mammography" },
                { value: "Ultrasound", title: "Ultrasound" },
                { value: "X-Ray", title: "X-Ray" },
                { value: "Others", title: "Others" },
            ],
            consultationServiceList:[
                { value: "Cardology", title: "Cardology" },
            ],
            labServiceList:[
                { value: "Blood Work", title: "Blood Work" },
                { value: "Microbiology", title: "Microbiology" },
                { value: "Urine", title: "Urine" },
                { value: "Others", title: "Others" },
            ],
            patient_detail:[],
			specialist_option:[]
		}
	},
	components: {
		CustomDropDown,
		ValidationErrorMessageList
	},
	validations() {
		return {
			form: {
				title: {
					required: helpers.withMessage("Please enter template description.", required),
				},
				module:{
					required: helpers.withMessage("Please select module.", required),
				},
				service:{
					required: helpers.withMessage("Please select service.", required),
				},
				referring_practitioner_id:{
					required: helpers.withMessage("Please select referring provider.", required),
				},
				specialist_id:{
					required: helpers.withMessage("Please select specialist.", required),
				},
				patient_id:{
					required: helpers.withMessage("Please select demographic.", required),
				}
			},
		};
	},
	methods: {
        patientSearch() {
				if (this.patient_search.length > 2) {
					axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
						.then((response) => {
							this.patientList = response.data.data;
					})
				}
				if (this.patient_search.length == 0) {
					this.patientList = [];
					this.form.patient_id = null
				}
        },
        handleClickOutside(event) {
				const globalSearchElement = this.$refs.globalSearch;
				if (globalSearchElement && !globalSearchElement.contains(event.target)) {
					// this.patient_search = '';
					this.patientList = [];
				}
			},
		handleItemSelected(obj){
            if (obj.fieldName == 'module') {
                this.form.module = obj.item.value;
            } else if (obj.fieldName == 'service') {
                this.form.service = obj.item.value;
            } else if (obj.fieldName == 'referring_practitioner_id') {
                this.form.referring_practitioner_id = obj.item.value;
            } else if (obj.fieldName == 'specialist_id') {
                this.form.specialist_id = obj.item.value;
            } 
		},
		onDocumentLoad: function () {
			// let viewer = this.$refs.pdfviewer.ej2Instances;
			// $.each(this.dynamicFields, function(key, detail) {
			// 	console.log(key)
			// 	viewer.formDesignerModule.addFormField(detail.type, detail);
			// });
			// viewer.formDesignerModule.updateFormField(viewer.formFieldCollections[0], {  });
		},
		async uploadDocument(action='save') {
			this.v$.$validate();
			if (!this.v$.$error) {
				if (action == 'save_fax') {
					this.savingFaxloader = true
				} else {
					this.savingloader = true
				}

				const formData = new FormData();
				const pdfViewerInstance = this.$refs.pdfviewer.ej2Instances;
				if (pdfViewerInstance) {
					const annotatedPdfBlob = pdfViewerInstance.saveAsBlob();
					if (annotatedPdfBlob instanceof Promise) {
						annotatedPdfBlob.then((result) => {
							if (result instanceof Blob) {
								formData.append('pdfData', result, 'edited_file.pdf');
							}
							formData.append('id', this.$route.params.template_id);
							Object.keys(this.form).forEach(key => {
								formData.append(key, this.form[key]);
							});
							if (action == 'save_fax') {
								formData.append('action', 'fax');
							}

							try {
								const config = {
									headers: {
										'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
										'Content-Type': 'multipart/form-data'
									},
								};

								let url = ''
								if (this.form.module.toLowerCase() == 'consultation') {
									url = 'patient/consultations/create-by-template';
								} else if (this.form.module.toLowerCase() == 'imaging') {
									url = 'patient/imaging/create-by-template';
								} else if (this.form.module.toLowerCase() == 'lab') {
									url = 'patient/lab/create-by-template';
								}

								if (url.length == 0) {
									this.$router.push({ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module:this.form.module.toLowerCase() } });
									return false;
								}

								axios.post(url, formData, config).then((response) => {
									if (response.status == 200) {
										if (action == 'save_fax') {
											this.savingFaxloader = false
										} else {
											this.savingloader = false
										}

										this.$filters.moshaToast(response.data.message, "success");
										this.$router.push({ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module:this.form.module.toLowerCase()} });
									}
								});
							}
							catch (error) {
								console.error('Error saving PDF with notes on the server:', error);
							}
						});
					}
				}
			}
		},
		async edit() {
			axios.post("e-forms/retrieve", { 'id': this.$route.params.template_id })
				.then((response) => {
					if (response.data.status === 200) {
						this.dynamicFields = response.data.data.formfields
						this.form.title = response.data.data.title;
						// this.form.additional_info = response.data.data.additional_info;
						this.form.module = response.data.data.module;
						this.documentPath = this.documentUrl(response.data.data.file_path);
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$filters.moshaToast(error.message, "error");
				});
		},
		documentUrl(documentPath) {
			// Assuming your documents are stored in the 'public' directory
			return `${process.env.VUE_APP_STORAGE_URL}/e-forms/document/view/${documentPath}`;
		},
		backToList() {
			this.$router.push({ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module:this.form.module.toLowerCase() } });
		},
        getProviderList(){
            axios.post("json_list/provider")
				.then((response) => {
					let tempData = [];
					$.each(response.data.data, function(key, item) {
                        tempData.push({ value: item.user_id, title: item.full_name })
					});
					this.referringProviderList = tempData;

				});
        },
        selectPatient(id, patient_name){
            this.$store.state.patient_document.form.patient_id = id
            this.patient_search = patient_name
            this.form.patient_id = id
        },
        fetchPatientDetail() {
			axios.post("patient/detail", { 'id': this.$route.params.patient_id })
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
                        this.patient_search = this.patient_detail.last_name+", "+this.patient_detail.first_name
                        this.$store.state.loader = false;
                        this.form.patient_id = this.patient_detail.id

					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		getSpecialistList(){
                axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
                    .then((response) => {
                         if (response.status == 200) {
                              const details = response.data.data;
                              const specialistTempData = [];
                              $.each(details, function (key, detail) {
                                   detail.value = detail.id
                                   let name = detail.name;
                                   if (detail.is_preferred_specialist) {
                                        name += "<p class='float-end'>(Preferred)</p>"
                                   }
                                   detail.title = name
                                   specialistTempData.push(detail)
                              })
                              this.specialist_option = specialistTempData;
                         } else {
                              this.$filters.moshaToast(response.data.message, "error");
                         }
                    }).catch(error => {
                         this.loader = false;
                         console.log(error)
                         if (error.response.status === 422) {
                              this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                         } else {
                              this.$filters.moshaToast(error.response.data.message, "error");
                         }
                    });
            },
	},
	mounted() {
        this.$store.state.loader = true;
		this.edit();
        this.getProviderList()
        this.fetchPatientDetail()
        document.addEventListener('click', this.handleClickOutside);
        if (this.$route.params.module == 'imaging'){
            this.serviceList = this.imagingServiceList
        } else if (this.$route.params.module == 'consultation'){
            this.serviceList = this.consultationServiceList
        } else if (this.$route.params.module == 'lab'){
            this.serviceList = this.labServiceList
        }
		this.getSpecialistList()


	},
	provide: {
		PdfViewer: [ Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation,  FormDesigner, FormFields ]
	}
}
</script>
<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css";

#pdfViewer_fileUploadElement {
	display: none !important;
}

.e-checkbox-wrapper {
	display: none !important;
}

.pdf_search_section {
	position: absolute;
	top: 31px;
	width: 100%;
	background: #f5f5f5;
	padding: 10px;
	box-shadow: 0px 0px 6px 1px #e1e1e1;
	border-radius: 2px;
	border-top: none;
}

.pdf_search {
	padding-left: 0;
	margin-bottom: -10px;
    margin-top: -7px;
}

.pdf_search_option {
	list-style-type: none;
	line-height: normal;
	border-bottom: 1px solid #e7e4e4;
	padding: 10px;
	cursor: pointer;
}

.pdf_search_option:hover {
	background: #e1e1e1;
}
</style>