<template>    
	<div ref="dropdown" class="position-relative" :class="{ 'disabled': isDisabled }" :divMainClass="divMainClass" style="max-width: 100%;">
		<label @click="displayOption" class="selectCheckBox" style="max-width: 100%; overflow: hidden;">
			<img :src="localDoctorImgIcon" class="img-fluid doc_icon" alt="" v-if="this.isShowDoctorIcon"> <span class="align-middle" :class="this.computedClasses">{{labelName}}</span>
			<!-- <span class="active mx-2 text-info">All</span>  -->
			<img src="/images/down-arrow-new.svg" alt="" class="img-fluid float-end ms-3" :class="{ 'rotate180': display_div }">
		</label> 
		<div v-show="display_div" class="select-check-list selectCheckBox">
			<div class="select-check-option" v-if="isUnselectAll">
				<input type="checkbox" class="filled-in chk-col-info " :id="labelName.replaceAll(' ', '_')+'selectall'" value="selectall" v-model="all_selected" @click="selectall">
				<label :for="labelName.replaceAll(' ', '_')+'selectall'" class="text-capitalize">Unselect All</label>
			</div>
			<slot v-for="(item,index) in dataSource" :key="index">
				<div class="select-check-option">
					<input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
					<label :for="item.name.replace(/\s+/g, '')+index">{{item.name}}</label>
				</div>
			</slot>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		dataSource:Object,
		labelName:String,
		displayDiv:Boolean,
		isShowDoctorIcon: {
			type: Boolean, // Adjust the type based on your data structure
			default: true, // Default value when not provided
		},
		isUnselectAll:{
			type: Boolean, // Adjust the type based on your data structure
			default: true, // Default value when not provided
		},
		divMainClass:{
			type: String,
			default:"d-inline-block"
		},
		isMultiSelection:{
			type: Boolean, // Adjust the type based on your data structure
			default: true, // Default value when not provided
		},
		doctorImgIcon:{
			type: String,
			default:"/images/doctor-man-icon.svg"
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		isOpenDivClassChange:{
			type: Boolean,
			default: false,
		}
	},
	emits: ["clicked", "last-selected-item"],
	data() {
		return {
			selected_list:null,
			display_div:this.displayDiv,
			all_selected:true,
			lastSelectedItem: null,
			isProviderSelected: false,
			localDoctorImgIcon: this.doctorImgIcon
		}
	},    
	methods:{
		selectall(){
			this.selected_list = this.dataSource.map(obj=> ({ ...obj, checked: false }));
			this.$emit('clicked', this.selected_list)	
			this.lastSelectedItem = null; // Reset last selected item
            this.emitLastSelectedItem();		
		},
		selectitem(event){
			if (!this.isMultiSelection) {
				var selected_count_multi = this.selected_list.map((item) => {
					return (item.checked == true) ? item.id : null;
				}).filter(n => n).length;

				if (selected_count_multi > 1) {
					this.dataSource.map((item) => {
						if (event.target.value == item.id) {
							item.checked = true
						} else {
							item.checked = false
						}
					})
					this.selectitem(event)
				}

				this.display_div=false
			}
			this.selected_list = this.dataSource;
			this.$emit('clicked', this.selected_list);
			var selected_count = this.selected_list.map((item) => {
					return (item.checked == true) ? item.id : null;
				}).filter(n => n).length;
			var all_count = this.dataSource.length;
			if(selected_count == all_count){
				this.all_selected=true
			}else{
				this.all_selected=false
			}

			if (event.target.checked) {
				// If checked, get the value of the checkbox or any other relevant data
				console.log('Item checked:', event.target.value);
				// You can also access any other data or methods within your component here
				// Update last selected item
				this.lastSelectedItem = event.target.value;
				this.emitLastSelectedItem();
			}

		},
		displayOption(){
			if(!this.isDisabled)
			{
				if(this.display_div){
					this.display_div=false;
				}else{
					this.display_div=true;
				}
			}
		},
		emitLastSelectedItem() {
            this.$emit('last-selected-item', this.lastSelectedItem);
        },
		handleClickOutside(event) {
			const dropdownEle = this.$refs.dropdown;
				if (dropdownEle && !dropdownEle.contains(event.target)) {
					// console.log('Clicked outside');
					this.display_div = false
				} else {
					console.log('Clicked inside');
					// this.display_div = true
				}
			},

	},
	mounted(){
		document.addEventListener('click', this.handleClickOutside);
		this.selected_list = this.dataSource;
		var selected_count = this.selected_list.map((item) => {
				return (item.checked == true) ? item.id : null;
			}).filter(n => n).length;
		var all_count = this.dataSource.length;
		if(selected_count == all_count){
			this.all_selected=true
		}else{
			this.all_selected=false
		}
	},
	computed:{
		computedClasses(){

			var selected_count_multi = (this.selected_list || []).map(item => {
				return item.checked ? item.id : null;
			}).filter(n => n).length;
			if (this.isOpenDivClassChange && (this.display_div || selected_count_multi > 0)) {
				return "dropdown-opened"
			} else if (this.isOpenDivClassChange && (!this.display_div && selected_count_multi == 0)){
				return "";
			} else {
				return "";
			}
		}
	},
	watch: {
		computedClasses() {
			if (this.isOpenDivClassChange) {
				if (this.computedClasses == 'dropdown-opened') {
					this.localDoctorImgIcon = "/images/doctor-man-icon.svg"
				} else {
					this.localDoctorImgIcon = "/images/doctor-man-icon-gray.svg"
				}
			}
		}
	},
}
</script>