<template>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <h3>Current Patient Set(s)</h3>
            <ul class="list-group">
                <li class="list-group-item" v-for="(item, index) in currentPatientSets" :key="index">{{item.set_name}}</li>
            </ul>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <h3>Add to Patient Set</h3>
            <ul class="list-group">
                <li class="list-group-item" v-for="(item, index) in patientSets" :key="index">{{item.set_name}}</li>
            </ul>
        </div>
    </div>

  <form ref="savePatientSetFrm">
    <div class="row mt-10">
        <div class="col-md-6">
        <InputField title="New Patient Set" v-model="form.set_name" :errors="v$.form.set_name.$errors"> </InputField>
        <form-btn
            title="Save"
            btnCls="btn-primary btn-sm col-md-2"
            @click="savePatientSet"
        ></form-btn>
        <form-btn
            title="Cancel"
            btnCls="btn-danger btn-sm col-md-2"
            @click="closeWindow"
        ></form-btn>
        </div>
    </div>
    </form>


</template>

<script>
import InputField from "../base/formFields/InputField.vue"
import FormBtn from "../base/formFields/FormBtn.vue";
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
// import $ from "jquery";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data(){
        return {
            patientSets:[],
            currentPatientSets:[]
        }
    },
    components: {
        InputField,
        FormBtn
    },
    methods:{
        savePatientSet(){
            this.v$.$validate();
			// const $this = this
			if (!this.v$.$error) {
                this.patientSets.push({'set_name':this.form.set_name})
                this.form.patient_id = this.$route.params.patient_id
                axios.post("patient-set/store", this.form)
				.then((response) => {
                    if (response.status == 200) {
                        this.getPatientSet()
                        this.resetforms();
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				})
            }
        },
        closeWindow() {
            if (window.opener === null) {
                // console.log('This page was opened in a new window or tab.');
                this.$router.push(`/patient/chart/${this.$route.params.patient_id}`);

            } else {
                // console.log('This page was opened from another page.');
                window.close();
            }
        },
        getPatientSet(){
            axios.post("patient-set/list", { patient_id: this.$route.params.patient_id })
				.then((response) => {
                    if (response.status == 200) {
                        this.currentPatientSets = this.patientSets = response.data.data;
                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}

				})
        },
        resetforms() {
            this.$refs.savePatientSetFrm.reset();
		},
    },
    computed: {
		form() {
			return this.$store.state.patient_set.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.patient_set.vuelidateExternalResults;
		},
    },
    validations() {
		return this.$store.state.patient_set.validationRules;
	},
    mounted() {
        this.getPatientSet()
     },
}
</script>
