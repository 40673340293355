<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 billing_module_table analysis_report">
            <div class="header_boxes">
                <div class="selact_dropdown d-inline-block">
                        <selectCheckBox :dataSource='provider_list' :labelName="tagProviderLabel" @clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key" :isShowDoctorIcon="true" :isUnselectAll="isUnselectAll" divMainClass="" doctorImgIcon="/images/doctor-man-icon-gray.svg" :isOpenDivClassChange="true"/>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-75px"></th>
                            <th scope="col">YEAR </th>
                            <th scope="col">MONTH</th>
                            <th scope="col">PROVIDER </th>
                            <th scope="col">REPORT NAME </th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>2024</td>
                            <td>01</td>
                            <td>HONG, JAMES</td>
                            <td>Billing Report Analysis (for Family Physicians)</td>
                            <td>
                                <span class="popup_link">Download</span>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>01</td>
                            <td>HONG, JAMES</td>
                            <td>Billing Report Analysis (for Family Physicians)</td>
                            <td>
                                <span class="popup_link">Download</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import sidebar from './sidebar.vue'
// import useVuelidate from "@vuelidate/core";
import selectCheckBox from "../base/selectCheckBox.vue";
import axios from "@/scripts/axios.js";

export default {
    // setup: () => {
    //     return { v$: useVuelidate() };
    // },
    // validations() {
    //     return {
    //         form: this.$store.state.patient_document.validationRules.form,
    //     }
    // },
    data() {
        return {
            provider_list:[],
            tagProviderLabel:"Select Provider(s)",
            form:{
                provider_ids:[]
            },
            isUnselectAll:false,
        }
    },
    components: {
        sidebar,
        selectCheckBox
    },
    methods: {
        getProviderList() {
				axios.post("json_list/provider", { roleId: 2 })
					.then((response) => {
						this.provider_list = response.data.data.map((item) => {
								return { id: item.id, name: item.full_name, checked: false };
						});
				})

        },
        selected_provider(result){
            this.form.provider_ids = result.filter(item => item.checked).map(item => item.id);
            if (this.form.provider_ids.length == 1) {
                this.tagProviderLabel = `${this.form.provider_ids.length } Provider Selected`;
            } else if (this.form.provider_ids.length > 1){
                this.tagProviderLabel = `${this.form.provider_ids.length } Providers Selected`;
            } else {
                this.tagProviderLabel = "Select Provider(s)";
            }
        },
    },
    mounted() {
        this.getProviderList()
    },
    computed: {

    }
}
</script>
