<template>
	<div class="form-group">
		<div :class="divClass">
			<input type="checkbox" :name="name" :checked="modelValue" :id="id" class="filled-in chk-col-success" :disabled="isCheckboxDisabled" @click="toggleCheckbox($event)" />
			<label :for="id">
				<small v-show="isRequired" class="text-danger">*</small>
				{{ title }}
			</label>
			<span class="form-text text-muted" v-if="notes != null">{{ notes }}</span>
			<div class="error-msg">
				<ul class="mb-0 p-0 fs-14">
					<li class="d-block" v-for="(error, index) of errors" :key="index">
						{{ error.$message }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {		
		name: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			default: "",
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		modelValue: {
			type: [Boolean,Number],
			default: false,
		},
		divClass: {
			type: String,
			default: "checkbox checkbox-success",
		},
		notes: {
			type: String,
			default: null,
		},
		errors: {
			type: Array,
			default: () => []
		},
		id: {
			type: String,
			default:  [...Array(10)].map(() => (~~(Math.random() * 36)).toString(36)).join(''),
		},
		isCheckboxDisabled:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {};
	},

	methods: {
		toggleCheckbox($event) {
			if(this.isCheckboxDisabled){
				$event.preventDefault();
			}else{
				this.$emit("update:modelValue", $event.target.checked);
			}
		},
	},
};
</script>
