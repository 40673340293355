<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
    </div>
</template>

<script>
import sidebar from '../sidebar.vue';

export default ({
    data() {
        return {
        }
    },
    components: {
        sidebar,
    },
    mounted() {
    },
    methods:{
    },
    computed: {
    },
})
</script>
