import {
	helpers,
	required,
	// email,
} from "@vuelidate/validators";

const inbox = {
    namespaced: true,

    state: {
		listUrl: "/inbox/list",
        header: [
			{ "patient_id": " Demographic No." },
			{ "provider_name": " Provider Name" },
			{ "patient_name": "Patient Name" },
			{ "gender": "Sex" },
			{ "document_description": "Document Description" },
			{ "pdf_document": "Content" },
			{"observation_date":"Observer Date"},
			{ "created_datetime": "Action" }
		],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
			id: null,
			observation_date:'',
			patient_id:null,
			provider_id:null,
			document_type_id:null,
			doc_file:null,
		},
		form: {
			observation_date:new Date()
		},
		validationRules: {
			form: {
				observation_date: {
					required: helpers.withMessage("Please select Patient's observation date.", required),
				},
				patient_id:{
					required: helpers.withMessage("Please select patient.", required),
				},
				document_type_id:{
					required: helpers.withMessage("Please select document type.", required),
				}
			},
		},
		filter:{
			'patient_name':'',
			'patient_dob':'',
			'filter_provider_id':'',
			'observation_date':'',
			'assigned_status':''
		}
    },
    getters: {},
	mutations: {},
	actions: {},

};

export default inbox;