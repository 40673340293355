<template>

	<div class="content">
		<div class="container-full">
            <div class="row">
                <div class="col-2">
                    <Sidebar></Sidebar>
                </div>

                <div class="col-10 me-auto">
                    <div class="box">
                        <div class="box-body">
                            <div class="content-header">
                                <div class="row d-flex align-items-center">
                                    <div class="me-auto">
                                        <h3 class="page-title">Role Management</h3>
                                    </div>

                                    <div class="col-2">
                                        <!-- <button class="waves-effect waves-light btn btn-primary d-block mb-10 btn-sm" href="javascript::void(0)" @click="createtRecord" ><i class="fa fa-plus fs-14"></i>&nbsp;Create Role</button> -->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <Datatable dataType="role" dataMethod="post" :key="key">
                                    <template v-slot="slotProps">
                                        <td>{{slotProps.item.name}}</td>
                                        <td>
                                            <a class="btn btn-sm btn-primary me-1" href="#" @click="editRecord(slotProps.item.id)" v-if="this.authUserRole == 'Admin'"> <i class="fa fa-pencil"></i>&nbsp;Edit</a>

                                        </td>
                                    </template>    
                                </Datatable>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

		</div>
	</div>
</template>

<script>
import Sidebar from './../Sidebar.vue';
import Datatable from '../../../base/formFields/Datatable.vue';

export default {
    data() {
        return {
            key: 0,
            searchKey:0,
            search_drop: true,
            authUserRole:localStorage.getItem("authUserRole")
        }
    },
    setup() {
        
    },
	components: {
        Sidebar,
        Datatable
	},
	created() {
	},
    methods:{
        applyFilter() {
            this.key++;
            this.search_drop = false;
        },
        resetFilter(){
            // this.filter.provider_id='';
            this.applyFilter()
        },
        createtRecord(){
            this.$router.push(`/administration/system/role/create`);
        },
        editRecord(id){
            this.$router.push(`/administration/system/role/retrieve/${id}`);
        }
    }
}
</script>