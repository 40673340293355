<template>

<div class="row">
	<h2>{{this.patient_detail.full_name}}</h2>
	<p>{{this.patient_detail.gender}} {{this.patient_detail.age}}</p>
	
</div>

<h3 style="text-align: center;">Prevention Recommendations</h3>

<table class="legend" cellspacing="0">
    <tbody>
        <tr>
            <td class="btn btn-secondary border-black"></td>
            <td>&nbsp;Completed or Normal&nbsp;</td>
            <td class="btn btn-danger border-black"></td>
            <td>&nbsp;Refused&nbsp;</td>
            <td class="btn btn-warning border-black"></td>
            <td>&nbsp;Ineligible&nbsp;</td>
        </tr>
    </tbody>
</table>

	<div class="box pt-5">		
		<div class="box-body p-0">
			<div class="media-list media-list-hover">
                <div class="row">
                    <div class="col-md-3">
                        <ul class="list-group">
                            <li class="list-group-item" v-for="item in this.defaultPreventionsData" :key="item">
                                <a href="javascript::void(0)" data-bs-toggle="modal" data-bs-target="#patientPreventionModel" @click="patientPreventionFrm(item.id, item.name, item.section)">
                                {{ item.name }}
                                </a>
                            </li>
                        </ul>
						<div class="text-center pt-5">
							<button class="btn btn-sm btn-warning pt-5" @click="print">Print</button>
						</div>
                    </div>

                    <div class="col-md-9">

                        <div v-for="item in this.patientPreventionsData" :key="item">
                            <a class="btn btn-sm btn-outline-secondary border-dark ml-5" href="javascript::void(0)" >
                                {{ item.preventions_name }}
                            </a>  
                          <!-- data-bs-toggle="modal" data-bs-target="#patientPreventionModel"  @click="patientPreventionFrm(item.latest.prevention_id, item.latest.preventions_name, item.section, item.latest.id)" -->
                            <a data-toggle="tooltip" data-html="true" data-placement="top" :title="itemDetail.summary" v-for="itemDetail in item.detail" :key="itemDetail" :class="['btn border-dark ml-5 btn-sm', itemDetail.status == 'Refused' ? 'btn-danger' : (itemDetail.status == 'Ineligible' ? 'btn-warning' : 'btn-secondary')] " href="javascript::void(0)" data-bs-toggle="modal" data-bs-target="#patientPreventionModel" @click="patientPreventionFrm(item.id, item.preventions_name, itemDetail.section, itemDetail.id)">
                                Date: {{itemDetail.start_date}}
                            </a>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

	<div class="modal center-modal fade " id="patientPreventionModel" tabindex="-1" style="display: none;" aria-hidden="true" :key="key">
		<div class="modal-dialog modal-lg  modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title"></h5>
				</div>
				<div class="modal-body">
					<form id="preventionFrom">
						<fieldset v-if="this.preventionForm.id">
							<div class="form-group">
								<label class="form-label">Summary </label>
								<div v-html="this.preventionForm.summary"></div>
							</div>
							<hr/>
						</fieldset>

						<div class="row">
							<div id="radioset">
								<input type="radio" id="statusCompleted" name="status" checked="checked" v-model="this.preventionForm.status" value="Completed"><label for="statusCompleted">Completed</label>
								<input type="radio" id="statusRefused" name="status" v-model="this.preventionForm.status" value="Refused"><label for="statusRefused" >Refused</label>
								<input type="radio" id="statusIneligible" name="status" v-model="this.preventionForm.status" value="Ineligible"><label for="statusIneligible" >Ineligible</label>
							</div>
							<DatePicker title="Date" format="yyyy-mm-dd" v-model="this.preventionForm.start_date"></DatePicker>
							<SelectBoxStatic title="Provider Doctor Name" 	  v-model="this.preventionForm.provider_id" :option="providerDataSource"></SelectBoxStatic>
							
							<InputField v-model="this.preventionForm.createdBy" title="Creator:" id="currentUser" value="currentUser" :readonly="true" > </InputField>
						</div>

						<fieldset>
							<legend>Result:</legend>		
								<div class="row" v-if="this.preventionForm.section == 'Immunizations'">
									<InputField v-model="this.preventionForm.result.name" title="Name:" id="prevention_name" :style="col-6"> </InputField>
									<InputField v-model="this.preventionForm.result.location" title="Location:" id="location" :style="col-6"> </InputField>
									<InputField v-model="this.preventionForm.result.route" title="Route:" id="route" > </InputField>
									<InputField v-model="this.preventionForm.result.dose" title="Dose:" id="dose"> </InputField>
									<InputField v-model="this.preventionForm.result.lot" title="Lot:" id="lot"> </InputField>
									<InputField v-model="this.preventionForm.result.din" title="DIN:" id="din"> </InputField>
									<InputField v-model="this.preventionForm.result.manufacture" title="Manufacture:" id="manufacture"> </InputField>
								</div>
								<div class="row" v-else-if="this.preventionForm.result">
									<div id="radioset">
										<input type="radio" id="resultPending" name="result" checked="checked" v-model="this.preventionForm.result.status" value="Pending"><label for="resultPending">Pending</label>
										<input type="radio" id="resultRefused" name="result" v-model="this.preventionForm.result.status" value="Normal"><label for="resultRefused" >Normal</label>
										<input type="radio" id="resultAbnormal" name="result" v-model="this.preventionForm.result.status" value="Abnormal"><label for="resultAbnormal" >Abnormal</label>
										<input type="radio" id="resultOther" name="result" v-model="this.preventionForm.result.status" value="Other"><label for="resultOther" >Other</label>
									</div>
									<InputField v-model="this.preventionForm.result.reason" title="Reason:"> </InputField>
								</div>
						</fieldset>

						<fieldset>
							<legend>Comments:</legend>
							<TextArea divClass="col-12" v-model="this.preventionForm.comments" :rows="6" placeholder="Enter Comments"></TextArea>
						</fieldset>

						<fieldset>
							<legend>Set Next Date:</legend>
							<div class="row">
								<DatePicker title="Next Date" format="yyyy-mm-dd" v-model="this.preventionForm.next_date"></DatePicker >
								<InputField v-model="this.preventionForm.result_reason" title="Reason:" id="result_reason"> </InputField>
								<CheckBox id="never_remind" name="never_remind" title="Never Remind"
								v-model="this.preventionForm.never_remind"></CheckBox>
							</div>
						</fieldset>
					</form>
				</div>
				<div class="modal-footer modal-footer-uniform">
					<form-btn
						data-bs-dismiss="modal"
						:title="savePreventationBtn"
						btnCls="btn-primary "
						@click="savePrevention"
					></form-btn>
					<button type="button" class="btn btn-danger float-end" data-bs-dismiss="modal">Exit</button>
				</div>
			</div>
		</div>
	</div>

    <div id="printHtml" style="display:none">
        <h1>Preventions</h1>
		<h2>{{this.patient_detail.full_name}}</h2>
		<h2>{{this.patient_detail.gender}} {{this.patient_detail.age}}({{this.patient_detail.dob}})</h2>
		<hr/>
		<div style="text-align:center">
			<h4>{{this.clinic_detail.organization_name}}</h4>
			<slot v-if="this.clinic_detail.address">{{this.clinic_detail.address}}</slot>
			<slot v-if="this.clinic_detail.city">,<br/> {{this.clinic_detail.city}}</slot>
			<slot v-if="this.clinic_detail.state">, {{this.clinic_detail.state}}</slot>
			<slot v-if="this.clinic_detail.country">, {{this.clinic_detail.country}}</slot>
			<slot v-if="this.clinic_detail.zipcode">, {{this.clinic_detail.zipcode}}</slot>
			<slot v-if="this.clinic_detail.phone_number"><br/>Ph No: {{this.clinic_detail.phone_number}}</slot>
		</div>

        <div v-for="item in this.patientPreventionsData" :key="item" style="margin-top:5px;">
            <b>{{ item.preventions_name }}</b>

            <div v-for="itemDetail in item.detail" :key="itemDetail" style="margin-top:5px;">
                <slot>Date: {{itemDetail.start_date}}</slot><br/>
				<slot>Status: {{itemDetail.status}}</slot><br/>
				<slot v-if="itemDetail.result.status != null">Result Status: {{itemDetail.result.status}}</slot>
				<slot>Administering Provider: {{itemDetail.provider_name}}</slot>
            </div>

        </div>
    </div>

</template>

<script>
import axios from "@/scripts/axios.js";
import TextArea from '../base/formFields/TextArea.vue';
import DatePicker from '../base/formFields/DatePicker.vue';
import SelectBoxStatic from "../base/formFields/SelectBoxStatic.vue";
import InputField from "../base/formFields/InputField.vue"
import CheckBox from "../base/formFields/CheckBox.vue";
import $ from "jquery";
import FormBtn from "../base/formFields/FormBtn.vue";

// Use the beforeunload event to notify the parent window when it's being closed
window.addEventListener("beforeunload", () => {
    // Notify the parent window that the child window is being closed
    if (window.opener) {
        window.opener.postMessage("Child window is closing", "*");
    }
});

export default {
    data() {
		return {
            defaultPreventionsData: [],
            patientPreventionsData: [],
            savePreventationBtn:'save',
            providerDataSource:[],
            currentUser:localStorage.getItem('authUserName'),
			patient_detail:[],
			clinic_detail: [],
			key:0,
        }
    },
	components: {
		TextArea,
		DatePicker,
		SelectBoxStatic,
		InputField,
		CheckBox,
		FormBtn
	},
    computed: {
        preventionForm() {
			return this.$store.state.prevention.form;
		}
    },
    mounted() {
		this.fetchClinicDetail()
		this.fetchPatientDetail()
        this.fetchDefaultPrevention()
        this.fetchPatientPrevention()
    },
    created() {
        this.resetforms();
    },
    methods: {
		fetchClinicDetail() {
			axios.post("clinic/detail")
				.then((response) => {
					if (response.data.status === 200) {
						this.clinic_detail = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		fetchPatientDetail() {
			axios.post("patient/detail", { 'id': this.$route.params.patient_id })
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
        fetchDefaultPrevention() {
			this.defaultPreventionsData = []
			axios.post("json_autocomplete/preventions/list")
				.then((response) => {				
					this.defaultPreventionsData = response.data.data
				})
		},
        fetchPatientPrevention() {
			this.patientPreventionsData = []
			axios.post("patient/preventions/list", {'patient_id':this.$route.params.patient_id})
				.then((response) => {
					this.patientPreventionsData = response.data.data
				})
		},
        async patientPreventionFrm(preventionId, preventationName, sectionName='',id='') {
				await this.fetchProviderData();
				if (id != '') {
					this.savePreventationBtn = 'Update';
					const url = "patient/preventions/retrieve";
					axios.post(url, { id: id })
					.then((response) => {
						if (response.status == 200) {
							this.$store.state.prevention.form = response.data.data
						}
					})
				} else {
					this.resetforms();
					this.savePreventationBtn = 'Save';
					this.$store.state.prevention.form.provider_id = localStorage.getItem('userId')
					this.currentUser = localStorage.getItem('authUserName')
                    this.$store.state.prevention.form.createdBy = this.currentUser
                    this.$store.state.prevention.form.section = sectionName
				}

				this.preventionForm.prevention_id = preventionId
                $("#patientPreventionModel .modal-title").text("Prevention : "+preventationName)
		},
        savePrevention() {
			let url = '';
			if (this.preventionForm.id) {
				url = "patient/preventions/update";
			} else {
				url = "patient/preventions/store";
			}
			this.preventionForm.patient_id = this.$route.params.patient_id
			let form = this.preventionForm;

			axios.post(url, form)
				.then((response) => {
					if (response.status == 200) {
						this.fetchPatientPrevention()
						this.$filters.moshaToast(response.data.message, "success");
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
					this.$store.state.prevention.form = {};
                    this.resetforms();
				})
		},
        resetforms() {
			Object.assign(
				this.$store.state.prevention.form,
				this.$store.state.prevention.defaultFormData
			);
			this.$store.state.prevention.form = this.$store.state.prevention.defaultFormData
		},
        fetchProviderData() {
			axios.post("json_list/provider", { 'roleId': '' })
				.then((response) => {
					const providerTempData = []
					const details = response.data.data;
					$.each(details, function (key, detail) {
						providerTempData.push({
							value: detail.user_id,
							title: detail.full_name,
						})
					})
					this.providerDataSource = providerTempData
				})
		},
        print() {
            // Pass the element id here
            this.$htmlToPaper('printHtml');
        }
    }
}
</script>

<style scoped>
.ml-5{
    margin-left: 5px; 
    margin-bottom: 5px;
}
</style>