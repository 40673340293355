<template>
	<div class="row">
		<div class="box">
			<div class="box-body">
				<div class="col-2">
					<router-link to="/patient/create" class="waves-effect waves-light btn btn-info d-block mb-10">
						<i class="fa fa-plus fs-14"></i> Create Demographic
					</router-link>
				</div>
			</div>
		</div>
	</div>
	<Datatable dataType="patient">
		<template v-slot="slotProps">
			<td>
				<router-link :to="{ name: 'PatientChart', params: { patient_id: slotProps.item.patient_id, } }" @click="setPatientId(slotProps.item.patient_id)">{{ slotProps.item.patient_id }}</router-link>
			</td>
			<td>
				<a href="#" @click="launchEchart(slotProps.item.patient_id)"> E </a>
				<a href="#" @click="openWindow('prescriptions',slotProps.item.patient_id)"> Rx </a>
			</td>
			<td>{{ slotProps.item.first_name }} {{ slotProps.item.last_name }}</td>
			<td>{{ slotProps.item.chart_id }}</td>
			<td>{{ slotProps.item.gender }}</td>
			<td>{{ slotProps.item.dob }}</td>
			<td>{{ slotProps.item.health_insurance_no }}</td>
			<td>{{ slotProps.item.provider_name }}</td>
			<!-- <td>{{ slotProps.item.roster_status }}</td> -->
			<td>{{ slotProps.item.status }}</td>
			<td>{{ slotProps.item.cell_phone }}</td>
		</template>
	</Datatable>
</template>

<script>
import Datatable from '../base/formFields/Datatable.vue';
export default {
	components: {
		Datatable,
	},
	methods: {
		setPatientId(PatientId) {
			this.$store.commit('setPatientId', { PatientId });
		},
		launchEchart(patient_id) {
			const { protocol, hostname, port } = window.location;			
			let baseUrl = `${protocol}//${hostname}`;			
			if (port && !['80', '443'].includes(port)) {
				baseUrl += `:${port}`;
			}
			baseUrl += `/#/patient/e-chart/${patient_id}`
			const newWindow = window.open(baseUrl, `_blank`);
			if (newWindow) {
				newWindow.focus();
			}
		},
		openWindow(moduleName, patient_id) {
			const { protocol, hostname, port } = window.location;

			// Construct the base URL using the parts obtained above
			let baseUrl = `${protocol}//${hostname}`;

			// Add the port to the base URL if it's not the default for the protocol
			if (port && !['80', '443'].includes(port)) {
				baseUrl += `:${port}`;
			}

			baseUrl += `/#/patient/${moduleName}/${patient_id}`			

			const newWindow = window.open(baseUrl, `_blank`);
			if (newWindow) {
				newWindow.focus();
			}
		},
	}
}
</script>