const permission = {
    namespaced: true,

    state: {
        admin_acount_setting_crud_all:2,
        admin_acount_setting_crud:3,
        admin_acount_setting_read:4,

        admin_clinic_detail_crud_all:5,
        admin_clinic_detail_crud:6,
        admin_clinic_detail_read:7,

        admin_billing_crud_all:8,
        admin_billing_crud:9,
        admin_billing_read:10,

        admin_inbox_crud_all:11,
        admin_inbox_crud:12,
        admin_inbox_read:13,

        admin_appointment_settings_crud_all:14,
        admin_appointment_settings_crud:15,
        admin_appointment_settings_read:16,

        admin_activity_log_crud_all:17,
        admin_activity_log_crud:18,
        admin_activity_log_read:19,

        demographics_encounter_note_crud_all:20,
        demographics_encounter_note_crud:21,
        demographics_encounter_note_read:22,

        demographics_prescription_crud_all:23,
        demographics_prescription_crud:24,
        demographics_prescription_read:25,

        inbox_inbox_crud_all:26,
        inbox_inbox_crud:27,
        inbox_inbox_read:28,

        task_task_crud_all:29,
        task_task_crud:30,
        task_task_read:31,

        other_other_crud_all:32,
        other_other_crud:33,
        other_other_read:34,

    },

    getters: {},
    mutations: {},
    actions: {},
}

export default permission;
