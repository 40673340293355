const websocketUrl = process.env.VUE_APP_WEB_SOCKET_URL;

class WebSocketService {
	constructor() {
		this.socket = null;
		this.messageCallback = null;
		this.connect();
	}

	connect() {
		this.socket = new WebSocket(websocketUrl);
		this.socket.onopen = this.onOpen.bind(this);
		this.socket.onmessage = this.onMessage.bind(this);
		this.socket.onclose = this.onClose.bind(this);
		this.socket.onerror = this.onError.bind(this);
	}

	onOpen(event) {
		console.log('WebSocket connection opened:', event);
	}

	onMessage(event) {
		if (event.data instanceof Blob) {
			const reader = new FileReader();
			reader.onload = () => {
				const text = reader.result;
				if (this.messageCallback) {
					this.messageCallback(text);
				}
			};
			reader.readAsText(event.data);
		} else {
			const message = JSON.parse(event.data);
			if (this.messageCallback) {
				this.messageCallback(message);
			}
		}
	}

	onClose(event) {
		console.log('WebSocket connection closed:', event);
		setTimeout(() => {
			this.connect();
		}, 2000);
	}

	onError(event) {
		console.error('WebSocket error:', event);
	}

	send(message) {
		this.socket.send(JSON.stringify(message));
	}

	close() {
		this.socket.close();
	}

	setMessageCallback(callback) {
		this.messageCallback = callback;
		this.socket.onmessage = (event) => {
			if (event.data instanceof Blob) {
				const reader = new FileReader();
				reader.onload = () => {
					const text = reader.result;
					if (this.messageCallback) {
						console.log(text);
						this.messageCallback(text);
					}
				};
				reader.readAsText(event.data);
			} else {
				const message = JSON.parse(event.data);
				if (this.messageCallback) {
					console.log(message);
					this.messageCallback(message);
				}
			}
		};
	}
}

export default WebSocketService;