import {
	helpers,
	required,
	email,
} from "@vuelidate/validators";

const specialist = {
    namespaced: true,

    state: {
		listUrl: "/specialists/list",
        header: [
            { "full_name": "Specialist" },
            { "specialist_type": "Specialist Type" },
            { "address": "Address" },
            { "phone": "Phone" },
            { "fax": "Fax" },
            { "Action": "Action" },
        ],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
            'clinic_id':null,
            'first_name':null,
            'last_name':null,
            'address':null,
            'annotation':null,
            'phone':null,
            'fax':'',
            'website':null,
            'email':null,
            'specialist_type':null,
            'referral_no':null,
        },
        form:{
            'clinic_id':null,
            'first_name':null,
            'last_name':null,
            'address':null,
            'annotation':null,
            'phone':null,
            'fax':'',
            'website':null,
            'email':null,
            'specialist_type':null,
            'referral_no':null,
        },
        validationRules: {
			form: {
				last_name: {
					required: helpers.withMessage("Please enter last name.", required),
				},
                first_name: {
					required: helpers.withMessage("Please enter first name.", required),
				},
                email:{
                    required: helpers.withMessage("Please enter email.", required),
                    email: helpers.withMessage("Please enter valid email.", email),
                }
            }
        },
        filter:{
        }
    }
}

const documentType = {
    namespaced: true,

    state: {
		listUrl: "/document-type/list",
        header: [
            { "type": "Document Type" },
            { "deleted_datetime": "Status" },
            { "Action": "Action" },
        ],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
            'type':null,
            'status':'active'
        },
        form:{
            'type':null,
            'status':'active'
        },
        validationRules: {
			form: {
				type: {
					required: helpers.withMessage("Please enter document type.", required),
				},
            }
        },
        filter:{
        }
    }
}

const role = {
    namespaced: true,

    state: {
		listUrl: "/admin/role/list",
        header: [
            { "name": "Role Name" },
            { "Action": "Action" },
        ],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
            'name':null,
        },
        form:{
            'name':null,
            'permissionIds':[]
        },
        validationRules: {
			form: {
				name: {
					required: helpers.withMessage("Please enter role name.", required),
				},
            }
        },
        filter:{
        }
    }
}

const service = {
    namespaced: true,

    state: {
		listUrl: "/consultations-services/list",
        header: [
            { "name": "Service Name" },
            { "status": "Status" },
            { "Action": "Action" },
        ],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
            'name':null,
        },
        form:{
            'name':null,
        },
        validationRules: {
			form: {
				name: {
					required: helpers.withMessage("Please enter service name.", required),
				},
            }
        },
        filter:{
        }
    }
}
const clinic_vital = {
    namespaced: true,

    state: {
		listUrl: "/admin/vital/list",
        header: [
            { "type": "Vital Name" },
            // { "status": "Status" },
            { "Action": "Action" },
        ],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
            'type':null,
            'possible_units':null,
            'status':null,
            'instruction':null
        },
        form:{
            'type':null,
            'possible_units':null,
            'status':null,
            'instruction':null
        },
        validationRules: {
			form: {
				type: {
					required: helpers.withMessage("Please enter vital name.", required),
				},
                status:{
                    required: helpers.withMessage("Please select status.", required),
                }
            }
        },
        filter:{
        }
    }
}

const user_site_details = {
    namespaced: true,
    state: {
        defaultFormData: {
            'id':null,
            'clinic_unique_id':null,
            'location_name':null,
            'address':null,
            'city':null,
            'province':null,
            'postal':null,
            'phone_number':null,
            'fax_number':null,
        },
        form:{
            'id':null,
            'clinic_unique_id':null,
            'location_name':null,
            'address':null,
            'city':null,
            'province':null,
            'postal':null,
            'phone_number':null,
            'fax_number':null,
        },
        validationRules: {
			form: {
				clinic_unique_id: {
					required: helpers.withMessage("Please enter clinic id.", required),
				},
                location_name:{
                    required: helpers.withMessage("Please enter location name.", required),
                },
                address:{
                    required: helpers.withMessage("Please enter address.", required),
                },
                city:{
                    required: helpers.withMessage("Please enter city.", required),
                },
                province:{
                    required: helpers.withMessage("Please enter province.", required),
                },
                postal:{
                    required: helpers.withMessage("Please enter postal.", required),
                },
                phone_number:{
                    required: helpers.withMessage("Please enter phone number.", required),
                },
                fax_number:{
                    required: helpers.withMessage("Please enter fax number.", required),
                }
            }
        },
    }
}
export { specialist, documentType, role, service, clinic_vital, user_site_details}