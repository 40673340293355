<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Prescription" ref="globalSearch" v-model="form.keyword" @blur="this.getPrescriptionList()">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length - 1}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList"></CustomFilter>
                        <button class="comman_brdr_btn cmn_mute_clr" > Export </button>
                    </span>
                </div>
                <button class="comman_btn" @click="addPrescription"> Add Prescription </button>
            </div>
            <div class="table-responsive consult-list prescriptions-table">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset"><input class="form-check-input" type="checkbox"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'written_date' }" @click="changeSort('written_date')">Entered Date <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'start_date' }" @click="changeSort('start_date')"> Start Date <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'generic_name' }" @click="changeSort('generic_name')"> Medication <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'is_past_med' }" @click="changeSort('is_past_med')"> Past Med <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'is_long_term' }" @click="changeSort('is_long_term')"> Long Term <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.prescriptionList" :key="index">
                            <td><input class="form-check-input" type="checkbox"></td>
                            <td>{{ item.written_date }}</td>
                            <td>{{ item.start_date }}</td>
                            <td><div>{{ item.generic_name }}</div><div> {{ item.drug_name }} {{ item.strength }}</div></td>
                            <td>{{ item.is_past_med == 1 ? 'Yes' : 'No'  }}</td>
                            <td>{{ item.is_long_term == 1 ? 'Yes' : 'No'  }}</td>
                            <td class="edit_delet_link">
                                <span class="popup_link" @click="viewPrescription(item.id)">View</span>
                                <span class="popup_link" @click="deletePrescription(item.id)">Delete</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>
<script>
import sidebar from '../sidebar.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../../../base/CustomFilter.vue';

export default {
        data() {
            return {
                prescriptionList:[],
                sortBy:'',
                sortOrder:'',
                filterList:[],
                isShowCustomFilter:false,
                filterOptions:[
                    { value: "medication_master.drug_name", title: "Medication" },
                ],
                permissionList:[]
            }
        },
        components: {
            sidebar,
            CustomFilter
        },
        mounted() {
            this.getPermissionList()
            this.getPrescriptionList()
        },
        methods:{
            viewPrescription(id){                
                this.$router.push({ name: 'PatientEChartPrescriptionView', params: {patient_id: this.$route.params.patient_id, id:id } });
            },
            addPrescription(){
                if (this.checkPermission()) {
                    this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}/medication/prescription-add`);
                } else {
                    this.$swal({
                        title: 'Error!',
                        text: 'You Do Not Have Permission to Access',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        customClass: {
                            confirmButton: 'comman_btn permission_remove_btn'
                        },
                    });
                }
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    advanceFilter[item.field] = item.value
                });
                this.form.advanceFilter = advanceFilter
            },
            getPrescriptionList(){
                axios.post('patient/prescriptions/list', {
                    patient_id: this.$route.params.patient_id,
                    keyword: this.form.keyword,
                    advanceFilter:this.form.advanceFilter,
                    sort_by: this.sortBy,
                    sort_order:this.sortOrder

                })
                .then((response) => {
                    if (response.status == 200) {
                        this.prescriptionList = response.data.data
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            changeSort(fieldName){
                if (fieldName != this.sortBy) {
                    this.sortOrder = 'desc'
                } else if (this.sortOrder == 'desc') {
                    this.sortOrder = 'asc'
                } else{
                    this.sortOrder = 'desc'
                }

                this.sortBy = fieldName

                this.getPrescriptionList()
            },
            addFilter(){
                if (this.isShowCustomFilter) {
                    this.getPrescriptionList()
                }
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            deletePrescription(id){
                if (!this.checkPermission()) {
                    this.$swal({
                        title: 'Error!',
                        text: 'You Do Not Have Permission to Access',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        customClass: {
                            confirmButton: 'comman_btn permission_remove_btn'
                        },
                    });
                    return false
                }
                this.$swal({
					title: 'Are you sure you want to delete records?',
					text: 'You can restore this record',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Yes, delete it!',
					cancelButtonText: 'No, cancel please!',
					closeOnConfirm: false,
					closeOnCancel: false,
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/prescriptions/delete";
						axios.post(url, {'id':id})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
                                this.$filters.moshaToast(response.data.message, "success");
								this.getPrescriptionList()
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}).catch(error => {
                            if (error.response.status === 422) {
                                this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            } else {
                                this.$filters.moshaToast(error.response.data.message, "error");
                            }
                        }) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
            },
            getPermissionList(){
                axios.post('user/get-permission')
                .then((response) => {
                    if (response.status == 200) {
                        this.permissionList = response.data.data
                    } else {
                        this.permissionList = []
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.permissionList = []
                    } else {
                        this.permissionList = []
                    }
                });
            },
            checkPermission(){
                if (this.permissionList && this.permissionList.prescription_access) {
                    return true
                } else {
                    return false
                }
            }
        },
        computed: {
            form() {
                return this.$store.state.prescriptions.form;
            },
        },
}
</script>