<template>
	<div class="content">

        <div class="box">
			<div class="box-body">
				<h3>Assign Permission</h3>
            </div>
        </div>

        <div class="box">
            <div class="box-body">
                <div :key="key">
                    <div class="box form-group" v-for="(options, moduleGrp) in this.defaultRoles" :key="moduleGrp">
                        <h3 class="text-center">{{ moduleGrp }}</h3>
                        <div class="box-body" v-for="(option, module) in options" :key="module">
                            <h4>{{ module }}</h4>
                            <div class="row" v-for="(optDetail, label) in option" :key="label">
                                <div class="col-2" v-for="(detail, index) in optDetail" :key="index">
                                    <CheckBox 
                                        class="permissionChk"
                                        :title="detail.label" 
                                        :name="'group_' + slugifyText(module)" 
                                        :id="detail.id" 
                                        @change="updatePermissions(moduleGrp,module,detail.id)"
                                        v-if="detail.label"
                                        :modelValue="(option.selectedOption === detail.id)"

                                        ></CheckBox>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                    
                <div class="row">
                    <div class="col-md-2 pt-10">
                        <button class="btn btn-primary text-white" @click="submit">
                            {{saveBtn}}
                            <img src="images/loader.gif" v-if="loader" />
                        </button>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import CheckBox from "../../base/formFields/CheckBox.vue";
import axios from "@/scripts/axios.js";
import $ from "jquery";

export default {
    data(){
        return{
            saveBtn:'Save',
            title:'Create Role',
            defaultRoles:[],
            key:0,
            form:{
                permissionIds:[]
            }
        }
    },
    components: {
        CheckBox
    },
    mounted() {
        this.getDefaultRole()
        this.edit()
    },
    methods:{
        getDefaultRole(){
            axios.post("/admin/role/default", {})
                    .then((response) => {
                        if (response.data.status === 200) {
							this.defaultRoles = response.data.data;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error")
                        }
                    }).catch(error => {
                        this.$filters.moshaToast(error.message, "error")
                   
			}).catch(error => {
				if (error.response.status === 422) {
					this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
				} else {
					this.$filters.moshaToast(error.response.data.message, "error");
				}
			});

        },
        slugifyText(text){
            return text.toLowerCase() // Convert to lowercase
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/[^\w-]+/g, '') // Remove non-word characters except hyphens
            .replace(/--+/g, '-'); // Replace consecutive hyphens with a single hyphen
        },
        submit(){
                let permissions = []
                $(".permissionChk :checked").each(function(){
                    permissions.push($(this).attr('id'));
                });
                this.form.permissionIds = permissions;
                this.form.id = this.$route.params.id;

                let url='/user/store-permission'
                axios.post(url, this.form)
				.then((response) => {
					if (response.status == 200) {
                        this.loader = false;
                        this.$filters.moshaToast(response.data.message, "success");
                        this.$router.push(`/administration/user/list`);

                    } else {
						this.$filters.moshaToast(response.data.message, "error");
					}
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });

        },
        updatePermissions(moduleGrp,module,permissionId){
            const index = this.form.permissionIds.indexOf(permissionId);
            $.each(this.defaultRoles[moduleGrp], function(key, roleDetail) {
                if (index === -1) {
                    if (key == module) {
                        roleDetail['selectedOption'] = permissionId
                        this.form.permissionIds.push(permissionId)
                    }
                } else {
                    if (key == module) {
                        roleDetail['selectedOption'] = null
                        this.form.permissionIds.splice(index, 1);
                    } 
                }
            });
            this.key++;
        },
        edit(){
            let params = { 'id': this.$route.params.id }

			axios.post("user/retrieve", params)
				.then((response) => {
					if (response.data.status === 200) {
                        let detail=response.data.data
                        this.form.permissionIds = detail.permissionIds
                        let $this = this

                        $.each(this.defaultRoles, function(moduleGrp, roleDetail) {
                            $.each(roleDetail, function(module, rolemoduleDetail) {
                                $.each(rolemoduleDetail['options'], function(index, detail) {
                                    if ($this.checkPermission(detail['id'])){
                                        rolemoduleDetail['selectedOption'] = detail['id'];
                                    }
                                });
                            })
                        });

                        this.key++;

					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
        },
        checkPermission(permissionId) {
            return this.form.permissionIds.includes(permissionId);
        },
    },
    computed: {
    },
    beforeCreate() {
    }
}
</script>
