import {
	helpers,
	required,
	// email,
} from "@vuelidate/validators";

const all_eform = {
    namespaced: true,

    state: {
		listUrl: "/e-forms/list",
        header: [
			{ "patient_name": "eForm Name" },
			{ "creator": "Additional Information" },
			{ "service_date": "Modified Date" },
		],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
			id: null,
			comments: null,
			assigned_to: null,
			status: null,
		},
		form: {},
		validationRules: {
			form: {
				description: {
					required: helpers.withMessage("Please enter document title.", required),
				},
				observation_date:{
					required: helpers.withMessage("Please select observation date.", required),
				},
				document_type_id:{
					required: helpers.withMessage("Please select document type.", required),
				},
				file_path:{
					required: helpers.withMessage("Please select document file.", required),
				}
			},
		},
		filter:{
			'document_type_id':'',
			'view_status':'published'
		}
    },
    getters: {},
	mutations: {},
	actions: {},

};

const current_eform = {
    namespaced: true,

    state: {
		listUrl: "/patient/eform/list",
        header: [
			{ "patient_name": "eForm Name" },
			{ "creator": "Additional Information" },
			{ "service_date": "Modified Date" },
			{ "created_datetime": "Action" },
		],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
			id: null,
			comments: null,
			assigned_to: null,
			status: null,
		},
		form: {},
		validationRules: {
			form: {
				description: {
					required: helpers.withMessage("Please enter document title.", required),
				},
				observation_date:{
					required: helpers.withMessage("Please select observation date.", required),
				},
				document_type_id:{
					required: helpers.withMessage("Please select document type.", required),
				},
				file_path:{
					required: helpers.withMessage("Please select document file.", required),
				}
			},
		},
		filter:{
			'document_type_id':'',
			'view_status':'published'
		}
    },
    getters: {},
	mutations: {},
	actions: {},

};

const deleted_eform = {
    namespaced: true,

    state: {
		listUrl: "/patient/eform/list",
        header: [
			{ "patient_name": "eForm Name" },
			{ "creator": "Additional Information" },
			{ "service_date": "Modified Date" },
			{ "created_datetime": "Action" },
		],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
			id: null,
			comments: null,
			assigned_to: null,
			status: null,
		},
		form: {},
		validationRules: {
			form: {
				description: {
					required: helpers.withMessage("Please enter document title.", required),
				},
				observation_date:{
					required: helpers.withMessage("Please select observation date.", required),
				},
				document_type_id:{
					required: helpers.withMessage("Please select document type.", required),
				},
				file_path:{
					required: helpers.withMessage("Please select document file.", required),
				}
			},
		},
		filter:{
			'document_type_id':'',
			'view_status':'published'
		}
    },
    getters: {},
	mutations: {},
	actions: {},

};

export {all_eform, current_eform, deleted_eform};