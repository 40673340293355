<template>
	<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
		<h3>Documents List</h3>
            <router-link :to="{ name: 'PatientDocumentAdd', params: { patient_id: $route.params.patient_id,  }}" class="waves-effect waves-light btn btn-info d-block mb-10 btn-sm">
                <i class="fa fa-plus fs-14"></i> Add Docment
            </router-link>
	</div>

    <div class="box">
        <div class="box-body">
            <div class="row">
                 <div class="col-md-12">
                    <div class="row justify-content-sm-left">
                        <SelectBoxStatic divClass="col-md-3" title="" v-model="filter.document_type_id" :option="document_type_options" ></SelectBoxStatic>

                        <SelectBoxStatic divClass="col-md-3" title="" v-model="filter.view_status" :option="view_status_options" ></SelectBoxStatic>
                        <div class="col-md-12">
                        <button
                            class="btn btn-sm btn-info col-md-1 me-1"
                            @click="applyFilter">
                            Search
                        </button>
                        <button
                            class="btn btn-sm btn-warning col-md-1"
                            @click="resetFilter">
                            Clear
                        </button>
                        </div>
                    </div>
                 </div>
            </div>
        </div>
    </div>



    <Datatable dataType="patient_document"  dataMethod="post" :key="key">
        <template v-slot="slotProps">
            <td>
                <a :href="slotProps.item.file_path ? documentUrl(slotProps.item.file_path) : '#'" target="_blank">
                    {{ slotProps.item.description }}
                </a>
            </td>
            <td>{{ slotProps.item.content }}</td>
            <td>{{ slotProps.item.type }}</td>
            <td>{{ slotProps.item.creator }}</td>
            <td>{{ slotProps.item.responsive }}</td>
            <td>{{ slotProps.item.observation_date }}</td>
            <td>{{ slotProps.item.reviewer }}</td>
            <td>
                <button class="btn btn-primary btn-sm me-1" href="javascript::void(0)" @click="editDocument(slotProps.item.id)" ><i class="fa fa-pencil"></i>&nbsp;Edit</button>
                <a href="#" class="btn btn-info btn-sm glyphicon glyphicon-list-alt me-1" @click="showAnnotation('document',slotProps.item.id,slotProps.item.annotation_note)">&nbsp;Annotation</a>
                <a href="#" v-if="!slotProps.item.deleted_datetime" class="btn btn-danger btn-sm me-1" @click="deleteRecord(slotProps.item.id)"> <i class="fa fa-trash-o"></i>&nbsp;Delete </a>
                <a href="#" v-if="slotProps.item.deleted_datetime" class="btn btn-info btn-sm" @click="restoreRecord(slotProps.item.id)"> <i class="fa fa-trash-restore"></i>&nbsp;Restore </a>

            </td>
        </template>
    </Datatable>

</template>


<script>

// Use the beforeunload event to notify the parent window when it's being closed
window.addEventListener("beforeunload", () => {
    // Notify the parent window that the child window is being closed
    if (window.opener) {
        window.opener.postMessage("Document window is closing", "*");
    }
});

import Datatable from '../../base/formFields/Datatable.vue';
import axios from "@/scripts/axios.js";
import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import $ from "jquery";

export default {
    data(){
        return {
            key: 0,
            search_drop: true,
            searchKey:0,
            document_type_options:[],
            view_status_options:[
                {value:'deleted', title:"Deleted"},
                {value:'published', title:"Published"}
            ]
        }
    },
	components: {
		Datatable,
        SelectBoxStatic
	},
    methods:{
        deleteRecord(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to revert this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/document/delete";
                    axios.post(url, {'ids':[id]})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.applyFilter()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });


        },
        resetFilter(){
            this.filter.document_type_id = ''
            this.filter.view_status = 'published'
            this.searchKey = this.searchKey + 1;
            this.applyFilter()
        },
        applyFilter() {
            this.key++;
            this.search_drop = false;
        },
        getDocumentType() {
			this.document_type_options = []
			axios.post("json_autocomplete/document-type/list", { })
				.then((response) => {
					if (response.data.status === 200) {
						const tempData = []
						$.each(response.data.data, function (key, detail) {
							tempData.push({
								value: detail.id,
								title: detail.type,
							})
						})
						this.document_type_options = tempData
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
        editDocument(id){
            this.$router.push(`/patient/document/${this.$route.params.patient_id}/retrieve/`+id);   

        },
        restoreRecord(id){
            this.$swal({
                title: 'Are you sure you want to restore records?',
                text: 'You will restore this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, restore it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/document/restore";
                    axios.post(url, {'id':id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.applyFilter()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });
        },
        showAnnotation(moduleName, history_id, annotation_note) {
            const { protocol, hostname, port } = window.location;
            // Construct the base URL using the parts obtained above
            let baseUrl = `${protocol}//${hostname}`;

            // Add the port to the base URL if it's not the default for the protocol
            if (port && !['80', '443'].includes(port)) {
                baseUrl += `:${port}`;
            }

            let encryptedMessage;
            if (annotation_note != null) {
                encryptedMessage = this.$filters.encryptString(annotation_note);
                baseUrl += `/#/patient/annotation-store/${moduleName}/${this.$route.params.patient_id}/${history_id}/${encryptedMessage}`
            } else {
                baseUrl += `/#/patient/annotation-store/${moduleName}/${this.$route.params.patient_id}/${history_id}`
            }

            const newWindow = window.open(baseUrl, `_blank`);
            if (newWindow) {
                newWindow.focus();
            }
		},
        documentUrl(documentPath) {
            // Assuming your documents are stored in the 'public' directory
            return `${process.env.VUE_APP_STORAGE_URL}/document/view/${documentPath}`;
        },
    },
    mounted() {
        this.getDocumentType()
    },
    computed: {
        filter() {
            return this.$store.state.patient_document.filter;
        },
    }
};
</script>
