<template>
    <SubPageHeader :headerTitle="getHeaderTitle" v-if="this.$store.state.component != 'templateMasterEdit'"></SubPageHeader>
	<div class="content">
		<div class="container-fluid">
			<component :is="$store.state.component">

			</component>
		</div>
	</div>
</template>
<script>
// import ManageEForms from '../../components/administrator/eforms/Home.vue';
// import EditEForms from '../../components/administrator/eforms/Edit.vue';
// import AdminDashboard from '../../components/administrator/Dashboard.vue';
// import AdminClinic from '../../components/administrator/clinic.vue';
// import AppointmentStatus from '../../components/administrator/AppointmentStatus.vue';
// import AppointmentType from '../../components/administrator/AppointmentType.vue';
// import AuditLog from "../administrator/AuditLog.vue"
import AccountSettings from "../../components/administrator/AccountSettings.vue"
import SubPageHeader from '../SubPageHeader.vue'
import managementSettings from "../../components/administrator/managementSettings.vue"
import billingCustomCode from "../../components/administrator/billingCustomCode.vue"
import thirdPartyIntegrations from "../../components/administrator/thirdPartyIntegrations.vue"
import clinicSettings from "../../components/administrator/clinicSettings.vue"
import hrAnalysisReports from "../../components/administrator/hrAnalysisReports.vue"
import auditLogs from "../../components/administrator/auditLogs.vue"
import dataManagement from "../../components/administrator/dataManagement.vue"
import paymentPreferences from "../../components/administrator/paymentPreferences.vue"

export default {
	components: {
		// ManageEForms,
		// EditEForms,
		// AdminDashboard,
		// AdminClinic,
		// AppointmentStatus,
		// AppointmentType,
		// AuditLog,
		AccountSettings,
		SubPageHeader,
		managementSettings,
		billingCustomCode,
		thirdPartyIntegrations,
		clinicSettings,
		hrAnalysisReports,
		auditLogs,
		dataManagement,
		paymentPreferences
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
	computed: {
		getHeaderTitle() {
			const currentRoute = this.$route;
			if (currentRoute.meta && currentRoute.meta.headerTitle) {
				return currentRoute.meta.headerTitle;
			} else {
				const parentRoute = currentRoute.matched[0];
				if (parentRoute.meta && parentRoute.meta.headerTitle) {
					return parentRoute.meta.headerTitle;
				} else {
					return 'Default Header Title';
				}
			}
		},
	},
}
</script>