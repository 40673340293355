<template>
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
		<h3 style="text-align: center;">Edit Patient Vital</h3>

        <div class="form-group">
            <slot v-for="(item, index) in this.vitalFrmData" :key="index">
                <div v-if="this.form.vital_master_id && item.id == this.form.vital_master_id">
                    <div class="input-group col-md-12">
                        <label class="form-label">  </label>
                        <InputField placeholder="Enter Value" :title="item.type" v-model="form.value" readonly="true"> </InputField>
                        <span  v-if="item.possible_units.length>0">
                            <label class="form-label">Unit</label>
                            <span class="input-group-text">{{item.possible_units}}</span>
                            <!-- <SelectBoxStatic title="Unit" :option="item.possible_units" v-model="form.selected_unit" disabled="true"></SelectBoxStatic> -->
                        </span>
                    </div>
                </div>
            </slot>

            <TextArea title="Comments" divClass="col-12"  :rows="4" style="width: 100%;  resize: vertical" placeholder="Enter Comment" v-model="form.comment" readonly="true"></TextArea>
        </div>

        <a href="#" class="btn btn-danger btn-sm" @click="deleteRecord(form.id)"> <i class="fa fa-trash-o"></i>&nbsp;Delete </a>
        <!-- v-model="form.comment" -->

	</div>
</template>

<script>
// Use the beforeunload event to notify the parent window when it's being closed
window.addEventListener("beforeunload", () => {
    // Notify the parent window that the child window is being closed
    if (window.opener) {
        window.opener.postMessage("Patient Vital window is closing", "*");
    }
});

import axios from "@/scripts/axios.js";
import InputField from "../../base/formFields/InputField.vue"
// import SelectBoxStatic from "../../base/formFields/SelectBoxStatic.vue";
import TextArea from '../../base/formFields/TextArea.vue';

export default {
    data() {
        return {
            vitalFrmData:[],
        }
    },
    components: {
        InputField,
        // SelectBoxStatic,
        TextArea
    },
    created() {
    },
    mounted() {
        this.getVitalForm()
        this.editRecord()
    },
    methods: {
        getVitalForm(){
            axios.post("patient/vital/get-form", { patient_id: this.$route.params.patient_id })
				.then((response) => {
                    this.vitalFrmData = response.data.data
				});
        },
        editRecord(){
            let _this = this
            axios.post("patient/vital/retrieve", { 'patient_id': this.$route.params.patient_id, 'id': this.$route.params.id})
            .then((response) => {
                if (response.data.status === 200) {
                    if (response.data.data === null || response.data.data === undefined || response.data.data.length === 0) {
                        this.$router.push(
                            `/patient/vital/` + _this.$route.params.patient_id+`/list/`+ _this.$route.params.vitalMasterId
                        );
                        this.$filters.moshaToast("Invalid request", "error")
                    }
                    this.$store.state.patientVital.form = response.data.data;
                } else {
                    this.$filters.moshaToast(response.data.message, "error")
                }
            }).catch(error => {
                this.$filters.moshaToast(error.message, "error")
            });
        },
        deleteRecord(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to revert this record',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel please!',
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/vital/delete-by-ids";
                    axios.post(url, {'ids':[id], 'patient_id': this.$route.params.patient_id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.$filters.moshaToast(response.data.message, "success");
                            this.$router.push(
                            `/patient/vital/` + this.$route.params.patient_id+`/list/`+ this.$route.params.vitalMasterId
                        );
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });


        },
    },
    validations() {
		return this.$store.state.patientVital.validationRulesEdit;
	},
    computed: {
        form() {
			return this.$store.state.patientVital.form;
		},
    },
    form() {
        return this.$store.state.patientVital.form;
    }
};
</script>
